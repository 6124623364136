import { useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from "react-bootstrap/Alert";
import { Plus, Dash, Calendar2Check } from "react-bootstrap-icons";

import Skracenica from "./Skracenica";
import { ugovori } from "../liste/ugovori";
import { useGlobalContext } from "../../context";



const ODBpojedinakdOBR = () => {
  const { procesuiranje, izvrsenje, procesuiranjeInfo, izvrsenjeInfo, DRZMOLITELJICA,
    //repls
    potraživanog, potraživani, njegovog, počinio, potraživanom } = useGlobalContext();

  const ugovor = ugovori.find((ugovor) => ugovor.ime === DRZMOLITELJICA.ime);

  const P1vrijeme = procesuiranjeInfo[0].procesuiranjeODB.vrijemevazenja;
  const P1kazna = procesuiranjeInfo[0].procesuiranjeODB.visinakazne;
  const P2vrijeme = procesuiranjeInfo[1]?.procesuiranjeODB?.vrijemevazenja ?? null;
  const P2kazna = procesuiranjeInfo[1]?.procesuiranjeODB?.visinakazne ?? null;

  const I1vrijeme = izvrsenjeInfo[0].izvrsenjeODB.vrijemevazenja;
  const I1kazna = izvrsenjeInfo[0].izvrsenjeODB.visinakazne;
  const I2vrijeme = izvrsenjeInfo[1]?.izvrsenjeODB?.vrijemevazenja ?? null;
  const I2kazna = izvrsenjeInfo[1]?.izvrsenjeODB?.visinakazne ?? null;


  const {
    procesuiranjekd: procesuiranjekd1,
    procesuiranjekazna: procesuiranjekazna1, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja1,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga1,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog1 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[0];

  const kaznaP1 = (procesuiranjekazna1.parafrazirano === "DA" ? procesuiranjekazna1.paraf || "je zaprijećena kazna zatvora u trajanju od __________" : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna1.val || "__________"));
  const nazivorganaP1 = (procesuiranjenazivsudanalog1.gen !== undefined ? "izdat je od strane " + (procesuiranjenazivsudanalog1.gen || "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));

  const {
    procesuiranjekd: procesuiranjekd2,
    procesuiranjekazna: procesuiranjekazna2, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja2,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga2,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog2 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[1] || {};

  const kaznaP2 = (procesuiranjekazna2?.parafrazirano === "DA" ? procesuiranjekazna2?.paraf || "je zaprijećena kazna zatvora u trajanju od __________" : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna2?.val || "__________"));
  const nazivorganaP2 = (procesuiranjenazivsudanalog2?.gen !== undefined ? "izdat je od strane " + (procesuiranjenazivsudanalog2?.gen || "__________") : "izdao je " + (procesuiranjenazivsudanalog2?.nom || "__________"));
  const nazivorganaP1jednakoP2 = (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen ? "izdati su od strane " + ((procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) ? (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) : "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));

  const {
    izvrsenjepresuda: izvrsenjepresuda1,
    izvrsenjenazivsuda: izvrsenjenazivsuda1, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd1,
    izvrsenjekazna: izvrsenjekazna1,   // {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja1,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga1,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog1, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[0];

  //OBRUVODizspisa var/s
  const nazivsudaI1 = (izvrsenjenazivsuda1.gen !== undefined ? (izvrsenjenazivsuda1.gen || "__________") + ", " + (DRZMOLITELJICA.ime || "__________") : "koju je izrekao " + (izvrsenjenazivsuda1.nom || "__________") + ", " + (DRZMOLITELJICA.ime || "__________"));
  const kaznaI1 = (izvrsenjekazna1.preostalidio !== undefined ? "kazne zatvora u trajanju od " + (izvrsenjekazna1.preostalidio || "__________") + ", kao preostalog neizdržanog dijela kazne zatvora izrečene u ukupnom trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________,") + "," : "kazne zatvora u trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________"));
  const kdivrijemeI1 = ("zbog krivičnog djela " + (izvrsenjekd1 || "_________") + " koje je počinjeno " + (izvrsenjevrijemeizvrsenja1 || "_________"));

  const {
    izvrsenjepresuda: izvrsenjepresuda2,
    izvrsenjenazivsuda: izvrsenjenazivsuda2, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd2,
    izvrsenjekazna: izvrsenjekazna2,   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja2,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga2,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog2, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[1] || {};

  const nazivsudaI2 = (izvrsenjenazivsuda2?.gen !== undefined ? (izvrsenjenazivsuda2?.gen || "__________") + ", " + (DRZMOLITELJICA.ime || "__________") : "koju je izrekao " + (izvrsenjenazivsuda2?.nom || "__________") + ", " + (DRZMOLITELJICA.ime || "__________"));
  const kaznaI2 = (izvrsenjekazna2?.preostalidio !== undefined ? "kazne zatvora u trajanju od " + (izvrsenjekazna2?.preostalidio || "__________") + ", kao preostalog neizdržanog dijela kazne zatvora izrečene u ukupnom trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________,") + "," : "kazne zatvora u trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________"));
  const kdivrijemeI2 = ("zbog krivičnog djela " + (izvrsenjekd2 || "_________") + " koje je počinjeno " + (izvrsenjevrijemeizvrsenja2 || "_________"));



  const UgovorPOJEDINAKDclanoviP = ugovor.pojedinosti.izrucenjevlastitih.clanovi
    .filter(clan => clan.vrsta === "P")
    .map((clan, index) => (
      <span key={index}>
        {index === 0 ? (
          <>
            Članom {clan.clan} Ugovora je propisano "<i>{clan.citat}</i>"
          </>
        ) : (
          <>
            članom {clan.clan} propisano "<i>{clan.citat}</i>".
          </>
        )}
      </span>
    ))
    .reduce((prev, curr) => [prev, " dok je ", curr]);

  const UgovorPOJEDINAKDclanoviI = ugovor.pojedinosti.izrucenjevlastitih.clanovi
    .filter(clan => clan.vrsta === "I")
    .map((clan, index) => (
      <span key={index}>
        {index === 0 ? (
          <>
            {procesuiranje && izvrsenje ? <>Kada je u pitanju izručenje potraživanih lica radi izvršenja kazne zatvora članom</> : <>Članom</>}
            {" "}{clan.clan} Ugovora je propisano "<i>{clan.citat}</i>"
          </>
        ) : (
          <>
            članom {clan.clan} propisano "<i>{clan.citat}</i>".
          </>
        )}
      </span>
    ))
    .reduce((prev, curr) => [prev, " dok je ", curr]);


  const ODBpojedinakdOBRprocesuiranje = () => {
    return (
      <>
        {procesuiranjeInfo.length === 1 &&
          <>
            {!P1vrijeme && !P1kazna && <>

              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za krivično gonjenje u <strong>dodatnim opcijama</strong>.
              </Alert>
            </>}

            {P1vrijeme && P1kazna && <>
              Nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"}.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
            </>}

            {P1vrijeme && !P1kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da, bez obzira na ispunjenost uslova u pogledu zaprijećene kazne, ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
            </>}

            {!P1vrijeme && P1kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
              {" "}dozvoljeno izručenje vlastitih državljanja shodno navedenom međunarodnom Ugovoru.
            </>}
          </>}

        {procesuiranjeInfo.length === 2 &&
          <>
            {!P1vrijeme && !P1kazna && !P2vrijeme && !P2kazna && <>
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva u <strong>dodatnim opcijama</strong>.
              </Alert>
            </>}

            {P1vrijeme && !P1kazna && !P2vrijeme && !P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da, bez obzira na ispunjenost uslova u pogledu zaprijećene kazne, ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugo krivično djelo <strong>({procesuiranjekd2 || "_________"})</strong>.
              </Alert>
            </>}

            {!P1vrijeme && P1kazna && !P2vrijeme && !P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
              {" "}dozvoljeno izručenje vlastitih državljanja shodno navedenom međunarodnom Ugovoru.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugo krivično djelo <strong>({procesuiranjekd2 || "_________"})</strong>.
              </Alert>
            </>}

            {!P1vrijeme && !P1kazna && (P2vrijeme || P2kazna) && <>

              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za prvo krivično djelo <strong>({procesuiranjekd1 || "_________"})</strong>.
              </Alert>
            </>}

            {P1vrijeme && P1kazna && !P2vrijeme && !P2kazna && <>
              Nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"}.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugo krivično djelo <strong>({procesuiranjekd2 || "_________"})</strong>.
              </Alert>
            </>}

            {P1vrijeme && !P1kazna && !P2vrijeme && P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, bez obzira na ispunjenost uslova u pogledu zaprijećene kazne kada je u pitanju krivično djelo {procesuiranjekd1 || "_________"}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).
              {" "}S druge strane, kada je u pitanju krivično djelo {procesuiranjekd2 || "_________"} očigledno je da se radi o krivičnom djelu koje ne spada u katalog
              {" "}krivičnih djela za koje je dozvoljeno izručenje vlastitih državljanja shodno citiranim odredbama Ugovora.

            </>}
            {!P1vrijeme && P1kazna && P2vrijeme && !P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, osim uslova u pogledu vrste i težine krivičnog djela potrebno je napomenuti i to da je odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Sud, u kontekstu navedenog, nalazi očiglednim da krivično djelo {procesuiranjekd1 || "_________"} zbog kojeg se traži izručenje {potraživanog}
              {" "}ne spada u katalog krivičnih djela za koje je dozvoljeno izručenje vlastitih državljanja shodno citiranim odredbama Ugovora.
              S druge strane, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, bez obzira na ispunjenost uslova u pogledu zaprijećene kazne kada je u pitanju krivično djelo {procesuiranjekd2 || "_________"}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).

            </>}
            {!P1vrijeme && P1kazna && !P2vrijeme && P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da krivična djela zbog kojih se traži izručenje {potraživanog}
              {" "}ne spadaju u katalog krivičnih djela za koje je dozvoljeno izručenje vlastitih državljanja shodno citiranim odredbama Ugovora.
            </>}

            {P1vrijeme && P1kazna && !P2vrijeme && P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, osim uslova u pogledu vrste i težine krivičnog djela potrebno je napomenuti i to da je odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, u odnosu na krivično djelo {procesuiranjekd1 || "_________"}, ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).
              {" "}S druge strane, kada je u pitanju krivično djelo {procesuiranjekd2 || "_________"} očigledno je da se radi o djelu koje ne spada u katalog
              {" "}krivičnih djela za koje je dozvoljeno izručenje vlastitih državljanja shodno citiranim odredbama Ugovora što važi i za krivično djelo {procesuiranjekd1 || "_________"}.
            </>}

            {!P1vrijeme && P1kazna && P2vrijeme && P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, osim uslova u pogledu vrste i težine krivičnog djela potrebno je napomenuti i to da je odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Sud, u kontekstu navedenog, nalazi očiglednim da krivično djelo {procesuiranjekd1 || "_________"} zbog kojeg se traži izručenje {potraživanog}
              {" "}ne spada u katalog krivičnih djela za koje je dozvoljeno izručenje vlastitih državljanja shodno citiranim odredbama Ugovora.
              S druge strane, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, osim smetnje u pogledu vrste i težine krivičnog djela {procesuiranjekd2 || "_________"}, ne postoji ni osnov za
              {" "}primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana ni sa aspekta vremenskog važenja Ugovora.
            </>}

            {P1vrijeme && P1kazna && P2vrijeme && P2kazna && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana sa aspekta njegovog vremenskog važenja.
              {" "}Osim toga, vidljivo je da krivična djelo zbog kojeg se traži izručenje {potraživanog} ne spadaju u katalog krivičnih djela za koje je
              {" "}dozvoljeno izručenje vlastitih državljanja shodno navedenom Ugovoru.
            </>}

            {P1vrijeme && P2vrijeme && (!P1kazna || !P2kazna || (!P1kazna && !P2kazna)) && <>
              {UgovorPOJEDINAKDclanoviP}
              {" "}Međunarodna potjernica je, u konkretnom slučaju, raspisana sa ciljem lišenja slobode {potraživanog} te {njegovog} krivičnog gonjenja
              {" "}zbog sumnje da je dana {procesuiranjevrijemeiizvrsenja1 || "_________"} {počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje
              {" "}{kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}, a koje je prema sadržaju potjernice počinjeno {procesuiranjevrijemeiizvrsenja2 || "_________"}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi krivičnog gonjenja,
              {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
              {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da, pored činjenice da je{" "}
              {!P1kazna && P2kazna ? `za neka od krivičnih djela ispunjen uslov zaprijećene kazne (${procesuiranjekd1 || "_________"})` : P1kazna && !P2kazna ? `za neka od krivičnih djela ispunjen uslov zaprijećene kazne (${procesuiranjekd2 || "_________"})` : `ispunjen uslov zaprijećene kazne u odnosu na sva krivčna djela`}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana sa aspekta njegovog vremenskog važenja.
            </>}


          </>}



      </>
    )
  }  //ODBpojedinakdOBRprocesuiranje

  const ODBpojedinakdOBRizvrsenje = () => {

    let oba = false;
    if (procesuiranje && izvrsenje) {
      oba = true;
    }


    return (
      <>
        {izvrsenjeInfo.length === 1 &&
          <>
            {!I1vrijeme && !I1kazna && <>

              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za izvršenje u <strong>dodatnim opcijama</strong>.
              </Alert>
            </>}

            {I1vrijeme && I1kazna && <>
              {oba ? `Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, a kako je i naprijed naznačeno, propisan uslov njihove primjene`
                :
                <>
                  Nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi izvršenja pravnosnažno izrečene kazne zatvora,
                  {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
                  {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                </>}
              {" "}samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              {" "}Međunarodna potjernica {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}
              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.
              <br /><br />
              {oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je {oba ? `da niti u pogledu razloga koji se odnose na izručenje radi izvršenja kazne zatvora` : "da"} ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
            </>}

            {I1vrijeme && !I1kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}
              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, propisan uslov njihove primjene`
                :
                `
                  ${" "}međutim, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              {oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da, bez obzira na ispunjenost uslova u pogledu visine izrečene kazne koju {potraživani} ima izdržati{izvrsenjekazna1.preostalidio !== undefined && " (preostalog neizdržanog dijela)"}, ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
            </>}

            {!I1vrijeme && I1kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}
              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne u konkretnom slučaju ne ispunjava objektivni uslov sadržan
              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
            </>}
          </>}

        {izvrsenjeInfo.length === 2 &&
          <>
            {!I1vrijeme && !I1kazna && !I2vrijeme && !I2kazna && <>
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva u <strong>dodatnim opcijama</strong>.
              </Alert>
            </>}

            {I1vrijeme && !I1kazna && !I2vrijeme && !I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}
              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, propisan uslov njihove primjene`
                :
                `
                  ${" "}međutim, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              {oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da, bez obzira na ispunjenost uslova u pogledu visine izrečene kazne koju {potraživani} ima izdržati{izvrsenjekazna1.preostalidio !== undefined && " (preostalog neizdržanog dijela)"}, ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugu kaznu zatvora <strong>({izvrsenjekazna2.preostalidio !== undefined ? `preostali dio ${izvrsenjekazna2.preostalidio}` : `${izvrsenjekazna2.izrecenakazna || "_________"}`})</strong>.
              </Alert>
            </>}

            {!I1vrijeme && I1kazna && !I2vrijeme && !I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne ne ispunjava objektivni uslov sadržan
              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugu kaznu zatvora <strong>({izvrsenjekazna2.preostalidio !== undefined ? `preostali dio ${izvrsenjekazna2.preostalidio}` : `${izvrsenjekazna2.izrecenakazna || "_________"}`})</strong>.
              </Alert>
            </>}

            {!I1vrijeme && !I1kazna && (I2vrijeme || I2kazna) && <>

              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za prvu kaznu zatvora <strong>({izvrsenjekazna1.preostalidio !== undefined ? `preostali dio ${izvrsenjekazna1.preostalidio}` : `${izvrsenjekazna1.izrecenakazna || "_________"}`})</strong>.
              </Alert>
            </>}

            {I1vrijeme && I1kazna && !I2vrijeme && !I2kazna && <>
              {oba ? `Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, a kako je i naprijed naznačeno, propisan uslov njihove primjene`
                :
                <>
                  Nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi izvršenja pravnosnažno izrečene kazne zatvora,
                  {" "}međutim, odredbom člana {ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano je da se odredbe Ugovora koje regulišu
                  {" "}dozvoljenost izručenja vlastitih državljana ({ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                </>}
              {" "}samo na krivična
              {" "}djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              {" "}Međunarodna potjernica {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}
              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}.
              <br /><br />
              {oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je {oba ? `da niti u pogledu razloga koji se odnose na izručenje radi izvršenja kazne zatvora` : "da"} ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana.
              <br /><br />
              <Alert variant="warning alertV2">
                Precizirajte razloge odbijanja zahtjeva za drugu kaznu zatvora <strong>({izvrsenjekazna2.preostalidio !== undefined ? `preostali dio ${izvrsenjekazna2.preostalidio}` : `${izvrsenjekazna2.izrecenakazna || "_________"}`})</strong>.
              </Alert>
            </>}

            {I1vrijeme && !I1kazna && !I2vrijeme && I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              {oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti  Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, bez obzira na ispunjenost objektivnog uslova koji se odnosi na visinu izrečene kazne {izvrsenjekazna1.preostalidio !== undefined && "(preostalog neizdržanog dijela)"} kada je u pitanju presuda, broj: {izvrsenjepresuda1 || "_________"}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).
              {" "}S druge strane, kada je u pitanju presuda, broj: {izvrsenjepresuda2 || "_________"} vidljivo je da {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po toj presudi ne ispunjava objektivni uslov sadržan
              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.

            </>}
            {!I1vrijeme && I1kazna && I2vrijeme && !I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.
              <br /><br />
              Sud, u kontekstu navedenog, a kada je u pitanju presuda, broj: {izvrsenjepresuda1 || "_________"}, nalazi očiglednim da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po toj presudi ne ispunjava objektivni uslov sadržan
              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
              S druge strane, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je Ugovor potpisan dana ${ugovor.datum}` : `je Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, bez obzira na ispunjenost objektivnog uslova koji se odnosi na visinu izrečene kazne {izvrsenjekazna2.preostalidio !== undefined && "(preostalog neizdržanog dijela)"} kada je u pitanju presuda, broj: {izvrsenjepresuda2 || "_________"}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).
            </>}

            {!I1vrijeme && I1kazna && !I2vrijeme && I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana radi izvršenja pravosnažno izrečene kazne zatvora,
              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da
              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI1}, broj: {izvrsenjepresuda1 || "__________"},
              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
              {" "}ne ispunjavaju objektivni uslov sadržan u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
            </>}

            {I1vrijeme && I1kazna && !I2vrijeme && I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              Stoga, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je Ugovor potpisan dana ${ugovor.datum}` : `je Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, uvažavajući vrijeme izvršenja krivičnog djela presudi {nazivsudaI1}, broj: {izvrsenjepresuda2 || "_________"},
              ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana (sa aspekta vremenskog važenja Ugovora).
              Osim toga, evidentno je da
              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po prethodno pomenutoj presudi,
              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
              {" "}ne ispunjavaju objektivni uslov visine neizdržanog dijela kazne zatvora sadržan u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
            </>}

            {!I1vrijeme && I1kazna && I2vrijeme && I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              Sud, u kontekstu navedenog, nalazi očiglednim da
              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po prethodno pomenutoj presudi {nazivsudaI1}, broj: {izvrsenjepresuda2 || "__________"},
              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
              {" "}ne ispunjavaju objektivni uslov visine neizdržanog dijela kazne zatvora sadržan u naprijed citiranim odredbama Ugovora koje regulišu izručenje vlastitih državljanja.
              Osim toga, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je Ugovor potpisan dana ${ugovor.datum}` : `je Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, uvažavajući vrijeme izvršenja krivičnog djela presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "_________"},
              ne postoji ni osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana sa aspekta vremenskog važenja Ugovora.
            </>}

            {I1vrijeme && I1kazna && I2vrijeme && I2kazna && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              Stoga, polazeći od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je Ugovor potpisan dana ${ugovor.datum}` : `je Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "}evidentno je da, uvažavajući vrijeme izvršenja krivičnih djela po naprijed naznačenim presudama {izvrsenjenazivsuda1.val === izvrsenjenazivsuda2.val ? "nadležnog suda države moliteljice" : "nadležnih sudova države moliteljice"}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana sa aspekta vremenskog važenja Ugovora.

            </>}

            {I1vrijeme && I2vrijeme && (!I1kazna || !I2kazna || (!I1kazna && !I2kazna)) && <>
              {UgovorPOJEDINAKDclanoviI}
              {" "}Međunarodna potjernica je {oba ? "je, u tom pravcu," : "je, u konkretnom slučaju,"} raspisana sa ciljem lišenja slobode {potraživanog} te izvršenja
              {" "}{kaznaI1}
              {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
              , i to {kdivrijemeI1}. Potraga je, dodatno, raspisana i sa ciljem izvršenja {kaznaI2}
              {" "}
              {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                ?
                `koja je ${potraživanom} izrečena presudom istog suda, broj: ${izvrsenjepresuda2 || "__________"}`
                :
                <>{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}</>}
              , {kdivrijemeI2}.

              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenja vlastitih državljana {oba && "i "}radi izvršenja pravnosnažno izrečene kazne zatvora,
              {" "}{oba ? `međutim, Ugovorom je, jednako kao i za odredbe o izručenju vlastitih državljana radi krivičnog gonjenja, pored uslova visine izrečene kazne propisan i uslov njihove primjene`
                :
                `
                  ${" "}međutim, potrebno je napomenuti i to da je, osim uslova u pogledu visine neizdržanog dijela izrečene kazne zatvora, odredbom člana ${ugovor.pojedinosti.vremenskaprimjena.clan} Ugovora propisano da se odredbe Ugovora koje regulišu
                  ${" "}dozvoljenost izručenja vlastitih državljana (${ugovor.pojedinosti.vremenskaprimjena.clanovipojedinakd}) primjenjuju
                  `}
              {" "}samo na krivična djela izvršena {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? "nakon stupanja na snagu" : "nakon potpisivanja"}
              {" "}pomenutog Ugovora.

              {" "}{oba ? "Stoga, polazeći" : "Polazeći"} od činjenice da {ugovor.pojedinosti.vremenskaprimjena.odrednica === "stupanje" ? <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor stupio na snagu dana ${ugovor.stupanje}` : `su pomenute izmjene bilateralnog Ugovora stupile na snagu dana ${ugovor.stupanje2}`}</> : <>{ugovor.izmjene === "NE" ? `je pomenuti Ugovor potpisan dana ${ugovor.datum}` : `je pomenuti Ugovor potpisan dana ${ugovor.datum} pri čemu je izmijenjen Ugovorom koji datira od ${ugovor.datum2}`}</>}
              {" "} evidentno je da,
              {" "}{!I1kazna && I2kazna ? `iako određene kazne ispunjavaju objektivni uslov kada je u pitanju visina kazne (${izvrsenjekazna1.preostalidio !== undefined ? `preostali dio izrečene kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio}` : `kazna zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`})` : I1kazna && !I2kazna ? `iako određene kazne ispunjavaju objektivni uslov kada je u pitanju visina kazne (${izvrsenjekazna2.preostalidio !== undefined ? `preostali dio izrečene kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio}` : `kazna zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`})` : `iako obje izrečene kazne zatvora ispunjavaju objektivni uslov kada je u pitanju visina kazne`}
              , ne postoji osnov za primjenu odredbi Ugovora koje regulišu mogućnost izručenja domaćih državljana sa aspekta njegovog vremenskog važenja.
            </>}


          </>}
      </>
    )
  } //ODBpojedinakdOBRizvrsenje

  return (
    <>
      {(procesuiranje && !izvrsenje) &&
        <>
          <ODBpojedinakdOBRprocesuiranje />
        </> /*procesuiranje */}

      {(!procesuiranje && izvrsenje) &&
        <>
          <ODBpojedinakdOBRizvrsenje />
        </> /*            izvrsenje */}

      {(procesuiranje && izvrsenje) &&
        <>
          <ODBpojedinakdOBRprocesuiranje />
          <br /><br />
          <ODBpojedinakdOBRizvrsenje />
        </> /*procesuiranje && izvrsenje */}

      {(!procesuiranje && !izvrsenje) &&
        <>
          <Alert variant="warning alertV2">
            Odaberite <strong>razlog izručenja</strong>.
          </Alert>
        </> /*procesuiranje && izvrsenje */}


    </>
  )
} //ODBpojedinakdOBR


const Snipet = (props) => {
  const {
    brojPredmeta,
    datumodluke,
    datumodlukeTMP,
    IMELICA,
    datumlisenja,
    procesuiranje,
    procesuiranjeInfo,
    procesuiranjenazivsudanalog,
    procesuiranjebrojnaloga,
    izvrsenje,
    izvrsenjeInfo,
    izvrsenjebrojnaloga,
    izvrsenjenazivsudanalog,
    ODBobustavi,
    inputChange,
    DrzavljaninBiH,
    posjedujedrzavljanstvo,
    DRZMOLITELJICA,
    ncb1,
    ncb2,
    vrstabranioca,
    dodajtumaca,
    BRANILAC,
    TUZILAC,
    TUMAC,
    jezikprevoda,
    vrstaOdluke,
    BRnacinizbora,
    osnoviugovor,
    PROzahtjev,
    ODBfaza,
    MZfaza,
    PRfaza,
    PREKSfaza,
    PROfaza,
    EKSfaza,
    PROzahtjevMPBIH,
    PROzahtjevNCB,
    PROzahtjevNCBdatumprijema,
    PROzahtjevNCBbroj,
    PROzahtjevMPBIHdatumprijema,
    PROzahtjevMPBIHbroj,
    PROzahtjevdostavljenBR,
    pJ,
    pL,
    MZpasosi,
    MZboravistekucni,
    MZboravistepodrucje,
    MZboravisteadresa,
    MZstanica,
    MZjavljanjenacin,
    MZputovanje,
    MZboraviste,
    MZjavljanje,
    dvabranioca,
    ODBopcije,
    PostojiUgovor,
    potraživanog, //repl
    potraživanom,
    počinio,
    potraživani,
    Potraživani,
    njegov,
    njegovi,
    njegovo,
    njegovog,
    te_mu_je,
    poučen,
    pozvan,
    lišen,
    osuđen,
    njegovih,
    izjasnio,
    mu_je,
    on,
    saglasio,
    istom,
    državljanin,
    kojeg,
    izručen,
    pušten
  } = useGlobalContext();

  const ugovor = ugovori.find((ugovor) => ugovor.ime === DRZMOLITELJICA.ime);

  const {
    procesuiranjekd: procesuiranjekd1,
    procesuiranjekazna: procesuiranjekazna1, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja1,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga1,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog1 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[0];

  const kaznaP1 = (procesuiranjekazna1.parafrazirano === "DA" ? procesuiranjekazna1.paraf || "je zaprijećena kazna zatvora u trajanju od __________" : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna1.val || "__________"));
  const nazivorganaP1 = (procesuiranjenazivsudanalog1.gen !== undefined ? "izdat je od strane " + (procesuiranjenazivsudanalog1.gen || "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));

  const {
    procesuiranjekd: procesuiranjekd2,
    procesuiranjekazna: procesuiranjekazna2, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja2,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga2,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog2 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[1] || {};

  const kaznaP2 = (procesuiranjekazna2?.parafrazirano === "DA" ? procesuiranjekazna2?.paraf || "je zaprijećena kazna zatvora u trajanju od __________" : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna2?.val || "__________"));
  const nazivorganaP2 = (procesuiranjenazivsudanalog2?.gen !== undefined ? "izdat je od strane " + (procesuiranjenazivsudanalog2?.gen || "__________") : "izdao je " + (procesuiranjenazivsudanalog2?.nom || "__________"));
  const nazivorganaP1jednakoP2 = (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen ? "izdati su od strane " + ((procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) ? (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) : "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));

  const {
    izvrsenjepresuda: izvrsenjepresuda1,
    izvrsenjenazivsuda: izvrsenjenazivsuda1, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd1,
    izvrsenjekazna: izvrsenjekazna1,   // {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja1,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga1,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog1, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[0];

  //OBRUVODizspisa var/s
  const nazivsudaI1 = (izvrsenjenazivsuda1.gen !== undefined ? (izvrsenjenazivsuda1.gen || "__________") + ", " + (DRZMOLITELJICA.ime || "__________") : "koju je izrekao " + (izvrsenjenazivsuda1.nom || "__________") + ", " + (DRZMOLITELJICA.ime || "__________"));
  const kdikaznaI1 = (izvrsenjekazna1.preostalidio !== undefined ? "zbog počinjenog krivičnog djela " + (izvrsenjekd1 || "_________") + " na kaznu zatvora u ukupnom trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________") + " pri čemu preostali neizdržani dio izrečene kazne iznosi " + (izvrsenjekazna1.preostalidio || "__________") : "na kaznu zatvora u trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________") + " zbog počinjenog krivičnog djela " + (izvrsenjekd1 || "_________"));
  const nazivorganaI1 = (izvrsenjenazivsudanalog1.gen !== undefined ? "izdat je od strane " + (izvrsenjenazivsudanalog1.gen || "__________") : "izdao je " + (izvrsenjenazivsudanalog1.nom || "__________"));

  const {
    izvrsenjepresuda: izvrsenjepresuda2,
    izvrsenjenazivsuda: izvrsenjenazivsuda2, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd2,
    izvrsenjekazna: izvrsenjekazna2,   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja2,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga2,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog2, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[1] || {};

  const nazivsudaI2 = (izvrsenjenazivsuda2?.gen !== undefined ? (izvrsenjenazivsuda2?.gen || "__________") + ", " + (DRZMOLITELJICA.ime || "__________") : "koju je izrekao " + (izvrsenjenazivsuda2?.nom || "__________") + ", " + (DRZMOLITELJICA.ime || "__________"));
  const kdikaznaI2 = (izvrsenjekazna2?.preostalidio !== undefined ? "zbog počinjenog krivičnog djela " + (izvrsenjekd2 || "_________") + " i na kaznu zatvora u ukupnom trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________") + " pri čemu preostali neizdržani dio izrečene kazne iznosi " + (izvrsenjekazna2?.preostalidio || "__________") : " i na kaznu zatvora u trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________") + " zbog počinjenog krivičnog djela " + (izvrsenjekd2 || "_________"));
  const nazivorganaI2 = (izvrsenjenazivsudanalog2?.gen !== undefined ? "izdat je od strane " + (izvrsenjenazivsudanalog2?.gen || "__________") : "izdao je " + (izvrsenjenazivsudanalog2?.nom || "__________"));
  const nazivorganaI1jednakoI2 = (izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen ? "izdati su od strane " + ((izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen) ? (izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen) : "__________") : "izdao je " + (izvrsenjenazivsudanalog1.nom || "__________"));

  const nazivorganaP1jednakoI1 = (procesuiranjenazivsudanalog1.gen || izvrsenjenazivsudanalog1.gen ? "izdati su od strane " + ((procesuiranjenazivsudanalog1.gen || izvrsenjenazivsudanalog1.gen) ? (procesuiranjenazivsudanalog1.gen || izvrsenjenazivsudanalog1.gen) : "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));


  /*
    GENERALNI
         razlozi          -  "u cilju krivičnog gonjenja ...
         ncb1ncb2         -  "NCB Interpola DRZMOLITELJICA.nazivinterpola, odnosno NCB Interpola Sarajevo ...
         nalogzahapsenje  -  "Nalog za hapšenje, broj: ___, na osnovu kojeg je raspisana međunarodna potjernica izdao je ...

    UVODNI DIO 
        UVODstranke

    OBRAZLOŽENJE
        izspisa  
        izspisa2
        OBRUVODstranke
        EKSneupucujena394
    
    */

  //MZ PUTOVANJE
  const MZputovanjeSadrzaj = () => {
    //Izdvoji pasoše u koje je Sud IZVRŠIO UVID (nalaze se kod sudske ili u spisu)
    const MZsveoduzeteISPR = MZpasosi.filter(
      (dokument) => dokument.uvid === true
    )
      .map(function (dokument, index) {
        return (
          (dokument.drzavaizdavanja.gen || "__________") +
          " broj: " +
          (dokument.brojdokumenta || "__________")
        );
      })
      .join(", ")
      .replace(/, ([^,]*)$/, " i $1");

    const MZsveoduzeteISPRbroj = MZpasosi.filter(
      (dokument) => dokument.uvid === true
    ).length;

    //Izdvoji pasoše u koje je Sud NIJE IZVRŠIO UVID ({potraživani} ih posjeduje prema sadržaju spisa)
    const MZsveNEoduzeteISPR = MZpasosi.filter(
      (dokument) => dokument.uvid === false
    )
      .map(function (dokument, index) {
        return (
          (dokument.drzavaizdavanja.gen || "__________") +
          " broj: " +
          (dokument.brojdokumenta || "__________")
        );
      })
      .join(", ")
      .replace(/, ([^,]*)$/, " i $1");
    const MZsveNEoduzeteISPRbroj = MZpasosi.filter(
      (dokument) => dokument.uvid === false
    ).length;

    return (
      <>
        {/*  nema dokumenata       nije drzavljanin */}
        {MZpasosi.length === 0 && DrzavljaninBiH === "NE" ? (
          <>
            zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
            2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
            isprava {potraživanog} ukoliko iste posjuduje ({potraživani} se
            obavezuje dostaviti putne isprave Sudu BiH u roku od 2 /dva/ dana od
            dana prijema ovog rješenja), zabranom izdavanja novih putnih isprava
            kao i zabranom korištenja lične karte za prelazak državne granice, a
            koje mjere će biti evidentirane u informacioni sistem Granične
            policije BiH
          </>
        ) : /*  nema dokumenata       drzavljanin */
          MZpasosi.length === 0 && DrzavljaninBiH === "DA" ? (
            <>
              zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
              2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
              isprava {potraživanog} ukoliko iste posjuduje ({potraživani} se
              obavezuje dostaviti putne isprave Sudu BiH u roku od 2 /dva/ dana od
              dana prijema ovog rješenja), zabranom izdavanja novih putnih isprava
              kao i zabranom korištenja lične karte za prelazak državne granice, a
              koje mjere će biti evidentirane u informacioni sistem Granične
              policije BiH. Nadležnom organu za izdavanje ličnih karti nalaže se
              postupanje u skladu s članom 18. Zakona o ličnoj karti državljana
              Bosne i Hercegovine i odredbama Pravilnika o upisivanju zabrane za
              prelazak državne granice u ličnu kartu državljana BiH
            </>
          ) : /* ima dokumenata nije drzavljanin, imaoduzetih, nemaNEoduzetih   */
            MZpasosi.length > 0 &&
              DrzavljaninBiH === "NE" &&
              MZsveoduzeteISPRbroj > 0 &&
              MZsveNEoduzeteISPRbroj === 0 ? (
              <>
                zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                isprava na način da se od {potraživanog} privremeno{" "}
                {MZsveoduzeteISPRbroj === 1 ? (
                  <>
                    oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će
                    biti deponovana
                  </>
                ) : (
                  <>
                    oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave
                    će biti deponovane
                  </>
                )}{" "}
                u službu za čuvanje privremeno oduzetih predmeta Suda BiH.
                {Potraživani} se obavezuje da dostavi Sudu i sve druge putne isprave
                ukoliko iste posjuduje u roku od 2 (dva) dana od dana prijema ovog
                rješenja. U okviru mjere zabrane putovanja {potraživanom} se
                zabranjuje i izdavanje novih putnih isprava {potraživanom} kao i
                korištenje lične karte za prelazak državne granice, a koje mjere će
                biti evidentirane u informacioni sistem Granične policije BiH
              </>
            ) : /*   ima dokumenata    nije drzavljanin, imaoduzetih, ima neoduzetih  */
              MZpasosi.length > 0 &&
                DrzavljaninBiH === "NE" &&
                MZsveoduzeteISPRbroj > 0 &&
                MZsveNEoduzeteISPRbroj > 0 ? (
                <>
                  zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                  2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                  isprava na način da se od {potraživanog} privremeno{" "}
                  {MZsveoduzeteISPRbroj === 1 ? (
                    <>
                      oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će
                      biti deponovana
                    </>
                  ) : (
                    <>
                      oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave
                      će biti deponovane
                    </>
                  )}{" "}
                  u službu za čuvanje privremeno oduzetih predmeta Suda BiH.
                  {Potraživani} se obavezuje da dostavi Sudu i{" "}
                  {MZsveNEoduzeteISPRbroj === 1 ? (
                    <>putnu ispravu {MZsveNEoduzeteISPR}</>
                  ) : (
                    <>putne isprave {MZsveNEoduzeteISPR}</>
                  )}{" "}
                  kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana
                  od dana prijema ovog rješenja. U okviru mjere zabrane putovanja
                  {potraživanom} se zabranjuje i izdavanje novih putnih isprava
                  {potraživanom} kao korištenja lične karte za prelazak državne granice,
                  a koje mjere će biti evidentirane u informacioni sistem Granične
                  policije BiH
                </>
              ) : /*  ima dokumenata  nije drzavljanin, nemaoduzetih, ima neoduzetih  */
                MZpasosi.length > 0 &&
                  DrzavljaninBiH === "NE" &&
                  MZsveoduzeteISPRbroj === 0 &&
                  MZsveNEoduzeteISPRbroj > 0 ? (
                  <>
                    zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                    2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                    isprava ({potraživani} obavezuje da dostavi Sudu{" "}
                    {MZsveNEoduzeteISPRbroj === 1 ? (
                      <>putnu ispravu {MZsveNEoduzeteISPR}</>
                    ) : (
                      <>putne isprave {MZsveNEoduzeteISPR}</>
                    )}
                    , kao i sve druge putne isprave koje posjuduje u roku od 2 /dva/
                    dana od dana prijema ovog rješenja), zabranom izdavanja novih putnih
                    isprava kao i zabranom korištenja lične karte za prelazak državne
                    granice, a koje mjere će biti evidentirane u informacioni sistem
                    Granične policije BiH
                  </>
                ) : /*  ima dokumenata  jest drzavljanin, imaoduzetih, nemaNEoduzetih  */
                  MZpasosi.length > 0 &&
                    DrzavljaninBiH === "DA" &&
                    MZsveoduzeteISPRbroj > 0 &&
                    MZsveNEoduzeteISPRbroj === 0 ? (
                    <>
                      zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                      2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                      isprava na način da se od {potraživanog} privremeno{" "}
                      {MZsveoduzeteISPRbroj === 1 ? (
                        <>
                          oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će
                          biti deponovana
                        </>
                      ) : (
                        <>
                          oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave
                          će biti deponovane
                        </>
                      )}{" "}
                      u službu za čuvanje privremeno oduzetih predmeta Suda BiH.
                      {Potraživani} se obavezuje da dostavi Sudu i sve druge putne isprave
                      ukoliko iste posjuduje u roku od 2 (dva) dana od dana prijema ovog
                      rješenja. U okviru mjere zabrane putovanja {potraživanom} se
                      zabranjuje i izdavanje novih putnih isprava kao i korištenje lične
                      karte za prelazak državne granice, a koje mjere će biti evidentirane
                      u informacioni sistem Granične policije BiH. Nadležni organ za
                      izdavanje ličnih karti će postupiti u skladu s članom 18. Zakona o
                      ličnoj karti državljana Bosne i Hercegovine i odredbama Pravilnika o
                      upisivanju zabrane za prelazak državne granice u ličnu kartu
                      državljana BiH
                    </>
                  ) : /*  ima dokumenata  jest drzavljanin, imaoduzetih, ima neoduzetih  */
                    MZpasosi.length > 0 &&
                      DrzavljaninBiH === "DA" &&
                      MZsveoduzeteISPRbroj > 0 &&
                      MZsveNEoduzeteISPRbroj > 0 ? (
                      <>
                        zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                        2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                        isprava na način da se od {potraživanog} privremeno{" "}
                        {MZsveoduzeteISPRbroj === 1 ? (
                          <>
                            oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će
                            biti deponovana
                          </>
                        ) : (
                          <>
                            oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave
                            će biti deponovane
                          </>
                        )}{" "}
                        u službu za čuvanje privremeno oduzetih predmeta Suda BiH.
                        {Potraživani} se obavezuje da dostavi Sudu i{" "}
                        {MZsveNEoduzeteISPRbroj === 1 ? (
                          <>putnu ispravu {MZsveNEoduzeteISPR}</>
                        ) : (
                          <>putne isprave {MZsveNEoduzeteISPR}</>
                        )}{" "}
                        kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana
                        od dana prijema ovog rješenja. U okviru mjere zabrane putovanja
                        {potraživanom} se zabranjuje i izdavanje novih putnih isprava kao i
                        korištenje lične karte za prelazak državne granice, a koje mjere će
                        biti evidentirane u informacioni sistem Granične policije BiH.
                        Nadležni organ za izdavanje ličnih karti će postupiti u skladu s
                        članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i
                        odredbama Pravilnika o upisivanju zabrane za prelazak državne
                        granice u ličnu kartu državljana BiH
                      </>
                    ) : /*  ima dokumenata  jest drzavljanin, nemaoduzetih, ima neoduzetih  */
                      MZpasosi.length > 0 &&
                        DrzavljaninBiH === "DA" &&
                        MZsveoduzeteISPRbroj === 0 &&
                        MZsveNEoduzeteISPRbroj > 0 ? (
                        <>
                          zabrane putovanja van Bosne i Hercegovine u smislu člana 126. stav
                          2. ZKP BiH koja mjera se ima provesti privremenim oduzimanjem putnih
                          isprava na način da se {potraživani} obavezuje da dostavi Sudu{" "}
                          {MZsveNEoduzeteISPRbroj === 1 ? (
                            <>putnu ispravu {MZsveNEoduzeteISPR}</>
                          ) : (
                            <>putne isprave {MZsveNEoduzeteISPR}</>
                          )}
                          , kao i sve druge putne isprave koje posjuduje u roku od 2 (dva)
                          dana od dana prijema ovog rješenja. U okviru mjere zabrane putovanja
                          {potraživanom} se zabrana i izdavanja novih putnih isprava kao
                          korištenje lične karte za prelazak državne granice, a koje mjere će
                          biti evidentirane u informacioni sistem Granične policije BiH.
                          Nadležni organ za izdavanje ličnih karti će postupiti u skladu s
                          članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i
                          odredbama Pravilnika o upisivanju zabrane za prelazak državne
                          granice u ličnu kartu državljana BiH
                        </>
                      ) : (
                        <></>
                      )}
      </>
    );
  };

  //MZ BORAVIŠTE
  const MZboravisteSadrzaj = () => {
    return (
      <>
        {MZboravistekucni === "NE" ? (
          <>
            zabrane napuštanja boravišta u smislu odredbe člana 126. stav 1. ZKP
            BiH ({MZboravistepodrucje || "__________"}) koje isti ne može
            napustiti bez prethodnog odobrenja Suda, izuzev ukoliko ga napušta
            da bi se pojavio pred Sudom BiH
          </>
        ) : (
          <>
            zabrane napuštanja boravišta (adrese stanovanja -{" "}
            {MZboravisteadresa || "__________"} uz kontrolu poštivanja ove mjere
            zabrane od strane ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}/) u smislu odredbe
            člana 126. stav 1. ZKP BiH koje mjesto boravišta/prebivališta
            {potraživani} ne može napustiti bez prethodnog odobrenja Suda, izuzev
            ukoliko ga napušta da bi se pojavio pred Sudom BiH
          </>
        )}
      </>
    );
  };

  //MZ JAVLJANJE
  const MZjavljanjeSadrzaj = () => {
    return (
      <>
        obaveznog javljanja nadležnom državnom (policijskom) organu iz člana
        126.a stav 1. tačka d) ZKP BiH i to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}) {" "}
        {MZjavljanjenacin || "__________ _________ _________"}
      </>
    );
  };
  // BrojDatum Snipet 
  const [isHovered, setIsHovered] = useState(false);

  const handleDateZoom = () => {
    setIsHovered(true);
  };

  const handleDateZoomOut = () => {
    setIsHovered(false);
  };
  const KalendarBTN = forwardRef(({ value, onClick }, ref) => (
    <Button variant="secondary" onClick={onClick} ref={ref} style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Calendar2Check /> Kalendar
    </Button>

  ));

  return (
    <>
      {props.kod === "BrojDatum" &&
        <>
          <div
            onMouseEnter={handleDateZoom}
            onMouseLeave={handleDateZoomOut}
            style={{position: "relative"}}
          >
            <p style={pL}>
              <strong>Broj: </strong>
              <strong>{brojPredmeta ? brojPredmeta + " " + (vrstaOdluke === "ODB" ? ODBfaza : vrstaOdluke === "MZ" ? MZfaza : vrstaOdluke === "PR" ? PRfaza : vrstaOdluke === "PREKS" ? PREKSfaza : vrstaOdluke === "PRO" ? PROfaza : vrstaOdluke === "EKS" ? EKSfaza : "") : ""}</strong>
              <br />
              <strong>Sarajevo, </strong>
              <strong>{datumodluke}</strong>
            </p>
            {isHovered && (
              <div>

                <ButtonGroup
                  vertical
                  size="sm"
                  style={{
                    position: "absolute",
                    top: "-12px",
                    left: "32%",
                    width: "160px"
                  }}
                >
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", vrstaOdluke, "+")}>
                    <Plus />  Datum gore
                  </Button>
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", vrstaOdluke, "-")}>
                    <Dash />  Datum dole
                  </Button>
                  <DatePicker
                    dateFormat="dd.MM.yyyy."
                    selected={new Date(datumodlukeTMP)}
                    onChange={(date) => inputChange('izmjenadatuma', vrstaOdluke, "kalendar", date)}
                    locale="bs"
                    customInput={<KalendarBTN />}
                  />
                </ButtonGroup>

                <ButtonGroup
                  vertical
                  size="sm"
                  style={{
                    position: "absolute",
                    top: "-12px",
                    left: "54%",
                    width: "160px"
                  }}
                >
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", vrstaOdluke, "+")}>
                    <Plus />  Faza gore
                  </Button>
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", vrstaOdluke, "-")}>
                    <Dash />  Faza dole
                  </Button>

                </ButtonGroup>



              </div>
            )}
          </div>
        </>}

      {props.kod === "BrojDatumDNA" &&
        <>
          <div
            onMouseEnter={handleDateZoom}
            onMouseLeave={handleDateZoomOut}
            style={{position: "relative"}}
          >
            <p style={{ textAlign: "left" }}>
              Broj: {brojPredmeta ? brojPredmeta + (vrstaOdluke === "ODB" ? ODBfaza : vrstaOdluke === "MZ" ? MZfaza : vrstaOdluke === "PR" ? PRfaza : vrstaOdluke === "PREKS" ? PREKSfaza : vrstaOdluke === "PRO" ? PROfaza : vrstaOdluke === "EKS" ? EKSfaza : "") : ""}
              <br />
              Sarajevo, {datumodluke}
            </p>
            {isHovered && (
              <div>
                <ButtonGroup
                  vertical
                  size="sm"
                  style={{
                    position: "absolute",
                    top: "-12px",
                    left: "32%",
                    width: "160px",
                    fontFamily: "Arial"
                  }}
                >
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", vrstaOdluke, "+")}>
                    <Plus />  Datum gore
                  </Button>
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", vrstaOdluke, "-")}>
                    <Dash />  Datum dole
                  </Button>
                  <DatePicker
                    dateFormat="dd.MM.yyyy."
                    selected={new Date(datumodlukeTMP)}
                    onChange={(date) => inputChange('izmjenadatuma', vrstaOdluke, "kalendar", date)}
                    locale="bs"
                    customInput={<KalendarBTN />}
                  />
                </ButtonGroup>

                <ButtonGroup
                  vertical
                  size="sm"
                  style={{
                    position: "absolute",
                    top: "-12px",
                    left: "54%",
                    width: "160px",
                    fontFamily: "Arial"
                  }}
                >
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", vrstaOdluke, "+")}>
                    <Plus />  Faza gore
                  </Button>
                  <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", vrstaOdluke, "-")}>
                    <Dash />  Faza dole
                  </Button>

                </ButtonGroup>

              </div>
            )}
          </div>
        </>}

      {props.kod === "MZobrazlozenje" && (
        <>
          {MZputovanje && !MZboraviste && !MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZputovanjeSadrzaj />
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečena mjera zabrane putovanja u smislu člana 126. stav 2.
                  Zakona o krivičnom postupku BiH
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izriče mjera <strong>zabrane putovanja</strong> u smislu člana
                  126. stav 2. ZKP BiH
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se Granična policija
                  Bosne i Hercegovine, a iste mogu trajati dok za tim postoji
                  potreba, a najduže do okončanja ovog ekstradiconog postupka
                  ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : MZputovanje && MZboraviste && !MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZputovanjeSadrzaj />.
                  Kao dodatna mjera {potraživanom} je izrečena mjera{" "}
                  <MZboravisteSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečene mjere zabrane i to mjera zabrane putovanja u smislu
                  člana 126. stav 2. Zakona o krivičnom postupku BiH (ZKP BiH) i
                  mjera zabrane napuštanja boravišta u smislu odredbe člana 126.
                  stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}) )
                    </>
                  )}
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izriču mjere zabrane i to mjera{" "}
                  <strong>zabrane putovanja</strong> u smislu člana 126. stav 2.
                  ZKP BiH i mjera <strong>zabrane napuštanja boravišta</strong>{" "}
                  u smislu odredbe člana 126. stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se Granična policija
                  Bosne i Hercegovine kao i ovlaštena službena lica{" "}
                  policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za tim
                  postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : MZputovanje && MZboraviste && MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZputovanjeSadrzaj />.
                  Nadalje, {potraživanom} se izriče i mjera <MZboravisteSadrzaj />
                  . Kao dodatna mjera, {potraživanom} je izrečena i mjera{" "}
                  <MZjavljanjeSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečene mjere zabrane i to mjera zabrane putovanja u smislu
                  člana 126. stav 2. Zakona o krivičnom postupku BiH (ZKP BiH),
                  mjera zabrane napuštanja boravišta u smislu odredbe člana 126.
                  stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                  , kao i mjera obaveznog javljanja nadležnom državnom
                  (policijskom) organu iz člana 126.a stav 1. tačka d) ZKP BiH i
                  to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izriču mjere zabrane i to mjera{" "}
                  <strong>zabrane putovanja</strong> u smislu člana 126. stav 2.
                  ZKP BiH, mjera <strong>zabrane napuštanja boravišta</strong>u
                  smislu odredbe člana 126. stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                  , kao i mjera <strong>obaveznog javljanja</strong> nadležnom
                  državnom (policijskom) organu iz člana 126.a stav 1. tačka d)
                  ZKP BiH i to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se Granična policija
                  Bosne i Hercegovine kao i ovlaštena službena lica{" "}
                  policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za tim
                  postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : !MZputovanje && MZboraviste && !MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZboravisteSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečena mjera zabrane napuštanja boravišta u smislu odredbe
                  člana 126. stav 1. Zakona o krivičnom postupku BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izrečena mjera <strong>zabrane napuštanja boravišta</strong> u
                  smislu odredbe člana 126. stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se ovlaštena službena
                  lica policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za
                  tim postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : !MZputovanje && MZboraviste && MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZboravisteSadrzaj />.
                  Kao dodatna mjera {potraživanom} je izrečena mjera{" "}
                  <MZjavljanjeSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečene mjere zabrane i to mjera zabrane napuštanja boravišta
                  u smislu odredbe člana 126. stav 1. Zakona o krivičnom
                  postupku BiH (ZKP BiH){" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                  , kao i mjera obaveznog javljanja nadležnom državnom
                  (policijskom) organu iz člana 126.a stav 1. tačka d) ZKP BiH i
                  to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izrečene mjere zabrane i to mjera{" "}
                  <strong>zabrane napuštanja boravišta</strong>u smislu odredbe
                  člana 126. stav 1. ZKP BiH{" "}
                  {MZboravistekucni === "NE" ? (
                    <>({MZboravistepodrucje || "__________"})</>
                  ) : (
                    <>
                      (adrese stanovanja - {MZboravisteadresa || "__________"}{" "}
                      uz kontrolu poštivanja ove mjere zabrane od strane{" "}
                      ovlaštenih službenih lica policijske stanice {MZstanica.nazivstanice || "__________"} /MUP {MZstanica.tag || "__________"},{MZstanica.nazivuprave || "__________"}/)
                    </>
                  )}
                  , kao i mjera <strong>obaveznog javljanja</strong> nadležnom
                  državnom (policijskom) organu iz člana 126.a stav 1. tačka d)
                  ZKP BiH i to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se ovlaštena službena
                  lica policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za
                  tim postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : !MZputovanje && !MZboraviste && MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZjavljanjeSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečena mjera obaveznog javljanja nadležnom državnom
                  (policijskom) organu u smislu člana 126.a stav 1. tačka d)
                  Zakona o krivičnom postupku BiH (ZKP BiH) i to{" "}
                  policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izrečena mjera <strong>obaveznog javljanja</strong> nadležnom
                  državnom (policijskom) organu u smislu člana 126.a stav 1.
                  tačka d) ZKP BiH i to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se ovlaštena službena
                  lica policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za
                  tim postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : MZputovanje && !MZboraviste && MZjavljanje ? (
            <>
              {props.tip === "opis" && (
                <>
                  Tako je {potraživanom} izrečena mjera <MZputovanjeSadrzaj />.
                  Kao dodatna mjera, {potraživanom} je izrečena mjera{" "}
                  <MZjavljanjeSadrzaj />.
                </>
              )}
              {props.tip === "opisskraceno" && (
                <>
                  izrečene mjere zabrane i to mjera zabrane putovanja u smislu
                  člana 126. stav 2. Zakona o krivičnom postupku BiH (ZKP BiH)
                  kao i mjera obaveznog javljanja nadležnom državnom
                  (policijskom) organu iz člana 126.a stav 1. tačka d) ZKP BiH i
                  to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "opisskracenodopis" && (
                <>
                  izrečene mjere zabrane i to mjera{" "}
                  <strong>zabrane putovanja</strong> u smislu člana 126. stav 2.
                  ZKP BiH kao i mjera <strong>obaveznog javljanja</strong>
                  nadležnom državnom (policijskom) organu iz člana 126.a stav 1.
                  tačka d) ZKP BiH i to policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"})
                </>
              )}
              {props.tip === "organ" && (
                <>
                  O izvršenju izrečenih mjera starat će se Granična policija
                  Bosne i Hercegovine kao i ovlaštena službena lica{" "}
                  policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"}, {MZstanica.nazivuprave || "__________"}), a iste mogu trajati dok za tim
                  postoji potreba, a najduže do okončanja ovog ekstradiconog
                  postupka ili drugacije odluke Suda, s tim da će se kontrola
                  opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva)
                  mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.
                </>
              )}
            </>
          ) : (
            <>
              <Alert variant="warning alertV2">
                Preciziraj mjere zabrane koje se izriču {potraživanom}.
              </Alert>
            </>
          )}
        </>
      )}

      {props.kod === "razlozi" &&
        <>
          {procesuiranje === true && izvrsenje === false
            ?
            <>
              {procesuiranjeInfo.length === 1 &&
                <>
                  vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio}
                  {" "}krivično djelo {procesuiranjekd1 || "_________"}
                </>
              }
              {procesuiranjeInfo.length === 2 &&
                <>
                  vođenja krivičnih postupaka pred nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"} {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                  {" "}{procesuiranjekd1 || "_________"} te krivično djelo {procesuiranjekd2 || "_________"}
                </> //P2 
              }
            </>
            :
            procesuiranje === false && izvrsenje === true
              ?
              <>
                {izvrsenjeInfo.length === 1 &&
                  <>
                    izvršenja
                    {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                    {" "}
                    {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                  </> //I1
                }

                {izvrsenjeInfo.length === 2 &&
                  <>
                    {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                      ?
                      <>
                        {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen
                          ?
                          <>
                            izvršenja
                            {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                            {" "}kao i
                            {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                            {" "}koje kazne su potraživanom izrečene presudama {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}, broj: {izvrsenjepresuda1 || "__________"} i  broj: {izvrsenjepresuda2 || "__________"}
                          </>
                          :
                          <>
                            izvršenja
                            {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                            {" "}kao i
                            {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                            {" "}koje kazne je potraživanom izrekao {izvrsenjenazivsuda1.nom || "__________"} presudama broj: {izvrsenjepresuda1 || "__________"} i broj: {izvrsenjepresuda2 || "__________"}
                          </>
                        }

                      </>
                      :
                      <>
                        izvršenja
                        {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                        {" "}
                        {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"},` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                        {" "}kao i izvršenja
                        {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                        {" "}
                        {izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}
                      </>
                    }
                  </> //I2
                }
              </>
              :
              procesuiranje === true && izvrsenje === true
                ?
                <>

                  {procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1 &&
                    <>
                      {props.tip === "uvod"
                        ?
                        <>
                          vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} i izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}`}
                        </>
                        :
                        <>
                          vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} kao i izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}
                          {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                        </>}

                    </>
                    /*P1 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1 &&
                    <>
                      {props.tip === "uvod"
                        ?
                        <>
                          vođenja krivičnih postupaka pred nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"} {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} i krivično djelo {procesuiranjekd2 || "_________"} te izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}`}
                        </>
                        :
                        <>
                          vođenja krivičnih postupaka pred nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"} {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} i krivično djelo {procesuiranjekd2 || "_________"} te izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}
                          {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom, ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                        </>}
                    </>
                    /*P2 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2 &&
                    <>
                      {props.tip === "uvod"
                        ?
                        <>
                          vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} i izvršenja
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                            ?
                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}kao i
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}koje kazne su {potraživanom} izrečene presudama nadležnog suda {DRZMOLITELJICA.gen || "_________"}
                            </>
                            :
                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}kao i
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}koje kazne su {potraživanom} izrečene presudama nadležnih sudova {DRZMOLITELJICA.gen || "_________"}
                            </>
                          }

                        </>
                        :
                        <>
                          vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"}.
                          {" "}
                          Međunarodna potjernica je, osim navedenog, raspisana i sa ciljem izvršenja
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                            ?
                            <>
                              {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen
                                ?
                                <>
                                  {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}kao i
                                  {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}koje kazne su potraživanom izrečene presudama {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}, broj: {izvrsenjepresuda1 || "__________"} i  broj: ${izvrsenjepresuda2 || "__________"}
                                </>
                                :
                                <>
                                  {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}kao i
                                  {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}koje kazne je potraživanom izrekao {izvrsenjenazivsuda1.nom || "__________"} presudama broj: {izvrsenjepresuda1 || "__________"} i broj: {izvrsenjepresuda2 || "__________"}
                                </>
                              }
                            </>
                            :
                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}
                              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"},` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                              {" "}kao i izvršenja
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                              {" "}
                              {izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}
                            </>
                          }
                        </>}

                    </>
                    /*P1 I2*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2 &&
                    <>
                      {props.tip === "uvod"
                        ?
                        <>
                          vođenja krivičnih postupaka pred nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"} {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} i krivično djelo {procesuiranjekd2 || "_________"} kao i izvršenja
                          {" "}
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                            ?

                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}i
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                              {" "}koje kazne su {potraživanom} izrečene presudom nadležnog suda {DRZMOLITELJICA.gen || "_________"}
                            </>
                            :
                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}i
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                              {" "}koje kazne su {potraživanom} izrečene presudama nadležnih sudova {DRZMOLITELJICA.gen || "_________"}
                            </>
                          }
                        </>
                        :
                        <>
                          vođenja krivičnih postupaka pred nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"} {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} te krivično djelo {procesuiranjekd2 || "_________"}.
                          {" "}
                          Osim navedenog, međunarodna potjernica je, dodatno, raspisana i sa ciljem izvršenja
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom
                            ?
                            <>
                              {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen
                                ?
                                <>
                                  {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}kao i
                                  {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                                  {" "}koje kazne su potraživanom izrečene presudama {izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}, broj: {izvrsenjepresuda1 || "__________"} i  broj: ${izvrsenjepresuda2 || "__________"}
                                </>
                                :
                                <>
                                  {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                                  {" "}kao i
                                  {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                                  {" "}koje kazne je potraživanom izrekao {izvrsenjenazivsuda1.nom || "__________"} presudama broj: {izvrsenjepresuda1 || "__________"} i broj: {izvrsenjepresuda2 || "__________"}
                                </>
                              }

                            </>
                            :
                            <>
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                              {" "}
                              {izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda1 || "__________"},` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda1 || "__________"}`}
                              {" "}kao i izvršenja
                              {" "}{izvrsenjekazna2.preostalidio !== undefined ? `preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                              {" "}
                              {izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, broj: ${izvrsenjepresuda2 || "__________"}` : `koju je ${potraživanom} izrekao ${izvrsenjenazivsuda2.nom || "__________"}, ${DRZMOLITELJICA.ime || "__________"}, presudom, broj: ${izvrsenjepresuda2 || "__________"}`}
                            </>
                          }
                        </>}

                    </>
                    /*P2 I2*/
                  }

                </> // I+P
                :
                <>
                  vođenja krivičnog postupka pred nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                  {" "}{procesuiranjekd1 || "_________"}
                </>}

        </>}

      {props.kod === "ncb1ncb2" && (
        <>
          NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"},{" "}
          {DRZMOLITELJICA.ime || "__________"}, broj: {ncb2 || "_________"},
          odnosno {props.ekvivalentnoj === "DA" ? "ekvivalentnoj potjernici NCB Interpola Sarajevo" : props.ekvivalentne === "DA" ? "ekvivalentne potjernice NCB Interpola Sarajevo" : <>{props.veznik === "potjernice" ? "potjernice" : "potjernici"} Interpola NCB Sarajevo, broj: {ncb1 || "_________"}</>}
        </>
      )}

      {props.kod === "nalogzahapsenje" &&
        <>
          {procesuiranje === true && izvrsenje === false
            ?
            <>
              {procesuiranjeInfo.length === 1 &&
                <>
                  Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"}, na osnovu kojeg{" "}
                  {props.tip === "2" ? `je zbog nemogućnosti ${njegovog} krivičnog procesuiranja` : 'je'} raspisana međunarodna potjernica
                  {" "}{nazivorganaP1}.
                </> //P1   ... radi osiguranja potraživanog u krivičnom postupku koji se vodi protiv njega //IZSPISA2
              }

              {procesuiranjeInfo.length === 2 &&
                <>
                  {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom
                    ?
                    <>
                      {procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {procesuiranjebrojnaloga1 === procesuiranjebrojnaloga2 ? `broj: ${procesuiranjebrojnaloga1 || "_________"}` : `broj: ${procesuiranjebrojnaloga1 || "_________"} i broj: ${procesuiranjebrojnaloga2 || "_________"}`},
                      {" "}{props.tip === "2" ? `na osnovu kojih je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                      {" "}{nazivorganaP1jednakoP2}.
                    </>
                    :
                    <>
                      Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"},{" "}
                      {props.tip === "2" ? `na osnovu kojeg je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                      {" "}{nazivorganaP1}, a nalog za hapšenje, broj: {procesuiranjebrojnaloga2 || "_________"}, {nazivorganaP2}.
                    </>}

                </> //P2 
              }
            </>
            :
            procesuiranje === false && izvrsenje === true
              ?
              <>
                {izvrsenjeInfo.length === 1 &&
                  <>
                    Nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"},{" "}
                    {props.tip === "2" ? `na osnovu kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                    {" "}{nazivorganaI1}.
                  </> //I1
                }
                {izvrsenjeInfo.length === 2 &&
                  <>
                    {izvrsenjenazivsudanalog1.nom === izvrsenjenazivsudanalog2.nom
                      ?
                      <>
                        {izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {izvrsenjebrojnaloga1 === izvrsenjebrojnaloga2 ? `broj: ${izvrsenjebrojnaloga1 || "_________"}` : `broj: ${izvrsenjebrojnaloga1 || "_________"} i broj: ${izvrsenjebrojnaloga2 || "_________"}`},
                        {" "}{props.tip === "2" ? `na osnovu kojih je zbog nedostupnosti ${potraživanog} u postupku izvršenja izrečenih kazni zatvora raspisana međunarodna` : 'sa ciljem izvršenja izrečenih kazni zatvora'}
                        {" "}{nazivorganaI1jednakoI2}.
                      </>
                      :
                      <>
                        Nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"}, {" "}
                        {props.tip === "2" ? `na osnovu kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                        {" "}{nazivorganaI1}, a nalog za hapšenje, broj: {izvrsenjebrojnaloga2 || "_________"}, {nazivorganaI2}.
                      </>
                    }

                  </> //I2
                }
              </>
              :
              procesuiranje === true && izvrsenje === true
                ?
                <>
                  {procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1 &&
                    <>
                      {procesuiranjenazivsudanalog1.nom === izvrsenjenazivsudanalog1.nom
                        ?
                        <>
                          {procesuiranjenazivsudanalog1.gen || izvrsenjenazivsudanalog1.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {procesuiranjebrojnaloga1 === izvrsenjebrojnaloga1 ? `broj: ${procesuiranjebrojnaloga1 || "_________"}` : `broj: ${procesuiranjebrojnaloga1 || "_________"} i broj: ${izvrsenjebrojnaloga1 || "_________"}`},
                          {" "}{props.tip === "2" ? `na osnovu kojih je zbog nemogućnosti ${njegovog} krivičnog procesuiranja odnosno, nedostupnosti u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja, odnosno izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                          {" "}{nazivorganaP1jednakoI1}.
                        </>
                        :
                        <>
                          Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"},{" "}
                          {props.tip === "2" ? `na osnovu kojeg je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : 'sa ciljem krivičnog procesuiranja'}
                          {" "}{nazivorganaP1}, a nalog za hapšenje, broj: {izvrsenjebrojnaloga1 || "_________"},
                          {" "}{props.tip === "2" ? `zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora` : `radi izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                          {" "}{nazivorganaI1}.
                        </>
                      }

                    </>
                    /*P1 I1*/
                  }

                  {procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1 &&
                    <>
                      {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom
                        ?
                        <>
                          {procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {procesuiranjebrojnaloga1 === procesuiranjebrojnaloga2 ? `broj: ${procesuiranjebrojnaloga1 || "_________"}` : `broj: ${procesuiranjebrojnaloga1 || "_________"} i broj: ${procesuiranjebrojnaloga2 || "_________"}`},
                          {" "}{props.tip === "2" ? `na osnovu kojih je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                          {" "}{nazivorganaP1jednakoP2}.
                          {" "}

                          {procesuiranjenazivsudanalog1.nom === izvrsenjenazivsudanalog1.nom
                            ?
                            `${izvrsenjenazivsudanalog1.nom || "_________"} izdao je i nalog za hapšenje ${potraživanog}, broj: ${izvrsenjebrojnaloga1 || "_________"},${" "}
                          ${props.tip === "2" ? `zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora` : `sa ciljem izvršenja izrečene ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora`}.
                          `
                            :
                            `
                          Nalog za hapšenje ${potraživanog}, broj: ${izvrsenjebrojnaloga1 || "_________"},{" "}
                          ${props.tip === "2" ? `kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora` : `sa ciljem izvršenja izrečene ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora`}
                          {" "}${nazivorganaI1}.
                          `}

                        </>
                        :
                        <>
                          Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"},{" "}
                          {props.tip === "2" ? `na osnovu kojeg je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                          {" "}{nazivorganaP1}, a nalog za hapšenje, broj: {procesuiranjebrojnaloga2 || "_________"}, {nazivorganaP2}.

                          S druge strane, nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"},{" "}
                          {props.tip === "2" ? `zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora ` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""}izrečene kazne zatvora`}
                          {" "}{nazivorganaI1}.
                        </>}
                    </>
                    /*P2 I1*/
                  }

                  {procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2 &&
                    <>
                      Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"},{" "}
                      {props.tip === "2" ? `na osnovu kojeg je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : 'sa ciljem krivičnog procesuiranja'}
                      {" "}{nazivorganaP1}.
                      {" "}
                      {procesuiranjenazivsudanalog1.nom === izvrsenjenazivsudanalog1.nom
                        ?
                        <>
                          {izvrsenjenazivsudanalog1.nom === izvrsenjenazivsudanalog2.nom  //svi isti
                            ?
                            <>
                              {procesuiranjenazivsudanalog1.nom || "_________"} izdao je i naloge za hapšenje {potraživanog}, {izvrsenjebrojnaloga1 === izvrsenjebrojnaloga2 ? `broj: ${izvrsenjebrojnaloga1 || "_________"}` : `broj: ${izvrsenjebrojnaloga1 || "_________"} i broj: ${izvrsenjebrojnaloga2 || "_________"}`},
                              {" "}{props.tip === "2" ? `zbog nedostupnosti ${potraživanog} u postupku izvršenja izrečenih kazni zatvora` : 'sa ciljem izvršenja izrečenih kazni zatvora'}
                              {" "}.
                            </>
                            :
                            <>
                              Nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"}, {" "}
                              {props.tip === "2" ? `na osnovu kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                              {" "}{nazivorganaI1}, a nalog za hapšenje, broj: {izvrsenjebrojnaloga2 || "_________"}, {nazivorganaI2}.
                            </>
                          }
                        </>

                        :
                        <>
                          {izvrsenjenazivsudanalog1.nom === izvrsenjenazivsudanalog2.nom   //P1 nije jednako I1
                            ?
                            <>
                              {izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {izvrsenjebrojnaloga1 === izvrsenjebrojnaloga2 ? `broj: ${izvrsenjebrojnaloga1 || "_________"}` : `broj: ${izvrsenjebrojnaloga1 || "_________"} i broj: ${izvrsenjebrojnaloga2 || "_________"}`},
                              {" "}{props.tip === "2" ? `na osnovu kojih je zbog nedostupnosti ${potraživanog} u postupku izvršenja izrečenih kazni zatvora raspisana međunarodna` : 'sa ciljem izvršenja izrečenih kazni zatvora'}
                              {" "}{nazivorganaI1jednakoI2}.
                            </>
                            :
                            <>
                              Nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"}, {" "}
                              {props.tip === "2" ? `na osnovu kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                              {" "}{nazivorganaI1}, a nalog za hapšenje, broj: {izvrsenjebrojnaloga2 || "_________"}, {nazivorganaI2}.
                            </>
                          }
                        </>
                      }

                    </>
                    /*P1 I2*/
                  }

                  {procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2 &&
                    <>
                      {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom
                        ?
                        <>
                          {procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {procesuiranjebrojnaloga1 === procesuiranjebrojnaloga2 ? `broj: ${procesuiranjebrojnaloga1 || "_________"}` : `broj: ${procesuiranjebrojnaloga1 || "_________"} i broj: ${procesuiranjebrojnaloga2 || "_________"}`},
                          {" "}{props.tip === "2" ? `na osnovu kojih je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                          {" "}{nazivorganaP1jednakoP2}.
                        </>
                        :
                        <>
                          Nalog za hapšenje {potraživanog}, broj: {procesuiranjebrojnaloga1 || "_________"},{" "}
                          {props.tip === "2" ? `na osnovu kojeg je zbog nemogućnosti ${njegovog} krivičnog procesuiranja raspisana međunarodna potjernica` : `sa ciljem krivičnog procesuiranja ${potraživanog}`}
                          {" "}{nazivorganaP1}, a nalog za hapšenje, broj: {procesuiranjebrojnaloga2 || "_________"}, {nazivorganaP2}.
                        </>}
                      {" "}
                      {izvrsenjenazivsudanalog1.nom === izvrsenjenazivsudanalog2.nom
                        ?
                        <>
                          {izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2.gen ? "Nalozi" : "Naloge"} za hapšenje {potraživanog}, {izvrsenjebrojnaloga1 === izvrsenjebrojnaloga2 ? `broj: ${izvrsenjebrojnaloga1 || "_________"}` : `broj: ${izvrsenjebrojnaloga1 || "_________"} i broj: ${izvrsenjebrojnaloga2 || "_________"}`},
                          {" "}{props.tip === "2" ? `na osnovu kojih je zbog nedostupnosti ${potraživanog} u postupku izvršenja izrečenih kazni zatvora raspisana međunarodna` : 'sa ciljem izvršenja izrečenih kazni zatvora'}
                          {" "}{nazivorganaI1jednakoI2}.
                        </>
                        :
                        <>
                          Nalog za hapšenje {potraživanog}, broj: {izvrsenjebrojnaloga1 || "_________"}, {" "}
                          {props.tip === "2" ? `na osnovu kojeg je zbog nedostupnosti ${potraživanog} u postupku izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} kazne zatvora raspisana međunarodna potjernica` : `sa ciljem izvršenja ${izvrsenjekazna1.preostalidio !== undefined ? "preostalog dijela" : ""} izrečene kazne zatvora`}
                          {" "}{nazivorganaI1}, a nalog za hapšenje, broj: {izvrsenjebrojnaloga2 || "_________"}, {nazivorganaI2}.
                        </>
                      }

                    </>
                    /*P2 I2*/
                  }

                </> // I+P
                :
                <>
                  <Alert variant="warning alertV2">
                    Odaberite <strong>razlog izručenja</strong>.
                  </Alert>
                </>}




        </>}





      {props.kod === "UVODstranke" && (
        <>
          {vrstabranioca === "posluzbenoj" && dodajtumaca === false ? (
            <>
              {IMELICA.imegen || "__________"} i {njegovog} branioca po službenoj dužnosti{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}
            </>
          ) : vrstabranioca === "posluzbenoj" && dodajtumaca === true ? (
            <>
              {IMELICA.imegen || "__________"}, {njegovog} branioca po službenoj dužnosti{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} te stalnog sudskog tumača za{" "}
              {jezikprevoda || "_________"} jezik {TUMAC.imegen || "_________"}
            </>
          ) : vrstabranioca === "izabrani" && dodajtumaca === false ? (
            <>
              {IMELICA.ime || "__________"}, {dvabranioca ? <>{njegovih} izabranih branilaca{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</> : <>{njegovog} izabranog branioca{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>}
            </>
          ) : vrstabranioca === "izabrani" && dodajtumaca === true ? (
            <>
              {IMELICA.ime || "__________"}, {dvabranioca ? <>{njegovih} izabranih branilaca{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</> : <>{njegovog} izabranog branioca{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>} te stalnog sudskog tumača za{" "}
              {jezikprevoda || "_________"} jezik {TUMAC.imegen || "_________"}
            </>
          ) : (
            <></>
          )}
        </>
      )}


      {props.kod === "izspisa" &&
        <>
          {procesuiranje === true && izvrsenje === false
            ?
            <>
              {procesuiranjeInfo.length === 1 &&
                <>
                  {vrstaOdluke === "EKS" ?
                    <>
                      Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                      {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                      {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                    </>
                    :
                    <>
                      Iz spisa proizilazi da je protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupak pred {" "}
                      nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                      {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}.
                      {props.tip === "beznaloga" ?
                        <>
                        </>
                        :
                        <>
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip="2" />
                        </>}

                    </>}

                </> //P1 
              }

              {procesuiranjeInfo.length === 2 &&
                <>
                  {vrstaOdluke === "EKS" ?
                    <>
                      Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                      {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                      {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                    </>
                    :
                    <>
                      Iz spisa proizilazi da su protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupci pred {" "}
                      nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"}
                      {" "} {DRZMOLITELJICA.gen || "_________"} i to krivični postupak zbog sumnje da je {počinio} krivično djelo
                      {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivični postupak zbog sumnje da je {počinio} krivično djelo
                      {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}.
                      {props.tip === "beznaloga" ?
                        <>
                        </>
                        :
                        <>
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip="2" />
                        </>}
                    </>}

                </> //P2 
              }
            </>
            :
            procesuiranje === false && izvrsenje === true
              ?
              <>
                {izvrsenjeInfo.length === 1 &&
                  <>
                    {vrstaOdluke === "EKS" ?
                      <>
                        Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                        {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                        {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                      </>
                      :
                      <>
                        Iz spisa proizilazi da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                        {" "}{osuđen} {kdikaznaI1}.
                        {props.tip === "beznaloga" ?
                          <>
                          </>
                          :
                          <>
                            <br /><br />
                            <Snipet kod="nalogzahapsenje" tip="2" />
                          </>}
                      </>}

                  </> //I1
                }
                {izvrsenjeInfo.length === 2 &&
                  <>
                    {vrstaOdluke === "EKS" ?
                      <>
                        Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                        {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                        {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                      </>
                      :
                      <>
                        Iz spisa proizilazi da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                        {" "}{osuđen} {kdikaznaI1}. Osim toga, iz spisa je vidljivo da je {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? (potraživani) + " presudom istog suda, broj: " + (izvrsenjepresuda2 || "_________") + ", dodatno," : (potraživani) + ", dodatno, presudom " + (nazivsudaI2) + ", " + (izvrsenjepresuda2 || "_________") + ","}
                        {" "}{osuđen} {kdikaznaI2}.
                        {props.tip === "beznaloga" ?
                          <>
                          </>
                          :
                          <>
                            <br /><br />
                            <Snipet kod="nalogzahapsenje" tip="2" />
                          </>}
                      </>}
                  </> //I2
                }
              </>
              :
              procesuiranje === true && izvrsenje === true
                ?
                <>
                  {procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                          {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                          {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                        </>
                        :
                        <>
                          Iz spisa proizilazi da je protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupak pred{" "}
                          nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}.

                          Osim toga, iz spisa proizilazi i to da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                          {" "}{osuđen} {kdikaznaI1}.
                          {props.tip === "beznaloga" ?
                            <>
                            </>
                            :
                            <>
                              <br /><br />
                              <Snipet kod="nalogzahapsenje" tip="2" />
                            </>}
                        </>}
                    </>
                    /*P1 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                          {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                          {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                        </>
                        :
                        <>
                          Iz spisa proizilazi da su protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupci pred {" "}
                          nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"}
                          {" "} {DRZMOLITELJICA.gen || "_________"} i to krivični postupak zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivični postupak zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}.

                          Osim toga, iz spisa proizilazi i to da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                          {" "}{osuđen} {kdikaznaI1}.

                          {props.tip === "beznaloga" ?
                            <>
                            </>
                            :
                            <>
                              <br /><br />
                              <Snipet kod="nalogzahapsenje" tip="2" /></>}

                        </>}

                    </>
                    /*P2 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                          {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                          {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                        </>
                        :
                        <>
                          Iz spisa proizilazi da je protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupak pred {" "}
                          nadležnim pravosudnim organom {DRZMOLITELJICA.gen || "_________"} zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}.

                          Osim toga, iz spisa proizilazi i to da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                          {" "}{osuđen} {kdikaznaI1}, a {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? (potraživani) + " je presudom istog suda, broj: " + (izvrsenjepresuda2 || "_________") + ", dodatno," : (potraživani) + " je presudom " + (nazivsudaI2) + ", " + (izvrsenjepresuda2 || "_________") + ", dodatno,"}
                          {" "}{osuđen} {kdikaznaI2}.
                          {props.tip === "beznaloga" ?
                            <>
                            </>
                            :
                            <>
                              <br /><br />
                              <Snipet kod="nalogzahapsenje" tip="2" /></>}

                        </>}
                    </>
                    /*P1 I2*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Iz stanja u spisu, kako je to i naprijed naznačeno, proizilazi da je {potraživani} {lišen} slobode dana {datumlisenja || "_________"}
                          {" "}po međunarodnoj potjernici <Snipet kod="ncb1ncb2" ekvivalentnoj="DA" />, raspisanoj u cilju lišenja slobode i izručenja {potraživanog} pravosudnim organima
                          {" "}{DRZMOLITELJICA.gen || "_________"} te <Snipet kod="razlozi" />.
                        </>
                        :
                        <>
                          Iz spisa proizilazi da su protiv {potraživanog} {IMELICA.imegen || "_________"} u toku krivični postupci pred {" "}
                          nadležnim pravosudnim {procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2.nom ? "organom" : "organima"}
                          {" "} {DRZMOLITELJICA.gen || "_________"} i to krivični postupak zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivični postupak zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}.

                          Osim toga, iz spisa proizilazi i to da je {potraživani} {IMELICA.ime || "_________"} presudom {nazivsudaI1}, broj: {izvrsenjepresuda1 || "_________"},
                          {" "}{osuđen} {kdikaznaI1}, a {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? (potraživani) + " je presudom istog suda, broj: " + (izvrsenjepresuda2 || "_________") + ", dodatno," : (potraživani) + " je presudom " + (nazivsudaI2) + ", " + (izvrsenjepresuda2 || "_________") + ", dodatno,"}
                          {" "}{osuđen} {kdikaznaI2}.
                          {props.tip === "beznaloga" ?
                            <>
                            </>
                            :
                            <>
                              <br /><br />
                              <Snipet kod="nalogzahapsenje" tip="2" /></>}
                        </>}
                    </>
                    /*P2 I2*/
                  }

                </> // I+P
                :
                <>
                  <Alert variant="warning alertV2">
                    Odaberite <strong>razlog izručenja</strong>.
                  </Alert>
                </>}
        </>}


      {props.kod === "izspisa2" &&
        <>
          {procesuiranje === true && izvrsenje === false
            ?
            <>
              {procesuiranjeInfo.length === 1 &&
                <>
                  {vrstaOdluke === "EKS" ?
                    <>
                      Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi{" "}
                      {DRZMOLITELJICA.gen || "_________"} vode krivični postupak protiv {potraživanog} zbog postojanja sumnje da je
                      {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.
                    </>
                    :
                    <>
                      Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                      {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnom postupku koji se vodi zbog sumnje da je {počinio} krivično djelo
                      {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}.
                      <br /><br />
                      <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                    </>}

                </> //P1 
              }

              {procesuiranjeInfo.length === 2 &&
                <>
                  {vrstaOdluke === "EKS" ?
                    <>
                      Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi{" "}
                      {DRZMOLITELJICA.gen || "_________"} vode krivične postupke protiv {potraživanog} zbog postojanja sumnje da je
                      {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo
                      {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}.
                    </>
                    :
                    <>
                      Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                      {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnim postupcima koji se vode zbog sumnje da je {počinio} krivično djelo
                      {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}.
                      <br /><br />
                      <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                    </>}

                </> //P2 
              }
            </>
            :
            procesuiranje === false && izvrsenje === true
              ?
              <>
                {izvrsenjeInfo.length === 1 &&
                  <>
                    {vrstaOdluke === "EKS" ?
                      <>
                        Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"}
                        {" "}inicirali postupak izvršenja pravosnažno izrečene {izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                      </>
                      :
                      <>
                        Analizom sadržaja međunarodne potjernice vidljivo je da je potraga raspisana sa ciljem lišenja slobode {potraživanog},
                        {" "}odnosno osiguranja {njegovog} prisustva u postupku izvršenja
                        {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                        <br /><br />
                        <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                      </>}

                  </> //I1
                }
                {izvrsenjeInfo.length === 2 &&
                  <>
                    {vrstaOdluke === "EKS" ?
                      <>
                        Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji, vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"}
                        {" "}inicirali postupak izvršenja pravosnažno izrečenih kazni zatvora i to {izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                        {" "}kao i {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                      </>
                      :
                      <>
                        Analizom sadržaja međunarodne potjernice vidljivo je da je potraga raspisana sa ciljem lišenja slobode {potraživanog},
                        {" "}odnosno osiguranja {njegovog} prisustva u postupku izvršenja
                        {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                        {" "}kao i {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                        {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                        <br /><br />
                        <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                      </>}
                  </> //I2
                }
              </>
              :
              procesuiranje === true && izvrsenje === true
                ?
                <>
                  {procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi{" "}
                          {DRZMOLITELJICA.gen || "_________"} vode krivični postupak protiv {potraživanog} zbog postojanja sumnje da je
                          {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}. Istovremeno, vidljivo je da je iniciran
                          {" "}i postupak izvršenja pravosnažno izrečene {izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                        </>
                        :
                        <>
                          Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                          {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnom postupku koji se vodi zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}. Istovremeno, vidljivo je da je potraga raspisana i sa ciljem osiguranja
                          {" "}prisustva {potraživanog} u postupku izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                        </>}
                    </>
                    /*P1 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi{" "}
                          {DRZMOLITELJICA.gen || "_________"} vode krivične postupke protiv {potraživanog} zbog postojanja sumnje da je
                          {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo
                          {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}. Osim toga, vidljivo je da su pravosudni organi države moliteljice
                          {" "}inicirali i postupak izvršenja pravosnažno izrečene {izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                        </>
                        :
                        <>
                          Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                          {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnim postupcima koji se vode zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}.
                          {" "}Istovremeno, vidljivo je da je potraga raspisana i sa ciljem osiguranja prisustva {potraživanog} u postupku izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}.
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                        </>}

                    </>
                    /*P2 I1*/
                  }

                  {
                    procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi
                          {" "}{DRZMOLITELJICA.gen || "_________"} vode krivični postupak protiv {potraživanog} zbog postojanja sumnje da je
                          {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1}.
                          {" "}Osim toga, vidljivo je da su pravosudni organi države moliteljice inicirali i postupak izvršenja pravosnažno izrečenih kazni zatvora i to
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                          {" "}kao i {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                        </>
                        :
                        <>
                          Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                          {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnom postupku koji se vodi zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1}.
                          {" "}Istovremeno, vidljivo je da je potraga raspisana i sa ciljem osiguranja prisustva {potraživanog} u postupku izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                          {" "}kao i {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                        </>}
                    </>
                    /*P1 I2*/
                  }

                  {
                    procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2 &&
                    <>
                      {vrstaOdluke === "EKS" ?
                        <>
                          Prema dostavljenoj molbi za izručenje i ekstradicionoj dokumentaciji vidljivo je da pravosudni organi{" "}
                          {DRZMOLITELJICA.gen || "_________"} vode krivične postupke protiv {potraživanog} zbog postojanja sumnje da je
                          {" "}{počinio} krivično djelo {procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo
                          {" "}{procesuiranjekd2 || "_________"} za koje {kaznaP2}. Osim toga, vidljivo je da su pravosudni organi države moliteljice
                          {" "}inicirali i postupak izvršenja pravosnažno izrečenih kazni zatvora i to {izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                          {" "}kao i {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                        </>
                        :
                        <>
                          Analizom sadržaja međunarodne potjernice vidljivo je da su pravosudni organi {DRZMOLITELJICA.gen || "_________"} potragu raspisali sa ciljem lišenja slobode
                          {" "}{potraživanog}, odnosno osiguranja {njegovog} prisustva u krivičnim postupcima koji se vode zbog sumnje da je {počinio} krivično djelo
                          {" "}{procesuiranjekd1 || "_________"} za koje {kaznaP1} kao i krivično djelo {procesuiranjekd2 || "_________"} za koje {kaznaP2}.
                          {" "}Istovremeno, vidljivo je da je potraga raspisana i sa ciljem osiguranja prisustva {potraživanog} u postupku izvršenja
                          {" "}{izvrsenjekazna1.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna1.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna1.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda1.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda1.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd1 || "_________"}, izrekao ${izvrsenjenazivsuda1.nom || "__________"}`}
                          {" "}kao i izvršenja {izvrsenjekazna2.preostalidio !== undefined ? `kazne zatvora u trajanju od ${izvrsenjekazna2.preostalidio || "__________"}, kao preostalog dijela kazne zatvora izrečene u ukupnom trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}` : `kazne zatvora u trajanju od ${izvrsenjekazna2.izrecenakazna || "__________"}`}
                          {" "}{izvrsenjenazivsuda2.gen !== undefined ? `koja je ${potraživanom} izrečena presudom ${izvrsenjenazivsuda2.gen || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}` : `koju kaznu je ${potraživanom}, zbog počinjenog krivičnog djela ${izvrsenjekd2 || "_________"}, izrekao ${izvrsenjenazivsuda2.nom || "__________"}`}.
                          <br /><br />
                          <Snipet kod="nalogzahapsenje" tip={props.nalogzahapsenje === "2" ? "2" : ""} />
                        </>}
                    </>
                    /*P2 I2*/
                  }

                </> // I+P
                :
                <>
                  <Alert variant="warning alertV2">
                    Odaberite <strong>razlog izručenja</strong>.
                  </Alert>
                </>}
        </>}

      {props.kod === "OBRUVODstranke" && (
        <>
          {vrstabranioca === "posluzbenoj" && BRnacinizbora === "" ? (
            <>
              Na ročištu održanom dana {datumodluke}, na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {njegov} branilac po
              službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}, od strane sudije za prethodni
              postupak ovog Suda je, prije svega, utvrđen identitet{" "}
              {potraživanog}, {te_mu_je} saopšteno zbog čega se i na osnovu kojih
              dokaza traži {njegovo} izručenje. Tom prilikom {potraživani} je {pozvan}{" "}
              da se izjasni na navode iz potjernice.
            </>
          ) : vrstabranioca === "posluzbenoj" && BRnacinizbora === "lista" ? (
            <>
              Na ročištu održanom dana {datumodluke}, na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {njegov} branilac po
              službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}, od strane sudije za prethodni
              postupak ovog Suda je, prije svega, utvrđen identitet{" "}
              {potraživanog}, {te_mu_je} saopšteno zbog čega se i na osnovu kojih
              dokaza traži {njegovo} izručenje. Tom prilikom {potraživani} je {pozvan}{" "}
              da se izjasni na navode iz potjernice.
            </>
          ) : vrstabranioca === "posluzbenoj" &&
            BRnacinizbora === "bezliste" ? (
            <>
              Na ročištu održanom dana {datumodluke}, {potraživani}{" "}
              {IMELICA.ime || "__________"} je obaviješten da {mu_je} u ovom postupku
              postavljen branilac po službenoj dužnosti{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}, sa čime se {on} {saglasio}.
              {Potraživani} je {poučen} je da može uzeti branioca po svom izboru.
              Takođe, od strane sudije za prethodni postupak ovog Suda je
              utvrđen identitet {potraživanog}, {te_mu_je} saopšteno zbog čega se i
              na osnovu kojih dokaza traži {njegovo} izručenje. Tom prilikom
              {potraživani} je {pozvan} da se izjasni na navode iz potjernice.
            </>
          ) : vrstabranioca === "izabrani" ? (
            <>
              Na ročištu održanom dana {datumodluke}, na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {dvabranioca ? <>{njegovi} izabrani branioci{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</> : <>{njegov} izabrani branilac{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>}, od strane sudije za prethodni
              postupak ovog Suda je, prije svega, utvrđen identitet{" "}
              {potraživanog}, {te_mu_je} saopšteno zbog čega se i na osnovu kojih
              dokaza traži {njegovo} izručenje. Tom prilikom {potraživani} je {pozvan}
              da se izjasni na navode iz potjernice.
            </>
          ) : (
            <></>
          )}
          {dodajtumaca === true && (
            <>
              {" "}
              Imajući u vidu da {potraživani} ne razumije nijedan od službenih
              jezika koji su u upotrebi pred Sudom BiH, {istom} je obezbjeđeno
              simultano prevođenje od strane stalnog sudskog tumača za{" "}
              {jezikprevoda || "__________"} jezik {TUMAC.imegen || "__________"}.
            </>
          )}
        </>
      )}

      {props.kod === "OBRUVODstrankeODB" && (
        <>
          {vrstabranioca === "posluzbenoj" && BRnacinizbora === "" ? (
            <>
              na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {njegov} branilac po
              službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}.
            </>
          ) : vrstabranioca === "posluzbenoj" && BRnacinizbora === "lista" ? (
            <>
              na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {njegov} branilac po
              službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}.
            </>
          ) : vrstabranioca === "posluzbenoj" &&
            BRnacinizbora === "bezliste" ? (
            <>
              na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {njegov} branilac po
              službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}.
            </>
          ) : vrstabranioca === "izabrani" ? (
            <>
              na kome su prisustvovali{" "}
              {potraživani} {IMELICA.ime || "__________"} i {dvabranioca ? <>{njegovi} izabrani branioci{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</> : <>{njegov} izabrani branilac{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>}.
            </>
          ) : (
            <></>
          )}
          {dodajtumaca === true && (
            <>
              {" "}
              Imajući u vidu da {potraživani} ne razumije nijedan od službenih
              jezika koji su u upotrebi pred Sudom BiH, {istom} je obezbjeđeno
              simultano prevođenje od strane stalnog sudskog tumača za{" "}
              {jezikprevoda || "__________"} jezik {TUMAC.imegen || "__________"}.
            </>
          )}
        </>
      )}

      {props.kod === "OBRstrankeEKS" && (
        <>
          {vrstabranioca === "posluzbenoj" && dodajtumaca === false ? (
            <>
              {IMELICA.imegen || "__________"} i {njegov} branilac po službenoj dužnosti{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}
            </>
          ) : vrstabranioca === "posluzbenoj" && dodajtumaca === true ? (
            <>
              {IMELICA.imegen || "__________"}, {njegov} branilac po službenoj dužnosti{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
              {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} te stalni sudski tumač za{" "}
              {jezikprevoda || "_________"} jezik {TUMAC.ime || "_________"}
            </>
          ) : vrstabranioca === "izabrani" && dodajtumaca === false ? (
            <>
              {IMELICA.ime || "__________"} i {dvabranioca ?
                <>{njegovi} izabrani branioci{" "}
                  {BRANILAC[0].grad === BRANILAC[1].grad ?
                    <>
                      {BRANILAC[0].ime || "_________"} i {BRANILAC[1].ime || "_________"}, advokati iz {BRANILAC[0].gradgen || "_________"}
                    </>
                    :
                    <>
                      {BRANILAC[0].ime || "_________"}, advokat iz{" "}
                      {BRANILAC[0].gradgen || "_________"} i {BRANILAC[1].ime || "_________"}, advokat iz{" "}
                      {BRANILAC[1].gradgen || "_________"}
                    </>}


                </>
                : <>{njegov} izabrani branilac{" "}
                  {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                  {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>}
            </>
          ) : vrstabranioca === "izabrani" && dodajtumaca === true ? (
            <>
              {IMELICA.ime || "__________"}, {dvabranioca ? <>{njegovi} izabrani branioci{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</> : <>{njegovog} izabranog branioca{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</>} te stalni sudski tumač za{" "}
              {jezikprevoda || "_________"} jezik {TUMAC.imegen || "_________"}
            </>
          ) : (
            <></>
          )}
        </>
      )}


      {props.kod === "navodistranaka" &&
        <>
          {vrstaOdluke === "EKS" &&
            <p style={pL}><strong>{(!TUZILAC || TUZILAC.spol === "m") ? "Tužilac" : "Tužiteljica"} Tužilaštva BiH</strong> je {(!TUZILAC || TUZILAC.spol === "m") ? "izjavio" : "izjavila"}</p>
          }
          {dvabranioca ?
            <>
              <p style={pL}><strong>{BRANILAC[0].spol === "m" ? "Branilac" : "Braniteljica"} {potraživanog}</strong>, advokat {BRANILAC[0].ime || "_________"} je {BRANILAC[0].spol === "m" ? `izjavio` : `izjavila`} da</p>
              <p style={pL}><strong>{BRANILAC[1].spol === "m" ? `Drugi izabrani branilac` : `Druga izabrana braniteljica`}</strong> {potraživanog}, advokat {BRANILAC[1].ime || "_________"} je {BRANILAC[1].spol === "m" ? `izjavio` : `izjavila`} da</p>
            </> :
            <>
              <p style={pL}><strong>{BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "Branilac" : "Braniteljica") : "Branilac"} {potraživanog}</strong>, advokat {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} je {BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "izjavio" : "izjavila") : "izjavio"} da</p>
            </>}
          <p style={pJ}><strong>{Potraživani}</strong> se u pogledu načina izručenja {izjasnio} da</p>

        </>}

      {props.kod === "EKSneupucujena394" && (
        <>
          {procesuiranje === true && izvrsenje === false ? (
            <>
              Sud u ovom momentu, cijeni da dostavljena molba ne upućuje na
              situaciju iz člana 39. stav 4. Zakona kojom je propisano da
              pritvor neće biti određen ako je iz same molbe za izručenje
              očigledno da nema mjesta izručenju. Ovo iz razloga jer Sud, u ovoj
              incijalnoj fazi razmatranja molbe, nalazi da su inkriminisane
              radnje koje se stavljaju na teret {potraživanom} kažnjive prema
              domaćem zakonodavstvu (__________) uz napomenu da će detaljna
              analiza opisanih radnji izvršenja biti izvršena u narednim fazama
              posutpka nakon provođenja svih izviđajnih radnji u ovom predmetu
              kada će se zauzeti konačan stav u pogledu pravne kvalifikacije i
              ispunjenosti uslova obostrane kažnjivosti te ostalih zakonom
              propisanih pretpostavki za izručenje {potraživanog}.
            </>
          ) : procesuiranje === false && izvrsenje === true ? (
            <>
              Sud u ovom momentu, cijeni da dostavljena molba ne upućuje na
              situaciju iz člana 39. stav 4. Zakona kojom je propisano da
              pritvor neće biti određen ako je iz same molbe za izručenje
              očigledno da nema mjesta izručenju. Ovo iz razloga jer Sud, u ovoj
              incijalnoj fazi razmatranja molbe, nalazi da su inkriminisane
              radnje za čije izvršenje je {potraživani} pravosnažno osuđen
              kažnjive prema domaćem zakonodavstvu (__________) uz napomenu da
              će detaljna analiza opisanih radnji izvršenja biti izvršena u
              narednim fazama posutpka nakon provođenja svih izviđajnih radnji u
              ovom predmetu kada će se zauzeti konačan stav u pogledu pravne
              kvalifikacije i ispunjenosti uslova obostrane kažnjivosti te
              ostalih zakonom propisanih pretpostavki za izručenje {potraživanog}.
            </>
          ) : procesuiranje === true && izvrsenje === true ? (
            <>
              Sud u ovom momentu, cijeni da dostavljena molba ne upućuje na
              situaciju iz člana 39. stav 4. Zakona kojom je propisano da
              pritvor neće biti određen ako je iz same molbe za izručenje
              očigledno da nema mjesta izručenju. Ovo iz razloga jer Sud, u ovoj
              incijalnoj fazi razmatranja molbe, nalazi da su inkriminisane
              radnje koje se stavljaju na teret {potraživanom} odnosno, za čije
              izvršenje je {potraživani} pravosnažno osuđen kažnjive prema domaćem
              zakonodavstvu (__________) uz napomenu da će detaljna analiza
              opisanih radnji izvršenja biti izvršena u narednim fazama posutpka
              nakon provođenja svih izviđajnih radnji u ovom predmetu kada će se
              zauzeti konačan stav u pogledu pravne kvalifikacije i ispunjenosti
              uslova obostrane kažnjivosti kao i ostalih zakonom propisanih
              pretpostavki za izručenje {potraživanog}.
            </>
          ) : procesuiranje === false && izvrsenje === false ? (
            <>
              Sud u ovom momentu, cijeni da dostavljena molba ne upućuje na
              situaciju iz člana 39. stav 4. Zakona kojom je propisano da
              pritvor neće biti određen ako je iz same molbe za izručenje
              očigledno da nema mjesta izručenju. Ovo iz razloga jer Sud, u ovoj
              incijalnoj fazi razmatranja molbe, nalazi da su inkriminisane
              radnje koje se stavljaju na teret {potraživanom} kažnjive prema
              domaćem zakonodavstvu (__________) uz napomenu da će detaljna
              analiza opisanih radnji izvršenja biti izvršena u narednim fazama
              posutpka nakon provođenja svih izviđajnih radnji u ovom predmetu
              kada će se zauzeti konačan stav u pogledu pravne kvalifikacije i
              ispunjenosti uslova obostrane kažnjivosti te ostalih zakonom
              propisanih pretpostavki za izručenje {potraživanog}.
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "MZclan40" && (
        <>
          {DrzavljaninBiH === "DA" ? (
            <>
              {DRZMOLITELJICA.ime === "" ? (
                <></>
              ) : DRZMOLITELJICA.ime === "Hrvatska" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjera
                  procesne prinude (privremenog pritvora) odnose i na
                  potraživana lica koja imaju državljanstvo BiH sadržan je u
                  odredbi člana 40. stav 2. ZMPP-a. Naime, ovom odredbom je
                  preopisano da će se, u situaciji kada je međunarodnim ugovorom
                  dozvoljeno izručenje državljana Bosne i Hercegovine, postupak
                  za njihovo izručenje provesti prema odredbama ovog zakona koje
                  se odnose na izručenje stranaca što, dakle, uključuje i
                  odredbe o primjeni mjera procesne prinude za osiguranje
                  prisustva potraživanih lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Hrvatske na snazi ugovor o
                  izručenju od 28.11.2012.godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori broj: 22/12, u daljem tekstu "Ugovor")
                  koji je stupio na snagu 06.03.2014.godine, a koji pod
                  određenim uslovima dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Srbija" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjera
                  procesne prinude (privremenog pritvora) odnose i na
                  potraživana lica koja imaju državljanstvo BiH sadržan je u
                  odredbi člana 40. stav 2. ZMPP-a. Naime, ovom odredbom je
                  preopisano da će se, u situaciji kada je međunarodnim ugovorom
                  dozvoljeno izručenje državljana Bosne i Hercegovine, postupak
                  za njihovo izručenje provesti prema odredbama ovog zakona koje
                  se odnose na izručenje stranaca što, dakle, uključuje i
                  odredbe o primjeni mjera procesne prinude za osiguranje
                  prisustva potraživanih lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Srbije na snazi ugovor o
                  izručenju od 05.09.2013.godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori, broj: 19/14, u daljem tekstu "Ugovor")
                  koji je stupio na snagu 02.12.2014. godine, a koji pod
                  određenim uslovima dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Crna Gora" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjera
                  procesne prinude (privremenog pritvora) odnose i na
                  potraživana lica koja imaju državljanstvo BiH sadržan je u
                  odredbi člana 40. stav 2. ZMPP-a. Naime, ovom odredbom je
                  preopisano da će se, u situaciji kada je međunarodnim ugovorom
                  dozvoljeno izručenje državljana Bosne i Hercegovine, postupak
                  za njihovo izručenje provesti prema odredbama ovog zakona koje
                  se odnose na izručenje stranaca što, dakle, uključuje i
                  odredbe o primjeni mjera procesne prinude za osiguranje
                  prisustva potraživanih lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Crne Gore na snazi ugovor o izručenju od
                  15.11.2012.godine ("Službeni glasnik BiH" - Međunarodni
                  ugovori broj: 10/13, u daljem tekstu "Ugovor") koji je stupio
                  na snagu 30.06.2015. godine, a koji pod određenim uslovima
                  dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Sjeverna Makedonija" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjera
                  procesne prinude (privremenog pritvora) odnose i na
                  potraživana lica koja imaju državljanstvo BiH sadržan je u
                  odredbi člana 40. stav 2. ZMPP-a. Naime, ovom odredbom je
                  preopisano da će se, u situaciji kada je međunarodnim ugovorom
                  dozvoljeno izručenje državljana Bosne i Hercegovine, postupak
                  za njihovo izručenje provesti prema odredbama ovog zakona koje
                  se odnose na izručenje stranaca što, dakle, uključuje i
                  odredbe o primjeni mjera procesne prinude za osiguranje
                  prisustva potraživanih lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Makedonije na snazi ugovor o
                  izručenju od 27.01.2006. godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori broj:14/06) koji je stupio na snagu
                  10.01.2007. godine. Ovaj ugovor izmijenjen je ugovorom o
                  izmjenama i dopunama Ugovora o izručenju između Bosne i
                  Hercegovine i Republike Makedonije od 12.03.2013.godine
                  ("Službeni glasnik BiH" - Međunarodni ugovori, broj: 6/14, u
                  daljem tekstu "Ugovor") koji je stupio na snagu
                  30.7.2014.godine, a koji pod određenim uslovima dozvoljava
                  izručenje vlastitih državljana.
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "PRclan40" && (
        <>
          {DrzavljaninBiH === "DA" ? (
            <>
              {DRZMOLITELJICA.ime === "" ? (
                <></>
              ) : DRZMOLITELJICA.ime === "Hrvatska" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjere
                  privemenog pritvora odnose i na potraživana lica koja imaju
                  državljanstvo BiH sadržan je u odredbi člana 40. stav 2.
                  ZMPP-a. Naime, ovom odredbom je preopisano da će se, u
                  situaciji kada je međunarodnim ugovorom dozvoljeno izručenje
                  državljana Bosne i Hercegovine, postupak za njihovo izručenje
                  provesti prema odredbama ovog zakona koje se odnose na
                  izručenje stranaca što, dakle, uključuje i odredbe o primjeni
                  mjera procesne prinude za osiguranje prisustva potraživanih
                  lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Hrvatske na snazi ugovor o
                  izručenju od 28.11.2012.godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori broj: 22/12, u daljem tekstu "Ugovor")
                  koji je stupio na snagu 06.03.2014.godine, a koji pod
                  određenim uslovima dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Srbija" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjere
                  privemenog pritvora odnose i na potraživana lica koja imaju
                  državljanstvo BiH sadržan je u odredbi člana 40. stav 2.
                  ZMPP-a. Naime, ovom odredbom je preopisano da će se, u
                  situaciji kada je međunarodnim ugovorom dozvoljeno izručenje
                  državljana Bosne i Hercegovine, postupak za njihovo izručenje
                  provesti prema odredbama ovog zakona koje se odnose na
                  izručenje stranaca što, dakle, uključuje i odredbe o primjeni
                  mjera procesne prinude za osiguranje prisustva potraživanih
                  lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Srbije na snazi ugovor o
                  izručenju od 05.09.2013.godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori, broj: 19/14, u daljem tekstu "Ugovor")
                  koji je stupio na snagu 02.12.2014. godine, a koji pod
                  određenim uslovima dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Crna Gora" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjere
                  privemenog pritvora odnose i na potraživana lica koja imaju
                  državljanstvo BiH sadržan je u odredbi člana 40. stav 2.
                  ZMPP-a. Naime, ovom odredbom je preopisano da će se, u
                  situaciji kada je međunarodnim ugovorom dozvoljeno izručenje
                  državljana Bosne i Hercegovine, postupak za njihovo izručenje
                  provesti prema odredbama ovog zakona koje se odnose na
                  izručenje stranaca što, dakle, uključuje i odredbe o primjeni
                  mjera procesne prinude za osiguranje prisustva potraživanih
                  lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Crne Gore na snazi ugovor o izručenju od
                  15.11.2012.godine ("Službeni glasnik BiH" - Međunarodni
                  ugovori broj: 10/13, u daljem tekstu "Ugovor") koji je stupio
                  na snagu 30.06.2015. godine, a koji pod određenim uslovima
                  dozvoljava izručenje vlastitih državljana.
                </>
              ) : DRZMOLITELJICA.ime === "Sjeverna Makedonija" ? (
                <>
                  Međutim, izuzetak prema kojem se odredbe o primjeni mjere
                  privemenog pritvora odnose i na potraživana lica koja imaju
                  državljanstvo BiH sadržan je u odredbi člana 40. stav 2.
                  ZMPP-a. Naime, ovom odredbom je preopisano da će se, u
                  situaciji kada je međunarodnim ugovorom dozvoljeno izručenje
                  državljana Bosne i Hercegovine, postupak za njihovo izručenje
                  provesti prema odredbama ovog zakona koje se odnose na
                  izručenje stranaca što, dakle, uključuje i odredbe o primjeni
                  mjera procesne prinude za osiguranje prisustva potraživanih
                  lica. <br />
                  <br /> S tim u vezi, Sud nalazi bitnim napomenuti da je između
                  Bosne i Hercegovine i Republike Makedonije na snazi ugovor o
                  izručenju od 27.01.2006. godine ("Službeni glasnik BiH" -
                  Međunarodni ugovori broj:14/06) koji je stupio na snagu
                  10.01.2007. godine. Ovaj ugovor izmijenjen je ugovorom o
                  izmjenama i dopunama Ugovora o izručenju između Bosne i
                  Hercegovine i Republike Makedonije od 12.03.2013.godine
                  ("Službeni glasnik BiH" - Međunarodni ugovori, broj: 6/14, u
                  daljem tekstu "Ugovor") koji je stupio na snagu
                  30.7.2014.godine, a koji pod određenim uslovima dozvoljava
                  izručenje vlastitih državljana.
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "PROclan40" && (
        <>
          {DrzavljaninBiH === "DA" ? (
            <>
              {DRZMOLITELJICA.ime === "" ? (
                <></>
              ) : DRZMOLITELJICA.ime === "Hrvatska" ? (
                <>
                  <p style={pJ}>
                    Kako je u prethodnom rješenju navedeno, Sud je, prilikom
                    odlučivanja o mjeri privremenog pritvora prema {potraživanom}
                    {IMELICA.imedat || "__________"}, primjenio odredbe člana
                    40. stav 2. Zakona o međunarodnoj pravnoj pomoći u krivičnim
                    stvarima, po kojoj se postupak za izručenje državljana Bosne
                    i Hercegovine provodi prema odredbama navedenog zakona koje
                    se odnose na izručenje stranaca, ako je međunarodnim
                    ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine
                    (što je ovdje slučaj), u okviru kog postupka Sud donosi i
                    odluke o pritvoru, uključujući i donošenje rješenja o
                    produženju privremenog pritvora.
                  </p>
                </>
              ) : DRZMOLITELJICA.ime === "Srbija" ? (
                <>
                  <p style={pJ}>
                    Kako je u prethodnom rješenju navedeno, Sud je, prilikom
                    odlučivanja o mjeri privremenog pritvora prema {potraživanom}
                    {IMELICA.imedat || "__________"}, primjenio odredbe člana
                    40. stav 2. Zakona o međunarodnoj pravnoj pomoći u krivičnim
                    stvarima, po kojoj se postupak za izručenje državljana Bosne
                    i Hercegovine provodi prema odredbama navedenog zakona koje
                    se odnose na izručenje stranaca, ako je međunarodnim
                    ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine
                    (što je ovdje slučaj), u okviru kog postupka Sud donosi i
                    odluke o pritvoru, uključujući i donošenje rješenja o
                    produženju privremenog pritvora.
                  </p>
                </>
              ) : DRZMOLITELJICA.ime === "Crna Gora" ? (
                <>
                  <p style={pJ}>
                    Kako je u prethodnom rješenju navedeno, Sud je, prilikom
                    odlučivanja o mjeri privremenog pritvora prema {potraživanom}
                    {IMELICA.imedat || "__________"}, primjenio odredbe člana
                    40. stav 2. Zakona o međunarodnoj pravnoj pomoći u krivičnim
                    stvarima, po kojoj se postupak za izručenje državljana Bosne
                    i Hercegovine provodi prema odredbama navedenog zakona koje
                    se odnose na izručenje stranaca, ako je međunarodnim
                    ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine
                    (što je ovdje slučaj), u okviru kog postupka Sud donosi i
                    odluke o pritvoru, uključujući i donošenje rješenja o
                    produženju privremenog pritvora.
                  </p>
                </>
              ) : DRZMOLITELJICA.ime === "Sjeverna Makedonija" ? (
                <>
                  <p style={pJ}>
                    Kako je u prethodnom rješenju navedeno, Sud je, prilikom
                    odlučivanja o mjeri privremenog pritvora prema {potraživanom}
                    {IMELICA.imedat || "__________"}, primjenio odredbe člana
                    40. stav 2. Zakona o međunarodnoj pravnoj pomoći u krivičnim
                    stvarima, po kojoj se postupak za izručenje državljana Bosne
                    i Hercegovine provodi prema odredbama navedenog zakona koje
                    se odnose na izručenje stranaca, ako je međunarodnim
                    ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine
                    (što je ovdje slučaj), u okviru kog postupka Sud donosi i
                    odluke o pritvoru, uključujući i donošenje rješenja o
                    produženju privremenog pritvora.
                  </p>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "ODBclan40" &&
        <>
          U vezi sa navedenim, bitno je napomenuti da ZMPP u članu 40. posebno definiše postupak izručenja s državljanima Bosne i Hercegovine
          {" "}pa je tako, iz stava 1. pomenutog člana, vidljivo ovlaštenje nadležnog organa Bosne i Hercegovine za saradnju s Interpolom (NCB Interpol Sarajevo)
          {" "}da, u situaciji kada po raspisanoj međunarodnoj potjernici strane države utvrdi da je potraživano lice državljanin Bosne i Hercegovine,
          {" "}obavijesti Interpol države moliteljice koja je raspisala međunarodnu potjernicu i sjedište Interpola o nemogućnosti izručenja vlastitih
          {" "}državljana, ako međunarodnim ugovorom koji dozvoljava izručenje vlastitih državljana nije drugačije određeno kao i nemogućnosti raspisivanja
          {" "}potjernice na teritoriji Bosne i Hercegovine.
          <br /><br />
          Kako je međunarodna potjernica ekvivalent zahtjeva za privremeno pritvaranje slijedi da, u situaciji kada međunarodnim ugovorom
          {" "}nije dozvoljeno izručenje vlastitih državljana, nije moguće primjeniti opšte odredbe ZMPP-a o izručenju (uključujući i odredbe o privremenom pritvoru)
          {" "}što nedvojbeno proizilazi i iz stava 2. pomenutog člana ZMPP-a.
          {" "}
        </>}

      {props.kod === "ODBobrazloženje" &&
        <>
          {!ODBopcije.aktivnapotjernica &&
            <>
              {DrzavljaninBiH === "DA" &&
                <>
                  U vezi sa navedenim, bitno je napomenuti da ZMPP u članu 40. posebno definiše
                  {" "}postupak izručenja s državljanima Bosne i Hercegovine pa tako, u stavu 2. pomenutog člana propisano da će se, u situaciji
                  {" "}kada je međunarodnim ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine, postupak za njihovo izručenje
                  {" "}provesti prema odredbama ovog zakona koje se odnose na izručenje stranaca.
                  <br /><br />
                  Sud, u kontekstu navedenog, nadalje, podsjeća da je članom 37. ZMPP-a propisano
                </>}
              {DrzavljaninBiH === "NE" &&
                <>
                  Naime, iz člana  37. ZMPP-a proizilazi
                </>}

              {" "}da će lice koje je lišeno slobode na osnovu
              {" "}raspisane međunarodne potjernice, u kojoj je naveden zahtjev države moliteljice za određivanje pritvora, biti dovedeno pred sudiju
              {" "}za prethodni postupak s ciljem odlučivanja o prijedlogu države moliteljice za određivanje privremenog pritvora ako postoji opasnost
              {" "}da će stranac čije se izručenje traži pobjeći ili se sakriti.

              {DrzavljaninBiH === "DA" &&
                <>
                  {" "}Iz navedenih zakonskih odredaba proizilazi da se privremeni pritvor na osnovu aktivne međunarodne potjernice može odrediti potraživanom
                  {" "}licu bez oobzira na činjenicu posjedovanja državljanstva Bosne i Hercegovine, ako su za to ispunjena dva zakonska uslova i to da je
                  {" "}međunarodnim ugovorom dozvoljeno izručenje državljana Bosne i Hercegovine te da postoji opasnost da će isti pobjeći ili se sakriti.
                </>}

              {DrzavljaninBiH === "NE" &&
                <>
                  {" "}Iz navedene zakonske odredbe proizilazi da se privremeni pritvor
                  {" "}na osnovu aktivne međunarodne potjernice može odrediti ako su za to ispunjena dva zakonska uslova i to da je lice čije se pritvaranje
                  {" "}traži stranac, odnosno da nije državljanin BiH i da postoji opasnost da će isti pobjeći ili se sakriti.
                </>}
              <br />
              <br />
              {!DRZMOLITELJICA &&
                <>
                  <Alert variant="warning alertV2">
                    Izaberite <strong>državu moliteljicu</strong>.
                  </Alert>
                </>}
              Međutim, Sud nije ispitivao uslove za određivanje mjere privremenog pritvora prema {potraživanom} {IMELICA.imedat || "_________"} u smislu citirane zakonske odredbe.
              Ovo iz razloga jer je Sud zaprimio obavijest organa za saradnju sa Interpolom (NCB Interpol Sarajevo), broj: {ODBopcije.NCBobavijestNeaktivnaPotjernica || "_________ od __.__.____. godine"},
              {" "}iz koje proizilazi da je međunarodna potjernica po kojoj je {lišen} slobode {potraživani} {IMELICA.imegen || "_________"} obustavljena.

            </>/*!ODB.!aktivnapotjernica */}

          {ODBopcije.aktivnapotjernica &&
            <>

              {DrzavljaninBiH === "DA" &&
                <>
                  <Snipet kod="ODBclan40" />
                  {PostojiUgovor === "DA" &&
                    <>
                      {ugovor.pojedinosti.vremenskaprimjena.postoji &&
                        <>
                          <br /><br />
                          Između Bosne i Hercegovine i {DRZMOLITELJICA.gen || "_________"} postoji zaključen ugovor o izručenju koji propisuje
                          {" "}mogućnost izručenja vlastitih državljana pod određenim uslovima. Radi se o{" "}
                          {ugovor.izmjene === "IZMJENE"
                            ?
                            <>Ugovoru {ugovor.naziv.replace('Ugovor', '')} od {ugovor.datum} {ugovor.sl} koji je stupio na snagu {ugovor.stupanje}. Ovaj ugovor je, u određenoj mjeri, izmijenjen Ugovorom {ugovor.naziv2.replace('Ugovor', '')} od {ugovor.datum2} {ugovor.sl2} koje izmjene su stupile na snagu {ugovor.stupanje2}. Slijedom navedenog Sud je,</>
                            :
                            ugovor.izmjene === "PROTOKOL"
                              ?
                              <>Ugovoru {ugovor.naziv.replace('Ugovor', '')} od {ugovor.datum} {ugovor.sl} (koji je stupio na snagu {ugovor.stupanje}) uz {ugovor.naziv2} od {ugovor.datum2} {ugovor.sl2} koji je stupio na snagu {ugovor.stupanje2}. Slijedom navedenog Sud je,</>
                              :
                              <>Ugovoru {ugovor.naziv.replace('Ugovor', '')} od {ugovor.datum} {ugovor.sl} koji je stupio na snagu {ugovor.stupanje} slijedom čega je Sud,</>
                          }

                          {" "}uvažavajući sve okolnosti konkretnog slučaja i razloge zbog kojih je raspisana međunarodna potraga, ispitao da li
                          {" "} relevantne odredbe Ugovora{" "}
                          {procesuiranje && izvrsenje ? `daju mogućnost izručenja vlastitih državljana kako u odnosu na krivično gonjenje tako i u odnosu na izvršenje ${izvrsenjeInfo.length === 2 ? "pravosnažno izrečenih kazni zatvora" : "kazne zatvora"}` : "daju mogućnost izručenja vlastitih državljana"}.

                          {ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" &&
                            <>
                              <br /><br />
                              <ODBpojedinakdOBR />
                            </> /* ugovor ... zrucenjevlastitih === "POJEDINAKD" */}

                        </>/*...vremenskaprimjena.postoji*/}

                      {!ugovor.pojedinosti.vremenskaprimjena.postoji &&
                        <>
                          Uvidom u bazu primjenjivih međunarodnih ugovora kojim je regulisano pitanje međusobnog izručenja potraživanih lica Sud konstatuje
                          {" "}da je na snazi{" "}
                          {ugovor.izmjene === "IZMJENE"
                            ?
                            <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl} odnosno, {ugovor.naziv2} od {ugovor.datum2} {ugovor.sl2} {ugovor.preuzet === "DA" && <>{ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                            :
                            ugovor.izmjene === "PROTOKOL"
                              ?
                              <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl} uz {ugovor.naziv2} od {ugovor.datum2} {ugovor.sl2}{ugovor.preuzet === "DA" && <> {ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                              :
                              <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl}{ugovor.preuzet === "DA" && <> {ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                          }. Međutim, ovaj međunarodni ugovor u pogledu obaveze izručenja u cilju krivičnog gonjenja ili izvršenja pravosnažno izrečene kazne{" "}
                          {ugovor.pojedinosti.izrucenjevlastitih.clanovi.map((obj, index, arr) => (
                            <>
                              {index !== 0 && index === arr.length - 1 ? "te " : ""}
                              članom {obj.clan} propisuje "{obj.citat}"
                              {index !== arr.length - 1 ? ", " : ""}
                            </>
                          ))}.

                          {ugovor.pojedinosti.izrucenjevlastitih.termin &&
                            <>{" "}
                              {ugovor.pojedinosti.izrucenjevlastitih.termin === "ZAMOLJENA" && `Evidentno je da, u konkretnom slučaju, Bosna i Hercegovina ima status zamoljene države čije državljanstvo posjeduje ${potraživani} pa je, kod ovakvog stanja stvari, jasno da ne postoji osnov za izručenje ${potraživanog} primjenom međunarodnog ugovora.`}
                              {ugovor.pojedinosti.izrucenjevlastitih.termin === "PODANIK" && `Evidentno je da se radi o ekvivalentu "domaćeg državljanina" u smislu ZMPP-a pa je, kod ovakvog stanja stvari jasno da ne postoji osnov za izručenje ${potraživanog} primjenom međunarodnog ugovora.`}

                            </>}

                        </>/*...!vremenskaprimjena.postoji*/}

                    </> /*PostojiUgovor === "DA" */}


                  {PostojiUgovor === "NE" &&
                    <>
                      {DRZMOLITELJICA ?
                        <>
                          <br /><br />
                          Uvidom u bazu primjenjivih međunarodnih ugovora Sud konstatuje da između Bosne i Hercegovine i {DRZMOLITELJICA.gen || "_________"}
                          {" "}ne postoji bilateralni ili multilateralni ugovor kojim je regulisano pitanje izručenja potraživanih lica, odnosno koji bi
                          {" "}pod određenim uslovima dozvoljavao izručenje vlastitih državljana.
                        </>
                        :
                        <>
                          <br /><br />
                          <Alert variant="warning alertV2">
                            Izaberite <strong>državu moliteljicu</strong>.
                          </Alert>

                        </>
                      }

                    </> /*PostojiUgovor === "NE" */}

                </>/*DrzavljaninBiH === "DA" */}

              {DrzavljaninBiH === "NE" &&
                <>
                  Naime, iz člana  37. ZMPP-a proizilazi da će lice koje je lišeno slobode na osnovu
                  {" "}raspisane međunarodne potjernice, u kojoj je naveden zahtjev države moliteljice za određivanje pritvora, biti dovedeno pred sudiju
                  {" "}za prethodni postupak s ciljem odlučivanja o prijedlogu države moliteljice za određivanje privremenog pritvora ako postoji opasnost
                  {" "}da će stranac čije se izručenje traži pobjeći ili se sakriti.
                  <br /><br />
                  {" "}Iz navedene zakonske odredbe proizilazi da se privremeni pritvor
                  {" "}na osnovu aktivne međunarodne potjernice može odrediti ako su za to ispunjena dva zakonska uslova i to da je lice čije se pritvaranje
                  {" "}traži stranac, odnosno da nije državljanin BiH i da postoji opasnost da će isti pobjeći ili se sakriti.

                  Međutim, Sud je prvenstveno ispitao da li relevantne {PostojiUgovor === "DA" ? "zakonom odnonso, ugovorom propisane" : "zakonske"} odredbe
                  {" "}daju mogućnost izručenja potraživanih lica. Uvažavajući sve okolnosti konkretnog slučaja i razloge zbog kojih je
                  {" "}raspisana međunarodna pojernica Sud je sa aspekta {PostojiUgovor === "DA" ? "zakonom odnonso, ugovorom propisanih" : "zakonom"} propisanih
                  {" "}uslova za izručenje potraživanih lica analizirao da li okolnosti konkretnog slučaja, eventualno, čine osnov za zaključak da je izručenje očigledno nedopušteno.
                  {" "}Ovo iz razloga jer se radi i opštem uslovu za primjenu mjera procesne prinude, a posebno mjere privremenog pritvora.

                  {PostojiUgovor === "DA" &&
                    <>
                      <br /><br />
                      Uvidom u bazu primjenjivih međunarodnih ugovora kojim je regulisano pitanje međusobnog izručenja potraživanih lica Sud konstatuje
                      {" "}da je na snazi{" "}
                      {ugovor.izmjene === "IZMJENE"
                        ?
                        <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl} odnosno, {ugovor.naziv2} od {ugovor.datum2} {ugovor.sl2} {ugovor.preuzet === "DA" && <>{ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                        :
                        ugovor.izmjene === "PROTOKOL"
                          ?
                          <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl} uz {ugovor.naziv2} od {ugovor.datum2} {ugovor.sl2}{ugovor.preuzet === "DA" && <> {ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                          :
                          <>{ugovor.naziv} od {ugovor.datum} {ugovor.sl}{ugovor.preuzet === "DA" && <> {ugovor.tip === "ugovor" ? " koji je preuzet notifikacijom o sukcesiji od strane Bosne i Hercegovine" : " koja je preuzeta notifikacijom o sukcesiji od strane Bosne i Hercegovine"}</>}</>
                      }.

                      {(procesuiranje && !izvrsenje) &&
                        <>
                          {" "}Međutim, ovaj međunarodni ugovor u pogledu obaveze izručenja potraživanog lica radi krivičnog gonjenja{" "}

                          {ugovor?.pojedinosti?.procesminimum?.clanovi.map((clan, index) => (
                            <span key={index}>
                              {index === 0 ? (
                                <>
                                  članom {clan.clan} propisuje "{clan.citat}"
                                </>
                              ) : (
                                <>
                                  članom {clan.clan} propisano "{clan.citat}".
                                </>
                              )}
                            </span>
                          ))
                            .reduce((prev, curr) => [prev, " dok je ", curr])}

                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {procesuiranjeInfo.length === 1 &&
                            <>
                              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
                              {" "}dozvoljeno izručenje shodno navedenom međunarodnom Ugovoru.
                            </>}

                          {procesuiranjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da krivična djela zbog kojih se traži izručenje {potraživanog}
                              {" "}ne spadaju u katalog krivičnih djela za koje je dozvoljeno izručenje shodno citiranim odredbama Ugovora.
                            </>}


                        </> /*procesuiranje */}

                      {(!procesuiranje && izvrsenje) &&
                        <>
                          {" "}Međutim, ovaj međunarodni ugovor u pogledu obaveze izručenja potraživanog lica radi izvršenja pravosnažno izrečene kazne{" "}

                          {ugovor.pojedinosti.izvrsenjeminimum.upucujenaproces
                            ?
                            <>
                              {ugovor.pojedinosti.izvrsenjeminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      članom {clan.clan} propisuje "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}

                              {" "}{ugovor.pojedinosti.procesminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      Članom {clan.clan} na koji upućuje prethodno citirana odredba je propisano "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}

                            </>
                            :
                            <>
                              {ugovor.pojedinosti.izvrsenjeminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      članom {clan.clan} propisuje "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}
                            </>
                          }

                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {izvrsenjeInfo.length === 1 &&
                            <>

                              Dakle, nesporno je da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne u konkretnom slučaju ne ispunjava objektivni uslov sadržan
                              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje.

                            </>}

                          {izvrsenjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI1}, broj: {izvrsenjepresuda1 || "__________"},
                              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
                              {" "}ne ispunjavaju objektivni uslov sadržan u naprijed citiranim odredbama Ugovora koje regulišu izručenje.
                            </>}

                        </> /*izvrsenje */}

                      {(procesuiranje && izvrsenje) &&
                        <>
                          {" "}Kada je u pitanju izručenje potraživanog lica radi krivičnog gonjenja ovim ugovorm je{" "}

                          {ugovor.pojedinosti.procesminimum.clanovi.map((clan, index) => (
                            <span key={index}>
                              {index === 0 ? (
                                <>
                                  članom {clan.clan} propisuje "{clan.citat}"
                                </>
                              ) : (
                                <>
                                  članom {clan.clan} propisano "{clan.citat}".
                                </>
                              )}
                            </span>
                          ))
                            .reduce((prev, curr) => [prev, " dok je ", curr])}

                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {procesuiranjeInfo.length === 1 &&
                            <>
                              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
                              {" "}dozvoljeno izručenje shodno navedenom međunarodnom Ugovoru.
                            </>}

                          {procesuiranjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da pomenuti bilateralni ugovor dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da krivična djela zbog kojih se traži izručenje {potraživanog}
                              {" "}ne spadaju u katalog krivičnih djela za koje je dozvoljeno izručenje shodno citiranim odredbama Ugovora.
                            </>}
                          <br /><br />
                          {" "}S druge strane, kada je u pitanju izručenje potraživanog lica radi izvršenja pravosnažno izrečene kazne{" "}

                          {ugovor.pojedinosti.izvrsenjeminimum.upucujenaproces
                            ?
                            <>
                              {ugovor.pojedinosti.izvrsenjeminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      član {clan.clan} Ugovora propisuje "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}

                              {" "}{ugovor.pojedinosti.procesminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      Članom {clan.clan} na koji upućuje prethodno citirana odredba je propisano "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}

                            </>
                            :
                            <>
                              {ugovor.pojedinosti.izvrsenjeminimum.clanovi.map((clan, index) => (
                                <span key={index}>
                                  {index === 0 ? (
                                    <>
                                      članom {clan.clan} propisuje "{clan.citat}"
                                    </>
                                  ) : (
                                    <>
                                      članom {clan.clan} propisano "{clan.citat}".
                                    </>
                                  )}
                                </span>
                              ))
                                .reduce((prev, curr) => [prev, " dok je ", curr])}
                            </>
                          }

                          <br /><br />
                          {izvrsenjeInfo.length === 1 &&
                            <>
                              Bilateralni ugovor dozvoljava, dakle, pod određenim uslovima izručenje potraživanih lica i radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne u konkretnom slučaju ne ispunjava objektivni uslov sadržan
                              {" "}u naprijed citiranim odredbama Ugovora koje regulišu izručenje.

                            </>}

                          {izvrsenjeInfo.length === 2 &&
                            <>
                              Bilateralni ugovor dozvoljava, dakle, pod određenim uslovima izručenje potraživanih lica i radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI1}, broj: {izvrsenjepresuda1 || "__________"},
                              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
                              {" "}ne ispunjavaju objektivni uslov sadržan u naprijed citiranim odredbama Ugovora koje regulišu izručenje.
                            </>}



                        </> /*procesuiranje && izvrsenje */}




                      {(!procesuiranje && !izvrsenje) &&
                        <>
                          <br /><br />
                          <Alert variant="warning alertV2">
                            Izaberite <strong>razlog izručenja</strong>.
                          </Alert>
                        </> /*procesuiranje && izvrsenje */}

                    </> /*PostojiUgovor === "DA" */}

                  {PostojiUgovor === "NE" &&
                    <>
                      <br /><br />
                      {" "}Uvidom u bazu primjenjivih međunarodnih ugovora Sud konstatuje da između Bosne i Hercegovine i {DRZMOLITELJICA.gen || "_________"}
                      {" "}ne postoji bilateralni ili multilateralni ugovor kojim je regulisano pitanje izručenja potraživanih lica, odnosno koji bi
                      {" "}pod određenim uslovima dozvoljavao izručenje vlastitih državljana.
                      <br /><br />
                      U odsustvu međunarodnog ugovora Sud je, u pogledu načina i postupka pružanja međunarodne pravne pomoći izručenja
                      {" "}potraživanih lica, obavezan primjenjivati <Skracenica kod="ZMPP" naziv="naziv" />/ZMPP.

                      {(procesuiranje && !izvrsenje) &&
                        <>
                          {" "}Odredbom člana 32. stav 3. ZMPP-a je propisano da se izručenje stranca radi krivičnog gonjenja dozvoljava samo za krivična
                          {" "}djela za koja je i prema krivičnom zakonodavstvu Bosne i Hercegovine i prema zakonima države moliteljice predviđena
                          {" "}kazna zatvora od najmanje jedne godine.
                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {procesuiranjeInfo.length === 1 &&
                            <>
                              Dakle, nesporno je da primjenjivi zakon, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
                              {" "}dozvoljeno izručenje shodno citiranoj odredbi zakona.
                            </>}

                          {procesuiranjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da krivična djela zbog kojih se traži izručenje {potraživanog}
                              {" "}ne spadaju u katalog krivičnih djela za koje je dozvoljeno izručenje shodno citiranoj odredbi zakona.
                            </>}

                        </> /*procesuiranje */}

                      {(!procesuiranje && izvrsenje) &&
                        <>
                          {" "}Odredbom člana 32. stav 4. ZMPP-a je propisano da se izručenje stranca radi izvršenja pravnosnažno izrečene kazne zatvora dozvoljava
                          {" "}ako trajanje izrečene kazne ili njen preostali dio koji se treba izvršiti iznosi najmanje četiri mjeseca.
                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {izvrsenjeInfo.length === 1 &&
                            <>

                              Dakle, nesporno je da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne u konkretnom slučaju ne ispunjava objektivni uslov sadržan
                              {" "}u naprijed citiranoj odredbi zakona.

                            </>}

                          {izvrsenjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI1}, broj: {izvrsenjepresuda1 || "__________"},
                              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
                              {" "}ne ispunjavaju objektivni uslov sadržan u naprijed citiranoj odredbi zakona.
                            </>}
                        </> /*izvrsenje */}

                      {(procesuiranje && izvrsenje) &&
                        <>
                          {" "}Odredbom člana 32. stav 3. ZMPP-a je propisano da se izručenje stranca radi krivičnog gonjenja dozvoljava samo za krivična
                          {" "}djela za koja je i prema krivičnom zakonodavstvu Bosne i Hercegovine i prema zakonima države moliteljice predviđena
                          {" "}kazna zatvora od najmanje jedne godine.
                          <br /><br />
                          <Snipet kod="izspisa" tip="beznaloga" />
                          <br /><br />
                          {procesuiranjeInfo.length === 1 &&
                            <>
                              Dakle, nesporno je da primjenjivi zakon, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, evidentno je da krivično djelo zbog kojeg se traži izručenje {potraživanog} ne spada u katalog krivičnih djela za koje je
                              {" "}dozvoljeno izručenje shodno citiranoj odredbi zakona.
                            </>}

                          {procesuiranjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih radi lica krivičnog gonjenja,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da krivična djela zbog kojih se traži izručenje {potraživanog}
                              {" "}ne spadaju u katalog krivičnih djela za koje je dozvoljeno izručenje shodno citiranoj odredbi zakona.
                            </>}
                          <br /><br />
                          {" "}S druge strane, kada je u pitanju izručenje stranca radi izvršenja pravnosnažno izrečene kazne zatvora odredbom člana 32. stav 4. ZMPP-a je
                          {" "}propisano da izručenje zbog navedenih razloga dozvoljava ako trajanje izrečene kazne ili njen preostali dio koji se treba izvršiti iznosi najmanje četiri mjeseca.
                          <br /><br />
                          {izvrsenjeInfo.length === 1 &&
                            <>

                              Dakle, nesporno je da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, evidentno je da {izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne u konkretnom slučaju ne ispunjava objektivni uslov sadržan
                              {" "}u naprijed citiranoj odredbi zakona.

                            </>}

                          {izvrsenjeInfo.length === 2 &&
                            <>
                              Nesporno je, dakle, da primjenjivi zakon dozvoljava, pod određenim uslovima, izručenje potraživanih {(procesuiranje && izvrsenje) && "i "}radi izvršenja pravosnažno izrečene kazne zatvora,
                              {" "}međutim, Sud u kontekstu navedenog nalazi očiglednim da
                              {" "}{izvrsenjekazna1.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI1}, broj: {izvrsenjepresuda1 || "__________"},
                              {" "}kao ni {izvrsenjekazna2.preostalidio !== undefined ? "preostali neizdržani dio" : "visina"} izrečene kazne po presudi {nazivsudaI2}, broj: {izvrsenjepresuda2 || "__________"},
                              {" "}ne ispunjavaju objektivni uslov sadržan u naprijed citiranoj odredbi zakona.
                            </>}

                        </> /*procesuiranje && izvrsenje */}
                    </> /*PostojiUgovor === "NE" */}

                </>/*DrzavljaninBiH === "NE" */}



            </>/*ODBopcije.aktivnapotjernica */}




        </>/* ODBobrazlozenje */}


      {props.kod === "ODBobrazloženjekraj" &&
        <>
          {!ODBopcije.aktivnapotjernica && <>
            Kod ovakvog stanja stvari, a u situaciji kada ne postoji aktivna međunarodna potjernica, kao ekvivalent zahtjeva za privremeno pritvaranje u smislu naprijed citiranog
            {" "}člana ZMPP-a, {potraživani} {IMELICA.ime || "_________"} je {pušten} na slobodu. Dakle, činjenica obustave međunarodne potjernice prema {potraživanom} ocijenjena
            {" "}je kao osnov za odbijanje zahtjeva za privremeno pritvaranje {potraživanog} {ODBobustavi === "DA" ? "i obustavu iniciranog postupka izručenja posebno" : "posebno"} uzimajući u obzir poštivanje osnovnih
            {" "}principa proklamovanih članom 5. Evropske konvencije za zaštitu ljudskih prava i osnovnih sloboda koji se odnose na slobodu i sigurnost pojedinca.
            <br /><br />
            S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.
          </>}

          {ODBopcije.aktivnapotjernica &&
            <>

              {DrzavljaninBiH === "DA" &&
                <>
                  {PostojiUgovor === "DA" &&
                    <>
                      {ugovor.pojedinosti.vremenskaprimjena.postoji &&
                        <>
                          {ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" &&
                            <>
                              U odsustvu međunarodnog ugovora ili nepostojanja ugovorom propisanog osnova za izručenje potraživanog lica koje posjeduje
                              {" "}državljanstvo Bosne i Hercegovine Sud je, u pogledu načina i postupka pružanja međunarodne pravne pomoći izručenja
                              {" "}potraživanih lica, obavezan primjenjivati <Skracenica kod="ZMPP" naziv="naziv" />.
                              <br />
                              <br />
                              Stoga, imajući u vidu da je {potraživani}, kako je to i naprijed obrazloženo, {državljanin} Bosne i Hercegovine, na {kojeg} se
                              {" "}u pogledu izručenja ne može primjeniti Ugovor, Sud izvodi zaključak da ne postoje zakonski uslovi za privremeno
                              {" "}pritvaranje {potraživanog}, odnosno određivanje privremenog pritvora.
                              {" "}{ODBobustavi === "DA" && "Osim toga, uzimajući u obzir sve okolnosti konkretnog slučaja Sud nalazi da se radi o okolnostima koje čine osnov za, istovremeno, donošenje odluke o obustavi konkretnog postupka izručenja. "}
                              Ovo iz razloga jer je već u ovog fazi postupka
                              {" "}izvjesno da {potraživani}, kao {državljanin} Bosne i Hercegovine, ne može biti {izručen} državi moliteljici obzirom da
                              {" "}<Skracenica kod="ZMPP" naziv="naziv" /> ne dopušta izručenje vlastitih državljana (član 40. ZMPP-a).
                              <br /><br />
                              {Potraživani} je odmah nakon održanog ročišta pušten na slobodu, međutim, Sud nalazi bitnim napomenuti
                              {" "}da pravosudni organi države moliteljice, u slučaju da ocijene osnovanim,{" "}
                              {(procesuiranje === true && izvrsenje === false) ?
                                <>
                                  imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                                </>
                                :
                                (procesuiranje === false && izvrsenje === true)
                                  ?
                                  <>
                                    imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude u odnosu na potraživano lice, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                                  </>
                                  :
                                  (procesuiranje === true && izvrsenje === true)
                                    ?
                                    <>
                                      imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude i/ili preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu takve oblike međunarodne pravne pomoći
                                    </>
                                    :
                                    <>
                                      <br />
                                      <Alert variant="warning alertV2">
                                        Izaberite <strong>razlog izručenja</strong>.
                                      </Alert>
                                      <br />
                                    </>}.
                              <br /><br />
                              S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.

                            </> /* ugovor ... zrucenjevlastitih === "POJEDINAKD" */}

                        </>/*...vremenskaprimjena.postoji*/}

                      {!ugovor.pojedinosti.vremenskaprimjena.postoji &&
                        <>
                          U odsustvu međunarodnog ugovora ili nepostojanja ugovorom propisanog osnova za izručenje potraživanog lica koje posjeduje
                          {" "}državljanstvo Bosne i Hercegovine Sud je, u pogledu načina i postupka pružanja međunarodne pravne pomoći izručenja
                          {" "}potraživanih lica, obavezan primjenjivati <Skracenica kod="ZMPP" naziv="naziv" />.
                          <br />
                          <br />
                          Stoga, imajući u vidu da je {potraživani}, kako je to i naprijed obrazloženo, {državljanin} Bosne i Hercegovine, na {kojeg} se
                          {" "}u pogledu izručenja ne može primjeniti Ugovor, Sud izvodi zaključak da ne postoje zakonski uslovi za privremeno
                          {" "}pritvaranje {potraživanog}, odnosno određivanje privremenog pritvora.
                          {" "}{ODBobustavi === "DA" && "Osim toga, uzimajući u obzir sve okolnosti konkretnog slučaja Sud nalazi da se radi o okolnostima koje čine osnov za, istovremeno, donošenje odluke o obustavi konkretnog postupka izručenja. "}
                          Ovo iz razloga jer je već u ovog fazi postupka
                          {" "}izvjesno da {potraživani}, kao {državljanin} Bosne i Hercegovine, ne može biti {izručen} državi moliteljici obzirom da
                          {" "}<Skracenica kod="ZMPP" naziv="naziv" /> ne dopušta izručenje vlastitih državljana (član 40. ZMPP-a).
                          <br /><br />
                          {Potraživani} je odmah nakon održanog ročišta pušten na slobodu, međutim, Sud nalazi bitnim napomenuti
                          {" "}da pravosudni organi države moliteljice, u slučaju da ocijene osnovanim,{" "}
                          {(procesuiranje === true && izvrsenje === false) ?
                            <>
                              imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                            </>
                            :
                            (procesuiranje === false && izvrsenje === true)
                              ?
                              <>
                                imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude u odnosu na potraživano lice, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                              </>
                              :
                              (procesuiranje === true && izvrsenje === true)
                                ?
                                <>
                                  imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude i/ili preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu takve oblike međunarodne pravne pomoći
                                </>
                                :
                                <>
                                  <br />
                                  <Alert variant="warning alertV2">
                                    Izaberite <strong>razlog izručenja</strong>.
                                  </Alert>
                                  <br />
                                </>}.
                          <br /><br />
                          S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.
                        </>/*...!vremenskaprimjena.postoji= NIJE DOZVOLJENO IZRUČENJE  */}

                    </> /*PostojiUgovor === "DA" */}


                  {PostojiUgovor === "NE" &&
                    <>
                      U odsustvu međunarodnog ugovora ili nepostojanja ugovorom propisanog osnova za izručenje potraživanog lica koje posjeduje
                      {" "}državljanstvo Bosne i Hercegovine Sud je, u pogledu načina i postupka pružanja međunarodne pravne pomoći izručenja
                      {" "}potraživanih lica, obavezan primjenjivati <Skracenica kod="ZMPP" naziv="naziv" />.
                      <br />
                      <br />
                      Stoga, imajući u vidu da je {potraživani}, kako je to i naprijed obrazloženo, {državljanin} Bosne i Hercegovine
                      {" "}Sud izvodi zaključak da ne postoje zakonski uslovi za privremeno
                      {" "}pritvaranje {potraživanog}, odnosno određivanje privremenog pritvora. Ovo iz razloga jer je već u ovog fazi postupka
                      {" "}izvjesno da {potraživani}, kao {državljanin} Bosne i Hercegovine, ne može biti {izručen} državi moliteljici obzirom da
                      {" "}<Skracenica kod="ZMPP" naziv="naziv" /> ne dopušta izručenje vlastitih državljana (član 40. <Skracenica kod="ZMPP" skr="skr" />-a).
                      <br /><br />
                      {Potraživani} je odmah nakon održanog ročišta pušten na slobodu, međutim, Sud nalazi bitnim napomenuti
                      {" "}da pravosudni organi države moliteljice, u slučaju da ocijene osnovanim,{" "}
                      {(procesuiranje === true && izvrsenje === false) ?
                        <>
                          imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                        </>
                        :
                        (procesuiranje === false && izvrsenje === true)
                          ?
                          <>
                            imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude u odnosu na potraživano lice, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu taj oblik međunarodne pravne pomoći
                          </>
                          :
                          (procesuiranje === true && izvrsenje === true)
                            ?
                            <>
                              imaju mogućnost podnošenja zamolnice nadležnom pravosudnom organu Bosne i Hercegovine za preuzimanje i izvršenje strane sudske presude i/ili preuzimanje krivičnog gonjenja potraživanog lica, a u skladu sa važećim međunarodnim/bilatelarnim ugovorima koji regulišu takve oblike međunarodne pravne pomoći
                            </>
                            :
                            <>
                              <br />
                              <Alert variant="warning alertV2">
                                Izaberite <strong>razlog izručenja</strong>.
                              </Alert>
                              <br />
                            </>}.
                      <br /><br />
                      S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.
                    </> /*PostojiUgovor === "NE" */}

                </>/*DrzavljaninBiH === "DA" */}

              {DrzavljaninBiH === "NE" &&
                <>
                  {PostojiUgovor === "DA" &&
                    <>
                      {" "}Kod ovakvog stanja stvari, Sud izvodi zaključak da ne postoje zakonski uslovi za privremeno
                      {" "}pritvaranje {potraživanog}, odnosno određivanje privremenog pritvora.
                      {" "}{ODBobustavi === "DA" && "Osim toga, uzimajući u obzir sve okolnosti konkretnog slučaja Sud nalazi da se radi o okolnostima koje čine osnov za, istovremeno, donošenje odluke o obustavi konkretnog postupka izručenja. "}
                      Ovo iz razloga jer je već u ovog fazi postupka izvjesno da {potraživani}, zbog razloga radi kojih je raspisana međunarodna
                      {" "}potraga ne može biti {izručen} državi moliteljici.
                      {" "}Istovremeno, polazeći od obaveze poštivanja osnovnih principa proklamovanih članom 5. Evropske konvencije za zaštitu ljudskih prava i temeljinih
                      {" "}sloboda koji se odnose na pravo na slobodu i sigurnost pojedinca, kao i nepostojanje konkretnih razloga za određivanje
                      {" "}privremenog pritvora {potraživani} {IMELICA.ime || "_________"} je odmah nakon održanog ročišta {pušten} na slobodu.
                      <br /><br />
                      S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.
                    </> /*PostojiUgovor === "DA" */}

                  {PostojiUgovor === "NE" &&
                    <>

                      {" "}Kod ovakvog stanja stvari, Sud izvodi zaključak da ne postoje zakonski uslovi za privremeno
                      {" "}pritvaranje {potraživanog}, odnosno određivanje privremenog pritvora.
                      {" "}{ODBobustavi === "DA" && "Osim toga, uzimajući u obzir sve okolnosti konkretnog slučaja Sud nalazi da se radi o okolnostima koje čine osnov za, istovremeno, donošenje odluke o obustavi konkretnog postupka izručenja. "}
                      Ovo iz razloga jer je već u ovog fazi postupka izvjesno da {potraživani}, zbog razloga radi kojih je raspisana međunarodna
                      {" "}potraga ne može biti {izručen} državi moliteljici.
                      {" "}Istovremeno, polazeći od obaveze poštivanja osnovnih principa proklamovanih članom 5. Evropske konvencije za zaštitu ljudskih prava i temeljinih
                      {" "}sloboda koji se odnose na pravo na slobodu i sigurnost pojedinca, kao i nepostojanje konkretnih razloga za određivanje
                      {" "}privremenog pritvora {potraživani} {IMELICA.ime || "_________"} je odmah nakon održanog ročišta {pušten} na slobodu.
                      <br /><br />
                      S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.
                    </> /*PostojiUgovor === "NE" */}

                </>/*DrzavljaninBiH === "NE" */}



            </>/*ODBopcije.aktivnapotjernica */}




        </>/* ODBobrazlozenjekraj */}






      {props.kod === "ugovorodredbe" && (
        <>
          {DrzavljaninBiH === "DA" && (
            <>
              {DRZMOLITELJICA.ime === "" ? (
                <>
                  <Alert variant="warning alertV2">
                    Izaberite <strong>državu moliteljicu</strong>.
                  </Alert>
                </>
              ) : DRZMOLITELJICA.ime === "Hrvatska" ? (
                <>
                  {procesuiranje === false && izvrsenje === false ? (
                    <>
                      <Alert variant="warning alertV2">
                        Izaberite <strong>razlog izručenja</strong>.
                      </Alert>{" "}
                    </>
                  ) : (
                    <>
                      {osnoviugovor === "" ? (
                        <>
                          <Alert variant="warning alertV2">
                            Izaberite{" "}
                            <strong>član ugovora (osnov za izručenje)</strong>.
                          </Alert>
                        </>
                      ) : osnoviugovor === "PugovorHRVclan71" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje vlastitih državljana u svrhu krivičnog
                                gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,IugovorHRVclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u svrhu krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,PugovorHRVclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                je da će se izručenje vlastitih državljana u
                                svrhu krivičnog gonjenja, osim za krivična djela
                                iz člana 7. Ugovora (organizirani kriminal,
                                korupcija i pranje novca) dopustiti i za teška
                                krivična djela za koja se može izreći kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od deset godina ili teža
                                kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u svrhu krivičnog gonjenja. Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje vlastitih državljana u svrhu krivičnog
                                gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                je da će se izručenje vlastitih državljana u
                                svrhu krivičnog gonjenja, osim za krivična djela
                                iz člana 7. Ugovora (organizirani kriminal,
                                korupcija i pranje novca) dopustiti i za teška
                                krivična djela za koja se može izreći kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od deset godina ili teža
                                kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje vlastitih državljana u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dopustiti za krivična djela iz stava 1.
                                istog člana (teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje vlastitih državljana u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dopustiti za krivična djela iz stava 1.
                                istog člana (teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,IugovorHRVclan72,PugovorHRVclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 8. stav 1. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu krivičnog
                                gonjenja, osim za krivična djela iz člana 7.
                                Ugovora (organizirani kriminal, korupcija i
                                pranje novca) dopustiti i za teška krivična
                                djela za koja se može izreći kazna zatvora ili
                                mjera koja uključuje oduzimanje slobode u
                                trajanju od deset godina ili teža kazna. S druge
                                strane, kada je u pitanju izručenje vlastitih
                                državljana u svrhu izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode članom 7. stav 2. Ugovora
                                propisano da će se izručenje dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 8. stav 1. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu krivičnog
                                gonjenja, osim za krivična djela iz člana 7.
                                Ugovora (organizirani kriminal, korupcija i
                                pranje novca) dopustiti i za teška krivična
                                djela za koja se može izreći kazna zatvora ili
                                mjera koja uključuje oduzimanje slobode u
                                trajanju od deset godina ili teža kazna. S druge
                                strane, kada je u pitanju izručenje vlastitih
                                državljana u svrhu izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode članom 7. stav 2. Ugovora
                                propisano da će se izručenje dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,IugovorHRVclan72,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije. Osim toga,
                                članom 8. stav 2. Ugovora propisano da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela iz stava 1. istog člana (teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije. Osim toga,
                                članom 8. stav 2. Ugovora propisano da će se
                                izručenje vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela iz stava 1. istog člana (teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan71,IugovorHRVclan72,PugovorHRVclan81,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Mogućnost
                                izručenja vlastitih državljana za ova krivična
                                djela propisana je članom 7. stav 2. Ugovora i u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, i to u situaciji kada trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine pri čemu
                                su stavom 3. i 4. istog člana preciznije
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. S druge strane, članom 8. stav 1.
                                Ugovora propisano da će se izručenje vlastitih
                                državljana u svrhu krivičnog gonjenja, osim za
                                krivična djela iz člana 7. Ugovora (organizirani
                                kriminal, korupcija i pranje novca) dopustiti i
                                za teška krivična djela za koja se može izreći
                                kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od deset godina
                                ili teža kazna, članom 8. stav 2. Ugovora je
                                propisani su uslovi vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode za teška
                                krivična djela ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenaj pravosnažno izrečeke kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja dopustiti za krivična djela
                                organiziranog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Mogućnost
                                izručenja vlastitih državljana za ova krivična
                                djela propisana je članom 7. stav 2. Ugovora i u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, i to u situaciji kada trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine pri čemu
                                su stavom 3. i 4. istog člana preciznije
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. S druge strane, članom 8. stav 1.
                                Ugovora propisano da će se izručenje vlastitih
                                državljana u svrhu krivičnog gonjenja, osim za
                                krivična djela iz člana 7. Ugovora (organizirani
                                kriminal, korupcija i pranje novca) dopustiti i
                                za teška krivična djela za koja se može izreći
                                kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od deset godina
                                ili teža kazna, članom 8. stav 2. Ugovora je
                                propisani su uslovi vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode za teška
                                krivična djela ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorHRVclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 7. stav
                                2. Ugovora propisano da će se izručenje
                                vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorHRVclan72,PugovorHRVclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. S druge strane, članom 8. stav 1.
                                Ugovora propisano da će se izručenje vlastitih
                                državljana u svrhu krivičnog gonjenja, osim za
                                krivična djela iz člana 7. Ugovora (organizirani
                                kriminal, korupcija i pranje novca) dopustiti i
                                za teška krivična djela za koja se može izreći
                                kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od deset godina
                                ili teža kazna.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. S druge strane, članom 8. stav 1.
                                Ugovora propisano da će se izručenje vlastitih
                                državljana u svrhu krivičnog gonjenja, osim za
                                krivična djela iz člana 7. Ugovora (organizirani
                                kriminal, korupcija i pranje novca) dopustiti i
                                za teška krivična djela za koja se može izreći
                                kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od deset godina
                                ili teža kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorHRVclan72,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. Istovremeno, članom 8. stav 2.
                                Ugovora propisano da će se izručenje vlastitih
                                državljana u svrhu izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode dopustiti za krivična djela
                                iz stava 1. istog člana (teška krivična djela),
                                ako trajanje kazne zatvora ili mjere koja
                                uključuje oduzimanje slobode, odnosno njihov
                                ostatak koji se treba izvršiti iznosi najmanje
                                pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 7. stav
                                2. Ugovora propisano da će se izručenje
                                vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela organiziranog kriminala,
                                korupcije i pranja novca koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje dvije godine. Stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                da će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela iz stava 1. istog
                                člana (teška krivična djela), ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorHRVclan72,PugovorHRVclan81,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. Istovremeno, članom 8. stav 1.
                                Ugovora propisano je da će se izručenje
                                vlastitih državljana u svrhu krivičnog gonjenja,
                                osim za krivična djela iz člana 7. Ugovora
                                (organizirani kriminal, korupcija i pranje
                                novca) dopustiti i za teška krivična djela za
                                koja se može izreći kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od deset
                                godina ili teža kazna. Kada je u pitanju
                                dozvoljenost izručenja vlastitih državljana u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode članom 8. stav 2. Ugovora je propisano
                                da će se isto dopustiti za krivična djela iz
                                stava 1. istog člana (teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela organiziranog
                                kriminala, korupcije i pranja novca koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. Istovremeno, članom 8. stav 1.
                                Ugovora propisano je da će se izručenje
                                vlastitih državljana u svrhu krivičnog gonjenja,
                                osim za krivična djela iz člana 7. Ugovora
                                (organizirani kriminal, korupcija i pranje
                                novca) dopustiti i za teška krivična djela za
                                koja se može izreći kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od deset
                                godina ili teža kazna. Kada je u pitanju
                                dozvoljenost izručenja vlastitih državljana u
                                svrhu izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode članom 8. stav 2. Ugovora je propisano
                                da će se isto dopustiti za krivična djela iz
                                stava 1. istog člana (teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                se treba izvršiti iznosi najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "PugovorHRVclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 1. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja, osim za krivična djela iz
                                člana 7. Ugovora (organizirani kriminal,
                                korupcija i pranje novca) dopustiti i za teška
                                krivična djela za koja se može izreći kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od deset godina ili teža
                                kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 8. stav 1. Ugovora propisano da će se
                                izručenje vlastitih državljana u svrhu krivičnog
                                gonjenja, osim za krivična djela iz člana 7.
                                Ugovora (organizirani kriminal, korupcija i
                                pranje novca) dopustiti i za teška krivična
                                djela za koja se može izreći kazna zatvora ili
                                mjera koja uključuje oduzimanje slobode u
                                trajanju od deset godina ili teža kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorHRVclan81,IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 1. Ugovora propisano je
                                da će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja, osim za krivična djela iz
                                člana 7. Ugovora (organizirani kriminal,
                                korupcija i pranje novca) dopustiti i za teška
                                krivična djela za koja se može izreći kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od deset godina ili teža
                                kazna. S druge strane, članom 8. stav 2. Ugovora
                                propisano da će se izručenje vlastitih
                                državljana dozvoliti i u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela iz stava 1. istog člana (teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvšrenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 8. stav 1. Ugovora propisano je
                                da će se izručenje vlastitih državljana u svrhu
                                krivičnog gonjenja, osim za krivična djela iz
                                člana 7. Ugovora (organizirani kriminal,
                                korupcija i pranje novca) dopustiti i za teška
                                krivična djela za koja se može izreći kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od deset godina ili teža
                                kazna. S druge strane, članom 8. stav 2. Ugovora
                                propisano da će se izručenje vlastitih
                                državljana dozvoliti i u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela iz stava 1. istog člana (teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorHRVclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 2. Ugovora propisano da
                                će se izručenje vlastitih državljana u svrhu
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dopustiti za krivična djela iz stava 1. istog
                                člana (teška krivična djela), ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji se treba
                                izvršiti iznosi najmanje pet godina.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Hrvatskom ima zaključen ugovor o izručenju od
                                28.11.2012.godine, koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenaj pravosnažno
                                izrečene kazne zatvora. Tako je članom 8. stav
                                2. Ugovora propisano da će se izručenje
                                vlastitih državljana u svrhu izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dopustiti za
                                krivična djela iz stava 1. istog člana (teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji se treba izvršiti iznosi
                                najmanje pet godina.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}{" "}
                  {/*preoces ili izvrsenje NIJE false */}
                </>
              ) : DRZMOLITELJICA.ime === "Srbija" ? (
                <>
                  {procesuiranje === false && izvrsenje === false ? (
                    <>
                      <Alert variant="warning alertV2">
                        Izaberite <strong>razlog izručenja</strong>.
                      </Alert>{" "}
                    </>
                  ) : (
                    <>
                      {osnoviugovor === "" ? (
                        <>
                          <Alert variant="warning alertV2">
                            Izaberite{" "}
                            <strong>član ugovora (osnov za izručenje)</strong>.
                          </Alert>
                        </>
                      ) : osnoviugovor === "PugovorSRBclan71" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,IugovorSRBclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 7. stav 2. Ugovora propisano je da će se
                                izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,PugovorSRBclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju krivičnog gonjenja dozvoliti i za druga
                                teška krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja.Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju krivičnog gonjenja dozvoliti i za druga
                                teška krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dozvoliti za krivična djela iz stava 1.
                                ovog člana (druga teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Stavom 3. i 4.
                                istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dozvoliti za krivična djela iz stava 1.
                                ovog člana (druga teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,IugovorSRBclan72,PugovorSRBclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Nadalje, članom 7.
                                stav 2. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine, a stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                da će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Nadalje, članom 7.
                                stav 2. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine, a stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 1. Ugovora propisano
                                da će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,IugovorSRBclan72,PugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Nadalje, članom 7.
                                stav 2. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine, a stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dozvoliti za krivična djela iz stava 1.
                                ovog člana (druga teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Nadalje, članom 7.
                                stav 2. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine, a stavom 3.
                                i 4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                                Istovremeno, članom 8. stav 2. Ugovora propisano
                                je da će se izručenje sopstvenih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode dozvoliti za krivična djela iz stava 1.
                                ovog člana (druga teška krivična djela), ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,PugovorSRBclan81,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 8. stav 1. Ugovora propisano je da će se
                                izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna. Kada je u pitanju izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode za druga teška krivična
                                djela, članom 8. stav 2. Ugovora propisano je da
                                će isto dozvoliti ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Istovremeno,
                                članom 8. stav 1. Ugovora propisano je da će se
                                izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna. Kada je u pitanju izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode za druga teška krivična
                                djela, članom 8. stav 2. Ugovora propisano je da
                                će isto dozvoliti ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan71,IugovorSRBclan72,PugovorSRBclan81,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Mogućnost
                                izručenja vlastitih državljana za ova krivična
                                djela propisana je članom 7. stav 2. Ugovora i u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, i to u situaciji kada trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine pri čemu su
                                stavom 3. i 4. istog člana preciznije određena
                                krivična djela odnosno, kategorije krivičnih
                                djela organiziranog kriminala i korupcije. S
                                druge strane, članom 8. stav 1. Ugovora
                                propisano je da će se izručenje sopstvenih
                                državljana u cilju krivičnog gonjenja dozvoliti
                                i za druga teška krivična djela za koja je
                                propisana kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od najmanje 5
                                godina ili teža kazna, a članom 8. stav 2.
                                Ugovora propisano da će se izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode dozvoliti za druga teška
                                krivična djela ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti za krivična djela
                                organizovanog kriminala, korupcije i pranja
                                novca za koja je prema pravu obje države
                                ugovornice propisana kazna zatvora ili mjera
                                koja uključuje oduzimanje slobode u trajanju od
                                četiri godine ili teža kazna. Mogućnost
                                izručenja vlastitih državljana za ova krivična
                                djela propisana je članom 7. stav 2. Ugovora i u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, i to u situaciji kada trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine pri čemu su
                                stavom 3. i 4. istog člana preciznije određena
                                krivična djela odnosno, kategorije krivičnih
                                djela organiziranog kriminala i korupcije. S
                                druge strane, članom 8. stav 1. Ugovora
                                propisano je da će se izručenje sopstvenih
                                državljana u cilju krivičnog gonjenja dozvoliti
                                i za druga teška krivična djela za koja je
                                propisana kazna zatvora ili mjera koja uključuje
                                oduzimanje slobode u trajanju od najmanje 5
                                godina ili teža kazna, a članom 8. stav 2.
                                Ugovora propisano da će se izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode dozvoliti za druga teška
                                krivična djela ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorSRBclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine.
                                Stavom 3. i 4. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 7. stav
                                2. Ugovora propisano da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine. Stavom 3. i
                                4. istog člana preciznije su određena krivična
                                djela odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorSRBclan72,PugovorSRBclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine
                                pri čemu su stavom 3. i 4. istog člana
                                preciznije određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije. Osim toga, članom 8. stav
                                1. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju krivičnog gonjenja
                                dozvoliti i za druga teška krivična djela za
                                koja je propisana kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od
                                najmanje 5 godina ili teža kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine
                                pri čemu su stavom 3. i 4. istog člana
                                preciznije određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije. Osim toga, članom 8. stav
                                1. Ugovora propisano je da će se izručenje
                                sopstvenih državljana u cilju krivičnog gonjenja
                                dozvoliti i za druga teška krivična djela za
                                koja je propisana kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od
                                najmanje 5 godina ili teža kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorSRBclan72,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine
                                pri čemu su tavom 3. i 4. istog člana preciznije
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije. Osim toga, članom 8. stav 2. Ugovora
                                propisano je da će se izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode dozvoliti za krivična djela
                                iz stava 1. ovog člana (druga teška krivična
                                djela), ako trajanje kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 7. stav
                                2. Ugovora propisano da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela organizovanog kriminala,
                                korupcije i pranja novca, koja su kažnjiva prema
                                pravu obje države ugovornice, ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši iznosi najmanje dvije godine pri čemu su
                                tavom 3. i 4. istog člana preciznije određena
                                krivična djela odnosno, kategorije krivičnih
                                djela organiziranog kriminala i korupcije. Osim
                                toga, članom 8. stav 2. Ugovora propisano je da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela iz stava 1. ovog
                                člana (druga teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "IugovorSRBclan72,PugovorSRBclan81,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine
                                pri čemu su stavom 3. i 4. istog člana
                                preciznije određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije. Istovremeno, članom 8.
                                stav 1. Ugovora propisano je će se izručenje
                                sopstvenih državljana u cilju krivičnog gonjenja
                                dozvoliti i za druga teška krivična djela za
                                koja je propisana kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od
                                najmanje 5 godina ili teža kazna. Kada je u
                                pitanju izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode članom
                                8. stav 2. Ugovora propisano je da će se isto
                                dozvoliti za krivična djela iz stava 1. ovog
                                člana (druga teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela organizovanog
                                kriminala, korupcije i pranja novca, koja su
                                kažnjiva prema pravu obje države ugovornice, ako
                                trajanje kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši iznosi najmanje dvije godine
                                pri čemu su stavom 3. i 4. istog člana
                                preciznije određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije. Istovremeno, članom 8.
                                stav 1. Ugovora propisano je će se izručenje
                                sopstvenih državljana u cilju krivičnog gonjenja
                                dozvoliti i za druga teška krivična djela za
                                koja je propisana kazna zatvora ili mjera koja
                                uključuje oduzimanje slobode u trajanju od
                                najmanje 5 godina ili teža kazna. Kada je u
                                pitanju izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode članom
                                8. stav 2. Ugovora propisano je da će se isto
                                dozvoliti za krivična djela iz stava 1. ovog
                                člana (druga teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "PugovorSRBclan81" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 8. stav 1. Ugovora propisano da će se
                                izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorSRBclan81,IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna. Kada je u pitanju izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode članom 8. stav 2. Ugovora
                                propisano je da će se isto dozvoliti za krivična
                                djela iz stava 1. ovog člana (druga teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 8. stav 1. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                krivičnog gonjenja dozvoliti i za druga teška
                                krivična djela za koja je propisana kazna
                                zatvora ili mjera koja uključuje oduzimanje
                                slobode u trajanju od najmanje 5 godina ili teža
                                kazna. Kada je u pitanju izručenje sopstvenih
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode članom 8. stav 2. Ugovora
                                propisano je da će se isto dozvoliti za krivična
                                djela iz stava 1. ovog člana (druga teška
                                krivična djela), ako trajanje kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode, odnosno
                                njihov ostatak koji treba da se izvrši, iznosi
                                najmanje dvije godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorSRBclan82" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 8. stav 2. Ugovora propisano da
                                će se izručenje sopstvenih državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora ili
                                mjere koja uključuje oduzimanje slobode
                                dozvoliti za krivična djela iz stava 1. ovog
                                člana (druga teška krivična djela), ako trajanje
                                kazne zatvora ili mjere koja uključuje
                                oduzimanje slobode, odnosno njihov ostatak koji
                                treba da se izvrši, iznosi najmanje dvije
                                godine.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Srbijom ima zaključen ugovor o izručenju od
                                05.09.2013.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori, broj: 19/14, u daljem
                                tekstu \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 8. stav
                                2. Ugovora propisano da će se izručenje
                                sopstvenih državljana u cilju izvršenja
                                pravosnažno izrečene kazne zatvora ili mjere
                                koja uključuje oduzimanje slobode dozvoliti za
                                krivična djela iz stava 1. ovog člana (druga
                                teška krivična djela), ako trajanje kazne
                                zatvora ili mjere koja uključuje oduzimanje
                                slobode, odnosno njihov ostatak koji treba da se
                                izvrši, iznosi najmanje dvije godine.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}{" "}
                  {/*preoces ili izvrsenje NIJE false */}
                </>
              ) : DRZMOLITELJICA.ime === "Crna Gora" ? (
                <>
                  {procesuiranje === false && izvrsenje === false ? (
                    <>
                      <Alert variant="warning alertV2">
                        Izaberite <strong>razlog izručenja</strong>.
                      </Alert>{" "}
                    </>
                  ) : (
                    <>
                      {osnoviugovor === "" ? (
                        <>
                          <Alert variant="warning alertV2">
                            Izaberite{" "}
                            <strong>član ugovora (osnov za izručenje)</strong>.
                          </Alert>
                        </>
                      ) : osnoviugovor === "PugovorCRNclan71" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje vlastitih držаvlјаnа zbоg krivičnоg
                                gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorCRNclan71,PugovorCRNclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna. Osim
                                toga, članom 7. stav 2. Ugovora propisano je da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih
                                djеlа zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо
                                mjеrа kоја uklјučuје lišenje slоbоdе (оsim zа
                                krivičnа djеlа prоtiv čоvjеčnоsti i drugih
                                dоbаrа zаštićеnih mеđunаrоdnim prаvоm).
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 7. stav 1. Ugovora propisano da će se
                                izručenje vlastitih držаvlјаnа zbоg krivičnоg
                                gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna. Osim
                                toga, članom 7. stav 2. Ugovora propisano je da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih
                                djеlа zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо
                                mjеrа kоја uklјučuје lišenje slоbоdе (оsim zа
                                krivičnа djеlа prоtiv čоvjеčnоsti i drugih
                                dоbаrа zаštićеnih mеđunаrоdnim prаvоm).{" "}
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorCRNclan71,IugovorCRNclan73" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna.
                                Istovremeno, članom 7. stav 3. Ugovora propisano
                                je da će se izručеnjе vlastitih držаvlјаnа rаdi
                                izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili
                                mjеrе kоја uklјučuје lišenje slоbоdе dоzvоliti
                                zа krivičnа djеlа iz stavova 1. i 2. istog člаnа
                                (оrgаniziranog kriminаlа, kоrupciје, prаnja
                                nоvcа i drugih teških krivičnih djela) аkо
                                izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје
                                lišnje slоbоdе, оdnоsnо njihоv оstаtаk kојi
                                trеbа dа sе izvrši, iznоsi nајmаnjе dvijе
                                gоdinе.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna.
                                Istovremeno, članom 7. stav 3. Ugovora propisano
                                je da će se izručеnjе vlastitih držаvlјаnа rаdi
                                izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili
                                mjеrе kоја uklјučuје lišenje slоbоdе dоzvоliti
                                zа krivičnа djеlа iz stavova 1. i 2. istog člаnа
                                (оrgаniziranog kriminаlа, kоrupciје, prаnja
                                nоvcа i drugih teških krivičnih djela) аkо
                                izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје
                                lišnje slоbоdе, оdnоsnо njihоv оstаtаk kојi
                                trеbа dа sе izvrši, iznоsi nајmаnjе dvijе
                                gоdinе.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorCRNclan71,PugovorCRNclan72,IugovorCRNclan73" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna. Osim
                                toga, članom 7. stav 2. Ugovora propisano je da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih
                                djеlа zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо
                                mjеrа kоја uklјučuје lišenje slоbоdе (оsim zа
                                krivičnа djеlа prоtiv čоvjеčnоsti i drugih
                                dоbаrа zаštićеnih mеđunаrоdnim prаvоm). S druge
                                strane, kada je u pitanju izručеnjе vlastitih
                                držаvlјаnа rаdi izvršеnjа prаvоsnаžnо izrеčеnе
                                kаznе zаtvоrа ili mjеrе kоја uklјučuје lišenje
                                slоbоdе članom 7. stav 3. Ugovora je propisano
                                da će se isto dоzvоliti zа krivičnа djеlа iz
                                stavova 1. i 2. istog člаnа аkо izrеčеnа kаznа
                                zаtvоrа ili mjеrа kоја uklјučuје lišnje slоbоdе,
                                оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši,
                                iznоsi nајmаnjе dvijе gоdinе.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 1. Ugovora propisano da
                                će se izručenje vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје i krivično
                                djеlo prаnjе nоvcа, za koja je prema pravu obje
                                države ugovornice propisana kazna zatvora ili
                                mjera koja podrazumijeva lišenje slobode u
                                trajanju od četiri godine ili teža kazna. Osim
                                toga, članom 7. stav 2. Ugovora propisano je da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih
                                djеlа zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо
                                mjеrа kоја uklјučuје lišenje slоbоdе (оsim zа
                                krivičnа djеlа prоtiv čоvjеčnоsti i drugih
                                dоbаrа zаštićеnih mеđunаrоdnim prаvоm). S druge
                                strane, kada je u pitanju izručеnjе vlastitih
                                držаvlјаnа rаdi izvršеnjа prаvоsnаžnо izrеčеnе
                                kаznе zаtvоrа ili mjеrе kоја uklјučuје lišenje
                                slоbоdе članom 7. stav 3. Ugovora je propisano
                                da će se isto dоzvоliti zа krivičnа djеlа iz
                                stavova 1. i 2. istog člаnа аkо izrеčеnа kаznа
                                zаtvоrа ili mjеrа kоја uklјučuје lišnje slоbоdе,
                                оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši,
                                iznоsi nајmаnjе dvijе gоdinе.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "PugovorCRNclan72" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih
                                djеlа zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо
                                mjеrа kоја uklјučuје lišenje slоbоdе (оsim zа
                                krivičnа djеlа prоtiv čоvjеčnоsti i drugih
                                dоbаrа zаštićеnih mеđunаrоdnim prаvоm).
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju krivičnog gonjenja. Tako je
                                članom 7. stav 2. Ugovora propisano da će se
                                izručеnjе vlastitih držаvlјаnа zbоg krivičnоg
                                gоnjеnjа dоzvоliti i za оstаlа tеškа krivičnа
                                djеlа, оdnоsnо tеškе оblikе krivičnih djеlа zа
                                kоја је prеmа prаvu оbjе držаvе ugоvоrnicе
                                prоpisаnа kаznа zаtvоrа u trајаnju оd nајmаnjе
                                pеt gоdinа ili tеžа kаznа, оdnоsnо mjеrа kоја
                                uklјučuје lišenje slоbоdе (оsim zа krivičnа
                                djеlа prоtiv čоvjеčnоsti i drugih dоbаrа
                                zаštićеnih mеđunаrоdnim prаvоm).
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorCRNclan72,IugovorCRNclan73" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа (osim krivičnih djеlа
                                оrgаniziranog kriminаlа, kоrupciје i prаnja
                                nоvcа), оdnоsnо tеškе оblikе krivičnih djеlа zа
                                kоја је prеmа prаvu оbjе držаvе ugоvоrnicе
                                prоpisаnа kаznа zаtvоrа u trајаnju оd nајmаnjе
                                pеt gоdinа ili tеžа kаznа, оdnоsnо mjеrа kоја
                                uklјučuје lišenje slоbоdе (оsim zа krivičnа
                                djеlа prоtiv čоvjеčnоsti i drugih dоbаrа
                                zаštićеnih mеđunаrоdnim prаvоm). S druge strane,
                                kada je u pitanju izručеnjе vlastitih držаvlјаnа
                                rаdi izvršеnjа prаvоsnаžnо izrеčеnе kаznе
                                zаtvоrа ili mjеrе kоја uklјučuје lišenje slоbоdе
                                članom 7. stav 3. Ugovora je propisano da će se
                                isto dоzvоliti zа krivičnа djеlа iz stavova 1. i
                                2. istog člаnа (оrgаniziranog kriminаlа,
                                kоrupciје, prаnja nоvcа i drugih teških
                                krivičnih djela) аkо izrеčеnа kаznа zаtvоrа ili
                                mjеrа kоја uklјučuје lišnje slоbоdе, оdnоsnо
                                njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi
                                nајmаnjе dvijе gоdinе.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana kako u cilju krivičnog gonjenja tako
                                i izvršenju pravosnažno izrečene kazne zatvora.
                                Tako je članom 7. stav 2. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti i za оstаlа tеškа
                                krivičnа djеlа (osim krivičnih djеlа
                                оrgаniziranog kriminаlа, kоrupciје i prаnja
                                nоvcа), оdnоsnо tеškе оblikе krivičnih djеlа zа
                                kоја је prеmа prаvu оbjе držаvе ugоvоrnicе
                                prоpisаnа kаznа zаtvоrа u trајаnju оd nајmаnjе
                                pеt gоdinа ili tеžа kаznа, оdnоsnо mjеrа kоја
                                uklјučuје lišenje slоbоdе (оsim zа krivičnа
                                djеlа prоtiv čоvjеčnоsti i drugih dоbаrа
                                zаštićеnih mеđunаrоdnim prаvоm). S druge strane,
                                kada je u pitanju izručеnjе vlastitih držаvlјаnа
                                rаdi izvršеnjа prаvоsnаžnо izrеčеnе kаznе
                                zаtvоrа ili mjеrе kоја uklјučuје lišenje slоbоdе
                                članom 7. stav 3. Ugovora je propisano da će se
                                isto dоzvоliti zа krivičnа djеlа iz stavova 1. i
                                2. istog člаnа (оrgаniziranog kriminаlа,
                                kоrupciје, prаnja nоvcа i drugih teških
                                krivičnih djela) аkо izrеčеnа kаznа zаtvоrа ili
                                mjеrа kоја uklјučuје lišnje slоbоdе, оdnоsnо
                                njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi
                                nајmаnjе dvijе gоdinе.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorCRNclan73" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 7. stav 3. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа rаdi
                                izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili
                                mjеrе kоја uklјučuје lišenje slоbоdе dоzvоliti
                                zа krivičnа djеlа iz stavova 1. i 2. istog člаnа
                                (оrgаniziranog kriminаlа, kоrupciје, prаnja
                                nоvcа i drugih teških krivičnih djela) аkо
                                izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје
                                lišnje slоbоdе, оdnоsnо njihоv оstаtаk kојi
                                trеbа dа sе izvrši, iznоsi nајmаnjе dvijе
                                gоdinе.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju izvršenja pravosnažno izrečene kazne
                                zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Crnom Gorom
                                ima zaključen ugovor o izručenju od
                                15.11.2012.godine (\"Službeni glasnik BiH\" –
                                Međunarodni ugovori broj: 10/13, u daljem tekstu
                                \"Ugovor\"), koji pod određenim uslovima,
                                propisuje mogućnost izručenja vlastitith
                                državljana u cilju izvršenja pravosnažno
                                izrečene kazne zatvora. Tako je članom 7. stav
                                3. Ugovora propisano da će se izručеnjе
                                vlastitih držаvlјаnа rаdi izvršеnjа prаvоsnаžnо
                                izrеčеnе kаznе zаtvоrа ili mjеrе kоја uklјučuје
                                lišenje slоbоdе dоzvоliti zа krivičnа djеlа iz
                                stavova 1. i 2. istog člаnа (оrgаniziranog
                                kriminаlа, kоrupciје, prаnja nоvcа i drugih
                                teških krivičnih djela) аkо izrеčеnа kаznа
                                zаtvоrа ili mjеrа kоја uklјučuје lišnje slоbоdе,
                                оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši,
                                iznоsi nајmаnjе dvijе gоdinе.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}{" "}
                  {/*preoces ili izvrsenje NIJE false */}
                </>
              ) : DRZMOLITELJICA.ime === "Sjeverna Makedonija" ? (
                <>
                  {procesuiranje === false && izvrsenje === false ? (
                    <>
                      <Alert variant="warning alertV2">
                        Izaberite <strong>razlog izručenja</strong>.
                      </Alert>{" "}
                    </>
                  ) : (
                    <>
                      {osnoviugovor === "" ? (
                        <>
                          <Alert variant="warning alertV2">
                            Izaberite{" "}
                            <strong>član ugovora (osnov za izručenje)</strong>.
                          </Alert>
                        </>
                      ) : osnoviugovor === "PugovorMAKclan9a1" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 9a. stav 1. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје, krivičnо
                                djеlо prаnjе nоvcа, kао i zа оstаle oblike
                                organiziranog kriminala zа kоја је prеmа prаvu
                                оbjе držаvе ugоvоrnicе prоpisаnа kаznа zаtvоrа u
                                trајаnju оd četiri gоdine ili tеžа kаznа,
                                оdnоsnо mjеrа kоја uklјučuје оduzimаnjе slоbоdе.
                                Stavom 4. i 5. istog člana preciznije su
                                određena krivična djela odnosno, kategorije
                                krivičnih djela organiziranog kriminala i
                                korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Sjevernom Makedonijom ima zaključen ugovor o
                                izručenju od 27.01.2006. godine (\"Službeni
                                glasnik BiH\" - Međunarodni ugovori broj:14/06),
                                koji pod određenim uslovima, propisuje mogućnost
                                izručenja vlastitith državljana u cilju
                                krivičnog gonjenja. Tako je članom 9a. stav 1.
                                Ugovora propisano da će se izručеnjе vlastitih
                                držаvlјаnа zbоg krivičnоg gоnjеnjа dоzvоliti zа
                                krivičnа djеlа оrgаniziranog kriminаlа i
                                kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа
                                оstаle oblike organiziranog kriminala zа kоја је
                                prеmа prаvu оbjе držаvе ugоvоrnicе prоpisаnа
                                kаznа zаtvоrа u trајаnju оd četiri gоdine ili
                                tеžа kаznа, оdnоsnо mjеrа kоја uklјučuје
                                оduzimаnjе slоbоdе. Stavom 4. i 5. istog člana
                                preciznije su određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor ===
                        "PugovorMAKclan9a1,IugovorMAKclan9a2" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 9a. stav 1. Ugovora propisano da
                                će se izručеnjе vlastitih držаvlјаnа zbоg
                                krivičnоg gоnjеnjа dоzvоliti zа krivičnа djеlа
                                оrgаniziranog kriminаlа i kоrupciје, krivičnо
                                djеlо prаnjе nоvcа, kао i zа оstаle oblike
                                organiziranog kriminala zа kоја је prеmа prаvu
                                оbjе držаvе ugоvоrnicе prоpisаnа kаznа zаtvоrа u
                                trајаnju оd četiri gоdine ili tеžа kаznа,
                                оdnоsnо mjеrа kоја uklјučuје оduzimаnjе slоbоdе.
                                Istovremeno, članom 9a. stav 2. Ugovora
                                propisano je da se izručеnjе vlastitih
                                držаvlјаnа rаdi izvršеnjа prаvоsnаžnо izrеčеnе
                                kаznе zаtvоrа ili mjеrе kоја uklјučuје
                                оduzimаnjе slоbоdе može dоzvоliti zа krivičnа
                                djеlа оrgаniziranog kriminаlа i kоrupciје,
                                krivičnо djеlо prаnjе nоvcа, kао i zа оstаle
                                oblike оrgаniziranog kriminala iz stаvа 1. оvоg
                                člаnа, аkо izrеčеnа kаznа zаtvоrа ili mjеrа kоја
                                uklјučuје оduzimаnjе slоbоdе, оdnоsnо njihоv
                                оstаtаk kојi trеbа dа sе izvrši, iznоsi nајmаnjе
                                dvijе gоdinе. Stavom 4. i 5. istog člana
                                preciznije su određena krivična djela odnosno,
                                kategorije krivičnih djela organiziranog
                                kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana kako u
                                cilju krivičnog gonjenja tako i izvršenja
                                pravosnažno izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Sjevernom Makedonijom ima zaključen ugovor o
                                izručenju od 27.01.2006. godine (\"Službeni
                                glasnik BiH\" - Međunarodni ugovori broj:14/06),
                                koji pod određenim uslovima, propisuje mogućnost
                                izručenja vlastitith državljana kako u cilju
                                krivičnog gonjenja tako i izvršenja pravosnažno
                                izerčene kazne zatvora. Tako je članom 9a. stav
                                1. Ugovora propisano da će se izručеnjе
                                vlastitih držаvlјаnа zbоg krivičnоg gоnjеnjа
                                dоzvоliti zа krivičnа djеlа оrgаniziranog
                                kriminаlа i kоrupciје, krivičnо djеlо prаnjе
                                nоvcа, kао i zа оstаle oblike organiziranog
                                kriminala zа kоја је prеmа prаvu оbjе držаvе
                                ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd
                                četiri gоdine ili tеžа kаznа, оdnоsnо mjеrа kоја
                                uklјučuје оduzimаnjе slоbоdе. Istovremeno,
                                članom 9a. stav 2. Ugovora propisano je da se
                                izručеnjе vlastitih držаvlјаnа rаdi izvršеnjа
                                prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе
                                kоја uklјučuје оduzimаnjе slоbоdе može dоzvоliti
                                zа krivičnа djеlа оrgаniziranog kriminаlа i
                                kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа
                                оstаle oblike оrgаniziranog kriminala iz stаvа
                                1. оvоg člаnа, аkо izrеčеnа kаznа zаtvоrа ili
                                mjеrа kоја uklјučuје оduzimаnjе slоbоdе, оdnоsnо
                                njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi
                                nајmаnjе dvijе gоdinе. Stavom 4. i 5. istog
                                člana preciznije su određena krivična djela
                                odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : osnoviugovor === "IugovorMAKclan9a2" ? (
                        <>
                          <p style={pJ}>
                            {vrstaOdluke === "PR" ? (
                              <>
                                Tako je članom 9a. stav 2. Ugovora propisano da
                                se izručеnjе vlastitih držаvlјаnа rаdi izvršеnjа
                                prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе
                                kоја uklјučuје оduzimаnjе slоbоdе može dоzvоliti
                                zа krivičnа djеlа оrgаniziranog kriminаlа i
                                kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа
                                оstаle oblike оrgаniziranog kriminala iz stаvа
                                1. оvоg člаnа, аkо izrеčеnа kаznа zаtvоrа ili
                                mjеrа kоја uklјučuје оduzimаnjе slоbоdе, оdnоsnо
                                njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi
                                nајmаnjе dvijе gоdinе. Stavom 4. i 5. istog
                                člana preciznije su određena krivična djela
                                odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : vrstaOdluke === "PRO" ? (
                              <>
                                Sud podsjeća da je između Bosne i Hercegovine i{" "}
                                {DRZMOLITELJICA.gen || "__________"} zaključen
                                ugovor o izručenju koji pod određenim uslovima
                                dozvoljava izručenje vlastitih državljana u
                                cilju krivičnog gonjenja izvršenja pravosnažno
                                izrečene kazne zatvora.
                              </>
                            ) : vrstaOdluke === "EKS" ? (
                              <>
                                Iako iz stanja u spisu proizilazi da {potraživani}{" "}
                                {IMELICA.ime || "__________"}{" "}
                                {posjedujedrzavljanstvo} Sud je, kod donošenja
                                odluke o ekstradicionom pritvoru, imao u vidu
                                činjenicu da Bosna i Hercegovina sa Republikom
                                Sjevernom Makedonijom ima zaključen ugovor o
                                izručenju od 27.01.2006. godine (\"Službeni
                                glasnik BiH\" - Međunarodni ugovori broj:14/06),
                                koji pod određenim uslovima, propisuje mogućnost
                                izručenja vlastitith državljana u cilju
                                izvršenja pravosnažno izrečene kazne zatvora.
                                Tako je članom 9a. stav 2. Ugovora propisano da
                                se izručеnjе vlastitih držаvlјаnа rаdi izvršеnjа
                                prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе
                                kоја uklјučuје оduzimаnjе slоbоdе može dоzvоliti
                                zа krivičnа djеlа оrgаniziranog kriminаlа i
                                kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа
                                оstаle oblike оrgаniziranog kriminala iz stаvа
                                1. оvоg člаnа, аkо izrеčеnа kаznа zаtvоrа ili
                                mjеrа kоја uklјučuје оduzimаnjе slоbоdе, оdnоsnо
                                njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi
                                nајmаnjе dvijе gоdinе. Stavom 4. i 5. istog
                                člana preciznije su određena krivična djela
                                odnosno, kategorije krivičnih djela
                                organiziranog kriminala i korupcije.
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}{" "}
                  {/*preoces ili izvrsenje NIJE false */}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {/*DrzavljaninBiH*/}
        </>
      )}
      {/*ugovorodredbe*/}
      {props.kod === "PROaktnaznacenupotjernici" && (
        <>
          {procesuiranje === false && izvrsenje === false ? (
            <>
              U konkretnom slučaju u potjernici je naveden akt koji se zahtjeva
              po članu 16. stav 2. Evropske konvencije o ekstradiciji, a to je
              nalog za hapšenje {potraživanog} {IMELICA.imegen || "__________"}, broj:{" "}
              {procesuiranjebrojnaloga || "__________"} koji je izdao{" "}
              {procesuiranjenazivsudanalog || "__________"} pri čemu je
              naznačeno i krivično djelo za koje se traži izručenje. Slijedom
              navedenog Sud smatra da je navedena potjernica sačinjena u skladu
              sa Evropskom konvencijom o ekstradiciji i kao takva predstavlja
              validan osnov za zakonito određivanje ili produženje privremenog
              pritvora prema {potraživanom}.
            </>
          ) : procesuiranje === true && izvrsenje === false ? (
            <>
              U konkretnom slučaju u potjernici je naveden akt koji se zahtjeva
              po članu 16. stav 2. Evropske konvencije o ekstradiciji, a to je
              nalog za hapšenje {potraživanog} {IMELICA.imegen || "__________"}, broj:{" "}
              {procesuiranjebrojnaloga || "__________"} koji je izdao{" "}
              {procesuiranjenazivsudanalog || "__________"} pri čemu je
              naznačeno i krivično djelo za koje se traži izručenje. Slijedom
              navedenog Sud smatra da je navedena potjernica sačinjena u skladu
              sa Evropskom konvencijom o ekstradiciji i kao takva predstavlja
              validan osnov za zakonito određivanje ili produženje privremenog
              pritvora prema {potraživanom}.
            </>
          ) : procesuiranje === false && izvrsenje === true ? (
            <>
              U konkretnom slučaju u potjernici je naveden akt koji se zahtjeva
              po članu 16. stav 2. Evropske konvencije o ekstradiciji, a to je
              nalog za hapšenje {potraživanog} {IMELICA.imegen || "__________"}, broj:{" "}
              {izvrsenjebrojnaloga || "__________"} koji je izdao{" "}
              {izvrsenjenazivsudanalog || "__________"} pri čemu je naznačeno i
              krivično djelo za koje se traži izručenje. Slijedom navedenog Sud
              smatra da je navedena potjernica sačinjena u skladu sa Evropskom
              konvencijom o ekstradiciji i kao takva predstavlja validan osnov
              za zakonito određivanje ili produženje privremenog pritvora prema
              {potraživanom}.
            </>
          ) : procesuiranje === true && izvrsenje === true ? (
            <>
              U konkretnom slučaju u potjernici su navedeni akti koji se
              zahtjevaju po članu 16. stav 2. Evropske konvencije o
              ekstradiciji, a to je nalog za hapšenje {potraživanog}{" "}
              {IMELICA.imegen || "__________"}, broj:{" "}
              {procesuiranjebrojnaloga || "__________"} koji je izdao{" "}
              {procesuiranjenazivsudanalog || "__________"}i nalog za hapšenje
              broj: {izvrsenjebrojnaloga || "__________"} koji je izdao
              {izvrsenjenazivsudanalog || "__________"} pri čemu su naznačena i
              krivična djela za koja se traži izručenje. Slijedom navedenog Sud
              smatra da je navedena potjernica sačinjena u skladu sa Evropskom
              konvencijom o ekstradiciji i kao takva predstavlja validan osnov
              za zakonito određivanje ili produženje privremenog pritvora prema
              {potraživanom}.
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "PROrelevantneodredbe" && (
        <>
          <p style={pL}><strong>Relevantne odredbe</strong></p>
          <p style={pJ}>Član 17. Zakona o izmjenama i dopunama Zakona o međunarodnoj
            pravnoj pomoći u krivičnim stvarima, kojim je izmjenjen član 38.
            osnovnog teksta Zakona, u stavu 5. propisuje da se privremeni pritvor
            u trajanju od 18 (osamnaest) dana <i>"može produžiti, ali ne može biti
              duži od 40 (četrdeset) dana"</i>.<br />
            <br />Član 16. stav 4. Evropske konvencije o ekstradiciji propisuje
            da se <i>"privremeno hapšenje može ukinuti ako zahtjev za izručenjem i
              isprave navedene u članu 12. Evropske konvencije o ekstradiciji ne
              budu dostavljene zamoljenoj strani u roku od 18 dana od hapšenja, a da
              rok ni u kojem slučaju ne može preći 40 dana od hapšenja"</i>. <br />
            <br />Cijeneći navedeno, sudija za prethodni postupak je zaključio da
            su se stekli zakonski uslovi za produženje privremenog pritvora iz
            istih osnova iz kojih je pritvor određen.</p>

        </>
      )}
      {props.kod === "PROzahtjevinformacije" && (
        <>
          {PROzahtjev === "" ? (
            <>
              <Alert variant="warning alertV2">
                Preciziraj <strong>postoji li zahtjev</strong> za produženje
                privremenog pritvora.
              </Alert>
            </>
          ) : PROzahtjev === "NE" ? (
            <></>
          ) : PROzahtjev === "DA" ? (
            <>
              {PROzahtjevNCB === false && PROzahtjevMPBIH === false ? (
                <>
                  <Alert variant="warning alertV2">
                    Preciziraj <strong>kako je dostavljen</strong> zahtjev
                    zaproduženje privremenog pritvora.
                  </Alert>
                </>
              ) : PROzahtjevNCB === true && PROzahtjevMPBIH === false ? (
                <>
                  <p style={pJ}>
                    S tim u vezi Sudu BiH je dana{" "}
                    {PROzahtjevNCBdatumprijema || "__________"} zaprimio dopis
                    NCB Interpola Sarajevo, broj:{" "}
                    {PROzahtjevNCBbroj || "__________"} u kojem je sadržan
                    zahtjev države moliteljice za produženje roka na 40 dana za
                    kompletiranje i dostavljanje dokumentacije odnosno,
                    produženje mjere privremenog pritvora prema {potraživanom}.{" "}
                    <Snipet kod="PROzahtjevizjasnjenjeodbrane" />
                  </p>
                </>
              ) : PROzahtjevNCB === false && PROzahtjevMPBIH === true ? (
                <>
                  <p style={pJ}>
                    S tim u vezi Sudu BiH je dana{" "}
                    {PROzahtjevMPBIHdatumprijema || "__________"} zaprimio dopis
                    Ministarstva pravde BiH, broj:{" "}
                    {PROzahtjevMPBIHbroj || "__________"} u prilogu kojeg je
                    dostavljen zahtjev države moliteljice za produženje roka na
                    40 dana za kompletiranje i dostavljanje dokumentacije
                    odnosno, produženje mjere privremenog pritvora prema
                    {potraživanom}. <Snipet kod="PROzahtjevizjasnjenjeodbrane" />
                  </p>
                </>
              ) : PROzahtjevNCB === true && PROzahtjevMPBIH === true ? (
                <>
                  <p style={pJ}>
                    S tim u vezi Sudu BiH je u nekoliko navrata i to dopisima
                    NCB Interpola Sarajevo, broj:{" "}
                    {PROzahtjevNCBdatumprijema || "__________"} i Ministarstva
                    pravde BiH, broj: {PROzahtjevMPBIHbroj || "__________"}{" "}
                    obaviješten o zahtjevu nadležnog organa države moliteljice
                    za produženje roka na 40 dana u cilju kompletiranja i
                    dostavljanja dokumentacije odnosno, produženje mjere
                    privremenog pritvora prema {potraživanom}.{" "}
                    <Snipet kod="PROzahtjevizjasnjenjeodbrane" />
                  </p>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "PROzahtjevizjasnjenjeodbrane" && (
        <>
          {PROzahtjev === "DA" && (
            <>
              {PROzahtjevNCB === true && PROzahtjevMPBIH === false ? (
                <>
                  {PROzahtjevdostavljenBR === "" ? (
                    <>
                      <br />
                      <br />
                      <Alert variant="warning alertV2">
                        Preciziraj{" "}
                        <strong>
                          da li je zhtjev za produženje dostavljen odbrani
                        </strong>
                        .
                      </Alert>
                    </>
                  ) : PROzahtjevdostavljenBR === "DA" ? (
                    <>
                      <br />
                      <br />
                      Sud je navedeni dopis dostavio na izjašnjenje {dvabranioca ? "braniocima" : "braniocu"}{" "}{potraživanog}. <br />
                      <br />
                      {dvabranioca ?
                        <>
                          <strong>{BRANILAC[0].spol === "m" ? "Branilac" : "Braniteljica"} {potraživanog}</strong>, advokat {BRANILAC[0].ime || "_________"} je u svom izjašnjenju {BRANILAC[0].spol === "m" ? `naveo` : `navela`} da
                          <strong>{BRANILAC[1].spol === "m" ? `Drugi izabrani branilac` : `Druga izabrana braniteljica`}</strong> {potraživanog}, advokat {BRANILAC[1].ime || "_________"} je u svom izjašnjenju {BRANILAC[1].spol === "m" ? `naveo` : `navela`} da
                        </> :
                        <>
                          <strong>{BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "Branilac" : "Braniteljica") : "Branilac"} {potraživanog}</strong>, advokat {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} je u svom izjašnjenju {BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "naveo" : "navela") : "naveo"} da
                        </>}
                    </>
                  ) : PROzahtjevdostavljenBR === "NE" ? (
                    <></>
                  ) : (
                    <></>
                  )}
                </>
              ) : PROzahtjevNCB === false && PROzahtjevMPBIH === true ? (
                <>
                  {PROzahtjevdostavljenBR === "" ? (
                    <>
                      <br />
                      <br />
                      <Alert variant="warning alertV2">
                        Preciziraj{" "}
                        <strong>
                          da li je zhtjev za produženje dostavljen odbrani
                        </strong>
                        .
                      </Alert>
                    </>
                  ) : PROzahtjevdostavljenBR === "DA" ? (
                    <>
                      <br />
                      <br />
                      Sud je navedeni dopis dostavio na izjašnjenje {dvabranioca ? "braniocima" : "braniocu"}{" "}{potraživanog}. <br />
                      <br />
                      {dvabranioca ?
                        <>
                          <strong>{BRANILAC[0].spol === "m" ? "Branilac" : "Braniteljica"} {potraživanog}</strong>, advokat {BRANILAC[0].ime || "_________"} je u svom izjašnjenju {BRANILAC[0].spol === "m" ? `naveo` : `navela`} da
                          <strong>{BRANILAC[1].spol === "m" ? `Drugi izabrani branilac` : `Druga izabrana braniteljica`}</strong> {potraživanog}, advokat {BRANILAC[1].ime || "_________"} je u svom izjašnjenju {BRANILAC[1].spol === "m" ? `naveo` : `navela`} da
                        </> :
                        <>
                          <strong>{BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "Branilac" : "Braniteljica") : "Branilac"} {potraživanog}</strong>, advokat {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} je u svom izjašnjenju {BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "naveo" : "navela") : "naveo"} da
                        </>}
                    </>
                  ) : PROzahtjevdostavljenBR === "NE" ? (
                    <></>
                  ) : (
                    <></>
                  )}
                </>
              ) : PROzahtjevNCB === true && PROzahtjevMPBIH === true ? (
                <>
                  {PROzahtjevdostavljenBR === "" ? (
                    <>
                      <br /> <br />
                      <Alert variant="warning alertV2">
                        Preciziraj{" "}
                        <strong>
                          da li su zhtjei za produženje dostavljei odbrani
                        </strong>
                        .
                      </Alert>
                    </>
                  ) : PROzahtjevdostavljenBR === "DA" ? (
                    <>
                      <br />
                      <br />
                      Sud je navedene dopise dostavio na izjašnjenje {dvabranioca ? "braniocima" : "braniocu"}{" "}{potraživanog}. <br />
                      <br />
                      {dvabranioca ?
                        <>
                          <strong>{BRANILAC[0].spol === "m" ? "Branilac" : "Braniteljica"} {potraživanog}</strong>, advokat {BRANILAC[0].ime || "_________"} je u svom izjašnjenju {BRANILAC[0].spol === "m" ? `naveo` : `navela`} da
                          <strong>{BRANILAC[1].spol === "m" ? `Drugi izabrani branilac` : `Druga izabrana braniteljica`}</strong> {potraživanog}, advokat {BRANILAC[1].ime || "_________"} je u svom izjašnjenju {BRANILAC[1].spol === "m" ? `naveo` : `navela`} da
                        </> :
                        <>
                          <strong>{BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "Branilac" : "Braniteljica") : "Branilac"} {potraživanog}</strong>, advokat {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} je u svom izjašnjenju {BRANILAC.length > 0 ? (BRANILAC[0]?.spol === "m" ? "naveo" : "navela") : "naveo"} da
                        </>}
                    </>
                  ) : PROzahtjevdostavljenBR === "NE" ? (
                    <></>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      {props.kod === "PROsvrhaprivremenog" && (
        <>
          {PROzahtjev === "" ? (
            <>
              Naime, iz naprijed citiranih odredbi proizilazi da je svrha
              određivanja i produženja privremenog pritvora prema {potraživanom}
              da se državi moliteljici ostavi dovoljno vremena za dostavu molbe
              za izručenje i ekstradicione dokumentacije, u zbog čega se
              određuje privremeni pritvor, koji se, po potrebi, može produžiti
              za najduže 40 dana od dana lišenja slobode. Članom 17. Zakona o
              izmjenama i dopunama Zakona o međunarodnoj pravnoj pomoći u
              krivičnim stvarima, promijenjen je član 38. koji u stavu 4
              propisuje određivanje privremenog pritvora od 18 dana, dok u stavu
              5. predviđa da se taj rok može produžiti, ali ne može biti duži od
              40 dana. Stav 6. citirane zakonske odredbe propisuje da će Sud
              BiH, u slučaju da u rokovima predviđenim za dostavljanje molbe za
              izručenje i ekstradicione dokumentacije iste ne budu dostavljenje,
              donijeti rješenje o ukidanju privremenog pritvora licu čije se
              izručenje traži. Dakle, svrha određivanja i produženja privremenog
              pritvora prema {potraživanom} licu jeste da se državi moliteljici
              ostavi određeno vrijeme za dostavu molbe za izručenje i
              ekstradicione dokumentacije, u koju svrhu se određuje privremeni
              pritvor, a koji se, po potrebi, može produžiti za najduže 40 dana
              od dana lišenja slobode. Navedeno je u skladu i sa članom 16. stav
              4. Evropske konvencije o ekstradiciji, po kojem se privremeni
              pritvor može ukinuti ako zahtjev za izručenje i potrebna
              dokumentacija ne budu dostavljeni zamoljenoj strani u roku od 18
              dana od pritvaranja, koji rok ne može, ni u kom slučaju, preći 40
              dana od pritvaranja. U konkretnom slučaju {potraživani} se nalazi u
              pritvoru u trajanju od 18 dana od dana lišenja slobode u okviru
              kojeg država moliteljica od momenta zaprimanja obavijesti o
              lišenju {potraživanog} nije dostavila molbu za izručenje sa
              relevantnom ekstradicionom dokumentacijom. <br />
              <br />Imajući u vidu da relevantne zakonske odredbe, sa formalnog
              aspekta, ne zahtijevaju podnošenje posebnog prijedloga države
              moliteljice za produženje mjere privremenog pritvora Sud je,
              imajući u vidu sve okolnosti konkretnog slučaja, ocijenio
              opravdanim produžiti mjeru privremenog pritvora prema
              {potraživanom}. Dakle, Sud cijeni da kod odlučivanja o daljem
              trajanju privremenog pritvora i u ovoj početnoj fazi ima
              ovlaštenja da postupa samo na osnovu potjernice iz koje je
              vidljivo da sadrži sve podatke shodno članu 16. stav 2. Evropske
              konvencije o ekstradiciji. Pomenuti član propisuje da je u
              potjernici, između ostalog, potrebno naznačiti postojanje jedne od
              isprava predviđenih u stavu 2. tačka a) člana 12. navedene
              Konvencije (odluka o izvršenju kazne, rješenje o hapšenju ili bilo
              koji drugi akt istog pravnog dejstva koji se izdaje na način
              propisan zakonom strane moliteljice).
              <br />
              <br />
              <Snipet kod="PROaktnaznacenupotjernici" />
            </>
          ) : PROzahtjev === "NE" ? (
            <>
              Naime, iz naprijed citiranih odredbi proizilazi da je svrha
              određivanja i produženja privremenog pritvora prema {potraživanom}
              da se državi moliteljici ostavi dovoljno vremena za dostavu molbe
              za izručenje i ekstradicione dokumentacije, u zbog čega se
              određuje privremeni pritvor, koji se, po potrebi, može produžiti
              za najduže 40 dana od dana lišenja slobode. Naime, članom 17.
              Zakona o izmjenama i dopunama Zakona o međunarodnoj pravnoj pomoći
              u krivičnim stvarima, promijenjen je član 38. koji u stavu 4
              propisuje određivanje privremenog pritvora od 18 dana, dok u stavu
              5. predviđa da se taj rok može produžiti, ali ne može biti duži od
              40 dana. Stav 6. citirane zakonske odredbe propisuje da će Sud
              BiH, u slučaju da u rokovima predviđenim za dostavljanje molbe za
              izručenje i ekstradicione dokumentacije iste ne budu dostavljenje,
              donijeti rješenje o ukidanju privremenog pritvora licu čije se
              izručenje traži. Dakle, svrha određivanja i produženja privremenog
              pritvora prema {potraživanom} licu jeste da se državi moliteljici
              ostavi određeno vrijeme za dostavu molbe za izručenje i
              ekstradicione dokumentacije, u koju svrhu se određuje privremeni
              pritvor, a koji se, po potrebi, može produžiti za najduže 40 dana
              od dana lišenja slobode. Navedeno je u skladu i sa članom 16. stav
              4. Evropske konvencije o ekstradiciji, po kojem se privremeni
              pritvor može ukinuti ako zahtjev za izručenje i potrebna
              dokumentacija ne budu dostavljeni zamoljenoj strani u roku od 18
              dana od pritvaranja, koji rok ne može, ni u kom slučaju, preći 40
              dana od pritvaranja. U konkretnom slučaju {potraživani} se nalazi u
              pritvoru u trajanju od 18 dana od dana lišenja slobode u okviru
              kojeg država moliteljica od momenta zaprimanja obavijesti o
              lišenju {potraživanog} nije dostavila molbu za izručenje sa
              relevantnom ekstradicionom dokumentacijom. <br />
              <br /> Imajući u vidu da relevantne zakonske odredbe, sa formalnog
              aspekta, ne zahtijevaju podnošenje posebnog prijedloga države
              moliteljice za produženje mjere privremenog pritvora Sud je,
              imajući u vidu sve okolnosti konkretnog slučaja, ocijenio
              opravdanim produžiti mjeru privremenog pritvora prema
              {potraživanom}. Dakle, Sud cijeni da kod odlučivanja o daljem
              trajanju privremenog pritvora i u ovoj početnoj fazi ima
              ovlaštenja da postupa samo na osnovu potjernice iz koje je
              vidljivo da sadrži sve podatke shodno članu 16. stav 2. Evropske
              konvencije o ekstradiciji. Pomenuti član propisuje da je u
              potjernici, između ostalog, potrebno naznačiti postojanje jedne od
              isprava predviđenih u stavu 2. tačka a) člana 12. navedene
              Konvencije (odluka o izvršenju kazne, rješenje o hapšenju ili bilo
              koji drugi akt istog pravnog dejstva koji se izdaje na način
              propisan zakonom strane moliteljice).
              <Snipet kod="PROaktnaznacenupotjernici" />
            </>
          ) : PROzahtjev === "DA" ? (
            <>
              Naime, iz naprijed citiranih odredbi proizilazi da je svrha
              određivanja i produženja privremenog pritvora prema {potraživanom}
              da se državi moliteljici ostavi dovoljno vremena za dostavu molbe
              za izručenje i ekstradicione dokumentacije, u zbog čega se
              određuje privremeni pritvor, koji se, po potrebi, može produžiti
              za najduže 40 dana od dana lišenja slobode. Naime, članom 17.
              Zakona o izmjenama i dopunama Zakona o međunarodnoj pravnoj pomoći
              u krivičnim stvarima, promijenjen je član 38. koji u stavu 4
              propisuje određivanje privremenog pritvora od 18 dana, dok u stavu
              5. predviđa da se taj rok može produžiti, ali ne može biti duži od
              40 dana. Stav 6. citirane zakonske odredbe propisuje da će Sud
              BiH, u slučaju da u rokovima predviđenim za dostavljanje molbe za
              izručenje i ekstradicione dokumentacije iste ne budu dostavljenje,
              donijeti rješenje o ukidanju privremenog pritvora licu čije se
              izručenje traži. Dakle, svrha određivanja i produženja privremenog
              pritvora prema {potraživanom} licu jeste da se državi moliteljici
              ostavi određeno vrijeme za dostavu molbe za izručenje i
              ekstradicione dokumentacije, u koju svrhu se određuje privremeni
              pritvor, a koji se, po potrebi, može produžiti za najduže 40 dana
              od dana lišenja slobode. Navedeno je u skladu i sa članom 16. stav
              4. Evropske konvencije o ekstradiciji, po kojem se privremeni
              pritvor može ukinuti ako zahtjev za izručenje i potrebna
              dokumentacija ne budu dostavljeni zamoljenoj strani u roku od 18
              dana od pritvaranja, koji rok ne može, ni u kom slučaju, preći 40
              dana od pritvaranja. U konkretnom slučaju {potraživani} se nalazi u
              pritvoru u trajanju od 18 dana od dana lišenja slobode, a kako je
              država moliteljica {DRZMOLITELJICA.ime || "__________"}, zatražila
              produženje roka za dostavljanje ekstradicione dokumentacije,
              evidentno je da država moliteljica preduzima sve radnje u cilju
              realizacije dostavljanja molbe i prateće ekstradicione
              dokumentacije.
              <br />
              <br />Pored navedenog, Sud nalazi bitnim napomenuti da kod
              odlučivanja o daljem trajanju privremenog pritvora i u ovoj
              početnoj fazi ima ovlaštenja da postupa samo na osnovu potjernice
              iz koje je vidljivo da sadrži sve podatke shodno članu 16. stav 2.
              Evropske konvencije o ekstradiciji. Pomenuti član propisuje da je
              u potjernici, između ostalog, potrebno naznačiti postojanje jedne
              od isprava predviđenih u stavu 2. tačka a) člana 12. navedene
              Konvencije (odluka o izvršenju kazne, rješenje o hapšenju ili bilo
              koji drugi akt istog pravnog dejstva koji se izdaje na način
              propisan zakonom strane moliteljice). <br />
              <br /><Snipet kod="PROaktnaznacenupotjernici" /> <br />
              <br />Osim toga, iz stanja u spisu, jasno proizilazi da je zemlja
              moliteljica istakla zahtjev za produženje roka za dostavu
              ekstradicione dokumentacije, odnosno roka za produženje
              privremenog pritvora.
            </>
          ) : (
            <></>
          )}
        </>
      )}
      {props.kod === "EKSclan39" && (
        <>
          Članom 18. Zakona o izmjenama i dopunama Zakona o međunarodnoj pravnoj
          pomoći u krivičnim stvarima („Sl. glasnik BiH”, broj 58/13) izmjenjen
          je član 39. osnovnog teksta Zakona, koji propisuje određivanje
          ekstradicionog pritvora. Tako je izmijenjenim članom 39. u stavu 1.
          između ostalog propisano da: „
          <i>
            Po prijemu molbe za izručenje, sudija za prethodni postupak Suda
            Bosne i Hercegovine, na prijedlog tužioca iz člana 36. stav (3) ovog
            zakona, određuje rješenjem ekstradicioni pritvor ako: a) postoji
            opasnost da će lice, čija se ekstradicija traži, izbjeći postupak
            izručenja ili provođenje izručenja …
          </i>
          ” .<br />
          <br />
          Stavom 4) istog člana propisano je da “
          <i>
            Pritvor neće biti određen ako je iz same molbe za izručenje
            očigledno da nema mjesta izručenju.
          </i>
        </>
      )}
    </>
  );
};

export default Snipet;
