
import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import { useGlobalContext } from '../src/context'

import Inputi from './komponente/Inputi'
import Odluka from './komponente/Odluka'
import Dodatneopcije from './komponente/Dodatneopcije'
import UgovorInformacije from './komponente/dodatneopcije/UgovorInformacije';
import { DodajGresku } from './komponente/dodatneopcije/Dodajstranku';

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import CloseButton from 'react-bootstrap/CloseButton';
import { Check2Square } from "react-bootstrap-icons";
import './App.css';
import 'react-datepicker/dist/react-datepicker.css'
import Todo from './komponente/testing/Todo';
import PregledStranaka from './komponente/liste/PregledStranaka';

const App = () => {
  const { dodajGreskuCanvas, kontrolisiCanvas } = useGlobalContext();
  const [logovan, setLogovan] = useState(localStorage.getItem('logovan') === 'true');

  // Effect to update local storage when logovan changes
  useEffect(() => {
    localStorage.setItem('logovan', logovan.toString());
  }, [logovan]);


  // Jednostavna login forma (samo vrši komparaciju otvorenih podataka)
  const Loginforma = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [greskapw, setGreskapw] = useState(false);

    const handleSubmit = event => {
      event.preventDefault();

      if (username === "odluka" && password === "odluka") {     //username === auth.username && password === auth.password Ne radi na lošoj konekciji ...
        setLogovan(true)
      }
      else {
        setLogovan(false)
        setGreskapw(true)
      }
    };

    return (

      <Form onSubmit={handleSubmit} >

        <Form.Group className="mb-2" controlId="korisnickoime">
          <Form.Control type="username" placeholder="Korisničko ime" value={username}
            onChange={e => setUsername(e.target.value)} autoComplete="on" size="lg" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="sifra">
          <Form.Control type="password" placeholder="Šifra"
            value={password}
            onChange={e => setPassword(e.target.value)} autoComplete="on" size="lg"/>
        </Form.Group>
        <div className="d-grid gap-2">
          <Button variant="primary" type='submit'>
            Prijavi se
          </Button>
        </div>
        <br />
        {greskapw && <Alert variant="warning alertV2">
          Netačni podaci.
        </Alert>}
      </Form>
    )
  };

  return (
    <>
      {

logovan ?
<>

      <Router>
        <ThemeProvider ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} >
          <Navbar bg="dark" variant="dark">
            <Container>
              <Nav className="me-auto">
                <NavLink to="/" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link inactive')}>EKSTRADICIJA</NavLink>
                <Nav.Link href="http://www.odluka.ba/izreka">IZREKA</Nav.Link>
                <Nav.Link href="http://www.odluka.ba/finalizacija">FINALIZACIJA</Nav.Link>
                </Nav>
             
         
              <CloseButton variant="white" placement="end" onClick={e => setLogovan(false)} />
            </Container>
          </Navbar>
          <Container className="p-1" style={{ maxWidth: "1400px" }}>
            <Routes>

              <Route path='/' exact element={<Row>
                <Col sm={12}>
                  <UgovorInformacije />
                </Col>
                <Col sm={3}>

                  <Inputi />
                </Col>
                <Col sm={7} >
                  <Odluka />
                </Col>
                <Col sm={2}>
                  <Dodatneopcije />
                </Col>
              </Row>}
              />

              <Route path="/pregledstranaka/:subRoute" element={<Row>
    
                <Col>
                <PregledStranaka />
                </Col>

              </Row>} />

              <Route path='/pregledstranaka' exact element={<Row>
    
                <Col>
                <PregledStranaka />
                </Col>

              </Row>}
              />

            </Routes>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">

            <Button variant="outline-secondary" size="sm"
                onClick={() => kontrolisiCanvas("CANVAS_DODAJSTRANKU_OTVORI", "greška")}
                >
              Prijavi grešku
                </Button>{' '}

                <Offcanvas style={{ "width": "600px" }} show={dodajGreskuCanvas} onHide={() => kontrolisiCanvas("CANVAS_DODAJSTRANKU_ZATVORI", "greška")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Prijavi grešku</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DodajGresku />
                    </Offcanvas.Body>
                </Offcanvas>
  </footer>

          </Container>

        </ThemeProvider >
      </Router>
</>
:
<>
<ThemeProvider ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} >
<Container className='d-flex flex-column min-vh-100 justify-content-center align-items-center' >
<Loginforma/>
</Container> 
    </ThemeProvider >

        </> }

    </>


  );
};


export default App;
