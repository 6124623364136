import { useState, forwardRef } from 'react';
import { useGlobalContext } from '../../context'
import DatePicker from "react-datepicker";

import { Plus, Dash, Calendar2Check,  Pencil, PencilFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CloseButton from 'react-bootstrap/CloseButton';


import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import Card from 'react-bootstrap/Card';

const EKSdodatne = () => {
    const { EKSopcijeCanvas, kontrolisiCanvas, PROpostoji, PRfaza, PROfaza, datumodlukePRTMP, datumodlukePR, datumodlukePROTMP, datumodlukePRO, inputChange, EKSbrojprijedloga, EKSbrojmolbe
    } = useGlobalContext()


    const [postavkePR, setPostavkePR] = useState(false);
    const [postavkePRO, setPostavkePRO] = useState(false);


    const KalendarBTN = forwardRef(({ value, onClick }, ref) => (
        <Button variant="secondary" onClick={onClick} ref={ref} style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Calendar2Check /> Kalendar
    </Button>
    
      ))


    return (
        <>

            <div className="d-grid gap-2">
                <Button variant="outline-secondary obavezno mb-2"
                    onClick={(e) => kontrolisiCanvas("CANVAS_EKSOPCIJE_OTVORI")}
                >
                    Dodatne opcije
                </Button>
            </div>
            <Offcanvas style={{ "width": "650px" }} show={EKSopcijeCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_EKSOPCIJE_ZATVORI")} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Ekstradicioni - dodatne opcije</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Card className='mb-2'>
                                    <Card.Body>
                                        <Card.Title>Da li je bilo produženje privremenog pritvora?</Card.Title>

                                        <ButtonGroup className="mb-2">
                                            <Button
                                                id="PROpostojiDA"
                                                size='lg'
                                                type="radio"
                                                variant={PROpostoji ? 'secondary' : 'outline-secondary'}
                                                name="radio"
                                                value={true}
                                                checked={PROpostoji === true}
                                                onClick={(e) => inputChange('PROpostoji', true)}
                                            >
                                                Da
                                            </Button>

                                            <Button

                                                id="PROpostojiNE"
                                                size='lg'
                                                type="radio"
                                                variant={PROpostoji ? 'outline-secondary' : 'secondary'}
                                                name="radio"
                                                value={false}
                                                checked={PROpostoji === false}
                                                onClick={(e) => inputChange('PROpostoji', false)}
                                            >
                                                Ne
                                            </Button>

                                        </ButtonGroup>

                                    </Card.Body>
                                </Card>
                                <br />
                              

                                <Container className='ps-0 pe-0'>
                                            <Row>
                                                <Col xs={10}>
                                                <Form.Label htmlFor="EKSdodatneDatumodlukePR">Datum rješenja o <b>određivanju</b> privremenog pritvora</Form.Label>
                                                <InputGroup size='lg'>
                                                <InputGroup.Text style={{minWidth:"42%"}}>
                                                    {datumodlukePR}
                                                </InputGroup.Text>

                                                    <InputGroup.Text >
                                                        Eks {PRfaza}
                                                    </InputGroup.Text>
                                                    <InputGroup.Text onClick={() => setPostavkePR(!postavkePR)}>{postavkePR ? <PencilFill/> : <Pencil/>}</InputGroup.Text>
                                                </InputGroup>
                                                </Col>
                                            </Row>
                                            {postavkePR && 
                                            <>
                                                  <Row >
                                              <Col xs={2} style={{borderRight: "3px dashed rgb(209, 206, 206)"}}> <br/> </Col>
                                            </Row>
                                            <Row>
                                                        <Col>
                                                          <Container>
                                                            <Row>
                                                             <Col xs={7}>
                                                                <Container className='ps-3 pt-2 pb-2 text-bg-light border rounded-3'>
                                                                    <Row>
                                                              
                                                                <Col xs={5} className='pe-0'>
                                                                   <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PR", "+", undefined, "neDirajDatumodluke")}>
                                                                        <Plus />  Datum gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PR", "-", undefined, "neDirajDatumodluke")}>
                                                                        <Dash />  Datum dole
                                                                    </Button>
                                                                    <DatePicker
                                                                    dateFormat="dd.MM.yyyy."
                                                                    selected={datumodlukePRTMP ? new Date(datumodlukePRTMP) : new Date()}  
                                                                    onChange={(date) => inputChange('izmjenadatuma', "PR", "kalendar", date, "neDirajDatumodluke")}
                                                                    locale="bs"
                                                                    customInput={<KalendarBTN />}
                                                                    />
                                                                    </ButtonGroup>

                                                                </Col>
                                                                <Col xs={5} className='ps-0'>
                                                                    <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PR", "+")}>
                                                                        <Plus />  Faza gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PR", "-")}>
                                                                        <Dash />  Faza dole
                                                                    </Button>
                                                                  
                                                                    </ButtonGroup>
                                                                </Col>
                                                                <Col xs={1}><CloseButton onClick={() => setPostavkePR(!postavkePR)}/></Col>
                                                                    </Row>
                                                                </Container>
                                                             </Col>

                                                            
                                                            </Row>
                                                        
                                                </Container>
                                                </Col>
                                               
                                            </Row>
                                            </>}
     
                                      </Container>
                        
                                {PROpostoji && <>
                                <br/>
                                    <Container className='ps-0 pe-0'>
                                            <Row>
                                                <Col xs={10}>
                                                <Form.Label htmlFor="EKSdodatneDatumodlukePRO">Datum rješenja o <b>produženju</b> privremenog pritvora</Form.Label>
                                                <InputGroup size='lg'>
                                                    <InputGroup.Text style={{minWidth:"42%"}}>
                                                    {datumodlukePRO}
                                                </InputGroup.Text>

                                                    <InputGroup.Text >
                                                        Eks {PROfaza}
                                                    </InputGroup.Text>
                                                    <InputGroup.Text onClick={() => setPostavkePRO(!postavkePRO)}>{postavkePRO ? <PencilFill/> : <Pencil/>}</InputGroup.Text>
                                                </InputGroup>
                                                </Col>
                                            </Row>
                                            {postavkePRO && 
                                            <>
                                                  <Row >
                                              <Col xs={2} style={{borderRight: "3px dashed rgb(209, 206, 206)"}}> <br/> </Col>
                                            </Row>
                                            <Row>
                                                        <Col>
                                                          <Container>
                                                            <Row>
                                                             <Col xs={7}>
                                                                <Container className='ps-3 pt-2 pb-2 text-bg-light border rounded-3'>
                                                                    <Row>
                                                               
                                                                <Col xs={5} className='pe-0'>
                                                                   <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PRO", "+", undefined ,"neDirajDatumodluke")}>
                                                                        <Plus />  Datum gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PRO", "-", undefined ,"neDirajDatumodluke")}>
                                                                        <Dash />  Datum dole
                                                                    </Button>
                                                                    <DatePicker
                                                                    dateFormat="dd.MM.yyyy."
                                                                    selected={datumodlukePROTMP ? new Date(datumodlukePROTMP) : new Date()}  
                                                                    onChange={(date) => inputChange('izmjenadatuma', "PRO", "kalendar", date, "neDirajDatumodluke")}
                                                                    locale="bs"
                                                                    customInput={<KalendarBTN />}
                                                                    />
                                                                    </ButtonGroup>

                                                                </Col>
                                                                <Col xs={5} className='ps-0'>
                                                                    <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PRO", "+")}>
                                                                        <Plus />  Faza gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PRO", "-")}>
                                                                        <Dash />  Faza dole
                                                                    </Button>
                                                                  
                                                                    </ButtonGroup>
                                                                </Col>
                                                                <Col xs={1}><CloseButton onClick={() => setPostavkePRO(!postavkePRO)}/></Col>
                                                                    </Row>
                                                                </Container>
                                                             </Col>

                                                            
                                                            </Row>
                                                        
                                                </Container>
                                                </Col>
                                               
                                            </Row>
                                          
                                            </>}
     
                                      </Container>


                                </>}
                                <br/>
                                <FloatingLabel
                                    controlId="EKSbrojprijedloga"
                                    label="Broj i datum prijedloga Tužilaštva BiH"
                                    className="mb-1"
                                ><Form.Control type="text" className={(EKSbrojprijedloga === "") && 'obavezaninput'} placeholder="Broj i datum prijedloga Tužilaštva BiH" value={EKSbrojprijedloga}
                                    onChange={(e) => inputChange('EKSbrojprijedloga', e.target.value)} />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="EKSbrojmolbe"
                                    label="Broj i datum molbe za izručenje"
                                    className="mb-1"
                                ><Form.Control type="text" className={(EKSbrojmolbe === "") && 'obavezaninput'} placeholder="Broj i datum molbe za izručenje" value={EKSbrojmolbe}
                                    onChange={(e) => inputChange('EKSbrojmolbe', e.target.value)} />
                                </FloatingLabel>
                            </Col>

                                </Row>
                                <br />
                    </Container>

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default EKSdodatne