import React from 'react'
import { useGlobalContext } from '../context'
import { Dokumenti } from './dodatneopcije/LPodaci'

const Obrazlozenjeidentitet = () => {

    const { vrstaOdluke, ODBopcije, DrzavljaninBiH, licnidokumenti, licnipodaci, potraživani, potraživanog,
        saopštio, sam, potvrdio, samog, isti, naveo, posjedovao, upisan, državljanin } = useGlobalContext()


    let postojecidokumenti = licnidokumenti.filter(dokument => (dokument.pasos === true || dokument.licna === true) && dokument.uvid === true)




    return (
        <>


            {DrzavljaninBiH === "DA"
                ?
                <>
                    {vrstaOdluke === "ODB" && `Kako je i naprijed naznačeno Sud je, prvenstveno, utvrdio identitet ${potraživanog}, a s tim u vezi, i činjenicu državljanstva potraživanog lica obzirom da se radi o odlučnoj činjenici u postupcima izručenja potraživanih lica kao specifičnom obliku međunarodne pravne pomoći.${" "}`}
                    {postojecidokumenti.length === 0
                        ?
                        <>
                            {licnipodaci.uvjerenje
                                ?
                                <>
                                    Sud konstatuje da se, u konkretnom slučaju, ne radi se o strancu u smislu odredaba citiranog člana zakona jer {potraživani} posjeduje državljanstvo BiH. Identitet {potraživanog}, u konkretnom slučaju, utvrđen je na osnovu podataka koje je {saopštio} {sam} {potraživani} na održanom ročištu, a lični podaci saopšteni tom prilikom u skladu su sa podacima naznačenim u raspisanoj međunarodnoj potjernici. S druge strane, kada je u pitanju činjenica posjedovanja državljanstva BiH ista je vidljiva iz priloženog Uvjerenja o državljanstvu, broj: {licnipodaci.uvjerenje.brojidatumuvjerenja || "__________ od ________. godine"}, izdatog od strane matičnog ureda {licnipodaci.uvjerenje.maticniured || "__________"}.
                                    {" "}Iz uvjerenja proizilazi da je {potraživani} {upisan} u matičnju knjigu državljana Bosne i Hercegovine koja se vodi za naseljeno mjesto {licnipodaci.uvjerenje.podrucjeunosa || "__________"}, na strani {licnipodaci.uvjerenje.stranaunosa || "__________"}, pod rednim brojem {licnipodaci.uvjerenje.rednibrojunosa || "__________"}, pri čemu je kao godina unosa naznačena {licnipodaci.uvjerenje.godinaunosa || "__________"}. godina.
                                </>
                                :
                                <>
                                    Sud konstatuje da se, u konkretnom slučaju, ne radi se o strancu u smislu odredaba citiranog člana zakona jer {potraživani} posjeduje državljanstvo BiH. Identitet {potraživanog}, u konkretnom slučaju, utvrđen je na osnovu podataka koje je {saopštio} {sam} {potraživani} na održanom ročištu, a lični podaci saopšteni tom prilikom u skladu su sa podacima naznačenim u raspisanoj međunarodnoj potjernici. Sud je prihvatio, kao nesporne, podatke u pogledu državljanstva {potraživanog} koje proizilaze iz međunarodne potjernice obzirom da se radi o službenim podacima nadležnih organa za saradnju sa Interpolom, a činjenice u tom pravcu {potvrdio} je i {sam} {potraživani}.
                                </>
                            }

                        </>
                        :
                        <>
                            {licnipodaci.uvjerenje
                                ?
                                <>
                                    Sud konstatuje da se, u konkretnom slučaju, ne radi se o strancu u smislu odredaba citiranog člana zakona jer {potraživani} posjeduje državljanstvo BiH. Identitet {potraživanog} utvrđen je na osnovu podataka koje je {saopštio} {sam} {potraživani} na održanom ročištu te uvidom u <Dokumenti kod="inlinePrikaz" />. Lični podaci utvrđeni tom prilikom u skladu su sa podacima naznačenim u raspisanoj međunarodnoj potjernici.
                                    S druge strane, kada je u pitanju činjenica posjedovanja državljanstva BiH ista je vidljiva iz priloženog Uvjerenja o državljanstvu, broj: {licnipodaci.uvjerenje.brojidatumuvjerenja || "__________ od ________. godine"}, izdatog od strane matičnog ureda {licnipodaci.uvjerenje.maticniured || "__________"}.
                                    {" "}Iz uvjerenja proizilazi da je {potraživani} {upisan} u matičnju knjigu državljana Bosne i Hercegovine koja se vodi za naseljeno mjesto {licnipodaci.uvjerenje.podrucjeunosa || "__________"}, na strani {licnipodaci.uvjerenje.stranaunosa || "__________"}, pod rednim brojem {licnipodaci.uvjerenje.rednibrojunosa || "__________"}, pri čemu je kao godina unosa naznačena {licnipodaci.uvjerenje.godinaunosa || "__________"}. godina.
                                    Dakle, na osnovu raspoloživih podataka, odnosno priložene dokumentacije nesporno je utvrđeno da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "__________"}.
                                </>
                                :
                                <>
                                    Sud konstatuje da se, u konkretnom slučaju, ne radi se o strancu u smislu odredaba citiranog člana zakona jer {potraživani} posjeduje državljanstvo BiH. Identitet {potraživanog} utvrđen je na osnovu podataka koje je {saopštio} {sam} {potraživani} na održanom ročištu te uvidom u <Dokumenti kod="inlinePrikaz" />. Lični podaci utvrđeni tom prilikom u skladu su sa podacima naznačenim u raspisanoj međunarodnoj potjernici.
                                    Dakle, na osnovu raspoloživih podataka, odnosno priložene dokumentacije nesporno je utvrđeno da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "__________"}.                          </>
                            }
                        </>
                    }

                </>
                :
                DrzavljaninBiH === "NE"
                    ?
                    <>
                        {vrstaOdluke === "ODB" ? `Kako je i naprijed naznačeno Sud je, prvenstveno, utvrdio identitet ${potraživanog} koji je, u konkretnom slučaju, utvrđen ${" "}` : `Identitet ${potraživanog}, u konkretnom slučaju, utvrđen je ${" "}`}
                        {postojecidokumenti.length === 0
                            ?
                            <>
                                na održanom ročištu na osnovu izjave {samog} {potraživanog} kojom prilikom je {isti} {naveo} svoje lične podatke koji su u saglasnosti sa podacima naznačenim u međunarodnoj potjernici. Sud u ovoj inicijalnoj fazi nije imao na raspolaganju neki od identifikacionih dokumenata {potraživanog} obzirom da {potraživani} nije {posjedovao} dokumente prilikom lišenja slobode, međutim, informacije utvrđene prilikom saslušanja {potraživanog} prema ocjeni sudije za prethodni postupak čine dovoljan osnov za donošenje odluke o zahtjevu države moliteljice za privremeno pritvaranje. Dakle, na osnovu rapoloživih podataka u spisu i izjave {samog} {potraživanog} Sud je utvrdio da je {potraživani} stranac, odnosno da nema državljanstvo Bosne i Hercegovine, te da je {državljanin} {licnipodaci.državljanstvo || "__________"}{(vrstaOdluke === "ODB" && !ODBopcije.aktivnapotjernica) ? `. Ova okolnost predstavlja jedan od osnovnih uslova za određivanje privremenog pritvora potraživanom licu u situaciji kada je lišeno slobode na osnovu aktivne međunarodne potjernice.` : `, a što predstavlja jedan od osnovnih uslova za lišavanje slobode i određivanje privremenog pritvora potraživanom licu.`}
                            </>
                            :
                            <>
                                na osnovu podataka koje je {saopštio} {sam} {potraživani} na održanom ročištu te uvidom u <Dokumenti kod="inlinePrikaz" />. Lični podaci utvrđeni tom prilikom u skladu su sa podacima naznačenim u raspisanoj međunarodnoj potjernici. Dakle, na osnovu raspoloživih podataka odnosno, izjave {samog} {potraživanog} i priložene dokumentacije Sud je utvrdio da je {potraživani} stranac, odnosno da nema državljanstvo Bosne i Hercegovine, te da je {državljanin} {licnipodaci.državljanstvo || "__________"}{(vrstaOdluke === "ODB" && !ODBopcije.aktivnapotjernica) ? `. Ova okolnost predstavlja jedan od osnovnih uslova za određivanje privremenog pritvora potraživanom licu u situaciji kada je lišeno slobode na osnovu aktivne međunarodne potjernice.` : `, a što predstavlja jedan od osnovnih uslova za lišavanje slobode i određivanje privremenog pritvora potraživanom licu.`}
                            </>
                        }



                    </>
                    :
                    <>

                    </>}
        </>
    )
}

export default Obrazlozenjeidentitet
