import React from 'react'
import { useGlobalContext } from '../context'
import Obrazlozenjeidentitet from './Obrazlozenjeidentitet'
import Alert from 'react-bootstrap/Alert';
import Snipet from './dodatneopcije/Snipet'
import Skracenica from './dodatneopcije/Skracenica';

const Obrazlozenjeuvodnidio = () => {

    const { datumodluke, brojPredmeta, vrstaOdluke, IMELICA,  DRZMOLITELJICA,
        TUZILAC, datumlisenja, organLisenja, datumodlukePR, PRkrajlisenja, PROdatumrjesenja, PROkrajlisenja, PRfaza, PROpostoji, PROfaza,
        EKSbrojprijedloga, EKSbrojmolbe, vrstabranioca, BRnacinizbora, BRANILAC, pJ, pC, pL,
        potraživani, potraživanom, Potraživanom, potraživanog, Potraživani, njegovog, poučen, izručen, izjasnio, dao, saslušan, isti,
        odabrao, priveden, koji, lišen, njegovo, upoznat, njegov

    } = useGlobalContext()






    return (
        <>

            {vrstaOdluke === "ODB" ? <><p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika {organLisenja.gen || "_________"} dana {datumlisenja || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                <p style={pJ}>Sudija za prethodni postupak je, u smislu odredbe člana 38. <Skracenica kod="ZMPP" naziv="naziv" /> <Skracenica kod="ZMPP" sl="sl" /> (u daljem tekstu "<Skracenica kod="ZMPP" skr="skr" />"), održao ročište povodom lišenja slobode {potraživanog} <Snipet kod="OBRUVODstrankeODB" />  </p>
                <p style={pJ}>{Potraživani} je, nakon što je utvrđen {njegov} identitet, {poučen} da se navedeno ročište održava povodom zahtjeva nadležnog organa države moliteljice {DRZMOLITELJICA.gen || "_________"} za {njegovo} privremeno pritvaranje u toku postupka izručenja koji zahtjev je sadržan u međunarodnoj potjernici.
                    {" "}U tom pravcu, {potraživani} je detaljno {upoznat} sa razlozima zbog kojih se traži {njegovo} izručenje, odnosno razlozima zbog kojih je raspisana međunarodna potjernica, a s tim u vezi, {potraživanom} i braniocu je omogućeno da se izjasne u odnosu navode iz potjernice kao i zahtjev države moliteljice za privremeno pritvaranje. </p>
                <Snipet kod="navodistranaka" />
                <p style={pJ}>Nakon održanog ročišta, sudija za prethodni postupak Suda BiH je, razmotrivši sadržaj međunarodne potjernice <Snipet kod="ncb1ncb2" veznik="potjernice" />, a imajući u vidu izjašnjenje {potraživanog} i navode {njegovog} branioca, donio odluku kao u dispozitivu ovog rješenja iz sljedećih razloga:</p>
                <p style={pJ}><Snipet kod="izspisa2" nalogzahapsenje="2" /></p>
                <p style={pJ}><Obrazlozenjeidentitet /></p>
                <p style={pJ}><Snipet kod="ODBobrazloženje" /></p>

            </> : <></>}

            {vrstaOdluke === "MZ" ? <><p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika {organLisenja.gen || "_________"} dana {datumlisenja || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                <p style={pJ}><Snipet kod="nalogzahapsenje" /></p> <p style={pJ}><Snipet kod="OBRUVODstranke" /></p>
                <p style={pJ}>Sudija za prethodni postupak je {potraživanom} pojasnio proceduru postupka izručenja te je posebno ukazao na zakonsku mogućnost pojednostavljenog izručenja u kom slučaju bi se izbjegla redovna procedura izručenja i gdje postupak izručenja traje dosta kraće, uz upozorenje da je saglasnost za pojednostavljeno izručenje neopoziva. {Potraživanom} je, nadalje, pojašnjeno da se u tom slučaju zapisnik sa saglasnošću {potraživanog} lica za pojednostavljeno izručenje dostavlja ministru pravde Bosne i Hercegovine, koji donosi rješenje o izručenju i u kom slučaju država moliteljica nije u obavezi dostavljati molbu i ekstradicionu dokumentaciju. Također, {potraživani} je {poučen} da se može odreći prava na principe specijaliteta, ukoliko želi da bude {izručen} u pojednostavljenom postupku.</p>
                <Snipet kod="navodistranaka" />
                <p style={pJ}>Nakon održanog ročišta, sudija za prethodni postupak Suda BiH je, razmotrivši sadržaj međunarodne potjernice <Snipet kod="ncb1ncb2" veznik="potjernice" />, a imajući u vidu izjašnjenje {potraživanog} i navode {njegovog} branioca, donio odluku kao u dispozitivu ovog rješenja iz sljedećih razloga:</p>
                <p style={pJ}><Snipet kod="izspisa" /></p>
                <p style={pJ}>Iz člana  16. stav 1. Zakona o izmjenama i dopunama zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima  („Službeni glasnik BIH“, broj 58/13), kojim je izmjenjen član 37. osnovnog teksta tog Zakona, proizilazi da će lice koje je lišeno slobode <i>na osnovu raspisane međunarodne potjernice, u kojoj je naveden zahtjev države moliteljice za određivanje pritvora, biti dovedeno pred sudiju za prethodni postupak, s ciljem odlučivanja o prijedlogu države moliteljice za određivanje privremenog pritvora, ako postoji opasnost da će stranac čije se izručenje  traži pobjeći ili se sakriti.
                    Iz navedenih zakonskih odredaba proizilazi da se privremeni pritvor može odrediti ako su za to ispunjena dva zakonska uslova i to da je lice čije se pritvaranje traži stranac, odnosno da nije državljanin BiH i da postoji opasnost  da će isti  pobjeći ili se sakriti</i>.</p>
                <p style={pJ}><Obrazlozenjeidentitet /></p>
                <p style={pJ}><Snipet kod="MZclan40" /></p>
                <p style={pJ}><Snipet kod="ugovorodredbe" /></p>
                <p style={pJ}><Snipet kod="izspisa2" /></p>
                <p style={pJ}>Sud nalazi da je navedena potjernica sačinjena u skladu sa članom 16. stav 2. Evropske konvencije o ekstradiciji i kao takva predstavlja validan osnov za zakonito određivanje privremenog pritvora prema {potraživanom}.</p>
                <p style={pJ}>Istovremeno, sudija za prethodni postupak, u ovoj inicijalnoj fazi kada raspolaže samo međunarodnom potjernicom, konstatuje da nisu utvrđeni neki očigledni razlozi na osnovu kojih bi se mogao izvesti nesporan zaključak o nedozvoljenosti izručenja {potraživanog}, a slijedom toga, i primjene mjere privremenog pritvora.
                    S tim u vezi, bitno je napomenuti da će odluku o ispunjenosti pretpostavki za izručenje {potraživanog} u kontekstu razloga zbog kojih je raspisana međunarodna potraga donijeti, funkcionalno nadležno, vanraspravno vijeće Suda BiH po dostavljanju molbe za izručenje sa ekstradicionom dokumentacijom te provođenju svih izviđajnih radnji u ovom predmetu.</p>
            </> : <></>}


            {vrstaOdluke === "PR" ? <>  <p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika {organLisenja.gen || "_________"} dana {datumlisenja || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                <p style={pJ}><Snipet kod="nalogzahapsenje" /></p> <p style={pJ}><Snipet kod="OBRUVODstranke" /></p>
                <p style={pJ}>Sudija za prethodni postupak je {potraživanom} pojasnio proceduru postupka izručenja te je posebno ukazao na zakonsku mogućnost pojednostavljenog izručenja u kom slučaju bi se izbjegla redovna procedura izručenja i gdje postupak izručenja traje dosta kraće, uz upozorenje da je saglasnost za pojednostavljeno izručenje neopoziva. {Potraživanom} je, nadalje, pojašnjeno da se u tom slučaju zapisnik sa saglasnošću {potraživanog} lica za pojednostavljeno izručenje dostavlja ministru pravde Bosne i Hercegovine, koji donosi rješenje o izručenju i u kom slučaju država moliteljica nije u obavezi dostavljati molbu i ekstradicionu dokumentaciju. Također, {potraživani} je {poučen} da se može odreći prava na principe specijaliteta, ukoliko želi da bude {izručen} u pojednostavljenom postupku.</p>
                <Snipet kod="navodistranaka" />
                <p style={pJ}>Nakon održanog ročišta, sudija za prethodni postupak Suda BiH je, razmotrivši sadržaj međunarodne potjernice <Snipet kod="ncb1ncb2" veznik="potjernice" />, a imajući u vidu izjašnjenje {potraživanog} i navode {njegovog} branioca, donio odluku kao u dispozitivu ovog rješenja iz sljedećih razloga:</p>
                <p style={pJ}><Snipet kod="izspisa" /></p>
                <p style={pJ}>Iz člana  16. stav 1. Zakona o izmjenama i dopunama zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima  („Službeni glasnik BIH“, broj 58/13), kojim je izmjenjen član 37. osnovnog teksta tog Zakona, proizilazi da će lice koje je lišeno slobode <i>na osnovu raspisane međunarodne potjernice, u kojoj je naveden zahtjev države moliteljice za određivanje pritvora, biti dovedeno pred sudiju za prethodni postupak, s ciljem odlučivanja o prijedlogu države moliteljice za određivanje privremenog pritvora, ako postoji opasnost da će stranac čije se izručenje  traži pobjeći ili se sakriti</i>.
                    Nadalje, članom 17. izmjena i dopuna navedenog Zakona, kojim je izmenjen član 38. stav 4. propisano je da privremeni pritvor može trajati 18 dana. Iz navedenih zakonskih odredaba proizilazi da se privremeni pritvor može odrediti ako su za to ispunjena dva zakonska uslova i to da je lice čije se pritvaranje traži stranac, odnosno da nije državljanin BiH i da postoji opasnost  da će isti  pobjeći ili se sakriti.
                </p>
                <p style={pJ}><Obrazlozenjeidentitet /></p>
                <p style={pJ}><Snipet kod="PRclan40" /></p>
                <p style={pJ}><Snipet kod="ugovorodredbe" /></p>
                <p style={pJ}><Snipet kod="izspisa2" /></p>
                <p style={pJ}>Sud nalazi da je navedena potjernica sačinjena u skladu sa članom 16. stav 2. Evropske konvencije o ekstradiciji i kao takva predstavlja validan osnov za zakonito određivanje privremenog pritvora prema {potraživanom}.</p>
                <p style={pJ}>Istovremeno, sudija za prethodni postupak, u ovoj inicijalnoj fazi kada raspolaže samo međunarodnom potjernicom, konstatuje da nisu utvrđeni neki očigledni razlozi na osnovu kojih bi se mogao izvesti nesporan zaključak o nedozvoljenosti izručenja {potraživanog}, a slijedom toga, i primjene mjere privremenog pritvora.
                    Međutim, bitno je napomenuti da će odluku o ispunjenosti pretpostavki za izručenje {potraživanog} u kontekstu razloga zbog kojih je raspisana međunarodna potraga donijeti, funkcionalno nadležno, vanraspravno vijeće Suda BiH po dostavljanju molbe za izručenje sa ekstradicionom dokumentacijom te provođenju svih izviđajnih radnji u ovom predmetu.</p>
                <p style={pJ}>Analizom svih utvrđenih okolnosti sudija za prethodni postupak, u konkretnom slučaju, nalazi da su ispunjene zakonske pretpostavke za određivanje privremenog pritvora {potraživanom} u smislu odredbe člana 37. ZMPP-a kojom odredbom je pripisano da će se privremeni pritvor, kako je i naprijed naznačeno, odrediti ako postoji opasnost da će lice čije se izručenje traži pobjeći ili se sakriti.</p>
            </> : <></>}

            {vrstaOdluke === "PREKS" ? <><p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud")
                    {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika {organLisenja.gen || "_________"} dana
                    {datumlisenja || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                <p style={pJ}><Snipet kod="OBRUVODstranke" /></p>
                <p style={pJ}>Sudija za prethodni postupak je {potraživanom} pojasnio proceduru postupka izručenja te je posebno ukazao na zakonsku mogućnost pojednostavljenog izručenja, u kom slučaju
                    bi se izbjegla redovna procedura izručenja i gdje postupak izručenja traje dosta kraće, uz upozorenje da je
                    saglasnost za pojednostavljeno izručenje neopoziva. {Potraživanom} je, nadalje, pojašnjeno da se u tom slučaju
                    zapisnik sa saglasnošću {potraživanog} lica za pojednostavljeno izručenje dostavlja ministru pravde Bosne i
                    Hercegovine, koji donosi rješenje o izručenju i u kom slučaju država moliteljica nije u obavezi dostavljati
                    molbu i ekstradicionu dokumentaciju. Također, {potraživani} je {poučen} da se može odreći prava na principe
                    specijaliteta, ukoliko želi da bude {izručen} u pojednostavljenom postupku.</p>
                <Snipet kod="navodistranaka" />
                <p style={pJ}>Nakon održanog ročišta, sudija za prethodni postupak Suda BiH je, razmotrivši
                    sadržaj međunarodne potjernice <Snipet kod="ncb1ncb2" veznik="potjernice" />, a imajući u vidu izjašnjenje {potraživanog} i
                    navode {njegovog} branioca, donio odluku kao u dispozitivu ovog rješenja iz sljedećih razloga:</p>
                <p style={pJ}><Snipet kod="izspisa" /></p>
                <p style={pJ}>Imajući u vidu navedeno kao i činjenicu da je {potraživani} {IMELICA.ime || "_________"}, na održanom ročištu pred ovim Sudom, {dao} neopozivu izjavu da
                    u {DRZMOLITELJICA.ime || "_________"}  želi biti {izručen} na pojednostavljen način, navedena situacija
                    je proizvela obavezu Suda za primjenom člana 52. stav 5. i stav 6., koji je izmjenjen članom 26.
                    Zakona o izmjenama i dopunama Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima. Prema
                    stavu 5. člana 52. citiranog Zakona, nakon što {potraživani} izjavi da je saglasan sa pojednostavljenim
                    izručenjem i nakon što se spis dostavi Ministarstvu pravde Bosne i Hercegovine, radi donošenja odluke
                    o izručenju stranca po pojednostavljenom postupku, država moliteljica nije obavezna dostavljati
                    molbu za izručenje, a shodno tome ni ekstradicionu dokumentaciju. Takođe, članom 52. stav 6.
                    Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima je propisano da: "<i>Nakon što lice
                        čije se izručenje traži da svoju saglasnost za pojednostavljeno izručenje državi moliteljici,
                        na {potraživanog} će se, u pogledu daljeg pritvora, primjeniti odrebe ovog zakona koje se odnose
                        na ekstradicioni pritvor</i>”. Zbog toga je sudija za prethodni postupak primjenio odredbe člana
                    52. stav 6. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima i {potraživanom} odredio
                    ekstradicioni pritvor.</p>
                <p style={pJ}><Obrazlozenjeidentitet /></p>
                <p style={pJ}>Članom 18. Zakona i izmjenama i dopunama Zakona o međunarodnoj pravnoj
                    pomoći u krivičnim stvarima, izmjenjen je član 39. osnovnog teksta Zakona, koji u
                    stavu 1. propisuje određivanje esktradicionog pritvora i po kome sudija za prethodni postupak,
                    nakon prijema molbe za izručenje (koja u ovom slučaju ne mora biti dostavljena), na prijedlog
                    tužioca, rješenjem određuje ekstradicioni pritvor, iz određenih, zakonom propisanih, razloga.
                    U ovom slučaju, takođe, nije potreban prijedlog tužioca jer sama potjernica predstavlja
                    zahtjev za lišenje slobode {potraživanog}.</p>
            </> : <></>}

            {vrstaOdluke === "PRO" ? <><p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                <p style={pJ}>Rješenjem Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") broj: {brojPredmeta || "__________"} {PRfaza} od {datumodlukePR || "__________"} {potraživanom} {IMELICA.imedat || "__________"} određen je privremeni pritvor koji
                    po tom rješenju može trajati najduže 18 (osamnaest) dana od dana lišenja slobode {potraživanog}, odnosno od {datumlisenja || "__________"} do {PRkrajlisenja || "__________"} ili do nove odluke Suda. Privremeni pritvor
                    određen je nakon što je {potraživani} {saslušan} pred sudijom za prethodni postupak Suda BiH, a nakon {njegovog}
                    lišenja slobode od strane pripadnika {organLisenja.gen || "__________"} dana {datumlisenja || "__________"} po
                    međunarodnoj potjernici <Snipet kod="ncb1ncb2" />. Međunarodna potjernica je raspisana u cilju <Snipet kod="razlozi" />.</p>
                <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                <p style={pJ}>Na održanom ročištu, na kojem je {saslušan} {potraživani} u prisustvu
                    svog branioca, {isti} se izričito {izjasnio} da ne želi biti {izručen} državi moliteljici na pojednostavljeni
                    način. Cijeneći sadržaj međunarodne potjernice NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"}, {DRZMOLITELJICA.ime || "__________"},
                    odnosno ekvivalentne potjernice NCB Interpola Sarajevo, sudija za prethodni postupak ovog Suda je
                    utvrdio da je potjernica raspisana u cilju hapšenja i ekstradicije {potraživanog}, a kako je potjernica
                    tretirana kao formalni zahtjev za njegovo lišenje slobode, Sud mu je odredio privremeni pritvor,
                    koji ističe dana {PRkrajlisenja || "__________"}.</p>
                <p style={pJ}>Rješenje o određivanju privremenog pritvora, u smislu
                    odredbe člana 38. stav 4. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima
                    (“Službeni glasnik BiH”, broj: 53/09, 58/13, u daljem tekstu “ZMPP”) istog dana dostavljeno
                    je Tužilaštvu BiH kao i Ministarstvu pravde BiH radi traženja molbe od strane države koja je raspisala
                    potjernicu.</p>
                <Snipet kod="PROzahtjevinformacije" />
                <Snipet kod="PROrelevantneodredbe" />
                <p style={pJ}><Snipet kod="PROsvrhaprivremenog" /></p>
                <Snipet kod="PROclan40" />
                <Snipet kod="ugovorodredbe" />
                <p style={pJ}><Snipet kod="izspisa2" /></p>
                <p style={pJ}>S tim u vezi, sudija za prethodni postupak, u ovoj inicijalnoj fazi kada
                    raspolaže samo međunarodnom potjernicom, konstatuje da nisu utvrđeni neki očigledni razlozi na osnovu kojih bi
                    se mogao izvesti nesporan zaključak o nedozvoljenosti izručenja {potraživanog}, a slijedom toga, i primjene mjere
                    privremenog pritvora. Međutim, bitno je napomenuti da će odluku o ispunjenosti pretpostavki za izručenje
                    {potraživanog} u kontekstu razloga zbog kojih je raspisana međunarodna potraga donijeti, funkcionalno nadležno,
                    vanraspravno vijeće Suda BiH po dostavljanju molbe za izručenje sa ekstradicionom dokumentacijom te
                    provođenju svih izviđajnih radnji u ovom predmetu.</p>
                <p style={pJ}>Kada su u pitanju konkretne okolnosti koje opravdavaju mjeru pritvora prema {potraživanom},
                    Sud cijeni da se iste nisu izmjenile od donošenja rješenja o određivanju privremenog pritvora. Uslovi za primjenu mjere
                    privremenog pritvora prema odredbi člana 37. ZMPP-a, a kako je to naznačeno i u rješenju Suda o određivanju privremenog
                    pritvora, iscrpljuju se u postojanju opasnosti da će potraživano lice pobjeći ili se sakriti.</p>
            </>
                :
                <>
                </>}


            {vrstaOdluke === "EKS" ?
                <>
                    <p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                    <p style={pJ}>Pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") u toku je postupak izručenja {potraživanog} {IMELICA.imegen || "__________"} {koji} je {lišen} slobode od strane pripadnika {organLisenja.gen || "__________"} dana {datumlisenja || "__________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                    <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                    <p style={pJ}>Rješenjem Suda BiH broj: {brojPredmeta || "__________"} {PRfaza} od {datumodlukePR || "__________"} prema {potraživanom} je,
                        u cilju osiguranja {njegovog} prisustva do okončanja ovog postupka, određena mjera {PROpostoji ? `privremenog pritvora. Privremeni pritvor je produžen rješenjem Suda, broj: ${brojPredmeta || "__________"} ${PROfaza} od ${PROdatumrjesenja || "__________"} na način da
                        isti može trajati do dostavljanja molbe za izručenje i ekstradicione dokumentacije od strane države moliteljice,
                        ili do nove odluke Suda, a najduže 40 (četrdeset) dana računajući od dana lišenja slobode, odnosno
                        od ${datumlisenja || "__________"} do ${PROkrajlisenja || "__________"} ili do nove odluke Suda` : `privremenog pritvora koji pritvor po tom rješenju može trajati najduže 18 (osamnaest) dana od dana lišenja slobode, odnosno od ${datumlisenja || "__________"} do ${PRkrajlisenja || "__________"} ili do nove odluke Suda`}.
                    </p>
                    <p style={pJ}>S tim u vezi, ovom Sudu je dostavljen prijedlog Tužilaštva Bosne i Hercegovine (u daljem tekstu "Tužilaštvo BiH/Tužilaštvo")
                        broj: {EKSbrojprijedloga || "__________"} za određivanje ekstradicionog pritvora prema {potraživanom}.</p>
                    <p style={pJ}>U prijedlogu se navodi da je __________ </p>
                    <p style={pJ}>Uz prijedlog je dostavljena sljedeća dokumentacija __________</p>
                    <p style={pL}><strong>Ročište radi razmatranja prijedloga Tužilaštva BiH</strong></p>
                    <p style={pJ}>Povodom prijedloga Tužilaštva BiH dana {datumlisenja || "__________"} održano je ročište na koje su pristupili tužilac Tužilaštva BiH
                        {TUZILAC.ime || "__________"}, {potraživani} <Snipet kod="OBRstrankeEKS" />.</p>
                    <Snipet kod="navodistranaka" />
                    <p style={pL}><strong>Odluka Suda</strong></p>
                    <p style={pJ}>Razmatrajući prijedlog Tužilaštva BiH sudija za prethodni postupak je, nakon uvida u spis predmeta te uzevši u obzir
                        izjašnjenje stranaka i branioca {potraživanog}, ocijenio da je prijedlog Tužilaštva BiH osnovan i da je {potraživanom}
                        neophodno odrediti ekstradicioni pritvor, iz sljedećih razloga:</p>
                    <p style={pJ}><Snipet kod="izspisa" /></p>
                    <p style={pJ}>Prihvatajući zahtjev za lišenje slobode {potraživanog} koji je formalno sadržan u međunarodnoj potjernici
                        istom je rješenjem Suda od {datumodlukePR || "__________"} određena mjera privremenog pritvora a zatim
                        pravosnažnim rješenjem Suda BiH od {PROdatumrjesenja || "__________"} i produžena mjera privremenog pritvora
                        sve u cilju omogućavanja državi moliteljici da podnese molbu za izručenje sa prilozima.
                    </p>
                    <p style={pJ}>Dostavljanjem molbe za izručenje i ekstradicione dokumentacije, stekle su se zakonske pretpostavke  za
                        sprovođenje redovnog ekstradicionog postupka prema {potraživanom} {IMELICA.imedat || "__________"}, a Sud o mjeri
                        pritvora prema {potraživanom} u ovoj fazi postupka odlučuje primjenom člana 39. stavovi 1. i 3. ZMPP-a.</p>
                    <p style={pJ}><Snipet kod="EKSclan39" /></p>
                    <p style={pJ}>Analizirajući dostavljeni prijedlog Tužilaštva BiH za određivanje mjere
                        ekstradicionog pritvora {potraživanom} {IMELICA.imedat || "__________"} sa aspekta formalnih pretpostavki
                        u svjetlu citiranih odredbi zakona, zaključak Suda je da su se stekle pretpostavke za donošenje sudske
                        odluke u tom pravcu. Naime, nesporno je da je ovom Sudu dostavljena molba nadležnog organa države
                        moliteljice, broj: {EKSbrojmolbe || "__________"}, za izručenje {potraživanog}, a u kontekstu odredbe
                        citiranog člana 39. stav 1. ZMPP-a podnesen i pisani prijedlog Tužilaštva BiH za određivanje mjere
                        ekstradicionog pritvora {potraživanom}.</p>
                    <Snipet kod="ugovorodredbe" />
                    <p style={pJ}><Snipet kod="izspisa2" /></p>
                    <p style={pJ}><Snipet kod="EKSneupucujena394" /> </p>
                    <p style={pJ}>Nadalje, Sud i u ovoj fazi postupka, kada je dostavljena molba za
                        izručenje {potraživanog} i ekstradiciona dokumentacija, nalazi da se okolnosti koje su predstavljale osnov za
                        privremeni  pritvor prema {potraživanom} nisu promijenile, te da iste ukazuju na opasnost da bi {potraživani},
                        ukoliko se nađe na slobodi, mogao pobjeći i tako izbjeći postupak izručenja, odnosno provođenja izručenja,
                        ukoliko bude donesena odluka o njegovom izručenju.</p>

                </> : <></>}
            {vrstaOdluke === "BR" ?
                <>
                    {vrstabranioca === "posluzbenoj"
                        ?
                        <>
                            {BRnacinizbora === "lista"
                                ?
                                <>
                                    <p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                                    <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud")
                                        {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika
                                        {organLisenja.gen || "_________"} dana {datumlisenja || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                                    <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                                    <p style={pJ}>Potjernica istovremeno sadrži zahtjev države moliteljice za privremeno pritvaranje {potraživanog}.</p>
                                    <p style={pJ}>Obzirom da {potraživani} u vrijeme ročišta, na kojem će se raspravljati o zahtjevu države moliteljice za
                                        privremeno pritvaranje {potraživanog}, a sve do okončanja ekstradicionog postupka, mora imati branioca,
                                        {potraživanom} je prije ročišta predočena lista ovlaštenih advokata, te je {isti} kao branioca {odabrao} {BRANILAC[0]?.imegen || "__________"}, advokata iz {BRANILAC[0]?.gradgen || "__________"}, koji je izrazio saglasnost
                                        za zastupanje u ovom predmetu.</p>
                                    <p style={pJ}>Kako se advokat kojeg je {potraživani} izabrao nalazi na pomenutoj
                                        listi advokata, Sud nalazi da su ispunjeni svi zakonom propisani uslovi za imenovanje advokata {BRANILAC[0]?.imegen || "__________"} za branioca po službenoj dužnosti {potraživanom} {IMELICA.imedat || "__________"}.</p>
                                    <p style={pJ}>Na osnovu naprijed navedenog, u skladu sa članom 38. stav 2. Zakona o međunarodnoj pravnoj pomoći
                                        u krivičnim stvarima i članom 45. stav 2. ZKP BiH, Sud je donio odluku kao u dispozitivu rješenja.</p>

                                </>
                                : BRnacinizbora === "bezliste"
                                    ?
                                    <>
                                        <p style={pC}><strong>O b r a z l o ž e nj e</strong></p>
                                        <p style={pJ}>Dana {datumodluke} sudiji za prethodni postupak Suda Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud")
                                            {priveden} je {potraživani} {IMELICA.ime || "_________"} {koji} je {lišen} slobode od strane pripadnika
                                            {organLisenja.gen || "_________"} dana {datumlisenja || "_________"} po međunarodnoj potjernici
                                            <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />.</p>
                                        <p style={pJ}><Snipet kod="nalogzahapsenje" /></p>
                                        <p style={pJ}>Potjernica istovremeno sadrži zahtjev države moliteljice za privremeno pritvaranje {potraživanog}.</p>
                                        <p style={pJ}>Obzirom da {potraživani} u vrijeme ročišta, na kojem će se raspravljati o zahtjevu države moliteljice
                                            za privremeno pritvaranje {potraživanog}, a sve do okončanja ekstradicionog postupka, mora imati branioca,
                                            te obzirom na zakonske rokove shodno kojim se ročište mora održati u kratkom roku,
                                            Sud je {potraživanom} postavio branioca po službenoj dužnosti, s tim da će {potraživanom} biti data mogućnost
                                            da izabere drugog branioca sa liste, ukoliko ne bude zadovoljan odbranom.</p>
                                        <p style={pJ}>Dakle, Sud je, smislu člana člana 38. stav 2. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima,
                                            iz razloga hitnosti donio odluku da se {potraživanom} postavi branilac po službenoj dužnosti {BRANILAC[0]?.ime || "__________"}, advokat iz {BRANILAC[0]?.gradgen || "__________"}.</p>
                                        <p style={pJ}>U toku postupka, {potraživani} može odlučiti da, umjesto branioca po službenoj dužnosti, izabere
                                            branioca kojeg će lično finansirati za preduzete radnje. U tom slučaju će postavljeni branilac biti razrješen dužnosti.</p>

                                    </>
                                    : <>
                                        <br />

                                        <Alert variant="warning alertV2">Preciziraj da li {potraživanom}<strong> predočena lista advokata</strong>.</Alert>
                                        <br />

                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>

                                        <br />
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <p style={pJ} className="skeleton mb-1">
                                            <br />
                                        </p>
                                        <br />

                                    </>}
                        </>
                        :
                        vrstabranioca === "izabrani"
                            ?
                            <>
                            </>
                            :
                            <></>
                    }






                </>
                : <></>}




        </>
    )
}

export default Obrazlozenjeuvodnidio