import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../context'
import prazanState from './liste/prazanstate';

import Button from 'react-bootstrap/Button';
import InputGroup from "react-bootstrap/InputGroup";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import bs from 'date-fns/locale/bs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEye, faEyeSlash, faPlus, faInfo} from "@fortawesome/free-solid-svg-icons";
import { PersonVcard, CardHeading, ListTask, Trash } from "react-bootstrap-icons";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import LPodaci from './dodatneopcije/LPodaci'
import Razlogizrucenja from './Razlogizrucenja';
import { DodajSudiju, DodajTuzioca, DodajZapisnicara, DodajAdvokata } from './dodatneopcije/Dodajstranku';
import Upozorenje2 from './dodatneopcije/Upozorenje2';
import AsyncSelect from 'react-select/async';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import mfImage from '../mf.png'; 

const Inputi = () => {

    const {updejtujStranke, vrstaOdluke, brojPredmeta, inputChange, IMELICA, licezensko, licnipodaciCanvas, kontrolisiCanvas, dodajTuziocaCanvas,
        dodajAdvokataCanvas, dodajZapinsicaraCanvas, dodajSudijuCanvas, procesuiranje, izvrsenje, DRZAVLJANSTVO, DRZAVLJANSTVO2, DrzavljaninBiH, drugodrzavljanstvo,
        licnidokumenti, dodajdokument, brisidokument, izmjenadokumenta,
        selektovano, organLisenja, licnipodaci, DRZMOLITELJICA, ncb1, ncb2, izborOsnovUgovor,
        datumlisenjaTMP, TUZILAC, BRANILAC, vrstabranioca, SUDIJA, ZAPISNICAR, TUMAC, dodajtumaca, jezikprevoda, potraživanog, potraživani, državljanin } = useGlobalContext()
    registerLocale('bs', bs)

    const { dispatch } = useGlobalContext();
    
        const [drzave, setDrzave] = useState([])
        const [sudije, setSudije] = useState([])
        const [tuzioci, setTuzioci] = useState([])
        const [zapisnicari, setZapisnicari] = useState([])
        const [advokati, setAdvokati] = useState([])

        useEffect( () => {
            preuzmiDrzave()
            preuzmiSudije()
            preuzmiTuzioce()
            preuzmiZapisincare()
            preuzmiAdvokate()
        }, [updejtujStranke])

        const preuzmiDrzave = async () => {
            const response = await axios.get("https://liste-odluka.onrender.com/drzave");
            if (response.status === 200) {
              setDrzave(response.data)
            }
          }
        const preuzmiSudije = async () => {
            const response = await axios.get("https://liste-odluka.onrender.com/sudije");
            if (response.status === 200) {
              setSudije(response.data)
            }
          }
          const preuzmiTuzioce = async () => {
            const response = await axios.get("https://liste-odluka.onrender.com/tuzioci");
            if (response.status === 200) {
              setTuzioci(response.data)
            }
          }

         const preuzmiZapisincare = async () => {
            const response = await axios.get("https://liste-odluka.onrender.com/zapisnicari");
            if (response.status === 200) {
              setZapisnicari(response.data)
            }
          }
          const preuzmiAdvokate = async () => {
            const response = await axios.get("https://liste-odluka.onrender.com/advokati");
            if (response.status === 200) {
              setAdvokati(response.data)
            }
          }


    const [alerti, setAlerti] = useState([
        { id: "obrisanpredmet", show: false, tip: 'success', pozicija: 'fixed', poruka: '' },
        { id: "otvorenpredmet", show: false, tip: 'success', pozicija: 'fixed', poruka: '' },
        { id: "novipredmet", show: false, tip: 'success', pozicija: 'fixed', poruka: '' },
        { id: "uvjerenjealert", show: false, tip: 'danger', pozicija: 'inline', poruka: '' },

    ]);

    const prikaziUpozorenje = (id, show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
        const newAlerti = alerti.map(alert => {
            if (alert.id === id) {
                return { ...alert, show, tip, pozicija, poruka };
            }
            return alert;
        });
        setAlerti(newAlerti);
    };

    const obrisanpredmet = alerti.find(alert => alert.id === "obrisanpredmet");
    const otvorenpredmet = alerti.find(alert => alert.id === "otvorenpredmet");
    const novipredmetAlert = alerti.find(alert => alert.id === "novipredmet");
    const uvjerenjealert = alerti.find(alert => alert.id === "uvjerenjealert");




    const [brojPredmetaLocal, setBrojPredmetaLocal] = useState('');
    const [inputDebounceTimeout, setInputDebounceTimeout] = useState({});
    const [mojiPredmetiModal, setMojiPredmetiModal] = useState(false);


    const LocalStoragePredmeti = () => {
        const localStorageKeys = Object.keys(localStorage);

        const brisiPredmet = (key) => {
            const trenutnipredmetValue = localStorage.getItem("trenutnipredmet");
            const localStorageItem = JSON.parse(localStorage.getItem(key));
            const localStorageItemID = localStorageItem?.ID;

            if (trenutnipredmetValue === localStorageItemID) {
                localStorage.removeItem(key);
                prazanState.ID = uuidv4();
                prazanState.vrijemeunosa = new Date()
                prazanState.vrijemeizmjene = "";
                localStorage.setItem("novipredmet", JSON.stringify(prazanState));
                localStorage.setItem("trenutnipredmet", prazanState.ID);
                dispatch({ type: 'LOAD_STATE', payload: prazanState });
            }  // brišemo trenutni predmet
            else {
                localStorage.removeItem(key);
            }

            // ako je obrisan zadnji predmet a nije trenutni mora se uskladit ID novipredmet i trenutnipredemt

            /*
                        const postojeciPredmeti = Object.keys(localStorage).filter(
                            (item) => item !== "novipredmet" && item !== "trenutnipredmet"
                        );
                        const brojSpasenihPredmeta = postojeciPredmeti.length;
                        console.log(brojSpasenihPredmeta)
             */
            prikaziUpozorenje("obrisanpredmet", true, 'success', 'inline',); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)

        }; //brisiPredmet

        const otvoriPredmet = (ID) => {
            localStorage.setItem('trenutnipredmet', ID);

            const postojeciPredmeti = Object.keys(localStorage);

            for (let i = 0; i < postojeciPredmeti.length; i++) {
                const key = postojeciPredmeti[i];
                if (key !== 'trenutnipredmet') {
                    const storedItem = localStorage.getItem(key);
                    let parsedItem = JSON.parse(storedItem);
                    if (parsedItem && parsedItem.ID === ID) {
                     parsedItem.datumlisenjaTMP = parsedItem.datumlisenjaTMP ? new Date(parsedItem.datumlisenjaTMP) : "";
                     //Pretvori u ISO datumlisenjaTMP da bi se mogao učitati u Datepicker-u
                     //parsedItem.datumlisenjaTMP -  2023-10-01T22:00:00.000Z     (ovako se spasi u localstorage-u)
                    //parsedItem.datumlisenjaTMP --  Mon Oct 02 2023 00:00:00 GMT+0200 (Central European Summer Time)   (ovo je nakon new Date formatiranja)
                        prikaziUpozorenje("otvorenpredmet", true, 'success', 'inline',); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
                        dispatch({
                            type: 'LOAD_STATE',
                            payload: {
                                ...parsedItem,
                               // vrijemeunosa: parsedItem.vrijemeunosa,
                            },
                        });
                        break; // Exit
                    }
                }
            }

        }; //Otvoripredmet

        const getTimeDifference = (lastEdited) => {
            const trenutnovrijeme = new Date();
            const vrijemeizmjene = new Date(lastEdited);

            const timeDifference = Math.abs(trenutnovrijeme - vrijemeizmjene);
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(timeDifference / (1000 * 60));
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            if (seconds < 60) {
                return `(prije ${seconds} ${[1, 2, 3, 4].includes(seconds % 10) ? 'sekunde' : 'sekundi'})`;
            } else if (minutes < 60) {
                return `(prije ${minutes} ${[1, 2, 3, 4].includes(minutes % 10) ? 'minute' : 'minuta'})`;
            } else if (hours < 24) {
                return `(prije ${hours} ${[1, 2, 3, 4].includes(hours % 10) ? 'sata' : 'sati'})`;
            } else {
                return `(prije ${days} dana)`;
            }
        };

        if (localStorageKeys.length === 3) {
            return <Alert variant="primary" className='alertV2'>
                Nemate spašenih predmeta.
            </Alert>;
        }

        const trenutnipredmet = localStorage.getItem("trenutnipredmet");

        return (
            <>




                <ListGroup >
                    {localStorageKeys.map((key) => {
                        if (key === "trenutnipredmet" || key === "novipredmet" || key === "logovan") {
                            return null; // Skipuj trenuti (ovo je samo ID trenutnog predmeta)
                        }

                        const predmet = localStorage.getItem(key);
                        const parsedPredmet = JSON.parse(predmet); // Parse JSON string
                        const broj = parsedPredmet.brojPredmeta ? parsedPredmet.brojPredmeta : "BEZ BROJA";
                        const ime = parsedPredmet.IMELICA ? parsedPredmet.IMELICA.ime : '';
                        const vrijemeunosa = parsedPredmet.vrijemeunosa;
                        const vrijemeizmjene = parsedPredmet.vrijemeizmjene;
                        const id = parsedPredmet.ID;
                        return (
                           

                                <ListGroup.Item action
                                    as="li"
                                    key={key} onClick={() => otvoriPredmet(id)}
                                    active={id === trenutnipredmet}
                                >
                                    <Container >
                                        <Row>
                                            <Col xs={6} className='ps-0 pr-0 me-auto'>  <div className="fw-bold">{broj}</div>{ime}</Col>
                                            <Col xs={3} className="d-flex align-items-center ps-0 pr-0 me-auto">

                                                {vrijemeizmjene ? `${getTimeDifference(vrijemeizmjene)}` : `${getTimeDifference(vrijemeunosa)}`}

                                            </Col>
                                            <Col xs={3} className="d-flex align-items-center justify-content-end ps-0 pe-0 me-auto">
                                                <Button variant={id === trenutnipredmet ? "outline-light" : "outline-secondary"}
                                                    className="ms-1 float-end"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Stop event propagation
                                                        brisiPredmet(key);
                                                    }}>
                                                    Briši <Trash />
                                                </Button></Col>
                                        </Row>
                                    </Container>

                                </ListGroup.Item>

              

                        );
                    })}
                </ListGroup>
                <br />
                <br />
                {obrisanpredmet && obrisanpredmet.show && (
                    <Upozorenje2 {...obrisanpredmet} sakrijUpozorenje={prikaziUpozorenje}>Uspješno ste obrisali predmet.</Upozorenje2>
                )}

                {otvorenpredmet && otvorenpredmet.show && (
                    <Upozorenje2 {...otvorenpredmet} sakrijUpozorenje={prikaziUpozorenje}>Uspješno ste otvorili predmet.</Upozorenje2>
                )}


            </>

        );
    }; //Localstoragepredmeti




    const inputDebounce = (broj, val, dodatniparam, dodatniparam2) => {
        setBrojPredmetaLocal(val);
        clearTimeout(inputDebounceTimeout[broj]);
        setInputDebounceTimeout((prevTimeouts) => ({
            ...prevTimeouts,
            [broj]: setTimeout(() => {
                inputChange(broj, val, dodatniparam, dodatniparam2);
            }, 1000)
        }));
    };

    useEffect(() => {
        // Cleanup function to clear the timeouts on unmount and re-render
        return () => {
            Object.values(inputDebounceTimeout).forEach((timeout) => {
                clearTimeout(timeout);
            });
        };
    }, [inputDebounceTimeout]);





    const novipredmet = () => {
        prazanState.ID = uuidv4();
        const { ID } = prazanState;
        prazanState.vrijemeunosa = new Date()
        localStorage.setItem("novipredmet", JSON.stringify(prazanState));
        localStorage.setItem("trenutnipredmet", ID);

        dispatch({ type: 'LOAD_STATE', payload: prazanState });
        prikaziUpozorenje("novipredmet", true, 'success', 'fixed',); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)

    }

    const mojiPredmetiSakrij = () => setMojiPredmetiModal(false);
    const mojiPredmetiPrikazi = () => setMojiPredmetiModal(true);

    //Updejtuj lokal brojPredmetaLocal (input field value) zavisno od konačnog brojPredmeta koji može biti prečišćen u reduceru
    useEffect(() => {
        setBrojPredmetaLocal(brojPredmeta);
    }, [brojPredmeta]);


    const organLisenjaPopover = (
        <Popover id="popover-basic" bsPrefix="popover-danger popover">
            <Popover.Body>
                Dovoljno je upisati skraćenicu npr: <br />
                <b>SIPA</b><br />
                <b>GP BiH, JGP Zvornik</b><br />
                <b>MUP RS, PU Banja Luka, PS Centar</b><br />
            </Popover.Body>
        </Popover>
    );

    const spolTumacaPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Spol tumača</Popover.Header>
            <Popover.Body>
                <ButtonGroup className='float-end mb-2'>
                    <ToggleButton
                        id="m"
                        type="radio"
                        variant={TUMAC.spol === "m" ? 'secondary' : 'outline-secondary'}
                        name="radio"
                        size="sm"
                        value="m"
                        checked={TUMAC.spol === "m"}
                        onChange={(e) => inputChange('TUMAC', e.target.value, "spol")}
                    >
                        muško
                    </ToggleButton>
                    <ToggleButton
                        id="ž"
                        type="radio"
                        size="sm"
                        variant={TUMAC.spol === "ž" ? 'secondary' : 'outline-secondary'}
                        name="radio"
                        value="ž"
                        checked={TUMAC.spol === "ž"}
                        onChange={(e) => inputChange('TUMAC', e.target.value, "spol")}
                    >
                        žensko
                    </ToggleButton>
                </ButtonGroup>
                <br />
            </Popover.Body>
        </Popover>
    );


    const jezikprevodaPopover = (
        <Popover id="popover-basic" bsPrefix="popover-danger popover">
            <Popover.Body>
                Primjeri: <br />
                <b>njemački</b><br />
                <b>turski</b><br />
                <b>...</b><br />
            </Popover.Body>
        </Popover>
    );

    const nemaOpcije = (inputValue, kat) => {
        return (
            <div>
                <span>Nije pronađen {kat}.</span>
                <br />
                <Button variant="secondary mt-2"
                    size="sm"
                    onClick={(e) => kontrolisiCanvas("CANVAS_DODAJSTRANKU_OTVORI", kat)}
                >
                    Dodaj novog {kat === "tužilac" && "tužioca"}
                    {kat === "advokat" && "advokata"}
                    {kat === "zapisničar" && "zapisničara"}
                    {kat === "sudija" && "sudiju"}
                </Button>
            </div>
        );
    };

    //Zemlje
    const conditionally = 0; //onemogućava učitavanje dropdown liste
    const filtrirajZemlje = (value) => {
        return drzave.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajZemlje = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajZemlje(value));
            } else return null;
        }, 1000);
    };

    const disableBiHopciju = (option) => {
        return option.ime === 'Bosna i Hercegovina' && DrzavljaninBiH === 'NE';
    };


    //Sudije
    const filtrirajSudije = (value) => {
        return sudije.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajSudije = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajSudije(value));
            } else return null;
        }, 1000);
    };
    //Tuzioci
    const filtrirajTuzioce = (value) => {
        return tuzioci.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajTuzioce = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajTuzioce(value));
            } else return null;
        }, 1000);
    };
    //Advokati
    const filtrirajAdvokate = (value) => {
        return advokati.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajAdvokate = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajAdvokate(value));
            } else return null;
        }, 1000);
    };

    //Zapisničari
    const filtrirajZapisnicare = (value) => {
        return zapisnicari.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajZapisnicare = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajZapisnicare(value));
            } else return null;
        }, 1000);
    };


    //Prati DRZMOLITELJICA i updejtuj "osnoviugovor" za slučaj da korisnik "vrati" na državu moliteljicu
    useEffect(() => {

        if (procesuiranje === false) {
            izborOsnovUgovor("" + DRZMOLITELJICA.kod + "", "ponistiproces", "");
        }
        if (izvrsenje === false) {
            izborOsnovUgovor("" + DRZMOLITELJICA.kod + "", "ponistizvrsenje", "");
        }

        izborOsnovUgovor("" + DRZMOLITELJICA.kod + "", "", "");
    }, [DRZMOLITELJICA, procesuiranje, izvrsenje]);


    const clickUvjerenje = () => {
        prikaziUpozorenje("uvjerenjealert", true, 'danger', 'inline'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
    };

    const promijeniVrstuBranioca = (id, val) => inputChange(id, val);
    const isDisabled = Array.isArray(BRANILAC) && BRANILAC.length === 2;

    const handleNeMozePosl = () => {
        if (isDisabled) {
            prikaziUpozorenje(true, 'danger', 'fixed', "Morate obrisati jednog advokata ukoliko će branilac biti po službenoj dužnosti!");
        }
    };

    const mfSlika = {
        backgroundImage: `url(${mfImage})`,
        backgroundSize: 'cover',
        backgroundPosition: licezensko ? 'center right' : 'center left', 
        minHeight: '190px',

      };

    return (
        <>
            <div className='sticky-top pt-2'>

                <InputGroup>
                <FloatingLabel
                    controlId="brojPredmeta"
                    label="Broj predmeta"
                    className="mb-1"
                    >
                    <Form.Control
                        type="text"
                        placeholder="S1 3 K 04"
                        value={brojPredmetaLocal}
                        autoComplete="off"
                        onChange={(e) => inputDebounce('brojPredmeta', e.target.value)}
                    />
                    </FloatingLabel>

                    <Button className="mb-1" variant='outline-secondary' onClick={novipredmet}><Trash /></Button>
                    <Button className="mb-1" variant='secondary' onClick={mojiPredmetiPrikazi}><ListTask /></Button>
                </InputGroup>

                {novipredmetAlert && novipredmetAlert.show && (
                    <Upozorenje2 {...novipredmetAlert} sakrijUpozorenje={prikaziUpozorenje}>Uspješno kreiran novi predmet.</Upozorenje2>
                )}

                <Modal dialogClassName="modal-mojiPredmeti" show={mojiPredmetiModal} onHide={mojiPredmetiSakrij}>
                    <Modal.Header closeButton>
                        <Modal.Title>Moji predmeti</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LocalStoragePredmeti />
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "none" }}>
                        <Button variant="secondary" onClick={mojiPredmetiSakrij}>
                            Zatvori
                        </Button>
                    </Modal.Footer>
                </Modal>

                <InputGroup className="mb-1" bsPrefix="colorfix input-group">
                    <FloatingLabel
                        controlId="IMELICA"
                        label="Ime lica"
                        className="mb-1"
                    >
                        <Form.Control type="text" placeholder="Ime lica" value={IMELICA.ime}
                         autoComplete="off"
                            onChange={(e) => inputChange('IMELICA', e.target.value)} />
                    </FloatingLabel>
                    {/*   <ToggleButton
                            id="licezensko"
                            type="checkbox"
                            variant="outline-secondary"
                            checked={licezensko}
                            onClick={(e) => inputChange('licezensko', !licezensko)}
                        >
                            {licezensko ? <><FontAwesomeIcon icon={faPersonDress} /></> : <><FontAwesomeIcon icon={faPerson} /></>}
                        </ToggleButton>
   */}

                    <Button variant="outline-secondary" className="mb-1" size="lg"
                        onClick={(e) => kontrolisiCanvas("CANVAS_LICNIPODACI_OTVORI")}
                    >
                        <FontAwesomeIcon icon={faInfo} />
                    </Button>
                </InputGroup>

                <Offcanvas style={{ "width": "880px" }} show={licnipodaciCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_LICNIPODACI_ZATVORI")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Lični podaci {potraživanog}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>

                        <Container className='ps-0'>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-1">
                                        <InputGroup.Text id="drzavljanstvolica" style={{ maxWidth: "38%" }}>
                                            Državljanstvo lica
                                        </InputGroup.Text>

                                        <AsyncSelect
                                            className='acinput'
                                            classNamePrefix="acinput"
                                            placeholder="Državljanstvo lica"
                                            isOptionDisabled={(e) => e.ime === 'Bosna i Hercegovina' && DRZAVLJANSTVO2?.ime === 'Bosna i Hercegovina'}
                                            cacheOptions
                                            defaultOptions={drzave}
                                            isClearable
                                            openMenuOnClick={false}
                                            value={DRZAVLJANSTVO}
                                            getOptionLabel={(e) => e.ime}
                                            getOptionValue={(e) => e.ime}
                                            loadOptions={ucitajZemlje}
                                            // onInputChange={(e) => inputChange('BRinput', e)}
                                            onChange={(e) => selektovano("DRZAVLJANSTVO", e)}
                                            noOptionsMessage={() => 'Nije pronađena država.'}
                                        />

                                        {(DRZAVLJANSTVO?.ime !== "") && <>
                                            {(drugodrzavljanstvo === false) ? <>
                                                <Button variant="outline-secondary" onClick={(e) => inputChange('drugodrzavljanstvo', !drugodrzavljanstvo)}>

                                                    <FontAwesomeIcon icon={faPlus} />

                                                </Button> </>
                                                :
                                                <>
                                                    <InputGroup.Text>1</InputGroup.Text>
                                                    <AsyncSelect
                                                        className='acinput'
                                                        classNamePrefix="acinput"
                                                        placeholder="Državljanstvo lica"
                                                        isOptionDisabled={(e) => e.ime === 'Bosna i Hercegovina' && DRZAVLJANSTVO?.ime === 'Bosna i Hercegovina'}
                                                        cacheOptions
                                                        defaultOptions={drzave}
                                                        isClearable
                                                        openMenuOnClick={false}
                                                        value={DRZAVLJANSTVO2}
                                                        getOptionLabel={(e) => e.ime}
                                                        getOptionValue={(e) => e.ime}
                                                        loadOptions={ucitajZemlje}
                                                        onChange={(e) => selektovano("DRZAVLJANSTVO2", e)}
                                                        noOptionsMessage={() => 'Nije pronađena država.'}
                                                    />
                                                    <InputGroup.Text>2</InputGroup.Text>
                                                    <Button variant="outline-secondary" onClick={(e) => inputChange('drugodrzavljanstvo', !drugodrzavljanstvo)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </Button>
                                                </>}
                                        </>}
                                    </InputGroup>

                                </Col>

                                <Col xs={3} className='shadow bg-body-tertiary rounded' style={{width:"180px", cursor: "pointer"}}
                                onClick={(e) => inputChange("licezensko", !licezensko)}>
                                <Container>
                                    <Row>
                                        
                                <Col xs={12} style={mfSlika} >
                                </Col>
                                <Col xs={12} style={{textAlign: "center", marginTop: "-15px"}}>
                                <h3 className="text-body-secondary">{licezensko ? "ŽENSKO" : "MUŠKO"}</h3>
                                </Col>
                                    </Row>
                                </Container>
                                </Col>


                            </Row>
                        
                            <Row>


                                <Col md={{ span: 4, offset: 8 }}>




                                </Col>


                            </Row>
                            <br />

                            <Row>
                                <Col className='pe-0'>

                                    <br />


                                    {licnidokumenti.map((dokument, index) => (


                                        <InputGroup className="mb-2" key={index} >

                                            <ToggleButton
                                                id={`licna${index}`}
                                                name="licna"
                                                type="checkbox"
                                                variant="outline-secondary"
                                                checked={dokument.licna}
                                                onChange={e => izmjenadokumenta('L', index, e)}

                                            >
                                                Lična
                                            </ToggleButton>

                                            <ToggleButton
                                                id={`pasos${index}`}
                                                name="pasos"
                                                type="checkbox"
                                                variant="outline-secondary"
                                                checked={dokument.pasos}
                                                onChange={e => izmjenadokumenta('L', index, e)}

                                            >
                                                Pasoš
                                            </ToggleButton>

                                            <Form.Control
                                                placeholder="broj dokumenta"
                                                aria-label="Default"
                                                aria-describedby={`brojdokumenta${index}`}
                                                type="text"
                                                name="brojdokumenta"
                                                value={dokument.brojdokumenta || ""}
                                                onChange={e => izmjenadokumenta('L', index, e)}

                                            />

                                            {/*
                                            <Form.Control
                                                placeholder="država izdavanja"
                                                aria-label="Default"
                                                aria-describedby={`drzavaizdavanja${index}`}
                                                type="text"
                                                name="drzavaizdavanja"
                                                value={dokument.drzavaizdavanja || ""}
                                                onChange={e => izmjenadokumenta('L', index, e)}
                                                
                                            /> */}

                                            <AsyncSelect
                                                className='acinput'
                                                classNamePrefix="acinput"
                                                placeholder="država izdavanja"
                                                cacheOptions
                                                defaultOptions={drzave}
                                                isClearable
                                                isOptionDisabled={disableBiHopciju}
                                                openMenuOnClick={false}
                                                value={dokument.drzavaizdavanja}
                                                getOptionLabel={(e) => e.ime}
                                                getOptionValue={(e) => e.ime}
                                                loadOptions={ucitajZemlje}
                                                onChange={(e) => selektovano("LDdrzavaizdavanja", e, index)}
                                                noOptionsMessage={() => 'Nije pronađena država.'}
                                            />

                                            {dokument.licna === true
                                                ?
                                                <>
                                                    <Form.Control
                                                        placeholder="organ izdavanja"
                                                        aria-label="Default"
                                                        aria-describedby={`organizdavanja${index}`}
                                                        name="organizdavanja"
                                                        value={dokument.organizdavanja || ""}
                                                        onChange={e => izmjenadokumenta('L', index, e)}

                                                    />
                                                </> : <></>}

                                            <Form.Control
                                                placeholder="datum isteka važenja"
                                                aria-label="Default"
                                                aria-describedby={`rokvazenja${index}`}
                                                name="rokvazenja"
                                                value={dokument.rokvazenja || ""}
                                                onChange={e => izmjenadokumenta('L', index, e)}

                                            />

                                            <ToggleButton
                                                id={`uvid${index}`}
                                                name="uvid"
                                                type="checkbox"
                                                variant="outline-secondary"
                                                checked={dokument.uvid}
                                                onChange={e => izmjenadokumenta('L', index, e)}

                                            >
                                                {dokument.uvid ? <><FontAwesomeIcon icon={faEye} /></> : <><FontAwesomeIcon icon={faEyeSlash} /></>}

                                            </ToggleButton>

                                            <Button variant="outline-secondary" onClick={() => brisidokument('L', index)} >
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>

                                        </InputGroup>

                                    ))}

                                    <Button variant="outline-secondary float-end"
                                        style={{
                                            opacity: (licnidokumenti.length === 4) ? '40%' : ''
                                        }}
                                        disabled={(licnidokumenti.length === 4) ? true : false}
                                        onClick={(e) => dodajdokument("L")}>
                                        Dodaj dokument <PersonVcard className='ms-1' size={20} />
                                    </Button>

                                    <br />
                                    <br />

                                    {!licnipodaci.uvjerenje &&
                                        <>
                                            <Button variant="outline-secondary float-end"
                                                //    style={{
                                                //        opacity: (licnidokumenti.length === 4) ? '40%' : ''
                                                //   }}
                                                //   disabled={(licnidokumenti.length === 4) ? true : false}
                                                onClick={DrzavljaninBiH === "DA" ? (e) => dodajdokument("LUV") : clickUvjerenje}                                                >
                                                Dodaj uvjerenje o državljanstvu BiH <CardHeading className='ms-1' size={20} />
                                            </Button>
                                            <br />
                                            <br />
                                        </>
                                    }

                                    {uvjerenjealert && uvjerenjealert.show && (
                                        <Upozorenje2 {...uvjerenjealert} sakrijUpozorenje={prikaziUpozorenje}>Uvjerenje je moguće dodati samo ako je {potraživani} {državljanin} BiH!</Upozorenje2>
                                    )}

                                </Col>
                            </Row>

                            {DrzavljaninBiH === "DA" && licnipodaci.uvjerenje &&
                                <Row className='mt-4'>

                                    <Col className='m-0 p-0'>
                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="brojidatumuvjerenja" style={{ borderBottom: '0' }}>
                                                Broj i datum uvjerenja
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Broj uvjerenja"
                                                aria-describedby="Broj uvjerenja"
                                                style={{ borderBottom: '0', borderRight: '0' }}
                                                placeholder="08/20-16-3-1/21 od 02.02.2022. godine"
                                                type="text"
                                                name="brojidatumuvjerenja"
                                                value={licnipodaci.uvjerenje.brojidatumuvjerenja || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="maticniured" style={{ borderBottom: '0' }}>
                                                Matični ured
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Matični ured"
                                                aria-describedby="Matični ured"
                                                placeholder="matični ured"
                                                style={{ borderBottom: '0', borderRight: '0' }}
                                                type="text"
                                                name="maticniured"
                                                value={licnipodaci.uvjerenje.maticniured || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="podrucjeunosa" >
                                                Područje upisa
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Područje unosa"
                                                aria-describedby="Područje unosa"
                                                placeholder="Područje unosa"
                                                style={{ borderRight: '0' }}
                                                type="text"
                                                name="podrucjeunosa"
                                                value={licnipodaci.uvjerenje.podrucjeunosa || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                    </Col>

                                    <Col className='m-0 p-0'>


                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="stranaunosa" style={{ borderBottom: '0' }}>
                                                Strana unosa
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Strana unosa"
                                                aria-describedby="Strana unosa"
                                                placeholder="Strana unosa"
                                                style={{ borderBottom: '0' }}
                                                type="text"
                                                name="stranaunosa"
                                                value={licnipodaci.uvjerenje.stranaunosa || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="rednibrojunosa" style={{ borderBottom: '0' }}>
                                                Redni broj unosa
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Redni broj unosa"
                                                aria-describedby="Redni broj unosa"
                                                placeholder="Redni broj unosa"
                                                style={{ borderBottom: '0' }}
                                                type="text"
                                                name="rednibrojunosa"
                                                value={licnipodaci.uvjerenje.rednibrojunosa || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                        <InputGroup className='noradius'>
                                            <InputGroup.Text id="godinaunosa">
                                                Godina unosa
                                            </InputGroup.Text>
                                            <Form.Control
                                                aria-label="Godina unosa"
                                                aria-describedby="Godina unosa"
                                                placeholder="Godina unosa"
                                                type="text"
                                                name="godinaunosa"
                                                value={licnipodaci.uvjerenje.godinaunosa || ""}
                                                onChange={e => izmjenadokumenta('LUV', undefined, e)}
                                            />
                                        </InputGroup>

                                        <Button variant="outline-danger mt-2 float-end" onClick={() => brisidokument('LUV', undefined)} >
                                            Briši uvjerenje <FontAwesomeIcon icon={faTrashCan} />
                                        </Button>
                                    </Col>

                                </Row>}



                            <Row>
                                <Col className='mt-4'>
                                    <LPodaci kod="LPunos" />

                                </Col>
                            </Row>

                        </Container>
                    </Offcanvas.Body>
                </Offcanvas>


                <AsyncSelect
                    className="mb-1"
                    placeholder="Država moliteljica"
                    classNamePrefix="select_bootstrapstil"
                    isOptionDisabled={(e) => e.ime === 'Bosna i Hercegovina'}
                    cacheOptions
                    defaultOptions={drzave}
                    isClearable
                    openMenuOnClick={false}
                    value={DRZMOLITELJICA}
                    getOptionLabel={(e) => e.ime}
                    getOptionValue={(e) => e.ime}
                    loadOptions={ucitajZemlje}
                    // onInputChange={(e) => inputChange('BRinput', e)}
                    onChange={(e) => selektovano("DRZMOLITELJICA", e)}
                    noOptionsMessage={() => 'Nije pronađena država.'}
                />

                <FloatingLabel
                    controlId="ncb1"
                    label="Broj i datum potjernice NCB Sarajevo"
                    className="mb-1"
                ><Form.Control type="text" placeholder="Broj i datum potjernice NCB Sarajevo" value={ncb1}
                    autoComplete="off"
                    onChange={(e) => inputChange('ncb1', e.target.value)} />
                </FloatingLabel>

                <FloatingLabel
                    controlId="ncb2"
                    label="Broj i datum potjernice NCB moliteljica"
                    className="mb-1"
                ><Form.Control type="text" placeholder="Broj i datum potjernice NCB moliteljica" value={ncb2}
                    autoComplete="off"
                    onChange={(e) => inputChange('ncb2', e.target.value)} />
                </FloatingLabel>

                <Razlogizrucenja />


                <DatePicker
                    dateFormat="dd.MM.yyyy."
                    selected={datumlisenjaTMP}
                    onChange={(date) => inputChange('datumlisenja', date)}
                    locale="bs"
                    className="form-control mb-1"
                    wrapperClassName="datumpicker"
                    placeholderText="Datum lišenja"

                />


                <OverlayTrigger trigger="focus" placement="right" overlay={organLisenjaPopover}>
                    <FloatingLabel
                        controlId="organLisenja"
                        label="Organ lišenja"
                        className="mb-1"
                    ><Form.Control type="text" placeholder="Organ lišenja" value={organLisenja.val}
                        autoComplete="off"
                        onChange={(e) => inputChange('organLisenja', e.target.value)} />
                    </FloatingLabel>
                </OverlayTrigger>

                {vrstaOdluke === "EKS" && <AsyncSelect
                    className="mb-1"
                    placeholder="Izaberi tužioca"
                    cacheOptions
                    defaultOptions={tuzioci}
                    isClearable
                    openMenuOnClick={false}
                    value={TUZILAC}
                    getOptionLabel={(e) => e.ime}
                    getOptionValue={(e) => e.ime}
                    loadOptions={ucitajTuzioce}
                    // onInputChange={(e) => inputChange('BRinput', e)}
                    onChange={(e) => selektovano("TUZILAC", e)}
                    noOptionsMessage={(inputValue) => nemaOpcije(inputValue, 'tužilac')}
                />}



                <OverlayTrigger trigger="focus" placement="right" overlay={
                    <Popover id="popover-basic">
                        <Popover.Header as="h3">Vrsta branioca</Popover.Header>
                        <Popover.Body>


                            <ToggleButtonGroup type="checkbox" name="BRvrstabranioca" value={vrstabranioca} className="mb-2">

                                <ToggleButton id="BRposluzbenoj" variant="outline-secondary"
                                    value="posluzbenoj"
                                    // onChange={(e) => promijeni("vrstabranioca", e.target.value)}
                                    onChange={(e) => promijeniVrstuBranioca("vrstabranioca", BRANILAC.length === 2 ? "izabrani" : "posluzbenoj")}
                                    style={{ fontSize: "14px" }}
                                    onClick={handleNeMozePosl}
                                >
                                    po službenoj
                                </ToggleButton>
                                <ToggleButton id="BRizabrani" variant="outline-secondary" value="izabrani" onChange={(e) => promijeniVrstuBranioca("vrstabranioca", e.target.value)} style={{ fontSize: "14px" }}>
                                    izabrani
                                </ToggleButton>

                            </ToggleButtonGroup>

                        </Popover.Body>
                    </Popover>
                }>
                    <AsyncSelect
                        className="mb-1"
                        placeholder="Izaberi advokata"
                        cacheOptions
                        defaultOptions={advokati}
                        isMulti={true}
                        //isOptionDisabled={BRANILACselektovan && BRANILACselektovan.length >= 2}
                        isClearable
                        openMenuOnClick={false}
                        value={BRANILAC}
                        getOptionLabel={(e) => e.ime + " - " + e.grad}
                        getOptionValue={(e) => e.ime}
                        loadOptions={ucitajAdvokate}
                        // onInputChange={(e) => inputChange('BRinput', e)}
                        onChange={(e) => selektovano("BR", e)}
                        noOptionsMessage={(inputValue) => nemaOpcije(inputValue, 'advokat')}
                    />
                </OverlayTrigger>
                {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}



                <AsyncSelect
                    className="mb-1"
                    placeholder="Izaberi zapisničara"
                    cacheOptions
                    defaultOptions={zapisnicari}
                    isClearable
                    openMenuOnClick={false}
                    value={ZAPISNICAR}
                    getOptionLabel={(e) => e.ime}
                    getOptionValue={(e) => e.ime}
                    loadOptions={ucitajZapisnicare}
                    // onInputChange={(e) => inputChange('BRinput', e)}
                    onChange={(e) => selektovano("ZAPISNICAR", e)}
                    noOptionsMessage={(inputValue) => nemaOpcije(inputValue, 'zapisničar')}
                />

                <AsyncSelect
                    className="mb-1"
                    placeholder="Izaberi sudiju"
                    cacheOptions
                    defaultOptions={sudije}
                    isClearable
                    openMenuOnClick={false}
                    value={SUDIJA}
                    getOptionLabel={(e) => e.ime}
                    getOptionValue={(e) => e.ime}
                    loadOptions={ucitajSudije}
                    // onInputChange={(e) => inputChange('BRinput', e)}
                    onChange={(e) => selektovano("SUDIJA", e)}
                    noOptionsMessage={(inputValue) => nemaOpcije(inputValue, 'sudija')}
                />

                <Offcanvas style={{ "width": "500px" }} show={dodajTuziocaCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_DODAJSTRANKU_ZATVORI", "tužilac")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Dodaj novog tužioca</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DodajTuzioca />
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas style={{ "width": "635px" }} show={dodajAdvokataCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_DODAJSTRANKU_ZATVORI", "advokat")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Dodaj novog advokata</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DodajAdvokata />
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas style={{ "width": "500px" }} show={dodajZapinsicaraCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_DODAJSTRANKU_ZATVORI", "zapisničar")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Dodaj novog zapisničara</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DodajZapisnicara />
                    </Offcanvas.Body>
                </Offcanvas>


                <Offcanvas style={{ "width": "500px" }} show={dodajSudijuCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_DODAJSTRANKU_ZATVORI", "sudija")}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Dodaj novog sudiju</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DodajSudiju />
                    </Offcanvas.Body>
                </Offcanvas>



                {!dodajtumaca && <><ToggleButton
                    className=""
                    id="dodajtumaca"
                    type="checkbox"
                    size='sm'
                    variant="outline-secondary float-end"
                    checked={dodajtumaca}
                    value="1"
                    onChange={(e) => inputChange("dodajtumaca", e.target.checked)}
                >
                    Dodaj tumača
                </ToggleButton>
                </>}

                {dodajtumaca && (
                    <>

                        <OverlayTrigger trigger="focus" placement="right" overlay={spolTumacaPopover}>
                            <InputGroup className="mb-1">
                                <InputGroup.Text id="imetumaca">Tumač</InputGroup.Text>
                                <Form.Control
                                    aria-label="Default"
                                    aria-describedby="imetumaca"
                                    type="text"
                                    value={TUMAC.ime}
                                    onChange={(e) => inputChange("TUMAC", e.target.value)}
                                />

                                <ToggleButton
                                    id="dodajtumaca"
                                    type="checkbox"
                                    variant="outline-secondary"
                                    //  style={{dodajtumaca && "line-through" }}
                                    style={{ backgroundColor: "white", color: "#6c757d" }}
                                    checked={dodajtumaca}
                                    value="1"
                                    onChange={(e) => inputChange("dodajtumaca", e.target.checked)}
                                >
                                    {dodajtumaca && "Briši"}
                                </ToggleButton>

                            </InputGroup>
                        </OverlayTrigger>


                    </>
                )}


                {dodajtumaca && <>
                    <OverlayTrigger trigger="focus" placement="right" overlay={jezikprevodaPopover}>
                        <InputGroup className="mb-1">
                            <InputGroup.Text id="jezikprevoda">Jezik prevoda</InputGroup.Text>
                            <Form.Control
                                aria-label="Default"
                                aria-describedby="jezikprevoda"
                                type="text"
                                value={jezikprevoda}
                                onChange={(e) => inputChange("jezikprevoda", e.target.value)}
                            />
                        </InputGroup>
                    </OverlayTrigger>
                </>}
                <br />
                <br />

                <br />
                <br />
                <br />
            </div>
        </>


    )
}

export default Inputi