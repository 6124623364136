import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';


/*
Sintaxa: Definisati sljedeće u kmponenti: 

  const [alerti, setAlerti] = useState([
    { id: "spasenpredmet", show: false, tip: 'danger', pozicija: 'fixed', poruka: 'dedeed' },
  
  ]);

  const prikaziUpozorenje = (id, show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    const newAlerti = alerti.map(alert => {
      if (alert.id === id) {
        return { ...alert, show, tip, pozicija, poruka };
      }
      return alert;
    });
    setAlerti(newAlerti);
  };

  const spasenpredmet = alerti.find(alert => alert.id === "spasenpredmet");

i koristiti kao:

{spasenpredmet && spasenpredmet.show && (
        <Upozorenje2 {...spasenpredmet} sakrijUpozorenje={prikaziUpozorenje}>Predmet spašen.</Upozorenje2>
      )}  


*/


const Upozorenje2 = ({ id, tip, pozicija, poruka, children, sakrijUpozorenje}) => {

  let style = {};

  if (pozicija === "fixed") {
    style = { position: "fixed", bottom: "50px", left: "50%", transform: "translate(-50%, 0)" }
  }
  else if (pozicija === "inline") {
    style = {}

  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      sakrijUpozorenje(id);
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  return <>
    <div style={style}>

      {!children && !poruka
        ?
        <Alert variant="danger alertV2">
          Nije definisana alert poruka! (props/children).
        </Alert>
        :
        poruka ?
          <Alert variant={`${tip} alertV2`}>
            {poruka}
          </Alert>
          :
          <Alert variant={`${tip} alertV2`}>
            {children}
          </Alert>
      }

    </div>

  </>

};

export default Upozorenje2;
