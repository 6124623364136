import React from 'react'
import { useGlobalContext } from '../../context'

import ToggleButton from "react-bootstrap/ToggleButton";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

const OkolnostiMZolaksavajuce = () => {
    const { sveOkolnostiMZOlaksavajuce, izborOkolnosti, odabraneOkolnostiMZOlaksavajuce, potraživanog } = useGlobalContext()

    const OkolnostiMZ = () => {
        return (
            <>
                {sveOkolnostiMZOlaksavajuce.map((okolnost, index) => (
                    <ToggleButton
                        className="m-1"
                        key={index}
                        id={okolnost.value}
                        type="checkbox"
                        variant="outline-secondary"
                        size="sm"
                        checked={okolnost.pojedinosti.cekirano}
                        style={{ opacity: okolnost.pojedinosti.disabled ? "40%" : "",}}
                        value={okolnost.value}
                        onChange={(e) => izborOkolnosti("MZOKOLNOSTI", e.target.value, e.target.checked)}
                        disabled={okolnost.pojedinosti.disabled}
                        
                    >
                        {okolnost.pojedinosti.naziv}
                        
                    </ToggleButton>
                ))}
            </>
        );
    };

    return (<>
    <Col>  <Card
            bg=""
            key="1"
            text="dark"
            style={{ textAlign: "left" }}
            className={`text-dark mb-2 ${odabraneOkolnostiMZOlaksavajuce.length < 1 && 'obavezno'}`}
        >
            <Card.Header
                className={`${odabraneOkolnostiMZOlaksavajuce.length < 1 && 'obavezno'}`}
            >
               Veze {potraživanog} sa Bosnom i Hercegovinom
            </Card.Header>
            <Card.Body className='p-2'>
                <OkolnostiMZ />
            </Card.Body>
        </Card>
        <br />
      </Col>
      
    </>
    )
}

export default OkolnostiMZolaksavajuce

