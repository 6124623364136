import React from 'react'
import { useGlobalContext } from '../context'

const Sastavsudaipouka = (props) => {

  const { vrstaOdluke, vrstabranioca, pR, pL, pC, pJ, SUDIJA, ZAPISNICAR, BRANILAC, TUZILAC, TUMAC, pLDNA, PRev, PROev, EKSev, IMELICA,
    Potraživani, priveden, dodajtumaca, jezikprevoda, pRDNA, dvabranioca, POTRAŽIVANI} = useGlobalContext()


  /* 
   <Sastavsudaipouka kod="sastav" sastav="svi/svipotpis/zapspp" />
  <Sastavsudaipouka kod="pouka"/>
  <Sastavsudaipouka kod="evidencija"/>
  */


  const SastavSuda = (props) => {
    return (
      <>
        {props.sastav === "svi" ?
          <>
               <p style={pL}>
                  <strong>SUDIJA</strong><br /><strong>ZA PRETHODNI POSTUPAK</strong><br /><strong>{SUDIJA.ime || "_________"}</strong> 
                  <br/><br/>
                  <strong>ZAPISNIČAR</strong><br /><strong>{ZAPISNICAR.ime || "_________"}</strong> 
                </p>    

                <p style={pR}>
                <strong>{Potraživani}: {IMELICA.ime || "__________"}</strong> ({priveden})
                <br/><br/>
                
                {dvabranioca ?
                      <>
                      <strong>Branilac: {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</strong>
                      <br />
                      (izabrani)
                      <br />
                      <br />
                      <strong>Branilac: {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</strong>
                      <br />
                      (izabrani)
                      </>
                      :
                      <>
                      <strong>Branilac: {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</strong>
                      <br/>({vrstabranioca === "posluzbenoj" ? "po službenoj dužnosti" : "izabrani"})
                      </>}

                {dodajtumaca && <><br/><br/><strong>Sudski tumač za {jezikprevoda || "__________"} jezik:</strong> {TUMAC.ime || "__________"}</>}
                
                {vrstaOdluke === "EKS" &&
                <>
                <br />
                <br />
                <strong>Tužilac</strong>
                <br />
                {TUZILAC.ime || "__________"}
                </>}
         
                </p>
                
          </>
          :
          props.sastav === "svipotpis" ?
            <>
              <p style={{ ...pC, marginBottom: "unset" }}><strong>ZAPISNIČAR</strong>{'\u00A0'.repeat(70)}<strong>S U D I J A</strong></p>    
              <p style={{ ...pC, marginBottom: "unset" }}>{'\u00A0'.repeat(85)}<strong>ZA PRETHODNI POSTUPAK</strong></p> 
              <p style={pC}><strong>{ZAPISNICAR.ime || "_________"}</strong>{'\u00A0'.repeat(60)}<strong>{SUDIJA.ime || "_________"}</strong></p>   
              <p style={pC}>_______________{'\u00A0'.repeat(70)}_______________</p>    
    

                <p style={pC}>
                <strong>{POTRAŽIVANI}: {IMELICA.ime || "__________"}</strong> ({priveden})
                <br/>
                _______________
                <br/>
                <br/>

                {dvabranioca ?
                      <>
                      <strong>BRANILAC:</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}
                      <br/>
                _______________
                <br/>
                <br/>

                      <strong>BRANILAC:</strong> {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}
                      <br />
                      <br/>
                _______________                      </>
                      :
                      <>
                                
                      <strong>BRANILAC:</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}
                      <br />
                      <br/>
                _______________
                      </>}
                
                {dodajtumaca && <><br/><br/><strong>Sudski tumač za {jezikprevoda || "__________"} jezik:</strong> {TUMAC.ime || "__________"}
                <br/>
                _______________</>}
                
                <br/>
                <br/>
                <strong>TUŽILAC: {TUZILAC.ime || "__________"}</strong>
                <br/>
                _______________
                <br/>
                <br/>
                
                </p>
                
            </>
            :
            props.sastav === "zapspp" ?
              <>
              <p style={{ ...pC, marginBottom: "unset" }}><strong>ZAPISNIČAR</strong>{'\u00A0'.repeat(60)}<strong>S U D I J A</strong></p>    
              <p style={pC}>{'\u00A0'.repeat(80)}<strong>ZA PRETHODNI POSTUPAK</strong></p> 
              <p style={pC}><strong>{ZAPISNICAR.ime || "_________"}</strong>{'\u00A0'.repeat(50)}<strong>{SUDIJA.ime || "_________"}</strong></p>   
              </>
              
              :
              props.sastav === "spp" ?
                <>
                <p style={{ ...pC, marginBottom: "unset" }}>{'\u00A0'.repeat(70)}<strong>S U D I J A</strong></p>    
                <p style={pC}>{'\u00A0'.repeat(70)}<strong>ZA PRETHODNI POSTUPAK</strong></p> 
                <p style={pC}>{'\u00A0'.repeat(70)}<strong>{SUDIJA.ime || "_________"}</strong></p>   
                </>
                :
                <>
                <p style={{ ...pC, marginBottom: "unset" }}>{'\u00A0'.repeat(70)}<strong>S U D I J A</strong></p>    
                <p style={pC}>{'\u00A0'.repeat(70)}<strong>ZA PRETHODNI POSTUPAK</strong></p> 
                <p style={pC}>{'\u00A0'.repeat(70)}<strong>{SUDIJA.ime || "_________"}</strong></p>   
                </>
        }

      </>
    )
  }



  const Pouka = () => {
    return (
      <p style={pJ}>
        {vrstaOdluke === "PR" || vrstaOdluke === "PREKS"
          ?
          <>
            <strong>POUKA O PRAVNOM LIJEKU:</strong> Protiv ovog rješenja dozvoljena je žalba vanraspravnom vijeću Suda Bosne i Hercegovine u roku od 24 sata od prijema ovog rješenja. Žalba na rješenje ne odlaže njegovo izvršenje.

          </>
          :
          vrstaOdluke === "ODB" || vrstaOdluke === "MZ" || vrstaOdluke === "PRO" || vrstaOdluke === "EKS"
            ?
            <>
              <strong>POUKA O PRAVNOM LIJEKU:</strong> Protiv ovog rješenja dozvoljena je žalba vanraspravnom vijeću Suda Bosne i Hercegovine u roku od 3 (tri) dana od prijema ovog rješenja. Žalba na rješenje ne odlaže njegovo izvršenje.

            </>
            :
            vrstaOdluke === "BR"
              ?
              <>
                {vrstabranioca !== "izabrani" &&
                  <>
                    <strong>POUKA O PRAVNOM LIJEKU:</strong> Protiv ovog rješenja dozvoljena je žalba vanraspravnom vijeću Suda Bosne i Hercegovine u roku od 3 (tri) dana od prijema ovog rješenja. Žalba na rješenje ne odlaže njegovo izvršenje.

                  </>}

              </>
              :
              <>
              </>}
      </p>
    )
  }

  const Evidencija = (props) => {
    return <>
      {vrstaOdluke === "ODB" || vrstaOdluke === "MZ" || vrstaOdluke === "PR" || vrstaOdluke === "PREKS"
        ?
        <>
          <p style={pLDNA}>Ev: {PRev || "__________"}  -  (provjeriti da li je uložena žalba...)</p>
          <p style={pRDNA}>
            Sudija<br /> {SUDIJA.ime || "_________"}
          </p>
        </>
        :
        vrstaOdluke === "PRO"
          ?
          <>
            <p style={pLDNA}>Ev: {PROev || "__________"}  -  (provjeriti da li je uložena žalba...)</p>
            <p style={pRDNA}>
              Sudija<br /> {SUDIJA.ime || "_________"}
            </p>
          </>
          :
          <>
            <p style={pLDNA}>Ev: {EKSev || "__________"}  -  (provjeriti da li je uložena žalba, nastaviti sa izvriđajnim radnjama ...)</p>
            {/* Defaultno učitava EKSev jer SastavDOP učitava samo ako vrstaDopisa nije "", a prethodno su pokrivene sve ostale situacije */}
            <p style={pRDNA}>
              Sudija<br /> {SUDIJA.ime || "_________"}
            </p>
          </>}
    </>
  }  //Evidencija

  return (

    <>
      {props.kod === "sastav" && <>
        <SastavSuda sastav={props.sastav} />
      </>}

      {props.kod === "pouka" && <>
        <Pouka />
      </>}

      {props.kod === "evidencija" && <>
        <Evidencija />
      </>}

    </>


  )
}

export default Sastavsudaipouka