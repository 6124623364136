import React, { useContext, useReducer, useEffect } from "react";
import reducer from "./reducer";
import { format} from "date-fns";
import { add } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';


const AppContext = React.createContext();
/*
const sveOkolnostiProcesuiranje = [
    { name: "Visinakazne", value: "Pvisinakazne", cekirano: true },
    { name: "Teško djelo", value: "Pteskodjelo", cekirano: false },
    { name: "Više djela", value: "Pvisedjela", cekirano: false },
    { name: "Nedostupan duže vrijeme", value: "Pnedostupanduzevrijeme", cekirano: false }
];

const today = new Date();
const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
const yyyy = today.getFullYear();

const danasnjidatum = dd + '.' + mm + '.' + yyyy + '.godine';


const PRkrajlisenja = add(new Date(), {
  days: 17
})

const PROkrajlisenja = add(new Date(), {
  days: 40
})

const EKSkrajlisenja = add(new Date(), {
months: 6
})

*/

//console.log("context - ",danasnjidatum)

let PRevTMP = add(new Date(), {
  days: 3
})
PRevTMP = format(PRevTMP, 'dd.MM.yyyy') + ". godine"

let PROevTMP = add(new Date(), {
  days: 3
})
PROevTMP = format(PROevTMP, 'dd.MM.yyyy') + ". godine"

let EKSevTMP = add(new Date(), {
  days: 3
})
EKSevTMP = format(EKSevTMP, 'dd.MM.yyyy') + ". godine"


const initialState = {
  ID: uuidv4(),
  updejtujStranke : 0,  // state value koji se mijenja nakon uspješnog dodavanja stranke, a za tim trigeruje fetch u inputima
  danasnjidatum: new Date(),
  datumodlukeTMP: new Date(),
  datumodluke: "",
  datumodlukeODBTMP: "",
  datumodlukeODB: "",
  datumodlukeMZTMP: "",
  datumodlukeMZ: "",
  datumodlukePRTMP: "",
  datumodlukePR: "",
  datumodlukePREKSTMP: "",
  datumodlukePREKS: "",
  datumodlukePROTMP: "",
  datumodlukePRO: "",
  datumodlukeEKSTMP: "",
  datumodlukeEKS: "",
  datumodlukeBRTMP: "",
  datumodlukeBR: "",

  vrijemeunosa: "",
  vrijemeizmjene: "",
  brojPredmeta: "",
  IMELICA: { "ime": "", "imegen": "", "imedat": "" },
  licezensko: false,
  licnipodaciCanvas: false,
  DRZMOLITELJICA: "",
  PostojiUgovor: "NE",
  DRZAVLJANSTVO: "",
  DRZAVLJANSTVO2: "",
  drugodrzavljanstvo: false,
  DrzavljaninBiH: "NE",
  posjedujedrzavljanstvo: "",
  dvadrzavljanstva: "NE",
  stranac: "DA",

  licnidokumenti: [],

  licnipodaci: {
    otac: "",
    majka: "",
    prezimemajke: "",
    datumrođenja: "",
    mjestorođenja: "",
    nastanjen: "",
    nacionalnost: "",
    državljanstvo: "",
    uvjerenje: "",
    zanimanje: "",
    zaposlen: false,
    firma: "",
    oženjen: false,
    djeca: false,
    brojdjece: "",
  },

  ncb1: "",
  ncb2: "",
  razlogIzrucenjaCanvas: false,
  dodajTuziocaCanvas: false,
  dodajAdvokataCanvas: false,
  dodajZapinsicaraCanvas: false,
  dodajSudijuCanvas: false,
  dodajGreskuCanvas: false,


  procesuiranje: true,
  izvrsenje: false,

  procesuiranjeInfo: [{
    procesuiranjekd: "",
    procesuiranjekazna: { val: "", paraf: "", parafrazirano: "DA" },    //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: "",
    procesuiranjebrojnaloga: "",
    procesuiranjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
    procesuiranjeODB: { vrijemevazenja: false, visinakazne: false }
  }
  ],
  izvrsenjeInfo: [{
    izvrsenjepresuda: "",
    izvrsenjenazivsuda: { val: "", nom: "", gen: undefined }, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: "",
    izvrsenjekazna: { val: "", izrecenakazna: "", preostalidio: undefined, izrecenakaznanom: "", preostalidionom: "" },   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjeprisutanizricanju: "DA",
    izvrsenjevrijemeizvrsenja: "",
    izvrsenjebrojnaloga: "",
    izvrsenjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjeODB: { vrijemevazenja: false, visinakazne: false }
  }],
  alerti: { kaznapreniska: false, },
  datumlisenjaTMP: "",
  datumlisenja: "",
  organLisenja: { val: "", nom: "", gen: "" },

  PRkrajlisenja: "",
  
  ODBfaza: "",
  MZfaza: "",
  PRfaza: "",
  PREKSfaza: "",
  BRfaza:"",

  PROpostoji: true,
  PROfaza: 2,
  PROzahtjevNCB: false,
  PROzahtjevMPBIH: false,
  PROzahtjevdostavljenBR: "",
  PROzahtjevNCBbroj: "",
  PROzahtjevNCBdatumprijema: "",
  PROzahtjevMPBIHbroj: "",
  PROzahtjevMPBIHdatumprijema: "",
  PROzahtjev: "",

  PROkrajlisenja: "",
  PROopcijeCanvas: false,

  EKSopcijeCanvas: false,
  EKSfaza: 3,
  EKSbrojprijedloga: "",
  EKSbrojmolbe: "",
  EKSkrajlisenja: "",

  PRev: PRevTMP,
  PROev: PROevTMP,
  EKSev: EKSevTMP,

  vrstaOdluke: "",
  vrstaDopisa: "",
  ODBobustavi: "NE",
  ODBopcijeCanvas: false,
  ODBopcije: { aktivnapotjernica: true, NCBobavijestNeaktivnaPotjernica: "" },

  MZopcijeCanvas: false,
  MZputovanje: false,
  MZboraviste: false,
  MZboravistekucni: "NE",
  MZboravistepodrucje: "područja grada ______",
  MZboravisteadresa: "",
  MZboravisteadresanacinprovjere:
    "jednom dnevno, nasumično, u vremenskom periodu između 08,00 i 12:00 sati",
  MZstanica: "", //za boravište i javljanje

  MZjavljanje: false,
  MZjavljanjenacin:
    "dva puta sedmično (svakog ______ i _____) u vremenskom periodu od 08:00 do 12:00 sati",

  MZpreuzmiisprave: false,
  MZprikazipasose: false,
  MZpasosi: [],
  MZdodajispravu: false,
  MZnemapasosaAlert: false,

  vrstabranioca: "posluzbenoj",
  BRnacinizbora: "",
  OkolnostiCanvas: false,
  sveOkolnostiMZOlaksavajuce: [
    { value: "MZboraviste", pojedinosti: { naziv: "boravište/prebivalište u BiH", cekirano: false, disabled: false } },
    { value: "MZsmjestaj", pojedinosti: { naziv: "osiguran/iznajmljen smještaj u BiH", cekirano: false, disabled: false } },
    { value: "MZporodicne", pojedinosti: { naziv: "porodične veze sa BiH", cekirano: false, disabled: false } }
  ],
  odabraneOkolnostiMZOlaksavajuce: [],

  sveOkolnostiProcesuiranje: [
    /*{ value: "Pvisinakazne", pojedinosti: {naziv: "visina kazne",  cekirano: false, disabled: false}},
    { value: "Pteskodjelo", pojedinosti: {naziv: "teško djelo",  cekirano: false, disabled: false}},*/
    { value: "Pvisedjela", pojedinosti: { naziv: "više djela", cekirano: false, disabled: false } },
    /*{
      name: "nedostupan duže vrijeme",
      value: "Pnedostupanduzevrijeme",
      cekirano: false,
    },*/
  ],
  odabraneOkolnostiProcesuiranje: [],
  sveOkolnostiIzvrsenje: [
    /*{ value: "Ivisinakazne", pojedinosti:{naziv: "visina kazne",  cekirano: false,      disabled: false}  },
    {
      value: "Iprisutanizricanju",
      pojedinosti: {naziv: "prisutan izricanju",
      cekirano: false,
      disabled: false}
    },
    {
      name: "nedostupan duže vrijeme",
      value: "Inedostupanduzevrijeme",
      cekirano: false,
    },*/
  ],
  odabraneOkolnostiIzvrsenje: [],
  sveOkolnostiLicne: [
    {
      value: "Lfalsdokumenti",
      pojedinosti: {
        naziv: "posjed lažnog dokumenta",
        cekirano: false,
        disabled: false
      }
    },
    // {value: "Lnemadokumenata"},  ... razmotriti automatsko obrazloženje (kretao se, teža identifikacija...)
    { value: "Lvisealiasa", pojedinosti: { naziv: "više aliasa", cekirano: false, disabled: false } },
    {
      value: "Lbjekstvoumaticnu",
      pojedinosti: {
        naziv: "bjekstvo u matičnu zemlju",
        cekirano: false,
        disabled: false
      }
    },
    {
      value: "Lboravisteudrugojzemlji",
      pojedinosti: {
        naziv: "boravište u drugoj zemlji",
        cekirano: false,
        disabled: false
      }
    },
    {
      value: "Lvezesadrugomzemljom",
      pojedinosti: {
        naziv: "veze sa drugom zemljom",
        cekirano: false,
        disabled: false
      }
    },
    { value: "Lbezvezasabih", pojedinosti: { naziv: "bez veza sa BiH", cekirano: false, disabled: false } },
    {
      value: "Lbeznacajneveze",
      pojedinosti: {
        naziv: "beznačajne veze sa BiH",
        cekirano: false,
        disabled: false
      }
    },

  ],
  odabraneOkolnostiLicne: "",
  osnoviugovor: "",
  osnoviugovorHR: [
    {
      id: "1",
      name: "član 7. stav 1.",
      value: "PugovorHRVclan71",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <strong>krivičnog gonjenja</strong> za krivična djela <strong>organiziranog kriminala, korupcije i pranja novca </strong> čija kazna <b>uključuje</b> oduzimanje slobode u trajanju od <strong>četiri godine ili teža kazna</strong>.",
    },
    {
      id: "2",
      name: "član 7. stav 2.",
      value: "IugovorHRVclan72",
      vrsta: "izvrsenje",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <strong>izvršenja</strong> pravosnažno izrečene kazne zatvora za krivična djela <strong>organiziranog kriminala, korupcije i pranja</strong> novca ako ostatak kazne koji se treba izvršiti iznosi <strong>najmanje dvije godine</strong>.",
    },
    {
      id: "3",
      name: "član 8. stav 1.",
      value: "PugovorHRVclan81",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <strong>krivičnog gonjenja</strong> za druga <strong>teška krivična djela</strong> za koja se može izreći kazna zatvora koja <strong>uključuje</strong> oduzimanje slobode u trajanju <strong>od deset godina ili teža kazna</strong>.",
    },
    {
      id: "4",
      name: "član 8. stav 2.",
      value: "IugovorHRVclan82",
      vrsta: "izvrsenje",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <strong>izvršenja</st> pravosnažno izrečene kazne zatvora za druga <strong>teška krivična djela</strong> ako ostatak kazne koji se treba izvršiti iznosi <strong>najmanje pet godina</strong>.",
    },
  ],
  osnoviugovorRS: [
    //Srbija (Republika Srbija)
    {
      id: "1",
      name: "član 7. stav 1.",
      value: "PugovorSRBclan71",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za krivična djela <b>organiziranog kriminala, korupcije i pranja novca </b> čija kazna <b>uključuje</b> oduzimanje slobode u trajanju od <b>četiri godine ili teža kazna</b>.",
    },
    {
      id: "2",
      name: "član 7. stav 2.",
      value: "IugovorSRBclan72",
      vrsta: "izvrsenje",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>izvršenja</b> pravosnažno izrečene kazne zatvora za krivična djela <b>organiziranog kriminala, korupcije i pranja</b> novca ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
    },
    {
      id: "3",
      name: "član 8. stav 1.",
      value: "PugovorSRBclan81",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za <b>druga teška krivična djela</b> za koja se može izreći kazna zatvora koja <b>uključuje</b> oduzimanje slobode u trajanju <b>od najmanje 5 godina ili teža kazna</b>.",
    },
    {
      id: "4",
      name: "član 8. stav 2.",
      value: "IugovorSRBclan82",
      vrsta: "izvrsenje",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>izvršenja</b> pravosnažno izrečene kazne zatvora za druga <b>teška krivična djela</b> ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
    },
  ],
  osnoviugovorME: [
    //Crna Gora (Montenegro)
    {
      id: "1",
      name: "član 7. stav 1.",
      value: "PugovorCRNclan71",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za krivična djela <b>organiziranog kriminala, korupcije i pranja novca</b> čija kazna <b>podrazumijeva</b> oduzimanje slobode u trajanju od <b>četiri godine ili teža kazna</b>.",
    },
    {
      id: "2",
      name: "član 7. stav 2.",
      value: "PugovorCRNclan72",
      vrsta: "proces",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za <b>ostala teška krivična djela, odnosno teške oblike krivičnih djela</b> za koja je <b>propisana</b> kazna zatvora u trajanju od <b>nakmanje pet godina ili teža kazna</b>.",
    },
    {
      id: "3",
      name: "član 7. stav 3.",
      value: "IugovorCRNclan73",
      vrsta: "izvrsenje",
      cekirano: false,
      sadrzaj:
        "Izručenje vlastitih državljana radi <b>izvršenja</b> pravosnažno izrečene kazne zatvora za druga <b>organiziranog kriminala, korupcije i pranja novca i ostala teška krivična djela</b> ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
    },
  ],


  // BRANILAC: {
  //   ime: "__________",
  //   imegen: "__________",
  //   adresa: "__________",
  //   kontakt: "__________",
  //   grad: "__________",
  //   gradgen: "__________",
  // },
  BRANILAC: [],
  dvabranioca: false,

  SUDIJA: "",
  ZAPISNICAR: "",
  TUZILAC: "",
  TUMAC: { ime: "", imegen: "", imedat: "", spol: "m" },
  dodajtumaca: false,
  imetumaca: "",
  jezikprevoda: "",
  izaberiZavodCanvas: false,
  zavod: "ZAVODZIKS", //string/prop koji se šalje u <Skracenica kod="{zavod}"/>

  pL: {
    textAlign: "left",
    fontFamily: "Arial",
  },
  pR: {
    textAlign: "right",
    fontFamily: "Arial",
  },
  pJ: {
    textAlign: "justify",
    fontFamily: "Arial",
  },
  pC: {
    textAlign: "center",
    fontFamily: "Arial",
  },
  //Replacementi, kasnije uraditi posebnu komponentu
  POTRAŽIVANI: "POTRAŽIVANI",
  Potraživani: "Potraživani",
  potraživani: "potraživani",
  POTRAŽIVANOM: "POTRAŽIVANOM",
  Potraživanom: "Potraživanom",
  potraživanom: "potraživanom",
  potraživanog: "potraživanog",
  potraživanogg: "potraživanog",
  državljanin: "državljanin",
  počinio: "počinio",
  poučen: "poučen",
  te_mu_je: "te mu je",
  njegov: "njegov",
  njegovi: "njegovi",
  njegovo: "njegovo",
  njegovog: "njegovog",
  pozvan: "pozvan",
  priveden: "priveden",
  saslušan: "saslušan", //
  izjasnio: "izjasnio",
  isti: "isti",
  izručen: "izručen",
  mogao: "mogao",
  mu_se: "mu se",
  posjedovao: "posjedovao",
  samog: "samog",
  saopštio: "saopštio",
  sam: "sam",
  naveo: "naveo",
  potvrdio: "potvrdio",
  mu_je: "mu je",
  njegovom: "njegovom",
  njega: "njega",
  dao: "dao",
  odabrao: "odabrao",
  koji: "koji",
  lišen: "lišen",
  istom: "istom", //
  njegovim: "njegovim",
  njegovih: "njegovih",
  upućen: "upućen",
  bio: "bio",
  Saglasan: "Saglasan",
  saglasan: "saglasan",
  njegove: "njegove",
  lišenog: "lišenog",
  upisan: "upisan",
  da_ga: "da ga",
  koja_ga: "koja ga",
  izdržao: "izdržao",
  osuđen: "osuđen",
  saglasio: "saglasio",
  on: "on",
  upoznat: "upoznat",
  kojeg: "kojeg",
  pušten: "pušten",
  nedostupan: "nedostupan",
  Prisutan: "Prisutan",
  prisutan: "prisutan",
  bi_ga: "bi ga",
  svjestan: "svjestan",


}; //initialState

const vrsteOdluka = [
  {
    naziv: "Odbijanje",
    value: "ODB",
  },
  {
    naziv: "Mjere zabrane",
    value: "MZ",
  },
  {
    naziv: "Privremeni",
    submenu: [
      {
        naziv: "Privremeni",
        value: "PR",
      },
      {
        naziv: "Ekstradicioni",
        value: "PREKS",
      },
    ],
  },
  {
    naziv: "Produženje",
    value: "PRO",
  },
  {
    naziv: "Ekstradicioni",
    value: "EKS",
  },
  {
    naziv: "Branilac",
    value: "BR",
  },
];

const vrsteDopisa = [
  {
    odluka: ["ODB", "MZ"],
    dopisi: [
      { dopis: "DNA", naziv: "DNA" },
      { dopis: "ZAPISNIK", naziv: "Zapisnik" },
      { dopis: "SUDSKA", naziv: "Sudska" },
      { dopis: "TBIH", naziv: "TBiH" },
      { dopis: "NCB", naziv: "NCB" },
      { dopis: "MPBIH", naziv: "MP BiH" },
    ],
  },
  {
    odluka: ["PR", "PREKS"],
    dopisi: [
      { dopis: "DNA", naziv: "DNA" },
      { dopis: "ZAPISNIK", naziv: "Zapisnik" },
      { dopis: "SUDSKA", naziv: "Sudska" },
      { dopis: "ZIKS", naziv: "ZIKS" },
      { dopis: "TBIH", naziv: "TBiH" },
      { dopis: "NCB", naziv: "NCB" },
      { dopis: "MPBIH", naziv: "MP BiH" },
      { dopis: "SAGLASNOST", naziv: "Saglasnost" },
      { dopis: "AMBASADA", naziv: "Ambasada" },
    ],
  },
  {
    odluka: ["PRO"],
    dopisi: [
      { dopis: "DNA", naziv: " DNA " },
      { dopis: "TBIH", naziv: "TBiH" },
      { dopis: "NCB", naziv: "NCB" },
      { dopis: "MPBIH", naziv: "MP BiH" },
      { dopis: "AMBASADA", naziv: "Ambasada" },
    ],
  },
  {
    odluka: ["EKS"],
    dopisi: [
      { dopis: "DNA", naziv: " DNA " },
      { dopis: "ZAPISNIK", naziv: " Zapisnik " },
      { dopis: "SUDSKA", naziv: "Sudska" },
      { dopis: "TBIH", naziv: "TBiH" },
      { dopis: "NCB", naziv: "NCB" },
      { dopis: "MPBIH", naziv: "MP BiH" },
      { dopis: "AMBASADA", naziv: "Ambasada" },
    ],
  },
  {
    odluka: ["BR"],
    dopisi: [

    ],
  },
];

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Trenutni predmet
  const trenutnipredmetID = localStorage.getItem("trenutnipredmet");
  let trenutnipredmet = trenutnipredmetID ? trenutnipredmetID : "";

  //Napravi novipredmet i trenutnipredmet na prvom otvaranju app-a
  useEffect(() => {
    const storedState = localStorage.getItem("novipredmet");
    if (!storedState) { // Prvo iniciranje aplikacije
      let vrijeme = new Date();
      state.vrijemeunosa = vrijeme;
      localStorage.setItem("novipredmet", JSON.stringify(state));
      localStorage.setItem("trenutnipredmet", state.ID);
    }
  }, []);


  // Učitaj state iz localStorage-a na početku

  useEffect(() => {

    const postojeciPRedmeti = Object.keys(localStorage);

    for (let i = 0; i < postojeciPRedmeti.length; i++) {
      const key = postojeciPRedmeti[i];
      if (key !== "trenutnipredmet"  && key !== "logovan" ) {
        const storedItem = localStorage.getItem(key);
        const parsedItem = JSON.parse(storedItem);

        parsedItem.datumlisenjaTMP = parsedItem.datumlisenjaTMP ? new Date(parsedItem.datumlisenjaTMP) : "";  //Pretvori u ISO datumlisenjaTMP da bi se mogao učitati u Datepicker-u
        if (parsedItem.ID === trenutnipredmet) {
          dispatch({ type: "LOAD_STATE", payload: parsedItem });
          break;
        }
      }
    }
  }, []);
 
  // Updejtuj localstorage item prilikom promjene state-a
  useEffect(() => {
    const postojeciPredmeti = Object.keys(localStorage);

    const postojeciPredmet = postojeciPredmeti
      .filter(key => key !== "trenutnipredmet" && key !== "novipredmet")
      .map(key => {
        const storedItem = localStorage.getItem(key);
        const parsedItem = JSON.parse(storedItem);
        return { key, parsedItem };
      })
      .find(item => item.parsedItem && item.parsedItem.ID === trenutnipredmet);

    if (postojeciPredmet) {
      let key = postojeciPredmet.key;
      let parsedItem = postojeciPredmet.parsedItem;

      state.ID = parsedItem.ID //Osiguraj da ID nije prekucan jer se na svakom reloadu kreira novi ID za state
      const updejtovanItem = { ...parsedItem, ...state }; // Updejtuj pronađeni localstorage sa novim state-om
      localStorage.removeItem(key); // Obriši prvo localstorage item sa starim ključem 
      let novikey = state.brojPredmeta ? state.brojPredmeta : "BEZ BROJA - " + state.ID;
      localStorage.setItem(novikey, JSON.stringify(updejtovanItem)); // dodaj sa updejtovim ključem/state-om
    }
    else {
      const novipredmet = localStorage.getItem("novipredmet");
      const novipredmetParsed = JSON.parse(novipredmet);
      state.ID = novipredmetParsed.ID //Osiguraj da ID nije prekucan jer se na svakom reloadu kreira novi ID za state
      const updejtovanItem = { ...novipredmetParsed, ...state }; // Updejtuj pronađeni localstorage sa novim state-om
      localStorage.setItem("novipredmet", JSON.stringify(updejtovanItem)); // dodaj sa updejtovim ključem/state-om
    }

  }, [state]);


  const inputChange = (id, val, dodatniparam, dodatniparam2, dodatniparam3) => {
    dispatch({ type: "INPUT_CHANGE", payload: { id, val, dodatniparam, dodatniparam2, dodatniparam3 } });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const dodajdokument = (vrsta) => {
    dispatch({ type: "DODAJ_DOKUMENT", payload: vrsta });
  };

  const brisidokument = (vrsta, i) => {
    dispatch({ type: "BRISI_DOKUMENT", payload: { vrsta, i } });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const izmjenadokumenta = (vrsta, i, e) => {
    dispatch({ type: "IZMJENA_DOKUMENTA", payload: { vrsta, i, e } });
  };

  const LPizmjena = (podatak, e) => {
    dispatch({ type: "IZMJENA_LP", payload: { podatak, e } });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const MZuzmipasose = (e) => {
    dispatch({ type: "MZ_PREUZMI_PASOSE", payload: e });
  };

  const sakrijAlert = (id) => {
    dispatch({ type: "SAKRIJ_ALERT", payload: id });
  };

  const izborOdluke = (odabranaodluka) => {
    dispatch({ type: "IZBOR_ODLUKE", payload: odabranaodluka });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };
  const izborDopisa = (odabranidopis) => {
    dispatch({ type: "IZBOR_DOPISA", payload: odabranidopis });
  };

  const konrolisiRazlogizrucenja = (razlog, cekirano) => {
    dispatch({ type: "IZBOR_RAZLOGAIZRUCENJA", payload: { razlog, cekirano } });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const selektovano = (id, val, index) => {
    dispatch({ type: "AUTOCOMPLETE_SELECT", payload: { id, val, index } });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const izborOkolnosti = (kategorija, okolnost, oznacena) => {
    dispatch({
      type: "IZBOR_OKOLNOSTI",
      payload: { kategorija, okolnost, oznacena },
    });
    dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const izborOsnovUgovor = (kategorija, okolnost, oznacena) => {
    dispatch({
      type: "IZBOR_OSNOVUGOVOR",
      payload: { kategorija, okolnost, oznacena },
    });
    // dispatch({ type: 'VRIJEME_IZMJENE' });
  };

  const kontrolisiCanvas = (idcanvasa, dodatniparam) => {
    dispatch({ type: "KONTROLISI_CANVAS", payload: { idcanvasa, dodatniparam } });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
        vrsteOdluka,
        vrsteDopisa,
        izborOdluke,
        izborDopisa,
        inputChange,
        izborOkolnosti,
        izborOsnovUgovor,
        kontrolisiCanvas,
        konrolisiRazlogizrucenja,
        dodajdokument,
        brisidokument,
        izmjenadokumenta,
        LPizmjena,
        MZuzmipasose,
        selektovano,
        sakrijAlert,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
