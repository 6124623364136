import React from "react";
import { useGlobalContext } from "../context";

import Navigacija from "./Navigacija";
import Uvodnidio from "./Uvodnidio";
import Dispozitiv from "./Dispozitiv";
import Obrazlozenjeuvodnidio from "./Obrazlozenjeuvodnidio";
import { Obrazlozenjeopasnost } from "./dodatneopcije/Okolnosti";
import Obrazlozenjekraj from "./Obrazlozenjekraj";
import Sastavsudaipouka from "./Sastavsudaipouka";
import Dopis from "./Dopis";

// <Obrazlozenjeopasnost />
const Odluka = () => {
  const { vrstaOdluke, vrsteDopisa, vrstaDopisa, vrstabranioca, pJ, pL, pC } =
    useGlobalContext();

  const postojiDopis = () => {
    let novidopisiTMP = vrsteDopisa.find((object) =>
      object.odluka.includes(vrstaOdluke)
    );
    if (novidopisiTMP.dopisi.some((dopis) => dopis.dopis === vrstaDopisa)) {
      return "DA";
    } else {
      return "NE";
    }
  };

  return (
    <div className="pt-2">
      <Navigacija />
      <div id="odluka" className="list" style={{ fontFamily: 'Arial' }}>
        {vrstaOdluke === "" ? (
          <>
            <br />
            <p style={{ pL, width: "30%" }} className="skeleton mb-1">
              <br />
            </p>
            <p style={{ pL, width: "30%" }} className="skeleton mb-0">
              <br />
            </p>
            <br />
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <br />
            <p
              style={{ pC, width: "25%", margin: "auto" }}
              className="skeleton mb-0"
            >
              <br />
            </p>
            <br />
            <p style={{ pL, width: "30%" }} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <br />
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <br />
            <p
              style={{ pC, width: "35%", margin: "auto" }}
              className="skeleton mb-0"
            >
              <br />
            </p>
            <br />
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <br />
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>

            <br />
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
            <p style={pJ} className="skeleton mb-1">
              <br />
            </p>
          </>
        ) : vrstaOdluke !== "" && vrstaDopisa === "" ? (
          <>
            <Uvodnidio />
            <Dispozitiv />
            <Obrazlozenjeuvodnidio />
            <Obrazlozenjeopasnost />
            <Obrazlozenjekraj />
            {vrstaOdluke === "BR"
              ? <>{vrstabranioca === "posluzbenoj" ? <Sastavsudaipouka kod="sastav" sastav="spp" /> : <></>}</>
              :
              <Sastavsudaipouka kod="sastav" sastav="zapspp" />}
            <Sastavsudaipouka kod="pouka" />
          </>
        ) : vrstaOdluke !== "" && vrstaDopisa !== "" ? (
          <>
            {postojiDopis() === "DA" ? (
              <Dopis />
            ) : (
              <>
                <Uvodnidio />
                <Dispozitiv />
                <Obrazlozenjeuvodnidio />
                <Obrazlozenjeopasnost />
                <Obrazlozenjekraj />
                {vrstaOdluke === "BR"
                  ? <>{vrstabranioca === "posluzbenoj" ? <Sastavsudaipouka kod="sastav" sastav="spp" /> : <></>}</>
                  : <Sastavsudaipouka kod="sastav" sastav="zapspp" />}
                <Sastavsudaipouka kod="pouka" />
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>



    </div>
  );
};

export default Odluka;
