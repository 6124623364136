import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context';
import parse from "html-react-parser";

import { ugovori } from '../liste/ugovori';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import { InfoCircle } from "react-bootstrap-icons";
/*
const data = { name: "America", html: "text <strong>strong text</strong>" };

const MyComponent = ({ data }) => {
  return (
    <div>
      <h1>{data.name}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.html }} />
    </div>
  );
};


*/


const UgovorInformacije = () => {
  const { vrstaOdluke, DRZMOLITELJICA, PostojiUgovor } = useGlobalContext()
  const [show, setShow] = useState(false);

  function InformacijeUgovor() {
    const ugovor = ugovori.find(ugovor => ugovor.ime === DRZMOLITELJICA.ime);

    return (

      <Card
        bg="light"
        key="light"
        text="dark"
        className="mb-2 mt-2 "
      >
        <Card.Header>
        {ugovor.izmjene === "IZMJENE"
         ?
                        <>Na snazi je <strong>{ugovor.naziv}</strong> od {ugovor.datum} odnosno, <strong>{ugovor.naziv2}</strong> od {ugovor.datum2} {ugovor.preuzet === "DA" && <>{ugovor.tip === "ugovor" ? "(preuzet notifikacijom o sukcesiji)" : "(preuzeta notifikacijom o sukcesiji)"}</>} - {ugovor.tip === "ugovor" ? <> <strong>stupio na snagu {ugovor.stupanje2}</strong></> : <>- <strong>stupila na snagu {ugovor.stupanje2}</strong></>}.</>
                        :
                        ugovor.izmjene === "PROTOKOL" 
                        ?
                        <>Na snazi je <strong>{ugovor.naziv}</strong> od {ugovor.datum} uz <strong>{ugovor.naziv2}</strong> od {ugovor.datum2} {ugovor.preuzet === "DA" && <>{ugovor.tip === "ugovor" ? "(preuzet notifikacijom o sukcesiji)" : "(preuzeta notifikacijom o sukcesiji)"}</>} - {ugovor.tip === "ugovor" ? <> <strong>stupio na snagu {ugovor.stupanje2}</strong></> : <>- <strong>stupila na snagu {ugovor.stupanje2}</strong></>}.</>
                        :
                        <>Na snazi je <strong>{ugovor.naziv}</strong> od {ugovor.datum} {ugovor.preuzet === "DA" && <> {ugovor.tip === "ugovor" ? "(preuzet notifikacijom o sukcesiji)" : "(preuzeta notifikacijom o sukcesiji)"} </>} {ugovor.tip === "ugovor" ? <>- <strong>stupio na snagu {ugovor.stupanje}</strong></> : <>- <strong>stupila na snagu {ugovor.stupanje}</strong></>}</>
                                       }

          <CloseButton className="float-end" aria-label="Hide" onClick={() => setShow(false)} />
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Row>
              <Col xs={2} className="pe-0 mb-2">Izručenje <strong>vlastitih</strong> državljana:</Col>
              <Col xs={2}>
                {ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD"
                  ?
                  <>
                    <strong>dozvoljeno</strong> za pojedina<strong> krivična djela</strong>
                  </>
                  :
                  ugovor.pojedinosti.izrucenjevlastitih.status === "DA"
                    ?
                    <><strong> dozvoljeno</strong></>
                    :
                    ugovor.pojedinosti.izrucenjevlastitih.status === "NE"
                      ?
                      <><strong> nije dozvoljeno</strong></>
                      :
                      ugovor.pojedinosti.izrucenjevlastitih.status === "NEJASNO"
                        ?
                        <><strong> nejasno</strong></>
                        :
                        ugovor.pojedinosti.izrucenjevlastitih.status === "NIJEPROPISANO"
                          ?
                          <><strong> nije propisano</strong></>
                          :
                          <></>}
              </Col>
              <Col xs={8} className="mb-2" style={{ textAlign: 'justify' }}>
                {ugovor.pojedinosti.izrucenjevlastitih.komentar && <> <span>{parse(ugovor.pojedinosti.izrucenjevlastitih.komentar)}</span><br /></>}
                {ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" && <>{ugovor.pojedinosti.pojedinakd.map((kd, index) =>
                  <span key={index}><b>- </b> {parse(kd)}<br /></span>
                )}
                </>}


                {ugovor.pojedinosti.napomena && <><span>{parse(ugovor.pojedinosti.napomena)}</span></>}
              </Col>

              <Col xs={2} className="pe-0 mb-2">Uslov <strong>zaprijećene kazne{ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" && " (za strance)"}</strong>:</Col>
              <Col xs={10} className="mb-2"><span>{parse(ugovor.pojedinosti.procesminimum.opis)}</span></Col>

              <Col xs={2} className="pe-0 mb-2"> Uslov <strong>izrečene kazne{ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" && " (za strance)"}</strong>:</Col>
              <Col xs={10} className="mb-2"><span>{parse(ugovor.pojedinosti.izvrsenjeminimum.opis)}</span></Col>

              {(vrstaOdluke === "PR" || vrstaOdluke === "PRO") && <>
                <Col xs={2} className="pe-0 mb-2"><strong>Privremeni pritvor</strong>:</Col>
                <Col xs={10} className="mb-2"><span>{parse(ugovor.pojedinosti.privremeni)}</span></Col>
              </>}

              {vrstaOdluke === "EKS" && <>
                <Col xs={2} className="pe-0 mb-2"><strong>Dokumentacija</strong>:</Col>
                <Col xs={10} className="mb-2">{ugovor.pojedinosti.dokumentacija.map((dokument, index) =>
                  <span key={index}><b>-</b> {parse(dokument)}<br /></span>
                )}</Col>
              </>}
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    );

  }


  return (

    <>

      {DRZMOLITELJICA === ""
        ?
        <></>
        :
        <>

          {show === true
            ?
            <>
              {PostojiUgovor === "DA"
                ?
                <>
                  <InformacijeUgovor />
                </>
                :
                PostojiUgovor === "NE"
                  ?
                  <>
                    <Alert variant="danger mt-2" onClose={() => setShow(false)} dismissible>
                      Između <strong>Bosne i Hercegovine i {DRZMOLITELJICA.gen} nije</strong> zaključen ugovor o izručenju.
                      <CloseButton className="float-end" aria-label="Hide" onClick={() => setShow(false)} />
                    </Alert>
                  </>
                  :
                  <></>}
            </>

            :
            <>
              <Button variant='secondary' className='mb-2 mt-2' onClick={() => setShow(true)}>Informacije o ugovoru  <InfoCircle /> </Button>

            </>
          }
        </>
      }


    </>


  )
}

export default UgovorInformacije
