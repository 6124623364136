import React from 'react'
import { useGlobalContext } from '../../context'


import ToggleButton from "react-bootstrap/ToggleButton";
import Card from "react-bootstrap/Card";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Ugovorosnovi = () => {
    const { procesuiranje, izvrsenje, DrzavljaninBiH, DRZMOLITELJICA, osnoviugovor, izborOsnovUgovor, osnoviugovorHR, osnoviugovorRS, osnoviugovorME } = useGlobalContext()

    return (<>


        {DrzavljaninBiH === "DA" && DRZMOLITELJICA.ime === "Hrvatska" && (izvrsenje || procesuiranje) && <>   <Card
            bg=""
            key="1"
            text="dark"
            style={{ textAlign: "left" }}
            className={`text-dark mb-2 ${osnoviugovor.length < 2 && 'obavezno'}`}
        >
            <Card.Header
                className={`${osnoviugovor.length < 2 && 'obavezno'}`}
            >
                Osnov za izručenje - ugovor HR
            </Card.Header>
            <Card.Body className='p-2'>
                {procesuiranje && <> {osnoviugovorHR.filter(clan => clan.vrsta === "proces").map((clanproces, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanproces.value}
                        overlay={
                            <Tooltip >
                                {clanproces.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanproces.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem", fontSize:"13px"}}
                            checked={clanproces.cekirano}
                            value={clanproces.value}
                            onChange={(e) => izborOsnovUgovor("HR", e.target.value, e.currentTarget.checked)}
                        >
                            {clanproces.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}
                {
                    /*
                     { id: "1", name: "član 7. stav 1.", value: "PugovorHRVclan71", vrsta: "proces", cekirano: false, sadrzaj: "Izručenje vlastitih državljana u svrhu <strong>krivičnog gonjenja</strong> za krivična djela <strong>organiziranog kriminala, korupcije i pranja novca </strong> čija kazna <b>uključuje</b> oduzimanje slobode u trajanju od <strong>četiri godine ili teža kazna</strong>." },
                    
                     const izborOsnovUgovor = (kategorija, okolnost, oznacena) => {
                            dispatch({ type: 'IZBOR_OSNOVUGOVOR', payload: { kategorija, okolnost, oznacena } })
                        }
                    
                    */
                }

                {izvrsenje && <> {osnoviugovorHR.filter(clan => clan.vrsta === "izvrsenje").map((clanizvrsenje, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanizvrsenje.value}
                        overlay={
                            <Tooltip >
                                {clanizvrsenje.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanizvrsenje.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem", fontSize:"13px"}}
                            checked={clanizvrsenje.cekirano}
                            value={clanizvrsenje.value}
                            onChange={(e) => izborOsnovUgovor("HR", e.target.value, e.currentTarget.checked)}
                        >
                            {clanizvrsenje.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}

            </Card.Body>
        </Card>
        </>}


        {DrzavljaninBiH === "DA" && DRZMOLITELJICA.ime === "Srbija" && (izvrsenje || procesuiranje) && <>   <Card
            bg=""
            key="1"
            text="dark"
            style={{ textAlign: "left" }}
            className={`text-dark mb-2 ${osnoviugovor.length < 2 && 'obavezno'}`}
        >
            <Card.Header
                className={`${osnoviugovor.length < 2 && 'obavezno'}`}
            >
                Osnov za izručenje - Ugovor Srbija
            </Card.Header>
            <Card.Body className='p-2'>
                {procesuiranje && <> {osnoviugovorRS.filter(clan => clan.vrsta === "proces").map((clanproces, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanproces.value}
                        overlay={
                            <Tooltip >
                                {clanproces.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanproces.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem", fontSize:"13px"}}
                            checked={clanproces.cekirano}
                            value={clanproces.value}
                            onChange={(e) => izborOsnovUgovor("RS", e.target.value, e.currentTarget.checked)}
                        >
                            {clanproces.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}
                {izvrsenje && <> {osnoviugovorRS.filter(clan => clan.vrsta === "izvrsenje").map((clanizvrsenje, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanizvrsenje.value}
                        overlay={
                            <Tooltip >
                                {clanizvrsenje.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanizvrsenje.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem", fontSize:"13px"}}
                            checked={clanizvrsenje.cekirano}
                            value={clanizvrsenje.value}
                            onChange={(e) => izborOsnovUgovor("RS", e.target.value, e.currentTarget.checked)}
                        >
                            {clanizvrsenje.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}
            </Card.Body>
        </Card>
        </>}

        {DrzavljaninBiH === "DA" && DRZMOLITELJICA.ime === "Crna Gora" && (izvrsenje || procesuiranje) && <>   <Card
            bg=""
            key="1"
            text="dark"
            style={{ textAlign: "left" }}
            className={`text-dark mb-2 ${osnoviugovor.length < 2 && 'obavezno'}`}
        >
            <Card.Header
                className={`${osnoviugovor.length < 2 && 'obavezno'}`}
            >
                Osnov za izručenje - Ugovor Srbija
            </Card.Header>
            <Card.Body className='p-2'>
                {procesuiranje && <> {osnoviugovorME.filter(clan => clan.vrsta === "proces").map((clanproces, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanproces.value}
                        overlay={
                            <Tooltip >
                                {clanproces.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanproces.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem" }}
                            checked={clanproces.cekirano}
                            value={clanproces.value}
                            onChange={(e) => izborOsnovUgovor("ME", e.target.value, e.currentTarget.checked)}
                        >
                            {clanproces.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}
                {izvrsenje && <> {osnoviugovorME.filter(clan => clan.vrsta === "izvrsenje").map((clanizvrsenje, index) => (
                    <OverlayTrigger
                        placement="left"
                        key={clanizvrsenje.value}
                        overlay={
                            <Tooltip >
                                {clanizvrsenje.sadrzaj}
                            </Tooltip>
                        }
                    >
                        <ToggleButton
                            className="m-1"
                            key={index}
                            id={clanizvrsenje.id}
                            type="checkbox"
                            variant="outline-secondary"
                            size="sm"
                            style={{paddingLeft:"0.30rem", paddingRight:"0.30rem", fontSize:"13px"}}
                            checked={clanizvrsenje.cekirano}
                            value={clanizvrsenje.value}
                            onChange={(e) => izborOsnovUgovor("ME", e.target.value, e.currentTarget.checked)}
                        >
                            {clanizvrsenje.name}
                        </ToggleButton>
                    </OverlayTrigger>
                ))}</>}
            </Card.Body>
        </Card>
        </>}




    </>
    )
}

export default Ugovorosnovi