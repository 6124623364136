import React from 'react';
import { useGlobalContext } from '../../context'
import format from 'date-fns/format';


import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Alert from "react-bootstrap/Alert";

import Okolnostilicne from '../okolnosti/Okolnostilicne';
import OkolnostiMZolaksavajuce from '../okolnosti/OkolnostiMZolaksavajuce';

const Obrazlozenjeopasnost = () => {

  const { vrstaOdluke, procesuiranjeInfo, izvrsenjeInfo, procesuiranje, izvrsenje, odabraneOkolnostiLicne, odabraneOkolnostiMZOlaksavajuce, pJ, DRZMOLITELJICA, DRZAVLJANSTVO, DRZAVLJANSTVO2, licnipodaci, DrzavljaninBiH, licezensko,
    upućen, dvadrzavljanstva, Potraživani, potraživanog, potraživanom, potraživani, svjestan, njega, nedostupan, počinio, njegovo, njegovog, izdržao, potraživanogg, bio, prisutan, državljanin,
    bi_ga, mogao, lišen, njegov, koja_ga, } = useGlobalContext()

  const {
    procesuiranjekd: procesuiranjekd1,
    procesuiranjekazna: procesuiranjekazna1, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja1,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga1,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog1 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[0];

  const kaznaP1 = (procesuiranjekazna1.parafrazirano === "DA" ? procesuiranjekazna1.paraf : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna1?.val || "__________"));


  const {
    procesuiranjekd: procesuiranjekd2,
    procesuiranjekazna: procesuiranjekazna2, //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: procesuiranjevrijemeiizvrsenja2,
    procesuiranjebrojnaloga: procesuiranjebrojnaloga2,
    procesuiranjenazivsudanalog: procesuiranjenazivsudanalog2 //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
  } = procesuiranjeInfo[1] || {};

  const kaznaP2 = (procesuiranjekazna2?.parafrazirano === "DA" ? procesuiranjekazna2?.paraf : "je zaprijećena kazna zatvora u trajanju od " + (procesuiranjekazna2?.val || "__________"));
  const nazivorganaP2 = (procesuiranjenazivsudanalog2?.gen !== undefined ? "izdat je od strane " + (procesuiranjenazivsudanalog2?.gen || "__________") : "izdao je " + (procesuiranjenazivsudanalog2?.nom || "__________"));
  const nazivorganaP1jednakoP2 = (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen ? "izdati su od strane " + ((procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) ? (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) : "__________") : "izdao je " + (procesuiranjenazivsudanalog1.nom || "__________"));



  const {
    izvrsenjepresuda: izvrsenjepresuda1,
    izvrsenjenazivsuda: izvrsenjenazivsuda1, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd1,
    izvrsenjekazna: izvrsenjekazna1,   // {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjeprisutanizricanju: izvrsenjeprisutanizricanju1,
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja1,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga1,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog1, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[0];
  const nazivsudaI1 = (izvrsenjenazivsuda1.gen !== undefined ? (izvrsenjenazivsuda1.gen || "__________") : "koju je izrekao " + (izvrsenjenazivsuda1.nom || "__________"));
  const kaznaI1 = (izvrsenjekazna1.preostalidio !== undefined ? "kazne zatvora u trajanju od " + (izvrsenjekazna1.preostalidio || "__________") + ", kao preostalog neizdržanog dijela kazne zatvora izrečene u ukupnom trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________,") + "," : "kazne zatvora u trajanju od " + (izvrsenjekazna1.izrecenakazna || "__________"));

  const {
    izvrsenjepresuda: izvrsenjepresuda2,
    izvrsenjenazivsuda: izvrsenjenazivsuda2, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: izvrsenjekd2,
    izvrsenjekazna: izvrsenjekazna2,   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjeprisutanizricanju: izvrsenjeprisutanizricanju2,
    izvrsenjevrijemeizvrsenja: izvrsenjevrijemeizvrsenja2,
    izvrsenjebrojnaloga: izvrsenjebrojnaloga2,
    izvrsenjenazivsudanalog: izvrsenjenazivsudanalog2, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
  } = izvrsenjeInfo[1] || {};

  const kaznaI2 = (izvrsenjekazna2?.preostalidio ? "kazne zatvora u trajanju od " + (izvrsenjekazna2?.preostalidio || "__________") + ", kao preostalog neizdržanog dijela kazne zatvora izrečene u ukupnom trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________,") + "," : "kazne zatvora u trajanju od " + (izvrsenjekazna2?.izrecenakazna || "__________"));
  const nazivsudaI2 = (izvrsenjenazivsuda2?.gen !== undefined ? (izvrsenjenazivsuda2?.gen || "__________") : "koju je izrekao " + (izvrsenjenazivsuda2?.nom || "__________"));


  const razlikauMjesecima = (inputString) => {
    const datePattern = /\d{2}\.\d{2}\.\d{4}/;
    const matches = datePattern.exec(inputString);
    if (matches) {
      const dateStr = matches[0];
      const dateParts = dateStr.split('.');
      const datum = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      const currentDate = new Date();
      const razlika = (currentDate.getFullYear() - datum.getFullYear()) * 12 + (currentDate.getMonth() - datum.getMonth());
      const formattedDatum = format(datum, "dd.MM.yyyy") + ". godine";
      return { razlika, datum: formattedDatum };
    } else {
      return null;
    }
  }

  const { razlika: P1razlika, datum: P1datum } = razlikauMjesecima(procesuiranjebrojnaloga1) ?? { razlika: false, datum: false };
  const { razlika: P2razlika, datum: P2datum } = razlikauMjesecima(procesuiranjebrojnaloga2) ?? { razlika: false, datum: false };
  const { razlika: I1razlika, datum: I1datum } = razlikauMjesecima(izvrsenjebrojnaloga1) ?? { razlika: false, datum: false };
  const { razlika: I2razlika, datum: I2datum } = razlikauMjesecima(izvrsenjebrojnaloga2) ?? { razlika: false, datum: false };

  const PnalozIizdatiOd = (procesuiranjenazivsudanalog1.nom === procesuiranjenazivsudanalog2?.nom
    ?
    (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen ? "su nalozi za hapšenje izdati od strane " + ((procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) ? (procesuiranjenazivsudanalog1.gen || procesuiranjenazivsudanalog2?.gen) : "__________") + (P1datum === P2datum ? ` dana ${P1datum}` : ` dana ${P1datum}, odnosno ${P2datum}`) : "je naloge za hapšenje izdao" + (procesuiranjenazivsudanalog1.nom || "__________") + (P1datum === P2datum ? ` dana ${P1datum}` : ` dana ${P1datum}, odnosno ${P2datum}`))
    :
    (P1datum === P2datum
      ?
      (procesuiranjenazivsudanalog1.gen ? `nalog ${procesuiranjenazivsudanalog1.gen}` : `nalog koji je izdao ${procesuiranjenazivsudanalog1.nom || "__________"}`) + " kao i nalog" + (procesuiranjenazivsudanalog2?.gen ? `${procesuiranjenazivsudanalog2?.gen}` : ` koji je izdao ${procesuiranjenazivsudanalog2?.nom || "__________"} datiraju od ${P1datum}`)
      :
      (procesuiranjenazivsudanalog1.gen ? `je nalog ${procesuiranjenazivsudanalog1.gen} izdat dana ${P1datum}` : `nalog koji je izdao ${procesuiranjenazivsudanalog1.nom || "__________"} datira od ${P1datum}`) + " pri čemu" + (procesuiranjenazivsudanalog2?.gen ? `je nalog ${procesuiranjenazivsudanalog2?.gen} izdat dana ${P2datum}` : `nalog koji je izdao ${procesuiranjenazivsudanalog2?.nom || "__________"} datira od ${P2datum}`)
    )
  );
  const P1nalogIzdatOd = (procesuiranjenazivsudanalog1.gen ? `je nalog ${procesuiranjenazivsudanalog1.gen} izdat dana ${P1datum}` : `nalog koji je izdao ${procesuiranjenazivsudanalog1.nom || "__________"} datira od ${P1datum}`)
  const P2nalogIzdatOd = (procesuiranjenazivsudanalog2?.gen ? `je nalog ${procesuiranjenazivsudanalog2.gen} izdat dana ${P2datum}` : `nalog koji je izdao ${procesuiranjenazivsudanalog2?.nom || "__________"} datira od ${P2datum}`)

  const Pvrijemedostupnosti = (props) => {
    let Idva = izvrsenjeInfo.length === 2

    if (procesuiranjeInfo.length === 1) {
      if (!P1razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                      međunarodne potjernice.`;
        } else if (props.prioritet === 2) {
          return `Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                      da učešće ${potraživanog} u krivičnom postupku kao i ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                      međunarodne potjernice. Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                      da učešće ${potraživanog} u krivičnom postupku nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje radi krivičnog gonjenja ${potraživanog} izdat dana ${P1datum}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u krivičnom postupku koji se protiv ${njega} vodi ako
                                    se ima u vidu činjenica da je nalog za hapšenje ${potraživanog} u tom pravcu izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u
                                    krivičnom postupku kao i ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnom postupku nije bilo moguće redovnim putem.`;
        }

      } else if (P1razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice pri čemu je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje radi krivičnog gonjenja ${potraživanog} izdat dana ${P1datum}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u
                                    krivičnom postupku koji se protiv ${njega} vodi ako se ima u vidu da je nalog za hapšenje ${potraživanog} izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u
                                    krivičnom postupku kao i ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {

        }
        return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice pri čemu je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnom postupku nije bilo moguće redovnim putem.`;
      } else {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice pri čemu je ${potraživani} duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje radi krivičnog gonjenja ${potraživanog} izdat dana ${P1datum}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i postupku
                                    izvršenja izrečene kazne zatvora ako se ima u vidu da je nalog za hapšenje ${potraživanog} izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u
                                    krivičnom postupku kao i u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${P1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnom postupku nije bilo moguće redovnim putem.`;
        }
      }
    } else if (procesuiranjeInfo.length === 2) {

      if (!P1razlika && !P2razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice.`;
        } else if (props.prioritet === 2) {
          return `Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                            da učešće ${potraživanog} u krivičnim postupcima kao i ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice. Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                            da učešće ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }

      } else if (!P1razlika && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice pri čemu je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim
                                            krivičnim postupcima ako se ima u vidu da ${P2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne
                                            pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima kao i ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (!P1razlika && P2razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice pri čemu je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima 
                                            ako se ima u vidu da ${P2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne
                                            pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i u krivičnim postupcima kao nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }

      } else if (!P1razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na 
                                            zaključak da osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i u krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd}, a sama činjenica
                                            da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 3 && !P2razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da 
                                            osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 3 && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${PnalozIizdatiOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${PnalozIizdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje
                                            na zaključak da osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${PnalozIizdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 3 && P2razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne
                                            pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi
                                            međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 7 && !P2razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na
                                            zaključak da osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 7 && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne
                                            pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 7 && P2razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postucpima
                                            ako se ima u vidu da ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne
                                            pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne 
                                            pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P2nalogIzdatOd} pri čemu ${P1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika >= 7 && !P2razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na 
                                            zaključak da osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika >= 7 && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi
                                            međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else if (P1razlika >= 7 && P2razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima
                                            ako se ima u vidu da ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći
                                            upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${P1nalogIzdatOd} pri čemu ${P2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }
      } else {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${PnalozIizdatiOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u iniciranim krivičnim postupcima 
                                            ako se ima u vidu da ${PnalozIizdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da
                                            osiguranje učešća ${potraživanog} u ${Idva ? "postupcima izvršenja izrečenih kazni" : "postupku izvršenja izrečene kazne"} kao i krivičnim postupcima nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${PnalozIizdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u krivičnim postupcima nije bilo moguće redovnim putem.`;
        }

      }

    }

  }  //Pvrijemedostupnosti


  const InaloziIzdatiOd = (izvrsenjenazivsudanalog1.nom === izvrsenjenazivsudanalog2?.nom
    ?
    (izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen ? "su nalozi za hapšenje izdati od strane " + ((izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen) ? (izvrsenjenazivsudanalog1.gen || izvrsenjenazivsudanalog2?.gen) : "__________") + (I1datum === I2datum ? ` dana ${I1datum}` : ` dana ${I1datum}, odnosno ${I2datum}`) : "je naloge za hapšenje izdao " + (izvrsenjenazivsudanalog1.nom || "__________") + (I1datum === I2datum ? ` dana ${I1datum}` : ` dana ${I1datum}, odnosno ${I2datum}`))
    :
    (I1datum === I2datum
      ?
      (izvrsenjenazivsudanalog1.gen ? `nalog ${izvrsenjenazivsudanalog1.gen}` : `nalog koji je izdao ${izvrsenjenazivsudanalog1.nom || "__________"}`) + " kao i nalog" + (izvrsenjenazivsudanalog2?.gen ? `${izvrsenjenazivsudanalog2?.gen}` : ` koji je izdao ${izvrsenjenazivsudanalog2?.nom || "__________"} datiraju od ${I1datum}`)
      :
      (izvrsenjenazivsudanalog1.gen ? `je nalog ${izvrsenjenazivsudanalog1.gen} izdat dana ${I1datum}` : `nalog koji je izdao ${izvrsenjenazivsudanalog1.nom || "__________"} datira od ${I1datum}`) + " pri čemu " + (izvrsenjenazivsudanalog2?.gen ? `je nalog ${izvrsenjenazivsudanalog2?.gen} izdat dana ${I2datum}` : `nalog koji je izdao ${izvrsenjenazivsudanalog2?.nom || "__________"} datira od ${I2datum}`)
    )
  );
  const I1nalogIzdatOd = (izvrsenjenazivsudanalog1.gen ? `je nalog ${izvrsenjenazivsudanalog1.gen} izdat dana ${I1datum}` : `nalog koji je izdao ${izvrsenjenazivsudanalog1.nom || "__________"} datira od ${I1datum}`)
  const I2nalogIzdatOd = (izvrsenjenazivsudanalog2?.gen ? `je nalog ${izvrsenjenazivsudanalog2.gen} izdat dana ${I2datum}` : `nalog koji je izdao ${izvrsenjenazivsudanalog2?.nom || "__________"} datira od ${I2datum}`)

  const Ivrijemedostupnosti = (props) => {

    let Pdva = procesuiranjeInfo.length === 2
    // console.log(Pdva)
    //  console.log(props)
    if (izvrsenjeInfo.length === 1) {
      if (!I1razlika) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                  međunarodne potjernice.`;
        } else if (props.prioritet === 2) {
          return `Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                  da učešće ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                  međunarodne potjernice. Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                                  da učešće ${potraživanog} u postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;
        }


      } else if (I1razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer je nalog za hapšenje ${potraživanog} radi izvršenja izrečene kazne izdat dana ${I1datum}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u postupku izvršenja
                                          izrečene kazne ako se ima u vidu da je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                                          da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog}
                                          u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                                          da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;
        }

      } else if (I1razlika < 7) {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje ${potraživanog} radi izvršenja izrečene kazne izdat dana ${I1datum}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupku izvršenja izrečene kazne zatvora
                                    ako se ima u vidu da je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog}
                                    u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                                    da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;
        }

      } else {
        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                  međunarodne potjernice uz napomenu da je ${potraživani} duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                  Ovo iz razloga jer je nalog za hapšenje ${potraživanog} radi izvršenja izrečene kazne izdat dana ${I1datum}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupku izvršenja izrečene kazne zatvora
                  ako se ima u vidu da je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                  da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog}
                  u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                  međunarodne potjernice uz napomenu da je ${potraživani} duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                  Ovo iz razloga jer je nalog za hapšenje ${potraživanog} izdat dana ${I1datum}, a sama činjenica
                  da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupku izvršenja izrečene kazne nije bilo moguće redovnim putem.`;

        }


      }
    } else if (izvrsenjeInfo.length === 2) {

      if (!I1razlika && !I2razlika) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                      međunarodne potjernice.`;
        } else if (props.prioritet === 2) {
          return `Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                      da učešće ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                      međunarodne potjernice. Polazeći, dakle, od činjenice da su inicirani mehanizmi međunarodne pravne pomoći slijedi zaključak
                      da učešće ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;
        }


      } else if (!I1razlika && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer ${I2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                    ako se ima u vidu da ${I2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje
                                    učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;
        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                    međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                    Ovo iz razloga jer ${I2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;
        }

      } else if (!I1razlika && I2razlika < 7) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                          ako se ima u vidu da ${I2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak
                                          da osiguranje učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (!I1razlika) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                                        međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                                        Ovo iz razloga jer ${I2nalogIzdatOd}.`;
        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                                        ako se ima u vidu da ${I2nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak
                                                        da osiguranje učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                                          međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                                          Ovo iz razloga jer ${I2nalogIzdatOd}, a sama činjenica
                                                          da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;
        }


      } else if (I1razlika < 3 && !I2razlika) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje
                                            učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 3 && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${InaloziIzdatiOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već određeni period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${InaloziIzdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da
                                            osiguranje učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već određeni period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${InaloziIzdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 3 && I2razlika < 7) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na
                                            zaključak da osiguranje učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                              međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                              Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje
                                            na zaključak da osiguranje učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 7 && !I2razlika) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvšenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog}
                                            u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 7 && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne
                                            pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                              međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                              Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 7 && I2razlika < 7) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već relativno duži period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                          ako se ima u vidu da ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne
                                          pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već relativno duži period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika < 7) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne
                                            pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I2nalogIzdatOd} pri čemu ${I1nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika >= 7 && !I2razlika) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I1nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                          ako se ima u vidu da ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje
                                          učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I1nalogIzdatOd}, a sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika >= 7 && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći 
                                            upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else if (I1razlika >= 7 && I2razlika < 7) {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne
                                            pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                          međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                          Ovo iz razloga jer ${I1nalogIzdatOd} pri čemu ${I2nalogIzdatOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      } else {

        if (props.prioritet === 1) {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                            međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                            Ovo iz razloga jer ${InaloziIzdatiOd}.`;

        } else if (props.prioritet === 2) {
          return `${Potraživani} je već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice i u postupcima izvršenja izrečenih kazni zatvora
                                            ako se ima u vidu da da ${InaloziIzdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje
                                            učešća ${potraživanog} u ${Pdva ? "krivičnim postupcima" : "krivičnom postupku"} kao i postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        } else {
          return `Otežavajuću okolnost na strani ${potraživanog} u tom pravcu Sud vidi u činjenici da je ${njegovo} prisustvo, posredno, osigurano tek realizacijom
                                              međunarodne potjernice uz napomenu da je ${potraživani} već duži vremenski period ${nedostupan} pravosudnim organima države moliteljice.
                                              Ovo iz razloga jer ${InaloziIzdatiOd}. Osim toga, sama činjenica da su inicirani mehanizmi međunarodne pravne pomoći upućuje na zaključak da osiguranje prisustva ${potraživanog} u postupcima izvršenja izrečenih kazni nije bilo moguće redovnim putem.`;

        }

      }

    } //izvrsenjeInfo.length === 2

  }  //Ivrijemedostupnosti


  const PIvrijemedostupnosti = () => {


    //Odredi vrijednosti/prioritet
    const P1prioritet = P1razlika === false ? 0 : P1razlika < 3 ? 1 : P1razlika < 7 ? 2 : 3;
    const P2prioritet = P2razlika === false ? 0 : P2razlika < 3 ? 1 : P2razlika < 7 ? 2 : 3;
    const I1prioritet = I1razlika === false ? 0 : I1razlika < 3 ? 1 : I1razlika < 7 ? 2 : 3;
    const I2prioritet = I2razlika === false ? 0 : I2razlika < 3 ? 1 : I2razlika < 7 ? 2 : 3;

    const Psum = P1prioritet + P2prioritet
    const Isum = I1prioritet + I2prioritet

    return (
      <>
        {(Psum > Isum) || (Psum === Isum) ?
          <>
            <Pvrijemedostupnosti prioritet={1} />{" "}
            <Ivrijemedostupnosti prioritet={2} />
          </>
          :
          <>
            <Ivrijemedostupnosti prioritet={1} />{" "}
            <Pvrijemedostupnosti prioritet={2} />
          </>
        }
      </>
    )
  }


  const VrijednostZaprijecene = (inputstring) => {
    let zaprijecenakazna;
    if (inputstring) {
      // Provjeri jel "doživotni" 
      if (inputstring.includes("doživotni") || inputstring.includes("doživotnog")) {
        zaprijecenakazna = 100;
      }
      // Preuzmi brojeve
      const brojevi = inputstring.match(/\d+/g);
      // Ako nema brojeva/nema kazne 
      if (!brojevi) {
        return null;
      }
      if (brojevi.length === 2) {
        zaprijecenakazna = parseInt(brojevi[1]);
        let index = inputstring.indexOf(zaprijecenakazna);
        inputstring = inputstring.slice(index)
      } else {
        zaprijecenakazna = parseInt(brojevi[0]);
      }
      // Posebni minimum "najmanje"
      if (inputstring.includes("najmanje")) {
        zaprijecenakazna *= 2;
      }
      // Jesul u pitanju mjeseci
      if (inputstring.includes("mjesec")) {
        zaprijecenakazna /= 12;
      }
      return zaprijecenakazna;
    } else {     //ako nema inputa
      return null;
    }
  }



  const VrijednostIzrecene = (inputstring) => {

    let kazna;
    if (inputstring) {
      if (inputstring.includes("/")) {
        inputstring = inputstring.split("/")[1].trim();
      }
      kazna = inputstring.replace(/\(.*?\)/g, '').trim();

      const regex = /(\d+)\s*(godin[ea]?|mjese[ca]?|dana?)/g;
      let match;
      let ukupnogodina = 0;

      while ((match = regex.exec(kazna)) !== null) {
        const number = parseInt(match[1], 10);
        const unit = match[2];

        if (unit.startsWith('godin')) {
          ukupnogodina += number;
        } else if (unit.startsWith('mjese')) {
          ukupnogodina += number / 12;
        } else if (unit.startsWith('dan')) {
          ukupnogodina += number / 365;
        }
      }

      return ukupnogodina;
    } else {
      return null;
    }
  }



  // Vrijednost zaprijećene:      5,  10, 20 100   ..., ozbiljna, naročito teška, doživotni
  // Vrijednost izrečene:         1,5- 3 - 5, 8     ..., ozbiljna, visoka,        naročito visoka

  const P1tezina = VrijednostZaprijecene(procesuiranjekazna1.val)
  const P2tezina = VrijednostZaprijecene(procesuiranjekazna2?.val)

  const I1tezina = VrijednostIzrecene(izvrsenjekazna1.val)
  const I2tezina = VrijednostIzrecene(izvrsenjekazna2?.val)



  const Ptezinazaprijecene = (props) => {


    return (
      <>

        {procesuiranjeInfo.length === 1 &&
          <>
            {!P1tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za prvo krvično djelo u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      <br /><br /><Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za prvo krvično djelo u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              <>
                {props.prioritet === 1 ?
                  <>
                    Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                    zbog sumnje da je {počinio} {P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                    {" "}za koje {P1tezina <= 5 ? `${kaznaP1}.` : P1tezina <= 10 ? `${kaznaP1} što predstavlja ozbiljnu krivičnopravnu sankciju.` : `${kaznaP1} što predstavlja naročito tešku krivičnopravnu sankciju.`}
                  </>
                  : props.prioritet === 2 ?
                    <>
                      Protiv {potraživanog} se, istovremeno, vodi i krivični postupak zbog sumnje da je {počinio}
                      {" "}{P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                      {" "}za koje {P1tezina <= 5 ? `${kaznaP1}.` : P1tezina <= 10 ? `${kaznaP1} što predstavlja ozbiljnu krivičnopravnu sankciju.` : `${kaznaP1} što predstavlja naročito tešku krivičnopravnu sankciju.`}
                    </>
                    :
                    <>
                      Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                      zbog sumnje da je {počinio} {P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                      {" "}za koje {P1tezina <= 5 ? `${kaznaP1}.` : P1tezina <= 10 ? `${kaznaP1} što predstavlja ozbiljnu krivičnopravnu sankciju.` : `${kaznaP1} što predstavlja naročito tešku krivičnopravnu sankciju.`}
                      {" "}Ove okolnosti, prema ocjeni Suda, {P1tezina <= 5 ? `mogu djelovati` : P1tezina <= 10 ? `djeluju` : `sasvim sigurno djeluju`} motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                    </>}
              </>

            }

          </>}

        {procesuiranjeInfo.length === 2 &&
          <>
            {!P1tezina && !P2tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      ... <br /><Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>

                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>
                    </>}
              </>

              :
              ((P1tezina && !P2tezina) || (!P1tezina && P2tezina))
                ?
                <>
                  {props.prioritet === 1 ?
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                    </>
                    : props.prioritet === 2 ?
                      <>
                        ... <br /><Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                      </>
                      :
                      <>
                        <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                      </>}
                </>

                :
                ((P1tezina === P2tezina) || (P1tezina > P2tezina))
                  ?
                  <>
                    {props.prioritet === 1 ?
                      <>
                        Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                        {" "}zbog sumnje da je {počinio} {P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                        {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd2 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP1} kao i krivično djelo ${procesuiranjekd2 || "__________"} za koje ${kaznaP2.replace(", kao maksimalna,", "")}`}.
                        {" "}
                        {(P1tezina <= 10 && P2tezina <= 5) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom ozbiljnom krivičnopravnom sankcijom (kaznom zatvora)." :
                          (P1tezina <= 10 && P2tezina <= 10) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim ozbiljnim krivičnopravnim sankcijama (kaznama zatvora)." :
                            (P1tezina <= 20 && P2tezina <= 5) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom visokom krivičnopravnom sankcijom (kaznom zatvora)." :
                              (P1tezina <= 20 && P2tezina <= 10) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom visokom krivičnopravnom sankcijom (kaznom zatvora)." :
                                (P1tezina <= 20 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim visokim krivičnopravnim sankcijama (kaznama zatvora)." :
                                  (P1tezina <= 100 && P2tezina <= 5) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom naročito teškom krivičnopravnom sankcijom (kaznom zatvora)." :
                                    (P1tezina <= 100 && P2tezina <= 10) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom naročito teškom krivičnopravnom sankcijom (kaznom zatvora)." :
                                      (P1tezina <= 100 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama."
                                        : "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama."}

                      </>
                      : props.prioritet === 2 ?
                        <>
                          Protiv {potraživanog} se, istovremeno, vodi i krivični postupak zbog sumnje da je {počinio} {P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                          {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd2 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP1} kao i krivično djelo ${procesuiranjekd2 || "__________"} za koje ${kaznaP2.replace(", kao maksimalna,", "")}`}.

                          {" "}
                          {(P1tezina <= 5 && P2tezina <= 5) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                            (P1tezina <= 10 && P2tezina <= 5) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                              (P1tezina <= 10 && P2tezina <= 10) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) pa ove okolnosti, prema ocjeni Suda, djeluju" :
                                (P1tezina <= 20 && P2tezina <= 5) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                  (P1tezina <= 20 && P2tezina <= 10) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                    (P1tezina <= 20 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim visokim krivičnopravnim sankcijama (kaznama zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                      (P1tezina <= 100 && P2tezina <= 5) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                        (P1tezina <= 100 && P2tezina <= 10) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                          (P1tezina <= 100 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"
                                            : "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>
                        :
                        <>
                          Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                          {" "}zbog sumnje da je {počinio} {P1tezina <= 15 ? `krivično djelo` : P1tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd1 || "__________"}
                          {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd2 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP1} kao i krivično djelo ${procesuiranjekd2 || "__________"} za koje ${kaznaP2.replace(", kao maksimalna,", "")}`}.

                          {" "}
                          {(P1tezina <= 5 && P2tezina <= 5) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                            (P1tezina <= 10 && P2tezina <= 5) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                              (P1tezina <= 10 && P2tezina <= 10) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) pa ove okolnosti, prema ocjeni Suda, djeluju" :
                                (P1tezina <= 20 && P2tezina <= 5) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                  (P1tezina <= 20 && P2tezina <= 10) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                    (P1tezina <= 20 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim visokim krivičnopravnim sankcijama (kaznama zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                      (P1tezina <= 100 && P2tezina <= 5) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                        (P1tezina <= 100 && P2tezina <= 10) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                          (P1tezina <= 100 && P2tezina <= 20) ? "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"
                                            : "Vidljivo je da se radi o krivičnim djelima sa zaprijećenim naročito teškim krivičnopravnim sankcijama pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>}
                  </>
                  :
                  <>
                    {props.prioritet === 1 ?
                      <>
                        Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                        {" "}zbog sumnje da je {počinio} {P2tezina <= 15 ? `krivično djelo` : P2tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd2 || "__________"}
                        {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd1 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP2} kao i krivično djelo ${procesuiranjekd1 || "__________"} za koje ${kaznaP1.replace(", kao maksimalna,", "")}`}.
                        {" "}
                        {(P1tezina <= 5 && P2tezina <= 10) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom ozbiljnom krivičnopravnom sankcijom (kaznom zatvora)." :
                          (P1tezina <= 5 && P2tezina <= 20) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom visokom krivičnopravnom sankcijom (kaznom zatvora)." :
                            (P1tezina <= 5 && P2tezina <= 100) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom naročito teškom krivičnopravnom sankcijom (kaznom zatvora)." :
                              (P1tezina <= 10 && P2tezina <= 20) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom visokom krivičnopravnom sankcijom (kaznom zatvora)." :
                                (P1tezina <= 10 && P2tezina <= 100) ? "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom naročito teškom krivičnopravnom sankcijom (kaznom zatvora)." :
                                  "Vidljivo je da se, između ostalog, radi i o krivičnom djelu sa zaprijećenom naročito teškom krivičnopravnom sankcijom (kaznom zatvora)."}
                      </>
                      : props.prioritet === 2 ?
                        <>
                          Protiv {potraživanog} se, istovremeno, vodi i krivični postupak zbog sumnje da je {počinio} {P2tezina <= 15 ? `krivično djelo` : P2tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd2 || "__________"}
                          {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd1 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP2} kao i krivično djelo ${procesuiranjekd1 || "__________"} za koje ${kaznaP1.replace(", kao maksimalna,", "")}`}.
                          {" "}
                          {(P1tezina <= 5 && P2tezina <= 10) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                            (P1tezina <= 5 && P2tezina <= 20) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                              (P1tezina <= 5 && P2tezina <= 100) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                (P1tezina <= 10 && P2tezina <= 20) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                  (P1tezina <= 10 && P2tezina <= 100) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                    "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.

                        </>
                        :
                        <>
                          Za Sud je, u kontekstu navedenog, značajna činjenica da se pred nadležnim organima države moliteljice protiv {potraživanog} vodi krivični postupak
                          zbog sumnje da je {počinio} {P2tezina <= 15 ? `krivično djelo` : P2tezina <= 20 ? `teško krivično djelo` : `naročito teško krivično djelo`} {procesuiranjekd2 || "__________"}
                          {" "}{procesuiranjekazna1.val === procesuiranjekazna2.val ? `kao i krivično djelo ${procesuiranjekd1 || "__________"} za koja ${kaznaP1}` : `za koje ${kaznaP2} kao i krivično djelo ${procesuiranjekd1 || "__________"} za koje ${kaznaP1.replace(", kao maksimalna,", "")}`}.
                          {" "}
                          {(P1tezina <= 5 && P2tezina <= 10) ? "Ove okolnosti, prema ocjeni Suda, mogu djelovati" :
                            (P1tezina <= 5 && P2tezina <= 20) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                              (P1tezina <= 5 && P2tezina <= 100) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                (P1tezina <= 10 && P2tezina <= 20) ? "Za neka od krivičnih djela je zaprijećena visoka krivičnopravna sankcija (kazna zatvora) pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                  (P1tezina <= 10 && P2tezina <= 100) ? "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju" :
                                    "Za neka od krivičnih djela je zaprijećena naročito teška krivičnopravna sankcija pa ove okolnosti, prema ocjeni Suda, sasvim sigurno djeluju"}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>}
                  </>


            }
          </>} {/* procesuiranjeinfo 2 */}

      </>
    )

  } //Ptezinazaprijecene

  const Itezinaizrecene = (props) => {

    // Vrijednost zaprijećene:      5,  10, 20 100   ..., ozbiljna, naročito teška, doživotni
    // Vrijednost izrečene:         1,5- 3 - 5, 8     ..., ozbiljna, visoka,        naročito visoka

    return (
      <>
        {izvrsenjeInfo.length === 1 &&
          <>
            {!I1tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za prvu presudu u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      <br /><br />  <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za prvu presudu u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečenu kaznu u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              <>
                {props.prioritet === 1 ?
                  <>
                    Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI1}
                    {izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} {nazivsudaI1} zbog počinjenog krivičnog djela {izvrsenjekd1 || "__________"}.
                    {" "}{I1tezina <= 1.5 ? `Ova okolnost, prema ocjeni Suda, sama po sebi može djelovati` : I1tezina <= 3 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o ozbiljnoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, djeluje` : I1tezina <= 5 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o visokoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje` : `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o naročito teškoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje`}
                    {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                  </>
                  : props.prioritet === 2 ?
                    <>
                      U odnosu na {potraživanogg} je, istovremeno, iniciran i postupak izvršenja {kaznaI1}
                      {izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} {nazivsudaI1} zbog počinjenog krivičnog djela {izvrsenjekd1 || "__________"}.
                      {" "}{I1tezina <= 1.5 ? `Ova okolnost, prema ocjeni Suda, sama po sebi može djelovati` : I1tezina <= 3 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o ozbiljnoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, djeluje` : I1tezina <= 5 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o visokoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje` : `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o naročito teškoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje`}
                      {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                    </>
                    :
                    <>
                      Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI1}
                      {izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} {nazivsudaI1} zbog počinjenog krivičnog djela {izvrsenjekd1 || "__________"}.
                      {" "}{I1tezina <= 1.5 ? `Ova okolnost, prema ocjeni Suda, sama po sebi može djelovati` : I1tezina <= 3 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o ozbiljnoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, djeluje` : I1tezina <= 5 ? `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o visokoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje` : `${izvrsenjekazna1.preostalidio !== undefined ? `Iako je ${potraživani} ${izdržao} jedan dio izrečene kazne i dalje se radi ` : "Radi se"} o naročito teškoj krivičnopravnoj sankciji (kazni zatvora) koja okolnost, prema ocjeni Suda, sasvim sigurno djeluje`}
                      {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                    </>}
              </>

            }
          </>} {/*izvrsenjeinfo 1 */}

        {izvrsenjeInfo.length === 2 &&
          <>
            {!I1tezina && !I2tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      ... <br /><Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              ((I1tezina && !I2tezina) || (!I1tezina && I2tezina))
                ?
                <>
                  {props.prioritet === 1 ?
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                    </>
                    : props.prioritet === 2 ?
                      <>
                        ... <br /><Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                      </>
                      :
                      <>
                        <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                      </>}
                </>
                :
                ((I1tezina === I2tezina) || (I1tezina > I2tezina))
                  ?
                  <>
                    {props.prioritet === 1 ?
                      <>
                        Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI1}
                        {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                          ` kao i ${kaznaI2} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}, odnosno krivičnog djela ${izvrsenjekd2 || "__________"}`}`
                          :
                          `${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"} kao i ${kaznaI2} ${izvrsenjenazivsuda2.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}`}.
                        {" "}

                        {(I1tezina <= 3 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                          (I1tezina <= 3 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                            (I1tezina <= 5 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora).` :
                              (I1tezina <= 5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                                (I1tezina <= 5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                                  (I1tezina <= 8 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora).` :
                                    (I1tezina <= 8 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora).` :
                                      (I1tezina <= 8 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                                        `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.`}
                      </>
                      : props.prioritet === 2 ?
                        <>
                          U odnosu na {potraživanogg} je, istovremeno, iniciran i postupak izvršenja {kaznaI1}
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                            ` kao i ${kaznaI2} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}, odnosno krivičnog djela ${izvrsenjekd2 || "__________"}`}`
                            :
                            `${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"} kao i ${kaznaI2} ${izvrsenjenazivsuda2.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}`}.
                          {" "}

                          {(I1tezina <= 1.5 && I2tezina <= 1.5) ? `Ove okolnosti, prema ocjeni Suda, same po sebi mogu djelovati` :
                            (I1tezina <= 3 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                              (I1tezina <= 3 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                (I1tezina <= 5 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora) koja okolnost, prema ocjeni Suda, djeluje` :
                                  (I1tezina <= 5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                    (I1tezina <= 5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                      (I1tezina <= 8 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                        (I1tezina <= 8 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                          (I1tezina <= 8 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                            `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje`}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>
                        :
                        <>
                          Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI1}
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                            ` kao i ${kaznaI2} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}, odnosno krivičnog djela ${izvrsenjekd2 || "__________"}`}`
                            :
                            `${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"} kao i ${kaznaI2} ${izvrsenjenazivsuda2.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}`}.
                          {" "}

                          {(I1tezina <= 1.5 && I2tezina <= 1.5) ? `Ove okolnosti, prema ocjeni Suda, same po sebi mogu djelovati` :
                            (I1tezina <= 3 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                              (I1tezina <= 3 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                (I1tezina <= 5 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora) koja okolnost, prema ocjeni Suda, djeluje` :
                                  (I1tezina <= 5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                    (I1tezina <= 5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                      (I1tezina <= 8 && I2tezina <= 1.5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                        (I1tezina <= 8 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                          (I1tezina <= 8 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                            `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje`}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>}
                  </>
                  :
                  <>
                    {props.prioritet === 1 ?
                      <>
                        Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI2}
                        {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                          ` kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}, odnosno krivičnog djela ${izvrsenjekd1 || "__________"}`}`
                          :
                          `${izvrsenjenazivsuda2?.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"} kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}`}.
                        {" "}

                        {(I1tezina <= 1.5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                          (I1tezina <= 1.5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora).` :
                            (I1tezina <= 1.5 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora).` :
                              (I1tezina <= 3 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.` :
                                (I1tezina <= 3 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora).` :
                                  `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji.`}
                      </>

                      : props.prioritet === 2 ?
                        <>
                          U odnosu na {potraživanogg} je, istovremeno, iniciran i postupak izvršenja {kaznaI2}
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                            ` kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}, odnosno krivičnog djela ${izvrsenjekd1 || "__________"}`}`
                            :
                            `${izvrsenjenazivsuda2?.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"} kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}`}.
                          {" "}

                          {(I1tezina <= 1.5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                            (I1tezina <= 1.5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora) koja okolnost, prema ocjeni Suda, djeluje` :
                              (I1tezina <= 1.5 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                (I1tezina <= 3 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                  (I1tezina <= 3 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                    `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje`}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.
                        </>
                        :
                        <>
                          Za Sud je, u kontekstu navedenog, značajna činjenica da je u odnosu na {potraživanogg} iniciran postupak izvršenja {kaznaI2}
                          {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                            ` kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? `koje su ${potraživanom} izrečene presudama ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : `koje je ${potraživanom} izrekao ${izvrsenjenazivsuda1.nom || "__________"} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"}, odnosno krivičnog djela ${izvrsenjekd1 || "__________"}`}`
                            :
                            `${izvrsenjenazivsuda2?.gen ? " izrečene presudom" : ""} ${nazivsudaI2} zbog počinjenog krivičnog djela ${izvrsenjekd2 || "__________"} kao i ${kaznaI1} ${izvrsenjenazivsuda1.gen ? " izrečene presudom" : ""} ${nazivsudaI1} zbog počinjenog krivičnog djela ${izvrsenjekd1 || "__________"}`}.
                          {" "}

                          {(I1tezina <= 1.5 && I2tezina <= 3) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o ozbiljnim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                            (I1tezina <= 1.5 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`})`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje visoke krivičnopravne sankcije (kazne zatvora) koja okolnost, prema ocjeni Suda, djeluje` :
                              (I1tezina <= 1.5 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                (I1tezina <= 3 && I2tezina <= 5) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje` :
                                  (I1tezina <= 3 && I2tezina <= 8) ? `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Vidljivo je da`} u odnosu na ${potraživanogg} predstoji izvršenje naročito visoke krivičnopravne sankcije (kazne zatvora), a koja okolnost, prema ocjeni Suda, djeluje` :
                                    `${(izvrsenjekazna1.preostalidio || izvrsenjekazna2.preostalidio) ? `Iako je ${(izvrsenjekazna1.preostalidio && izvrsenjekazna2.preostalidio) ? `${potraživani}, u određenom dijelu, ${izdržao} izrečene kazne i dalje se radi` : `${potraživani} ${izdržao} jedan dio izrečenih kazni (dio kazne po ${izvrsenjekazna1.preostalidio ? `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `prvoj` : ``} presudi ${nazivsudaI1}` : `${izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ? `drugoj` : ``} presudi ${nazivsudaI2}`}) i dalje se radi`}` : `Radi se`} o naročito visokim krivičnopravnim sankcijama (kaznama zatvora) čije izvršenje predstoji koja okolnost, prema ocjeni Suda, djeluje`}
                          {" "}motivirajuće na {potraživanog} u smislu preduzimanja radnji sa ciljem bjekstva ili skrivanja.

                        </>}
                  </>


            }


          </>}  {/* izvrsenjeInfo 2 */}


      </>
    )
  } //Itezinaizrecene


  const PItezina = () => {


    // Vrijednost zaprijećene:      5,  10, 20 100   ..., ozbiljna, naročito teška, doživotni
    // Vrijednost izrečene:         1,5- 3 - 5, 8     ..., ozbiljna, visoka,        naročito visoka


    //Odredi vrijednosti/prioritet
    const P1prioritet = P1tezina === false ? 0 : P1tezina <= 3 ? 1 : P1tezina <= 5 ? 2 : P1tezina <= 10 ? 3 : P1tezina <= 20 ? 4 : 5;
    const P2prioritet = P2tezina === false ? 0 : P1tezina <= 3 ? 1 : P2tezina <= 5 ? 2 : P2tezina <= 10 ? 3 : P2tezina <= 20 ? 4 : 5;
    const I1prioritet = I1tezina === false ? 0 : I1tezina <= 1 ? 1.1 : I1tezina <= 1.5 ? 2 : I1tezina <= 3 ? 3 : I1tezina <= 5 ? 4 : 5;
    const I2prioritet = I1tezina === false ? 0 : I1tezina <= 1 ? 1.1 : I1tezina <= 1.5 ? 2 : I1tezina <= 3 ? 3 : I1tezina <= 5 ? 4 : 5;

    const Psum = P1prioritet + P2prioritet
    const Isum = I1prioritet + I2prioritet

    return (
      <>
        {(Psum > Isum) || (Psum === Isum) ?
          <>
            <Ptezinazaprijecene prioritet={1} />{" "}
            <Itezinaizrecene prioritet={2} />
          </>
          :
          <>
            <Itezinaizrecene prioritet={1} />{" "}
            <Ptezinazaprijecene prioritet={2} />
          </>
        }
      </>
    )
  }


  const Iosudauodsutnosti = () => {
    return (
      <>
        {izvrsenjeInfo.length === 1 &&
          <>

            {izvrsenjeprisutanizricanju1 === "DA"
              ?
              <>
              </>
              :
              <>
                Sud je imao u vidu da {potraživani}, {(vrstaOdluke === "PR" || vrstaOdluke === "PRO" || vrstaOdluke === "PREKS" ? "prema sadržaju međunarodne potjernice" : "prema stanju u spisu")},
                {" "}nije {bio} {prisutan} izricanju presude {nazivsudaI1.replace("izrekao", "donio")}. Međutim, ova okolnost nije od značaja za donošenje odluke Suda o
                {" "}načinu osiguranja prisustva potraživanog lica u ovom postupku. Ovo iz razloga jer je odredbom člana 3. stav 1. Drugog dodatnog protokola uz Evropsku konvenciju o ekstradiciji,
                {" "}uz garanciju države moliteljice o poštivanju prava potraživanog lica na pokretanje novog sudskog postupka, propisana mogućnost izručenja potraživanog lica
                {" "}čak i u situaciji kada je osuđeno u odsustvu. Osim toga, članom 48. stav 1. ZMPP-a je propisano da će ministar pravde (pod pretpostavkom da izručenje bude dopušteno)
                {" "}u rješenju kojim se dopušta izručenje postaviti, između ostalih, uslov da će prema potraživanom licu biti ponovljen krivični postupak ako je osuđen u odsustvu.

              </>
            }

          </>}

        {izvrsenjeInfo.length === 2 &&
          <>

            {izvrsenjeprisutanizricanju1 === "NE" && izvrsenjeprisutanizricanju2 === "NE"
              ?
              <>
                Sud je imao u vidu da {potraživani}, {(vrstaOdluke === "PR" || vrstaOdluke === "PRO" || vrstaOdluke === "PREKS" ? "prema sadržaju međunarodne potjernice" : "prema stanju u spisu")},
                {" "}nije {bio} {prisutan} izricanju
                {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom ?
                  `${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen ? ` presuda ${izvrsenjenazivsuda1.gen || izvrsenjenazivsuda2.gen}` : ` presuda koje je donio ${izvrsenjenazivsuda1.nom || "__________"}`}`
                  :
                  ` presude ${nazivsudaI1.replace("izrekao", "donio")} kao i presude ${nazivsudaI2.replace("izrekao", "donio")}`}.

                {" "}Međutim, ove okolnosti nisu od značaja za donošenje odluke Suda o
                {" "}načinu osiguranja prisustva potraživanog lica u ovom postupku. Ovo iz razloga jer je odredbom člana 3. stav 1. Drugog dodatnog protokola uz Evropsku konvenciju o ekstradiciji,
                {" "}uz garanciju države moliteljice o poštivanju prava potraživanog lica na pokretanje novog sudskog postupka, propisana mogućnost izručenja potraživanog lica
                {" "}čak i u situaciji kada je osuđeno u odsustvu. Osim toga, članom 48. stav 1. ZMPP-a je propisano da će ministar pravde (pod pretpostavkom da izručenje bude dopušteno)
                {" "}u rješenju kojim se dopušta izručenje postaviti, između ostalih, uslov da će prema potraživanom licu biti ponovljen krivični postupak ako je osuđen u odsustvu.
              </>
              :
              izvrsenjeprisutanizricanju1 === "NE" && izvrsenjeprisutanizricanju2 === "DA"
                ?
                <>
                  Sud je imao u vidu da {potraživani}, {(vrstaOdluke === "PR" || vrstaOdluke === "PRO" || vrstaOdluke === "PREKS" ? "prema sadržaju međunarodne potjernice" : "prema stanju u spisu")},
                  {" "}nije {bio} {prisutan} izricanju {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom && "prve"} presude {nazivsudaI1.replace("izrekao", "donio")}. Međutim, ova okolnost nije od značaja za donošenje odluke Suda o
                  {" "}načinu osiguranja prisustva potraživanog lica u ovom postupku. Ovo iz razloga jer je odredbom člana 3. stav 1. Drugog dodatnog protokola uz Evropsku konvenciju o ekstradiciji,
                  {" "}uz garanciju države moliteljice o poštivanju prava potraživanog lica na pokretanje novog sudskog postupka, propisana mogućnost izručenja potraživanog lica
                  {" "}čak i u situaciji kada je osuđeno u odsustvu. Osim toga, članom 48. stav 1. ZMPP-a je propisano da će ministar pravde (pod pretpostavkom da izručenje bude dopušteno)
                  {" "}u rješenju kojim se dopušta izručenje postaviti, između ostalih, uslov da će prema potraživanom licu biti ponovljen krivični postupak ako je osuđen u odsustvu.
                </>
                :
                izvrsenjeprisutanizricanju1 === "DA" && izvrsenjeprisutanizricanju2 === "NE"
                  ?
                  <>
                    Sud je imao u vidu da {potraživani}, {(vrstaOdluke === "PR" || vrstaOdluke === "PRO" || vrstaOdluke === "PREKS" ? "prema sadržaju međunarodne potjernice" : "prema stanju u spisu")},
                    {" "}nije {bio} {prisutan} izricanju {izvrsenjenazivsuda1.nom === izvrsenjenazivsuda2.nom && "druge"} presude {nazivsudaI2.replace("izrekao", "donio")}. Međutim, ova okolnost nije od značaja za donošenje odluke Suda o
                    {" "}načinu osiguranja prisustva potraživanog lica u ovom postupku. Ovo iz razloga jer je odredbom člana 3. stav 1. Drugog dodatnog protokola uz Evropsku konvenciju o ekstradiciji,
                    {" "}uz garanciju države moliteljice o poštivanju prava potraživanog lica na pokretanje novog sudskog postupka, propisana mogućnost izručenja potraživanog lica
                    {" "}čak i u situaciji kada je osuđeno u odsustvu. Osim toga, članom 48. stav 1. ZMPP-a je propisano da će ministar pravde (pod pretpostavkom da izručenje bude dopušteno)
                    {" "}u rješenju kojim se dopušta izručenje postaviti, između ostalih, uslov da će prema potraživanom licu biti ponovljen krivični postupak ako je osuđen u odsustvu.
                  </>
                  :
                  <>
                  </>
            }

          </>}

      </>
    )
  }
  /*
  const mogucnosti = [ 'Lfalsdokumenti',

  'Lvisealiasa',
                  
           'Lbjekstvoumaticnu',
                  
            'Lboravisteudrugojzemlji',
                  
            'Lvezesadrugomzemljom',
                  
            'Lbezvezasabih',
       
 'Lbeznacajneveze',
                  
            // ----------------------------------------------------------
 
'Lfalsdokumenti,Lvisealiasa',
 
'Lfalsdokumenti,Lbjekstvoumaticnu',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji',
 
'Lfalsdokumenti,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lbezvezasabih',
 
'Lfalsdokumenti,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu',
 
'Lvisealiasa,Lboravisteudrugojzemlji',
 
'Lvisealiasa,Lvezesadrugomzemljom',
 
'Lvisealiasa,Lbezvezasabih',
 
'Lvisealiasa,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji',
 
'Lbjekstvoumaticnu,Lvezesadrugomzemljom',
 
'Lbjekstvoumaticnu,Lbezvezasabih',
 
'Lbjekstvoumaticnu,Lbeznacajneveze',
 
'Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lbezvezasabih,Lbeznacajneveze',
 
// 3------------------------------------------------------
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji',
 
'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lvisealiasa,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lbezvezasabih',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lbeznacajneveze',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lbeznacajneveze',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lvisealiasa,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lvisealiasa,Lbezvezasabih,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lbezvezasabih,Lbeznacajneveze',
 
'Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
//-4------------------------------------------------------------
 
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
//--5------------------------------------------------------------------
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
//-6----------------------------------------------------------------
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
//-7----------------------------------------------
 
'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih,Lbeznacajneveze',
 
]

const output = mogucnosti.map((item) => {
  return `case '${item}':<br>  return ""${item.toUpperCase()}"";<br><br>`;
});
 
*/



  const OtezavajuceLicne = () => {

    const pasvenavedeno = `pa sve navedeno ukazuje na zaključak da postoji realna opasnost da bi ${potraživani}, ukoliko protiv ${njega} ne bi bila ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS") ? "određena" : "produžena"} mjera pritvora, ${mogao} napustiti Bosnu i Hercegovinu i pobjeći ili preduzeti radnje u cilju skrivanja, čime bi ponovo postao ${nedostupan}, ovog puta i pravosudnim organima BiH, a čime bi se onemogućilo okončanje postupka ${vrstaOdluke === "PREKS" ? "izručenja bez obzira na datu saglasnost za pojednostavljenu predaju pravosudnim organima države molieljice" : "izručenja"}`;
    const Prilikom = `Prilikom donošenja ove odluke Sud je imao u vidu da`;



    const maticnaZemlja = () => {
      let maticna;
      let veznik;

      if (!DRZAVLJANSTVO && !DRZAVLJANSTVO2) {
        maticna = `je ${državljanin} ____________`;
        veznik = "u matičnu"

      } else if (DRZAVLJANSTVO && !DRZAVLJANSTVO2) {
        if (DRZMOLITELJICA) {
          if (DRZAVLJANSTVO.ime !== "Bosna i Hercegovina" && DRZAVLJANSTVO.ime !== DRZMOLITELJICA.ime) {
            maticna = `je ${državljanin} ${DRZAVLJANSTVO.gen}`;
            veznik = "u matičnu"
          }
        } else { // nije izabrana DRZMOLITELJICA
          maticna = "____________";
          veznik = "u matičnu"
        }

      } else if (!DRZAVLJANSTVO && DRZAVLJANSTVO2) {
        if (DRZMOLITELJICA) {
          if (DRZAVLJANSTVO2.ime !== "Bosna i Hercegovina" && DRZAVLJANSTVO2.ime !== DRZMOLITELJICA.ime) {
            maticna = `je ${državljanin} ${DRZAVLJANSTVO2.gen}`;
            veznik = "u matičnu"
          }
        } else { // nije izabrana DRZMOLITELJICA
          maticna = `je ${državljanin} ____________`;
          veznik = "u matičnu"
        }
      } else {  // 2 dva drzavljanstva
        let filtriraneMaticne = [DRZAVLJANSTVO, DRZAVLJANSTVO2].filter(
          (item) =>
            item &&
            item.ime !== "Bosna i Hercegovina" &&
            item.ime !== DRZMOLITELJICA?.ime
        );

     //   console.log("filtriraneMaticne")
     //   console.log(filtriraneMaticne)

        if (DRZMOLITELJICA) {
          if (filtriraneMaticne.length === 1) {
            if (DrzavljaninBiH === "DA") {
              maticna = `pored državljanstva Bosne i Hercegovine posjeduje i državljanstvo ` + filtriraneMaticne[0].gen;
              veznik = "u ovu zemlju"
            } else if (filtriraneMaticne.length === 2) {

              maticna = `pored državljanstva ` + DRZMOLITELJICA.gen + `, kao države koja ` + (licezensko ? "je" : "ga") + ` potražuje posjeduje i državljanstvo ` + filtriraneMaticne[0].gen;
              veznik = "u ovu zemlju"
            }

          } else {  //dvije matične zemlje (treće zemlje)
            maticna = "posjeduje državljanstvo " + filtriraneMaticne[0]?.gen + " kao i " + filtriraneMaticne[1]?.gen;
            veznik = "u neku od ovih zemalja"
          }
        }
        else {
          maticna = `je ${državljanin} ____________ kao i ____________`;
          veznik = "u neku od ovih zemalja"
        }

      } // 2 dva drzavljanstva

      return { maticna, veznik };
    };

    const { maticna, veznik } = maticnaZemlja();


    switch (odabraneOkolnostiLicne) {



      case 'Lfalsdokumenti':

        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost ${pasvenavedeno}.`;
      case 'Lvisealiasa':

        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima) ${pasvenavedeno}.`;
      case 'Lbjekstvoumaticnu':

        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.`;
      case 'Lboravisteudrugojzemlji':

        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;
      case 'Lvezesadrugomzemljom':

        return `${Prilikom} ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;
      case 'Lbezvezasabih':

        return `${Prilikom} ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}`;
      case 'Lbeznacajneveze':

        return `${Prilikom} ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}`;
      // ----------------------------------------------------------

      case 'Lfalsdokumenti,Lvisealiasa':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                ${Potraživani} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima) ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}`;

      case 'Lfalsdokumenti,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}`;

      case 'Lvisealiasa,Lbjekstvoumaticnu':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lboravisteudrugojzemlji':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lvezesadrugomzemljom':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}`;

      case 'Lvisealiasa,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. Osim toga, vidljivo je da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lvezesadrugomzemljom':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. Osim toga, vidljivo je da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. Istovremeno, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  Istovremeno, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. Osim toga, ${potraživani} ima, istovremeno, i zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.  S druge strne, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                        Sud je, nadalje, cijenio činjenicu da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                        Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Sud je, nadalje, cijenio činjenicu da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima) ${pasvenavedeno}. Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Sud je, nadalje, cijenio činjenicu da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost .
                Sud je, nadalje, cijenio i činjenicu da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Sud je, nadalje, cijenio činjenicu da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). Osim toga, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Uz navedeno, značajna je činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Uz navedeno, značajna je činjenica da  ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Uz navedeno, značajna je činjenica da ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Uz navedeno, cijenjena je i činjenica da ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost . ${Potraživani}, nadalje, ima zasnovano boravište u drugoj zemlji pri čemu nisu sporne ni veze ${potraživanog} sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                Osim toga, ${potraživani} ima zasnovane veze i sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji ali i zasnovane veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ima zasnovano boravište u drugoj zemlji ali i zasnovane veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuća, imaju cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Konačno, značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Konačno, značajna je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Konačno, značajna je i činjenica da ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.  
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga,  ${potraživani} ima zasnovano boravište u drugoj zemlji ali i zasnovane veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuća, imaju cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno} .`;

      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Osim toga, ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                 ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                 Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                 S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                Osim toga, cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosi se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Istovremeno, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Istovremeno, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Istovremeno, cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                 ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                 Istovremeno, cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                 S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Istovremeno, cijenjena je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;



      case 'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i ima zasnovane veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Cijenjena je i činjenica da ${potraživani} ima zasnovano boravište ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Uz sve navedeno, značajno je da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Istovremeno, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Uz sve navedeno, značajno je da ${potraživani} ima i zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge sterane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim tog, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Značajna je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Značajna je i činjenica da ${potraživani} ima zasnovane veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima). 
                Značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosi se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Značajno je da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva. 
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost. 
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                Značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.  
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
               Značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
               S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                 ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.
                 Značajna je i činjenica da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnost se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                 S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;


      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbezvezasabih':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Značajno je da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                Nadalje, ${potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove.  
                Osim toga, ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koje okolnosti se, kao otežavajuće, imaju cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima određene veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      case 'Lfalsdokumenti,Lvisealiasa,Lbjekstvoumaticnu,Lboravisteudrugojzemlji,Lvezesadrugomzemljom,Lbeznacajneveze':
        return `${Prilikom} je ${potraživani} ${lišen} slobode u posjedu lažnog ličnog dokumenta što je, u kontekstu opasnosti od bjekstva, ocijenjeno kao naročito otežavajuća okolnost.
                Cijenjena je i činjenica da se ${(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO") ? `${potraživani}, prema podacima iz potjernice,` : `${potraživani}, prema stanju u spisu,`} pojavljuje u pravnom prometu i sa drugim identitetima (aliasima).
                ${Potraživani} ${maticna} pa bi bjekstvom ${veznik} sasvim sigurno ${mogao} uspješno osporiti zahtjev države moliteljice za izručenje. Ovo ako se ima u vidu pretpostavka da niti jedna država ne izručuje vastite državljane radi krivičnog gonjenja ili izvršenja izrečene kazne u nekoj drugoj zemlji ili za ovaj oblik međunarodne pravne pomoći propisuje restriktivne uslove. 
                Značajno je i to da ${potraživani} ima zasnovano boravište u drugoj zemlji ali i veze sa ___________ (_________ prirode) koja okolnost se, kao otežavajuća, ima cijeniti prilikom procjene opasnosti od bjekstva.
                S druge strane, ne postoje okolnosti ili dokazi iz kojih bi proizilazilo da ${potraživani} ima značajnije veze sa državom Bosnom i Hercegovinom, što ${bi_ga} opredijelilo da boravi u BiH do okončanja ovog postupka ${pasvenavedeno}.`;

      default:

        return (
          <Alert variant="warning alertV2">
            Precizirajte lične (otežavajuće) okolnosti u dodatnim opcijama.
          </Alert>
        );
    }
  };

  /************  Snpeti za obrazloženje opasnosti kod MZ ****************/

  const OdnosMaticnaMoliteljica = () => {
    let InoUpucenost;
    let Veznik;

    if (!DRZAVLJANSTVO && !DRZAVLJANSTVO2) {  //nije izabrano državljanstvo
      InoUpucenost = (<><Alert variant="warning alertV2">Izaberite državljanstvo {potraživanog} lica u <b>ličnim podacima</b>.</Alert>...</>);
    } else if (DRZAVLJANSTVO && !DRZAVLJANSTVO2) { //1 državljanstvo
      if (DRZMOLITELJICA) {
        if (DrzavljaninBiH === "DA") {
          InoUpucenost = `Naime, ${potraživani} pored državljanstva BiH, prema stanju u spisu, ne posjeduje državljanstvo bilo koje druge zemlje u kojoj bi se ${mogao} skrivati, niti podaci u spisu ukazuju da ${potraživani} ima zasnovane veze ili imovinu u inostranstvu što bi, s obzirom na razloge zbog kojih je raspisana potraga, ukazivalo na visok stepen opasnosti od bjekstva.`;
          Veznik = "S druge strane, nema"
        } else {  // Nije državljanin BiH
          if (DRZAVLJANSTVO.ime === DRZMOLITELJICA.ime) {  //podudaranje
            InoUpucenost = `Naime, ${potraživani} pored državljanstva ${DRZAVLJANSTVO.gen}, kao države koja ga potražuje, ne posjeduje državljanstvo bilo koje druge zemlje u kojoj bi se ${mogao} skrivati što bi, s obzirom na razloge zbog kojih je raspisana potraga, ukazivalo na visok stepen opasnosti od bjekstva. Nesporno je da ${potraživani}, uvažavajući ${njegov} zahtjev za provođenje redovne ekstradicione procedure, realno, nema interes za odlazak u na područje ${DRZAVLJANSTVO.gen}.`;
            Veznik = "S druge strane, nema"
          } else { // nema podudaranja
            InoUpucenost = `Naime, činjenica je da ${potraživani} posjeduje državljanstvo ${DRZAVLJANSTVO.gen} u kojoj državi bi, hipotetički gledano, ${mogao} osporavati zahtjev za izručenje države moliteljice pod pretpostavkom da se da i realizuje bjekstvo u ovu državu. Međutim, ova činjenica ne može, po automatizmu, predstavljati osnov za određivanje pritvora kao najstrožije mjere za osiguranje prisustva potraživanog lica već se ima cijeniti u vezi sa ostalim okolnostima relevantnim za procjenu bojazni od bjekstva.`;
            Veznik = "Nema"
          }
        }
      } else { // nije izabrana DRZMOLITELJICA
        InoUpucenost = (<><Alert variant="warning alertV2">Izaberite <b>državu moliteljicu.</b>.</Alert>...</>);
      }

    } else if (!DRZAVLJANSTVO && DRZAVLJANSTVO2) {
      if (DRZMOLITELJICA) {
        if (DrzavljaninBiH === "DA") {
          InoUpucenost = `Naime, ${potraživani} pored državljanstva BiH, prema stanju u spisu, ne posjeduje državljanstvo bilo koje druge zemlje u kojoj bi se ${mogao} skrivati, niti podaci u spisu ukazuju da ${potraživani} ima zasnovane veze ili imovinu u inostranstvu što bi, s obzirom na razloge zbog kojih je raspisana potraga, ukazivalo na visok stepen opasnosti od bjekstva.`;
          Veznik = "S druge strane, nema"
        } else {  // Nije državljanin BiH
          if (DRZAVLJANSTVO2.ime === DRZMOLITELJICA.ime) {  //podudaranje
            InoUpucenost = `Naime, ${potraživani} pored državljanstva ${DRZAVLJANSTVO2.gen}, kao države koja ga potražuje, ne posjeduje državljanstvo bilo koje druge zemlje u kojoj bi se ${mogao} skrivati što bi, s obzirom na razloge zbog kojih je raspisana potraga, ukazivalo na visok stepen opasnosti od bjekstva. Nesporno je da ${potraživani}, uvažavajući ${njegov} zahtjev za provođenje redovne ekstradicione procedure, realno, nema interes za odlazak u na područje ${DRZAVLJANSTVO2.gen}.`;
            Veznik = "S druge strane, nema"
          } else { // nema podudaranja
            InoUpucenost = `Naime, činjenica je da ${potraživani} posjeduje državljanstvo ${DRZAVLJANSTVO2.gen} u kojoj državi bi, hipotetički gledano, ${mogao} osporavati zahtjev za izručenje države moliteljice pod pretpostavkom da se da i realizuje bjekstvo u ovu državu. Međutim, ova činjenica ne može, po automatizmu, predstavljati osnov za određivanje pritvora kao najstrožije mjere za osiguranje prisustva potraživanog lica već se ima cijeniti u vezi sa ostalim okolnostima relevantnim za procjenu bojazni od bjekstva.`;
            Veznik = "Nema"
          }
        }
      } else { // nije izabrana DRZMOLITELJICA
        InoUpucenost = (<><Alert variant="warning alertV2">Izaberite <b>državu moliteljicu.</b>.</Alert>...</>);
      }
    } else {  // 2 dva drzavljanstva

      //pronađi treću zemlju u koju bi mogao otići
      let filtriraneMaticne = [DRZAVLJANSTVO, DRZAVLJANSTVO2].filter(
        (item) =>
          item &&
          item.ime !== "Bosna i Hercegovina" &&
          item.ime !== DRZMOLITELJICA?.ime
      );

      if (DRZMOLITELJICA) {
        if (filtriraneMaticne.length === 0) {  // BiH i DRZ moliteljica
          InoUpucenost = `Naime, ${potraživani} pored državljanstva Bosne i Hercegovine te državljanstva ${DRZMOLITELJICA?.gen}, kao države ${koja_ga} potražuje, ne posjeduje državljanstvo bilo koje druge zemlje u kojoj bi se ${mogao} skrivati što bi, s obzirom na razloge zbog kojih je raspisana potraga, ukazivalo na visok stepen opasnosti od bjekstva. Nesporno je da ${potraživani}, uvažavajući ${njegov} zahtjev za provođenje redovne ekstradicione procedure, realno, nema interes za odlazak u na područje ${DRZMOLITELJICA?.gen}.`;
          Veznik = "S druge strane, nema"
        } else if (filtriraneMaticne.length === 1) {  //DRZ BiH + 1 treća zemlja
          InoUpucenost = `Naime, činjenica je da ${potraživani} pored državljanstva Bosne i Hercegovine posjeduje i državljanstvo ` + filtriraneMaticne[0].gen + ` u kojoj državi bi, hipotetički gledano, ${mogao} osporavati zahtjev za izručenje države moliteljice pod pretpostavkom da se da i realizuje bjekstvo u ovu državu. Međutim, ova činjenica ne može, po automatizmu, predstavljati osnov za određivanje pritvora kao najstrožije mjere za osiguranje prisustva potraživanog lica već se ima cijeniti u vezi sa ostalim okolnostima relevantnim za procjenu bojazni od bjekstva.`;
          Veznik = "Nema"
        } else {  // obje države su "treće zemlje"
          InoUpucenost = `Naime, činjenica je da ${potraživani} posjeduje ${DRZAVLJANSTVO.gen} i državljanstvo ${DRZAVLJANSTVO2.gen} u kojim državama bi, hipotetički gledano, ${mogao} osporavati zahtjev za izručenje države moliteljice pod pretpostavkom da se da i realizuje bjekstvo u neku od ovih država. Međutim, ove činjenice ne mogu, po automatizmu, predstavljati osnov za određivanje pritvora kao najstrožije mjere za osiguranje prisustva potraživanog lica već se imaju cijeniti u vezi sa ostalim okolnostima relevantnim za procjenu bojazni od bjekstva.`;
          Veznik = "Nema"
        }
      }
      else {
        // nije izabrana DRZMOLITELJICA
        InoUpucenost = (<><Alert variant="warning alertV2">Izaberite <b>državu moliteljicu.</b>.</Alert>...</>);
      }

    } // 2 dva drzavljanstva

    return { InoUpucenost,Veznik };
  };

  const { InoUpucenost, Veznik } = OdnosMaticnaMoliteljica();

  const PvrijemedostupnostiMZ = (props) => {
   

    if (procesuiranjeInfo.length === 1) {
      if (!P1razlika) {
        if (props.prioritet === 1) {
          return ``;
        } else if (props.prioritet === 2) {
          return ``;
        } else {
          return ``;
        }
      } else if (P1razlika < 3) {
        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${P1nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${P1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${P1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }
      }  
    } else if (procesuiranjeInfo.length === 2) {

      if (!P1razlika && !P2razlika) {
        if (props.prioritet === 1) {
          return ``;
        } else if (props.prioritet === 2) {
          return ``;
        } else {
          return ``;
        }

      } else if (!P1razlika && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${P2nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${P2nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${P2nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }
      } else if (P1razlika < 3 && !P2razlika) {
        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${P1nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${P1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${P1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }
      } else if (P1razlika < 3 && P2razlika < 3) {
        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${PnalozIizdatiOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${PnalozIizdatiOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${PnalozIizdatiOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }
      }  
    }

  }  //PvrijemedostupnostiMZ


  const IvrijemedostupnostiMZ = (props) => {

    if (izvrsenjeInfo.length === 1) {
      if (!I1razlika) {
        if (props.prioritet === 1) {
          return ``;
        } else if (props.prioritet === 2) {
          return ``;
        } else {
          return ``;
        }


      } else if (I1razlika < 3) {

        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${I1nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${I1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${I1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }

      } 
    } else if (izvrsenjeInfo.length === 2) {

      if (!I1razlika && !I2razlika) {

        if (props.prioritet === 1) {
          return ``;
        } else if (props.prioritet === 2) {
          return ``;
        } else {
          return ``;
        }

      } else if (!I1razlika && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${I2nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${I2nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${I2nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }

      } else if (I1razlika < 3 && !I2razlika) {

        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${I1nalogIzdatOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${I1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${I1nalogIzdatOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }

      } else if (I1razlika < 3 && I2razlika < 3) {

        if (props.prioritet === 1) {
          return `Ovo posebno iz razloga jer ${InaloziIzdatiOd}`;
        } else if (props.prioritet === 2) {
          return `pri čemu ${InaloziIzdatiOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        } else {
          return `Ovo posebno iz razloga jer ${InaloziIzdatiOd} iz čega slijedi da se ne radi o relativno dužem vremenskom periodu nedostupnosti ${potraživanog} pravosudnim organima države moliteljice.`;
        }

      } 

    } //izvrsenjeInfo.length === 2

  }  //IvrijemedostupnostiMZ

  
  const PIvrijemedostupnostiMZ = () => {    //prati samo vrijednosti/razliku do 3 mjeseca (za potrebe MZ jer sve preko 3 mjeseca nije za MZ)

    //Odredi vrijednosti/prioritet
    const P1prioritet = P1razlika === false ? 0 : P1razlika < 3 ? 1 : P1razlika < 7 ? 2 : 3;
    const P2prioritet = P2razlika === false ? 0 : P2razlika < 3 ? 1 : P2razlika < 7 ? 2 : 3;
    const I1prioritet = I1razlika === false ? 0 : I1razlika < 3 ? 1 : I1razlika < 7 ? 2 : 3;
    const I2prioritet = I2razlika === false ? 0 : I2razlika < 3 ? 1 : I2razlika < 7 ? 2 : 3;

    const Psum = P1prioritet + P2prioritet
    const Isum = I1prioritet + I2prioritet

    return (
      <>
        {(Psum > Isum) || (Psum === Isum) ?
          <>
            <PvrijemedostupnostiMZ prioritet={1} />{" "}
            <IvrijemedostupnostiMZ prioritet={2} />
          </>
          :
          <>
            <IvrijemedostupnostiMZ prioritet={1} />{" "}
            <PvrijemedostupnostiMZ prioritet={2} />
          </>
        }
      </>
    )
  } //PIvrijemedostupnostiMZ

  const BiHupucenost = () => {

    if (DrzavljaninBiH === "DA") {
          switch (odabraneOkolnostiMZOlaksavajuce) {
            case "MZboraviste":
            return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je prihvatio te, kao relevantne za donošenje odluke o
            primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima zasnovano boravište u BiH i to na adresi
            ${licnipodaci.nastanjen || "____________________________"} što, uz činjenicu posjedovanja domaćeg državljanstva, čini osnov za
            zaključak da je ${potraživani}, dakle, značajnijim vezama ${upućen} ${dvadrzavljanstva === "NE" ? "isključivo" : ""} na BiH.`
            case "MZsmjestaj":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je prihvatio te, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima osiguran smještaj u BiH i to na adresi
              ${licnipodaci.nastanjen || "____________________________"} što, uz činjenicu posjedovanja domaćeg državljanstva, čini osnov za
              zaključak da je ${potraživani}, dakle, značajnijim vezama ${upućen} ${dvadrzavljanstva === "NE" ? "isključivo" : ""} na BiH.`

            case "MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je prihvatio kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva navode ${potraživanog} o postojanju porodičnih veza u BiH što uz činjenicu posjedovanja
              domaćeg državljanstva, čini osnov za zaključak da je ${potraživani}, dakle, značajnijim vezama ${upućen} ${dvadrzavljanstva === "NE" ? "isključivo" : ""} na BiH.`
            case "MZboraviste,MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je prihvatio te, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima zasnovano boravište na adresi ${licnipodaci.nastanjen || "____________________________"})
              kao i porodične veze BiH što, uz činjenicu posjedovanja domaćeg državljanstva, čini osnov za zaključak da je ${potraživani}, dakle, značajnijim vezama ${upućen} ${dvadrzavljanstva === "NE" ? "isključivo" : ""} na BiH.`
            case "MZsmjestaj,MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je prihvatio te, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima osiguran smještaj u BiH i to na adresi ${licnipodaci.nastanjen || "____________________________"}
              kao i porodične veze BiH što, uz činjenicu posjedovanja domaćeg državljanstva, čini osnov za zaključak da je ${potraživani}, dakle, značajnijim vezama ${upućen} ${dvadrzavljanstva === "NE" ? "isključivo" : ""} na BiH.`
            default:
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud je, kao odlučnu, za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva u ovoj procesnoj situaciji cijenio činjenicu posjedovanja domaćeg državljanstva na strani ${potraživanog}
              koja činjenica, u pravilu, upućuje na mogućnost i opravdanost primjene blažih mjera prema potraživanom licu.`
          }
      

    } else {   //nije državljanin BiH

          switch (odabraneOkolnostiMZOlaksavajuce) {
            case "MZboraviste":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} slijedom čega je, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima zasnovano boravište u BiH i to na adresi ${licnipodaci.nastanjen || "____________________________"}.`
              case "MZsmjestaj":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} slijedom čega je, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima osiguran smještaj u BiH i to na adresi ${licnipodaci.nastanjen || "____________________________"}.`
            case "MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} slijedom čega je, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima zasnovane porodične veze u BiH.`
            case "MZboraviste,MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} slijedom čega je, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima zasnovano boravište (na adresi ${licnipodaci.nastanjen || "____________________________"})
              ali i određene porodične veze u BiH.`
   
            case "MZsmjestaj,MZporodicne":
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} slijedom čega je, kao relevantne za donošenje odluke o
              primjeni blažih mjera za osiguranje prisustva, cijenio navode ${potraživanog} da ima osiguran smještaj (na adresi ${licnipodaci.nastanjen || "____________________________"})
              ali i određene porodične veze u BiH.`
            default:
              return `U odsustvu podataka koji bi ukazivali na drugačiji zaključak Sud nije mogao samo na osnovu činjenice da je raspisana međunarodna potraga
              izvesti nesporan zaključak o postojanju stvarne opasnosti od bjekstva i/ili skrivanja ${potraživanog} što je jedan od zakonom propisanih uslova za primjenu nastrožije mjere
              za osiguranje potraživanog lica (privremeni pritvor).`
          }
          

    }
    

  }

 // console.log("P1tezina", P1tezina)
 // console.log("P2tezina", P2tezina)
 // console.log("I1tezina", I1tezina)
 // console.log("I2tezina", I2tezina)


  
  const PtezinazaprijeceneMZ = (props) => {


    return (
      <>

        {procesuiranjeInfo.length === 1 &&
          <>
            {!P1tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za prvo krvično djelo u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      <br /><br /><Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za prvo krvično djelo u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              <>
                {props.prioritet === 1 ?
                  <>
                    Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {P1tezina <= 5 ? `imati u vidu da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje
                    naročito teškog krivičnog djela već se radi o krivičnom djelu za koje ${kaznaP1} koja činjenica ima direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                    bjekstva. Ovo posebno kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                    potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna
                    potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                  </>
                  : props.prioritet === 2 ?
                  <>
                    Navedeni princip o procjeni opasnosti od bjekstva primjenjiv je i sa aspekta drugog razloga zbog kojeg je raspisana međunarodna potraga (krivično gonjenje). 
                    {P1tezina <= 5 && ` S tim u vezi, značajno je da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje naročito teškog krivičnog djela već se radi
                    o krivičnom djelu za koje ${kaznaP1}.`}
                  </>
                    :
                  <>
                    Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {P1tezina <= 5 ? `imati u vidu da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje
                    naročito teškog krivičnog djela već se radi o krivičnom djelu za koje ${kaznaP1} koja činjenica ima direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                    bjekstva. Ovo posebno kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                    potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                    činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                  </>}
              </>

            }

          </>}

        {procesuiranjeInfo.length === 2 &&
          <>
            {!P1tezina && !P2tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      ... <br /><Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>

                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećene kazne u razlozima izručenja.</Alert>
                    </>}
              </>

              :
              ((P1tezina && !P2tezina) || (!P1tezina && P2tezina))
                ?
                <>
                  {props.prioritet === 1 ?
                    <>
                      <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                    </>
                    : props.prioritet === 2 ?
                      <>
                        ... <br /><Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                      </>
                      :
                      <>
                        <Alert variant="warning alertV2">Precizirajte zaprijećenu kaznu za <strong>{P1tezina ? "drugo" : "prvo"}</strong> krivično djelo u razlozima izručenja.</Alert>
                      </>}
                </>
                :  //P1tezina i P2tezina uneseni
                  <>
                    {props.prioritet === 1 ?
                      <>
                        Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {(P1tezina <= 5 && P2tezina <= 5) ? `imati u vidu da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje
                        naročito teških krivičnih djela već se radi o ${procesuiranjekazna1.val === procesuiranjekazna2.val ? `krivičnim djelima za koja ${kaznaP1}` : `krivičnom djelu za koje ${kaznaP1} odnosno, ${kaznaP2.replace(", kao maksimalna,", "")}`}.
                        Ove činjenice imaju direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem bjekstva posebno u situaciji kada je ${potraživani} u procesnoj situaciji
                        u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen.` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno,
                         činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                      </>
                      : props.prioritet === 2 ?
                        <>
                          Navedeni princip o procjeni opasnosti od bjekstva primjenjiv je i sa aspekta drugog razloga zbog kojeg je raspisana međunarodna potraga (krivično gonjenje).
                          {(P1tezina <= 5 && P2tezina <= 5) && ` S tim u vezi, značajno je da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje naročito teških krivičnih djela
                          već se radi o ${procesuiranjekazna1.val === procesuiranjekazna2.val ? `krivičnim djelima za koja ${kaznaP1}` : ` krivičnom djelu za koje ${kaznaP1} odnosno, ${kaznaP2.replace(", kao maksimalna,", "")}`}.`}
                        </>
                        :
                        <>
                        Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {(P1tezina <= 5 && P2tezina <= 5) ? `imati u vidu da se ${potraživanom}, objektivno, ne stavlja na teret izvršenje
                        naročito teških krivičnih djela već se radi o ${procesuiranjekazna1.val === procesuiranjekazna2.val ? `krivičnim djelima za koja ${kaznaP1}` : `krivičnom djelu za koje ${kaznaP1} odnosno, ${kaznaP2.replace(", kao maksimalna,", "")}`}.
                        Ove činjenice imaju direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem bjekstva posebno u situaciji kada je ${potraživani} u procesnoj situaciji
                        u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen.` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                        činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                        </>}
                  </>}
           
          </>}
           {/* procesuiranjeinfo 2 */}

      </>)

  } //PtezinazaprijeceneMZ

  const ItezinaizreceneMZ = (props) => {

    // Vrijednost zaprijećene:      5,  10, 20 100   ..., ozbiljna, naročito teška, doživotni
    // Vrijednost izrečene:         1,5- 3 - 5, 8     ..., ozbiljna, visoka,        naročito visoka

    return (
      <>
        {izvrsenjeInfo.length === 1 &&
          <>
            {!I1tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za prvu presudu u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      <br /><br />  <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za prvu presudu u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečenu kaznu u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              <>
                {props.prioritet === 1 ?
                  <>
                      Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {I1tezina <=1 ? `imati u vidu da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                      nije izrečena naročito teška krivičnopravna sankcija već se radi o izvršenju ${kaznaI1} koja činjenica ima direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                      bjekstva. Ovo posebno kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                      potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                      činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                  </>
                  : props.prioritet === 2 ?
                    <>
                     Navedeni princip o procjeni opasnosti od bjekstva primjenjiv je i sa aspekta drugog razloga zbog kojeg je raspisana međunarodna potraga (izvršenje kazne).
                     {I1tezina <=1 && ` S tim u vezi, značajno je da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                      nije izrečena naročito teška krivičnopravna sankcija već se radi o izvršenju ${kaznaI1}.`}
                    </>
                    :
                    <>
                      Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {I1tezina <=1 ? `imati u vidu da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                      nije izrečena naročito teška krivičnopravna sankcija već se radi o izvršenju ${kaznaI1} koja činjenica ima direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                      bjekstva. Ovo posebno kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                      potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                      činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
 
                    </>}
              </>

            }
          </>} {/*izvrsenjeinfo 1 */}

        {izvrsenjeInfo.length === 2 &&
          <>
            {!I1tezina && !I2tezina ?
              <>
                {props.prioritet === 1 ?
                  <>
                    <Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                  </>
                  : props.prioritet === 2 ?
                    <>
                      ... <br /><Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                    </>
                    :
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečene kazne u razlozima izručenja.</Alert>
                    </>}
              </>
              :
              ((I1tezina && !I2tezina) || (!I1tezina && I2tezina))
                ?
                <>
                  {props.prioritet === 1 ?
                    <>
                      <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                    </>
                    : props.prioritet === 2 ?
                      <>
                        ... <br /><Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                      </>
                      :
                      <>
                        <Alert variant="warning alertV2">Precizirajte izrečenu kaznu za <strong>{I1tezina ? "drugu" : "prvu"}</strong> presudu u razlozima izručenja.</Alert>
                      </>}
                </>
                  :
                  <>
                    {props.prioritet === 1 ?
                      <>
                          Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {(I1tezina <= 1 && I2tezina <= 1) ? `imati u vidu da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                          nisu izrečene naročito teške krivičnopravne sankcije već se radi o izvršenju ${izvrsenjekazna1.izrecenakazna === izvrsenjekazna2?.izrecenakazna ?
                            `${(izvrsenjekazna1.preostalidio !== undefined || izvrsenjekazna2?.preostalidio !== undefined) ?
                                                      `${kaznaI1} odnosno ${kaznaI2}`:
                                                      `kazni zatvora u trajanju od po ${izvrsenjekazna1.izrecenakazna}`}` : `${kaznaI1} odnosno ${kaznaI2}`}.
                          ${" "}Ove činjenice imaju direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                          bjekstva kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                          potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                          činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                     
                         
                     
                      </> 
                      : props.prioritet === 2 ?
                        <>
                           Navedeni princip o procjeni opasnosti od bjekstva primjenjiv je i sa aspekta drugog razloga zbog kojeg je raspisana međunarodna potraga (izvršenje kazne).
                          {(I1tezina <= 1 && I2tezina <= 1) && ` S tim u vezi, značajno je da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                          nisu izrečene naročito teške krivičnopravne sankcije već se radi o izvršenju ${izvrsenjekazna1.izrecenakazna === izvrsenjekazna2?.izrecenakazna ?
                            `${(izvrsenjekazna1.preostalidio !== undefined || izvrsenjekazna2?.preostalidio !== undefined) ?
                                                      `${kaznaI1} odnosno ${kaznaI2}`:
                                                      `kazni zatvora u trajanju od po ${izvrsenjekazna1.izrecenakazna}`}` : `${kaznaI1} odnosno ${kaznaI2}`}.
                        `}
                        </>
                        :
                        <>                       
                          Prilikom procjene opasnosti od bjekstva treba, u svakom slučaju, {(I1tezina <= 1 && I2tezina <= 1) ? `imati u vidu da ${potraživanom} od strane nadležnog suda države moliteljice, objektivno,
                          nisu izrečene naročito teške krivičnopravne sankcije već se radi o izvršenju ${izvrsenjekazna1.izrecenakazna === izvrsenjekazna2?.izrecenakazna ?
                            `${(izvrsenjekazna1.preostalidio !== undefined || izvrsenjekazna2?.preostalidio !== undefined) ?
                                                      `${kaznaI1} odnosno ${kaznaI2}`:
                                                      `kazni zatvora u trajanju od po ${izvrsenjekazna1.izrecenakazna}`}` : `${kaznaI1} odnosno ${kaznaI2}`}.
                          ${" "}Ove činjenice imaju direktan uticaj na, eventualni, motiv za preduzimanje radnji sa ciljem
                          bjekstva kada je ${potraživani} u procesnoj situaciji u kojoj se nalazi sasvim sigurno ${svjestan} činjenice da je za raspisana međunarodna
                          potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen` : `imati u vidu procesnu situaciju u kojoj se nalazi ${potraživani} odnosno, 
                          činjenicu da je sasvim sigurno ${svjestan} da je raspisana međunarodna potraga temeljem koje bi svaki ${njegov} pokušaj prelaska državne granice bio osujećen`}.
                        </>}
                  </>


            }


          </>}  {/* izvrsenjeInfo 2 */}


      </>
    )
  } //ItezinaizreceneMZ


  const PItezinaMZ = () => {


    // Vrijednost zaprijećene:      5,  10, 20 100   ..., ozbiljna, naročito teška, doživotni
    // Vrijednost izrečene:         1,5- 3 - 5, 8     ..., ozbiljna, visoka,        naročito visoka


    //Odredi vrijednosti/prioritet
    const P1prioritet = P1tezina === false ? 0 : P1tezina <= 3 ? 1 : P1tezina <= 5 ? 2 : P1tezina <= 10 ? 3 : P1tezina <= 20 ? 4 : 5;
    const P2prioritet = P2tezina === false ? 0 : P1tezina <= 3 ? 1 : P2tezina <= 5 ? 2 : P2tezina <= 10 ? 3 : P2tezina <= 20 ? 4 : 5;
    const I1prioritet = I1tezina === false ? 0 : I1tezina <= 1 ? 1.1 : I1tezina <= 1.5 ? 2 : I1tezina <= 3 ? 3 : I1tezina <= 5 ? 4 : 5;
    const I2prioritet = I1tezina === false ? 0 : I1tezina <= 1 ? 1.1 : I1tezina <= 1.5 ? 2 : I1tezina <= 3 ? 3 : I1tezina <= 5 ? 4 : 5;

    const Psum = P1prioritet + P2prioritet
    const Isum = I1prioritet + I2prioritet

    return (
      <>
        {(Psum > Isum) || (Psum === Isum) ?
          <>
            <PtezinazaprijeceneMZ prioritet={1} />{" "}
            <ItezinaizreceneMZ prioritet={2} />
          </>
          :
          <>
            <ItezinaizreceneMZ prioritet={1} />{" "}
            <PtezinazaprijeceneMZ prioritet={2} />
          </>
        }
      </>
    )
  } // PItezinaMZ


  return (
    <>

      {(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO" || vrstaOdluke === "EKS") &&
        <>
          {procesuiranje && !izvrsenje
            ?
            <>
              <p style={pJ}><Ptezinazaprijecene />
                <Pvrijemedostupnosti /></p>
              <p style={pJ}><OtezavajuceLicne /></p>
            </>

            :
            !procesuiranje && izvrsenje
              ?
              <>
                <p style={pJ}><Itezinaizrecene />
                  <Ivrijemedostupnosti /></p>
                <p style={pJ}><Iosudauodsutnosti /></p>
                <p style={pJ}><OtezavajuceLicne /></p>
              </>
              :
              procesuiranje && izvrsenje
                ?
                <>
                  <p style={pJ}><PItezina />
                    <PIvrijemedostupnosti /></p>
                  <p style={pJ}><Iosudauodsutnosti /></p>
                  <p style={pJ}><OtezavajuceLicne /></p>
                </>
                :
                <Alert variant="warning alertV2">
                  Odaberite <strong>razlog izručenja</strong>.
                </Alert>
          }

        </>}

      {vrstaOdluke === "MZ" && <>
        <p style={pJ}>Međutim, analizom svih utvrđenih okolnosti na strani {potraživanog} Sud zaključuje da nisu ispunjene zakonske pretpostavke za određivanje privremenog pritvora {potraživanom} u smislu odredbe člana 37. ZMPP-a jer nisu utvrđene okolnosti koje ukazuju na postojanje opasnosti da će {potraživani} pobjeći ili se sakriti, a u mjeri koja bi činila opravdanim privremeno pritvaranje {potraživanog}.</p>
        <p style={pJ}>Sud je zaključio da određivanje privremenog pritvora u odnosu na {potraživanogg} nije nužna mjera za osiguranje {njegovog} prisustva u ekstradicionom postupku i realizaciju eventualne odluke o izručenju ukoliko, konačnom odlukom, isto bude dopušteno.</p>
        {procesuiranje && !izvrsenje
          ?
          <>
            <p style={pJ}>
              {InoUpucenost}
              {" "}{Veznik} konkretnih činjenica i okolnosti koje ukazuju da se {potraživani} skrivao
              i/ili očigledno dao u bjekstvo kako bi osujetio {procesuiranjeInfo.length === 1 ? `krivični postupak koji se protiv ${njega} vodi` : `krivične postupke koji se protiv ${njega} vode`}
              {" "}pred pravosudnim organima {DRZMOLITELJICA.gen || "__________"}.
              {" "}<PvrijemedostupnostiMZ />
              {" "}<BiHupucenost/>
              {" "}<PtezinazaprijeceneMZ/>
          </p>
          </>

          :
          !procesuiranje && izvrsenje
            ?
            <>
              <p style={pJ}>
                {InoUpucenost}
                {" "}{Veznik} konkretnih činjenica i okolnosti koje ukazuju da se {potraživani} skrivao
                i/ili očigledno dao u bjekstvo kako bi osujetio {izvrsenjeInfo.length === 1 ? `postupak izvršenja koji se protiv ${njega} vodi` : `postupke izvršenja koji se protiv ${njega} vode` }
                {" "}pred pravosudnim organima {DRZMOLITELJICA.gen || "__________"}.
                {" "}<IvrijemedostupnostiMZ />
                {" "}<BiHupucenost/>
                {" "}<ItezinaizreceneMZ/>

              </p>
            </>
            :
            procesuiranje && izvrsenje
              ?
              <>
                <p style={pJ}>{InoUpucenost}
                  {" "}{Veznik} konkretnih činjenica i okolnosti koje ukazuju da se {potraživani} skrivao
                  i/ili očigledno dao u bjekstvo kako bi osujetio {procesuiranjeInfo.length === 1 ? `krivični postupak` : `krivične postupke`},
                  odnosno {izvrsenjeInfo.length === 1 ? `postupak izvršenja koji se protiv ${njega} vodi` : `postupke izvršenja koji se protiv ${njega} vode`}
                  {" "}pred pravosudnim organima {DRZMOLITELJICA.gen || "__________"}.
                  {" "}<PIvrijemedostupnostiMZ />
                  {" "}<BiHupucenost/>
                  {" "}<PItezinaMZ/>
                </p>
             
              </>
              :
              <Alert variant="warning alertV2">
                Odaberite <strong>razlog izručenja</strong>.
              </Alert>
        }

        <p style={pJ}>Kod ovakvog
          stanja stvari, Sud  je zaključio da u konkretnom postupku izručenja nije neophodno privremeno pritvaranje {potraživanog} kako bi se
          proveo ekstradicioni postupak i efikasno sprovelo izručenje, te da se ciljevi međunarodne pravne pomoći mogu efikasno osigurati
          blažim mjerama.</p>
      </>}


    </>
  )
}





const Okolnosti = () => {
  const { vrstaOdluke, kontrolisiCanvas, OkolnostiCanvas, procesuiranje, izvrsenje} = useGlobalContext()

  return (
    <>
      <div className="d-grid gap-2">
         <Button
        variant="outline-secondary obavezno mb-2 pt-3 pb-3"
        onClick={(e) => kontrolisiCanvas("CANVAS_OKOLNOSTI_OTVORI")}
      >
        {vrstaOdluke === "MZ" ? "Okolnosti (olakšavajuće)" : "Okolnosti (opasnost od bjekstva)"} 
      </Button>

      </div>
     
      <Offcanvas
        style={{ width: "650px" }}
        show={OkolnostiCanvas}
        onHide={(e) => kontrolisiCanvas("CANVAS_OKOLNOSTI_ZATVORI")}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Okolnosti konkretnog slučaja (opasnost od bjekstva)</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
            {(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" || vrstaOdluke === "PRO" || vrstaOdluke === "EKS") &&
            <>
               {procesuiranje && !izvrsenje && <> <Col><Okolnostilicne /></Col></>}
              {!procesuiranje && izvrsenje && <> <Col> <Okolnostilicne /></Col></>}
              {procesuiranje && izvrsenje && <> <Col><Okolnostilicne /></Col></>}
            </>}
           
           {vrstaOdluke === "MZ" && 
           <>
           <OkolnostiMZolaksavajuce/>
           </>}

            </Row>

            <Row>

            </Row>

          </Container>
        </Offcanvas.Body>
      </Offcanvas>

    </>
  )
}


export { Obrazlozenjeopasnost };
export default Okolnosti;