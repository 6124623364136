/*
1. Nominativ    : ko? šta Išto?
2. Genitiv      : (od) koga? (od) čega?
3. Dativ        : kome? čemu?
4. Akuzativ     : koga?šta Išto?
5. Vokativ      : dozivanje (oj! hej!)
6. Instrumental : (s) kim? (s) čim?
7. Lokativ      : (o) kome? (o) čemu?

Iznimka su ženska prezimena koja završavaju na -a.
Npr. Dao sam poklon Ivani Kesoviji. Razgovarao sam s Julijom Šimlešom.
*/
const Deklinacija = (imeiprezime, spol = "m") => {
    
  const razdvoji = imeiprezime?.trim().split(/\s+/);
  let ime = "";
  let prezime1 = "";
  let prezime2 = "";

  
  if (razdvoji.length === 1) {
    // console.log("samo ime");
       [ime] = razdvoji;
     }
 else if (razdvoji.length === 2) {
 // console.log("jedno prezime");
    [ime, prezime1] = razdvoji;
  } else if (razdvoji.length === 3) {
 // console.log("dva prezimena");
    [ime, prezime1, prezime2] = razdvoji;
  } 
  
  let imelica = {nom: imeiprezime, gen: "", dat: "" };
  let prezimeGen;
  let prezimeDat;
  const pravila = [    //nom, gen, dativ
  ["a", "e", "i"],
  ["no", "ne", "ni"],
  ["na", "nu", "ni"],
  ["ć", "ća", "ću"],
  ["ko", "ka", "ku"],
  ["c", "ca", "cu"],
  ["o", "e", "i"],
  ["io", "ia", "iu"],
  ["lo", "la", "lu"],
  ];
  
  const provuciIme = (ime, pravila) => {
    let najboljePravilo = null;
    
    for (let i = 0; i < pravila.length; i++) {
      let kraj = pravila[i][0];
      let replaceGen = pravila[i][1];
      let replaceDat = pravila[i][2];
  
      if (ime.endsWith(kraj)) {
        if (!najboljePravilo || kraj.length > najboljePravilo[0].length) {
          najboljePravilo = [kraj, replaceGen, replaceDat];
        }
      }
    }
  
    if (najboljePravilo) {
      let imeGen = ime.slice(0, ime.length - najboljePravilo[0].length) + najboljePravilo[1];
      let imeDat = ime.slice(0, ime.length - najboljePravilo[0].length) + najboljePravilo[2];
      return { imeGen, imeDat };
    } else {
      let imeGen = ime + "a";
      let imeDat = ime + "u";
      return { imeGen, imeDat };
    }
  }
  
  const provuciPrezime = (prezime, pravila) => {
    let primjenjivaPravila = [];
    // Prezime
    for (let i = 0; i < pravila.length; i++) {
      let kraj = pravila[i][0];
      if (prezime.endsWith(kraj)) {
        primjenjivaPravila.push(pravila[i]);
      }
    }
    if (primjenjivaPravila.length > 0) {
      // sort po dužini pogođenog sufixa/kraja, Descending order
      primjenjivaPravila.sort((a, b) => b[0].length - a[0].length);
      let replaceGen = primjenjivaPravila[0][1];
      let replaceDat = primjenjivaPravila[0][2];
      let kraj = primjenjivaPravila[0][0];
      if (spol === "m") {
        prezimeGen = prezime.slice(0, prezime.length - kraj.length) + replaceGen;
        prezimeDat = prezime.slice(0, prezime.length - kraj.length) + replaceDat;
        return {prezimeGen, prezimeDat};
      } else if (spol === "ž") {
        prezimeGen = prezime;
        prezimeDat = prezime;
        return {prezimeGen, prezimeDat};
      }
    } else {
      if (spol === "m") {
        prezimeGen = prezime + "a";
        prezimeDat = prezime + "u";
        return {prezimeGen, prezimeDat};
      } else if (spol === "ž") {
        prezimeGen = prezime;
        prezimeDat = prezime;
        return {prezimeGen, prezimeDat};
      }
    }
  }
  
  if (razdvoji.length === 1) {
    let iG = provuciIme(ime, pravila).imeGen
    let iD = provuciIme(ime, pravila).imeDat
    
   imelica.gen = iG + " __________";
   imelica.dat = iD + " __________";
   
   } else if (razdvoji.length === 2) {
   let iG = provuciIme(ime, pravila).imeGen
   let iD = provuciIme(ime, pravila).imeDat
   let pG= provuciPrezime(prezime1, pravila).prezimeGen
   let pD = provuciPrezime(prezime1, pravila).prezimeDat
  imelica.gen = iG + " " + pG
  imelica.dat = iD + " " + pD
  
  } else if (razdvoji.length === 3) {
   let iG = provuciIme(ime, pravila).imeGen
   let iD = provuciIme(ime, pravila).imeDat
   let pG1 = provuciPrezime(prezime1, pravila).prezimeGen
   let pD1= provuciPrezime(prezime1, pravila).prezimeDat
   let pG2 = provuciPrezime(prezime2, pravila).prezimeGen
   let pD2= provuciPrezime(prezime2, pravila).prezimeDat
   
   imelica.gen = iG + " " + pG1 + " " + pG2
   imelica.dat = iD + " " + pD1 + " " + pD2
   
  }
  
   return imelica;
  
  }
  
  export default Deklinacija;