import React, { useState } from 'react';
import { useGlobalContext } from '../../context'

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import axios from 'axios';
import Upozorenje2 from './Upozorenje2';

//inputChange('IMELICA', e.target.value)

function DodajTuzioca() {
  const { inputChange } = useGlobalContext();

  const [alert, setAlert] = useState({ show: false, tip: '' });
  const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    setAlert({ show, tip, pozicija, poruka });
  };

  const [ime, setIme] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spol, setSpol] = useState('m');
  const slovaspacecrtica = /^[A-Za-zšđčćžŠĐČĆŽ\s-]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      axios.get('https://liste-odluka.onrender.com/tuzioci')
        .then(response => {
          const noviTuzilac = { ime, spol};
          axios.post('https://liste-odluka.onrender.com/tuzilac', noviTuzilac)
            .then(response => {
              console.log('Dodat novi tuzilac:', response.data);
              prikaziUpozorenje(true, 'success', 'inline', 'Uspješno ste dodali novog tužioca.'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              inputChange("updejtujStranke");
              setIme('');
              setValidated(false);
              setIsLoading(false);
              setSpol('m');
            })
            .catch(error => {
              console.error('Greška pri dodavanju novog tuzioca:', error);
              prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri dodavanju novog tuzioca!'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              setIsLoading(false)
            });
        })
        .catch(error => {
          console.error('Greška pri čitanju tuzilaca:', error);
          prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri komunikaciji sa serverom!');
          setIsLoading(false)
        });
    }
  }

  const handleImeChange = (event) => {
    if (event.target.value === '' || slovaspacecrtica.test(event.target.value)) {
      setIme(event.target.value);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="8" controlId="validationCustom01">
            <Form.Control
              required
              type="text"
              placeholder="Ime i prezime tužioca"
              value={ime} onChange={handleImeChange}
              isInvalid={validated && ime === ''}
            />
            <Form.Control.Feedback type="invalid">
              Unesite ispravno ime i prezime u formatu: Ime Prezime
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom02">
          <ButtonGroup className='float-end'>
            <ToggleButton
              id="m"
              type="radio"
              variant={spol === "m" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="m"
              checked={spol === "m"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              muško
            </ToggleButton>
             <ToggleButton
              id="ž"
              type="radio"
              variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="ž"
              checked={spol === "ž"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              žensko
            </ToggleButton>
          </ButtonGroup>
            
          </Form.Group>

        </Row>

        <Button type="submit" className="primary float-end" size="sm">
          {isLoading ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" "}Učitava se...
            </>
            :
            "Dodaj tužioca"}
        </Button>
      </Form>
      <br /> <br />
      {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}
    </>
  );
}

function DodajZapisnicara() {
  const { inputChange } = useGlobalContext();
  const [alert, setAlert] = useState({ show: false, tip: '' });
  const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    setAlert({ show, tip, pozicija, poruka });
  };

  const [ime, setIme] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spol, setSpol] = useState('ž');
  const slovaspacecrtica = /^[A-Za-zšđčćžŠĐČĆŽ\s-]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      axios.get('https://liste-odluka.onrender.com/zapisnicari')
        .then(response => {
          const noviZapisnicar = { ime, spol};
          axios.post('https://liste-odluka.onrender.com/zapisnicar', noviZapisnicar)
            .then(response => {
              console.log('Dodat novi zapisničar:', response.data);
              prikaziUpozorenje(true, 'success', 'inline', 'Uspješno ste dodali novog zapisničara.'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              inputChange("updejtujStranke");
              setIme('');
              setValidated(false);
              setIsLoading(false)
            })
            .catch(error => {
              console.error('Greška pri dodavanju novog zapisničara:', error);
              prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri dodavanju novog zapisničara!'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              setIsLoading(false)
            });
        })
        .catch(error => {
          console.error('Greška pri čitanju zapisničara:', error);
          prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri komunikaciji sa serverom!');
          setIsLoading(false)
        });
    }
  }

  const handleImeChange = (event) => {
    if (event.target.value === '' || slovaspacecrtica.test(event.target.value)) {
      setIme(event.target.value);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="8" controlId="validationCustom01">
            <Form.Control
              required
              type="text"
              placeholder="Ime i prezime zapisničara"
              value={ime} onChange={handleImeChange}
              isInvalid={validated && ime === ''}
            />
            <Form.Control.Feedback type="invalid">
              Unesite ispravno ime i prezime u formatu: Ime Prezime
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom02">
          <ButtonGroup className='float-end'>
            <ToggleButton
              id="m"
              type="radio"
              variant={spol === "m" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="m"
              checked={spol === "m"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              muško
            </ToggleButton>
             <ToggleButton
              id="ž"
              type="radio"
              variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="ž"
              checked={spol === "ž"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              žensko
            </ToggleButton>
          </ButtonGroup>
            
          </Form.Group>

        </Row>

        <Button type="submit" className="primary float-end" size="sm">
          {isLoading ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" "}Učitava se...
            </>
            :
            "Dodaj zapisničara"}
        </Button>
      </Form>
      <br /> <br />
      {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}
    </>
  );
}

function DodajSudiju() {
  const { inputChange } = useGlobalContext();
  const [alert, setAlert] = useState({ show: false, tip: '' });
  const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    setAlert({ show, tip, pozicija, poruka });
  };

  const [ime, setIme] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spol, setSpol] = useState('m');
  const slovaspacecrtica = /^[A-Za-zšđčćžŠĐČĆŽ\s-]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      axios.get('https://liste-odluka.onrender.com/sudije')
        .then(response => {

          const noviSudija = { ime, spol};
          axios.post('https://liste-odluka.onrender.com/sudija', noviSudija)
            .then(response => {
              console.log('Dodat novi sudija:', response.data);
              prikaziUpozorenje(true, 'success', 'inline', 'Uspješno ste dodali novog sudiju.'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              inputChange("updejtujStranke");
              setIme('');
              setValidated(false);
              setIsLoading(false)
            })
            .catch(error => {
              console.error('Greška pri dodavanju novog sudije:', error);
              prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri dodavanju novog sudije!'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              setIsLoading(false)
            });
        })
        .catch(error => {
          console.error('Greška pri čitanju sudija:', error);
          prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri komunikaciji sa serverom!');
          setIsLoading(false)
        });
    }
  }

  const handleImeChange = (event) => {
    if (event.target.value === '' || slovaspacecrtica.test(event.target.value)) {
      setIme(event.target.value);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="8" controlId="validationCustom01">
            <Form.Control
              required
              type="text"
              placeholder="Ime i prezime sudije"
              value={ime} onChange={handleImeChange}
              isInvalid={validated && ime === ''}
            />
            <Form.Control.Feedback type="invalid">
              Unesite ispravno ime i prezime u formatu: Ime Prezime
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom02">
          <ButtonGroup className='float-end'>
            <ToggleButton
              id="m"
              type="radio"
              variant={spol === "m" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="m"
              checked={spol === "m"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              muško
            </ToggleButton>
             <ToggleButton
              id="ž"
              type="radio"
              variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="ž"
              checked={spol === "ž"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              žensko
            </ToggleButton>
          </ButtonGroup>
            
          </Form.Group>

        </Row>
        <Button type="submit" className="primary float-end" size="sm">
          {isLoading ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" "}Učitava se...
            </>
            :
            "Dodaj sudiju"}
        </Button>
      
      </Form>
      <br />
      <br />
      {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}
    </>
  );
}


function DodajAdvokata() {
  const { inputChange } = useGlobalContext();
  const [alert, setAlert] = useState({ show: false, tip: '' });
  const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    setAlert({ show, tip, pozicija, poruka });
  };

  const [ime, setIme] = useState('');
  const [grad, setGrad] = useState('');
  const [gradgen, setGradgen] = useState('');
  const [adresa, setAdresa] = useState('');
  const [kontakt, setKontakt] = useState('');
  const [spol, setSpol] = useState('m');

  const slovaspacecrtica = /^[A-Za-zšđčćžŠĐČĆŽ\s-]+$/;
  const slovaspacecrticebrojevimail = /^[A-Za-zšđčćžŠĐČĆŽ\s()-,@/0-9]+$/;

  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      axios.get('https://liste-odluka.onrender.com/advokati')
        .then(response => {
          const noviAdvokat = { ime, adresa, kontakt, grad, gradgen, spol};
          axios.post('https://liste-odluka.onrender.com/advokat', noviAdvokat)
            .then(response => {
              prikaziUpozorenje(true, 'success', 'inline', 'Uspješno ste dodali novog advokata.'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              inputChange("updejtujStranke");
              setIme('');
              setGrad('');
              setGradgen('');
              setAdresa('');
              setKontakt('');
              setSpol('m');
              setValidated(false);
              setIsLoading(false)
            })
            .catch(error => {
              console.error('Greška pri dodavanju novog advokata:', error);
              prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri dodavanju novog advokata!'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              setIsLoading(false)
            });
        })
        .catch(error => {
          console.error('Greška pri čitanju advokata:', error);
          prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri komunikaciji sa serverom!');
          setIsLoading(false)
        });
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'ime':
        if (value === '' || slovaspacecrtica.test(value)) {
          setIme(value);
        }
        break;
      case 'grad':
        if (value === '' || slovaspacecrtica.test(value)) {
          setGrad(value);
        }
        break;
      case 'gradgen':
        if (value === '' || slovaspacecrtica.test(value)) {
          setGradgen(value);
        }
        break;
      case 'kontakt':
        if (value === '' || slovaspacecrticebrojevimail.test(value)) {
          setKontakt(value);
        }
        break;
      case 'adresa':
        if (value === '' || slovaspacecrticebrojevimail.test(value)) {
          setAdresa(value);
        }
        break;
      default:
        break;
    }
  };


  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='mb-2'>
          <Form.Group as={Col} md="8" controlId="validationCustom01">
            <Form.Control
              required
              type="text"
              name="ime"
              placeholder="Ime i prezime advokata"
              value={ime} onChange={handleChange}
    //          isInvalid={validated && ime === ''}
            />
            <Form.Control.Feedback type="invalid" className='mb-1'>
              Unesite ispravno ime i prezime u formatu: Ime Prezime
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="validationCustom02">
          <ButtonGroup className='float-end'>
            <ToggleButton
              id="m"
              type="radio"
              variant={spol === "m" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="m"
              checked={spol === "m"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              muško
            </ToggleButton>
             <ToggleButton
              id="ž"
              type="radio"
              variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
              name="radio"
              value="ž"
              checked={spol === "ž"}
              onChange={(e) => setSpol(e.currentTarget.value)}
            >
              žensko
            </ToggleButton>
          </ButtonGroup>
            
          </Form.Group>

       

          <br /><br />
          <Form.Group as={Col} md="6" controlId="validationCustom03">
            <Form.Control
              required
              type="text"
              name="grad"
              placeholder="Grad"
              value={grad} onChange={handleChange}
       //       isInvalid={validated && grad === ''}
            />
            <Form.Control.Feedback type="invalid" className='mb-1'>
              Unesite grad sjedišta advokata.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Control
            required
            type="text"
            name="gradgen"
            placeholder="Naziv grada u genitivu npr. Sarajeva"
            value={gradgen}
            onChange={handleChange}
            isInvalid={validated && gradgen === ''}
          />
          <Form.Control.Feedback type="invalid" className='mb-1'>
            Unesite naziv grada u genitivu npr. "<b>Sarajeva</b>".
          </Form.Control.Feedback>
        </Form.Group>
        <br /><br />
        <Form.Group as={Col} md="12">
          <Form.Control
            type="text"
            name="adresa"
            placeholder="Adresa sjedišta advokata"
            value={adresa}
            onChange={handleChange}
          />
        </Form.Group>
      <br/>
      <br />
      <Form.Group as={Col} md="12" >
        <Form.Control
          type="text"
          name="kontakt"
          placeholder="Kontakt npr: (tel) 033-xxx-xxx, (mob) 06x-xxx-xxx, (e-mail) ..."
          value={kontakt} onChange={handleChange}
                  />
      </Form.Group>

        </Row>
        <Button type="submit" className="primary float-end" size="sm"
      
      >
        {isLoading ?
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {" "}Učitava se...
          </>
          :
          "Dodaj advokata"}
      </Button>
      </Form>
      <br />
      <br/>
      {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}
    </>
  );
}

function DodajGresku() {
  const { inputChange } = useGlobalContext();
  const [alert, setAlert] = useState({ show: false, tip: '' });
  const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline', poruka = '') => {
    setAlert({ show, tip, pozicija, poruka });
  };

  const [ime, setIme] = useState('');
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opis, setOpis] = useState('');
  const slovaspacecrtica = /^[A-Za-zšđčćžŠĐČĆŽ\s-]+$/;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setIsLoading(true)
      axios.get('https://liste-odluka.onrender.com/greske')
        .then(response => {

          const novaGreska = { ime, opis, app: "EKS"};
          axios.post('https://liste-odluka.onrender.com/greska', novaGreska)
            .then(response => {
              console.log('Prijavljena greška:', response.data);
              prikaziUpozorenje(true, 'success', 'inline', 'Uspješno ste prijavili grešku. Hvala Vam!.'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              inputChange("updejtujStranke");
              setIme('');
              setOpis('');
              setValidated(false);
              setIsLoading(false)
            })
            .catch(error => {
              console.error('Greška pri upisivnaju:', error);
              prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri upisivanju!'); //show (true/false), tip (danger, warning...) pozicija (fixed/inline)
              setIsLoading(false)
            });
        })
        .catch(error => {
          console.error('Greška pri učitavanju:', error);
          prikaziUpozorenje(true, 'danger', 'inline', 'Greška pri komunikaciji sa serverom!');
          setIsLoading(false)
        });
    }
  }

  const handleImeChange = (event) => {
    if (event.target.value === '' || slovaspacecrtica.test(event.target.value)) {
      setIme(event.target.value);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col}  controlId="validationCustom01" className='mb-3'>
            <Form.Control
              required
              type="text"
              placeholder="Ime i prezime"
              spellCheck="false"
              value={ime} onChange={handleImeChange}
              isInvalid={validated && ime === ''}
            />
            <Form.Control.Feedback type="invalid">
              Unesite ispravno ime i prezime u formatu: Ime Prezime
            </Form.Control.Feedback>
          </Form.Group>
        
          
          <Form.Group as={Col} md={12}  controlId="validationCustom02" className='mb-3'>
            <FloatingLabel controlId="greška-opis" label="Opis">
              <Form.Control
                  as="textarea"
                  placeholder="Opišite problem"
                  spellCheck="false"
                  style={{ height: '100px' }}
                  value={opis} onChange={(e) => setOpis(e.currentTarget.value)}
                  isInvalid={validated && opis === ''}
                />
              </FloatingLabel>
      </Form.Group>
        

    

        </Row>
        <Button type="submit" className="primary float-end" size="sm">
          {isLoading ?
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              {" "}Učitava se...
            </>
            :
            "Pošalji"}
        </Button>
      
      </Form>
      <br />
      <br />
      {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje} />}
    </>
  );
}

export { DodajTuzioca, DodajZapisnicara, DodajSudiju, DodajAdvokata, DodajGresku };
