import React, { useState } from 'react'
import { useGlobalContext } from '../../context'

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


/*
 licnipodaci: {
        otac: "__________",
        majka: "__________",
        prezimemajke: "__________",
        datumrođenja: "__________",
        mjestorođenja: "__________",
        nacionalnost: "__________",
        državljanstvo: "__________",
        zanimanje: "__________",
        zaposlen: false,
        firma: "__________",
        oženjen: false,
        djeca: false,
        brojdjece: "__________"
    },
*/

const Dokumenti = (props) => {
    const { licnidokumenti } = useGlobalContext()

    let postojecidokumenti = licnidokumenti.filter(dokument => (dokument.pasos === true || dokument.licna === true) && dokument.uvid === true)
    console.log(postojecidokumenti)
    return (
        <>
            {props.kod === "brPrikaz"
                &&
                <>
                    {postojecidokumenti.map((dokument, index) => {
                        const { licna, brojdokumenta, organizdavanja, drzavaizdavanja, rokvazenja } = dokument;

                        return (
                            <strong key={index}>-{" "}
                                {licna === true ? `ličnu kartu,` : `putnu ispravu (pasoš),`} broj: {brojdokumenta || "__________"},
                                {licna === true ? ` izdatu od strane ${organizdavanja === "" ? `nadležnog organa ${drzavaizdavanja?.gen || "__________"}` : `${organizdavanja}, ${drzavaizdavanja?.ime || "__________"}`}` : ` izdat od strane nadležnog organa ${drzavaizdavanja?.gen || "__________"}`} sa rokom važenja do {rokvazenja || "__________"};
                                <br />
                            </strong>
                        );
                    })}

                </>
            }

            {props.kod === "inlinePrikaz"
                &&
                <>
                    {postojecidokumenti.map((dokument, index) => {
                        const { licna, brojdokumenta, organizdavanja, drzavaizdavanja, rokvazenja } = dokument;

                        return (
                            <span key={index}>
                                {postojecidokumenti.length === 1
                                    ?
                                    ``
                                    :
                                    postojecidokumenti.length > 1 && index === 0
                                        ?
                                        ``
                                        :
                                        postojecidokumenti.length > 1 && index !== (postojecidokumenti.length - 1)
                                            ?
                                            `, `
                                            :
                                            postojecidokumenti.length > 1 && index === (postojecidokumenti.length - 1)
                                                ?
                                                ` kao i `
                                                : ``}

                                {licna === true ? `ličnu kartu ${drzavaizdavanja?.gen || "__________"}, broj: ` : `putnu ispravu (pasoš) ${drzavaizdavanja?.gen || "__________"}, broj: `}
                                {brojdokumenta || "__________"},
                                {licna === true ? ` izdatu od strane ${organizdavanja === "" ? `nadležnog organa ${drzavaizdavanja?.gen || "__________"}` : `${organizdavanja}`}` : ` izdat od strane nadležnog organa ${drzavaizdavanja?.gen || "__________"}`}
                                {" "}(sa rokom važenja do {rokvazenja || "__________"})
                            </span>
                        );
                    })}

                </>
            }



        </>

    )
}

const LPodaci = (props) => {

    const { IMELICA, licezensko, licnipodaci, LPizmjena, licnidokumenti, datumlisenja, organLisenja, pJ, državljanin,
        potraživanog } = useGlobalContext()

    let postojecidokumenti = licnidokumenti.filter(dokument => (dokument.pasos === true || dokument.licna === true) && dokument.uvid === true)

    const [LPinputi, setLPinputi] = useState({
        otac: false,
        majka: false,
        prezimemajke: false,
        datumrođenja: false,
        mjestorođenja: false,
        nastanjen: false,
        nacionalnost: false,
        zanimanje: false,
        firma: false,
        brojdjece: false
    });

    const LPinputiprikaz = (id) => {
        let input = id.substring(2)
        let inputStatus = LPinputi[input]


        setLPinputi({ ...LPinputi, [input]: !LPinputi[input] });

        console.log(input + ": " + LPinputi.otac)

    };

    const onKeyDown = (event, input) => {
        if (event.key === "Enter" || event.key === "Escape") {
            event.target.blur();
            setLPinputi({ ...LPinputi, [input]: !LPinputi[input] });
        }
    }

    const onBlur = (event, input) => {
        setLPinputi({ ...LPinputi, [input]: !LPinputi[input] });
    }

    return <>

        {props.kod === "LPunos"
            &&
            <>
                <p style={{ lineHeight: "35px" }} >
                    <strong>{IMELICA.ime.toUpperCase() || "____________________________"}</strong>, {licezensko ? "kći" : "sin"} {LPinputi.otac ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.otac}
                        onChange={(e) => LPizmjena("otac", e)} onKeyDown={(event) => onKeyDown(event, "otac")} onBlur={(event) => onBlur(event, "otac")} />
                        :
                        <span id='LPotac' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                            {licnipodaci.otac || "____________________________"}
                        </span>} i majke {LPinputi.majka ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.majka}
                            onChange={(e) => LPizmjena("majka", e)} onKeyDown={(event) => onKeyDown(event, "majka")} onBlur={(event) => onBlur(event, "majka")} />
                            :
                            <span id='LPmajka' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                                {licnipodaci.majka || "____________________________"}</span>},
                    djevojačko prezime majke {LPinputi.prezimemajke ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.prezimemajke}
                        onChange={(e) => LPizmjena("prezimemajke", e)} onKeyDown={(event) => onKeyDown(event, "prezimemajke")} onBlur={(event) => onBlur(event, "prezimemajke")} />
                        :
                        <span id='LPprezimemajke' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                            {licnipodaci.prezimemajke || "____________________________"}</span>}, {licezensko ? "rođena " : "rođen "} {LPinputi.datumrođenja ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.datumrođenja}
                                onChange={(e) => LPizmjena("datumrođenja", e)} onKeyDown={(event) => onKeyDown(event, "datumrođenja")} onBlur={(event) => onBlur(event, "datumrođenja")} />
                                :
                                <span id='LPdatumrođenja' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                                    {licnipodaci.datumrođenja || "____________________________"}
                                </span>} godine
                    u {LPinputi.mjestorođenja ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.mjestorođenja}
                        onChange={(e) => LPizmjena("mjestorođenja", e)} onKeyDown={(event) => onKeyDown(event, "mjestorođenja")} onBlur={(event) => onBlur(event, "mjestorođenja")} />
                        :
                        <span id='LPmjestorođenja' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                            {licnipodaci.mjestorođenja || "____________________________"}
                        </span>}, {licezensko ? "nastanjena" : "nastanjen"} {LPinputi.nastanjen ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.nastanjen}
                            onChange={(e) => LPizmjena("nastanjen", e)} onKeyDown={(event) => onKeyDown(event, "nastanjen")} onBlur={(event) => onBlur(event, "nastanjen")} />
                            :
                            <span id='LPnastanjen' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                                {licnipodaci.nastanjen || "____________________________"}
                            </span>}, {državljanin} {licnipodaci.državljanstvo || "____________________________"}, po nacionalnosti {LPinputi.nacionalnost ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.nacionalnost}
                                onChange={(e) => LPizmjena("nacionalnost", e)} onKeyDown={(event) => onKeyDown(event, "nacionalnost")} onBlur={(event) => onBlur(event, "nacionalnost")} />
                                :
                                <span id='LPnacionalnost' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                                    {licnipodaci.nacionalnost || "____________________________"}
                                </span>}, po zanimanju  {LPinputi.zanimanje ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.zanimanje}
                                    onChange={(e) => LPizmjena("zanimanje", e)} onKeyDown={(event) => onKeyDown(event, "zanimanje")} onBlur={(event) => onBlur(event, "zanimanje")} />
                                    :
                                    <span id='LPzanimanje' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                                        {licnipodaci.zanimanje || "____________________________"}
                                    </span>},

                    {licnipodaci.zaposlen ? <> <Button variant="light"
                        size="sm"
                        name="zaposlen"
                        checked={licnipodaci.zaposlen}
                        onClick={(e) => LPizmjena("zaposlen", e)}>
                        {licnipodaci.zaposlen ? <> {licezensko ? "zaposlena" : "zaposlen"}</> : <> {licezensko ? "nezaposlena" : "nezaposlen"}</>}
                    </Button> u {LPinputi.firma ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.firma}
                        onChange={(e) => LPizmjena("firma", e)} onKeyDown={(event) => onKeyDown(event, "firma")} onBlur={(event) => onBlur(event, "firma")} />
                        :
                        <span id='LPfirma' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                            {licnipodaci.firma || "____________________________"}
                        </span>}, </> : <> <Button variant="light"
                            size="sm"
                            name="zaposlen"
                            checked={licnipodaci.zaposlen}
                            onClick={(e) => LPizmjena("zaposlen", e)}>
                            {licnipodaci.zaposlen ? <> {licezensko ? "zaposlena" : "zaposlen"}</> : <> {licezensko ? "nezaposlena" : "nezaposlen"}</>}
                        </Button></>}

                    {licnipodaci.oženjen ? <> <Button variant="light"
                        size="sm"
                        name="oženjen"
                        checked={licnipodaci.oženjen}
                        onClick={(e) => LPizmjena("oženjen", e)}>
                        {licnipodaci.oženjen ? <> {licezensko ? "udata" : "oženjen"}</> : <> {licezensko ? "neudata" : "neoženjen"}</>}
                    </Button></> : <> <Button variant="light"
                        size="sm"
                        name="oženjen"
                        checked={licnipodaci.oženjen}
                        onClick={(e) => LPizmjena("oženjen", e)}>
                        {licnipodaci.oženjen ? <> {licezensko ? "udata" : "oženjen"}</> : <> {licezensko ? "neudata" : "neoženjen"}</>}
                    </Button></>},


                    {licnipodaci.djeca ? <> <Button variant="light"
                        size="sm"
                        name="djeca"
                        checked={licnipodaci.djeca}
                        onClick={(e) => LPizmjena("djeca", e)}>
                        {licezensko ? "majka" : "otac"}
                    </Button> {LPinputi.brojdjece ? <Form.Control autoFocus type="text" className='LPinputEditable' value={licnipodaci.brojdjece}
                        onChange={(e) => LPizmjena("brojdjece", e)} onKeyDown={(event) => onKeyDown(event, "brojdjece")} onBlur={(event) => onBlur(event, "brojdjece")} />
                        :
                        <span id='LPbrojdjece' className='LPspanEditable' onClick={(e) => { LPinputiprikaz(e.target.id); }}>
                            {licnipodaci.brojdjece || "____________________________"}
                        </span>}</> : <> <Button variant="light"
                            size="sm"
                            name="djeca"
                            checked={licnipodaci.djeca}
                            onClick={(e) => LPizmjena("djeca", e)}>
                            nema djece
                        </Button></>}, {" "}
                    {postojecidokumenti.length === 0 ? <> identitet utvrđen na osnovu kazivanja {potraživanog}</> : <> identitet utvđen uvidom u <Dokumenti kod="inlinePrikaz" /></>}
                    , <span className='u'>{licezensko ? "lišena" : "lišen"} slobode dana {datumlisenja || "____________________________"} od strane pripadnika {organLisenja.gen || "____________________________"}</span>;
                </p>
            </>
        }



        {props.kod === "LPprikaz"
            &&
            <>

                <p style={pJ}>
                    <strong>{IMELICA.ime.toUpperCase() || "_______________"}</strong>, {licezensko ? "kći" : "sin"} {licnipodaci.otac || "_______________"} i majke {licnipodaci.majka || "_______________"},
                    djevojačko prezime majke {licnipodaci.prezimemajke || "_______________"}, {licezensko ? "rođena " : "rođen "} {licnipodaci.datumrođenja || "_______________"} godine
                    u {licnipodaci.mjestorođenja || "_______________"}, {licezensko ? "nastanjena" : "nastanjen"} {licnipodaci.nastanjen || "_______________"}, <span className='u'>{državljanin} {licnipodaci.državljanstvo || "_______________"}</span>, po nacionalnosti {licnipodaci.nacionalnost || "_______________"}, po zanimanju {licnipodaci.zanimanje || "_______________"},

                    {licnipodaci.zaposlen ?
                        <>
                            {licezensko ? " zaposlena" : " zaposlen"} u {licnipodaci.firma || "_______________"},
                        </>
                        :
                        <>
                            {licezensko ? " nezaposlena," : " nezaposlen,"}
                        </>}

                    {licnipodaci.oženjen ? <>
                        {licezensko ? " udata" : " oženjen"}
                    </> : <>
                        {licezensko ? " neudata" : " neoženjen"}
                    </>},


                    {licnipodaci.djeca ? <>
                        {licezensko ? " majka" : " otac"} {licnipodaci.brojdjece || "_______________"},
                    </>
                        :
                        <> nema djece,</>}
                    {postojecidokumenti.length === 0 ? <> identitet utvrđen na osnovu kazivanja {potraživanog}</> : <> identitet utvđen uvidom u <Dokumenti kod="inlinePrikaz" /></>}
                    , <span className='u'>{licezensko ? "lišena" : "lišen"} slobode dana {datumlisenja || "_______________"} od strane pripadnika {organLisenja.gen || "_______________"}</span>;
                </p>
            </>
        }

        {props.kod === "LPprikazSkraceno"
            &&
            <>
                <p style={pJ}>
                    <strong >{IMELICA.ime.toUpperCase() || "_______________"}</strong>, {licezensko ? "kći" : "sin"} {licnipodaci.otac || "_______________"} i majke {licnipodaci.majka || "_______________"},
                    djevojačko prezime majke {licnipodaci.prezimemajke || "_______________"}, {licezensko ? "rođena " : "rođen "} {licnipodaci.datumrođenja || "_______________"} godine
                    u {licnipodaci.mjestorođenja || "_______________"}, {licezensko ? "nastanjena" : "nastanjen"} {licnipodaci.nastanjen || "_______________"}, <span className='u'>{državljanin} {licnipodaci.državljanstvo || "_______________"}</span>, po nacionalnosti {licnipodaci.nacionalnost || "_______________"},
                    {postojecidokumenti.length === 0 ? <> identitet utvrđen na osnovu kazivanja {potraživanog}</> : <> identitet utvđen uvidom u <Dokumenti kod="inlinePrikaz" /></>}
                    , <span className='u'>{licezensko ? "lišena" : "lišen"} slobode dana {datumlisenja || "_______________"} od strane pripadnika {organLisenja.gen || "_______________"}</span>;
                </p>
            </>
        }

        {props.kod === "LPprikazSkraceno2"
            &&
            <>
                <p style={pJ}>
                    <strong >{IMELICA.ime.toUpperCase() || "_______________"}</strong>, {licezensko ? "kći" : "sin"} {licnipodaci.otac || "_______________"} i majke {licnipodaci.majka || "_______________"},
                    djevojačko prezime majke {licnipodaci.prezimemajke || "_______________"}, {licezensko ? "rođena " : "rođen "} {licnipodaci.datumrođenja || "_______________"} godine
                    u {licnipodaci.mjestorođenja || "_______________"}, {državljanin} {licnipodaci.državljanstvo || "_______________"}, po nacionalnosti {licnipodaci.nacionalnost || "_______________"};
                </p>
            </>
        }





    </>


}

export default LPodaci
export { Dokumenti };