import React from 'react'
import { useGlobalContext } from '../context'

import Preuzmiodluku from './docx/Preuzmiodluku'
import ODBdodatne from './dodatneopcije/ODBdodatne'
import MZdodatne from './dodatneopcije/MZdodatne'
import EKSdodatne from './dodatneopcije/EKSdodatne'
import PROdodatne from './dodatneopcije/PROdodatne'
import BRdodatne from './dodatneopcije/BRdodatne'
import Ugovorosnovi from './dodatneopcije/Ugovorosnovi'

import Zavodopcije from './dodatneopcije/Zavodopcije';
import Okolnosti from './dodatneopcije/Okolnosti'


const Dodatneopcije = () => {
    const { vrstaOdluke } = useGlobalContext()


    return (
        <>
            <div className='sticky-top pt-2'>

                <Ugovorosnovi />

                {(vrstaOdluke === "ODB") ? <ODBdodatne /> : <></>}
                {(vrstaOdluke === "MZ") ? <><MZdodatne /><Okolnosti /></> : <></>}
                {(vrstaOdluke === "PR") ? <><Okolnosti /></> : <></>}
                {(vrstaOdluke === "PREKS") ? <><Okolnosti /></> : <></>}
                {(vrstaOdluke === "PRO") ? <><PROdodatne /> <Okolnosti /></> : <></>}
                {(vrstaOdluke === "EKS") ? <><EKSdodatne /> <Okolnosti /></> : <></>}
                {(vrstaOdluke === "BR") ? <BRdodatne /> : <></>}
                {(vrstaOdluke === "PR" || vrstaOdluke === "PRO" || vrstaOdluke === "PREKS" || vrstaOdluke === "EKS") && <Zavodopcije />}


            </div>

            <Preuzmiodluku />



        </>

    )
}

export default Dodatneopcije