import React from "react";
import { useGlobalContext } from "../context";

import DropdownButton from "react-bootstrap/DropdownButton";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Nav from "react-bootstrap/Nav";

import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

const Navigacija = () => {
  const {
    vrsteOdluka,
    izborOdluke,
    izborDopisa,
    vrstaOdluke,
    vrsteDopisa,
    vrstaDopisa,
    stranac

  } = useGlobalContext();

  const MenuItems = ({ items, index }) => {
    return (
      <>
        {items.submenu ? (
          <DropdownButton
            as={ButtonGroup}
            title={
              vrstaOdluke === ""
                ? `${items.naziv}`
                : vrstaOdluke === "PREKS"
                ? "Ekstradicioni (pojed.)"
                : "Privremeni"
            }
            id="bg-nested-dropdown"
            className="border-0"
            variant={
              vrstaOdluke === "PR" || vrstaOdluke === "PREKS"
                ? "outline-secondary active"
                : "outline-secondary"
            }
          >
            <Dropdovni submenus={items.submenu} />
          </DropdownButton>
        ) : (
          <ToggleButton
            key={index}
            id={items.naziv}
            type="radio"
            variant="outline-secondary"
            value={items.value}
            checked={vrstaOdluke === items.value}
            //  onChange={(e) => setVrstaOdluke(e.currentTarget.value)}
            onChange={(e) => izborOdluke(e.currentTarget.value)}
          >
            {items.naziv}
          </ToggleButton>
        )}
      </>
    );
  };

  const Dropdovni = ({ submenus }) => {
    return (
      <>
        {submenus.map((submenu, index) => (
          <ToggleButton
            key={index}
            id={submenu.naziv}
            type="radio"
            variant="outline-secondary d-grid gap-2 mb-1"
            style={{ borderRadius: 0 }}
            value={submenu.value}
            checked={vrstaOdluke === submenu.value}
            //  onChange={(e) => setVrstaOdluke(e.currentTarget.value)}
            onChange={(e) => izborOdluke(e.currentTarget.value)}
          >
            {submenu.naziv}
          </ToggleButton>
        ))}
      </>
    );
  };

  const DopisiMenu = () => {

    let  result =  vrsteDopisa.find((object) => object.odluka.includes(vrstaOdluke));
    let result2 = {...result};
    if("dopisi" in result2){
      result2.dopisi = result2.dopisi.filter(obj => obj.dopis !== "AMBASADA");
    }
       
    const handleClick = (val) => {
      if (val === vrstaDopisa) {
        izborDopisa(""); //Odčekiraj i poništi izabrani dopis
        console.log(vrstaDopisa)
      } else {
        izborDopisa(val);
      }
    };

    return (
      <>
{result && (
          <>
        
{stranac === "DA"
?
<>  <Nav className="mb-2 justify-content-end">
              <ToggleButtonGroup type="checkbox" value={vrstaDopisa}>
          {result.dopisi.map((dopis, index) => (
                  <ToggleButton
                    key={index}
                    id={dopis.dopis}
                    value={dopis.dopis}
                    variant="outline-secondary"
                    size="sm"
                    onClick={(e) => handleClick(dopis.dopis)}
                  >
                    {dopis.naziv}
                  </ToggleButton>
                ))}
                  </ToggleButtonGroup>
            </Nav>
</>
:
<>
<Nav className="mb-2 justify-content-end">
              <ToggleButtonGroup type="checkbox" value={vrstaDopisa}>
{result2.dopisi.map((dopis, index) => (
                  <ToggleButton
                    key={index}
                    id={dopis.dopis}
                    value={dopis.dopis}
                    variant="outline-secondary"
                    size="sm"
                    onClick={(e) => handleClick(dopis.dopis)}
                  >
                    {dopis.naziv}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Nav>
</>}
     
          </>
        )}


     
      </>
    );
  };

  /*

 {result && <>
                <Nav className="mb-2 justify-content-end">
                <ButtonGroup >
             {dopisi.map((el, index) => (
             
                <ToggleButton
                    key={index}
                    id={el.dopis}
                    type="checkbox"
                    variant="outline-secondary"
                    size='sm'
                   value={el.dopis}
                   // checked={vrstaOdluke === submenu.value}
                    //  onChange={(e) => setVrstaOdluke(e.currentTarget.value)}
                  //  onChange={(e) => izborOdluke(e.currentTarget.value)}
                  >
                    {el.naziv}
                </ToggleButton>
            ))
            }
             </ButtonGroup>
             </Nav>
            </>
             
            } 

*/

  return (
    <>
      <Nav className="mb-2 justify-content-end">
        <ButtonGroup>
          {vrsteOdluka.map((odluka, index) => {
            return <MenuItems items={odluka} key={index} />;
          })}
        </ButtonGroup>
      </Nav>

      <DopisiMenu />
    </>
  );
};

/*
const Navigacija = () => {
   const { vrsteOdluka, izborOdluke, vrstaOdluke } = useGlobalContext()

   return (
       <>
           <ButtonGroup className="mb-2">
               {vrsteOdluka.map((odluka, index) => {

                   return (
                       <ToggleButton
                           key={index}
                           id={odluka.name}
                           type="radio"
                           variant="outline-secondary"
                           value={odluka.value}
                           checked={vrstaOdluke === odluka.value}
                           //  onChange={(e) => setVrstaOdluke(e.currentTarget.value)}
                           onChange={(e) => izborOdluke(e.currentTarget.value)}>
                           {odluka.name}
                       </ToggleButton>
                   )
               })}

           </ButtonGroup>
           <br />
           <br />
           Trenutna vrsta odluke je: {vrstaOdluke}
       </>
   )
} */

export default Navigacija;
