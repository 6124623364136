

export const ugovori = [
  {
    ime: "Hrvatska",
    kod: "HR",
    naziv: "Ugovor između Bosne i Hercegovine i Republike Hrvatske o izručenju",
    tip: "ugovor", // ugovor/konvencija
    datum: "28.11.2012. godine",
    stupanje: "06.03.2014. godine",
    sl: "(„Službeni glasnik BiH“ - Međunarodni ugovori broj: 22/12 , privremena primjena od dana potpisivanja)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      izrucenjevlastitih: { status: "POJEDINAKD", komentar: "", 
                            clanovi: [
                              {clan: "7. stav 1.", vrsta: "P", citat: "Izručenje vlastitih državljana u svrhu krivičnog gonjenja dopustit će se ako su ispunjeni uslovi propisani ovim Ugovorom za krivična djela organiziranog kriminala, korupcije i pranja novca za koja je prema pravu obje države ugovornice propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od četiri godine ili teža kazna."}, 
                              {clan: "7. stav 2.", vrsta: "I", citat: "Izručenje vlastitih državljana u svrhu izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode dopustit će se ako su ispunjeni uslovi propisani ovim Ugovorom za krivična djela organiziranog kriminala, korupcije i pranja novca koja su kažnjiva prema pravu obje države ugovornice, ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode, odnosno njihov ostatak koji se treba izvršiti iznosi najmanje dvije godine." },
                              {clan: "8. stav 1.", vrsta: "P", citat: "Izručenje vlastitih državljana u svrhu krivičnog gonjenja dopustit će se ako su ispunjeni uslovi propisani ovim Ugovorom, osim za krivična djela iz člana 7. ovog Ugovora, i za teška krivična djela za koja se može izreći kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od deset godina ili teža kazna." },
                              {clan: "8. stav 2.", vrsta: "I", citat: "Izručenje vlastitih državljana u svrhu izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode dopustit će se ako su ispunjeni uslovi propisani ovim Ugovorom za krivična djela iz stava (1) ovog člana, ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode, odnosno njihov ostatak koji se treba izvršiti iznosi najmanje pet godina." } 
                            ],
    }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [
        "član 7. stav 1. - <strong>krivično gonjenje</strong> - (krivična djela organiziranog kriminala, korupcije i pranja novca) - ako je prema pravu obje države ugovornice propisana kazna zatvora koja uključuje oduzimanje slobode u trajanju od četiri godine ili teža kazna",
        "član 7. stav 2. - <strong>izvršenje kazne</strong> - (krivična djela organiziranog kriminala, korupcije i pranja novca) - ako trajanje kazne zatvora/ostatak koji se treba izvršiti iznosi najmanje dvije godine",
        "član 8. stav 1. - <strong>krivičnog gonjenje</strong> - (druga teška krivična djela) - ako se radi o teškim krivičnim djelima za koja se može izreći kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od deset godina ili teža kazna",
        "član 8. stav 2. - <strong>izvršenje kazne</strong> - (druga teška krivična djela) - ako trajanje kazne zatvora ili odnosno njihov ostatak koji se treba izvršiti iznosi najmanje pet godina, a pod uslovom da se radi o teškim krivičnim djelima u smislu člana 8. stav 1. Ugovora"
      ],
      procesminimum: {clanovi: [{clan: "6. stav 1.", citat: "Izručenje u svrhu krivičnog gonjenja dopustit će se samo ako je za krivično djelo prema pravu obje države ugovornice propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od jedne godine ili teža kazna."}],
                       opis: "propisana kazna zatvora ili mjera koja <strong>uključuje</strong> oduzimanje slobode u trajanju <strong>od jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "6. stav 2.", citat: "Izručenje u svrhu izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode dopustit će se samo za krivična djela koja su kažnjiva prema pravu obje države ugovornice i ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode, odnosno njihov ostatak koji se treba izvršiti, iznosi najmanje četiri mjeseca."}],
                        opis: "najmanje <strong>četiri mjeseca</strong>"},
      privremeni: "18 dana od datuma pritvaranja, na <strong>obrazloženi zahtjev</strong> se može produžiti do 40 dana",
      dokumentacija: ["sredstva za utvrđivanje identiteta lica čije se izručenje traži (tačan opis, fotografije, otisci prstiju i sl.),",
        "optužnicu ili presudu ili odluku o pritvoru ili koji drugi akt istog pravnog značaja, u ovjerenom prijepisu ili ovjerenoj kopiji originala (ime i prezime lica čije se izručenje traži i drugi podaci koji su potrebni za utvrđivanje njegovog identiteta, opis djela i zakonski naziv krivičnog djela)",
        "izvod iz teksta zakonskih odredbi koje treba da budu primijenjene ili su bile primijenjene prema licu čije se izručenje traži",
        "podatke o dužini krivične sankcije kada se radi o izručenju lica radi izdržavanja ostatka krivične sankcije;",
        "ostale podatke ili dokumente koji mogu da budu od značaja za donošenje odluke o izručenju",
      ],
      vremenskaprimjena: { postoji: true, odrednica: "potpisivanje", od: "nakon datuma potpisivanja", clanovipojedinakd: "članovi 7. i 8.", clan: "34.", citat: "Odredbe članova 7. i 8. ovog Ugovora primjenjuju se samo na krivična djela počinjena nakon datuma potpisivanja ovog Ugovora." }
    }
  },
  {
    ime: "Srbija",
    kod: "RS",
    naziv: "Ugovor o izručenju između Bosne i Hercegovine i Republike Srbije",
    tip: "ugovor",
    datum: "05.09.2013. godine",
    stupanje: "02.12.2014. godine",
    sl: "(“Službeni glasnik BiH” - Međunarodni ugovori, broj: 19/14)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "POJEDINAKD", komentar: "", 
                            clanovi: [
                                {clan: "7. stav 1.", vrsta: "P", citat: "Izručenje sopstvenih državljana u cilju krivičnog gonjenja će se dozvoliti ako su ispunjeni uslovi propisani ovim ugovorom za krivična djela organizovanog kriminala, korupcije i pranja novca za koja je prema pravu obje države ugovornice propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od četiri godine ili teža kazna." },
                                {clan: "7. stav 2.", vrsta: "I", citat: "Izručenje sopstvenih državljana u cilju izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode dozvoliće se ako su ispunjeni uslovi propisani ovim ugovorom za krivična djela organizovanog kriminala, korupcije i pranja novca, koja su kažnjiva prema pravu obje države ugovornice, ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode, odnosno njihov ostatak koji treba da se izvrši iznosi najmanje dvije godine." }, 
                                {clan: "8. stav 1.", vrsta: "P", citat: "Izručenje sopstvenih državljana u cilju krivičnog gonjenja dozvoliće se ako su ispunjeni uslovi propisani ovim ugovorom, i za druga teška krivična djela za koja je propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od najmanje 5 godina ili teža kazna." },
                                {clan: "8. stav 2.", vrsta: "I", citat: "Izručenje sopstvenih državljana u cilju izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode dozvoliće se ako su ispunjeni uslovi propisani ovim ugovorom za krivična djela iz stava (1) ovog člana, ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode, odnosno njihov ostatak koji treba da se izvrši, iznosi najmanje dvije godine." }
                                                  ]}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [
        "član 7. stav 1. - <strong>krivično gonjenje</strong> - (krivična djela organizovanog kriminala, korupcije i pranja novca) - ako je prema pravu obje države ugovornice <strong>propisana kazna</strong> zatvora ili mjera koja <strong>uključuje</strong> oduzimanje slobode u trajanju<strong> od četiri godine ili teža kazna</strong>",
        "član 7. stav 1. - <strong>izvršenje kazne</strong> - (krivična djela organizovanog kriminala, korupcije i pranja novca) - ako <strong>trajanje</strong> kazne/ostatak koji treba da se izvrši iznosi <strong>najmanje dvije godine</strong>",
        "član 8. stav 1. - <strong>krivično gonjenje</strong> - (druga teška krivična djela) - ako se radi o teškim krivičnim djelima za koja je <strong>propisana</strong> kazna zatvora ili mjera koja <strong>uključuje</strong> oduzimanje slobode u trajanju od <strong>najmanje 5 godina ili teža kazna</strong>",
        "član 8. stav 2. - <strong>izvršenje kazne</strong> - (druga teška krivična djela) - ako <strong>trajanje kazne</strong> zatvora/ostatak koji treba da se izvrši, iznosi <strong>najmanje dvije godine</strong>, a pod uslovom da se radi o teškim krivičnim djelima u smislu člana 8. stav 1. Ugovora"
      ],
      procesminimum: {clanovi: [{clan: "6. stav 1.", citat: "Izručenje radi krivičnog gonjenja dozvoliće se samo za krivična djela za koja je, kako prema pravu države molilje, tako i prema pravu zamoljene države, propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od najmanje jedne godine."}],
                      opis: "propisana kazna zatvora ili mjera koja uključuje oduzimanje slobode u trajanju od <strong>najmanje jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "6. stav 2.", citat: "Izručenje radi izvršenja pravosnažno izrečene kazne zatvora ili mjere koja uključuje oduzimanje slobode, dozvoliće se samo za krivična djela koja su kažnjiva prema pravu obje države ugovornice i ako trajanje kazne zatvora ili mjere koja uključuje oduzimanje slobode ili njihov ostatak koji treba da se izvrši, iznosi najmanje četiri mjeseca."}],
                         opis: "najmanje <strong>četiri mjeseca</strong>"},
      privremeni: "18 dana od dana pritvaranja, na <strong>obrazloženi zahtjev</strong> države moliteljice se može produžiti do 40 dana",
      dokumentacija: ["sredstva za utvrđivanje istovjetnosti lica čije se izručenje traži (tačan opis, fotografije, otisci prstiju i sl.)",
        "potvrdu ili druge podatke o državljanstvu lica čije se izručenje traži;",
        "optužni akt ili presudu ili odluku o pritvoru ili koji drugi akt jednak ovoj odluci, u izvorniku ili ovjerenom prijepisu (sa naznačenim: ime i prezime lica i ostali podaci potrebni za utvrđivanje njegove istovjetnosti potraživanog, opis djela, zakonski naziv krivičnog djela i dokazi iz kojih proizilazi osnovana sumnja",
        "izvod iz teksta zakonskih odredaba koje će se primijeniti ili su primijenjene prema licu čije se izručenje traži, zbog djela povodom koga se traži izručenje",
        "podatke o dužini ostatku krivične sankcije/kazne"
      ],
      vremenskaprimjena: { postoji: true, odrednica: "stupanje", od: "nakon stupanja na snagu", clanovipojedinakd: "članovi 7. i 8.", clan: "35.", citat: "Odredbe članova 7. i 8. ovog ugovora primjenjivaće se samo na krivična djela izvršena nakon stupanja na snagu ovog Ugovora." }
    }
  },
  {
    ime: "Crna Gora",
    kod: "ME",
    naziv: "Ugovor o izručenju između Bosne i Hercegovine i Crne Gore",
    tip: "ugovor",
    datum: "15.11.2012. godine",
    stupanje: "30.06.2015. godine",
    sl: "(„Službeni glasnik BiH – Međunarodni ugovori“ broj: 10/13)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "POJEDINAKD", komentar: "",
                            clanovi: [
                              {clan: "7. stav 1.", vrsta: "P", citat: "Izručеnjе vlastitih držаvlјаnа zbоg krivičnоg gоnjеnjа dоzvоlit će se аkо su ispunjеni uslоvi prоpisаni оvim Ugоvоrоm sаmо zа krivičnа djеlа оrgаniziranog kriminаlа i kоrupciје i krivično djеlo prаnjе nоvcа, za koja je prema pravu obje države ugovornice propisana kazna zatvora ili mjera koja podrazumijeva lišenje slobode u trajanju od četiri godine ili teža kazna, koja su kао tаkvа prеdviđеnа dоmаćim zаkоnоdаvstvimа držаvа ugоvоrnicа." },
                              {clan: "7. stav 2.", vrsta: "P", citat: "Izručеnjе vlastitih držаvlјаnа zbоg krivičnоg gоnjеnjа ćе sе dоzvоliti аkо su ispunjеni uslоvi prоpisаni оvim Ugоvоrоm i za оstаlа tеškа krivičnа djеlа, оdnоsnо tеškе оblikе krivičnih djеlа zа kоја је prеmа prаvu оbjе držаvе ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd nајmаnjе pеt gоdinа ili tеžа kаznа, оdnоsnо mjеrа kоја uklјučuје lišenje slоbоdе, оsim zа krivičnа djеlа prоtiv čоvjеčnоsti i drugih dоbаrа zаštićеnih mеđunаrоdnim prаvоm." },
                              {clan: "7. stav 3.", vrsta: "I", citat: "Izručеnjе vlastitih držаvlјаnа rаdi izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе kоја uklјučuје lišenje slоbоdе dоzvоlit će se, zа krivičnа djеlа iz stavova(1) i (2) оvоg člаnа i аkо izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје lišnje slоbоdе, оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi nајmаnjе dvijе gоdinе." },
                            ]}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [
        "član 7. stav 1. - <strong>krivično gonjenje</strong> - (krivičnа djеlа оrgаniziranog kriminаlа i kоrupciје i krivično djеlo prаnjе nоvcа) - ako je prema pravu obje države ugovornice <strong>propisana</strong> kazna zatvora ili mjera koja <strong>podrazumijeva</strong> lišenje slobode u trajanju <strong>od četiri godine ili teža kazna,</strong> koja su kао tаkvа prеdviđеnа dоmаćim zаkоnоdаvstvimа držаvа ugоvоrnicа",
        "član 7. stav 2. - <strong>krivično gonjenje</strong> - (оstаlа tеškа krivičnа djеlа/tеški оblici krivičnih djеlа) - ako je prеmа prаvu оbjе držаvе ugоvоrnicе <strong>prоpisаnа</strong> kаznа zаtvоrа <strong>u trајаnju оd nајmаnjе pеt gоdinа ili tеžа kаznа</strong>",
        "član 7. stav 3. - <strong>izvršenje kazne</strong> - (za krivična djela iz stava 1. i 2.) -  аkо izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје lišnje slоbоdе, оdnоsnо njihоv <strong>оstаtаk</strong> kојi trеbа dа sе izvrši, iznоsi <strong>nајmаnjе dvijе gоdinе</strong>"
      ],
     
      procesminimum: {clanovi: [{clan: "6. stav 1.", citat: "Izručеnjе rаdi krivičnоg gоnjеnjа dоzvоlićе sе sаmо zа krivičnо djеlо zа kоје је, kаkо prеmа prаvu držаvе mоliteljice, tаkо i prеmа prаvu zаmоlјеnе držаvе, prоpisаnа kаznа zаtvоrа ili mjеrа kоја uklјučuје оduzimаnjе slоbоdе u trајаnju оd nајmаnjе јеdnе gоdinе."}],
                      opis: "prоpisаnа kаznа zаtvоrа (prema obje države) ili mjеrа kоја <strong>uklјučuје</strong> оduzimаnjе slоbоdе u trајаnju оd <strong>nајmаnjе јеdnе gоdinе</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "6. stav 2.", citat: "Izručеnjе rаdi izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе kоја uklјučuје lišenje slоbоdе, dоzvоlit ćе sе sаmо zа krivičnа djеlа kоја su kаžnjivа prеmа prаvu оbjе držаvе ugоvоrnicе i аkо trајаnjе kаznе zаtvоrа ili mjеrе kоја uklјučuје lišenje slоbоdе ili njihоv оstаtаk kојi se trеbа izvršiti, iznоsi nајmаnjе čеtiri mjеsеcа."}],
                         opis: "nајmаnjе <strong>čеtiri mjеsеcа</strong>"},
      privremeni: "18 dаnа оd dаnа pritvаrаnjа, <strong>nа оbrаzlоžеni zаhtjеv</strong> držаvе mоliteljice se mоžе prоdužiti 40 dаnа",
      dokumentacija: ["srеdstvа zа utvrđivаnjе identiteta licа čiје sе izručеnjе trаži (tаčаn оpis, fоtоgrаfiје, оtisci prstiјu i sl.);",
        "pоtvrdu ili drugе pоdаtkе о držаvlјаnstvu licа čiје sе izručеnjе trаži",
        "оptužnicu ili prеsudu ili odluku o sprovođenju istrage ili kојi drugi аkt јеdnаk оvој оdluci, odluku o pritvoru, u izvоrniku ili оvjеrеnоm prijepisu (sa naznačenim: imе i prеzimе licа čiје sе izručеnjе trаži i оstаli pоdаci pоtrеbni zа utvrđivаnjе njеgоvog identiteta, činjenični оpis djеlа i zаkоnski nаziv krivičnоg djеlа)",
        "izvоd iz tеkstа zаkоnskih оdrеdbi kоје ćе sе primjеniti ili su primjenjеnе prеmа licu čiје sе izručеnjе trаži, zbоg djеlа pоvоdоm kоgа sе trаži izručеnjе",
        "pоdаtkе о dužini krivičnе sаnkciјe/kazne"
      ],
      vremenskaprimjena: { postoji: true, odrednica: "potpisivanje", od: "poslije potpisivanja", clanovipojedinakd: "član 7. stavovi 1. i 2.",clan: "7. stav 4.", citat: "Izručenje vlastitih državljana za krivična djela iz stavova 1. i 2. ovog člana dozvolit će se samo za krivična djela izvršena poslije potpisivanja ovog Ugovora." }
    }
  },
  {
    ime: "Sjeverna Makedonija",
    kod: "MK",
    naziv: "Ugovor između Bosne i Hercegovine i Republike Makedonije o izručenju",
    tip: "ugovor",
    datum: "27.01.2006. godine",
    stupanje: "10.01.2007. godine",
    sl: "(„Službeni glasnik BiH - Međunarodni ugovori broj:14/06)",
    izmjene: "IZMJENE",  //IZMJENE PROTOKOL
    naziv2: "Ugovor o izmjenama i dopunama Ugovora o izručenju između Bosne i Hercegovine i Republike Makedonije",
    datum2: "12.03.2013. godine",
    stupanje2: "30.07.2014. godine",
    sl2: "(“Službeni glasnik BiH” – Međunarodni ugovori, broj: 6/14)",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "POJEDINAKD", komentar: "", 
                            clanovi: [
                              { clan: "9a. stav 1.", vrsta: "P", citat: "Izručеnjе vlastitih držаvlјаnа zbоg krivičnоg gоnjеnjа ćе sе dоzvоliti аkо su ispunjеni uslоvi prоpisаni оvim ugоvоrоm sаmо zа krivičnа djеlа оrgаniziranog kriminаlа i kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа оstаle oblike organiziranog kriminala zа kоја је prеmа prаvu оbjе držаvе ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd četiri gоdine ili tеžа kаznа, оdnоsnо mjеrа kоја uklјučuје оduzimаnjе slоbоdе." },
                              { clan: "9a. stav 2.", vrsta: "I", citat: "Izručеnjе vlastitih držаvlјаnа rаdi izvršеnjа prаvоsnаžnо izrеčеnе kаznе zаtvоrа ili mjеrе kоја uklјučuје оduzimаnjе slоbоdе može sе dоzvоliti, аkо su ispunjеni uslоvi prоpisаni оvim ugоvоrоm sаmо zа krivičnа djеlа оrgаniziranog kriminаlа i kоrupciје, krivičnо djеlо prаnjе nоvcа, kао i zа оstаle oblike оrgаniziranog kriminala iz stаvа (1) оvоg člаnа, i аkо izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје оduzimаnjе slоbоdе, оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi nајmаnjе dvijе gоdinе." },
                            ]}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: ["član 9a. stav 1. - <strong>krivično gonjenje</strong> - (krivična djela оrgаniziranog kriminаlа i kоrupciје, krivičnо djеlо prаnjе nоvcа) - ako je prеmа prаvu оbjе držаvе ugоvоrnicе prоpisаnа kаznа zаtvоrа u trајаnju оd četiri gоdine ili tеžа kаznа, оdnоsnо mjеrа kоја uklјučuје оduzimаnjе slоbоdе",
        "član 9a. stav 2. - <strong>izvršenje kazne</strong> - (krivična djela оrgаniziranog kriminаlа i kоrupciје, krivičnо djеlо prаnjе nоvcа) - аkо izrеčеnа kаznа zаtvоrа ili mjеrа kоја uklјučuје оduzimаnjе slоbоdе, оdnоsnо njihоv оstаtаk kојi trеbа dа sе izvrši, iznоsi nајmаnjе dvijе gоdinе",
      ],
      
      procesminimum: {clanovi: [{clan: "9. stav 1.", citat: "Izručenje zbog kaznenog gonjenja dozvolit će se ako je za kazneno djelo, prema pravu obiju ugovornica, propisana kazna zatvora ili mjera sigurnosti ili odgojna mjera koja podrazumijeva lišavanje slobode od najmanje jedne godine."}],
                      opis: "prema pravu obje ugovornice, <strong>propisana kazna</strong> zatvora ... koja podrazumijeva lišavanje slobode od <strong>najmanje jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "9. stav 2.", citat: "Izručenje zbog izvršenja pravomoćno izrečene kazne zatvora ili mjere sigurnosti ili odgojne mjere koje podrazumijevaju lišavanje slobode dozvolit će se ako je djelo za koje je izrečena kaznena sankcija propisano kao kazneno djelo prema pravu obiju ugovornica, prije nego što je djelo počinjeno i ako je izrečena kazna zatvora ili mjera sigurnosti ili odgojna mjera koja podrazumijeva lišavanje slobode u trajanju od najmanje četiri mjeseca."}],
                         opis: "kazna podrazumijeva lišavanje slobode u trajanju od <strong>najmanje četiri mjeseca</strong>"},
      privremeni: "18 dana od dana pritvaranja, ne može biti duži od 40 dana",
      dokumentacija: ["rješenje o sprovođenju istrage, ili optužnica ili rješenje o pritvoru ili neki drugi akt, koji je jednak toj odluci, ili pravomoćna odluka o izrečenoj sankciji, u izvorniku ili ovjerenom prijepisu",
        "izvod odredaba Kaznenog zakona, koje će biti primijenjene ili su bile primijenjene protiv osobe, čije se izručenje traži",
        "sredstva za utvrđivanje istovjetnosti osobe (tačan opis, fotografija, otisci prstiju i sl.), za koju se traži izručenje",
        "uvjerenje ili drugi podaci o državljanstvu osobe za koju se traži izručenje",
        "podaci u vezi sa trajanjem preostalog dijela kazne u slučaju izručenja osobe koja je osuđena i koja je samo djelomično izdržala kaznu."
      ],
      vremenskaprimjena: { postoji: true, odrednica: "stupanje", od: "nakon stupanja na snagu", clanovipojedinakd: "član 9a. stav 1. i 2.", clan: "9a. stav 3.", citat: "Izručеnjе iz stava (1) i (2) ovoga člana neće se dozvoliti za krivična djela koja su počinjena prije stupanja na snagu ovog ugovora." }
    }
  },
  {
    ime: "Albanija",
    kod: "AL",
    naziv: "Konvencija o izdavanju krivaca između Kraljevine Srba, Hrvata i Slovenaca i Arbanske Republike",
    tip: "konvencija",
    datum: "22.06.1926. godine",
    stupanje: "17.05.1929. godine",
    sl: "(„Službene novine“, broj: 117/29)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "3.", citat: "Nije dopušteno izdavanje svoga sopstvenog državljanina."}], termin:""}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "2. stav 3.", citat: "Ako se zahtev odnosi na okrivljene, koji nisu još osuđeni, izdavanje će se odobriti, ako je minimum kazne lišenja slobode jedna godina za delo, zarad kojeg je njihovo izdavanje traženo."}],
                      opis: "minimum kazne lišenja slobode <strong>jedna godina</strong> za djelo"} ,
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "2. stav 2.", citat: "Ako se zahtev (izdavanje) odnosi na osuđeno lice, ono će moći biti izdato, ako je u poslednjoj instanciji osuđeno na kaznu lišenja slobode ne manju od šest meseci."}],
                        opis: "kazna lišenja slobode <strong>ne manje od šest meseci</strong>"},
      privremeni: "maksimalno četrdeset i pet dana",
      dokumentacija: ["osuda, rešenje o stavljanju pod optužbu, potjernica (u kojoj će biti označena priroda i težina inkrimisanog dela, kao i njegov naziv i tekst krivičnog zakona koji je na snazi u zemlji, koja traži izdavanje, a primenjuje se na krivično delo i sadrži kaznu koju to djelo povlači",
                      "opis, fotografija i svi drugi podaci pogodni za utvrđivanje identiteta (ako je moguće)"
                      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Austrija",
    kod: "AT",
    naziv: "Ugovor između SFRJ i Republike Austrije o izdavanju",
    tip: "ugovor",
    datum: "01.02.1982. godine",
    stupanje: "01.01.2984. godine",
    sl: "(„Službeni list SFRJ“ - Međunarodni ugovori broj: 2/83)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "3. stav 1.", citat: "Državljani zamoljene države se neće izdavati."}], termin:"ZAMOLJENA"}, // NE, POJEDINAKD, NEJASNO  
      pojedinakd: [""],  // 2 upućuje na 1
      procesminimum: {clanovi: [{ clan: "2. stav 1.", citat: "Izdavanje će se odobriti radi gonjenja zbog djela za koje je prema pravu obije države ugovornice propisana kazna lišenja slobode ili preventivna mjera preko jedne godine stroža kazna."}],
                      opis: "prema pravu obije države propisana kazna lišenja slobode <strong>preko jedne godine ili stroža</strong> kazna (ako je izručenje odobreno za neka djela odobrava se i za djela koja ne ispunjavaju uslove)"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "2. stav 2.", citat: "Izdavanje radi izvršenja pravosnažno izrečene kazne preventivne mjere zbog jednog ili više djela navedenih u stavu 1. ovog člana odobriće se ako trajanje kazne preventivne mjere, odnosno njihov ostatak koji treba da se izvrši iznosi najmanje 4 mjeseca. Izdavanje će se takođe odobriti ako je potrebno izvršiti više kazni ili preventivnih mjera čiji je zbir najmanje 4 mjeseca."}],
                         opis: "ako trajanje kazne odnosno ostatak iznosi <strong>najmanje 4 mjeseca (ili zbir više kazni)</strong> - mora se raditi o djelima koja podliježu izručenju radi krivičnog gonjenja (ako je izručenje odobreno za neka djela odobrava se i za djela koja ne ispunjavaju uslove)"},
      privremeni: "može se ukinuti ako molba i dokumentcija ne budu dostavljeni u roku od 30 dana, a ne može trajati duže od 45 dana",
      dokumentacija: ["nalog za pritvaranje/izvršna osuđujuća presuda/odluka o izricanju preventivne mjere ili neki drugi dokument istog dejstva (sa potpisom sudije i službenim pečatom)",
        "ako nije sadržano u pomenutom dokumentima prilaže se: opis djela sa navođenjem vremena i mjesta izvršenja ",
        "pravna kvalifikacija djela i prepis zakonskih odredaba koje treba primjeniti ili su primjenjene",
        "ako se radi o izvršenju kazne dokument iz kojeg je vidljiva izvršnost odluke",
        "što tačniji podaci o licu čije se izdavanje traži, njegovom državljanstvu, prebivalištu ili boravištu"
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Belgija",
    kod: "BE",
    naziv: "Konvencija o ekstradiciji i pravnoj pomoći u krivičnim stvarima između SFRJ i Kraljevine Belgije",
    tip: "konvencija",
    datum: "04.06.1971. godine",
    stupanje: "01.11.1972.) godine",
    sl: "(„Službeni list SFRJ“ - Dodatak broj: 9/73)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "3. stav 1.", citat: "Strane ugovornice ne izdaju svoje državljane"}], termin:"" }, // NE, POJEDINAKD, NEJASNO  
      pojedinakd: [""],  //b upućuje na a
      procesminimum: {clanovi: [{ clan: "2. stav 1. tačka a)", citat: "Ima mjesta izdavanju u slučaju ako jedna ili više radnji, prema zakonodavstvu obje države, predstavljaju krivična djela za koja je propisana kazna lišenja slobode čiji je maksimum najmanje dvije godine ili teža kazna"}],
                      opis: "ako jedna ili više radnji, prema zakonima obje države, predstavlja krivična djela za koja je <strong>propisana kazna</strong> lišenja slobode čiji je <strong>>maksimum najmanje dvije godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                         clanovi: [{clan: "2. stav 1. tačka b)", citat: "Ima mjesta izdavanju u slučaju osude na kaznu lišenja slobode najmanje šest mjeseci koje su izrekli sudovi države molilje za krivična djela navedena u tački a) ovog stava"},
                                   {clan: "2. stav 1. tačka c)", citat: "Ima mjesta izdavanju u slučaju osude za krivična djela za koja je, prema zakonodavstvu obje države propisana kazna lišenja slobode čije ukupno trajanje iznosi najmanje šest mjeseci i pod uslovom da se jedno od učinjenih krivičnih djela kažnjava po njihovom zakonodavstvu kaznom lišenja slobode čiji je maksimum najmanje dvije godine ili teža kazna"}],
                         opis: "kazna lišenja slobode <strong>najmanje šest mjeseci</strong> za krivična djela koja ispunjavaju pomenuti uslov zaprijećene kazne ili osude za krivična djela za koja je, prema zakonodavstvu obje države, propisana kazna lišenja slobode čije ukupno trajanje iznosi najmanje šest mjeseci i pod uslovom da se jedno od ućinjenih krivičnih djela kažnjava po njihovom zakonodavstvu kaznom lišenja slobode čiji je maksimum najmanje dvije godine ili teža kazna"},
      privremeni: "može da prestane ako zamoljena strana u roku od 18 dana po pritvaranju ne primi zahtjev za izdavanje i dokumentaciju",
      dokumentacija: ["nalog za hapšenje ili bilo koji drugi akt koji ima istu snagu a izdat je u obliku koji propisuje zakon države molilje (sa naznačenim okolnostima pod kojima je djelo učinjeno, vrijeme i mjesto izvršenja djela, pravna kvalifikacija i zakonske odredbe, priroda i visina štete ako postoji)",
        "izvorniku ili u ovjeren prijepisu, pravosnažne presude ako se radi o izvršenju",
        "tekst zakonskih odredaba koje se primjenjuju",
        "po mogućnosti lični opis lica i njegova fotografija, kao i svi podaci koji su podobni za utvrđivanje njegovog identiteta i državljanstva",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Bjelorusija",
    kod: "BY",
    naziv: "Ugovor između FNRJ i SSSR-a o pravnoj pomoći u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "24.02.1962. godine",
    stupanje: "26.05.1963. godine",
    sl: "(„Službeni list FNRJ“ - Dodatak broj: 5/63)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "59. stav 1. tačka a)", citat: "Nema mjesta izdavanju ako je: a) lice čije se izdavanje traži državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{ clan: "58. stav 2.", citat: "Izdavanje se vrši samo za ona djela koja su po zakonu obe strane ugovornice predviđena kao krivična djela i za koja se može izreći kazna lišenja slobode u trajanju dužem od jedne godine ili teža kazna."}],
                      opis: "djela za koja se <strong>može izreći kazna zatvora preko jedne godine</strong> ili teža kazna"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "58. stav 2.", citat: "Izdavanju radi izvršenja kazne ima mjesta ako je presuda postala pravosnažna i ako je lice čije se izdavanje traži osuđeno na kaznu lišenja slobode u trajanju dužem od jedne godine ili na težu kaznu."}],
                         opis: "kazna zatvora u trajanju <strong>dužem od jedne godine</strong> ili teža kazna"},
      privremeni: "potraživani će biti pušten na slobodu ako zahtjev za puštanje na slobodu ne bude dostavljen u roku od trideset dana od dana prijema obaveštenja o lišenju slobode",
      dokumentacija: ["ovjerena kopija rješenja o lišenju slobode, odnosno kopija pravosnažne presude ili druge odgovarajuće sudske odluke ako se zahtjev odnosi na izdavanje kazne za izvršenje kazne",
        "po potrebi, dodatne podatke o činjeničnom stanju, pravnu kvalifikaciju djela i obim štete prouzrokovane krivičnim djelom",
        "tekst krivičnog zakona koji se kvalifikuje, odnosno krivično delo na teritoriji ugovorne strane koja traži izručenje",
        "lični opis lica čije se izdavanje traži, podaci o državljanstvu i mjestu prebivališta, a najbolje fotografija i otisci prstiju",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Bugarska",
    kod: "BG",
    naziv: "Ugovor između FNRJ i Narodne Republike Bugarske o uzajamnoj pravnoj pomoći",
    tip: "ugovor",
    datum: "23.03.1956. godine",
    stupanje: "17.01.1957. godine",
    sl: "(„Službeni list FNRJ“ - Dodatak broj: 1/57)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "70. stav 1. tačka a)", citat: "Zahtjev za izdavanje odbiće se u sljedećim slučajevima: a) ako je lice čije se izdavanje traži državljanin zamoljene države."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""], // izvrsenje upućuje na proces
      procesminimum: {clanovi: [{ clan: "67. stav 1.", citat: " Zamoljena država odobriće izdavanje lica protiv koga se u državi koja postavlja zahtjev za izdavanje vodi krivični postupak zbog krivičnog djela za koje je po zakonima obje države ugovornice predviđena maksimalna kazna lišenja slobode u trajanju najmanje dvije godine ili teža kazna."}],
                      opis: "po zakonima obje drŽave ugovornice <strong>predviđena</strong> maksimalna kazna liŠenja slobode u trajanju <strong>najmanje dvije godine ili teŽa kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "68. stav 1.", citat: "Pod uslovima iz stava 1. člana 67. ovog ugovora zamoljena država će, takođe, odobriti izdavanje lica koje je pravosnažnom sudskom presudom osuđeno na kaznu lišenja slobode najmanje 6 mjeseci."}],
                         opis: "kaznu lišenja slobode <strong>najmanje 6 mjeseci</strong> uz uslov da se radi o krivičnim djelima koji ispunjavaju uslov zaprijećene kazne"},
      privremeni: "pritvaranje se obustavlja ako u roku koji odredi nadležni organ zamoljene države ne bude dostavljen zahtjev za izdavanje - <strong>na obrazloženi zahtjev</strong> rok se može produžiti",
      dokumentacija: ["akt kojim se određuje pritvor ili istražni zatvor, otvara istraga, podiže optužnica, raspisuje potjernica ili drugi akt istog dejstva",
        "u navedenom aktu treba da su oznaćeni opis djela zbog koga se izdavanje traži i činjenično stanje, vrijeme i mjesto izvršenja i pravna kvalifikacija",
        "pravosnažna presuda ako se radi o izvršenju",
        "dokaze ili podatke o državljanstvu traženog lica",
        "podatke i sredstva za utvrđivanje identiteta traženog lica",
        "tekst krivičnog zakona koji se odnosi na krivično djelo zbog koga se izdavanje traži"
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Češka",
    kod: "CZ",
    naziv: "Ugovor između SFRJ i Čehoslovačke Socijalističke Republike o regulisanju pravnih odnosa u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "20.01.1964. godine",
    stupanje: "02.08.1964. godine",
    sl: "(„Služben list SFRJ“ - Dodatak broj: 13/64)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "64. stav 1. tačka a)", citat: "Izdavanju nema mjesta a) ako je lice koje se traži u momentu prijema zahtjeva za izdavanje državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO  
      pojedinakd: [""],
      procesminimum: { clanovi: [{ clan: "63. stav 2.", citat: "Izdavanje radi vođenja krivičnog postupka je dopušteno samo za krivične djela za koja se po zakonu obje strane ugovornice može izreći kazna lišenja slobode u trajanju dužem od jedne godine."}],
                       opis: "za krivična djela za koja se po zakonu obje države ugovornice <strong>može izreći</strong> kazna lišenja slobode u trajanju <strong>dužem od jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi:  [{clan: "63. stav 3.", citat: "Izdavanje radi izvršenja kazne je dopušteno samo za krivična djela koja su kažnjiva po zakonu obje strane ugovornice a traženo lice je osuđeno na kaznu lišenja slobode u trajanju najmanje od jedne godine ili težu kaznu."}],
                         opis: "potraživano lice osuđeno na kaznu lišenja slobode u trajanju <strong>najmanje od jedne godine ili na težu kaznu</strong>"},
      privremeni: "potraživano lice se može privremeno pritvoriti (nije precizirano trajanje privremenog pritvaranja ali je određeno da se može pustiti ako u roku od 2 mjeseca od obavještenja o pritvaranju ne bude dostavljen zahtjev), dodatno,  Ako je zatražena dopuna zahtjeva/dokumentacije precizirano je da se može pustiti ako dopuna nije dostavljena u dodatnom roku koji se može odrediti do 2 mjeseca. Ovaj rok se može na zahtjev produžiti.",
      dokumentacija: ["odluka o lišenju slobode sa opisom krivičnog djela i podacima o dokazima (nije obavezno dostavljanje dokaza)",
        "tekst zakonskih propisa koji se odnose na krivično djelo",
        "podatke o visini nastale štete",
        "otpravak pravosnažne presude",
        "podatke o dijelu kazne koji je izdržan",
        "po mogućnosti opis traženog lica i njegova fotografija, podaci o državljanstvu, ličnim prilikama i boravištu",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Francuska",
    kod: "FR",
    naziv: "Konvencija o izdavanju između Vlade SFRJ i Vlade Francuske Republike",
    tip: "konvencija",
    datum: "23.09.1970. godine",
    stupanje: "01.08.1971. godine",
    sl: "(„Službeni list SFRJ“ - Dodatak broj: 43/71)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "3. stav 1. tačka a)", citat: "Izdavanje se neće odobriti a) ako je lice čije se izdavanje traži bilo državljanin zamoljene strane u trenutku izvršenja krivičnog djela."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],  // izvrsenje upucuje na proces
      procesminimum: {clanovi: [{clan: "2. stav 1. tačka a)", citat: "Ima mjesta izdavanju a) ako jedna ili više radnji prema zakonodavstvu obje države predstavljaju krivična djela za koja je propisana kazna lišenja slobode od najmanje jedne godine ili teža kazna."}], 
                     opis: "<strong>ako jedna ili više radnji</strong> prema zakonima obje države predstavljaju krivična djela za koja je <strong>propisana</strong> kazna lišenja slobode od <strong>najmanje jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "6. stav 1. tačka b)", citat: "Ima mjesta izdavanju b) ako su sudovi države molilje za djela navedena u tački a) ovog člana izrekli kaznu lišenja slobode od najmanje šest mjeseci."},
                                    {clan: "2. stav 1. tačka c)", citat: "Ima mjesta izdavanju u slučaju: c) ako se radi o osudama za krivična djela za koja se, prema zakonodavstvu obje države, predviđa kazna lišenja slobode čije ukupno trajanje iznosi najmanje šest mjeseci pod uslovom da se bar jedno od počinjenih krivičnih djela kažnjava po njihovim zakonodavstvima kaznom lišenja slobode od najmanje jedne godine."}
                                  ], 
                         opis: "ako je izrečena kazna lišenja slobode od <strong>najmanje šest mjeseci</strong> za djela koja ispunjavaju uslov zaprijećene kazne, ako se radi o osudama za krivična djela za koja se, prema zakonodavstvu obje države, predviđa kazna lišenja slobode čije ukupno trajanje iznosi najmanje šest mjeseci pod uslovom da se bar jedno od počinjenih krivičnih djela kažnjava po krivičnim zakonodavstvima kaznom lišenja slobode od najmanje jedne godine"},
      privremeni: "lice će biti pušteno ako na slobodu ako zahtjev za izdavanje ne bude dostavljen u roku od jednog mjeseca od dostavljanja/prijema obavještenja o lišenju slobode potraživanog, rok se može produžiti najviše za 15 dana",
      dokumentacija: ["izvornik ili ovjeren nalog hapšenju ili bilo koji drug akt koji ima istu snagu prema zakonu države moliteljice (u navedenom aktu treba da su naznačene okolnosti pod kojima je djelo učinjeno, vrijeme i mjesto izvršenja djela, pravna kvalifikacija i zakonske odredbe koje se odnose na djelo, priroda i visina štete /ako postoji/)",
        "izvornik ili ovjerena kopija pravosnažne presude",
        "tekst zakonskih odredaba koje će se primjeniti",
        "po mogućnosti lični opis lica i njegova fotografija kao i podaci koji doprinose utvrđivanju njegovog identiteta i državljanstva"
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Grčka",
    kod: "GR",
    naziv: "Konvencija između FNRJ i Kraljevine Grčke  o uzajamnim pravnim odnosima",
    tip: "konvencija",
    datum: "18.06.1959. godine",
    stupanje: "31.03.1960. godine",
    sl: "(„Službeni list FNRJ“ - Dodatak broj:  7/60)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "49. stav 1.", citat: "Zahtjev za izdavanje odbiće se u sljedećim slučajevima: a) ako je lice čije se izdavanje traži državljanin zamoljene države."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""], // izvrsenje upućuje na proces
      procesminimum: {clanovi: [{ clan: "45. stav 1.", citat: "Zamoljena država odobriće izdavanje lica protiv koga se u državi koja postavlja zahtjev za izdavanje vodi krivični postupak za zločin ili krivično djelo koje je kažnjivo prema krivičnim zakonima obje države ugovornice i ako zakon obje strane dozvoljava izricanje kazne lišenja slobode u trajanju od dvije godine ili teže kazne."}],
                      opis: "ako zakon obje strane <strong>dozvoljava izricanje</strong> kazne lišenja slobode u trajanju <strong>od dvije godine ili teže kazne</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{ clan: "47. stav 1.", citat: "Pod uslovima iz stava 1. člana 45. ove konvencije zamoljena država }e, takođe, odobriti izdavanje lica koje je pravosnažnom sudskom presudom osuđeno na kaznu lišenja slobode najmanje 6 mjeseci ili na težu kaznu."}],
                         opis: "osuđeno na kaznu lišenja slobode <strong>najmanje 6 mjeseci ili na težu kaznu</strong> (ako krivično djelo koje ispunjava uslov zaprijećene kazne)"},
      privremeni: "privremeno pritvaranje reguliše se zakonom zamoljene države",
      dokumentacija: ["akt nadležnog organa države koja postavlja zahtjev za krivično gonjenje, pritvor, istražni zatvor, izvođenje okrivljenog ili optuženog pred krivični sud svaki drugi akt istog dejstva",
        "u navedenom aktu aktu treba da su označeni opis djela zbog koga se izdavanje traži, tekst krivičnog zakona države koja postavlja zahtjev, a koji se primjenjuje na djelo i utvrđuje kaznu koju on povlači, vrijeme i mjesto izvršenja djela i pravna kvalifikacija",
        "pravosnažna sudska odluka (sa dokazom da je pravosnažna)",
        "dokakaz ili podaci o državljanstvu traženog lica",
        "podaci i sredstva za utvrđivanje identiteta traženog lica",
        "tekst krivičnog zakona koji se odnosi na krivično djelo zbog koga se izdavanje traži",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Nizozemska",
    kod: "NL",
    naziv: "Ugovor o izdavanju krivaca između Srbije i Holandije",
    tip: "ugovor",
    datum: "28.02 (11.03.) 1896. godine",
    stupanje: "17.12.1896. godine",
    sl: "(„Srpske novine“, broj: 275/1896)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : true, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "Članom 1. propisano da se ugovornice, <strong>izuzimajući svoje podanike</strong>, obavezuju da međusobno izdaju sva lica osuđena ili optužena zbog jednog Konvencijom preciziranih djela, učinjenih van teritorije države od koje se traži ekstradicija", 
                            clanovi: [{clan: "1. stav 1.", citat: "Vlada Srpska i Vlada Holandska obvezuju se, da, po odredbama sledećih članova, izuzimajući svoje podanike, međusobno izdaju sva lica osuđena ili optužena zbog jednog od dole pobrojanih dela, učinjenih van teritorije države od koje se traži ekstradicija"}], termin:"PODANIK"}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""], // *** ugovor nabraja krivčna djela, ne postvlja minimum/max
      procesminimum: {clanovi: [{clan: "", citat: ""}],
                      opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje"},
      izvrsenjeminimum: {upucujenaproces: undefined,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "", citat: ""}],
                         opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje"},
      privremeni: "pustiće se u slobodu ako se u roku od 20 dana, računajući od datuma rešenja o njegovom privremenom pritvoru, zahtev za izdavanje ne učini diplomatskim putem i ako se ne dostavi u isto vreme jedan od dokumenata propisanih konvencijom",
      dokumentacija: ["original ili overeni prepis, bilo osudne presude, bilo rešenja da se stavi pod isleđenje ili da se preda sudu sa nalogom da se stavi u pritvor, ili naloga da se stavi u pritvor, izdatog u propisanoj formi zakonodavstva zemlje koja izdavanje traži, s dovoljnim objašnjenjem dela, da bi umoljena država mogla ocijeniti, da li, prema njenom zakonodavstvu, postoji slučaj predviđen ovom konvencijom, kao i ako se podnese odredba krivičnog zakonika koja se na to delo primenjuje",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Irak",
    kod: "IQ",
    naziv: "Ugovor o pravnoj i sudskoj suradnji između SFRJ i Republike Irak",
    tip: "ugovor",
    datum: "23.05.1986. godine",
    stupanje: "11.08.1987. godine",
    sl: "(„Službeni list SFRJ“ - Dodatak Međunarodni ugovori broj:  01/87)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "21. stav 1. tačka a)", citat: "Izdavanje će se odbiti u sljedećim slučajevima: a) ako je lice čije se izdavanje traži državljanin zamoljene strane ugovornice"}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "20. stav 2.", citat: "Izdavanje radi vođenja krivičnog postupka odobrava se za krivično djelo za koje je po zakonima obje strane ugovornice propisana kazna zatvora od dve godine ili teža kazna."},{clan: "20. stav 4.", citat: "Ako se izdavanje traži za više krivičnih djela zahtjevu se može udovoljiti za sva krivična djela su bar za jedno djelo za koje se zahtjev odnosi ispunjeni uslovi iz stava 1 do 3. ovog člana"}],
                      opis: "krivično djelo za koje je pozakonima obe strane ugovornice <strong>propisana</strong> kazna zatvora <strong>od dvije godine ili teža kazna</strong> (zahtjevu se može udovoljiti i ako neka krivična djela ne ispunjavaju ovaj uslov)"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "20. stav 3.", citat: "Izdavanje radi izvršenja kazne odobriće se pod uslovom da je sud strane molilje izrekao kaznu zatvora u trajanju od najmanje šest mjeseci ili težu kaznu."}, {clan: "20. stav 4.", citat: "Ako se izdavanje traži za više krivičnih djela zahtjevu se može udovoljiti za sva krivična djela su bar za jedno djelo za koje se zahtjev odnosi ispunjeni uslovi iz stava 1 do 3. ovog člana"}],
                         opis: "kazna zatvora u trajanju od <strong>najmanje šest mjeseci ili teža kazna</strong> (zahtjevu za izručenje se može udovoljiti i ako neke osude ne ispunjavaju ovaj uslov)"},
      privremeni: "propisana mogućnost privremenog pritvaranja na način da se država moliteljica obavezuje da dostavi molbu za izručenje u roku od 2 mjeseca (od dana obavještavanja o lišenju slobode lica) pod prijetnjom puštanja na slobodu",
      dokumentacija: ["rješenje o pritvoru",
        "opis krivičnog djela, spisak dokaza",
        "tekst zakonske odredbe koja se odnosi na krivično djelo ",
        "dokaz o visini materijalne štete koja je posljdica djela",
        "ovjeren primjerak pravosnažne presude",
        "tekst zakonske odredbe na osnovu koje je presuda donesena",
        "potrebni podaci ako je osuđeno lice izdržalo dio kazne",
        "opis lica koje se traži, podatke o državljanstvu, ličnom statusu i imovnom stanju, mjestu prebivališta, odnosno boravišta i po mogućnosti njegovu fotografiju ako su podaci u pomenutom rješenju nedovoljni",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Iran (Islamska Republika)",
    kod: "IR",
    naziv: "Ugovor o pravnoj pomoći u građanskim i krivičnim stvarima između Bosne i Hercegovine i Islamske Republike Iran",
    tip: "ugovor",
    datum: "14.06.2011. godine",
    stupanje: "16.05.2013. godine",
    sl: "(„Službeni glasnik BiH – Međunarodni ugovori“broj: 2/12)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi:[{clan: "33. stav 1. tačka a)", citat: "Izručenje se ne može dogoditi u slučaju lica: a) ako je državljanin zamoljene Ugovorne strane"}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO 
      pojedinakd: [""], // izvrsenje upućuje na proces
      procesminimum: {clanovi: [{clan: "32. stav 1.", citat: "Izručenje će se dopustiti ako je lice koje se traži počinilo djelo koje predstavlja krivično djelo prema zakonima Ugovornih strana i koje je kažnjivo kaznom zatvora u trajanju od jedne ili više godina."}],
                      opis: "krivično djelo prema zakonima ugovornih strana i koje je <strong>kažnjivo</strong> kaznom zatvora u trajanju <strong>od jedne ili više godina</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "32. stav 2.", citat: "Izručenje u vezi izvršenja kazne za počinjeno djelo s kvalifikacijom iz stava 1. ovog člana bit će dopušteno ako je osuđeni osuđen na godinu dana ili dužu zatvorsku kaznu ili više godina."}],
                        opis: "potraživani osuđen na <strong>godinu dana ili dužu zatvorsku</strong> kaznu ili više godina (uz uslov ispunjenosti zaprijećenog minimuma)"},
      privremeni: "lice će biti oslobođeno ako zamolnica nije podnesena u roku od 30 dana nakon što druga Ugovorna strana bude službeno obaviještena o pritvoru, dodatne informacije nisu podnesene u predviđenom roku, zamoljena Ugovorna strana će odmah obustaviti postupak izručenja i pustiti pritvoreno lice",
      dokumentacija: ["ovjerena kopiju naloga za hapšenje ili rješenje o pritvoru, a ako se izručenje traži za izdržavanje kazne - ovjeren prijepis pravosnažne presude",
        "ako nalog za hapšenje ne navodi krivično djelo, mjesto i vrijeme izvršenja, niti zakonsku kvalifikaciju, ti elementi moraju biti sadržani u ovjerenim prilozima",
        "kopiju tekstova zakona koji se primjenjuju na slučaj",
        "trajanje preostale kazne u slučaju izručenja lica koje je osuđeno, a koje je samo djelimično odslužilo kaznu",
        "sve potrebne informacije za identifikaciju traženog lica, ime i prezime, imena roditelja, državljanstvo, adresu prebivališta ili boravišta, fotografiju, lične znakove prepoznavanja, otiske prstiju i sve ostale potrebne informacije, ako je moguće",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Italija",
    kod: "IT",
    naziv: "Konvencija između Kraljevine SHS i Italije o izdavanju krivaca",
    tip: "konvencija",
    datum: "06.03.1922. godine",
    stupanje: "06.02.1931. godine (zadržana je na snazi i poslije II Svjetskog rata na osnovu izjave Jugoslavije u smislu člana 4. Ugovora o miru sa Italijom - /objavljen u „Službenom listu FNRJ“, broj: 74/49/)",
    sl: "(„Službene novine“, broj: 42/31)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "4.", citat: "Nije dopušteno izdavanje vlastitog državljanina."}], termin:"" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "2.", citat: "Obaveza izdavanje se odnosi na prosta krivična djela, s izuzetkom onih, koja su obilježena u čl. 5 (vojna, politička i krivična djela predviđena zakonima o štampi), a za koja je izrečena kazna lišenja slobode za najmanje šest mjeseci, ili za koja, po zakonu države molilje, može biti izrečena kazna lišenja lične slobode ne manje od jedne godine. Djelo mora biti kažnjivo i po zakonima države molilje i po zakonima zamoljene države."}],
                      opis: "krivična djela za koja po zakonu države molilje <strong>može biti izrečena</strong> kazna lišenja lične slobode <strong>ne manje od jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "2.", citat: "Obaveza izdavanje se odnosi na prosta krivična djela, s izuzetkom onih, koja su obilježena u čl. 5 (vojna, politička i krivična djela predviđena zakonima o štampi), a za koja je izrečena kazna lišenja slobode za najmanje šest mjeseci, ili za koja, po zakonu države molilje, može biti izrečena kazna lišenja lične slobode ne manje od jedne godine. Djelo mora biti kažnjivo i po zakonima države molilje i po zakonima zamoljene države."}],
                         opis: "<strong>prosta krivična djela</strong> za koja je izrečena kazna lišenja slobode <strong>za najmanje šest mjeseci</strong>"},
      privremeni: "Pritvoren olice biće pušteno na slobodu, ako u roku od četrdeset i pet dana, računajući od njegovog pritvaranja Ministarstvu pravde zamoljene države ne stignu molba i potrebni dokument, rok biti produžen na dva mjeseca u slučaju ako je lice koje se ima izdati naznačeno kao opasan zločinac",
      dokumentacija: ["krivična presuda ili rješenje o pritvoru ili bilo koji akt ekvivalentan takvom rješenju, u kome će biti naznačeni priroda i težina imputiranog djela i primijenjene ili primjenljive odredbe krivičnog zakona",
        "kopija tekstova primijenjenih ili primjenljivih zakona",
        "po mogućnosti sa opis zatraženog lica, njegova fotografija i svaki drugo naznačenje podobno za konstatovanje identiteta",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Kina",
    kod: "CN",
    naziv: "Ugovor o ekstradiciji između Bosne i Hercegovine i Narodne Republike Kine",
    tip: "ugovor",
    datum: "18.12.2012. godine",
    stupanje: "12.10.2014. godine",
    sl: "(„Službeni glasnik BiH - Međunarodni ugovori broj: 18/13“)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: {status: "NE", komentar: "", clanovi: [{clan: "5.", citat: "Svaka Strana ugovornica ima diskreciono pravo, da u skladu sa svojim zakonodavstvom, odbije izručenje sopstvenih državljana."}], termin:"" }, // NE, POJEDINAKD, NEJASNO  
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "2. stav 1. tačka a)", citat: "Krivična djela za koja je dozvoljena ekstradicija: Kada je zahtjev za ekstradiciju podnesen radi pokretanja krivičnog postupka, ako je za to krivično djelo u zakonima obje Strane ugovornice predviđena kazna zatvora u trajanju od najmanje jedne godine ili teža kazna."}],
                      opis: "ako je za krivično djelo u zakonima obje Strane ugovornice <strong>predviđena</strong> kazna zatvora u trajanju <strong>od najmanje jedne godine ili teža kazna</strong>, ako se radi o više djela sve dok jedno od djela ispunjava ovaj uslov može se odobriti ekstradiciju i za druga djela"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "2. stav 1. tačka b)", citat: "Krivična djela za koja je dozvoljena ekstradicija: Kada je zahtjev za ekstradiciju podnesen radi izvršenja kazne zatvora, ako je traženom licu u vrijeme podnošenja zahtjeva preostalo još najmanje šest mjeseci do kraja odsluženja te kazne zatvora."}],
                         opis: "u vrijeme podnošenja zahtjeva preostalo <strong>još najmanje šest mjeseci</strong> do kraja odsluženja te kazne zatvora, ako se radi o više osuda sve dok jedna osuda ispunjava ovaj uslov može se odobriti ekstradiciju i za druge osude"},
      privremeni: "Privremeno lišavanje slobode se prekida ako, u roku od trideset dana od dana lišavanja slobode traženog lica, nadležni organ Zamoljene strane ne dobije formalni zahtjev za ekstradiciju. <strong>Na uredno podneseni zahtjev Strane molilje</strong>, taj vremenski rok može biti produžen za petnaest dana",
      dokumentacija: ["Ime i prezime, godine starosti, pol, državljanstvo, broj sa identifikacionih dokumenata, zanimanje, prebivalište ili boravište traženog lica, te ostale podatke koji mogu pomoći u utvrđivanju identiteta i moguće lokacije tog lica",
        "po mogućnosti, i fizički opis tog lica, fotografije i otiske prstiju tog lica",
        "činjenično stanje krivičnog djela, uključujući i vrijeme, mjesto, djelo i posljedice krivičnog djela",
        "Tekst relevantnih odredbi zakona kojima se uređuje krivična nadležnost, kriminalizacija i kaznena sankcija za to krivično djelo",
        "Tekst relevantnih odredbi zakona u kojima se navodi period zastarijevanja krivičnog gonjenja ili vremenski rok za izvršenje kazne",
        "kopija naloga za hapšenje koji je izdao nadležni organ Strane molilje (na dokumente se stavlja službeni potpis ili pečat nadležnog organa)",
        "kopija pravosnažne presude i opis dužine trajanja kazne koja je već izvršena (na dokumente se stavlja službeni potpis ili pečat nadležnog organa)",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Mađarska (Hungary)",
    kod: "HU",
    naziv: "Ugovor između SFRJ i Narodne Republike Mađarske o uzajamnom pravnom saobraćaju",
    tip: "ugovor",
    datum: "07.03.1968. godine",
    stupanje: "18.01.1969. godine)",
    sl: "(„Službeni listu FNRJ“ - Dodatak broj: 3/68)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "88. stav 1. tačka a)", citat: "Nema mjesta izdavanju ako je lice čije se izdavanje traži, državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],   // izvršenje upućuje na proces
      procesminimum: {clanovi: [{clan: "85. stav 1.", citat: "Izdavanju ima mjesta samo za ona djela koja su kao krivična djela predviđena zakonima obje strane ugovornice i po kojima se može izreći kazna lišenja slobode u trajanju od najmanje dvije godine ili teža kazna."}],
                     opis: "za djela koja su kao krivična djela <strong>predviđena</strong> zakonima obje strane ugovornice i po kojima se može izreći kazna lišenja slobode u trajanju <strong>od najmanje dvije godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "86. stav 1.", citat: "Pod uslovima iz člana 85. stav (1) ovog ugovora zamoljena strana ugovornica će izdati lice koje je pravosnažnom sudskom presudom osuđeno na kaznu lišenja slobode najmanje šest mjeseci."}],
                         opis: "osuđeno na kaznu lišenja slobode <strong>najmanje šest mjeseci</strong> (uz uslov da se radi o djelu koje ispunjava zaprijećene kazne)"},
      privremeni: "Postupak pritvaranja zamoljena strana ugovornica provodi saglasno njenim zakonima. Postupak pritvaranja se obustavlja ako u roku koji odredi sud zamoljene strane ugovornice, koji ne može biti duži od dva mjeseca, ne bude dostavljen zahtjev za izdavanje. N<strong>a obrazloženi zahtjev strane ugovornice</strong> od koje potiče zahtjev za izdavanje, ovaj rok može se produžiti",
      dokumentacija: [" odluka o lišenju slobode koju je donio sud države moliteljice ili druga sudska odluka sličnog dejstva. Odluka treba da sadrži opis krivičnog djela zbog koga se izdavanje traži, okolnosti pod kojima je ono učinjeno, mjesto i vrijeme izšvreenja i njegovu pravnu kvalifikaciju",
        "pravosnažna presuda nadležnog suda (sa dokazom da je pravosnažna)",
        "dokazi ili podaci o državljanstvu traženog lica",
        "tekst zakona koji se odnosi na krivično djelo zbog koga se izdavanje traži kako strane ugovornice koja postavlja zahtjev za izdavanje tako i treće države ako je na njenoj teritoriji djelo učinjeno",
        "po mogućnosti lični podaci potraživanog lica, njegov lični opis, podaci o njegovom boravištu, fotografija i otisci prstiju",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Mongolija",
    kod: "MN",
    naziv: "Ugovor između SFRJ i Mongolske Narodne Republike o pružanju pravne pomoći u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "08.06.1981. godine",
    stupanje: "27.03.1983. godine",
    sl: "(„Službeni list FNRJ“- Međunarodni ugovori broj: 7/82)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "67. stav 1. tačka a)", citat: "Zahtjev za izdavanje odbiće se ako je lice čije se izdavanje zahtijeva državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA"}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "66. stav 1.", citat: "Izdavanje radi krivičnog gonjenja odobrava se samo za ona krivična djela za koja je po zakonu obe strane ugovornice propisana kazna lišenja slobode u trajanju dužem od jedne godine ili teža kazna."}],
                      opis: "krivična djela za koja je po zakonu obe strane propisana kazna lišenja slobode <strong>u trajanju dužem od jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "66. stav 2.", citat: "Izdavanje radi izvršenja kazne lišenja slobode odobrava se samo ako je presudom izrečena kazna lišenja slobode duža od jedne godine ili teža kazna."}],
                         opis: "izrečena kazna lišenja slobode <strong>duža od jedne godine ili teža kazna</strong>"},
      privremeni: "Lice privremeno lišeno slobode može biti pušteno na slobodu ako u roku od 45 dana od dana dobijanja obavještenja o pritvaranju zamoljene strane ne primi zahtjev za izdavanje sa prilozima.",
      dokumentacija: ["ovjeren prepis odluke o pritvaranju ili prepis pravosnažne presude",
        "podaci o identitetu državljanstvu i drugi dokumenti koji se odnose na okrivljenog odnosno osuđenog",
        "lični opis lica, a po mogućnosti njegova fotografija i otisci prstiju",
        "opis krivičnog djela i njegova pravna kvalifikacija",
        "tekst krivičnog zakona strane ugovornice koja podnosi zahtjev",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Njemačka",
    kod: "DE",
    naziv: "Sporazum između SFRJ i Savezne Republike Njemačke o izdavanju",
    tip: "ugovor",
    datum: "26.11.1970. godine",
    stupanje: "14.11.1975. godine",
    sl: "(„Službeni list SFRJ“ - Dodatak broj: 17/76)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {  //** izručenje vlastitih clan ubaciti u array
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NEJASNO", komentar: "Zamoljena država neće izdavati lica čije izdavanje ne smatra dozvoljenim po svom ustavu. Zamoljena država neće dati državljanstvo licu čije izdavanje traži druga država, osim ako to lice ima zakonsko pravo na sticanje državljanstva.",
                            clanovi: [{clan: "6. stav 1.", citat: "Zamoljena država neće izdavati lica čije izdavanje ne smatra dozvoljenim po svom ustavu."}, {clan: "6. stav 2.", citat: "Zamoljena država neće dati državljanstvo licu čije izdavanje traži druga država, osim ako to lice ima zakonsko pravo na sticanje državljanstva."}], termin:""}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""], // ** izvrsenje upućuje na proces
      procesminimum: {clanovi: [{clan: "2. stav 1.", citat: "Izdavanje će se vršiti samo za dela, za koja je, kako prema pravu države molilje tako i prema pravu zamoljene države, propisana kazna lišenja slobode u maksimumu od najmanje jedne godine ili teža kazna."}],
                      opis: "krivična djela za koja je prema pravu države molilje tako i prema pravu zamoljene države, <strong>propisana </strong>kazna lišenja slobode u maksimumu od <strong>najmanje jedne godine ili teža kazna</strong>, Ako je odobreno izdavanje za jedno djelo dopunski će se, istovremeno ili naknadno, odobriti izdavanje i djela koja nisu podobna za izdavanje"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "2. stav 2.", citat: "ako je neko lice na teritoriji države molilje zbog dela navedenog u stavu 1 pravnosnažno osuđeno na kaznu lišenja slobode, izdavanje će se odobriti samo ako kazna lišenja slobode, odnosno njen ostatak, koje treba izvršiti iznosi najmanje četiri meseca. Ako se traži izdavanje radi izvršenja mere bezbednosti odnosno vaspitne mere, koja sadrži lišenje slobode, ono će biti odobreno ako bi izvršenje mere prema pravu države molilje moglo da traje još najmanje četiri meseca. Izdavanje će biti odobreno i kad treba izvršiti više kazni ili mera bezbednosti odnosno vaspitnih mera, a njihov zbir iznosi najmanje četiri meseca."}],
                         opis: "kazna lišenja slobode, odnosno njen <strong>ostatak</strong>, koje treba izvršiti iznosi <strong>najmanje četiri meseca</strong> (uz uslov da se radi o krivičnom djelu koje ispunjava uslov zaprijećene kazne). Izdavanje će biti odobreno i kad treba izvršiti više kazni a njihov zbir iznosi najmanje četiri meseca, Ako je odobreno izdavanje po ovom osnovu dopunski će se, istovremeno ili naknadno, odobriti izdavanje i djela koja nisu podobna za izdavanje."},
      privremeni: "Privremeni pritvor može se ukinuti ako molba za izdavanje i prilozi ne budu dostavljeni zamoljenoj državi u roku od trideset dana od pritvaranja, pritvor ni u kojem slučaju ne može preći 45 dana računajući od časa pritvaranja",
      dokumentacija: ["izvornik ili overeni prepis naloga za hapšenje ili neke druge isprave istog pravnog dejstva ili izvršna osuđujuća odluka",
        "opis djela zbog kojeg se traži izdavanje",
        "Koliko je mogućno tačnije navode se podaci o vremenu i mestu izvršenja dela, pravna kvalifikacija sa upućivanjem na odnosne zakonske odredbe, kao i vrsta a po mogućnosti i obim pričinjene štete",
        "prepis zakonskih odredaba koje treba primeniti",
        "što tačniji opis lica čije se izdavanje traži i svi ostali podaci podobni za utvrđivanje njegovog identiteta i državljanstva",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Poljska",
    kod: "PL",
    naziv: "Ugovor između FNRJ i Narodne Republike Poljske o pravnom saobraćaju u građanskim i krivičnim stvarima",
    tip: "ugovor",
    datum: "06.02.1960. godine",
    stupanje: "05.06.1963. godine",
    sl: "(„Sužbeni list SFRJ“ - Dodatak broj: 5/63)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "77. stav 1.", citat: "Zahtjev za izdavanje odbiće se u sljedećim slučajevima ako je lice čije se izdavanje traži državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],  //**  izvršenje upućuje na proces
      procesminimum: {clanovi: [{clan: "74. stav 1.", citat: "Izdavanje ima mjesta za krivično djelo za koje se po zakonima obje strane ugovornice može izreći kazna lišenja slobode u trajanju od dvije godine ili teža kazna."}],
                      opis: "krivično djelo za koje se po zakonima obje strane ugovornice <strong>može izreći</strong> kazna lišenja slobode u trajanju <strong>od dvije godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "75. stav 1.", citat: "Pod uslovima iz člana 74. stav 1. zamoljena strana ugovornica će takođe odobriti izavanje lica koje je pravosnažnom presudom suda strane ugovornice koja moli osuđeno na kaznu lišenja slobode najmanje šest mjeseci."}],
                         opis: "kazna lišenja slobode <strong>najmanje šest mjeseci</strong>"},
      privremeni: "Privremeno pritvaranj se provodi saglasno zakonu zamoljene države. Postupak za izdavanje obustavlja se ako u roku koji odredi nadležni sud zamoljene strane ugovornice ne bude dostavljen zahtjev za izdavanje. Na obrazloženi zahtjev strane ugovornice od koje potiče zahtjev za izdavanje ovaj se rok može produžiti.",
      dokumentacija: ["akt nadležnog suda kojim se određuje pritvor ili istražni zatvor, otvara istraga, podiže optužnica, raspisuje potjernica ili drugi akt istog dejstva",
        "u navedenom aktu treba da su označeni opis djela zbog koga se izdavanje traži i činjenično stanje, vrijeme i mjesto izvršenja djela i pravna kvalifikacija djela",
        "izvod pravosnažne sudske presude",
        "dokazi ili podaci o državljanstvu traženog lica",
        "podaci i sredstva za utvrđivanje identiteta traženog lica, a po mogućnosti i drugi podaci za njegov brzi pronalazak, ako ono već nije identifikovano u zamoljenoj strani ugovornici",
        "tekst krivičnog zakona koji se odnosi na krivično djelo zbog koga se izdavanje traži kako strane ugovornice koja postavlja zahtjev za izdavanje, tako i države u kojoj je djelo izvršeno",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Rumunija (Romania)",
    kod: "RO",
    naziv: "Ugovor između FNRJ i Rumunske Narodne Republike o pravnoj pomoći",
    tip: "ugovor",
    datum: "18.10.1960. godine",
    stupanje: "01.10.1961. godine",
    sl: "(„Službeni list FNRJ“ - Dodatak broj: 8/61)",
    izmjene: "PROTOKOL",
    naziv2: "Dodatni protokol", 
    datum2: "21.01.1972. godine",
    stupanje2: "",
    sl2: "(„Službeni list SFRJ“ - Dodatak broj: 4/73)",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "69. stav 1. tačka b)", citat: "Nema mjesta izdavanju u slučajevima ako je lice čije se izdavanje traži u momentu prijema zahtjeva za izdavanje, državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO  // u momentu prijema zahtjeva za izdavanje, državljanin zamoljene strane ugovornice;
      pojedinakd: [""], // izvrsenje upućuje na proces
      procesminimum: {clanovi: [{clan: "68. stav 2.", citat: "Izdavanje radi krivičnog gonjenja i suđenja odobrava se samo za krivična djela za koja se po zakonima obje strane ugovornice može izreći kazna lišenja slobode u trajanju dužem od jedne godine ili teža kazna"}],
                      opis: "za krivična djela za koja se po zakonima obje strane ugovornice <strong>može izreći</strong> kazna lišenja slobode u trajanju <strong>dužem od jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "68. stav 3.", citat: "Pod uslovom iz stava 2. ovog člana odobriće se i izdavanje lica koje je pravosnažno osuđeno na kaznu lišenja slobode u trajanju dužem od jedne godine ili na težu kaznu."}],
                         opis: "osuđeno na kaznu lišenja slobode u trajanju <strong>dužem od jedne godine ili na težu</strong> kaznu (uz uslov da se radi o krivičnom djelu koje ispunjava zaprijećeni minimum)"},
      privremeni: "Pritvoreno liće će biti pušteno na slobodu ako u utvrđenom roku od nadležnog organa zamoljene strane ugovornice, koji ne može biti duži od jednog mjeseca od dana kada je dostavljeno obavještenje o lišenju slobode, nije primljen zahtjev za izdavanje. <strong>Na zahtjev druge strane ugovornice</strong> ovaj rok se može produžiti",
      dokumentacija: ["ovjeren prijepis odluke nadležnog organa o lišenju slobode (nalog za hapšenje) ili ovjeren prijepis sudske presude o izrečenoj kazni sa klauzulom o njenoj pravosnažnosti",
        "u navedenim aktima treba izložiti činjenično stanje i označiti vrijeme i mjesto izvršenja krivičnog djela i njegovu pravnu kvalifikaciju",
        "ako je krivičnim djelom pričinjena materijalna šteta, onda, po mogućstvu, treba označiti i podatke o utvrđenoj ili približnoj visini iste",
        "tekst krivičnog zakona koji se odnosi na krivično djelo zbog koga se izdavanje traži kako strane ugovornice koja postavlja zahtjev tako i države na čijoj je teritoriji djelo izvršeno",
        "dokaze ili podatke o državljanstvu lica čije se izdavanje traži, podatke i sredstvo za utvrđivanje njegovog identiteta (opis, fotografija, otisci prstiju), ukoliko traženo lice nije identifikovano na teritoriji zamoljene strane ugovornice, kao i podatke o njegovoj adresi, sve u mjeri u kojoj je to moguće",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Rusija",
    kod: "RU",
    naziv: "Ugovor između FNRJ i SSSR-a o pravnoj pomoći u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "24.02.1962. godine",
    stupanje: "26.05.1963. godine",
    sl: "( „Službeni list FNRJ“ - Dodatak broj: 5/63)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "59. stav 1. tačka a)", citat: "Nema mjesta izdavanju ako je lice čije se izdavanje traži državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "58. stav 2.", citat: "Izdavanje se vrši samo za ona djela koja su po zakonu obje strane ugovornice predviđena kao krivična djela i za koja se može izreći kazna lišenja slobode u trajanju dužem od jedne godine ili teža kazna."}],
                      opis: "djela koja su zakonom obje ugovorne strane definisana kao krivična djela i za koja se <strong>može izreći</strong> kazna zatvora <strong>dužem od jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "58. stav 2.", citat: "Izdavanju radi izvršenja kazne ima mjesta ako je presuda postala pravosnažna i ako je lice čije se izdavanje traži osuđeno na kaznu lišenja slobode u trajanju dužem od jedne godine ili na težu kaznu."}],
                         opis: "osuđeno na kaznu zatvora u trajanju <strong>dužem od jedne godine ili težu kaznu</strong>"},
      privremeni: "Lice lišeno slobode ugovora biće pušteno na slobodu ako  u roku od trideset dana od dana prijema obaveštenja o lišenju slobode ne bude primljen zahtjev za izdavanje, Članom 61. je propisano da zamoljena država može tražiti dopunske podatke ostavljajući rok državi moliteljici koji ne može biti duži od 2 mjeseca koji rok se na zahtjev može produžiti, ako se u ovim rokovima ne dostave traženi podaci postupak se obustavlja a traženo lice pušta na slboodu",
      dokumentacija: ["ovjerenu kopiju rješenja o lišenju slobode, odnosno kopiju pravosnažne presude ili druge odgovarajuće sudske odluke ako se zahtjev odnosi na izdavanje kazne za izvršenje kazne",
        "po potrebi, dodatne podatke o činjeničnom stanju, pravnu kvalifikaciju djela i obim štete prouzrokovane krivičnim djelom",
        "tekst krivičnog zakona po kojem se kvalifikuje odnosno krivično djelo na teritoriji strane ugovornice koja traži izručenje",
        "ični opis lica čije se izdavanje traži, podaci o državljanstvu i prebivalištu, a po mogućnosti fotografija i otisci prstiju",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Sjedinjene Američke Države (United States)",
    kod: "US",
    naziv: "Konvencija o izdavanju krivaca zaključena između Kraljevine Srbije i Ujedinjenih Američkih Država",
    tip: "ugovor",
    datum: "12/25.10.1901. godine",
    stupanje: "12.06.1902. godine",
    sl: "  („Srpske novine“ broj: 33/1902)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : true, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "Ugovornice pristaju da izdaju lica koja, optužena ili osuđena ma za koje zločinstava ili prijestupe <strong>precizirane u članu 2. Konvencije</strong> te izvršena u granicama jurisdikcije jedne od ugovornica, traže utočište ili budu nađena na zemljištu druge. Izdavanje će se učiniti samo na osnovu takvih dokaza o krivici koji, prema zakonima mjesta gdje se bjegunac ili lice tako optuženo bude našlo, mogu opravdati njegov pritvor i predaju na suđenje kao da je zločinstvo ili prijestup tu i izvršenf. <br/><br/>Ni jedna od Visokih ugovornica <strong>nije obavezna</strong> da izdaje <strong>svoje sopstvene građane ili podanike</strong> prema uslovima u ovom Ugovoru. (član V)",
                            clanovi: [{clan: "V", citat: "Ni jedna od Visokih ugovornica nije obavezna da izdaje svoje sopstvene građane ili podanike prema uslovima u ovom Ugovoru."}], termin:"PODANIK" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "", citat: ""}],
                     opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje."},
      izvrsenjeminimum: {upucujenaproces: undefined,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                          clanovi: [{clan: "", citat: ""}],
                         opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje."},
      privremeni: "Privremeni pritvor bjegunca prestaće i pritvorenik biće pušten na slobodu, ako se u tok od dva mjeseca od dana njegovog privremenog stavljanja u pritvor, ne podnese formalna molba za njegovo izdavanje",
      dokumentacija: ["ovjeren prijepis presude suda koji je osudio potraživanog",
        "potvrđeni prijepis rješenja o pritvoru u državi gdje je zločinstvo učinjeno",
        "saslušanja i drugi dokazi, na osnovu kojih je takvo (pomenuto) rješenje doneseno",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Slovačka",
    kod: "SK",
    naziv: "Ugovor između SFRJ i Čehoslovačke Socijalističke Republike o regulisanju pravnih odnosa u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "20.01.1964. godine",
    stupanje: "02.08.1964. godine",
    sl: "(„Služben list SFRJ“ - Dodatak broj: 13/64)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi:[{clan: "64. stav 1.", citat: "Izdavanju nema mjesta ako je lice koje se traži u momentu prijema zahtjeva za izdavanje državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "63. tav 2.", citat: "Izdavanje radi vođenja krivičnog postupka je dopušteno samo za krivična djela za koja se po zakonu obe strane ugovornice može izreći kazna lišenja slobode u trajanju dužem od jedne godine."}],
                      opis: "za krivična djela za koja se po zakonu obje države ugovornice <strong>može izreći</strong> kazna lišenja slobode u trajanju <strong>dužem od jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "63. stav 3.", citat: "Izdavanje radi izvršenja kazne je dopušteno samo za krivična djela koja su kažnjiva po zakonu obje strane ugovornice a traženo lice je osuđeno na kaznu lišenja slobode u trajanju najmanje od jedne godine ili na težu kaznu."}],
                         opis: "potraživano lice osuđeno na kaznu lišenja slobode u trajanju <strong>najmanje od jedne godine ili na težu kaznu</strong>"},
      privremeni: "potraživano lice se može privremeno pritvoriti (nije precizirano trajanje privremenog pritvaranja ali je određeno da se može pustiti ako u roku od 2 mjeseca od obavještenja o pritvaranju ne bude dostavljen zahtjev), dodatno,  Ako je zatražena dopuna zahtjeva/dokumentacije precizirano je da se može pustiti ako dopuna nije dostavljena u dodatnom roku koji se može odrediti do 2 mjeseca. Ovaj rok se može na zahtjev produžiti.",
      dokumentacija: ["odluka o lišenju slobode sa opisom krivičnog djela i podacima o dokazima (nije obavezno dostavljanje dokaza)",
        "tekst zakonskih propisa koji se odnose na krivično djelo",
        "podatke o visini nastale štete",
        "otpravak pravosnažne presude",
        "podatke o dijelu kazne koji je izdržan",
        "po mogućnosti opis traženog lica i njegova fotografija, podaci o državljanstvu, ličnim prilikama i boravištu",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Slovenija",
    kod: "SI",
    naziv: "Ugovor između Bosne i Hercegovine i Republike Slovenije o izručenju",
    tip: "ugovor",
    datum: "05.04.2002. godine",
    stupanje: "18.02.2006. godine",
    sl: "(„Službeni glasnik BiH” - Međunarodni ugovori broj: 12/2005)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "NE",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi: [{clan: "2. stav 1.", citat: "Izručenje vlastitih državljana se ne dozvoljava."}, {clan: "3. stav 1. tačka 1.", citat: "Izručenje će se dozvoliti pod slijedećim uvjetima: da osoba čije se izručenje traži nije državljanin zamoljene ugovornice ili osoba bez državljanstva, koja ima prebivalište u zamoljenoj ugovornici"}], termin:"ZAMOLJENA"}, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "10. stav 1.", citat: "Izručenje zbog krivičnog gonjenja dozvolit će se ako je za krivično djelo, prema pravu obje ugovornice, propisana kazna zatvora ili mjera sigurnosti ili odgojna mjera koja podrazumijeva lišenje slobode od najmanje jedne godine."}],
                      opis: "ako je za krivično djelo, prema pravu obje ugovornice, <strong>propisana</strong> kazna zatvora ili mjera sigurnosti ili odgojna mjera koja <strong>podrazumijeva</strong> lišenje slobode od <strong>najmanje jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "10. stav 2.", citat: "Izručenje zbog izvršenja pravosnažno izrečene kazne zatvora ili mjere sigurnosti ili odgojne mjere koje podrazumijevaju lišenje slobode dozvolit će se ako je djelo za koje je izrečena krivična sankcija propisano kao krivično djelo prema pravu obje ugovornice, prije nego što je djelo učinjeno i ako je izrečena kazna zatvora ili mjera sigurnosti ili odgojna mjera koja podrazumijeva lišenje slobode u trajanju od najmanje šest mjeseci."}],
                         opis: "ako je djelo za koje je izrečena krivična sankcija propisano kao krivično djelo prema pravu obje ugovornice, prije nego što je djelo učinjeno i ako je izrečena kazna zatvora ili mjera sigurnosti ili odgojna mjera koja podrazumijeva lišenje slobode u trajanju od <strong>najmanje šest mjeseci</strong>"},
      privremeni: "Nakon što je obaviještena o pritvaranju potraživanog lica država moliteljica mora najkasnije u roku od petnaest dana po prijemu obavještenja o pritvoru saopćiti, da li će podnijeti molbu za izručenje. Molbu za izručenje mora dostaviti u roku od trideset dana od dana kada je saopćila da će zahtijevati izručenje. Pritvor se ukida ako zamoljena država ne primi molbu za izručenje u pomenutom roku.",
      dokumentacija: ["sredstva za utvrđivanje istovjetnosti osobe za koju se traži izručenje",
        "uvjerenje ili drugi podaci o državljanstvu osobe za koju se traži izručenje",
        "rješenje o sprovođenju istrage, ili optužnicu ili rješenje o pritvoru ili neki drugi akt, koji je jednak toj odluci, ili pravosnažna odluka o izrečenoj krivičnoj sankciji, u originalu ili ovjerenom prijepisu",
        "izvod odredaba Krivičnog zakona, koje će biti primijenjene ili su bile primijenjene protiv osobe čije se izručenje traži",
        "podaci o dužini kazne, koja se još ima izdržati, kada se radi o izručenju osobe zbog izdržavanja kazne, a ista je dio kazne izdržala",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Španija",
    kod: "ES",
    naziv: "Ugovor  između SFRJ i Španije o pravnoj pomoći u krivičnim stvarima i izdavanju",
    tip: "ugovor",
    datum: "08.07.1980. godine",
    stupanje: "26.05.1982. godine",
    sl: "(„SlužbenI list SFRJ“ - Međunarodni ugovori broj: 3/81)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "Članom 26. propisano da zamoljena strana neće odobriti izdavanje lica za koje smatra da ga prema svome ustavu ne smije izdati. Strane ugovornice <strong>odbiće izdavanje svojih državljana</strong>. Državljanstvo traženog lica oceniće se u momentu donošenja odluke o izdavanju.",
                            clanovi: [{clan: "26. stav 1.", citat: "Zamoljena strana neće odobriti izdavanje lica za koje smatra da ga prema svome ustavu ne smije izdati."}, {clan: "26. stav 2.", citat: "Strane ugovornice odbiće izdavanje svojih državljana. Državljanstvo traženog lica ocijeniti će se u momentu donošenja odluke o izdavanju."}], termin:"" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""], // izvršenje upućuje na proces
      procesminimum: {clanovi: [{clan: "22. stav 1.", citat: "Izdavanje će se vršiti samo za djela za koja je prema pravu strane molilje i prema pravu zamoljene strane propisana kazna lišenja slobode u maksimumu od najmanje jedne godine."}],
                      opis: "za djela za koja je prema pravu obje države <strong>propisana</strong> kazna lišenja slobode <strong>u maksimumu od najmanje jedne godine</strong>"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "22. stav 2.", citat: "Ako se izdavanje traži radi izvršenja kazne za djela navedena u stavu 1 ovog člana, izdavanje će se odobriti ako je pravosnažno izrečena kazna, odnosno njen ostatak viši od šest mjeseci. Izdavanje će se takođe odobriti ako je potrebno izvršiti više kazni čiji je zbig viši od šest mjeseci.  "}],
                         opis: "kazna odnosno njen ostatak <strong>viši od šest mjeseci</strong> (izdvanje će se odobriti i ak oje potrebno izvršiti više kazni čiji je<strong> zbir viši od šest mjeseci</strong>)"},
      privremeni: "Privremeni pritvor se može ukinuti ako molba za izdavanje i prilozi ne budu dostavljeni zamoljenoj strani u roku od 30 dana, pritvor ni u kom slučaju ne može preći 45 dana računajući od časa pritvaranja",
      dokumentacija: ["original ili overeni prepis pravnosnažne odluke ili naloga za hapšenje ili bilo kojeg drugog dokumenta istog pravnog dejstva koji se sastavlja u obliku koji propisuje zakon strane molilje",
        "opis dela za koja se traži izdavanje, uz navođenje što je moguće tačnijih podataka o vremenu i mestu izvršenja dela, njegovu pravnu kvalifikaciju i tekst zakonskih odredaba koje se na njih mogu primenjivati",
        "prepis zakonskih odredaba koje se primenjuju na krivično delo ili, ako to nije moguće, zakonskih propisa koji se mogu primeniti, kao i što je moguće tačnije podatke o licu koje se traži i sve ostale podatke koji su pogodni za utvrđivanje njegovog identiteta i državljanstva",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Švicarska",
    kod: "CH",
    naziv: "Konvencija o izdavanju krivaca između Srbije i Švajcarske",
    tip: "konvencija",
    datum: "16/28.11.1887. godine",
    stupanje: "05.07.1888. godine",
    sl: "(„Službene novine“ broj: 83/1888)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : true, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "Članom 1. Konvencije ugovornice obavezane da na zahtjev izdaju drugoj optužena ili osuđena lica <strong>izuzimajući samo svoje podanike</strong>.",
                            clanovi:[{clan: "1.", citat: "Zemlje ugovornice obavezuju se, da na zahtjev, koji jedna od Vlada uputi drugoj, uzajamno izdaju jedna drugoj, izuzimajući samo svoje podanike, lica koja su iz Srbije pobjegla u Švajcarsku ili iz Švajcarske u Srbiju, a optužena su ili osuđena kao učinioci ili saučesnici nadležnim sudovima za zločine ili prestupe niže pobrojane...."}], termin:"PODANIK" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "", citat: ""}],
                      opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje"},
      izvrsenjeminimum: {upucujenaproces: undefined,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "", citat: ""}],
                        opis: "Konvencija precizira krivična djela za koja se dozvoljava izručenje"},
      privremeni: "Pritvor se izvršava na način i po pravilima koja su utvrđena zakonodavstvom zamolje države. Pritvor će prestati ako za 30 dana računajući od dana kad je počeo pritvor nije dostavljen zahtjev za izručenje",
      dokumentacija: ["osudno rješenje ili presuda prvostepenog suda, bilo rješenje o pritvoru ili drugo pismeno koje ima istu važnost u kojem je označena priroda i značaj optuženog djela i dan istog (misli se na vrijeme izvršenja))",
        "naznačeni akti, po mogućnosti, treba da sadrže lični opis potraživanog lica, prepis krivičnog zakona koji se primjenjuje ja krivično djelo",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },
  {
    ime: "Turska",
    kod: "TR",
    naziv: "Konvencija o izdavanju između  SFRJ i Republike Turske",
    tip: "konvencija",
    datum: "17.11.1973. godine",
    stupanje: "03.05.1975. godine",
    sl: "(„Službeni list SFRJ“ - Dodatak broj: 47/75)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi:[{clan: "3. stav 1. tačka a)", citat: "Izručenje se neće odobriti ako je osoba čije se izručenje zahtjeva bila državljanin zamoljene strane."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "2. stav 1. tačka a)", citat: "Izručenje se primjenjuje ako postoji jedno ili više djela koja se prema zakonima jedne i druge ugovornice kažnjavaju kaznom lišenja slobode od najmanje jedne godine ili težom kaznom."}],
                      opis: "ako postoji jedno ili više djela koja se prema zakonima jedne i druge ugovornice <strong>kažnjavaju</strong> kaznom lišenja slobode <strong>od najmanje jedne godine ili težom kaznom </strong>(ako je izručenje odobreno za jedno djelo biti će istovremeno ili naknadno odobreno i za krivično djelo (prema zakonodavstvu obje države) koje ne ispunjava ovaj uslov)"},
      izvrsenjeminimum: {upucujenaproces: true,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "2. stav 2. tačka b)", citat: "Izručenje se primjenjuje ako postoji osuda na kaznu lišenja slobode od najmanje šest mjeseci ili teža kazna koja je izrečena na teritoriju strane moliteljice za djelo spomenuto u tački a) ovog člana."}],
                         opis: "ako postoji osuda na kaznu lišenja slobode od <strong>najmanje šest mjeseci ili teža kazna</strong> koja je izrečena za djelo koje ispunjava uslov zaprijećene kazne (ako je izručenje odobreno po ovom osnovu biti će istovremeno ili naknadno odobreno i u pogledu zahtjeva/osude koja ne ispunjava pomenuti uslov uz poštivanje principa obostrane kažnjivosti djela iz osude"},
      privremeni: "Zamoljena država će što prije obavijestiti državu moliteljicu o pritvaranju uz napomenu da će pritvoreno lice biti pušteno na slobodu ako dokumenti ne budu podneseni u roku od 40 dana od dana hapšenja. <strong>Ako postoji potreba i na zahtjev države moliteljice</strong> privremeno hapšenje može biti produženo za razmno vrijeme koje ne može prijeći 20 dana. Ako je u ostavljenom roku podnesen zahtjev za izručenje privremeno hapšenje se produžava do donošenja odluke o izručenju i traje do predaje potraživanog lica.",
      dokumentacija: ["(u fazi <strong>prije osude)</strong> - nalog za hapšenje ili svaki drugi akt istog učinka, izlaganje činjenica uz navođenje datuma, mjesta i okolnosti u kojima je krivično djelo počinjeno, njegova zakonska kvalifikacija te, ako je potrebno radi informiranja, zapisnike sastavljene i ovjerene od strane javnog tužioca ili sudije",
        "(u fazi prije osude)dokumente koji sadrže lične podatke o osobi čije se izručenje zahtjeva i, po mogućnosti, izvadak iz knjige građanskog stanja te druge dokumente sa sadržanim ličnim opisom fotografijom i daktiloskopskim kartonom optuženog",
        "(ako je <strong>nepravosnažna osuda</strong>) - nalog za hapšenje okrivljenika ili svaki drugi akt istog učinka",
        "(ako je nepravosnažna osuda) - odluka o osudi",
        "(ako je nepravosnažna osuda) - dokumente o identitetu osobe čije se izručenje zahtijeva i, ako je moguće, izvadak iz knjige građanskog stanja okrivljenika te druge dokumente koji sadrže opis, fotografiju i daktiloskopski karton okrivljenika",
        "(ako je nepravosnažna osuda) - tekstove zakonskih odredaba koje se primjenuju na to krivično djelo",
        "(ako postoji <strong>pravosnažna osuda</strong>) - osuda koja sadržava obrazloženje sa naznakom da je osuda postala pravosnažna i izvršna",
        "(ako postoji pravosnažna osuda) - dokumenti o identitetu osobe čije se izručenje traži i, ako je moguće, izvadak iz knjige građanskog stanja okrivljenika te druge dokumente što sadržavaju osobni opis, fotografiju i daktiloskopski karton okrivljenika",
        "(ako postoji pravosnažna osuda) - tekstove zakonksih odredaba koji se primjenuju na to krivično djelo",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Ukrajina",
    kod: "UA",
    naziv: " Ugovor između FNRJ i SSSR-a o pravnoj pomoći u građanskim, porodičnim i krivičnim stvarima",
    tip: "ugovor",
    datum: "24.02.1962. godine",
    stupanje: "26.05.1963. godine",
    sl: "(„Službeni list FNRJ“ - Dodatak broj: 5/63)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : false, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "", clanovi:[{clan: "59. stav 1. tačka a)", citat: "Nema mjesta izdavanju ako je lice čije se izdavanje traži državljanin zamoljene strane ugovornice."}], termin:"ZAMOLJENA" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "58. stav 2.", citat: "Izdavanje se vrši samo za ona djela koja su po zakonu obe strane ugovornice predviđena kao krivična djela za koja se može izreči kazna lišenja slobode u trajanju dužem od jedne godine ili teža kazna."}],
                      opis: "za krivična djela prema pravu obje strane ugovornice za koja se <strong>može izreći</strong> kazna lišenja slobode u trajanju <strong>dužem od jedne godine ili teža kazna</strong>"},
      izvrsenjeminimum: {upucujenaproces: false,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "58. stav 2.", citat: "Izdavanju radi izvršenja kazne ima mjesta ako je presuda postala pravosnažna i ako je lice čije se izdavanje traži osuđeno na kaznu lišenja slobode u trajanju dužem od jedne godine ili na težu kaznu."}],
                         opis: "kazna lišenja slobode u trajanju <strong>dužem od jedne godine ili na težu kaznu</strong>"},
      privremeni: "Lice lišeno slobode biće pušteno na slobodu ako u roku od trideset dana od dana prijema obavještenja o lišenju slboode ne bude primljen zahtjev za izdavanje.",
      dokumentacija: ["ovjeren prepis odluke o lišenju slboode, odnsono prepis pravosnažne presude ili druge odgovarajuće sudske odluke ako se zahtjev odnosi na izdavanje radi izvršenja kazne",
        "po potrebi dopunske podatke o činjeničnom stanju, pravnoj kvalifikaciji djela i obimu štete pričinjene krivčnim djelom",
        "tekst krivičnog zakona po kome se kvalifikuje odnosno krivično djelo na teritoriji strane ugovornice koja traži izdavanje",
        "lični opis lica čije se izdavanje traži, podaci o državljanstvu i boravištu, a po mogućnosti fotografiju i otiske prstiju",
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }
    }
  },
  {
    ime: "Ujedinjeno Kraljevstvo (Velike Britanije i Sjeverne Irske)",
    kod: "GB",
    naziv: "Ugovor o uzajamnom izdavanju krivaca između Srbije i Velike Britanije",
    tip: "ugovor",
    datum: "06.12.1900. godine",
    stupanje: "23.02.1901. godine",
    sl: " („Srpske novine“ broj: 35/1901)",
    izmjene: "NE",
    naziv2: "",
    datum2: "",
    stupanje2: "",
    sl2: "",
    preuzet: "DA",
    pojedinosti: {
      precizirasamokd : true, // Da li ugovor precizira samo KD za koja je dozvoljeno izručenje
      izrucenjevlastitih: { status: "NE", komentar: "Svaka Ugovorna Strana <strong>može</strong>, po svome nahođenju, <strong>odbiti izdavanje Svojih sopstvenih podanika</strong> drugoj Strani.",
                            clanovi:[{clan: "III", citat: "Svaka Ugovorna Strana može, po svome nahođenju, odbiti izdavanje Svojih sopstvenih podanika drugoj Strani."}], termin:"PODANIK" }, // NE, POJEDINAKD, NEJASNO
      pojedinakd: [""],
      procesminimum: {clanovi: [{clan: "", citat: ""}],
                      opis: "Ugovor precizira krivična djela za koja se dozvoljava izručenje."},
      izvrsenjeminimum: {upucujenaproces: undefined,  //Da li krivično djelo za koje je osuđen mora imati kvalifikaciju iz procesminimum
                        clanovi: [{clan: "", citat: ""}],
                         opis: "Ugovor precizira krivična djela za koja se dozvoljava izručenje."},
      privremeni: "Ako za dva mjeseca, od dana kad je lice, čije se izdavanje traži, pritvoreno, ili za duže vrijeme, koje bi umoljena Država odnosno nadležni Sud odredio, nisu podnesena dovoljna dokazna sredstva, koja bi izdavanje mogla opravdati, potraživano lice će se pustiti na slobodu",
      dokumentacija: ["naredba o stavljanju u pritvor, izdata od strane nadležne vlasti Države koja traži izdavanje",
        "dokazi koji bi, prema zakonima mesta gde se okrivljeno lice nalazi, opravdali njegovo stavljanje u pritvor u slučaju da je delo učinjeno tamo",
        "ako je lice već osuđeno, treba traženju priložiti osudnu presudu, izdatu od strane nadležnog suda u zemlji, koja traži izdavanje",
        "Presuda izrečena in contumaciam, neće se smatrati kao osudna presuda ali sa tako osuđenim licem moći će se postupiti kao s okrivljenim licem.",
        "iz autentičnog i nadležno ovjerenja o osudi ili naredbe da se okrivljeno lice stavi u pritvor treba biti vidljiva priroda zločina ili prestupa, zbog kojega je i otvorena krivična istraga protiv potraživanog",
        "naznačenim dokumentima priložiće se lični opis i drugi podaci potrebni za potvrdu identiteta potraživanog lica"
      ],
      vremenskaprimjena: { postoji: false, od: "", citat: "" }

    }
  },


]