import { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert';


const Upozorenje = ({ variant, children }) => {
    const [show, setShow] = useState(true)

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setShow(false)
        }, 4000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    // Briši
    if (!show) {
        return null;
    }

    // Prikaži alert kroz timeout vrijeme  
    return (
        <Alert variant={`${variant}`}>
            {children}
        </Alert>
    )
}

/* 
Upozorenje.defaultPros = {
    variant: 'warning',
}
*/
export default Upozorenje;