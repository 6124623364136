import React from 'react'
import { useGlobalContext } from '../context'
import Snipet from './dodatneopcije/Snipet'

const Obrazlozenjekraj = () => {

    const { datumodluke, vrstaOdluke, datumlisenja, PRkrajlisenja, EKSkrajlisenja, PROkrajlisenja, pJ,
        potraživani, potraživanog, potraživanom, izjasnio, mu_je, njegovo, njegovom } = useGlobalContext()

    return (
        <>


            {vrstaOdluke === "ODB" ?
                <>
                    <p style={pJ}><Snipet kod="ODBobrazloženjekraj" /></p>
                </>

                : <></> /*ODB */}

            {vrstaOdluke === "MZ" ? <>
                <p style={pJ}>Prilikom donšenja ove odluke Sud je, dakle, primjenio odredbu člana 123. stav 2. ZKP BiH koji procesni zakon se
                    alternativno primjenjuje u postupcima izručenja, a kojim je propisana obaveza Suda da pri izboru mjera procesne prinude vodi račuana
                    da se ne primjenjuje teža mjera ako se ista svrha može postići blažom mjerom.</p>
                <p style={pJ}>
                    <Snipet kod="MZobrazlozenje" tip="opis" />
                </p>
                <p style={pJ}>Izricanjem mjere zabrane na način naveden u dispozitivu rješenja Sud smatra dovoljno efikasnim sredstvom za osiguranje
                    neometanog vođenja izviđajnih radnji u predmetnom postupku.</p>
                <p style={pJ}><Snipet kod="MZobrazlozenje" tip="organ" /></p>
                <p style={pJ}>Rješenje o određivanju  mjera zabrane {potraživanom} će se dostaviti Tužilaštvu Bosne i Hercegovine  i Ministarstvu
                    pravde BiH, radi traženja molbe od strane države koja je raspisala potjernicu.</p>
            </> : <></>}
            {vrstaOdluke === "PR"
                ?
                <>
                    <p style={pJ}>S obzirom da se {potraživani} {izjasnio} da ne želi izručenje u skraćenom postupku, Sud {mu_je}, zbog svih
                        naprijed iznesenih razloga, odredio privremeni pritvor koji po ovom rješenju može trajati najduže 18
                        (osamnaest) dana od dana lišenja slobode, odnosno od {datumlisenja || "__________"} do {PRkrajlisenja || "__________"} ili do nove odluke Suda.<br /><br />
                        Prilikom odlučivanja o mjeri pritvora, Sud je cijenio i mogućnost izricanja neke blaže mjere kojom
                        bi se osigurala dostupnost {potraživanog} u ovom postupku, međutim, sve naprijed navedene okolnosti ukazuju
                        da se jedino mjerom pritvora može u potpunosti osigurati prisustvo {potraživanog} i spriječiti {njegovo}
                        bjekstvo, dok, s druge strane, Sud u fazi odlučivanja o {njegovom} privremenom pritvaranju nije
                        našao okolnosti koje bi išle u prilog određivanju neke od mjera zabrane odnosno, okolnosti na
                        osnovu koje bi iste bilo objektivno moguće provesti.</p>
                    <p style={pJ}>Privremeni pritvor se, u smislu člana 38.
                        stav 5. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima, može produžiti, ali isti ne može
                        trajati duže od 40 (četrdeset) dana.</p>
                    <p style={pJ}>U smislu člana 38. stav 4. Zakona o međunarodnoj pravnoj
                        pomoći u krivičnim stvarima, rješenje o određivanju pritvora će se dostaviti Tužilaštvu Bosne i
                        Hercegovine i Ministarstvu pravde BiH, radi traženja molbe od strane države koja je raspisala
                        potjernicu.</p>
                    <p style={pJ}>S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja,
                        primjenom citiranih zakonskih propisa.</p>

                </>
                : <></>}
            {vrstaOdluke === "PREKS"
                ?
                <>
                    <p style={pJ}>Zbog navedenih razloga, prema {potraživanom} je određen ekstradicioni pritvor koji po ovom rješenju može
                        trajati najduže 6 (šest) mjeseci od dana lišenja slobode, odnosno od
                        {datumlisenja || "__________"} do {EKSkrajlisenja || "__________"},
                        odnosno do izvršenja odluke o izručenju pojednostavljanim načinom ili do nove odluke Suda.</p>
                    <p style={pJ}>Prilikom odlučivanja o mjeri privremenog pritvora, Sud je, u smislu člana 5. Evropske konvencije o
                        ljudskim pravima i osnovnim slobodama, cijenio i mogućnost izricanja neke blaže mjere kojom bi se obezbjedila
                        dostupnost {potraživanog} u ovom postupku, međutim, sve naprijed navedene okolnosti ukazuju da se za sada jedino
                        mjerom  pritvora može u potpunosti osigurati {njegovo} prisustvo i spriječiti  bjekstvo, posebno iz razloga što
                        Sud u spisu ne raspolaže niti jednim dokazom iz kojeg bi se mogao izvesti zaključak o opravdanosti primjene
                        nekih drugih mjera za osiguranje prisustva {potraživanog}, te da je mjera određivanja ekstradicionog pritvora
                        jedina adekvatna i proporcionalna u datoj pravnoj situaciji.</p>
                    <p style={pJ}>Na osnovu člana 52. stav 5. Zakona o
                        međunarodnoj pravnoj pomoći u krivičnim stvarima, koji je izmjenjen članom 26. stav 6. Zakona o izmjenama i dopunama Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima, zapisnik sa ročišta od {datumodluke} sa sadržanom saglasnošću {potraživanog} za pojednostavljeno
                        izručenje biće bez odgađanja dostavljen Ministarstvu pravde Bosne i Hercegovine, na osnovu koga će ministar
                        pravde donijeti rješenje kojim će odlučiti o izručenju {potraživanog}.</p>
                    <p style={pJ}>S obzirom na navedeno, Sud je
                        odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih propisa.</p>
                </>
                :
                <></>}
            {vrstaOdluke === "PRO"
                ?
                <>
                    <p style={pJ}>Prilikom odlučivanja o mjeri pritvora, Sud je, kao i u dosadašnjem toku postupka, cijenio i mogućnost
                        izricanja neke blaže mjere kojom bi se osigurala dostupnost {potraživanog} u ovom postupku, međutim,
                        sve naprijed navedene okolnosti ukazuju da se jedino mjerom pritvora može u potpunosti osigurati
                        prisustvo {potraživanog} i spriječiti {njegovo} bjekstvo ili preduzimanje radnju u cilju skrivanja
                        što bi onemogućilo okončanje postupka izručenja, odnosno realizaciju eventualnog izručenja. Ovo posebno
                        iz razloga što Sud, ni u ovoj fazi postupka, u spisu ne raspolaže nikakvim dokazima iz kojih bi se mogao
                        izvesti zaključak o opravdanosti primjene nekih drugih mjera za osiguranje prisustva {potraživanog}.</p>
                    <p style={pJ}>S obzirom na navedeno, Sud je prema {potraživanom} produžio mjeru privremenog pritvora, koji
                        po ovom rješenju može trajati do dostavljanja ekstradicione molbe i dokumentacije od strane države
                        moliteljice, ali ne duže od 40 (četrdeset) dana, računajući od dana lišenja slobode, odnosno od {datumlisenja || "__________"} do {PROkrajlisenja || "__________"} ili do nove odluke Suda.</p>
                    <p style={pJ}>U slučaju da država molteljica u datom roku ne dostavi ekstradicionu molbu i dokumentaciju,
                        Sud će, primjenom člana 38. stav 6. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima,
                        ukinuti privremeni pritvor {potraživanom}.</p>
                    <p style={pJ}>S obzirom na navedeno, Sud je odlučio kao u dispozitivu
                        ovog rješenja, primjenom citiranih zakonskih propisa.</p>

                </>
                : <></>}
            {vrstaOdluke === "EKS"
                ?
                <>
                    <p style={pJ}>Sud je prilikom donošenja ove odluke, u smislu člana 39. stav 5. Zakona o međunarodnoj pravnoj pomoći u
                        krivičnim stvarima, cijenio i mogućnost izricanja neke blaže mjere kojom bi se osigurala dostupnost
                        {potraživanog}  u ovom postupku, međutim, sve naprijed navedene  okolnosti ukazuju da se jedino mjerom
                        pritvora može u potpunosti osigurati prisustvo {potraživanog} i spriječiti {njegovo} napuštanje BiH ili
                        preduzimanje radnju u cilju skrivanja što bi onemogućilo okončanje postupka izručenja, odnosno realizaciju
                        eventualnog izručenja. Ovo  posebno iz razloga što Sud u spisu ne raspolaže nikakvim dokazima iz kojih bi
                        se mogao izvesti zaključak o opravdanosti primjene nekih drugih mjera za osiguranje prisustva {potraživanog}.</p>
                    <p style={pJ}>Odluku o trajanju mjere ekstradicionog pritvora Sud je donio na osnovu odredbe 39. stav 3.
                        Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima tako da isti, obzirom na vrijeme lišenja
                        {potraživanog}, može trajati najduže do {EKSkrajlisenja || "__________"}.</p>
                    <p style={pJ}>U smislu  člana 39. stav 6. Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima,
                        Ministarstvo pravde Bosne i Hercegovine će biti obaviješteno o određivanju ekstradicionog pritvora
                        prema {potraživanom}, kako bi o tome izvjestilo  državu moliteljicu.</p>
                    <p style={pJ}>S obzirom na navedeno, Sud je odlučio kao u dispozitivu ovog rješenja, primjenom citiranih zakonskih
                        propisa.</p>

                </>
                : <></>}



        </>
    )
}

export default Obrazlozenjekraj