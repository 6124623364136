import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import {Col, Row, Table, Button, ButtonGroup, ToggleButton, Modal, Form, InputGroup, Spinner, Alert  } from 'react-bootstrap';

const PregledStranaka = () => {
  const navigate = useNavigate();
  const { subRoute } = useParams();

  const prmijeniPutanju = (route) => {
    const newPath = `/pregledstranaka/${route}`;
    navigate(newPath);
              //ovdje bi moglo na osnovu subRouta uraditi preuzimanje stranaka 
  };

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [prikazanModal, setprikazanModal] = useState(false); 
  const [stranka, setStranka] = useState(null); 

  // sudije, tuzioci, zapisnicari, advokati, mupovi
  const [sudije, setSudije] = useState([]);
  const [tuzioci, setTuzioci] = useState([]);
  const [zapisnicari, setZapisnicari] = useState([]);
  const [advokati, setAdvokati] = useState([]);
  const [mupovi, setMupovi] = useState([]);
  const [greske, setGreske] = useState([]);



  const otvoriModal = (id, naziv) => {
    setStranka({ id, naziv });
    setprikazanModal(true);
  };

  const zatvoriModal = () => {
    setStranka(null);
    setprikazanModal(false);

    if (subRoute === "sudije") {
      preuzmiSudije()
    } else if (subRoute === "tuzioci") {
      preuzmiTuzioce()
    } else if (subRoute === "zapisnicari") {
      preuzmiZapisnicare()
    } else if (subRoute === "advokati") {
      preuzmiAdvokate()
    } else if (subRoute === "mupovi") {
      preuzmiMupove()
    } else if (subRoute === "greske") {
      preuzmiGreske()
    }  else {
      preuzmiSudije()
    }
  
  
  }; //zatvoriModal

  useEffect(() => {  //ucitaj sudije prilikom prvog otvaranja aplikacije PregledStranaka
    preuzmiSudije();
    preuzmiTuzioce();
    preuzmiZapisnicare()
    preuzmiAdvokate()
    preuzmiMupove()
    preuzmiGreske()
  }, []);

  const brisiSudiju = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/sudija/${id}`);
    if (response.status === 200) {
      preuzmiSudije();
    }
  };

  const brisiTuzioca = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/tuzilac/${id}`);
    if (response.status === 200) {
      preuzmiTuzioce();
    }
  };

  const brisiZapisnicara = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/zapisnicar/${id}`);
    if (response.status === 200) {
      preuzmiZapisnicare();
    }
  };
  
  const brisiAdvokata = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/advokat/${id}`);
    if (response.status === 200) {
      preuzmiAdvokate();
    }
  };

  const brisiMup = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/mup/${id}`);
    if (response.status === 200) {
      preuzmiMupove();
    }
  };

  const brisiGresku = async (id) => {
    const response = await axios.delete(`https://liste-odluka.onrender.com/greska/${id}`);
    if (response.status === 200) {
      preuzmiGreske();
    }
  };

  const preuzmiSudije = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/sudije');
      if (response.status === 200) {
        setSudije(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja sudija.');
    } finally {
      setIsLoading(false);
    }
  };

  const preuzmiTuzioce = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/tuzioci');
      if (response.status === 200) {
        setTuzioci(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja tužilaca.');
    } finally {
      setIsLoading(false);
    }
  };

  const preuzmiZapisnicare = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/zapisnicari');
      if (response.status === 200) {
        setZapisnicari(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja zapisničara.');
    } finally {
      setIsLoading(false);
    }
  };

  const preuzmiAdvokate = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/advokati');
      if (response.status === 200) {
        setAdvokati(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja advokata.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const preuzmiMupove = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/mupovi');
      if (response.status === 200) {
        setMupovi(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja MUP-a/Policijske stanice.');
    } finally {
      setIsLoading(false);
    }
  };

  const preuzmiGreske = async () => {
    setIsLoading(true); 
    try {
      const response = await axios.get('https://liste-odluka.onrender.com/greske');
      if (response.status === 200) {
        setGreske(response.data);
      }
    } catch (error) {
      setError('Greška prilikom učitavanja (lista prijavljenih grešaka).');
    } finally {
      setIsLoading(false);
    }
  };

  const IzmjenaStranke = () => {

    const IzmijeniSudiju = () => {
          
            const [ucitavaSe, setUcitavaSe] = useState(false);
            const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
            const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");

            const [editujeSe, setEditujeSe] = useState(false);
            const [editujeSeAlert, setEditujeSeAlert] = useState(false);
            const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");

            const intialPodaci = { ime: "", spol: ""};
          
            const [podaci, setPodaci] = useState(intialPodaci)
            const {ime, spol} = podaci;

            const handleSubmit = (e) => {
              e.preventDefault()
              izmjenaSudije(podaci)
            } //handleSubmit

            const izmjenaSudije = async (podaci) => {
              setEditujeSe(true);
              try {
                const response = await axios.put(`https://liste-odluka.onrender.com/sudija/${podaci.id}`, podaci);
                // Handle the response as needed
                setEditujeSeAlertMsg('Uspješno izmijenjeni podaci sudije.');
              } catch (error) {
                if (error.response && error.response.data) {
                  setEditujeSeAlertMsg(error.response.data);
                } else {
                  setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
                }
              } finally {
                setEditujeSe(false);
                setEditujeSeAlert(true);
                // Sakrij alert nakon 4 sec.
                setTimeout(() => {
                  setEditujeSeAlert(false);
                  setEditujeSeAlertMsg('');
                }, 4000);
              }
            }; //izmjenaSudije
            
            
            const handleChange = (e) => {
            let {name, value} = e.target;
            setPodaci({...podaci, [name]: value})  
            }//handleChange

            //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
            useEffect( () => {
                if(stranka.id){
                  preuzmiStranku(stranka.id)
                }
            }, [])

            const preuzmiStranku = async (id) => {
              setUcitavaSe(true);
              try {
                const response = await axios.get(`https://liste-odluka.onrender.com/sudija/${id}`);
                setPodaci({...response.data[0]})
              } catch (error) {
                  setUcitavaSe(false);
                  if (error.response && error.response.data) {
                    setUcitavaSeAlertMsg(error.response.data);
                  } else {
                    setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
                  }
                  setUcitavaSeAlert(true);
          
                  setTimeout(() => {
                    setUcitavaSeAlert(false);
                    setUcitavaSeAlertMsg('');
                  }, 4000);
              } finally {
                setUcitavaSe(false);       
              }
            }; //preuzmiStranku

              return (<>
                            {ucitavaSe ? 
                              <div className='d-flex justify-content-center'>
                                  <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                             </div>
                            :
                            ucitavaSeAlert ?
                              <Alert variant="danger" className="alertV2">
                              {ucitavaSeAlertMsg}
                              </Alert>
                            :
                                <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                  <Form.Group as={Col}>
                                  
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="sudija-ime">Ime i prezime</InputGroup.Text>
                                        <Form.Control
                                          placeholder="Ime i prezime"
                                          aria-label="Ime"
                                          aria-describedby="sudija-ime"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="ime"
                                          value={ime}
                                        />
                                      </InputGroup>
                                  </Form.Group>

                                  <Form.Group as={Col} md="4">
                                    <ButtonGroup className='float-end'>
                                      <ToggleButton
                                        id="sudija-spol-m"
                                        type="radio"
                                        variant={spol === "m" ? 'secondary' : 'outline-secondary'}
                                        name="spol"
                                        value="m"
                                        checked={spol === "m"}
                                        onChange={handleChange}
                                        >
                                        muško
                                      </ToggleButton>
                                      <ToggleButton
                                        id="sudija-spol-ž"
                                        type="radio"
                                        variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
                                        name="spol"
                                        value="ž"
                                        checked={spol === "ž"}
                                        onChange={handleChange}
                                        >
                                        žensko
                                      </ToggleButton>
                                    </ButtonGroup>
                                      
                                    </Form.Group>

                                </Row>

                                {editujeSe ? 
                                  <Button variant="primary float-end" disabled>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {" "}Izmijeni ...
                                </Button>
                                :
                                <Button variant="primary float-end" type="submit">
                                  Izmijeni
                                </Button>
                                }  
                              </Form>
                            }

                  
                            
                          {editujeSeAlert &&  
                          <Alert variant="success" className="alertV2">
                            {editujeSeAlertMsg}
                          </Alert> }

                    </>)

    } //izmijeniSudiju

       //TUŽIOCI
    const IzmijeniTuzioca = () => {
          
      const [ucitavaSe, setUcitavaSe] = useState(false);
      const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
      const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");

      const [editujeSe, setEditujeSe] = useState(false);
      const [editujeSeAlert, setEditujeSeAlert] = useState(false);
      const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");

      const intialPodaci = { ime: "", spol: ""};
    
      const [podaci, setPodaci] = useState(intialPodaci)
      const {ime, spol} = podaci;

      const handleSubmit = (e) => {
        e.preventDefault()
        izmjenaTuzioca(podaci)
      } //handleSubmit
      
   
      const izmjenaTuzioca = async (podaci) => {
        setEditujeSe(true);
        try {
          const response = await axios.put(`https://liste-odluka.onrender.com/tuzilac/${podaci.id}`, podaci);          
          setEditujeSeAlertMsg('Uspješno izmijenjeni podaci tužioca.');
        } catch (error) {
          if (error.response && error.response.data) {
            setEditujeSeAlertMsg(error.response.data);
          } else {
            setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
          }
        } finally {
          setEditujeSe(false);
          setEditujeSeAlert(true);
          // Sakrij alert nakon 4 sec.
          setTimeout(() => {
            setEditujeSeAlert(false);
            setEditujeSeAlertMsg('');
          }, 4000);
        }
      }; //izmjenaTuzioca
      
      
      const handleChange = (e) => {
      let {name, value} = e.target;
      setPodaci({...podaci, [name]: value})  
      }//handleChange

      //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
      useEffect( () => {
          if(stranka.id){
            preuzmiStranku(stranka.id)
          }
      }, [])

      const preuzmiStranku = async (id) => {
        setUcitavaSe(true);
        try {
          const response = await axios.get(`https://liste-odluka.onrender.com/tuzilac/${id}`);
          setPodaci({...response.data[0]})
        } catch (error) {
            setUcitavaSe(false);
            if (error.response && error.response.data) {
              setUcitavaSeAlertMsg(error.response.data);
            } else {
              setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
            }
            setUcitavaSeAlert(true);
    
            setTimeout(() => {
              setUcitavaSeAlert(false);
              setUcitavaSeAlertMsg('');
            }, 4000);
        } finally {
          setUcitavaSe(false);       
        }
      }; //preuzmiStranku

        return (<>
                      {ucitavaSe ? 
                        <div className='d-flex justify-content-center'>
                            <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                       </div>
                      :
                      ucitavaSeAlert ?
                        <Alert variant="danger" className="alertV2">
                        {ucitavaSeAlertMsg}
                        </Alert>
                      :
                          <Form onSubmit={handleSubmit}>
                          <Row className="mb-3">
                            <Form.Group as={Col}>
                            
                              <InputGroup className="mb-2">
                                  <InputGroup.Text id="tuzilac-ime">Ime i prezime</InputGroup.Text>
                                  <Form.Control
                                    placeholder="Ime i prezime"
                                    aria-label="Ime"
                                    aria-describedby="tuzilac-ime"
                                    spellCheck={false}
                                    onChange={handleChange}
                                    name="ime"
                                    value={ime}
                                  />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                              <ButtonGroup className='float-end'>
                                <ToggleButton
                                  id="tuzilac-spol-m"
                                  type="radio"
                                  variant={spol === "m" ? 'secondary' : 'outline-secondary'}
                                  name="spol"
                                  value="m"
                                  checked={spol === "m"}
                                  onChange={handleChange}
                                  >
                                  muško
                                </ToggleButton>
                                <ToggleButton
                                  id="tuzilac-spol-ž"
                                  type="radio"
                                  variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
                                  name="spol"
                                  value="ž"
                                  checked={spol === "ž"}
                                  onChange={handleChange}
                                  >
                                  žensko
                                </ToggleButton>
                              </ButtonGroup>
                                
                              </Form.Group>

                          </Row>

                          {editujeSe ? 
                            <Button variant="primary float-end" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {" "}Izmijeni ...
                          </Button>
                          :
                          <Button variant="primary float-end" type="submit">
                            Izmijeni
                          </Button>
                          }  
                        </Form>
                      }

            
                      
                    {editujeSeAlert &&  
                    <Alert variant="success" className="alertV2">
                      {editujeSeAlertMsg}
                    </Alert> }

              </>)

      } //IzmijeniTuzioca

      const IzmijeniZapisnicara = () => {
          
        const [ucitavaSe, setUcitavaSe] = useState(false);
        const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
        const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");
  
        const [editujeSe, setEditujeSe] = useState(false);
        const [editujeSeAlert, setEditujeSeAlert] = useState(false);
        const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");
  
        const intialPodaci = { ime: "", spol: ""};
      
        const [podaci, setPodaci] = useState(intialPodaci)
        const {ime, spol} = podaci;
  
        const handleSubmit = (e) => {
          e.preventDefault()
          izmjenaZapisnicara(podaci)
        } //handleSubmit
        
     
        const izmjenaZapisnicara = async (podaci) => {
          setEditujeSe(true);
          try {
            const response = await axios.put(`https://liste-odluka.onrender.com/zapisnicar/${podaci.id}`, podaci);          
            setEditujeSeAlertMsg('Uspješno izmijenjeni podaci zapisničara/saradnika.');
          } catch (error) {
            if (error.response && error.response.data) {
              setEditujeSeAlertMsg(error.response.data);
            } else {
              setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
            }
          } finally {
            setEditujeSe(false);
            setEditujeSeAlert(true);
           
            setTimeout(() => {
              setEditujeSeAlert(false);
              setEditujeSeAlertMsg('');
            }, 4000);
          }
        }; //izmjenaZapisnicara
        
        
        const handleChange = (e) => {
        let {name, value} = e.target;
        setPodaci({...podaci, [name]: value})  
        }//handleChange
  
        //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
        useEffect( () => {
            if(stranka.id){
              preuzmiStranku(stranka.id)
            }
        }, [])
  
        const preuzmiStranku = async (id) => {
          setUcitavaSe(true);
          try {
            const response = await axios.get(`https://liste-odluka.onrender.com/zapisnicar/${id}`);
            setPodaci({...response.data[0]})
          } catch (error) {
              setUcitavaSe(false);
              if (error.response && error.response.data) {
                setUcitavaSeAlertMsg(error.response.data);
              } else {
                setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
              }
              setUcitavaSeAlert(true);
      
              setTimeout(() => {
                setUcitavaSeAlert(false);
                setUcitavaSeAlertMsg('');
              }, 4000);
          } finally {
            setUcitavaSe(false);       
          }
        }; //preuzmiStranku
  
          return (<>
                        {ucitavaSe ? 
                          <div className='d-flex justify-content-center'>
                              <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                         </div>
                        :
                        ucitavaSeAlert ?
                          <Alert variant="danger" className="alertV2">
                          {ucitavaSeAlertMsg}
                          </Alert>
                        :
                            <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                              <Form.Group as={Col}>
                              
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="zapisnicar-ime">Ime i prezime</InputGroup.Text>
                                    <Form.Control
                                      placeholder="Ime i prezime"
                                      aria-label="Ime"
                                      aria-describedby="zapisnicar-ime"
                                      spellCheck={false}
                                      onChange={handleChange}
                                      name="ime"
                                      value={ime}
                                    />
                                  </InputGroup>
                              </Form.Group>
  
                              <Form.Group as={Col} md="4">
                                <ButtonGroup className='float-end'>
                                  <ToggleButton
                                    id="zapisnicar-spol-m"
                                    type="radio"
                                    variant={spol === "m" ? 'secondary' : 'outline-secondary'}
                                    name="spol"
                                    value="m"
                                    checked={spol === "m"}
                                    onChange={handleChange}
                                    >
                                    muško
                                  </ToggleButton>
                                  <ToggleButton
                                    id="zapisnicar-spol-ž"
                                    type="radio"
                                    variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
                                    name="spol"
                                    value="ž"
                                    checked={spol === "ž"}
                                    onChange={handleChange}
                                    >
                                    žensko
                                  </ToggleButton>
                                </ButtonGroup>
                                  
                                </Form.Group>
  
                            </Row>
  
                            {editujeSe ? 
                              <Button variant="primary float-end" disabled>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {" "}Izmijeni ...
                            </Button>
                            :
                            <Button variant="primary float-end" type="submit">
                              Izmijeni
                            </Button>
                            }  
                          </Form>
                        }
  
              
                        
                      {editujeSeAlert &&  
                      <Alert variant="success" className="alertV2">
                        {editujeSeAlertMsg}
                      </Alert> }
  
                </>)
  
        } //IzmijeniZapisnicara

        const IzmijeniAdvokata = () => {
          
          const [ucitavaSe, setUcitavaSe] = useState(false);
          const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
          const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");
    
          const [editujeSe, setEditujeSe] = useState(false);
          const [editujeSeAlert, setEditujeSeAlert] = useState(false);
          const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");
    
          const intialPodaci = { ime: "", spol: "", adresa: "", kontakt: "", grad: "", gradgen: ""};
        
          const [podaci, setPodaci] = useState(intialPodaci)
          const {ime, spol, adresa, kontakt, grad, gradgen} = podaci;
    
          const handleSubmit = (e) => {
            e.preventDefault()
            izmjenaAdvokata(podaci)
          } //handleSubmit
          
       
          const izmjenaAdvokata = async (podaci) => {
            setEditujeSe(true);
            try {
              const response = await axios.put(`https://liste-odluka.onrender.com/advokat/${podaci.id}`, podaci);          
              setEditujeSeAlertMsg('Uspješno izmijenjeni podaci advokata.');
            } catch (error) {
              if (error.response && error.response.data) {
                setEditujeSeAlertMsg(error.response.data);
              } else {
                setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
              }
            } finally {
              setEditujeSe(false);
              setEditujeSeAlert(true);
             
              setTimeout(() => {
                setEditujeSeAlert(false);
                setEditujeSeAlertMsg('');
              }, 4000);
            }
          }; //izmjenaAdvokata
          
          const handleChange = (e) => {
          let {name, value} = e.target;
          setPodaci({...podaci, [name]: value})  
          }//handleChange
    
          //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
          useEffect( () => {
              if(stranka.id){
                preuzmiStranku(stranka.id)
              }
          }, [])
    
          const preuzmiStranku = async (id) => {
            setUcitavaSe(true);
            try {
              const response = await axios.get(`https://liste-odluka.onrender.com/advokat/${id}`);
              setPodaci({...response.data[0]})
            } catch (error) {
                setUcitavaSe(false);
                if (error.response && error.response.data) {
                  setUcitavaSeAlertMsg(error.response.data);
                } else {
                  setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
                }
                setUcitavaSeAlert(true);
        
                setTimeout(() => {
                  setUcitavaSeAlert(false);
                  setUcitavaSeAlertMsg('');
                }, 4000);
            } finally {
              setUcitavaSe(false);       
            }
          }; //preuzmiStranku
    
            return (<>
                          {ucitavaSe ? 
                            <div className='d-flex justify-content-center'>
                                <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                           </div>
                          :
                          ucitavaSeAlert ?
                            <Alert variant="danger" className="alertV2">
                            {ucitavaSeAlertMsg}
                            </Alert>
                          :
                              <Form onSubmit={handleSubmit}>
                            
                                
                                <Row>
                                <Form.Group as={Col}>
                                
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="advokat-ime">Ime i prezime</InputGroup.Text>
                                    <Form.Control
                                      placeholder="Ime i prezime"
                                      aria-label="Ime"
                                      aria-describedby="advokat-ime"
                                      spellCheck={false}
                                      onChange={handleChange}
                                      name="ime"
                                      value={ime}
                                    />
                                  </InputGroup>
                              </Form.Group>
  
                              <Form.Group as={Col} md="4">
                                <ButtonGroup className='float-end'>
                                  <ToggleButton
                                    id="zapisnicar-spol-m"
                                    type="radio"
                                    variant={spol === "m" ? 'secondary' : 'outline-secondary'}
                                    name="spol"
                                    value="m"
                                    checked={spol === "m"}
                                    onChange={handleChange}
                                    >
                                    muško
                                  </ToggleButton>
                                  <ToggleButton
                                    id="advokat-spol-ž"
                                    type="radio"
                                    variant={spol === "ž" ? 'secondary' : 'outline-secondary'}
                                    name="spol"
                                    value="ž"
                                    checked={spol === "ž"}
                                    onChange={handleChange}
                                    >
                                    žensko
                                  </ToggleButton>
                                </ButtonGroup>
                                  
                                </Form.Group>
                               
                                </Row>
                               

                                  <Row>
                                  <Form.Group as={Col}>                      
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="advokat-grad">Grad</InputGroup.Text>
                                        <Form.Control
                                          placeholder="Grad"
                                          aria-label="Grad"
                                          aria-describedby="advokat-grad"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="grad"
                                          value={grad}
                                        />
                                      </InputGroup>
                                   </Form.Group>

                                   <Form.Group as={Col}>                      
                                    <InputGroup className="mb-2">
                                      
                                        <Form.Control
                                          placeholder="Unesite naziv grada u genitivu npr. Sarajeva"
                                          aria-label="Naziv grada u genitivu"
                                          aria-describedby="grad"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="grad"
                                          value={gradgen}
                                        />
                                      </InputGroup>
                                   </Form.Group>
                                  </Row>

                                
                                  <Form.Group as={Col}>                      
                                    <InputGroup className="mb-2">
                                      
                                        <Form.Control
                                          placeholder="Adresa sjedišta advokata"
                                          aria-label="Adresa sjedišta advokata"
                                          aria-describedby="adresa"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="adresa"
                                          value={adresa}
                                        />
                                      </InputGroup>
                                   </Form.Group>

                                   <Form.Group as={Col}>                      
                                    <InputGroup className="mb-2">
                                      
                                        <Form.Control
                                          placeholder="Kontakt podaci"
                                          aria-label="Kontakt"
                                          aria-describedby="kontakt"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="kontakt"
                                          value={kontakt}
                                        />
                                      </InputGroup>
                                   </Form.Group>

                              {editujeSe ? 
                                <Button variant="primary float-end" disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                {" "}Izmijeni ...
                              </Button>
                              :
                              <Button variant="primary float-end" type="submit">
                                Izmijeni
                              </Button>
                              }  
                            </Form>
                          }
    
                
                        {editujeSeAlert &&  
                        <Alert variant="success" className="alertV2">
                          {editujeSeAlertMsg}
                        </Alert> }
    
                  </>)
    
          } //IzmijeniAdvokata
  
          const IzmijeniMup = () => {
          
            const [ucitavaSe, setUcitavaSe] = useState(false);
            const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
            const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");
      
            const [editujeSe, setEditujeSe] = useState(false);
            const [editujeSeAlert, setEditujeSeAlert] = useState(false);
            const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");
      
            const intialPodaci = { nazivmupa: "", nazivuprave: "", nazivstanice: "", adresa: "", grad: "", tag: ""};
          
            const [podaci, setPodaci] = useState(intialPodaci)
            const {nazivmupa, nazivuprave, nazivstanice, adresa, grad, tag} = podaci;

            const handleSubmit = (e) => {
              e.preventDefault()
              izmjenaMupa(podaci)
            } //handleSubmit
         
            const izmjenaMupa = async (podaci) => {
              setEditujeSe(true);
              try {
                const response = await axios.put(`https://liste-odluka.onrender.com/mup/${podaci.id}`, podaci);          
                setEditujeSeAlertMsg('Uspješno izmijenjeni podaci MUP-a/Policijske stanice.');
              } catch (error) {
                if (error.response && error.response.data) {
                  setEditujeSeAlertMsg(error.response.data);
                } else {
                  setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
                }
              } finally {
                setEditujeSe(false);
                setEditujeSeAlert(true);
               
                setTimeout(() => {
                  setEditujeSeAlert(false);
                  setEditujeSeAlertMsg('');
                }, 4000);
              }
            }; //izmjenaMupa
            
            
            const handleChange = (e) => {
            let {name, value} = e.target;
            setPodaci({...podaci, [name]: value})  
            }//handleChange
      
            //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
            useEffect( () => {
                if(stranka.id){
                  preuzmiStranku(stranka.id)
                }
            }, [])
      
            const preuzmiStranku = async (id) => {
              setUcitavaSe(true);
              try {
                const response = await axios.get(`https://liste-odluka.onrender.com/mup/${id}`);
                setPodaci({...response.data[0]})
              } catch (error) {
                  setUcitavaSe(false);
                  if (error.response && error.response.data) {
                    setUcitavaSeAlertMsg(error.response.data);
                  } else {
                    setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
                  }
                  setUcitavaSeAlert(true);
          
                  setTimeout(() => {
                    setUcitavaSeAlert(false);
                    setUcitavaSeAlertMsg('');
                  }, 4000);
              } finally {
                setUcitavaSe(false);       
              }
            }; //preuzmiStranku
      
              return (<>
                            {ucitavaSe ? 
                              <div className='d-flex justify-content-center'>
                                  <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                             </div>
                            :
                            ucitavaSeAlert ?
                              <Alert variant="danger" className="alertV2">
                              {ucitavaSeAlertMsg}
                              </Alert>
                            :
                                <Form onSubmit={handleSubmit}>
                                  
                                  <Row>

                                  <Form.Group as={Col}>
                                  
                                  <InputGroup className="mb-2">
                                      <InputGroup.Text id="nazivmupa">MUP</InputGroup.Text>
                                      <Form.Control
                                        placeholder="Naziv MUP-a"
                                        aria-label="Naziv MUP-a"
                                        aria-describedby="nazivmupa"
                                        spellCheck={false}
                                        onChange={handleChange}
                                        name="nazivmupa"
                                        value={nazivmupa}
                                      />
                                    </InputGroup>
                                  </Form.Group>

                                  <Form.Group as={Col}>

                                  <InputGroup className="mb-2">
                                      <InputGroup.Text id="tag">Skraćeno</InputGroup.Text>
                                      <Form.Control
                                        placeholder="Naziv MUP-a skraćeno"
                                        aria-label="Naziv MUP-a skraćeno"
                                        aria-describedby="nazivmupa"
                                        spellCheck={false}
                                        onChange={handleChange}
                                        name="tag"
                                        value={tag}
                                      />
                                    </InputGroup>
                                  </Form.Group>

                                  
                                <Form.Group as={Col}>
                                  
                                  <InputGroup className="mb-2">
                                      <InputGroup.Text id="nazivuprave">Uprava</InputGroup.Text>
                                      <Form.Control
                                        placeholder="Naziv uprave"
                                        aria-label="Naziv uprave"
                                        aria-describedby="nazivuprave"
                                        spellCheck={false}
                                        onChange={handleChange}
                                        name="nazivuprave"
                                        value={nazivuprave}
                                      />
                                    </InputGroup>
                                </Form.Group>
                              
                           
                                  </Row>

                                  <Row>

                                    <Form.Group as={Col}>

                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="nazivstanice">PS</InputGroup.Text>
                                        <Form.Control
                                          placeholder="Naziv policijske stanice"
                                          aria-label="Naziv policijske stanice"
                                          aria-describedby="nazivstanice"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="nazivstanice"
                                          value={nazivstanice}
                                        />
                                      </InputGroup>
                                    </Form.Group>


                                    <Form.Group as={Col}>

                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="grad">Grad</InputGroup.Text>
                                        <Form.Control
                                          placeholder="Grad"
                                          aria-label="Grad"
                                          aria-describedby="Grad"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="grad"
                                          value={grad}
                                        />
                                      </InputGroup>
                                    </Form.Group>


                                    </Row>

                                
                                    <Form.Group as={Col}>

                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="adresa">Adresa</InputGroup.Text>
                                        <Form.Control
                                          placeholder="Adresa"
                                          aria-label="adresa"
                                          aria-describedby="adresa"
                                          spellCheck={false}
                                          onChange={handleChange}
                                          name="adresa"
                                          value={adresa}
                                        />
                                      </InputGroup>
                                    </Form.Group>

                      
      
                             
                                {editujeSe ? 
                                  <Button variant="primary float-end" disabled>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {" "}Izmijeni ...
                                </Button>
                                :
                                <Button variant="primary float-end" type="submit">
                                  Izmijeni
                                </Button>
                                }  
                              </Form>
                            }
      
                  
                            
                          {editujeSeAlert &&  
                          <Alert variant="success" className="alertV2">
                            {editujeSeAlertMsg}
                          </Alert> }
      
                    </>)
      
            } //IzmijeniMup
    
            
       //TUŽIOCI
    const IzmijeniGresku = () => {
          
      const [ucitavaSe, setUcitavaSe] = useState(false);
      const [ucitavaSeAlert, setUcitavaSeAlert] = useState(false);
      const [ucitavaSeAlertMsg, setUcitavaSeAlertMsg] = useState("");

      const [editujeSe, setEditujeSe] = useState(false);
      const [editujeSeAlert, setEditujeSeAlert] = useState(false);
      const [editujeSeAlertMsg, setEditujeSeAlertMsg] = useState("");

      const intialPodaci = { ime: "", opis: ""};
    
      const [podaci, setPodaci] = useState(intialPodaci)
      const {ime, opis} = podaci;

      const handleSubmit = (e) => {
        e.preventDefault()
        izmjenaGreske(podaci)
      } //handleSubmit
      
   
      const izmjenaGreske = async (podaci) => {
        setEditujeSe(true);
        try {
          const response = await axios.put(`https://liste-odluka.onrender.com/greska/${podaci.id}`, podaci);          
          setEditujeSeAlertMsg('Uspješno izmijenjen sadržaj greške.');
        } catch (error) {
          if (error.response && error.response.data) {
            setEditujeSeAlertMsg(error.response.data);
          } else {
            setEditujeSeAlertMsg('Greška kod komunikacije sa serverom.');
          }
        } finally {
          setEditujeSe(false);
          setEditujeSeAlert(true);
          // Sakrij alert nakon 4 sec.
          setTimeout(() => {
            setEditujeSeAlert(false);
            setEditujeSeAlertMsg('');
          }, 4000);
        }
      }; //izmjenaGreske
      
      
      const handleChange = (e) => {
      let {name, value} = e.target;
      setPodaci({...podaci, [name]: value})  
      }//handleChange

      //Preuzmi podatke stranke preko API-a ukoliko je proslijeđen id .. 
      useEffect( () => {
          if(stranka.id){
            preuzmiStranku(stranka.id)
          }
      }, [])

      const preuzmiStranku = async (id) => {
        setUcitavaSe(true);
        try {
          const response = await axios.get(`https://liste-odluka.onrender.com/greska/${id}`);
          setPodaci({...response.data[0]})
        } catch (error) {
            setUcitavaSe(false);
            if (error.response && error.response.data) {
              setUcitavaSeAlertMsg(error.response.data);
            } else {
              setUcitavaSeAlertMsg('Greška kod komunikacije sa serverom.');
            }
            setUcitavaSeAlert(true);
    
            setTimeout(() => {
              setUcitavaSeAlert(false);
              setUcitavaSeAlertMsg('');
            }, 4000);
        } finally {
          setUcitavaSe(false);       
        }
      }; //preuzmiStranku

        return (<>
                      {ucitavaSe ? 
                        <div className='d-flex justify-content-center'>
                            <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                       </div>
                      :
                      ucitavaSeAlert ?
                        <Alert variant="danger" className="alertV2">
                        {ucitavaSeAlertMsg}
                        </Alert>
                      :
                          <Form onSubmit={handleSubmit}>
                          <Row className="mb-3">
                            <Form.Group as={Col}>
                            
                              <InputGroup className="mb-2">
                                  <InputGroup.Text id="greska-ime">Ime i prezime</InputGroup.Text>
                                  <Form.Control
                                    placeholder="Ime i prezime"
                                    aria-label="Ime"
                                    aria-describedby="greska-ime"
                                    spellCheck={false}
                                    onChange={handleChange}
                                    name="ime"
                                    value={ime}
                                  />
                                </InputGroup>

                                <InputGroup className="mb-2">
                                  <InputGroup.Text id="greska-opis">Opis</InputGroup.Text>
                                  <Form.Control
                                    placeholder="Opis greške"
                                    aria-label="Opis"
                                    aria-describedby="greska-opis"
                                    spellCheck={false}
                                    onChange={handleChange}
                                    name="opis"
                                    value={opis}
                                  />
                                </InputGroup>
                            </Form.Group>

                          </Row>

                          {editujeSe ? 
                            <Button variant="primary float-end" disabled>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            {" "}Izmijeni ...
                          </Button>
                          :
                          <Button variant="primary float-end" type="submit">
                            Izmijeni
                          </Button>
                          }  
                        </Form>
                      }

            
                      
                    {editujeSeAlert &&  
                    <Alert variant="success" className="alertV2">
                      {editujeSeAlertMsg}
                    </Alert> }

              </>)

      } //IzmijeniGresku



      return (<>
          {stranka && stranka.naziv === "sudija" ?
          <IzmijeniSudiju />
          : stranka && stranka.naziv === "tuzilac" ?
          <IzmijeniTuzioca />
          :
          stranka && stranka.naziv === "zapisnicar" ? 
          <IzmijeniZapisnicara />
          :
          stranka && stranka.naziv === "advokat" ? 
          <IzmijeniAdvokata />
          :
          stranka && stranka.naziv === "mup" ? 
          <IzmijeniMup />
          :
          
          stranka && stranka.naziv === "greska" ? 
          <IzmijeniGresku />
          :
          <>something else ...</>}
      </>)

  } //IzmjenaStranke

  return (
    <div className='p-4'>

      <Modal show={prikazanModal} onHide={zatvoriModal}  size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Izmjeni stranku</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>  
             <IzmjenaStranke/>
        </Modal.Body>
      </Modal>

      <Row>
        <Col className='mb-3 d-flex justify-content-center'>      
          <ButtonGroup>
            <ToggleButton
              type='radio'
              variant={(!subRoute || subRoute === "sudije")  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('sudije')}
            >
              SUDIJE
            </ToggleButton>
            <ToggleButton
              type='radio'
              variant={subRoute === "tuzioci"  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('tuzioci')}
            >
              TUŽIOCI
            </ToggleButton>
            <ToggleButton
              type='radio'
              variant={subRoute === "zapisnicari"  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('zapisnicari')}
            >
              ZAPISNIČARI
            </ToggleButton>
            <ToggleButton
              type='radio'
              variant={subRoute === "advokati"  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('advokati')}
            >
              ADVOKATI
            </ToggleButton>
            <ToggleButton
              type='radio'
              variant={subRoute === "mupovi"  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('mupovi')}
            >
              MUPOVI
            </ToggleButton>
            <ToggleButton
              type='radio'
              variant={subRoute === "greske"  ? 'secondary' : 'outline-secondary'}
              onClick={() => prmijeniPutanju('greske')}
            >
              GREŠKE
            </ToggleButton>
          </ButtonGroup>
        </Col>
      </Row>

      <Row>
      
        {subRoute === 'tuzioci' ? 
              <>
                      <Col sm={3}></Col>
                      <Col sm={6} className='d-flex justify-content-center align-items-center'>
            
                      {isLoading ? 
                      <div>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
            
                      </div>
                      :
                      error ?
                      <>
                          <Alert variant="danger" className="alertV2">
                            {error}
                          </Alert>
                      </>
                      :
                      <>
                
                            {tuzioci &&
                              <Table bordered hover>
                                <tbody>        
                                    {tuzioci.map((tuzilac) => (
                                      <tr key={tuzilac.id}>
                                        <td>{tuzilac.id}</td>
                                        <td>{tuzilac.ime}</td>
                                        <td>
                                          <Button variant='outline-secondary'
                                                  size='sm'
                                                  onClick={() => otvoriModal(tuzilac.id, "tuzilac")}
                                          >
                                            Izmijeni
                                          </Button>{' '}
            
                                          <Button
                                            variant='outline-danger'
                                            size='sm'
                                            onClick={() => brisiTuzioca(tuzilac.id)}
                                          >
                                            Briši
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>}
                      </>}
            
                      </Col>
                      <Col sm={3}></Col>
          </>
      
        : subRoute === 'zapisnicari' ? 
        <>
                <Col sm={3}></Col>
                <Col sm={6} className='d-flex justify-content-center align-items-center'>

                {isLoading ? 
                <div>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>

                </div>
                :
                error ?
                <>
                    <Alert variant="danger" className="alertV2">
                      {error}
                    </Alert>
                </>
                :
                <>
          
                      {zapisnicari &&
                        <Table bordered hover>
                          <tbody>        
                              {zapisnicari.map((zapisnicar) => (
                                <tr key={zapisnicar.id}>
                                  <td>{zapisnicar.id}</td>
                                  <td>{zapisnicar.ime}</td>
                                  <td>
                                    <Button variant='outline-secondary'
                                            size='sm'
                                            onClick={() => otvoriModal(zapisnicar.id, "zapisnicar")}
                                    >
                                      Izmijeni
                                    </Button>{' '}

                                    <Button
                                      variant='outline-danger'
                                      size='sm'
                                      onClick={() => brisiZapisnicara(zapisnicar.id)}
                                    >
                                      Briši
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>}
                </>}

                </Col>
                <Col sm={3}></Col>
        </>
       
       : subRoute === 'advokati' ?
            <>
              <Col sm={3}></Col>
              <Col sm={6} className='d-flex justify-content-center align-items-center'>

              {isLoading ? 
              <div>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>

              </div>
              :
              error ?
              <>
                  <Alert variant="danger" className="alertV2">
                    {error}
                  </Alert>
              </>
              :
              <>
        
                    {advokati &&
                      <Table bordered hover>
                        <tbody>        
                            {advokati.map((advokat) => (
                              <tr key={advokat.id}>
                                <td>{advokat.id}</td>
                                <td>{advokat.ime}</td>
                                 <td>{advokat.grad}</td>
                                <td>
                                  <Button variant='outline-secondary'
                                          size='sm'
                                          onClick={() => otvoriModal(advokat.id, "advokat")}
                                  >
                                    Izmijeni
                                  </Button>{' '}

                                  <Button
                                    variant='outline-danger'
                                    size='sm'
                                    onClick={() => brisiAdvokata(advokat.id)}
                                  >
                                    Briši
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>}
              </>}

              </Col>
              <Col sm={3}></Col>
      </>

       : subRoute === 'mupovi' ?
       <>
            <Col sm={2}></Col>
            <Col sm={8} className='d-flex justify-content-center align-items-center'>

            {isLoading ? 
            <div>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>

            </div>
            :
            error ?
            <>
                <Alert variant="danger" className="alertV2">
                  {error}
                </Alert>
            </>
            :
            <>
                  {mupovi &&
                    <Table bordered hover>
                      <tbody>        
                          {mupovi.map((mup) => (
                            <tr key={mup.id}>
                                <td>{mup.id}</td>
                              <td>{mup.nazivstanice ? `PS ${mup.nazivstanice}` : <i style={{opacity: 0.7}}>{mup.opis}</i>}</td>
                              <td>{mup.nazivuprave ? `PU ${mup.nazivuprave}` : <i style={{opacity: 0.7}}>{mup.grad}</i>}</td>
                              <td>{mup.nazivmupa}</td>
                              <td>
                                <Button variant='outline-secondary'
                                        size='sm'
                                        onClick={() => otvoriModal(mup.id, "mup")}
                                >
                                  Izmijeni
                                </Button>{' '}

                                <Button
                                  variant='outline-danger'
                                  size='sm'
                                  onClick={() => brisiMup(mup.id)}
                                >
                                  Briši
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>}
            </>}

            </Col>
            <Col sm={3}></Col>

        </> 
        : subRoute === 'greske' ?
        <>
          <Col sm={3}></Col>
          <Col sm={6} className='d-flex justify-content-center align-items-center'>

          {isLoading ? 
          <div>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

          </div>
          :
          error ?
          <>
              <Alert variant="danger" className="alertV2">
                {error}
              </Alert>
          </>
          :
          <>
    
                {greske &&
                  <Table bordered hover>
                    <tbody>        
                        {greske.map((greska) => (
                          <tr key={greska.id}>
                            <td>{greska.id}</td>
                            <td>{greska.ime}</td>
                            <td>{greska.app}</td>
                             <td>{greska.opis}</td>
                            <td>
                              <Button variant='outline-secondary'
                                      size='sm'
                                      onClick={() => otvoriModal(greska.id, "greska")}
                              >
                                Izmijeni
                              </Button>{' '}

                              <Button
                                variant='outline-danger'
                                size='sm'
                                onClick={() => brisiGresku(greska.id)}
                              >
                                Briši
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>}
          </>}

          </Col>
          <Col sm={3}></Col>
      </>
       
       :
         <>
         <Col sm={3}></Col>
          <Col sm={6} className='d-flex justify-content-center align-items-center'>

          {isLoading ? 
          <div>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>

          </div>
          :
          error ?
          <>
             <Alert variant="danger" className="alertV2">
                {error}
              </Alert>
          </>
          :
          <>
    
                {sudije &&
                  <Table bordered hover>
                    <tbody>        
                        {sudije.map((sudija) => (
                          <tr key={sudija.id}>
                            <td>{sudija.id}</td>
                            <td>{sudija.ime}</td>
                            <td>
                              <Button variant='outline-secondary'
                                      size='sm'
                                      onClick={() => otvoriModal(sudija.id, "sudija")}
                              >
                                Izmijeni
                              </Button>{' '}

                              <Button
                                variant='outline-danger'
                                size='sm'
                                onClick={() => brisiSudiju(sudija.id)}
                              >
                                Briši
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>}
          </>}

          </Col>
          <Col sm={3}></Col>
        </>}

      </Row>
    </div>
  );
};

export default PregledStranaka;
