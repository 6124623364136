import { add, addDays, subDays } from 'date-fns'
import { format } from "date-fns";
import Deklinacija from './komponente/dodatneopcije/Deklinacija';
import { ugovori } from './komponente/liste/ugovori';
import { Parafraziraj } from './komponente/Razlogizrucenja';

const reducer = (state, action) => {

    if (action.type === "LOAD_STATE") {
        return { ...state, ...action.payload };
    }



    if (action.type === "VRIJEME_IZMJENE") {
        return {
            ...state,
            vrijemeizmjene: new Date(),
        };
    }


    if (action.type === 'INPUT_CHANGE') {

            //updejtujStranke   -  updejtuej se nakon uspješnog dodavanja stranke što trigeruje fetch stranaka u inputima;
            if (action.payload.id === 'updejtujStranke') {
                console.log("updejtovaneStranke")
                return { ...state, updejtujStranke: state.updejtujStranke + 1 }
            }
    
        if (action.payload.id === "brojPredmeta") {


            let val = action.payload.val.trim();

            let reg1 = /^S13 K/i;
            if (reg1.test(val)) {
                val = val.replace(reg1, "S1 3 K")
            }

            let reg2 = /^S1 3K/i;
            if (reg2.test(val)) {
                val = val.replace(reg2, "S1 3 K");
            }

            let reg3 = /^S13K/i;
            if (reg3.test(val)) {
                val = val.replace(reg3, "S1 3 K");
            }

            if (!val.startsWith("S1 3 K")) {    //ne počinje ... 
                val = "S1 3 K " + val
            }

            if (!val.includes("Eks")) {
                val = val + " Eks "
            }


            // Ako nema centralnog broja/predmeta u localstorage nastavi updejtovat brojPredmeta

            return { ...state, brojPredmeta: val }

        } // brojPredmeta

        if (action.payload.id === 'izmjenadatuma') {
            const val = action.payload.val;

            let stvarnidatumTMP = state[`datumodluke${val}TMP`];
            let stvarnidatum;



            if (action.payload.dodatniparam === "kalendar") {
                stvarnidatumTMP = action.payload.dodatniparam2;
            }


            if (action.payload.dodatniparam === '+') {
                if (!stvarnidatumTMP) {
                    stvarnidatumTMP = new Date();
                } else {
                    stvarnidatumTMP = new Date(stvarnidatumTMP); // Konvertuj datum iz ISO formata u format čitljiv za date-fns
                }
                stvarnidatumTMP = addDays(stvarnidatumTMP, 1);
            } else if (action.payload.dodatniparam === '-') {
                if (!stvarnidatumTMP) {
                    stvarnidatumTMP = new Date();
                } else {
                    stvarnidatumTMP = new Date(stvarnidatumTMP); // Konvertuj datum iz ISO formata u format čitljiv za date-fns
                }
                stvarnidatumTMP = subDays(stvarnidatumTMP, 1);
            }

            stvarnidatum = format(stvarnidatumTMP, 'dd.MM.yyyy.') + ' godine';

            if (action.payload.dodatniparam3 === "neDirajDatumodluke") {
                return {
                    ...state,
                    [`datumodluke${val}TMP`]: stvarnidatumTMP,
                    [`datumodluke${val}`]: stvarnidatum
                };
            } else {
                return {
                    ...state,
                    datumodluke: stvarnidatum,
                    [`datumodluke${val}TMP`]: stvarnidatumTMP,
                    [`datumodluke${val}`]: stvarnidatum
                };
            }
        }

        if (action.payload.id === "izmjenafaze") {
            const val = action.payload.val;
            let fazaTMP = state[`${val}faza`];

            if (action.payload.dodatniparam === "+") {
                fazaTMP = fazaTMP ? fazaTMP + 1 : 2
            }

            if (action.payload.dodatniparam === "-") {
                if (fazaTMP <= 2) {
                    fazaTMP = "";
                } else {
                    fazaTMP = fazaTMP - 1;
                }
            }

            return {
                ...state,
                [`${val}faza`]: fazaTMP
            };
        }



        if (action.payload.id === "IMELICA") {
            let spol;
            if (state.licezensko) {
                spol = "ž"
            } else {
                spol = "m"
            }

            const deklinirano = Deklinacija(action.payload.val, spol)
            //console.log("deklinirano", deklinirano.gen)
            return {
                ...state,
                IMELICA: {
                    ime: action.payload.val,
                    imegen: deklinirano.gen,
                    imedat: deklinirano.dat
                }
            }

        }

        // Promijeni spol, uradi replacement za pojedine riječi po rodu
        if (action.payload.id === 'licezensko') {
            let repls = {
                POTRAŽIVANI: "POTRAŽIVANA",
                Potraživani: "Potraživana",
                potraživani: "potraživana",
                POTRAŽIVANOM: "POTRAŽIVANOJ",
                Potraživanom: "Potraživanoj",
                potraživanom: "potraživanoj",
                potraživanog: "potraživane",
                potraživanogg: "potraživanu",
                državljanin: "državljanka",
                počinio: "počinila",
                poučen: "poučena",
                te_mu_je: "te joj je",
                njegov: "njen",
                njegovi: "njeni",
                njegovo: "njeno",
                njegovog: "njenjog",
                pozvan: "pozvana",
                priveden: "privedena",
                saslušan: "saslušana", //
                izjasnio: "izjasnila",
                isti: "ista",
                izručen: "izručena",
                mogao: "mogla",
                mu_se: "joj se",
                posjedovao: "posjedovala",
                samog: "same",
                saopštio: "saopštila",
                sam: "sama",
                naveo: "navela",
                potvrdio: "potvrdila",
                mu_je: "joj je",
                njegovom: "njenom",
                njega: "nje",
                dao: "dala",
                odabrao: "odabrala",
                koji: "koja", //
                lišen: "lišena",
                istom: "istoj", //
                njegovim: "njenim",
                njegovih: "njenih",
                upućen: "upućena",
                bio: "bila",
                Saglasan: "Saglasna",
                saglasan: "saglasna",
                njegove: "njene",
                lišenog: "lišene",
                upisan: "upisana",
                da_ga: "da je",
                koja_ga: "koja je",
                izdržao: "izdržala",
                osuđen: "osuđena",
                saglasio: "saglasila",
                on: "ona",
                upoznat: "upoznata",
                kojeg: "koju",
                pušten: "puštena",
                nedostupan: "nedostupna",
                Prisutan: "Prisutna",
                prisutan: "prisutna",
                bi_ga: "bi je",
                svjestan: "svjesna",


            }


            if (action.payload.val === true) {

                if (state.IMELICA.ime !== "") {
                    const deklinirano = Deklinacija(state.IMELICA.ime, "ž")
                    return {
                        ...state, [action.payload.id]: action.payload.val, ...repls, IMELICA: {
                            ...state.IMELICA,
                            imegen: deklinirano.gen,
                            imedat: deklinirano.dat
                        }
                    }
                } else {
                    return { ...state, [action.payload.id]: action.payload.val, ...repls }
                }

            }
            if (action.payload.val === false) {
                let repls = {
                    POTRAŽIVANI: "POTRAŽIVANI",
                    Potraživani: "Potraživani",
                    potraživani: "potraživani",
                    POTRAŽIVANOM: "POTRAŽIVANOM",
                    Potraživanom: "Potraživanom",
                    potraživanom: "potraživanom",
                    potraživanog: "potraživanog",
                    potraživanogg: "potraživanog",
                    državljanin: "državljanin",
                    počinio: "počinio",
                    poučen: "poučen",
                    te_mu_je: "te mu je",
                    njegov: "njegov",
                    njegovi: "njegovi",
                    njegovo: "njegovo",
                    njegovog: "njegovog",
                    pozvan: "pozvan",
                    priveden: "priveden",
                    saslušan: "saslušan", //
                    izjasnio: "izjasnio",
                    isti: "isti",
                    izručen: "izručen",
                    mogao: "mogao",
                    mu_se: "mu se",
                    posjedovao: "posjedovao",
                    samog: "samog",
                    saopštio: "saopštio",
                    sam: "sam",
                    naveo: "naveo",
                    potvrdio: "potvrdio",
                    mu_je: "mu je",
                    njegovom: "njegovom",
                    njega: "njega",
                    dao: "dao",
                    odabrao: "odabrao",
                    koji: "koji",
                    lišen: "lišen",
                    istom: "istom", //
                    njegovim: "njegovim",
                    njegovih: "njegovih",
                    upućen: "upućen",
                    bio: "bio",
                    Saglasan: "Saglasan",
                    saglasan: "saglasan",
                    njegove: "njegove",
                    lišenog: "lišenog",
                    upisan: "upisan",
                    da_ga: "da ga",
                    koja_ga: "koja ga",
                    izdržao: "izdržao",
                    osuđen: "osuđen",
                    saglasio: "saglasio",
                    on: "on",
                    upoznat: "upoznat",
                    kojeg: "kojeg",
                    pušten: "pušten",
                    nedostupan: "nedostupan",
                    Prisutan: "Prisutan",
                    prisutan: "prisutan",
                    bi_ga: "bi ga",
                    svjestan: "svjestan",








                }
                if (state.IMELICA.ime !== "") {
                    const deklinirano = Deklinacija(state.IMELICA.ime, "m")
                    return {
                        ...state, [action.payload.id]: action.payload.val, ...repls, IMELICA: {
                            ...state.IMELICA,
                            imegen: deklinirano.gen,
                            imedat: deklinirano.dat
                        }
                    }
                } else {
                    return { ...state, [action.payload.id]: action.payload.val, ...repls }
                }
            }
        }

        if (action.payload.id === "TUMAC") {
            let spol;
            if (action.payload.dodatniparam === "spol") {
                spol = action.payload.val
                if (state.TUMAC.ime) {   //ako je upisano ime uradi i deklinaciju
                    let ime = state.TUMAC.ime
                    const deklinirano = Deklinacija(ime, spol)
                    return {
                        ...state,
                        TUMAC: {
                            ...state.TUMAC,
                            imegen: deklinirano.gen,
                            imedat: deklinirano.dat,
                            spol: spol,
                        }
                    }
                } else {  // u suprotnom samo izmijeni spol
                    return {
                        ...state,
                        TUMAC: {
                            ...state.TUMAC,
                            spol: spol,
                        }
                    }
                }

            }

            spol = state.TUMAC.spol
            const deklinirano = Deklinacija(action.payload.val, spol)
            return {
                ...state,
                TUMAC: {
                    ...state.TUMAC,
                    ime: action.payload.val,
                    imegen: deklinirano.gen,
                    imedat: deklinirano.dat
                }
            }

        } //TUMAC

        // Poništi drugodržavljanstvo ukoliko je vizualno obrisan input za drugo državljanstvo
        if (action.payload.id === 'drugodrzavljanstvo') {
            if (action.payload.val === true) {
                return { ...state, [action.payload.id]: action.payload.val }
            }
            if (action.payload.val === false) {
                return { ...state, [action.payload.id]: action.payload.val, DRZAVLJANSTVO2selektovano: "", DRZAVLJANSTVO2: "" }
            }
        }

        //Isključi MZjavljanje ako je označen kućni pritvor
        if (action.payload.id === "MZboravistekucni" && action.payload.val === "DA") {
            return { ...state, [action.payload.id]: action.payload.val, MZjavljanje: false }
        }
        //Isključi "Kućni pritvor" ako je MZjavljanje
        if (action.payload.id === "MZjavljanje" && action.payload.val === true) {
            return { ...state, [action.payload.id]: action.payload.val, MZboravistekucni: "NE" }
        }


        //Datumi
        if (action.payload.id === 'datumlisenja') {
            let datuml
            let PRkrajlisenjaTMP
            let PROkrajlisenjaTMP
            let EKSkrajlisenjaTMP

            if (!action.payload.val) {
                datuml = "";
                PRkrajlisenjaTMP = "";
                PROkrajlisenjaTMP = "";
                EKSkrajlisenjaTMP = "";
            } else {
                datuml = format(action.payload.val, 'dd.MM.yyyy') + ". godine"
                PRkrajlisenjaTMP = add(action.payload.val, {
                    days: 17
                })

                PROkrajlisenjaTMP = add(action.payload.val, {
                    days: 40
                })

                EKSkrajlisenjaTMP = add(action.payload.val, {
                    months: 6
                })

                PRkrajlisenjaTMP = format(PRkrajlisenjaTMP, 'dd.MM.yyyy') + ". godine"
                PROkrajlisenjaTMP = format(PROkrajlisenjaTMP, 'dd.MM.yyyy') + ". godine"
                EKSkrajlisenjaTMP = format(EKSkrajlisenjaTMP, 'dd.MM.yyyy') + ". godine"

            }


            return { ...state, datumlisenjaTMP: action.payload.val, datumlisenja: datuml, PRkrajlisenja: PRkrajlisenjaTMP, PROkrajlisenja: PROkrajlisenjaTMP, EKSkrajlisenja: EKSkrajlisenjaTMP }
        }

        if (action.payload.id === "organLisenja") {
            const parafraziraj = Parafraziraj("ORGANLIŠENJA", action.payload.val);
            const { nom, gen } = parafraziraj;

            return {
                ...state,
                organLisenja: { val: action.payload.val, nom: nom, gen: gen }
            }
        }

        //Izbor Zavoda
        if (action.payload.id === 'izborzavoda') {
            //console.log(action.payload.val)

            return { ...state, zavod: action.payload.val }
        }

        //ODBopcije
        if (action.payload.id === 'ODBopcije') {

            if (action.payload.dodatniparam === "aktivnapotjernica") {

                return {
                    ...state, ODBopcije:
                    {
                        ...state.ODBopcije,
                        aktivnapotjernica: action.payload.val,
                    }
                }

            }

            if (action.payload.dodatniparam === "NCBobavijestNeaktivnaPotjernica") {

                return {
                    ...state, ODBopcije:
                    {
                        ...state.ODBopcije,
                        NCBobavijestNeaktivnaPotjernica: action.payload.val,
                    }
                }

            }

        } //ODBopcije

        if (action.payload.id === "PROCESUIRANJE_DODAJ") {
            const noviobjekt = {
                procesuiranjekd: "",
                procesuiranjekazna: { val: "", paraf: "", parafrazirano: "DA" },    //{val: "", paraf: "", parafrazirano: "NE" }
                procesuiranjevrijemeiizvrsenja: "",
                procesuiranjebrojnaloga: "",
                procesuiranjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
                procesuiranjeODB: { vrijemevazenja: false, visinakazne: false }
            };
            // Add the new object to the array
            const novoProcesuiranje = [...state.procesuiranjeInfo, noviobjekt];
            // Return the updated state
            return {
                ...state,
                procesuiranjeInfo: novoProcesuiranje,
            };

        } //PROCESUIRANJE_DODAJ


        if (action.payload.id === "PROCESUIRANJE_BRISI") {

            const brisizadnji = state.procesuiranjeInfo.slice(0, -1);
            // Return the updated state
            return {
                ...state,
                procesuiranjeInfo: brisizadnji,
            };

        } //PROCESUIRANJE_BRISI

        if (action.payload.id === "IZVRSENJE_DODAJ") {
            const noviobjekt = {
                izvrsenjepresuda: "",
                izvrsenjenazivsuda: { val: "", nom: "", gen: undefined }, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
                izvrsenjekd: "",
                izvrsenjekazna: { val: "", izrecenakazna: "", preostalidio: undefined, izrecenakaznanom: "", preostalidionom: "" },   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
                izvrsenjeprisutanizricanju: "DA",
                izvrsenjevrijemeizvrsenja: "",
                izvrsenjebrojnaloga: "",
                izvrsenjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
                izvrsenjeODB: { vrijemevazenja: false, visinakazne: false }
            };
            // Add the new object to the array
            const novoIzvrsenje = [...state.izvrsenjeInfo, noviobjekt];
            // Return the updated state
            return {
                ...state,
                izvrsenjeInfo: novoIzvrsenje,
            };

        } //PROCESUIRANJE_DODAJ

        if (action.payload.id === "IZVRSENJE_BRISI") {

            const brisizadnji = state.izvrsenjeInfo.slice(0, -1);
            // Return the updated state
            return {
                ...state,
                izvrsenjeInfo: brisizadnji,
            };

        } //PROCESUIRANJE_BRISI

        if (action.payload.dodatniparam === "procesuiranjeInfo") {
            if (action.payload.id === "procesuiranjekazna") {
                const val = action.payload.val?.trim()
                const parafraziraj = Parafraziraj("PROCESKAZNA", val);
                const { parafrazirano, status } = parafraziraj;
                let procesuiranjekaznaTMP = status === "DA" ? { val: val, paraf: parafrazirano, parafrazirano: "DA" } : { val: val, paraf: "", parafrazirano: "NE" };
                return {
                    ...state, procesuiranjeInfo: [
                        {
                            ...state.procesuiranjeInfo[0], // kopiraj prvi objekt
                            procesuiranjekazna: procesuiranjekaznaTMP
                        },
                        ...state.procesuiranjeInfo.slice(1) // kopiraj ostatak
                    ],
                }
            }   //procesuiranjekazna

            if (action.payload.id === "procesuiranjenazivsudanalog") {

                const val = action.payload.val?.trim()
                const gen = val.includes("/") ? val.split("/")[1] : undefined;
                const procesuiranjenazivsudanalogTMP = { val, nom: gen ? val.split("/")[0] : val, gen };
                const procesuiranjeInfo = state.procesuiranjeInfo.map((info, index) => {
                    if (index === 0) {
                        return { ...info, procesuiranjenazivsudanalog: procesuiranjenazivsudanalogTMP };
                    }
                    return info;
                });
                return { ...state, procesuiranjeInfo };
            } // procesuiranje-nazivsudanalog

            if (action.payload.id === "vrijemevazenja" || action.payload.id === "visinakazne") {
                return {
                    ...state,
                    procesuiranjeInfo: [
                        {
                            ...state.procesuiranjeInfo[0],
                            procesuiranjeODB: {
                                ...state.procesuiranjeInfo[0].procesuiranjeODB,
                                [action.payload.id]: action.payload.val
                            }
                        },
                        ...state.procesuiranjeInfo.slice(1)
                    ]
                };
            }

            return {
                ...state, procesuiranjeInfo: [
                    {
                        ...state.procesuiranjeInfo[0], // kopiraj prvi objekt
                        [action.payload.id]: action.payload.val?.trim()  // updejtuj šta treba
                    },
                    ...state.procesuiranjeInfo.slice(1) // kopiraj ostatak
                ]
            }
        } //procesuiranjeInfo

        if (action.payload.dodatniparam === "procesuiranjeInfo1") {

            if (action.payload.id === "procesuiranjekazna") {
                const val = action.payload.val?.trim()
                const parafraziraj = Parafraziraj("PROCESKAZNA", val);
                const { parafrazirano, status } = parafraziraj;
                let procesuiranjekaznaTMP = status === "DA" ? { val: val, paraf: parafrazirano, parafrazirano: "DA" } : { val: val, paraf: "", parafrazirano: "NE" };
                return {
                    ...state, procesuiranjeInfo: [
                        {
                            ...state.procesuiranjeInfo[0], // kopiraj prvi objekt
                        },
                        {
                            ...state.procesuiranjeInfo[1], // kopiraj drugi objekt
                            procesuiranjekazna: procesuiranjekaznaTMP
                        }
                    ]
                }
            }   //procesuiranjekazna

            if (action.payload.id === "procesuiranjenazivsudanalog") {
                const val = action.payload.val?.trim()
                const gen = val.includes("/") ? val.split("/")[1] : undefined;
                const procesuiranjenazivsudanalogTMP = { val, nom: gen ? val.split("/")[0] : val, gen };
                const [firstObj, secondObj] = state.procesuiranjeInfo;
                return {
                    ...state,
                    procesuiranjeInfo: [
                        { ...firstObj },
                        { ...secondObj, procesuiranjenazivsudanalog: procesuiranjenazivsudanalogTMP }
                    ],
                };
            }
            // procesuiranje-nazivsudanalog

            if (action.payload.id === "vrijemevazenja" || action.payload.id === "visinakazne") {
                return {
                    ...state,
                    procesuiranjeInfo: [
                        {
                            ...state.procesuiranjeInfo[0], // kopiraj prvi objekt
                        },
                        {
                            ...state.procesuiranjeInfo[1],
                            procesuiranjeODB: {
                                ...state.procesuiranjeInfo[1].procesuiranjeODB,
                                [action.payload.id]: action.payload.val
                            }
                        }
                    ]
                };
            }

            return {
                ...state, procesuiranjeInfo: [
                    {
                        ...state.procesuiranjeInfo[0], // kopiraj prvi objekt
                    },
                    {
                        ...state.procesuiranjeInfo[1], // kopiraj drugi objekt
                        [action.payload.id]: action.payload.val?.trim()  // updejtuj šta treba
                    }
                ]
            }
        }

        if (action.payload.dodatniparam === "izvrsenjeInfo") {

            if (action.payload.id === "izvrsenjekazna") {
                const val = action.payload.val?.trim()
                const parafraziraj = Parafraziraj("IZVRSENJEKAZNA", val);
                const { modIzrecenakazna, modPreostalidio, izrecenakaznanom, preostalidionom } = parafraziraj;
                let izvrsenjekaznaTMP = { val: val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio, izrecenakaznanom: izrecenakaznanom, preostalidionom: preostalidionom };

                return {
                    ...state, izvrsenjeInfo: [
                        {
                            ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                            izvrsenjekazna: izvrsenjekaznaTMP,
                        },
                        ...state.izvrsenjeInfo.slice(1) // kopiraj ostatak
                    ]
                }

            } //izvrsenjekazna


            if (action.payload.id === "izvrsenjenazivsuda" || action.payload.id === "izvrsenjenazivsudanalog") {
                const val = action.payload.val?.trim()
                if (val.includes("/")) {  //upisan genitiv

                    const [nom, gen] = val.split("/");
                    let izvrsenjenazivsudaTMP = { val: val?.trim(), nom: nom?.trim(), gen: gen }
                    return {
                        ...state, izvrsenjeInfo: [
                            {
                                ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                                [action.payload.id]: izvrsenjenazivsudaTMP,
                            },
                            ...state.izvrsenjeInfo.slice(1) // kopiraj ostatak
                        ]
                    }
                } else {  //nije upisan genitiv
                    let izvrsenjenazivsudaTMP = { val: val?.trim(), nom: val?.trim(), gen: undefined }
                    return {
                        ...state, izvrsenjeInfo: [
                            {
                                ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                                [action.payload.id]: izvrsenjenazivsudaTMP,
                            },
                            ...state.izvrsenjeInfo.slice(1) // kopiraj ostatak
                        ]
                    }
                }

            } // izvrsenjenazivsuda

            if (action.payload.id === "vrijemevazenja" || action.payload.id === "visinakazne") {
                return {
                    ...state,
                    izvrsenjeInfo: [
                        {
                            ...state.izvrsenjeInfo[0],
                            izvrsenjeODB: {
                                ...state.izvrsenjeInfo[0].izvrsenjeODB,
                                [action.payload.id]: action.payload.val
                            }
                        },
                        ...state.izvrsenjeInfo.slice(1)
                    ]
                };
            }


            return {
                ...state, izvrsenjeInfo: [
                    {
                        ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                        [action.payload.id]: action.payload.val?.trim()  // updejtuj šta treba
                    },
                    ...state.izvrsenjeInfo.slice(1) // kopiraj ostatak
                ]
            }
        } //izvrsenjeInfo

        if (action.payload.dodatniparam === "izvrsenjeInfo1") {

            if (action.payload.id === "izvrsenjekazna") {
                const val = action.payload.val?.trim()
                const parafraziraj = Parafraziraj("IZVRSENJEKAZNA", val);
                const { modIzrecenakazna, modPreostalidio, izrecenakaznanom, preostalidionom } = parafraziraj;
                let izvrsenjekaznaTMP = { val: val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio, izrecenakaznanom: izrecenakaznanom, preostalidionom: preostalidionom };

                return {
                    ...state, izvrsenjeInfo: [
                        {
                            ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                        },
                        {
                            ...state.izvrsenjeInfo[1], // kopiraj drugi objekt
                            izvrsenjekazna: izvrsenjekaznaTMP
                        }
                    ]
                }
            } //izvrsenjekazna

            if (action.payload.id === "izvrsenjenazivsuda" || action.payload.id === "izvrsenjenazivsudanalog") {
                const val = action.payload.val?.trim()
                if (val.includes("/")) {  //upisan genitiv
                    const [nom, gen] = val.split("/");
                    let izvrsenjenazivsudaTMP = { val: val?.trim(), nom: nom?.trim(), gen: gen }
                    return {
                        ...state, izvrsenjeInfo: [
                            {
                                ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                            },
                            {
                                ...state.izvrsenjeInfo[1], // kopiraj drugi objekt
                                [action.payload.id]: izvrsenjenazivsudaTMP,
                            }
                        ]
                    }

                } else {  //nije upisan genitiv
                    let izvrsenjenazivsudaTMP = { val: val?.trim(), nom: val?.trim(), gen: undefined }
                    return {
                        ...state, izvrsenjeInfo: [
                            {
                                ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                            },
                            {
                                ...state.izvrsenjeInfo[1], // kopiraj drugi objekt
                                [action.payload.id]: izvrsenjenazivsudaTMP,
                            }
                        ]
                    }
                }

            } // izvrsenjenazivsuda

            if (action.payload.id === "vrijemevazenja" || action.payload.id === "visinakazne") {
                return {
                    ...state,
                    izvrsenjeInfo: [
                        {
                            ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                        },
                        {
                            ...state.izvrsenjeInfo[1],
                            izvrsenjeODB: {
                                ...state.izvrsenjeInfo[1].izvrsenjeODB,
                                [action.payload.id]: action.payload.val
                            }
                        }
                    ]
                };
            }

            return {
                ...state, izvrsenjeInfo: [
                    {
                        ...state.izvrsenjeInfo[0], // kopiraj prvi objekt
                    },
                    {
                        ...state.izvrsenjeInfo[1], // kopiraj drugi objekt
                        [action.payload.id]: action.payload.val?.trim()  // updejtuj šta treba
                    }
                ]
            }
        } //izvrsenjeInfo1

        return { ...state, [action.payload.id]: action.payload.val } //glavni return za inpute
    } //INPUT_CHANGE


    if (action.type === 'IZBOR_ODLUKE') {
        const payload = action.payload;

        let datumodlukeTMP = state.datumodlukeTMP;
        let datumodluke;
        let stvarnidatumTMP;

        if (payload === 'ODB') {
            datumodluke = 'datumodlukeODB';
            stvarnidatumTMP = state.datumodlukeODBTMP;
        } else if (payload === 'MZ') {
            datumodluke = 'datumodlukeMZ';
            stvarnidatumTMP = state.datumodlukeMZTMP;
        } else if (payload === 'PR') {
            datumodluke = 'datumodlukePR';
            stvarnidatumTMP = state.datumodlukePRTMP;
        } else if (payload === 'PREKS') {
            datumodluke = 'datumodlukePREKS';
            stvarnidatumTMP = state.datumodlukePREKSTMP;
        } else if (payload === 'PRO') {
            datumodluke = 'datumodlukePRO';
            stvarnidatumTMP = state.datumodlukePROTMP;
        } else if (payload === 'EKS') {
            datumodluke = 'datumodlukeEKS';
            stvarnidatumTMP = state.datumodlukeEKSTMP;
        } else if (payload === 'BR') {
            datumodluke = 'datumodlukeBR';
            stvarnidatumTMP = state.datumodlukeBRTMP;
        }
        stvarnidatumTMP = stvarnidatumTMP ? new Date(stvarnidatumTMP) : null;  //Provjeri postoji li uopšte stvarnidatumTMP za izabranu odluku (u tom slučaju  konvertuj u ISO, u suprotnom return null jer new Date na nepostojeći datum baca "Invalid date" string)
        datumodlukeTMP = new Date(datumodlukeTMP)  //Konvertuj u ISO ukoliko bude potrebe da se koristi u stvarnidatum
        const stvarnidatum = format(stvarnidatumTMP || datumodlukeTMP, 'dd.MM.yyyy.') + ' godine'; //preuzmi inicijalni datumodlukeTMP ako do sada nije definisan datumodlukePRTMP ... (nije mijenjana vrsta odlulke)

        return {
            ...state,
            vrstaOdluke: payload,
            datumodluke: stvarnidatum,
            [datumodluke]: stvarnidatum,
            [`${datumodluke}TMP`]: stvarnidatumTMP || datumodlukeTMP
        };
    }


    if (action.type === 'IZBOR_DOPISA') {
        return { ...state, vrstaDopisa: action.payload }
    }


    if (action.type === 'DODAJ_DOKUMENT') {

        let brojdokumenata = state.licnidokumenti.length
        //   let brojdokumenataMZ = state.MZpasosi.length

        let novidokument = {
            licna: false,
            pasos: false,
            brojdokumenta: "",
            organizdavanja: "",
            drzavaizdavanja: "",
            rokvazenja: "",
            uvid: true
        }
        /*
             let novidokumentMZ = {
                 pasos: true,
                 brojdokumenta: "",
                 organizdavanja: "",
                 drzavaizdavanja: "",
                 rokvazenja: "",
                 preuzet: "NE"
             }
         */
        if (action.payload === "L") {
            if (brojdokumenata <= 3) { //ograniči na max 4 dokumenta
                state.licnidokumenti.push(novidokument)
                return { ...state }
            }
        }
        if (action.payload === "LUV") {  //dodaj uvjerenje
            let novouvjerenje = {

                brojidatumuvjerenja: "",
                maticniured: "",
                podrucjeunosa: "",
                stranaunosa: "",
                rednibrojunosa: "",
                godinaunosa: ""
            }

            return { ...state, licnipodaci: { ...state.licnipodaci, uvjerenje: novouvjerenje, } }
        }
        /*
                if (action.payload === "MZ") {
                    if (brojdokumenataMZ <= 2) { //ograniči na max 3 dokumenta
                        state.MZpasosi.push(novidokumentMZ)
                        //console.log(state.MZpasosi)
                        return { ...state, MZprikazipasose: true }
                    }
                }
        */
        return { ...state }


    }

    if (action.type === 'BRISI_DOKUMENT') {

        if (action.payload.vrsta === "L") {
            state.licnidokumenti.splice(action.payload.i, 1);
            let MZsamopasosi = state.licnidokumenti.filter(dokument => dokument.pasos)

            state.MZpasosi = MZsamopasosi


        }

        if (action.payload.vrsta === "LUV") {
            return { ...state, licnipodaci: { ...state.licnipodaci, uvjerenje: "", } }


        }
        /*
                if (action.payload.vrsta === "MZ") {
                    state.MZpasosi.splice(action.payload.i, 1);
        
                    if (state.MZpasosi.length === 0) {
                        state.MZprikazipasose = false;
                    }
                }
        */
        return { ...state }
    }

    if (action.type === 'IZMJENA_DOKUMENTA') {

        if (action.payload.vrsta === "L") {

            let trenutnidokumenti = [...state.licnidokumenti];
            if (action.payload.e.target.type === "checkbox") {  //ako je čekbox = hardkodiran "radio" od čekboxa
                if (action.payload.e.target.name === "pasos" && action.payload.e.target.checked === true) {
                    trenutnidokumenti[action.payload.i][action.payload.e.target.name] = action.payload.e.target.checked;
                    trenutnidokumenti[action.payload.i].licna = false;
                }
                if (action.payload.e.target.name === "licna" && action.payload.e.target.checked === true) {
                    trenutnidokumenti[action.payload.i][action.payload.e.target.name] = action.payload.e.target.checked;
                    trenutnidokumenti[action.payload.i].pasos = false;
                }
                trenutnidokumenti[action.payload.i][action.payload.e.target.name] = action.payload.e.target.checked;
            }
            else { //ako nije čekbox
                trenutnidokumenti[action.payload.i][action.payload.e.target.name] = action.payload.e.target.value;
            }

            let MZsamopasosi = trenutnidokumenti.filter(dokument => dokument.pasos)
            //console.log(MZsamopasosi)

            return { ...state, licnidokumenti: trenutnidokumenti, MZpasosi: MZsamopasosi }

        }

        if (action.payload.vrsta === "LUV") {
            let uvjerenjeTMP = { ...state.licnipodaci.uvjerenje };
            uvjerenjeTMP[action.payload.e.target.name] = action.payload.e.target.value;

            return { ...state, licnipodaci: { ...state.licnipodaci, uvjerenje: uvjerenjeTMP, } }

        }


        if (action.payload.vrsta === "MZ") {


            let trenutnidokumentiMZ = [...state.MZpasosi];

            if (action.payload.e.target.type === "checkbox") {  //ako je čekbox = hardkodiran "radio" od čekboxa

                trenutnidokumentiMZ[action.payload.i][action.payload.e.target.name] = action.payload.e.target.checked;
            }
            else { //ako nije čekbox
                trenutnidokumentiMZ[action.payload.i][action.payload.e.target.name] = action.payload.e.target.value;
            }
            return { ...state, MZpasosi: trenutnidokumentiMZ }

        }


        return { ...state }

    }

    if (action.type === 'IZMJENA_LP') {
        let licnipodaciTMP = { ...state.licnipodaci };

        if (action.payload.podatak === "zaposlen") {
            licnipodaciTMP.zaposlen = !licnipodaciTMP.zaposlen

            return {
                ...state, licnipodaci: licnipodaciTMP,
            }

        } else if (action.payload.podatak === "oženjen") {
            licnipodaciTMP.oženjen = !licnipodaciTMP.oženjen  //ide ž životom

            return {
                ...state, licnipodaci: licnipodaciTMP,
            }

        } else if (action.payload.podatak === "djeca") {
            licnipodaciTMP.djeca = !licnipodaciTMP.djeca

            return {
                ...state, licnipodaci: licnipodaciTMP,
            }

        } else {
            let podatak = action.payload.podatak
            licnipodaciTMP[podatak] = action.payload.e.target.value
            return {
                ...state, licnipodaci: licnipodaciTMP,
            }


        }



    }
    /*
        if (action.type === 'MZ_PREUZMI_PASOSE') {
    
    
            const preuzetiPasosi = state.licnidokumenti.filter(dokument => dokument.pasos).map(({
                licna,
                ...object
            }) => ({
                ...object,
                preuzet: 'DA'
            }));
    
            //   let preuzetiPasosi = state.licnidokumenti.filter(dokument => dokument.pasos === true);
     
    
            if (preuzetiPasosi.length === 0) { //Ako NEMA pasoša u ličnim dokumentima
                return { ...state, MZpreuzmiisprave: false, MZnemapasosaAlert: true }
            }
    
            if (preuzetiPasosi.length !== 0) { //Ako IMA pasoša u ličnim
    
                if (preuzetiPasosi.length < 4 && state.MZpasosi.length === 0) {
                    return { ...state, MZpasosi: preuzetiPasosi, MZprikazipasose: true, MZpreuzmiisprave: !state.MZpreuzmiisprave }
                }
                if (preuzetiPasosi.length < 4 && state.MZpasosi.length === 1) {
                    preuzetiPasosi.splice(2, 2); //preuzmi samo prva 2
    
                    //console.log("preuzeti PASOŠI SPLICE 2")
                    //console.log(preuzetiPasosi)
    
                    return { ...state, MZpasosi: [...state.MZpasosi, ...preuzetiPasosi], MZprikazipasose: true, MZpreuzmiisprave: !state.MZpreuzmiisprave }
                }
                if (preuzetiPasosi.length < 4 && state.MZpasosi.length === 2) {
                    preuzetiPasosi.splice(1, 3); //preuzmi samo prvi
                    return { ...state, MZpasosi: [...state.MZpasosi, ...preuzetiPasosi], MZprikazipasose: true, MZpreuzmiisprave: !state.MZpreuzmiisprave }
                }
    
                if (preuzetiPasosi.length === 4 && state.MZpasosi.length === 0) {
                    preuzetiPasosi.splice(3, 1); //preuzmi samo 3 pasoša 
                    return { ...state, MZpasosi: preuzetiPasosi, MZprikazipasose: true, MZpreuzmiisprave: !state.MZpreuzmiisprave }
                }
    
            }
    
            return { ...state }
        }
    */


    if (action.type === 'SAKRIJ_ALERT') {

        if (action.payload === "MZnemapasosa") {
            return { ...state, MZnemapasosaAlert: false }
        }



    }



    /* if (action.type === 'MZ_SAKRIJ_PASOSE_CONTAINER') {

        const preuzetiPasosi = state.licnidokumenti.filter(dokument => dokument.pasos)

        if (state.MZpasosi.length === 0) {
            return { ...state, MZprikazipasose: false, MZsakrijpasoseDelay: false }
        }

    }
 */



    if (action.type === 'IZBOR_RAZLOGAIZRUCENJA') {
        //console.log(action.payload.razlog + " - " + !action.payload.cekirano)
        return { ...state, [action.payload.razlog]: !action.payload.cekirano }
    }

    if (action.type === 'KONTROLISI_CANVAS') {

        if (action.payload.idcanvasa === 'CANVAS_LICNIPODACI_OTVORI') {
            return { ...state, licnipodaciCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_LICNIPODACI_ZATVORI') {
            return { ...state, licnipodaciCanvas: false }
        }

        if (action.payload.idcanvasa === 'CANVAS_OKOLNOSTI_OTVORI') {
            return { ...state, OkolnostiCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_OKOLNOSTI_ZATVORI') {
            return { ...state, OkolnostiCanvas: false }
        }

        if (action.payload.idcanvasa === 'CANVAS_RAZLOGIZRUCENJA_OTVORI') {
            return { ...state, razlogIzrucenjaCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_RAZLOGIZRUCENJA_ZATVORI') {
            return { ...state, razlogIzrucenjaCanvas: false }
        }

        if (action.payload.idcanvasa === 'CANVAS_ODBOPCIJE_OTVORI') {
            return { ...state, ODBopcijeCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_ODBOPCIJE_ZATVORI') {
            return { ...state, ODBopcijeCanvas: false }
        }
        if (action.payload.idcanvasa === 'CANVAS_MZOPCIJE_OTVORI') {
            return { ...state, MZopcijeCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_MZOPCIJE_ZATVORI') {
            return { ...state, MZopcijeCanvas: false }
        }
        if (action.payload.idcanvasa === 'CANVAS_PROOPCIJE_OTVORI') {
            return { ...state, PROopcijeCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_PROOPCIJE_ZATVORI') {
            return { ...state, PROopcijeCanvas: false }
        }
        if (action.payload.idcanvasa === 'CANVAS_EKSOPCIJE_OTVORI') {
            return { ...state, EKSopcijeCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_EKSOPCIJE_ZATVORI') {
            return { ...state, EKSopcijeCanvas: false }
        }
        if (action.payload.idcanvasa === 'CANVAS_ZAVODOPCIJE_OTVORI') {
            return { ...state, izaberiZavodCanvas: true }
        }
        if (action.payload.idcanvasa === 'CANVAS_ZAVODOPCIJE_ZATVORI') {
            return { ...state, izaberiZavodCanvas: false }
        }
        if (action.payload.idcanvasa === 'CANVAS_DODAJSTRANKU_OTVORI') {
            console.log(action.payload)
            if (action.payload.dodatniparam === "tužilac") {
                return { ...state, dodajTuziocaCanvas: true }
            }
            if (action.payload.dodatniparam === "advokat") {
                return { ...state, dodajAdvokataCanvas: true }
            }
            if (action.payload.dodatniparam === "zapisničar") {
                return { ...state, dodajZapinsicaraCanvas: true }

            }
            if (action.payload.dodatniparam === "sudija") {
                return { ...state, dodajSudijuCanvas: true }
            }
            if (action.payload.dodatniparam === "greška") {
                return { ...state, dodajGreskuCanvas: true }
            }

        }
        if (action.payload.idcanvasa === 'CANVAS_DODAJSTRANKU_ZATVORI') {
            if (action.payload.dodatniparam === "tužilac") {
                return { ...state, dodajTuziocaCanvas: false }
            }
            if (action.payload.dodatniparam === "advokat") {
                return { ...state, dodajAdvokataCanvas: false }
            }
            if (action.payload.dodatniparam === "zapisničar") {
                return { ...state, dodajZapinsicaraCanvas: false }

            }
            if (action.payload.dodatniparam === "sudija") {
                return { ...state, dodajSudijuCanvas: false }
            }
            if (action.payload.dodatniparam === "greška") {
                return { ...state, dodajGreskuCanvas: false }
            }
        }



    }

    if (action.type === 'AUTOCOMPLETE_SELECT') {

        if (action.payload.id === 'DRZMOLITELJICA') {
            const LbjekstvoumaticnuIndex = state.sveOkolnostiLicne.findIndex(item => item.value === "Lbjekstvoumaticnu");
            let updatedLbjekstvoumaticnu = { ...state.sveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti };
            let updatedSveOkolnostiLicne = [...state.sveOkolnostiLicne];

            const bjekstvoMaticna = () => {
                let bijegumaticnu;

                if (!state.DRZAVLJANSTVO && !state.DRZAVLJANSTVO2) {
                    bijegumaticnu = false;
                }
                else { // ima neko DRZAVLJANSTVO 
                    let opasnaMaticna = [state.DRZAVLJANSTVO, state.DRZAVLJANSTVO2].filter(
                        (item) =>
                            item &&
                            item.ime !== "Bosna i Hercegovina" &&
                            item.ime !== action.payload.val?.ime
                    );

                    if (opasnaMaticna.length !== 0) {
                        bijegumaticnu = true;
                    } else {
                        bijegumaticnu = false;
                    }
                }

                return { bijegumaticnu };
            } // bjekstvoMaticna()

            const { bijegumaticnu } = bjekstvoMaticna()

            if (action.payload.val) {
                if (bijegumaticnu) {
                    updatedLbjekstvoumaticnu.cekirano = false;
                    updatedLbjekstvoumaticnu.disabled = false;
                } else {
                    updatedLbjekstvoumaticnu.cekirano = false;
                    updatedLbjekstvoumaticnu.disabled = true;
                }

                updatedSveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti = updatedLbjekstvoumaticnu;

                let updatedOdabraneOkolnostiLicne = updatedSveOkolnostiLicne.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
                state.odabraneOkolnostiLicne = "";

                const postojiugovorTMP = ugovori.some(el => el.ime === action.payload.val.ime);
                const PostojiUgovor = postojiugovorTMP ? "DA" : "NE";
                return { ...state, DRZMOLITELJICA: action.payload.val, PostojiUgovor, sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne };
            } else {

                updatedLbjekstvoumaticnu.disabled = true;
                updatedSveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti = updatedLbjekstvoumaticnu;

                let updatedOdabraneOkolnostiLicne = updatedSveOkolnostiLicne.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
                state.odabraneOkolnostiLicne = "";

                return { ...state, DRZMOLITELJICA: "", osnoviugovor: "", PostojiUgovor: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne };
            }
        } //DRZMOLITELJICA


        if (action.payload.id === 'LDdrzavaizdavanja') {
            //console.log(action.payload.index)
            //console.log(action.payload.val)

            /*
            licnidokumenti: [{
                    licna: false,
                    pasos: false,
                    brojdokumenta: "",
                    organizdavanja: "",
                    drzavaizdavanja: null,
                    rokvazenja: "",
                    uvid: true
                }],
            
            { ime: "", kod: "", gen: "", nazivinterpola: "" }
            
            */


            let licnidokumentiTMP = [...state.licnidokumenti];
            licnidokumentiTMP[action.payload.index].drzavaizdavanja = action.payload.val;

            return { ...state, licnidokumenti: licnidokumentiTMP }

        }


        /* 
           if (action.payload.id === 'DRZAVLJANSTVO') {
                    let DRZAVLJANSTVOselektovanoCopy = Object.assign({}, action.payload.val);
        
                    if (DRZAVLJANSTVOselektovanoCopy.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO2.ime !== "Bosna i Hercegovina") {
                        return { ...state, DRZAVLJANSTVOselektovano: action.payload.val, DRZAVLJANSTVO: DRZAVLJANSTVOselektovanoCopy, DrzavljaninBiH: "NE" }
        
                    } else {
                        let TEMPposjedujedrzBiH = ""; //Kreiraj poveznicu "posjeduje drzavljanstvo Bosne i Hercegovine.."
                        state.posjedujedrzBiH = "";
        
                        if (state.DRZAVLJANSTVO2.ime === "") {
                            TEMPposjedujedrzBiH = "posjeduje državljanstvo Bosne i Hercegovine";
                            //console.log("1 - if 1")
                        }
                        else if (DRZAVLJANSTVOselektovanoCopy.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO2.ime !== "" && state.DRZAVLJANSTVO2.ime !== "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "pored državljanstva " + (state.DRZAVLJANSTVO2.gen || "____bbb_____") + " posjeduje i državljanstvo Bosne i Hercegovine";
                            //console.log("1 - if 2")
        
                        }
                        else if (DRZAVLJANSTVOselektovanoCopy.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "" && state.DRZAVLJANSTVO.ime === "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "posjeduje državljanstvo Bosne i Hercegovine";
                            //console.log("1 - if 3")
                        }
                        else if (DRZAVLJANSTVOselektovanoCopy.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "" && state.DRZAVLJANSTVO.ime === "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "pored državljanstva " + (DRZAVLJANSTVOselektovanoCopy.ime || "____bbb_____") + " posjeduje i državljanstvo Bosne i Hercegovine";
                            //console.log("1 - if 4")
                        }
        
                        return { ...state, DRZAVLJANSTVOselektovano: action.payload.val, DRZAVLJANSTVO: DRZAVLJANSTVOselektovanoCopy, DrzavljaninBiH: "DA", posjedujedrzBiH: TEMPposjedujedrzBiH }
        
                    }
        
                }
        
                if (action.payload.id === 'DRZAVLJANSTVO2') {
                    let DRZAVLJANSTVO2selektovanoCopy = Object.assign({}, action.payload.val);
        
                    if (DRZAVLJANSTVO2selektovanoCopy.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "Bosna i Hercegovina") {
                        return { ...state, DRZAVLJANSTVO2selektovano: action.payload.val, DRZAVLJANSTVO2: DRZAVLJANSTVO2selektovanoCopy, DrzavljaninBiH: "NE" }
        
                    } else {
        
                        let TEMPposjedujedrzBiH = ""; //Kreiraj poveznicu "posjeduje drzavljanstvo Bosne i Hercegovine.."
                        state.posjedujedrzBiH = "";
        
                        if (state.DRZAVLJANSTVO.ime === "") {
                            TEMPposjedujedrzBiH = "posjeduje državljanstvo Bosne i Hercegovine";
                            //console.log("2 - if 1")
                        }
                        else if (DRZAVLJANSTVO2selektovanoCopy.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "" && state.DRZAVLJANSTVO.ime !== "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "pored državljanstva " + (state.DRZAVLJANSTVO.gen || "____bbb_____") + " posjeduje i državljanstvo Bosne i Hercegovine";
                            //console.log("2 - if 2")
        
                        }
                        else if (DRZAVLJANSTVO2selektovanoCopy.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "" && state.DRZAVLJANSTVO.ime === "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "posjeduje državljanstvo Bosne i Hercegovine";
                            //console.log("2 - if 3")
                        }
                        else if (DRZAVLJANSTVO2selektovanoCopy.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO.ime !== "" && state.DRZAVLJANSTVO.ime === "Bosna i Hercegovina") {
                            TEMPposjedujedrzBiH = "pored državljanstva " + (DRZAVLJANSTVO2selektovanoCopy.gen || "____bbb_____") + " posjeduje i državljanstvo Bosne i Hercegovine";
                            //console.log("2 - if 4")
                        }
        
        
                        return { ...state, DRZAVLJANSTVO2selektovano: action.payload.val, DRZAVLJANSTVO2: DRZAVLJANSTVO2selektovanoCopy, DrzavljaninBiH: "DA", posjedujedrzBiH: TEMPposjedujedrzBiH }
        
                    }
        
                }
         */


        if (action.payload.id === 'DRZAVLJANSTVO') {
            let posjedujedrzavljanstvoTEMP;
            let licnipodaciTEMP = state.licnipodaci

            //Prvo provjeri podudaranje DRZMOLITELJICA i DRZAVLJANSTVO (bilo koje) i pripremi updajtovane sveOkolnostiLicne i odabraneOkolnostiLicne
            const LbjekstvoumaticnuIndex = state.sveOkolnostiLicne.findIndex(item => item.value === "Lbjekstvoumaticnu");
            let updatedLbjekstvoumaticnu = { ...state.sveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti };
            let updatedSveOkolnostiLicne = [...state.sveOkolnostiLicne];

            const bjekstvoMaticna = () => {
                let bijegumaticnu;

                if (state.DRZMOLITELJICA) {
                    let opasnaMaticna = [action.payload.val, state.DRZAVLJANSTVO2].filter(
                        (item) =>
                            item &&
                            item.ime !== "Bosna i Hercegovina" &&
                            item.ime !== state.DRZMOLITELJICA.ime
                    );


                    if (opasnaMaticna.length !== 0) {
                        bijegumaticnu = true;
                    } else {
                        bijegumaticnu = false;
                    }
                }
                else { // nema DRZMOLITELJICE
                    bijegumaticnu = false;
                }

                return { bijegumaticnu };
            } // bjekstvoMaticna()

            const { bijegumaticnu } = bjekstvoMaticna()

            if (bijegumaticnu) {
                updatedLbjekstvoumaticnu.cekirano = !bijegumaticnu;
                updatedLbjekstvoumaticnu.disabled = !bijegumaticnu;
            }
            else {
                updatedLbjekstvoumaticnu.cekirano = bijegumaticnu;
                updatedLbjekstvoumaticnu.disabled = !bijegumaticnu;
            }

            updatedSveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti = updatedLbjekstvoumaticnu;

            let updatedOdabraneOkolnostiLicne = updatedSveOkolnostiLicne.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiLicne = "";


            if (action.payload.val?.ime === "Bosna i Hercegovina" || state.DRZAVLJANSTVO2?.ime === "Bosna i Hercegovina") {
                state.DrzavljaninBiH = "DA";
                state.stranac = "NE";
                //  state.vrstaDopisa = "";

                /*
                BiH         "" 
                BIH         !"" !BiH
                BIH         BIH
        
                ""          BIH
                !"" !BIH    BIH
        
                */
                if (action.payload.val?.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO2?.ime === undefined) {
                    posjedujedrzavljanstvoTEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine"
                    //console.log("1 DA if 1")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (action.payload.val?.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO2?.ime !== undefined && state.DRZAVLJANSTVO2?.ime !== "Bosna i Hercegovina") {
                    posjedujedrzavljanstvoTEMP = "pored državljanstva " + (state.DRZAVLJANSTVO2.gen || "__________") + " posjeduje i državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine i " + (state.DRZAVLJANSTVO2.gen || "__________");

                    //console.log("1 DA if 2")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (action.payload.val?.ime === "Bosna i Hercegovina" && state.DRZAVLJANSTVO2?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvoTEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine";


                    //console.log("1 DA if 3")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (action.payload.val?.ime === undefined && state.DRZAVLJANSTVO2?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvoTEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine";

                    //console.log("1 DA if 4")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }

                }
                else if (action.payload.val?.ime !== undefined && action.payload.val?.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO2?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvoTEMP = "pored državljanstva " + (action.payload.val.gen || "__________") + " posjeduje i državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine i " + (action.payload.val.gen || "__________");
                    //console.log("1 DA if 5")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }

                }


            } else if (action.payload.val?.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO2?.ime !== "Bosna i Hercegovina") {
                state.DrzavljaninBiH = "NE";
                state.stranac = "DA";

                /*
                !"" !BiH    "" 
                !"" !BiH    !"" !BiH
                ""    !"" !BiH
                ""  "" 
                */


                if (action.payload.val?.ime !== undefined && state.DRZAVLJANSTVO2?.ime === undefined) {
                    posjedujedrzavljanstvoTEMP = "posjeduje državljanstvo " + (action.payload.val.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (action.payload.val.gen || "__________")

                    //console.log("1 NE if 1")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (action.payload.val?.ime !== undefined && state.DRZAVLJANSTVO2?.ime !== undefined) {
                    posjedujedrzavljanstvoTEMP = "pored državljanstva " + (action.payload.val.gen || "__________") + " posjeduje i državljanstvo " + (state.DRZAVLJANSTVO2.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (action.payload.val.gen || "__________") + " i " + (state.DRZAVLJANSTVO2.gen || "__________");

                    //console.log("1 NE if 2")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (action.payload.val?.ime === undefined && state.DRZAVLJANSTVO2?.ime !== undefined) {
                    posjedujedrzavljanstvoTEMP = "posjeduje državljanstvo " + (state.DRZAVLJANSTVO2.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (state.DRZAVLJANSTVO2.gen || "__________");

                    //console.log("1 NE if 3")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }

                }
                else if ((action.payload.val?.ime === undefined || action.payload.val?.ime === "") && (state.DRZAVLJANSTVO2?.ime === undefined || state.DRZAVLJANSTVO2?.ime === "")) {
                    posjedujedrzavljanstvoTEMP = "";
                    licnipodaciTEMP.državljanstvo = "";

                    //console.log("1 NE if 4")
                    return { ...state, DRZAVLJANSTVO: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvoTEMP, licnipodaci: licnipodaciTEMP, stranac: "DA", dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }

                }

            }

        } //payload DRZAVLJANSTVO

        else if (action.payload.id === 'DRZAVLJANSTVO2') {
            let posjedujedrzavljanstvo2TEMP;
            let licnipodaciTEMP = state.licnipodaci

            //Prvo provjeri podudaranje DRZMOLITELJICA i DRZAVLJANSTVO (bilo koje) i pripremi updajtovane sveOkolnostiLicne i odabraneOkolnostiLicne
            const LbjekstvoumaticnuIndex = state.sveOkolnostiLicne.findIndex(item => item.value === "Lbjekstvoumaticnu");
            let updatedLbjekstvoumaticnu = { ...state.sveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti };
            let updatedSveOkolnostiLicne = [...state.sveOkolnostiLicne];

            const bjekstvoMaticna = () => {
                let bijegumaticnu;

                if (state.DRZMOLITELJICA) {
                    let opasnaMaticna = [action.payload.val, state.DRZAVLJANSTVO].filter(
                        (item) =>
                            item &&
                            item.ime !== "Bosna i Hercegovina" &&
                            item.ime !== state.DRZMOLITELJICA.ime
                    );


                    if (opasnaMaticna.length !== 0) {
                        bijegumaticnu = true;
                    } else {
                        bijegumaticnu = false;
                    }
                }
                else { // nema DRZMOLITELJICE
                    bijegumaticnu = false;
                }

                return { bijegumaticnu };
            } // bjekstvoMaticna()

            const { bijegumaticnu } = bjekstvoMaticna()

            if (bijegumaticnu) {
                updatedLbjekstvoumaticnu.cekirano = !bijegumaticnu;
                updatedLbjekstvoumaticnu.disabled = !bijegumaticnu;
            }
            else {
                updatedLbjekstvoumaticnu.cekirano = bijegumaticnu;
                updatedLbjekstvoumaticnu.disabled = !bijegumaticnu;
            }


            updatedSveOkolnostiLicne[LbjekstvoumaticnuIndex].pojedinosti = updatedLbjekstvoumaticnu;

            let updatedOdabraneOkolnostiLicne = updatedSveOkolnostiLicne.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiLicne = "";

            if (action.payload.val?.ime === "Bosna i Hercegovina" || state.DRZAVLJANSTVO?.ime === "Bosna i Hercegovina") {
                state.DrzavljaninBiH = "DA";
                state.stranac = "NE";
                //   state.vrstaDopisa = "";
                /*
                   ""          BiH   
                   !"" !BiH    BIH   
                   BIH         BIH
                   BIH         ""     
                   BIH         !"" !BIH  
        
             */

                if (state.DRZAVLJANSTVO?.ime === undefined && action.payload.val?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvo2TEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine";

                    //console.log("2 DA if 1")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (state.DRZAVLJANSTVO?.ime !== undefined && state.DRZAVLJANSTVO?.ime !== "Bosna i Hercegovina" && action.payload.val?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvo2TEMP = "pored državljanstva " + (state.DRZAVLJANSTVO.gen || "__________") + " posjeduje i državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = (state.DRZAVLJANSTVO.gen || "__________") + " i Bosne i Hercegovine";

                    //console.log("2 DA if 2")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (state.DRZAVLJANSTVO?.ime === "Bosna i Hercegovina" && action.payload.val?.ime === "Bosna i Hercegovina") {
                    posjedujedrzavljanstvo2TEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine";

                    //console.log("2 DA if 3")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }
                }
                else if (state.DRZAVLJANSTVO?.ime === "Bosna i Hercegovina" && action.payload.val?.ime === undefined) {
                    posjedujedrzavljanstvo2TEMP = "posjeduje državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine";

                    //console.log("2 DA if 4")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (state.DRZAVLJANSTVO?.ime === "Bosna i Hercegovina" && action.payload.val?.ime !== undefined && action.payload.val?.ime !== "Bosna i Hercegovina") {
                    posjedujedrzavljanstvo2TEMP = "pored državljanstva " + (action.payload.val.gen || "__________") + " posjeduje i državljanstvo Bosne i Hercegovine";
                    licnipodaciTEMP.državljanstvo = "Bosne i Hercegovine i " + (action.payload.val.gen || "__________");

                    //console.log("2 DA if 5")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }

            } else if (action.payload.val?.ime !== "Bosna i Hercegovina" && state.DRZAVLJANSTVO?.ime !== "Bosna i Hercegovina") {
                state.DrzavljaninBiH = "NE";
                state.stranac = "DA";
                /*
                      ""          !"" !BiH
                      !"" !BiH    !"" !BiH
                      !"" !BiH    ""
                      ""          "" 
                   */


                if (state.DRZAVLJANSTVO?.ime === undefined && action.payload.val?.ime !== undefined) {
                    posjedujedrzavljanstvo2TEMP = "posjeduje državljanstvo " + (action.payload.val.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (action.payload.val.gen || "__________");

                    //console.log("2 NE if 1")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (state.DRZAVLJANSTVO?.ime !== undefined && action.payload.val?.ime !== undefined) {
                    posjedujedrzavljanstvo2TEMP = "pored državljanstva " + (state.DRZAVLJANSTVO.gen || "__________") + " posjeduje i državljanstvo " + (action.payload.val.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (state.DRZAVLJANSTVO.gen || "__________") + " i " + (action.payload.val.gen || "__________");

                    //console.log("2 NE if 2")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "DA", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if (state.DRZAVLJANSTVO?.ime !== undefined && action.payload.val?.ime === undefined) {
                    posjedujedrzavljanstvo2TEMP = "posjeduje državljanstvo " + (state.DRZAVLJANSTVO.gen || "__________") + "";
                    licnipodaciTEMP.državljanstvo = (state.DRZAVLJANSTVO.gen || "__________");

                    //console.log("2 NE if 3")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }
                else if ((state.DRZAVLJANSTVO?.ime === undefined || state.DRZAVLJANSTVO?.ime === "") && (action.payload.val?.ime === undefined || action.payload.val?.ime === "")) {
                    posjedujedrzavljanstvo2TEMP = "";
                    licnipodaciTEMP.državljanstvo = "";

                    //console.log("2 NE if 4")
                    return { ...state, DRZAVLJANSTVO2: action.payload.val, posjedujedrzavljanstvo: posjedujedrzavljanstvo2TEMP, licnipodaci: licnipodaciTEMP, stranac: "DA", dvadrzavljanstva: "NE", sveOkolnostiLicne: updatedSveOkolnostiLicne, odabraneOkolnostiLicne: updatedOdabraneOkolnostiLicne }


                }

            }

        } // payload DRZAVLJANSTVO2


        if (action.payload.id === 'BR') {
            //console.log("BR", action.payload.val)

            if (action.payload.val.length === 0) {
                return { ...state, BRANILAC: [], dvabranioca: false }
            }

            if (action.payload.val.length === 1) {
                const deklinirano = Deklinacija(action.payload.val[0].ime, action.payload.val[0].spol)
                return {
                    ...state,
                    dvabranioca: false,
                    BRANILAC: [
                        {
                            ...action.payload.val[0],
                            imegen: deklinirano.gen,
                            imedat: deklinirano.dat
                        }
                    ]
                }

            } else if (action.payload.val.length >= 2) {
                const deklinirano1 = Deklinacija(action.payload.val[0].ime, action.payload.val[0].spol)
                const deklinirano2 = Deklinacija(action.payload.val[1].ime, action.payload.val[1].spol)

                return {
                    ...state,
                    BRANILAC: [
                        {
                            ...action.payload.val[0],
                            imegen: deklinirano1.gen,
                            imedat: deklinirano1.dat
                        },
                        {
                            ...action.payload.val[1],
                            imegen: deklinirano2.gen,
                            imedat: deklinirano2.dat
                        }
                    ],
                    vrstabranioca: "izabrani", dvabranioca: true
                }
            }

            // return { ...state, BRANILAC: action.payload.val, dvabranioca: false }
        }

        if (action.payload.id === 'SUDIJA') {
            if (action.payload.val === null) {
                return { ...state, SUDIJA: "" }
            }
            return { ...state, SUDIJA: action.payload.val }
        }

        if (action.payload.id === 'TUZILAC') {
            if (action.payload.val === null) {
                return { ...state, TUZILAC: "" }
            } else {
                const deklinirano = Deklinacija(action.payload.val.ime, action.payload.val.spol)
                return {
                    ...state,
                    TUZILAC: {
                        ...action.payload.val,
                        imegen: deklinirano.gen,
                        imedat: deklinirano.dat
                    }
                }
            }
        }

        if (action.payload.id === 'ZAPISNICAR') {
            if (action.payload.val === null) {
                return { ...state, ZAPISNICAR: "" }
            } else {
                const deklinirano = Deklinacija(action.payload.val.ime, action.payload.val.spol)
                //console.log("deklinirano", deklinirano.gen)
                return {
                    ...state,
                    ZAPISNICAR: {
                        ...action.payload.val,
                        imegen: deklinirano.gen,
                        imedat: deklinirano.dat
                    }
                }
            }

        }

        if (action.payload.id === 'MZSTANICA') {
            if (action.payload.val === null) {
                return { ...state, MZstanica: "" }
            }

            return { ...state, MZstanica: action.payload.val, }
        }

    }


    if (action.type === 'IZBOR_OKOLNOSTI') {



        if (action.payload.kategorija === 'MZOKOLNOSTI') {
            let tempsveOkolnostiMZOlaksavajuce = state.sveOkolnostiMZOlaksavajuce.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {

                    return {
                        ...okolnost,
                        pojedinosti: {
                            ...okolnost.pojedinosti,
                            cekirano: !okolnost.pojedinosti.cekirano
                        }
                    }
                }
                return okolnost
            })
            const MZboravisteCKD = tempsveOkolnostiMZOlaksavajuce.find((elem) => elem.value === "MZboraviste").pojedinosti.cekirano;
            const MZsmjestajCKD = tempsveOkolnostiMZOlaksavajuce.find((elem) => elem.value === "MZsmjestaj").pojedinosti.cekirano;


            if (action.payload.okolnost === "MZboraviste" && MZboravisteCKD) {
                tempsveOkolnostiMZOlaksavajuce.find((elem) => elem.value === "MZsmjestaj").pojedinosti.cekirano = false;
            }

            if (action.payload.okolnost === "MZsmjestaj" && MZsmjestajCKD) {
                tempsveOkolnostiMZOlaksavajuce.find((elem) => elem.value === "MZboraviste").pojedinosti.cekirano = false;
            }



            let tempodbrane = tempsveOkolnostiMZOlaksavajuce.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiMZOlaksavajuce = "";

            return { ...state, sveOkolnostiMZOlaksavajuce: tempsveOkolnostiMZOlaksavajuce, odabraneOkolnostiMZOlaksavajuce: tempodbrane }
        }

        if (action.payload.kategorija === 'OKOLNOSTIPROCESURIANJE') {
            let tempsveOkolnostiProcesuiranje = state.sveOkolnostiProcesuiranje.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {
                    return { ...okolnost, cekirano: action.payload.oznacena }
                }
                return okolnost
            })

            let tempodbrane = tempsveOkolnostiProcesuiranje.filter((e) => e.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiProcesuiranje = "";

            return { ...state, sveOkolnostiProcesuiranje: tempsveOkolnostiProcesuiranje, odabraneOkolnostiProcesuiranje: tempodbrane }

        }

        if (action.payload.kategorija === 'OKOLNOSTIIZVRSENJE') {
            let tempsveOkolnostiIzvrsenje = state.sveOkolnostiIzvrsenje.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {
                    return { ...okolnost, cekirano: action.payload.oznacena }
                }
                return okolnost
            })

            let tempodbrane = tempsveOkolnostiIzvrsenje.filter((e) => e.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiIzvrsenje = "";

            return { ...state, sveOkolnostiIzvrsenje: tempsveOkolnostiIzvrsenje, odabraneOkolnostiIzvrsenje: tempodbrane }


        }

        if (action.payload.kategorija === 'OKOLNOSTIILICNE') {
            let tempsveOkolnostiLicne = state.sveOkolnostiLicne.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {

                    return {
                        ...okolnost,
                        pojedinosti: {
                            ...okolnost.pojedinosti,
                            cekirano: !okolnost.pojedinosti.cekirano
                        }
                    }
                }
                return okolnost
            })
            const LbezvezasabihCKD = tempsveOkolnostiLicne.find((elem) => elem.value === "Lbezvezasabih").pojedinosti.cekirano;
            const LbeznacajnevezeCKD = tempsveOkolnostiLicne.find((elem) => elem.value === "Lbeznacajneveze").pojedinosti.cekirano;

            if (LbezvezasabihCKD) {
                tempsveOkolnostiLicne.find((elem) => elem.value === "Lbeznacajneveze").pojedinosti = { naziv: "beznačajne veze sa bih", cekirano: false, disabled: true };
            } else {
                tempsveOkolnostiLicne.find((elem) => elem.value === "Lbeznacajneveze").pojedinosti.disabled = false;
            }

            if (LbeznacajnevezeCKD) {
                tempsveOkolnostiLicne.find((elem) => elem.value === "Lbezvezasabih").pojedinosti = { naziv: "bez veza sa BiH", cekirano: false, disabled: true };
            } else {
                tempsveOkolnostiLicne.find((elem) => elem.value === "Lbezvezasabih").pojedinosti.disabled = false;
            }

            let tempodbrane = tempsveOkolnostiLicne.filter((e) => e.pojedinosti.cekirano === true).map(({ value }) => value).toString();
            state.odabraneOkolnostiLicne = "";

            return { ...state, sveOkolnostiLicne: tempsveOkolnostiLicne, odabraneOkolnostiLicne: tempodbrane }

        }

    }

    if (action.type === 'IZBOR_OSNOVUGOVOR') {
        //console.log("KATEGORIJA - " + action.payload.kategorija)
        //console.log("OKOLNOST - " + action.payload.okolnost)
        //console.log("OZNAČENA - " + action.payload.oznacena)
        //console.log("isspod IZBOR OSONVUGOVOR")
        /*
        sveOkolnostiProcesuiranje: [
                { name: "Visinakazne", value: "Pvisinakazne" },
        
         onChange={(e) => izborOkolnosti("OKOLNOSTIPROCESURIANJE", e.target.value, e.currentTarget.checked)}
        
         const izborOsnovUgovor = (kategorija, okolnost, oznacena) => {
                dispatch({ type: 'IZBOR_OKOLNOSTI', payload: { kategorija, okolnost, oznacena } })
            }     
      || action.payload.kategorija !== 'HR' || action.payload.kategorija !== 'RS' || action.payload.kategorija !== 'ME'
        */
        //Obrisana zemlja
        if (action.payload.kategorija === '') {
            return { ...state, osnoviugovor: "" }
        }

        //Hrvatska
        else if (action.payload.kategorija === 'HR') {


            let temposnoviugovorHR = state.osnoviugovorHR.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {
                    return { ...okolnost, cekirano: action.payload.oznacena }
                }
                return okolnost
            })

            //Odčekiraj članove za procesuiranje ako je procesuiranje false
            if (action.payload.okolnost === "ponistiproces") {
                temposnoviugovorHR = temposnoviugovorHR.map((okolnost) => {
                    if (okolnost.vrsta === "proces") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }
            //Odčekiraj članove za izvršenje ako je izvršenje false
            if (action.payload.okolnost === "ponistizvrsenje") {
                temposnoviugovorHR = temposnoviugovorHR.map((okolnost) => {
                    if (okolnost.vrsta === "izvrsenje") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }
            /*
             
            */
            let temposnovi = temposnoviugovorHR.filter((e) => e.cekirano === true).map(({ value }) => value).toString();

            state.osnoviugovor = "";
            return { ...state, osnoviugovorHR: temposnoviugovorHR, osnoviugovor: temposnovi }

        }
        //Srbija (Republika Srbija)
        else if (action.payload.kategorija === 'RS') {
            let temposnoviugovorRS = state.osnoviugovorRS.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {
                    return { ...okolnost, cekirano: action.payload.oznacena }
                }
                return okolnost
            })

            //Odčekiraj članove za procesuiranje ako je procesuiranje false
            if (action.payload.okolnost === "ponistiproces") {
                temposnoviugovorRS = temposnoviugovorRS.map((okolnost) => {
                    if (okolnost.vrsta === "proces") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }
            //Odčekiraj članove za izvršenje ako je izvršenje false
            if (action.payload.okolnost === "ponistizvrsenje") {
                temposnoviugovorRS = temposnoviugovorRS.map((okolnost) => {
                    if (okolnost.vrsta === "izvrsenje") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }


            let temposnovi = temposnoviugovorRS.filter((e) => e.cekirano === true).map(({ value }) => value).toString();
            state.osnoviugovor = "";
            return { ...state, osnoviugovorRS: temposnoviugovorRS, osnoviugovor: temposnovi }

        }
        //Crna Gora (Montenegro)
        else if (action.payload.kategorija === 'ME') {
            let temposnoviugovorME = state.osnoviugovorME.map((okolnost) => {
                if (okolnost.value === action.payload.okolnost) {
                    return { ...okolnost, cekirano: action.payload.oznacena }
                }
                return okolnost
            })

            //Odčekiraj članove za procesuiranje ako je procesuiranje false
            if (action.payload.okolnost === "ponistiproces") {
                temposnoviugovorME = temposnoviugovorME.map((okolnost) => {
                    if (okolnost.vrsta === "proces") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }
            //Odčekiraj članove za izvršenje ako je izvršenje false
            if (action.payload.okolnost === "ponistizvrsenje") {
                temposnoviugovorME = temposnoviugovorME.map((okolnost) => {
                    if (okolnost.vrsta === "izvrsenje") {
                        return { ...okolnost, cekirano: false }
                    }
                    return okolnost
                })
            }

            let temposnovi = temposnoviugovorME.filter((e) => e.cekirano === true).map(({ value }) => value).toString();
            state.osnoviugovor = "";
            return { ...state, osnoviugovorME: temposnoviugovorME, osnoviugovor: temposnovi }

        }
        else {
            return { ...state, osnoviugovor: "" }
        }



    }


    throw new Error('no matching action type')
}

export default reducer