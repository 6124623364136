import { format } from "date-fns";
import { add } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

let PRevTMP = add(new Date(), {
    days: 3
})
PRevTMP = format(PRevTMP, 'dd.MM.yyyy') + ". godine"

let PROevTMP = add(new Date(), {
    days: 3
})
PROevTMP = format(PROevTMP, 'dd.MM.yyyy') + ". godine"

let EKSevTMP = add(new Date(), {
    days: 3
})
EKSevTMP = format(EKSevTMP, 'dd.MM.yyyy') + ". godine"

const prazanState = {
    /*
 const sveOkolnostiProcesuiranje = [
     { name: "Visinakazne", value: "Pvisinakazne", cekirano: true },
     { name: "Teško djelo", value: "Pteskodjelo", cekirano: false },
     { name: "Više djela", value: "Pvisedjela", cekirano: false },
     { name: "Nedostupan duže vrijeme", value: "Pnedostupanduzevrijeme", cekirano: false }
 ];
 
 const today = new Date();
 const dd = String(today.getDate()).padStart(2, '0');
 const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
 const yyyy = today.getFullYear();
 
 const danasnjidatum = dd + '.' + mm + '.' + yyyy + '.godine';
 
 
 const PRkrajlisenja = add(new Date(), {
   days: 17
 })
 
 const PROkrajlisenja = add(new Date(), {
   days: 40
 })
 
 const EKSkrajlisenja = add(new Date(), {
 months: 6
 })
 
 */
 ID: uuidv4(),
 updejtujStranke : 0,  // state value koji se mijenja nakon uspješnog dodavanja stranke, a za tim trigeruje fetch u inputima
 danasnjidatum: new Date(),
 datumodlukeTMP: new Date(),
 datumodluke: "",
 datumodlukeODBTMP: "",
 datumodlukeODB: "",
 datumodlukeMZTMP: "",
 datumodlukeMZ: "",
 datumodlukePRTMP: "",
 datumodlukePR: "",
 datumodlukePREKSTMP: "",
 datumodlukePREKS: "",
 datumodlukePROTMP: "",
 datumodlukePRO: "",
 datumodlukeEKSTMP: "",
 datumodlukeEKS: "",
 datumodlukeBRTMP: "",
 datumodlukeBR: "",

 vrijemeunosa: "",
 vrijemeizmjene: "",
 brojPredmeta: "",
 IMELICA: { "ime": "", "imegen": "", "imedat": "" },
 licezensko: false,
 licnipodaciCanvas: false,
 DRZMOLITELJICA: "",
 PostojiUgovor: "NE",
 DRZAVLJANSTVO: "",
 DRZAVLJANSTVO2: "",
 drugodrzavljanstvo: false,
 DrzavljaninBiH: "NE",
 posjedujedrzavljanstvo: "",
 dvadrzavljanstva: "NE",
 stranac: "DA",

 licnidokumenti: [],

 licnipodaci: {
   otac: "",
   majka: "",
   prezimemajke: "",
   datumrođenja: "",
   mjestorođenja: "",
   nastanjen: "",
   nacionalnost: "",
   državljanstvo: "",
   uvjerenje: "",
   zanimanje: "",
   zaposlen: false,
   firma: "",
   oženjen: false,
   djeca: false,
   brojdjece: "",
 },

 ncb1: "",
 ncb2: "",
 razlogIzrucenjaCanvas: false,
 dodajTuziocaCanvas: false,
 dodajAdvokataCanvas: false,
 dodajZapinsicaraCanvas: false,
 dodajSudijuCanvas: false,

 procesuiranje: true,
 izvrsenje: false,

 procesuiranjeInfo: [{
   procesuiranjekd: "",
   procesuiranjekazna: { val: "", paraf: "", parafrazirano: "DA" },    //{val: "", paraf: "", parafrazirano: "NE" }
   procesuiranjevrijemeiizvrsenja: "",
   procesuiranjebrojnaloga: "",
   procesuiranjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
   procesuiranjeODB: { vrijemevazenja: false, visinakazne: false }
 }
 ],
 izvrsenjeInfo: [{
   izvrsenjepresuda: "",
   izvrsenjenazivsuda: { val: "", nom: "", gen: undefined }, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
   izvrsenjekd: "",
   izvrsenjekazna: { val: "", izrecenakazna: "", preostalidio: undefined, izrecenakaznanom: "", preostalidionom: "" },   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
   izvrsenjeprisutanizricanju: "DA",
   izvrsenjevrijemeizvrsenja: "",
   izvrsenjebrojnaloga: "",
   izvrsenjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
   izvrsenjeODB: { vrijemevazenja: false, visinakazne: false }
 }],
 alerti: { kaznapreniska: false, },
 datumlisenjaTMP: "",
 datumlisenja: "",
 organLisenja: { val: "", nom: "", gen: "" },

 PRkrajlisenja: "",
 
 ODBfaza: "",
 MZfaza: "",
 PRfaza: "",
 PREKSfaza: "",
 BRfaza:"",

 PROpostoji: true,
 PROfaza: 2,
 PROzahtjevNCB: false,
 PROzahtjevMPBIH: false,
 PROzahtjevdostavljenBR: "",
 PROzahtjevNCBbroj: "",
 PROzahtjevNCBdatumprijema: "",
 PROzahtjevMPBIHbroj: "",
 PROzahtjevMPBIHdatumprijema: "",
 PROzahtjev: "",

 PROkrajlisenja: "",
 PROopcijeCanvas: false,

 EKSopcijeCanvas: false,
 EKSfaza: 3,
 EKSbrojprijedloga: "",
 EKSbrojmolbe: "",
 EKSkrajlisenja: "",

 PRev: PRevTMP,
 PROev: PROevTMP,
 EKSev: EKSevTMP,

 vrstaOdluke: "",
 vrstaDopisa: "",
 ODBobustavi: "NE",
 ODBopcijeCanvas: false,
 ODBopcije: { aktivnapotjernica: true, NCBobavijestNeaktivnaPotjernica: "" },

 MZopcijeCanvas: false,
 MZputovanje: false,
 MZboraviste: false,
 MZboravistekucni: "NE",
 MZboravistepodrucje: "područja grada ______",
 MZboravisteadresa: "",
 MZboravisteadresanacinprovjere:
   "jednom dnevno, nasumično, u vremenskom periodu između 08,00 i 12:00 sati",
 MZstanica: "", //za boravište i javljanje

 MZjavljanje: false,
 MZjavljanjenacin:
   "dva puta sedmično (svakog ______ i _____) u vremenskom periodu od 08:00 do 12:00 sati",

 MZpreuzmiisprave: false,
 MZprikazipasose: false,
 MZpasosi: [],
 MZdodajispravu: false,
 MZnemapasosaAlert: false,

 vrstabranioca: "posluzbenoj",
 BRnacinizbora: "",
 OkolnostiCanvas: false,
 sveOkolnostiMZOlaksavajuce: [
   { value: "MZboraviste", pojedinosti: { naziv: "boravište/prebivalište u BiH", cekirano: false, disabled: false } },
   { value: "MZsmjestaj", pojedinosti: { naziv: "osiguran/iznajmljen smještaj u BiH", cekirano: false, disabled: false } },
   { value: "MZporodicne", pojedinosti: { naziv: "porodične veze sa BiH", cekirano: false, disabled: false } }
 ],
 odabraneOkolnostiMZOlaksavajuce: [],

 sveOkolnostiProcesuiranje: [
   /*{ value: "Pvisinakazne", pojedinosti: {naziv: "visina kazne",  cekirano: false, disabled: false}},
   { value: "Pteskodjelo", pojedinosti: {naziv: "teško djelo",  cekirano: false, disabled: false}},*/
   { value: "Pvisedjela", pojedinosti: { naziv: "više djela", cekirano: false, disabled: false } },
   /*{
     name: "nedostupan duže vrijeme",
     value: "Pnedostupanduzevrijeme",
     cekirano: false,
   },*/
 ],
 odabraneOkolnostiProcesuiranje: [],
 sveOkolnostiIzvrsenje: [
   /*{ value: "Ivisinakazne", pojedinosti:{naziv: "visina kazne",  cekirano: false,      disabled: false}  },
   {
     value: "Iprisutanizricanju",
     pojedinosti: {naziv: "prisutan izricanju",
     cekirano: false,
     disabled: false}
   },
   {
     name: "nedostupan duže vrijeme",
     value: "Inedostupanduzevrijeme",
     cekirano: false,
   },*/
 ],
 odabraneOkolnostiIzvrsenje: [],
 sveOkolnostiLicne: [
   {
     value: "Lfalsdokumenti",
     pojedinosti: {
       naziv: "posjed lažnog dokumenta",
       cekirano: false,
       disabled: false
     }
   },
   // {value: "Lnemadokumenata"},  ... razmotriti automatsko obrazloženje (kretao se, teža identifikacija...)
   { value: "Lvisealiasa", pojedinosti: { naziv: "više aliasa", cekirano: false, disabled: false } },
   {
     value: "Lbjekstvoumaticnu",
     pojedinosti: {
       naziv: "bjekstvo u matičnu zemlju",
       cekirano: false,
       disabled: false
     }
   },
   {
     value: "Lboravisteudrugojzemlji",
     pojedinosti: {
       naziv: "boravište u drugoj zemlji",
       cekirano: false,
       disabled: false
     }
   },
   {
     value: "Lvezesadrugomzemljom",
     pojedinosti: {
       naziv: "veze sa drugom zemljom",
       cekirano: false,
       disabled: false
     }
   },
   { value: "Lbezvezasabih", pojedinosti: { naziv: "bez veza sa BiH", cekirano: false, disabled: false } },
   {
     value: "Lbeznacajneveze",
     pojedinosti: {
       naziv: "beznačajne veze sa BiH",
       cekirano: false,
       disabled: false
     }
   },

 ],
 odabraneOkolnostiLicne: "",
 osnoviugovor: "",
 osnoviugovorHR: [
   {
     id: "1",
     name: "član 7. stav 1.",
     value: "PugovorHRVclan71",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <strong>krivičnog gonjenja</strong> za krivična djela <strong>organiziranog kriminala, korupcije i pranja novca </strong> čija kazna <b>uključuje</b> oduzimanje slobode u trajanju od <strong>četiri godine ili teža kazna</strong>.",
   },
   {
     id: "2",
     name: "član 7. stav 2.",
     value: "IugovorHRVclan72",
     vrsta: "izvrsenje",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <strong>izvršenja</strong> pravosnažno izrečene kazne zatvora za krivična djela <strong>organiziranog kriminala, korupcije i pranja</strong> novca ako ostatak kazne koji se treba izvršiti iznosi <strong>najmanje dvije godine</strong>.",
   },
   {
     id: "3",
     name: "član 8. stav 1.",
     value: "PugovorHRVclan81",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <strong>krivičnog gonjenja</strong> za druga <strong>teška krivična djela</strong> za koja se može izreći kazna zatvora koja <strong>uključuje</strong> oduzimanje slobode u trajanju <strong>od deset godina ili teža kazna</strong>.",
   },
   {
     id: "4",
     name: "član 8. stav 2.",
     value: "IugovorHRVclan82",
     vrsta: "izvrsenje",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <strong>izvršenja</st> pravosnažno izrečene kazne zatvora za druga <strong>teška krivična djela</strong> ako ostatak kazne koji se treba izvršiti iznosi <strong>najmanje pet godina</strong>.",
   },
 ],
 osnoviugovorRS: [
   //Srbija (Republika Srbija)
   {
     id: "1",
     name: "član 7. stav 1.",
     value: "PugovorSRBclan71",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za krivična djela <b>organiziranog kriminala, korupcije i pranja novca </b> čija kazna <b>uključuje</b> oduzimanje slobode u trajanju od <b>četiri godine ili teža kazna</b>.",
   },
   {
     id: "2",
     name: "član 7. stav 2.",
     value: "IugovorSRBclan72",
     vrsta: "izvrsenje",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>izvršenja</b> pravosnažno izrečene kazne zatvora za krivična djela <b>organiziranog kriminala, korupcije i pranja</b> novca ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
   },
   {
     id: "3",
     name: "član 8. stav 1.",
     value: "PugovorSRBclan81",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za <b>druga teška krivična djela</b> za koja se može izreći kazna zatvora koja <b>uključuje</b> oduzimanje slobode u trajanju <b>od najmanje 5 godina ili teža kazna</b>.",
   },
   {
     id: "4",
     name: "član 8. stav 2.",
     value: "IugovorSRBclan82",
     vrsta: "izvrsenje",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>izvršenja</b> pravosnažno izrečene kazne zatvora za druga <b>teška krivična djela</b> ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
   },
 ],
 osnoviugovorME: [
   //Crna Gora (Montenegro)
   {
     id: "1",
     name: "član 7. stav 1.",
     value: "PugovorCRNclan71",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za krivična djela <b>organiziranog kriminala, korupcije i pranja novca</b> čija kazna <b>podrazumijeva</b> oduzimanje slobode u trajanju od <b>četiri godine ili teža kazna</b>.",
   },
   {
     id: "2",
     name: "član 7. stav 2.",
     value: "PugovorCRNclan72",
     vrsta: "proces",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana u svrhu <b>krivičnog gonjenja</b> za <b>ostala teška krivična djela, odnosno teške oblike krivičnih djela</b> za koja je <b>propisana</b> kazna zatvora u trajanju od <b>nakmanje pet godina ili teža kazna</b>.",
   },
   {
     id: "3",
     name: "član 7. stav 3.",
     value: "IugovorCRNclan73",
     vrsta: "izvrsenje",
     cekirano: false,
     sadrzaj:
       "Izručenje vlastitih državljana radi <b>izvršenja</b> pravosnažno izrečene kazne zatvora za druga <b>organiziranog kriminala, korupcije i pranja novca i ostala teška krivična djela</b> ako ostatak kazne koji se treba izvršiti iznosi <b>najmanje dvije godine</b>.",
   },
 ],


 // BRANILAC: {
 //   ime: "__________",
 //   imegen: "__________",
 //   adresa: "__________",
 //   kontakt: "__________",
 //   grad: "__________",
 //   gradgen: "__________",
 // },
 BRANILAC: [],
 dvabranioca: false,

 SUDIJA: "",
 ZAPISNICAR: "",
 TUZILAC: "",
 TUMAC: { ime: "", imegen: "", imedat: "", spol: "m" },
 dodajtumaca: false,
 imetumaca: "",
 jezikprevoda: "",
 izaberiZavodCanvas: false,
 zavod: "ZAVODZIKS", //string/prop koji se šalje u <Skracenica kod="{zavod}"/>

 pL: {
   textAlign: "left",
   fontFamily: "Arial",
 },
 pR: {
   textAlign: "right",
   fontFamily: "Arial",
 },
 pJ: {
   textAlign: "justify",
   fontFamily: "Arial",
 },
 pC: {
   textAlign: "center",
   fontFamily: "Arial",
 },
 //Replacementi, kasnije uraditi posebnu komponentu
 POTRAŽIVANI: "POTRAŽIVANI",
 Potraživani: "Potraživani",
 potraživani: "potraživani",
 POTRAŽIVANOM: "POTRAŽIVANOM",
 Potraživanom: "Potraživanom",
 potraživanom: "potraživanom",
 potraživanog: "potraživanog",
 potraživanogg: "potraživanog",
 državljanin: "državljanin",
 počinio: "počinio",
 poučen: "poučen",
 te_mu_je: "te mu je",
 njegov: "njegov",
 njegovi: "njegovi",
 njegovo: "njegovo",
 njegovog: "njegovog",
 pozvan: "pozvan",
 priveden: "priveden",
 saslušan: "saslušan", //
 izjasnio: "izjasnio",
 isti: "isti",
 izručen: "izručen",
 mogao: "mogao",
 mu_se: "mu se",
 posjedovao: "posjedovao",
 samog: "samog",
 saopštio: "saopštio",
 sam: "sam",
 naveo: "naveo",
 potvrdio: "potvrdio",
 mu_je: "mu je",
 njegovom: "njegovom",
 njega: "njega",
 dao: "dao",
 odabrao: "odabrao",
 koji: "koji",
 lišen: "lišen",
 istom: "istom", //
 njegovim: "njegovim",
 njegovih: "njegovih",
 upućen: "upućen",
 bio: "bio",
 Saglasan: "Saglasan",
 saglasan: "saglasan",
 njegove: "njegove",
 lišenog: "lišenog",
 upisan: "upisan",
 da_ga: "da ga",
 koja_ga: "koja ga",
 izdržao: "izdržao",
 osuđen: "osuđen",
 saglasio: "saglasio",
 on: "on",
 upoznat: "upoznat",
 kojeg: "kojeg",
 pušten: "pušten",
 nedostupan: "nedostupan",
 Prisutan: "Prisutan",
 prisutan: "prisutan",
 bi_ga: "bi ga",
 svjestan: "svjestan",


};

export default prazanState;