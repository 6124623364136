import React, { useState } from 'react';
import { useGlobalContext } from '../../context'

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

import Card from "react-bootstrap/Card";
import Upozorenje2 from './Upozorenje2';

const BRdodatne = () => {
    const { inputChange, vrstabranioca, BRnacinizbora, BRANILAC } = useGlobalContext()
    const promijeni = (id, val) => inputChange(id, val);
    const BRprovjeriopcije = () => {
        if (vrstabranioca === 'izabrani') {
            return '';
        }
        if (vrstabranioca === 'posluzbenoj' && (BRnacinizbora === 'lista' || BRnacinizbora === 'bezliste')) {
            return '';
        }
        return 'obavezno';
    };
    const isDisabled = Array.isArray(BRANILAC) && BRANILAC.length === 2;

    const [alert, setAlert] = useState({ show: false, tip: '' });
    const prikaziUpozorenje = (show = false, tip = 'danger', pozicija = 'inline') => {
      setAlert({ show, tip, pozicija });
    };
  
  const handleClick = () => {
    if (isDisabled) {
        prikaziUpozorenje(true, 'danger', 'fixed');
    }
  };

    return <>
    
    {alert.show && <Upozorenje2 {...alert} sakrijUpozorenje={prikaziUpozorenje}>Morate obrisati jednog advokata ukoliko će odbrana biti po službenoj dužnosti!</Upozorenje2>}


        <Card
            bg=""
            key="1"
            text="dark"
            style={{textAlign: "left" }}
            className={`text-dark mb-2 ${BRprovjeriopcije()}`}
        >
            <Card.Header className={BRprovjeriopcije()}>
                Vrsta branioca
            </Card.Header>
            <Card.Body className='p-2'>

                <ToggleButtonGroup type="checkbox" name="BRvrstabranioca" value={vrstabranioca} className="mb-2">

                    <ToggleButton id="BRposluzbenoj" variant="outline-secondary"
                     value="posluzbenoj"
                   // onChange={(e) => promijeni("vrstabranioca", e.target.value)}
                   onChange={(e) => promijeni("vrstabranioca", BRANILAC.length === 2 ? "izabrani" : "posluzbenoj")}
                    style={{ fontSize: "14px" }}
                    onClick={handleClick}
                    >
                        po službenoj
                    </ToggleButton>
                    <ToggleButton id="BRizabrani" variant="outline-secondary" value="izabrani" onChange={(e) => promijeni("vrstabranioca", e.target.value)} style={{ fontSize: "14px" }}>
                        izabrani
                    </ToggleButton>

                </ToggleButtonGroup>



                {(vrstabranioca === "posluzbenoj") && <>
                    <ToggleButtonGroup type="radio" name="BRnacinizbora" value={BRnacinizbora} className="mb-2" >
                        <ToggleButton id="BRpredocenalista" variant="outline-secondary" value="lista" onChange={(e) => promijeni("BRnacinizbora", e.target.value)} style={{ fontSize: "14px" }}>
                            predočena lista
                        </ToggleButton>

                        <ToggleButton id="BRbezliste" variant="outline-secondary" value="bezliste" onChange={(e) => promijeni("BRnacinizbora", e.target.value)} style={{ fontSize: "14px" }}>
                            bez liste
                        </ToggleButton>

                    </ToggleButtonGroup>
                </>}

            </Card.Body>
        </Card>
       
        </>
}

export default BRdodatne