import React from 'react'
import { useGlobalContext } from '../../context'

import ToggleButton from "react-bootstrap/ToggleButton";
import Card from "react-bootstrap/Card";

const Okolnostilicne = () => {
    const { sveOkolnostiLicne, izborOkolnosti, odabraneOkolnostiLicne } = useGlobalContext()

    const OkolnostLicne = () => {
        return (
            <>
                {sveOkolnostiLicne.map((okolnost, index) => (
                    <ToggleButton
                        className="m-1"
                        key={index}
                        id={okolnost.value}
                        type="checkbox"
                        variant="outline-secondary"
                        size="sm"
                        checked={okolnost.pojedinosti.cekirano}
                        style={{ opacity: okolnost.pojedinosti.disabled ? "40%" : "",}}
                        value={okolnost.value}
                        onChange={(e) => izborOkolnosti("OKOLNOSTIILICNE", e.target.value, e.target.checked)}
                        disabled={okolnost.pojedinosti.disabled}
                        
                    >
                        {okolnost.pojedinosti.naziv}
                        
                    </ToggleButton>
                ))}
            </>
        );
    };

    return (<>
        <Card
            bg=""
            key="1"
            text="dark"
            style={{ textAlign: "left" }}
            className={`text-dark mb-2 ${odabraneOkolnostiLicne.length < 2 && 'obavezno'}`}
        >
            <Card.Header
                className={`${odabraneOkolnostiLicne.length < 2 && 'obavezno'}`}
            >
                Lične okolnosti potraživanog
            </Card.Header>
            <Card.Body className='p-2'>
                <OkolnostLicne />
            </Card.Body>
        </Card>
       
        <br />
    </>
    )
}

export default Okolnostilicne