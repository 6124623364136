import React from "react";
import { useGlobalContext } from "../../context";
import { ugovori } from "../liste/ugovori";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';


const ODBdodatne = () => {
  const {
    inputChange,
    ODBobustavi,
    kontrolisiCanvas,
    ODBopcijeCanvas,
    ODBopcije,
    licnipodaci,
    DRZMOLITELJICA,
    potraživanog,
    DrzavljaninBiH, PostojiUgovor, procesuiranje,izvrsenje, izvrsenjeInfo, procesuiranjeInfo

  } = useGlobalContext();
  const ODBpromijeni = (val) => inputChange("ODBobustavi", val);
  const ugovor = ugovori.find((ugovor) => ugovor.ime === DRZMOLITELJICA.ime);
  return (
    <>
      <Card
        bg=""
        key="1"
        text="dark"
        style={{ textAlign: "left" }}
        className={"text-dark mb-2"}
      >
        <Card.Header>Obustava postupka</Card.Header>
        <Card.Body className="p-2">
          <ToggleButtonGroup
            type="radio"
            name="ODBobustavi"
            value={ODBobustavi}
            onChange={ODBpromijeni}
          >
            <ToggleButton id="tbg-btn-1" variant="outline-secondary" value="NE">
              NE
            </ToggleButton>
            <ToggleButton id="tbg-btn-2" variant="outline-secondary" value="DA">
              DA
            </ToggleButton>
          </ToggleButtonGroup>
        </Card.Body>
      </Card>

      <div className="d-grid gap-2">
      <Button
        variant="outline-secondary obavezno mb-2"
        onClick={(e) => kontrolisiCanvas("CANVAS_ODBOPCIJE_OTVORI")}
      >
                Dodatne opcije
      </Button>
     </div>
      <Offcanvas
        style={{ width: "950px" }}
        show={ODBopcijeCanvas}
        onHide={(e) => kontrolisiCanvas("CANVAS_ODBOPCIJE_ZATVORI")}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Odbjanje zahtjeva - dodatne opcije</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
           
              <Col lg={12}>
                {!licnipodaci.državljanstvo && !DRZMOLITELJICA && (
                  <Alert variant="warning">
                    Unesite državljanstvo <strong>{potraživanog}</strong> u lične
                    podatke i <strong>državu moliteljicu</strong>.
                  </Alert>
                )}
                {licnipodaci.državljanstvo && !DRZMOLITELJICA && (
                  <Alert variant="warning">
                    Unesite <strong>državu moliteljicu</strong>.
                  </Alert>
                )}
                {!licnipodaci.državljanstvo && DRZMOLITELJICA && (
                  <Alert variant="warning">
                    Unesite <strong>državljanstvo {potraživanog}</strong> u lične
                    podatke
                  </Alert>
                )}
                 {(!procesuiranje && !izvrsenje) &&
                              
                  <Alert variant="warning">
                    Precizirajte <strong>razlog/e izručenja</strong>.
                  </Alert>
                               /*procesuiranje && izvrsenje */}

                  

                {/*
ODBopcije : {"aktivnapotjernica": true, "vremenskiprimjenjiv" : true,}

                                           aktivnapotjernica    neaktivnapotjernica 
                                ?       postoji ugovor              ne postoji   
                                ?    vremenska primjena da           ne
                važeći ugovor u vrijeme    nevažaći ugovoru vrijeme izvršenja
                    ?  da/ne/nejasno/pojedinakd 
        gonjenje (oblik/visina) izvrsenje (visina/oblik) 

*/}
              </Col>

</Row>
   
    <Row>
              <Col lg={8}>
                <ToggleButtonGroup
                  type="radio"
                  name="ODBopcije-aktivnapotjernica"
                  defaultValue={ODBopcije.aktivnapotjernica}
                >
                  <ToggleButton
                    id="ODBaktivnapotjernica"
                    variant="outline-secondary"
                    value={true}
                    onClick={(e) =>
                      inputChange("ODBopcije", true, "aktivnapotjernica")
                    }
                  >
                    Aktivna potjernica
                  </ToggleButton>
                  <ToggleButton
                    id="ODBneaktivnapotjernica"
                    variant="outline-secondary"
                    value={false}
                    onClick={(e) =>
                      inputChange("ODBopcije", false, "aktivnapotjernica")
                    }
                  >
                    Obustavljena potjernica
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
              <Col lg={4} ></Col>
              </Row>


{!ODBopcije.aktivnapotjernica && <>
  <Row>
  <Col lg={2}>
  <br />
  </Col>                
  <Col lg={2} style={{ borderRight: "3px dashed #d1cece" }}></Col>
                        <Col lg={7}></Col>
                        <Col lg={2}>
                          <br />
                        </Col>
  <Col lg={2} style={{ borderTop: "3px dashed #d1cece", borderLeft: "3px dashed #d1cece" }}></Col>
                        <Col lg={7}></Col>

  <InputGroup className="mb-3">
        <InputGroup.Text id="NCBobavijestNeaktivnaPotjernica">NCB Interpol Sarajevo</InputGroup.Text>
        <Form.Control
          placeholder="Akt NCB Interpola kojim je dostavljena obavijest o obustavi potjernice"
          aria-label="NCBobavijestNeaktivnaPotjernica"
          aria-describedby="NCBobavijestNeaktivnaPotjernica"
          onChange={(e) =>  inputChange("ODBopcije", e.target.value , "NCBobavijestNeaktivnaPotjernica")}
        />
      </InputGroup>
      </Row>
</>}


{ODBopcije.aktivnapotjernica &&
 <>

          {DrzavljaninBiH ==="DA" &&
          <>
                {PostojiUgovor === "DA" &&
                <>
                    {ugovor.pojedinosti.vremenskaprimjena.postoji && 
                    <>

                    {/*
                     <Col lg={4}></Col>
                        <Col>
                          <br />
                          <br />
                        </Col>
                        <Col style={{ borderRight: "3px dashed #d1cece" }}></Col>
                        <Col></Col>
                        <Col lg={9}></Col>

                            <Col lg={12}>
    
                            <OverlayTrigger placement="left"
                              overlay={
                                <Popover id="popover-ODBvremenskovazenje">
                                  <Popover.Header as="h3">
                                    {(ugovor.izmjene === "IZMJENE" || ugovor.izmjene === "PROTOKOL") ? <>{ugovor.naziv} (izmijenjen)</> : <>{ugovor.naziv}</> }
                                  </Popover.Header>
                                  <Popover.Body>
                                    Vremenska primjena ugovora:{" "}
                                    <strong>
                                      {ugovor?.pojedinosti.vremenskaprimjena.od} (
                                      {ugovor?.pojedinosti.vremenskaprimjena.odrednica ===
                                      "stupanje"
                                        ? `stupio na snagu - ${
                                          (ugovor.izmjene === "IZMJENE" || ugovor.izmjene === "PROTOKOL")
                                              ? `${ugovor.stupanje2}`
                                              : `${ugovor.stupanje}`
                                          }`
                                        : `potpisan - ${
                                          (ugovor.izmjene === "IZMJENE" || ugovor.izmjene === "PROTOKOL")
                                              ? `${ugovor.datum2}`
                                              : `${ugovor.datum}`
                                          }`}
                                      )
                                    </strong>
                                    .
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <ToggleButtonGroup
                                type="radio"
                                name="ODBopcije-vremenskiprimjenjiv"
                                defaultValue={ODBopcije.vremenskiprimjenjiv}
                              >
                                <ToggleButton
                                  id="ODBvremenskiprimjenjivDa"
                                  variant="outline-secondary"
                                  value={true}
                                  onClick={(e) =>
                                    inputChange("ODBopcije", true, "vremenskiprimjenjiv")
                                  }
                                >
                                  Važeći ugovor (u vrijeme izvršenja)
                                </ToggleButton>
                                <ToggleButton
                                  id="ODBvremenskiprimjenjivNe"
                                  variant="outline-secondary"
                                  value={false}
                                  onClick={(e) =>
                                    inputChange("ODBopcije", false, "vremenskiprimjenjiv")
                                  }
                                >
                                  Nevažeći ugovor (u vrijeme izvršenja)
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </OverlayTrigger> 
                        </Col>

                    
                    */}
                     

                            {ugovor.pojedinosti.izrucenjevlastitih.status === "POJEDINAKD" &&
                            <>
                              <Row>
                                 
                              <Col lg={1} style={{ borderRight: "3px dashed #d1cece" }}></Col>
                                <Col lg={11} >
                                <br />
                                <br />
                                </Col>
                                
                              </Row>

                              {procesuiranje &&
                              <>

                                {procesuiranjeInfo.map((p, index, arr) => (
                                    <>
                                 
                                    <Row key={`procesuiranjeODB${index+1}`}>
                                      <Col id={`procesuiranjeODB${index+1}`} className="col-lg-7 pe-0">
                                          <div className="ps-3 pt-2 pb-2 pe-3 text-bg-light bs-tertiary-bg-rgb border bs-tertiary-bg rounded-3" style={{minHeight: "100px"}}>
                                          <Row>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>KRIVIČNO GONJENJE</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>KRIVIČNO DJELO</Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm mb-2 "></Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm mb-2 text-truncate">{p.procesuiranjekd}</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>ZAPRIJEĆENA KAZNA</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>VRIJEME IZVRŠENJA</Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm ">{p.procesuiranjekazna.val} </Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm ">{p.procesuiranjevrijemeiizvrsenja}</Col>
                                          </Row>
                                            </div>   
                                      </Col>
                                      <Col className="col-lg-1" >
                                        <Row>
                                              <Col lg={12} style={{ borderBottom: "3px dashed #d1cece" }}> <br/><br/> </Col>
                                              <Col lg={12} ><br/><br/> </Col>
                                        </Row>
                                      </Col>

                                      <Col lg={4}>
                                        <Row>
                                        <Col lg={12}><br/></Col>
                                        <Col lg={12} className="ps-0">
                                        <ButtonGroup className="mb-2 ps-0">
                                                  <ToggleButton
                                                    id={`PODB${index+1}-vrijemevazenja`}
                                                    type="checkbox"
                                                    variant="outline-danger lh-1"
                                                    checked={p.procesuiranjeODB.vrijemevazenja}
                                                    onChange={(e) => inputChange('vrijemevazenja', e.currentTarget.checked, index === 0 ? "procesuiranjeInfo" : "procesuiranjeInfo" + index)}                                                    //onChange={(e) => setChecked(e.currentTarget.checked)}
                                                  >
                                                   Vrijeme primjene ugovora
                                                  </ToggleButton>
                                                  <ToggleButton
                                                    id={`PODB${index+1}-visinakazne`}
                                                    type="checkbox"
                                                    variant="outline-danger lh-1"
                                                    checked={p.procesuiranjeODB.visinakazne}
                                                    onChange={(e) => inputChange('visinakazne', e.currentTarget.checked, index === 0 ? "procesuiranjeInfo" : "procesuiranjeInfo" + index)}                                                    //onChange={(e) => setChecked(e.currentTarget.checked)}
                                                  >
                                                   Visina kazne
                                                  </ToggleButton>
                                          </ButtonGroup>
                                        </Col>
                                        <Col><br/></Col>
                                      
                                        </Row>
                                      </Col>


                                      </Row>
                                    
      
                                      {(arr.length > 1 && index !== arr.length - 1)
                                       ?
                                         <Row key={`spacerODB${index+1}`}>
                                          <Col lg={1} style={{ borderRight: "3px dashed #d1cece" }}> <br/> </Col>
                                          <Col lg={11} ></Col>
                                          </Row>
                                       : 
                                       ""}

                                              </>
                                            ))}          
                                      {izvrsenje && <br/>}
                              </> /*procesuiranje */}
                              
                              {izvrsenje &&
                              <>
                              
     
                              {izvrsenjeInfo.map((i, index, arr) => (
                                    <>
                                 
                                    <Row key={`izvrsenjeODB${index+1}`}>
                                      <Col id={`izvrsenjeODB${index+1}`} className="col-lg-7 pe-0">
                                          <div className="ps-3 pt-2 pb-2 pe-3 text-bg-light bs-tertiary-bg-rgb border bs-tertiary-bg rounded-3" style={{minHeight: "100px"}}>
                                          <Row>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>PRESUDA</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>KRIVIČNO DJELO</Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm mb-2 ">{i.izvrsenjenazivsuda.nom} - {i.izvrsenjepresuda}</Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm mb-2 text-truncate">{i.izvrsenjekd}</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>IZREČENA KAZNA</Col>
                                          <Col lg={6} className="text-body-secondary" style={{fontSize: "11px"}}>VRIJEME IZVRŠENJA</Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm ">{i.izvrsenjekazna.preostalidio ? <>{i.izvrsenjekazna.izrecenakaznanom} / preostali dio: {i.izvrsenjekazna.preostalidionom }</>  : <>{i.izvrsenjekazna.izrecenakazna}</>} </Col>
                                          <Col lg={6} style={{fontSize: "13px"}} className="fw-medium lh-sm ">{i.izvrsenjevrijemeizvrsenja}</Col>
                                          </Row>
                                            </div>   
                                      </Col>
                                      <Col className="col-lg-1" >
                                        <Row>
                                              <Col lg={12} style={{ borderBottom: "3px dashed #d1cece" }}> <br/><br/> </Col>
                                              <Col lg={12} ><br/><br/> </Col>
                                        </Row>
                                      </Col>


                                      <Col lg={4}>
                                        <Row>
                                        <Col lg={12}><br/></Col>
                                        <Col lg={12} className="ps-0">
                                        <ButtonGroup className="mb-2 ps-0">
                                                  <ToggleButton
                                                    id={`IODB${index+1}-vrijemevazenja`}
                                                    type="checkbox"
                                                    variant="outline-danger lh-1"
                                                    checked={i.izvrsenjeODB.vrijemevazenja}
                                                    onChange={(e) => inputChange('vrijemevazenja', e.currentTarget.checked, index === 0 ? "izvrsenjeInfo" : "izvrsenjeInfo" + index)}                                                    //onChange={(e) => setChecked(e.currentTarget.checked)}
                                                  >
                                                   Vrijeme primjene ugovora
                                                  </ToggleButton>
                                                  <ToggleButton
                                                    id={`IODB${index+1}-visinakazne`}
                                                    type="checkbox"
                                                    variant="outline-danger lh-1"
                                                    checked={i.izvrsenjeODB.visinakazne}
                                                    onChange={(e) => inputChange('visinakazne', e.currentTarget.checked, index === 0 ? "izvrsenjeInfo" : "izvrsenjeInfo" + index)}                                                    //onChange={(e) => setChecked(e.currentTarget.checked)}
                                                  >
                                                   Visina kazne
                                                  </ToggleButton>
                                          </ButtonGroup>
                                        </Col>
                                        <Col><br/></Col>
                                      
                                        </Row>
                                      </Col>


                                      </Row>
                                    
      
                                      {(arr.length > 1 && index !== arr.length - 1)
                                       ?
                                         <Row key={`spacerODB${index+1}`}>
                                          <Col lg={1} style={{ borderRight: "3px dashed #d1cece" }}> <br/> </Col>
                                          <Col lg={11} ></Col>
                                          </Row>
                                       : 
                                       ""}


                                              </>
                                            ))}          
                                              
                                            
                              </> /*izvrsenje */}
                              
                              {(procesuiranje && izvrsenje) &&
                              <>

                              </> /*procesuiranje && izvrsenje */}

                              {(!procesuiranje && !izvrsenje) &&
                              <>

                              </> /*procesuiranje && izvrsenje */}

                            </> /* ugovor ... zrucenjevlastitih === "POJEDINAKD" */}

                

                    </>/*...vremenskaprimjena.postoji*/}

                    {!ugovor.pojedinosti.vremenskaprimjena.postoji && 
                    <>
                    NE POSTOJI VREMENSKA - nije dozvoljeno ni izručenje DRZBIH jer nema ugovora koji dozvoljavaju izručenje DRZBIH a da nemaju vremensku odrednicu
                    </>/*...vremenskaprimjena.postoji*/}
                
                </> /*PostojiUgovor === "DA" */}


                {PostojiUgovor === "NE" &&
                <>
                ALERT - NE POSTOJI UGOVOR - biće primjenjena odredba člana 40 ZMPP
                </> /*PostojiUgovor === "NE" */}

          </>/*DrzavljaninBiH === "DA" */}

          {DrzavljaninBiH ==="NE" &&
          <>
                      {PostojiUgovor === "DA" &&
                    <>
                      {procesuiranje &&
                        <>

                        </> /*procesuiranje */}

                      {izvrsenje &&
                        <>

                        </> /*izvrsenje */}

                      {(procesuiranje && izvrsenje) &&
                        <>

                        </> /*procesuiranje && izvrsenje */}

                    </> /*PostojiUgovor === "DA" */}

                {PostojiUgovor === "NE" &&
                <>
                              {procesuiranje &&
                              <>

                              </> /*procesuiranje */}
                              
                              {izvrsenje &&
                              <>

                              </> /*izvrsenje */}
                              
                              {(procesuiranje && izvrsenje) &&
                              <>

                              </> /*procesuiranje && izvrsenje */}
                </> /*PostojiUgovor === "NE" */}

          </>/*DrzavljaninBiH === "NE" */}



</>/*ODBopcije.aktivnapotjernica */}



             



          
     
         


            <br />
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ODBdodatne;



