import React from "react";
import parse from "html-react-parser";
import { useGlobalContext } from "../context";

import Snipet from "./dodatneopcije/Snipet";
import LPodaci from "./dodatneopcije/LPodaci";
import { Dokumenti } from './dodatneopcije/LPodaci'
import Skracenica from "./dodatneopcije/Skracenica";
import Sastavsudaipouka from "./Sastavsudaipouka";



const Dopis = () => {
  const {
    datumodluke,
    vrstaOdluke,
    brojPredmeta,
    IMELICA,
    licnipodaci,
    vrstabranioca,
    dvabranioca,
    BRnacinizbora,
    DrzavljaninBiH,
    ODBfaza,
    MZfaza,
    PRfaza,
    PREKSfaza,
    PROfaza,
    EKSfaza,

    Potraživani, //repls
    potraživani,//repl
    potraživanog,//repl
    potraživanom, //repl
    državljanin,//repl
    Potraživanom, //repl
    POTRAŽIVANI, //repl
    njegov, njegovi, njegovo, priveden, njegovom,
    Saglasan, isti, odabrao, mu_je, saglasan, sam,
    njegovim, istom, njegovog, upućen, lišen, bio,
    samog, posjedovao, njegove, lišenog, upisan, saopštio, da_ga,

    BRANILAC,
    licezensko,
    DRZMOLITELJICA,
    DRZAVLJANSTVO,
    DRZAVLJANSTVO2,
    datumlisenja,
    datumodlukePR,
    PRkrajlisenja,
    datumodlukePRO,
    PROkrajlisenja,
    EKSkrajlisenja,
    EKSbrojmolbe,
    EKSbrojprijedloga,
    ncb1,
    ncb2,
    SUDIJA,
    TUZILAC,
    pJ,
    pL,
    pC,
    pR,
    vrstaDopisa,
    MZboraviste,
    MZjavljanje,
    MZputovanje,
    MZstanica,
    zavod,
    stranac,
    dodajtumaca,
    TUMAC,
    jezikprevoda,
    dvadrzavljanstva,
    ODBobustavi,
    posjedujedrzavljanstvo,
    licnidokumenti

    
  } = useGlobalContext();

  let postojecidokumenti = licnidokumenti.filter(dokument => (dokument.pasos === true || dokument.licna === true) && dokument.uvid === true)


  let pLDNA = {
    textAlign: "left",
  };
  let pRDNA = {
    textAlign: "right",
  };
  let pJDNA = {
    textAlign: "justify",
  };
  let pCDNA = {
    textAlign: "center",
  };

  const organMZ = () => {
    if (MZputovanje && !MZboraviste && !MZjavljanje) {
      return "- Graničnoj policiji Bosne i Hercegovine - faxom i redovni putem <br/>";
    } else if (MZputovanje && MZboraviste && !MZjavljanje) {
      return (
        "- Graničnoj policiji Bosne i Hercegovine - faxom i redovni putem <br/> - Policijskoj stanici " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        " - faxom i redovni putem <br/>"
      );
    } else if (MZputovanje && MZboraviste && MZjavljanje) {
      return (
        "- Graničnoj policiji Bosne i Hercegovine - faxom i redovni putem <br/> - Policijskoj stanici " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        " - faxom i redovni putem <br/>"
      );
    } else if (!MZputovanje && MZboraviste && !MZjavljanje) {
      return (
        "- Policijskoj stanici - " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        "- faxom i redovni putem <br/>"
      );
    } else if (!MZputovanje && MZboraviste && MZjavljanje) {
      return (
        "- Graničnoj policiji Bosne i Hercegovine - faxom i redovni putem <br/> - Policijskoj stanici " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        " - faxom i redovni putem <br/>"
      );
    } else if (!MZputovanje && !MZboraviste && MZjavljanje) {
      return (
        "- Policijskoj stanici - " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        " - faxom i redovni putem <br/>"
      );
    } else if (MZputovanje && !MZboraviste && MZjavljanje) {
      return (
        "- Graničnoj policiji Bosne i Hercegovine - faxom i redovni putem <br/> - Policijskoj stanici " +
        (MZstanica.nazivstanice || "__________") + " (" + (MZstanica.nazivuprave || "PU __________") + ")" +
        " - faxom i redovni putem <br/>"
      );
    } else {
      return "- ____________________ <br/>";
    }
  };

  const prikaziPopunjenInput = (obj1, obj2) => {
    if (Object.keys(obj1).length === 0) {
      return (<>
        <strong>AMBASADA</strong><br />
        <strong style={{ textTransform: "uppercase" }}>{obj2.gen}</strong><br />
        <strong>____________________</strong>
        <br />
      </>
      )
    }
    else if (Object.keys(obj2).length === 0) {
      return (<>
        <strong>AMBASADA</strong><br />
        <strong style={{ textTransform: "uppercase" }}>{obj1.gen}</strong><br />
        <strong>____________________</strong>
        <br />
      </>
      )
    }
    else {
      return 'both objects are not empty';
    }
  }

  const NazivAmbasade = () => {

    return (
      <>
        {dvadrzavljanstva === "DA" ?
          <>
            <strong>AMBASADA</strong><br />
            <strong style={{ textTransform: "uppercase" }}>{DRZAVLJANSTVO.gen}</strong><br />
            <strong>____________________</strong><br />
            <br />
            <strong>AMBASADA</strong><br />
            <strong style={{ textTransform: "uppercase" }}>{DRZAVLJANSTVO2.gen}</strong><br />
            <strong>____________________</strong>
          </>
          :
          dvadrzavljanstva === "NE" ?
            <>
              {prikaziPopunjenInput(DRZAVLJANSTVO, DRZAVLJANSTVO2)}
            </>
            :
            <></>}


      </>
    )
  }

 
  return (
    <>
      {vrstaDopisa === "DNA" && (
        <div style={{ fontFamily: "Times New Roman, Times, serif" }}>
          {vrstaOdluke === "ODB" && (
            <>
              <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                {ODBobustavi === "NE" && <>1. Rješenje o odbijanju zahtjeva za privremeno pritvaranje {potraživanog} dostaviti:</>}
                {ODBobustavi === "DA" && <>1. Rješenje o odbijanju zahtjeva za privremeno pritvaranje i obustavi postupka u odnosu na {potraživanog} dostaviti:</>}
                <br />
                - Tužilaštvu BiH (na znanje) <br />
                - {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}
                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
              </p>

              <p style={pLDNA}>
                2. Naredbu za puštanje {potraživanog} dostaviti:
                <br />- Sudskoj policiji Suda BiH
              </p>

              <p style={pLDNA}>
                3. Obavijest o odluci Suda BiH po zahtjevu za pritvaranje
                {potraživanog} dostaviti:
                <br /> - Ministarstvu pravde BiH (sa rješenjem) <br />- NCB Interpol-u sarajevo (sa rješenjem)
              </p>

              {vrstabranioca === "posluzbenoj" &&
                <p style={pLDNA}>
                  4. Rješenje o postavljanju branioca {potraživanom} dostaviti:{" "}
                  <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                  <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                </p>
              }
            </>
          )}
          {vrstaOdluke === "MZ" && (
            <>
             <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                1. Rješenje o odbijanju zahtjeva za privremeno pritvaranje i
                izricanju mjera zabrane {potraživanom} dostaviti:
                <br />
                <br />
                {parse(organMZ())}
                - Tužilaštvu BiH
                <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
              </p>

              <p style={pLDNA}>
                2. Naredbu za puštanje {potraživanog} dostaviti:
                <br />- Sudskoj policiji Suda BiH
              </p>

              <p style={pLDNA}>
                3. Obavijest o odbijanju zahtjeva za privremeno pritvaranje i
                izricanju mjera zabrane {potraživanom} dostaviti:
                <br /> - Ministarstvu pravde BiH (sa rješenjem) <br />- NCB
                Interpol-u sarajevo (sa rješenjem)
              </p>

              {vrstabranioca === "posluzbenoj" &&
                <p style={pLDNA}>
                  4. Rješenje o postavljanju branioca {potraživanom} dostaviti:{" "}
                  <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                  <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem{" "}
                  {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                </p>
              }
            </>
          )}
          {vrstaOdluke === "PR" && (
            <>
              <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                1. Rješenje o određivanju privremenog pritvora {potraživanom}
                dostaviti:
                <br />
                - Tužilaštvu BiH
                <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}
                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i putem sudske policije
                <br />
              </p>

              <p style={pLDNA}>
                2. Naredbu za sprovođenje {potraživanog} dostaviti:
                <br />- Sudskoj policiji Suda BiH
              </p>

              <p style={pLDNA}>
                3. Nalog za prihvatanje {potraživanog} dostaviti:
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i putem sudske policije
              </p>

              <p style={pLDNA}>
                4. Obavijest o pritvaranju {potraživanog} dostaviti:
                <br />
                - Ministarstvu pravde BiH (sa rješenjem)
                <br />
                - NCB Interpol-u sarajevo (sa rješenjem)
                {stranac === "DA" ? <><br />- Ambasadi {licnipodaci.državljanstvo || "__________"}</> : <></>}
              </p>

              {vrstabranioca === "posluzbenoj" ?
                <>
                  <p style={pLDNA}>
                    5. Rješenje o postavljanju branioca {potraživanom} dostaviti:
                    <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                    <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem{" "}
                    {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                  </p>
                  <p style={pLDNA}>
                    6. Stalnu saglasnost za advokata dostaviti:
                    <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
                    <br />- {Potraživanom}
                    <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} (
                    {BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem{" "}
                  </p>
                </>
                :
                <p style={pLDNA}>
                  5. Stalnu saglasnost za advokata dostaviti:
                  <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
                  <br />- {Potraživanom}
                  <br />
                  {dvabranioca ?
                    <>
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                      <br />
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                    </> :
                    <>
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    </>}
                </p>
              }
            </>
          )}
          {vrstaOdluke === "PREKS" && (
            <>
             <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                1. Rješenje o određivanju ekstradicionog pritvora {potraživanom}
                dostaviti:
                <br />
                - Tužilaštvu BiH
                <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}
                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i putem sudske policije
                <br />
              </p>

              <p style={pLDNA}>
                2. Naredbu za sprovođenje {potraživanog} dostaviti:
                <br />- Sudskoj policiji Suda BiH
              </p>

              <p style={pLDNA}>
                3. Nalog za prihvatanje {potraživanog} dostaviti:
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i putem sudske policije
              </p>

              <p style={pLDNA}>
                4. Obavijest o određivanju ekstraidicionog pritvora i davanju
                saglasnosti za pojednostavljeno izručenje {potraživanog}
                dostaviti:
                <br />
                - Ministarstvu pravde BiH (sa sudskim zapisnikom i rješenjem)
                <br />
                - NCB Interpol-u sarajevo (sa rješenjem)
                {stranac === "DA" ? <><br />- Ambasadi {licnipodaci.državljanstvo || "__________"}</> : <></>}
              </p>

              {vrstabranioca === "posluzbenoj"
                ?
                <>
                  <p style={pLDNA}>
                    5. Rješenje o postavljanju branioca {potraživanom} dostaviti:{" "}
                    <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                    <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem{" "}
                    {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                  </p>

                  <p style={pLDNA}>
                    6. Stalnu saglasnost za advokata dostaviti:
                    <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
                    <br />- {Potraživanom}
                    <br />- Advokatu {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"} (
                    {BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem{" "}
                  </p>
                </>
                :
                <p style={pLDNA}>
                  5. Stalnu saglasnost za advokata dostaviti:
                  <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
                  <br />- {Potraživanom}
                  <br />
                  {dvabranioca ?
                    <>
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                      <br />
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                    </> :
                    <>
                      - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    </>}
                </p>
              }
            </>
          )}
          {vrstaOdluke === "PRO" && (
            <>
              <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                1. Rješenje o produženju privremenog pritvora {potraživanom}
                dostaviti:
                <br />
                - Tužilaštvu BiH
                <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}
                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
                <br />
              </p>

              <p style={pLDNA}>
                2. Obavijest o produženju privremenog pritvora {potraživanog}
                dostaviti:
                <br />
                - Ministarstvu pravde BiH (sa rješenjem)
                <br />
                - NCB Interpol-u sarajevo (sa rješenjem)
                {stranac === "DA" ? <><br />- Ambasadi {licnipodaci.državljanstvo || "__________"}</> : <></>}
              </p>
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
             <p style={pLDNA}>BOSNA I HERCEGOVINA <br/>
              SUD BOSNE I HERCEGOVINE</p>

              <Snipet kod="BrojDatumDNA"/>

              <p style={pCDNA}>DNEVNA NAREDBA</p>
              <p style={pLDNA}>
                1. Rješenje o ukidanju privremenog pritvora i određivanju
                ekstradicionog pritvora {potraživanom} dostaviti:
                <br />
                - Tužilaštvu BiH
                <br />- {Potraživanom} {dodajtumaca ? `(nakon prevoda na ${jezikprevoda || "__________"} jezik)` : ""} - lično
                <br />
                {dvabranioca ?
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                    <br />
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[1].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}) - redovnim putem
                  </> :
                  <>
                    - Advokatu {BRANILAC.length > 0 ? BRANILAC[0].imedat || "_________" : "_________"} ({BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}) - redovnim putem
                  </>}
                {dodajtumaca && <><br />- Sudskom tumaču ({TUMAC.ime || "__________"}) za {jezikprevoda || "__________"} jezik (radi prevoda) - putem e-maila</>}
                <br />- NCB Interpolu Sarajevo: putem fax-a i redovnim putem
                <br />- <Skracenica kod={zavod} skraku="skraku" /> putem fax-a i redovnim putem
              </p>

              <p style={pLDNA}>
                2. Naredbu za sprovođenje {potraživanog} dostaviti:
                <br />- Sudskoj policiji Suda BiH
              </p>

              <p style={pLDNA}>
                3. Obavijest o određivanju ekstradicionog pritvora {potraživanom} dostaviti:
                <br />
                - Ministarstvu pravde BiH (sa rješenjem)
                <br />
                - NCB Interpol-u sarajevo (sa rješenjem)
                {stranac === "DA" ? <><br />- Ambasadi {licnipodaci.državljanstvo || "__________"}</> : <></>}
              </p>
            </>
          )}
          <Sastavsudaipouka kod="evidencija" />
        </div>
      )}

      {vrstaDopisa === "ZAPISNIK" && (
        <> 
          {(vrstaOdluke === "ODB" || vrstaOdluke === "MZ" || vrstaOdluke === "PR" || vrstaOdluke === "PREKS" ) && (
            <>
               <Snipet kod="BrojDatum"/>
                <p style={pC}><strong>Z A P I S N I K</strong></p> 
                 
                <p style={pJ}>
                <strong>
                sačinjen u smislu člana 37. i 38. <Skracenica kod="ZMPP" gen="gen"/> <Skracenica kod="ZMPP" sl="sl" /> u krivičnom predmetu protiv {potraživanog} {IMELICA.imegen || "__________"}, a povodom {njegovog} lišenja slobode po međunarodnoj potjernici raspisanoj od strane nadležnog organa {DRZMOLITELJICA.gen || "__________"}:
                </strong>
                </p>

                <p style={pC}><strong>P r i s u t n i:</strong></p> 

                <Sastavsudaipouka kod="sastav" sastav="svi" />

                <p style={pC}><strong>Započeto u _________ sati, sudnica broj:____</strong></p>
                <p style={pJ}><strong>Sudija:</strong> Otvaram ročište povodom lišenja slobode {potraživanog} {IMELICA.imegen || "__________"} po međunarodnoj potjernici NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"}, {DRZMOLITELJICA.ime|| "__________"} te izjašnjenja {potraživanog} u pogledu načina izručenja i zahtjeva za {njegovo} privremeno pritvaranje koji je sadržan u međunarodnoj potjernici.</p>
                <p style={pJ}>Konstatuje se da na ročištu prisustvuju: {potraživani} ({priveden} putem sudske policije),{" "}  
                {dodajtumaca ?
                 <>
                    {dvabranioca ? 
                      <>{njegovi} izabrani branioci{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"} te sudski tumač za {jezikprevoda || "__________"} jezik,  {TUMAC.ime || "__________"}.
                      </>
                    :
                      <>
                    {vrstabranioca === "izabrani" ? <>{njegov} izabrani branilac{" "}</> : <>{njegov} branilac po službenoj dužnosti</>} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "} {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i sudski tumač za {jezikprevoda || "__________"} jezik,  {TUMAC.ime || "__________"}.
                      </>
                    } 
                 </>
                 : 
                 <>
                   {dvabranioca ? 
                      <>{njegovi} izabrani branioci{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}.
                      </>
                    :
                      <>
                      {vrstabranioca === "izabrani" ? <>{njegov} izabrani branilac{" "}</> : <>{njegov} branilac po službenoj dužnosti</>} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "} {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}.
                      </>
                    } 
                 </>}
                </p>
                <p style={pJ}>Ja sam sudija {SUDIJA.ime || "__________"} i u ovom predmetu postupam kao sudija za prethodni postupak.</p>
                <p style={pL}>Imate li primjedbi na sastav Suda i nadležnost?</p>
                <p style={pJ}>
                {dvabranioca ? "Branioci" : "Branilac"} i {potraživani} jednoglasno izjavljuju da nemaju primjedbi na sastav Suda.
                </p>
                <p style={pJ}>
                Obavještavam sve prisutne da se cijeli tok ovog ročišta snima uređajem za audiovizuelno snimanje, te da je izvorni zapisnik sa ovog ročišta optički CD medij. Istovremeno, za potrebe ovog postupka zapisnik se vodi i u pisanoj formi koji zapisnik {potraživani} prije potpisivanja može, na kraju ročišta, pročitati i staviti evenutalne primjedne na isti. 
                </p>
                <p style={pJ}>
                Takođe se konstatuje da je po jedan primjerak dokumentacije u fotokopiji uručen {potraživanom} i{" "}
                {dvabranioca ?
                    <>
                      {njegovim} izabranim braniocima.
                    </>
                    : 
                    <>
                      {vrstabranioca === "izabrani"
                          ?
                            <>
                            {njegovom} izabranom braniocu.
                            </>
                            :
                            <>
                            {njegovom} braniocu po službenoj dužnosti.
                            </>
                      }
                    </>
                }
                </p>
                
                {vrstabranioca === "izabrani" ?
                <>
                    {dvabranioca ?
                    <>
                    Ročištu su pristupili izabrani branioci {potraživanog} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}. Da li je {potraživani} {saglasan} {da_ga} u ovom postupku zastupaju imenovani advokati?
                      <br/><br/>
                      <strong>{Potraživani}:</strong> {Saglasan} sam da me zastupaju imenovani branioci.
                      <br/> <br/>
                    </>
                    :
                    <>
                     Ročištu je pristupio izabrani branilac {potraživanog} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                      {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}. Da li je {potraživani} {saglasan} {da_ga} u ovom postupku zastupa imenovani advokat?
                      <br/><br/>
                      <strong>{Potraživani}:</strong> {Saglasan} sam da me zastupa imenovani branilac.
                      <br/> <br/>
                    </>}
                </>
                :
                <>
                    {BRnacinizbora !== ""
                    ?
                    <>
                     {BRnacinizbora === "lista"
                        ?
                        <p style={pJ}>Konstatuje se da je {potraživanom} prije ročišta predočena lista advokata ovlaštenih za zastupanje pred Sudom BiH, te je {isti} kao branioca {odabrao} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}, koji je izrazio saglasnost za zastupanje u ovom predmetu.</p>
                        :
                        <>
                        <p style={pJ}>{Potraživani} se poučava da {mu_je} Sud zbog hitnosti postavio branioca po službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}. {Potraživani} može iz opravdanih razloga tražiti promjenu branioca ili izabrati branioca kojeg će lično finansirati.</p>
                        <p style={pJ}><strong>{Potraživani}</strong>: Razumijem pouku Suda i {saglasan} sam da me u ovom postupku brani branilac koji mi je postavljen po službenoj dužnosti.</p>
                        </>
                      }
                    </>
                      : 
                      <>
                      <p style={pJ}>{Potraživani} se poučava da {mu_je} Sud zbog hitnosti postavio branioca po službenoj dužnosti {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"}, advokata iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}. {Potraživani} može iz opravdanih razloga tražiti promjenu branioca ili izabrati branioca kojeg će lično finansirati.</p>
                      <p style={pJ}><strong>{Potraživani}</strong>: Razumijem pouku Suda i {saglasan} sam da me u ovom postupku brani branilac koji mi je postavljen po službenoj dužnosti.</p>
                      </>
                    }

                </>}

                {dodajtumaca
                ?
                <>
                    <p style={pJ}>
                    <strong>Sudija:</strong>{Potraživani}, poučavate se u smislu člana 8. ZKP-a BiH, da su  pred Sudom BiH, u ravnopravnoj su upotrebi službeni jezici Bosne i Hercegovine - bosanski, hrvatski i srpski jezik, kao i oba pisma - ćirilica i latinica.
                    <br/>
                    <br/>
                    Međutim, imajući vidu da Vi imate pravo služiti se maternjim jezikom ili jezikom koji razumijete osigurano je usmeno prevođenje na {jezikprevoda || "__________"} jezik onoga što se iznosi pred ovim Sudom kao i pismeno prevođenje svih isprava i drugog pisanog materijala koji Vam budu upućeni.
                    Prevođenje će vršiti sudski tumač za {jezikprevoda || "__________"} jezik, {TUMAC.ime || "__________"}.
                    </p>
                    <p style={pL}><strong>Sudija</strong>: Da li razumijete {jezikprevoda || "__________"} jezik?</p> 
                    <p style={pL}><strong>{Potraživani}</strong>: Razumijem jezik na koji mi se.</p>
                </>
                : 
                <>
                    <p style={pJ}>
                    <strong>Sudija:</strong> {Potraživani}, poučavate se u smislu člana 8. ZKP-a BiH, da su  pred Sudom BiH, u ravnopravnoj su upotrebi službeni jezici Bosne i Hercegovine - bosanski, hrvatski i srpski jezik, kao i oba pisma - ćirilica i latinica. U postupku koji se vodi protiv Vas pred ovim Sudom Vi imate pravo služiti se maternjim jezikom ili jezikom koji razumijete.
                    </p>
                    <p style={pJ}>
                    Ako ne razumijete jedan od navedenih službenih jezika BiH, osiguraće se usmeno prevođenje ovog što se iznosi pred ovim Sudom kao i pismeno prevođenje svih isprava i drugog pisanog materijala.
                    </p>                  
                    <p style={pL}><strong>Sudija</strong>: Da li razumijete jezik na kojem Vam se obraćam?</p> 
                    <p style={pL}><strong>{Potraživani}</strong>: Razumijem jezik na kojem mi se obraćate i ne treba mi prevodilac.</p>
                </>}
                
                <p style={pL}><strong>Sudija</strong>: {Potraživani} uzećemo Vaše lične podatke, molim Vas da ustanete.</p>
      
                <LPodaci kod="LPprikaz" />

              {postojecidokumenti.length === 0
                ?
                <p style={pJ}>
                  Konstatuje se da je identitet {potraživanog}, na održanom ročištu, utvrđen na osnovu kazivanja {samog} {potraživanog} kojom prilikom je {isti} {saopštio} lične podatke koji su u saglasnosti sa podacima naznačenim u međunarodnoj potjernici. Sud u ovoj inicijalnoj fazi nije imao na raspolaganju neki od identifikacionih dokumenata {potraživanog} obzirom da {potraživani} iste nije {posjedovao} prilikom lišenja slobode.
                </p>
                :
                postojecidokumenti.length === 1
                  ?
                  <>
                    <p style={pJ}><strong>Sudija</strong>: Konstatuje se da {potraživani} posjeduje sljedeći identifikacioni dokument:</p>
                    <Dokumenti kod="brPrikaz" />
                    <br />
                  </>
                  :
                  <>
                    <p style={pJ}><strong>Sudija</strong>: Konstatuje se da {potraživani} posjeduje sljedeće identifikacione dokumente:</p>
                    <Dokumenti kod="brPrikaz" />
                    <br />
                  </>
              }
                  {postojecidokumenti.length === 0 
                  ?
                  <></>
                  :
                  postojecidokumenti.length === 1 
                  ?
                  <p style={pJ}><strong>Sudija</strong>: Konstatuje se da je izvršen uvid u pomenuti dokument {potraživanog}, da je kopija istog uložena u sudski spis te da će se original dokumenta vratiti Sudskoj policiji Suda BiH po okončanju ovog ročišta.</p>
                  :
                  <p style={pJ}><strong>Sudija</strong>: Konstatuje se da je izvršen uvid u pomenute dokumente {potraživanog}, da su kopije istih uložene u sudski spis te da će se originali dokumenata vratiti Sudskoj policiji Suda BiH po okončanju ovog ročišta.</p>
                  }

              <p style={pJ}><strong>Konstatuje se da {potraživani} posjeduje {posjedujedrzavljanstvo || "državljanstvo __________"}</strong>.</p>


                {DrzavljaninBiH === "DA" && licnipodaci.uvjerenje &&
                <p style={pJ}>
                Sud je izvršio uvid u Uvjerenje o državljanstvu, broj: {licnipodaci.uvjerenje.brojidatumuvjerenja || "__________ od ________. godine"}, izdato od strane matičnog ureda {licnipodaci.uvjerenje.maticniured || "__________"} 
                {" "}iz kojeg je vidljivo da je {potraživani} {upisan} u matičnju knjigu državljana Bosne i Hercegovine koja se vodi za naseljeno mjesto {licnipodaci.uvjerenje.podrucjeunosa || "__________"}, na strani {licnipodaci.uvjerenje.stranaunosa || "__________"}.
                </p>
                }

                <p style={pJ}>
                <strong>Sudija</strong>: {Potraživani}, Vi ste  lišeni slobode i privedeni u Sud BiH, jer je za Vama raspisana  međunarodna <Snipet kod="ncb1ncb2" />. Međunarodna pojternica je raspisana u cilju <Snipet kod="razlozi" />.
                </p>
                <p style={pJ}>
                <Snipet kod="nalogzahapsenje" />
                </p>  
                
                <p style={pJ}>
                Potraživano lice se, nakon lišenja slobode po međunarodnoj potjernici, dovodi pred Sud kako bi se potraživanom licu omogućilo da se izjasni u pogledu načina izručenja i zahtjeva države moliteljice za pritvaranje u ovom postupku, a koji zahtjev je sadržan u međunarodnoj potjernici.
                </p>
                <p style={pJ}>
                Kada je u pitanju način izručenja Vi, prema odredbama Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima, možete tražiti <strong>pojednostavljeno</strong> izručenje ili <strong>tražiti provođenje redovnog postupka</strong> izručenja. 
                </p>

                <p style={pJ}>
                <strong>Pojednostavljeno</strong> izručenje znači da dajete saglasnost za predaju državi moliteljici na pojednostavljeni način, dakle, bez provođenja redovnog ekstradicionog postupka.
                </p>
                <p style={pJ}>
                U tom slučaju bi se postupak ekstradicije skratio na najkraće vrijeme, odnosno Sud bi odmah nakon ovog ročišta dostavio Ministarstvu pravde BiH zapisnik u kojem je sadržana Vaša saglasnost za pojednostavljeno izručenje, a na osnovu kojeg bi ministar pravde BiH donio odluku kojom se dopušta Vaše izručenje i prelazi na fazu tehničke realizacije Vašeg izručenja.
                </p>
                <p style={pJ}>
                U slučaju da Vi danas date saglasnost za pojednostavljeno izručenje morate znati da je takva Vaša saglasnost neopoziva.
                </p>
                <p style={pJ}>
                Istovremeno, Sud je u obavezi da Vas upozna u slučaju pojednostavljenog izručenja potraživano lice se može odreći prava na <strong>principe specijaliteta</strong> koji znače: 
                </p>
                <p style={pJ}>
                -	da se ne možete krivično goniti goniti za drugo djelo počinjeno prije izručenja; <br />
                -	da se prema Vama ne može izvršiti kazna za drugo krivično djelo počinjeno prije izručenja <br />
                -	da se prema Vama ne može izvršiti teža kazna od one na koju ste osuđeni; <br />
                -	da će prema Vama biti ponovljen krivični postupak ukoliko ste osuđeni u odsustvu i <br />
              -	da se on, bez saglasnosti Bosne i Hercegovine, ne smijete izručiti trećoj državi,
              </p>
              <p style={pJ}>Odricanje od prava na principe specijaliteta, kao i davanje saglasnosti za pojednostavljenu predaju su neopozivi.</p>
              <p style={pJ}>Vi se možete protiviti pojednostavljenom izručenju i tražiti provođenje redovnog postupka izručenja koji podrazumijeva da je država moliteljica u obavezi, u zakonom propisanim rokovima, dostaviti molbu za izručenje sa ekstradicionom dokumentacijom (odredbe krivičnog zakona, informacije o krivičnom djelu, nalog za hapšenje ili presudu ukoliko ste osuđeni), a koju dokumentaciju bi u daljem toku postupka pred ovim Sudom razmatralo vijeće od trojice sudija te utvrđivalo da li su ispunjene pretpostavke za Vaše izručenje. Vijeće bi odluku donijelo u formi rješenja na koje bi vi imalo pravo žalbe.</p>
              <p style={pJ}>Konačnu odluku o dopuštenosti Vašeg izručenja bi opet donosio ministar pravde BiH.</p>

              {dvabranioca ?
                <>
                  <p style={pJ}>
                  <strong>Sudija</strong>: Pozivaju se branioci i {potraživani} da se izjasne o načinu izručenja koji {potraživani} želi da se provede i zahtjevu države moliteljice za privremeno pritvaranje. 
                  </p>
                  <p style={pJ}><strong>Branilac</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}:</p>
                  <p style={pJ}><strong>Drugi izabrani branilac</strong> {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}: </p>
                </>
                :
                <>
                  <p style={pJ}>
                    <strong>Sudija</strong>: Pozivaju se branilac i {potraživani} da se izjasne o načinu izručenja koji {potraživani} želi da se provede i zahtjevu države moliteljice za privremeno pritvaranje.
                  </p>
                  <p style={pJ}><strong>Branilac</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}:</p>
                </>
              }
              <p style={pJ}><strong>{Potraživani} {TUMAC.ime || "_________"}</strong>: izjavljuje </p>

              <p style={pJ}>
                <strong>Sudija</strong>: Saslušali smo {potraživanog} i {dvabranioca ? <>{njegove} branioce</> : <>{njegovog} branioca</>}, Sud će donijeti odluku koja će Vam biti dostavljena i Vi ćete sačekati dole u prostorijama Sudske policije za zadržavanje. Sudska policija će Vam dozvoliti da razgovarate sa Vašim {dvabranioca ? "braniocima." : <>braniocem{vrstabranioca === "izabrani" ? "." : ". Dobit ćete odluku o postavljenju branioca po službenoj dužnosti."}</>}.  
              </p>

              <p style={pJ}><strong>Sudija</strong>: Imaju li {dvabranioca ? <>branioci {potraživanog}</> : <>branilac {potraživanog}</>} ili {potraživani} nešto dodatno da izjave ili komentarišu?</p>
              <p style={pJ}>{dvabranioca ? <>Branioci {potraživanog}</> : <>Branilac {potraživanog}</>} i {potraživani} potvrđuju da nemaju ništa dodatno izijaviti.</p>

              <p style={pJ}><strong>Sudija</strong>: {Potraživani} se upozorava da ima pravo da pročitati zapisnik i staviti, eventualne, primjedbe na taj zapisnik sa današnjeg ročišta. Ima li potrebe da se ovaj zapisnik pročita?</p>
                <p style={pJ}>{dvabranioca ? <>Branioci {potraživanog}</> : <>Branilac {potraživanog}</>} i {potraživani} potvrđuju da nema potrebe da se čita zapisnik.</p>
              <p style={pJ}><strong>Sudija</strong>: Ročište u ovom predmetu je završeno.</p>
              <p style={pC}><strong>Završeno u  __________ sati</strong></p>
              
            </>
          )}

          {vrstaOdluke === "EKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}><strong>Z A P I S N I K</strong></p>

              <p style={pJ}>
                <strong>
                  sačinjen u smislu člana 36. stav 3., a u vezi sa članom 42. stav 1. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" /> u krivičnom predmetu protiv {potraživanog} {IMELICA.imegen || "__________"}, {državljanin} {licnipodaci.državljanstvo || "____________________________"}, radi izjašnjenja stranaka i {dvabranioca ? "branilaca" : "branioca"} {potraživanog} o molbi za izručenje nadležnog organa {DRZMOLITELJICA.gen || "__________"} i primjeni adekvatne mjere za osiguranje prisustva {potraživanog} u toku ovog postupka:
                </strong>
              </p>

              <p style={pC}><strong>P r i s u t n i:</strong></p>

              <Sastavsudaipouka kod="sastav" sastav="svi" />

              <p style={pC}><strong>Započeto u _________ sati, sudnica broj:____</strong></p>
              <p style={pJ}><strong>Sudija:</strong> Otvaram ročište povodom prijedloga <Skracenica kod="TBiH" gen="gen" />, {EKSbrojprijedloga || "__________"}, odnosno Molbe za izručenje nadležnog organa {DRZMOLITELJICA.gen || "__________"}, broj: {EKSbrojmolbe || "__________"}, za izručenje {potraživanog} {IMELICA.imegen || "__________"} koja je dostavljena ovom Sudu na nadležno rješavanje u smislu odredbe člana 36. stav 3. <Skracenica kod="ZMPP" naziv="naziv" />.</p>
            
              <p style={pJ}>Konstatuje se da na ročištu prisustvuju: {potraživani} ({priveden} putem sudske policije),{" "}
                {dodajtumaca ?
                  <>
                    {dvabranioca ?
                      <>{njegovi} izabrani branioci{" "}
                        {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                        {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                        {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"} te sudski tumač za {jezikprevoda || "__________"} jezik,  {TUMAC.ime || "__________"}.
                      </>
                      :
                      <>
                        {vrstabranioca === "izabrani" ? <>{njegov} izabrani branilac{" "}</> : <>{njegov} branilac po službenoj dužnosti</>} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "} {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i sudski tumač za {jezikprevoda || "__________"} jezik,  {TUMAC.ime || "__________"}.
                      </>
                    }
                  </>
                  :
                  <>
                    {dvabranioca ?
                      <>{njegovi} izabrani branioci{" "}
                        {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "}
                        {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"} i {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz{" "}
                        {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}.
                      </>
                      :
                      <>
                        {vrstabranioca === "izabrani" ? <>{njegov} izabrani branilac{" "}</> : <>{njegov} branilac po službenoj dužnosti</>} {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz{" "} {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}.
                      </>
                    }
                  </>}
              </p>

              {dodajtumaca
                ?
                <>
                  <p style={pJ}>
                    <strong>Sudija:</strong>{Potraživani}, poučavate se u smislu člana 8. ZKP-a BiH, da su  pred Sudom BiH, u ravnopravnoj su upotrebi službeni jezici Bosne i Hercegovine - bosanski, hrvatski i srpski jezik, kao i oba pisma - ćirilica i latinica.
                    <br />
                    <br/>
                    Međutim, imajući vidu da Vi imate pravo služiti se maternjim jezikom ili jezikom koji razumijete osigurano je usmeno prevođenje na {jezikprevoda || "__________"} jezik onoga što se iznosi pred ovim Sudom kao i pismeno prevođenje svih isprava i drugog pisanog materijala koji Vam budu upućeni.
                    Prevođenje će vršiti sudski tumač za {jezikprevoda || "__________"} jezik, {TUMAC.ime || "__________"}.
                  </p>
                  <p style={pL}><strong>Sudija</strong>: Da li razumijete {jezikprevoda || "__________"} jezik?</p>
                  <p style={pL}><strong>{Potraživani}</strong>: Razumijem.</p>
                </>
                :
                <>
                  <p style={pJ}>
                    <strong>Sudija:</strong> {Potraživani}, poučavate se u smislu člana 8. ZKP-a BiH, da su  pred Sudom BiH, u ravnopravnoj su upotrebi službeni jezici Bosne i Hercegovine - bosanski, hrvatski i srpski jezik, kao i oba pisma - ćirilica i latinica. U postupku koji se vodi protiv Vas pred ovim Sudom Vi imate pravo služiti se maternjim jezikom ili jezikom koji razumijete.
                  </p>
                  <p style={pJ}>
                    Ako ne razumijete jedan od navedenih službenih jezika BiH, osiguraće se usmeno prevođenje ovog što se iznosi pred ovim Sudom kao i pismeno prevođenje svih isprava i drugog pisanog materijala.
                  </p>
                  <p style={pL}><strong>Sudija</strong>: Da li razumijete jezik na kojem Vam se obraćam?</p>
                  <p style={pL}><strong>{Potraživani}</strong>: Razumijem jezik na kojem mi se obraćate i ne treba mi prevodilac.</p>
                </>}

              <p style={pJ}>Ja sam sudija {SUDIJA.ime || "__________"} i u ovom predmetu postupam kao sudija za prethodni postupak.</p>
              <p style={pL}>Imate li primjedbi na sastav Suda i nadležnost?</p>
              <p style={pJ}>Tužilac, {dvabranioca ? "branioci" : "branilac"} i {potraživani} izjavljuju da nemaju primjedbi na sastav i nadležnost Suda.
              </p>

              <p style={pJ}>
                Obavještavam sve prisutne da se cijeli tok ovog ročišta snima uređajem za audiovizuelno snimanje, te da je izvorni zapisnik sa ovog ročišta optički CD medij. Istovremeno, za potrebe ovog postupka zapisnik se vodi i u pisanoj formi koji zapisnik {potraživani} prije potpisivanja može, na kraju ročišta, pročitati i staviti evenutalne primjedne na isti.
              </p>

              <p style={pL}><strong>Sudija</strong>: Konstatuje se da je identitet potraživanog utvrđen na ročištu održanom dana {datumodlukePR || "_________"} povodom {njegovog} lišenja slobode po međunarodnoj potjernici.</p>

              {postojecidokumenti.length === 0
                ?
                <p style={pJ}>
                  Tom prilikom identitet {potraživanog} je utvrđen na osnovu kazivanja {samog} {potraživanog}. Sud ni u ovoj fazi posutpka nekim od identifikacionih dokumenata {potraživanog} obzirom da {potraživani} iste nije {posjedovao} prilikom lišenja slobode.
                </p>
                :
                postojecidokumenti.length === 1
                  ?
                  <>
                    <p style={pJ}><strong>Sudija</strong>: Tom prilikom identitet {potraživanog} je utvrđen na osnovu kazivanja {samog} {potraživanog} te uvidom u identifikacioni dokument:</p>
                    <Dokumenti kod="brPrikaz" />
                    <br />
                  </>
                  :
                  <>
                    <p style={pJ}><strong>Sudija</strong>: Tom prilikom identitet {potraživanog} je utvrđen na osnovu kazivanja {samog} {potraživanog} te uvidom u identifikacione dokumente:</p>
                    <Dokumenti kod="brPrikaz" />
                    <br />
                  </>
              }

              <p style={pJ}><strong>Konstatuje se da {potraživani} posjeduje {posjedujedrzavljanstvo || "državljanstvo __________"}</strong>.</p>
  

              {console.log("licnipodaci.uvjerenje.length")}
              {console.log(licnipodaci.uvjerenje)}

              {DrzavljaninBiH === "DA" && licnipodaci.uvjerenje &&
                <p style={pJ}>
                Sud je izvršio uvid u Uvjerenje o državljanstvu, broj: {licnipodaci.uvjerenje.brojidatumuvjerenja || "__________ od ________. godine"}, izdato od strane matičnog ureda {licnipodaci.uvjerenje.maticniured || "__________"} 
                {" "}iz kojeg je vidljivo da je {potraživani} {upisan} u matičnju knjigu državljana Bosne i Hercegovine koja se vodi za naseljeno mjesto {licnipodaci.uvjerenje.podrucjeunosa || "__________"}, na strani {licnipodaci.uvjerenje.stranaunosa || "__________"}.
                </p>
                }

              <p style={pL}><strong>Sudija</strong>: {Potraživani} da li je došlo do promjene u Vašim ličnim podacima od prethodnog ročišta?</p>
              <p style={pJ}><strong>{Potraživani} {TUMAC.ime || "_________"}</strong>: Nije bilo promjene ličnih podatka.</p>

              <p style={pJ}><strong>Sudija</strong>: Podsjetiti ću da je realizacijom međunarodne potjernice NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"}, {DRZMOLITELJICA.ime || "__________"}, broj: {ncb2 || "_________"},
                {" "} odnosno ekvivalentne potjernice Interpola NCB Sarajevo iniciran pred Sudom Bosne i Hercegovine postupak izručenja {potraživanog} {IMELICA.imegen || "_________"}, {lišenog} slobode {datumlisenja || "_________"}, a sve u cilju <Snipet kod="razlozi" />.
               </p>

               <p style={pJ}>Po donošenju odluke o zahtjevu za privremeno pritvaranje {potraživanog}, sadržanom u međunarodnoj potjernici,
               {" "} od države moliteljice je zatraženo dostavljanje molbe za izručenje sa ekstradicionom dokumentacijom. S tim u vezi,
               {" "} <Skracenica kod="TBiH" naziv="naziv" /> dostavilo je ovom Sudu, u smislu odredbe člana 36. stav 3. <Skracenica kod="ZMPP" gen="gen" />, na nadležno rješavanje Molbu nadležnog organa {DRZMOLITELJICA.gen || "__________"}, broj: {EKSbrojmolbe || "__________"}, za izručenje {potraživanog} {IMELICA.imegen || "__________"}. <Skracenica kod="TBiH" skr="skr" /> je Molbu za izručenje {potraživanog} dostavilo uz prijedlog za utvrđivanje pretpostavki/određivanje ekstradicionog pritvora {potraživanom}, broj: {EKSbrojprijedloga || "__________"}.</p>
              
              <p style={pJ}>Današnje ročište je zakazano kako bi se stranke i {dvabranioca ? "branioci": "branilac"} {potraživanog} izjasnili o molbi za izručenje i daljoj primjeni odgovarajuće mjere za osiguranje prisustva {potraživanog} u ovom postupku.</p>
              <p style={pJ}>Konstatuje da je kopija prijedloga Tužilaštva BiH i molbe za izručenje sa ekstradicionom dokumentacijom uručena {potraživanom} i{" "}
                {dvabranioca ?
                  <>
                    {njegovim} izabranim braniocima.
                  </>
                  :
                  <>
                    {vrstabranioca === "izabrani"
                      ?
                      <>
                        {njegovom} izabranom braniocu.
                      </>
                      :
                      <>
                        {njegovom} braniocu po službenoj dužnosti.
                      </>
                    }
                  </>
                }
              </p>

              <p style={pJ}><strong>Sudija</strong>: Pozivam tužioca <Skracenica kod="TBiH" gen="gen"/> da izjasni u pogledu <strong>formalne urednosti</strong> dostavljene molbe za izručenje, <strong>ispunjenosti pretpostavki</strong> za izručenje te <strong>načinu osiguranja prisustva {potraživanog}</strong> u daljem toku ovog postupka. Nakon toga, dobiti će priliku da se izjasne {dvabranioca ? "branioci" : "branilac"} {potraživanog} i {sam} {potraživani}.</p>
              
              <p style={pJ}><strong>Tužilac Tužilaštva BiH{TUZILAC.ime || "_________"}</strong>: navodi </p>

              {dvabranioca ?
                <>
                  <p style={pJ}>
                    <strong>Sudija</strong>: Pozivaju se branioci i {potraživani} da se izjasne o načinu izručenja koji {potraživani} želi da se provede i zahtjevu države moliteljice za privremeno pritvaranje.
                  </p>
                  <p style={pJ}><strong>Branilac</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}:</p>
                  <p style={pJ}><strong>Drugi izabrani branilac</strong> {BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}: </p>
                </>
                :
                <>
                  <p style={pJ}>
                    <strong>Sudija</strong>: Pozivaju se branilac i {potraživani} da se izjasne o načinu izručenja koji {potraživani} želi da se provede i zahtjevu države moliteljice za privremeno pritvaranje.
                  </p>
                  <p style={pJ}><strong>Branilac</strong> {BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}, advokat iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}:</p>
                </>
              }

              <p style={pJ}><strong>{Potraživani} {TUMAC.ime || "_________"}</strong>: izjavljuje </p>


              <p style={pJ}>
                <strong>Sudija</strong>: Saslušali smo tužioca, {potraživanog} i {dvabranioca ? <>{njegove} branioce</> : <>{njegovog} branioca</>}, Sud će prvo donijeti odluku koja se odnosi na eventualnu dalju primjenu mjere za osiguranje {potraživanog} koja će biti dostavljena {potraživanom}, {dvabranioca ? "njegovim braniocima" : "njegovom braniocu"}, i tužiocu. {Potraživani} će biti vraćen u <Skracenica kod={zavod} naziv="naziv" /> do donošenja odluke Suda. Sud će, nadalje, analizirati molbu za izručenje te, po potrebi, provesti i druge izviđajne radnje radi utvrđivanja postoje li pretpostavke za izručenje stranca nakon čega će sudija za prethodni postupak dostaviti svoje obrazloženo mišljenje Vanraspravnom vijeću Suda BiH koje će donijeti rješenje o pretpostavkama za izručenje koje, takođe, biti dostavljeno strankama i {dvabranioca ? "braniocima" : "braniocu"} {potraživanog} sa poukama o pravu na žalbu.
              </p>

              <p style={pJ}><strong>Sudija</strong>: Imaju li {dvabranioca ? <>branioci {potraživanog}</> : <>branilac {potraživanog}</>} ili {potraživani} nešto dodatno da izjave ili komentarišu?</p>
              <p style={pJ}>{dvabranioca ? <>Branioci {potraživanog}</> : <>Branilac {potraživanog}</>} i {potraživani} potvrđuju da nemaju ništa dodatno izijaviti.</p>

              <p style={pJ}><strong>Sudija</strong>: {Potraživani} se upozorava da ima pravo da pročitati zapisnik i staviti, eventualne, primjedbe na taj zapisnik sa današnjeg ročišta. Ima li potrebe da se ovaj zapisnik pročita?</p>
              <p style={pJ}>{dvabranioca ? <>Branioci {potraživanog}</> : <>Branilac {potraživanog}</>} i {potraživani} potvrđuju da nema potrebe da se čita zapisnik.</p>
              <p style={pJ}><strong>Sudija</strong>: Ročište u ovom predmetu je završeno.</p>
              <p style={pC}><strong>Završeno u  __________ sati</strong></p>

            </>
          )}

       
          <br />
          <Sastavsudaipouka kod="sastav" sastav="svipotpis" />
        </>

      )}

      {vrstaDopisa === "SUDSKA" && (
        <>
          {vrstaOdluke === "ODB" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici{" "}
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong>SUDSKOJ POLICIJI SUDA BOSNE I HERCEGOVINE</strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod="SUDSKA" aku="aku" /> da {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                <strong>odmah PUSTI NA SLOBODU.</strong>
              </p>
              <p style={pC}>
                <strong>II</strong>
              </p>
              <p style={pJ}>Ova naredba izdaje se na osnovu rješenja Suda BiH, broj:{" "}
                {brojPredmeta || "__________"} {ODBfaza} od{" "}
                {datumodluke} kojim je{" "}
                {ODBobustavi === "NE" && <><strong>odbijen</strong> zahtjev države moliteljice{" "}
                  {DRZMOLITELJICA.gen || "__________"} za privremeno pritvaranje
                  {potraživanog}.</>}
                {ODBobustavi === "DA" && <><strong>odbijen</strong> zahtjev države moliteljice{" "}
                  {DRZMOLITELJICA.gen || "__________"} za privremeno pritvaranje i, istovremeno, obustavljen postupak izručenja {potraživanog}.</>}
              </p>
             
            </>
          )}
          {vrstaOdluke === "MZ" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici{" "}
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong>SUDSKOJ POLICIJI SUDA BOSNE I HERCEGOVINE</strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod="SUDSKA" aku="aku" /> da {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pL}>
                <strong> odmah PUSTI NA SLOBODU.</strong>
              </p>
              <p style={pC}>
                <strong>II</strong>
              </p>
              <p style={pJ}>Ova naredba izdaje se na osnovu rješenja Suda
              BiH, broj: {brojPredmeta || "__________"} {MZfaza} od{" "}
              {datumodluke} kojim je u odnosu na{" "}
              {potraživanog}{" "}
              <strong>odbijen</strong> zahtjev države moliteljice{" "}
              {DRZMOLITELJICA.gen || "__________"} za{" "}
              {licezensko ? "njeno" : "njegovo"} privremeno pritvaranje te{" "}
              {licezensko ? "istoj" : "istom"} izrečene mjere zabrane.</p>
             
            </>
          )}
          {vrstaOdluke === "PR" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici{" "}
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong>SUDSKOJ POLICIJI SUDA BOSNE I HERCEGOVINE</strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod="SUDSKA" aku="aku" /> da {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                <strong>
                  SPROVEDE u <Skracenica kod={zavod} naziv="naziv" />
                </strong>{" "}
                radi izdržavanja mjere privremenog pritvora određenog rješenjem
                Suda BiH, broj: {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodluke}.
              </p>
              <br />
            </>
          )}

          {vrstaOdluke === "PREKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici{" "}
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong>SUDSKOJ POLICIJI SUDA BOSNE I HERCEGOVINE</strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod="SUDSKA" aku="aku" /> da {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                <strong>
                  SPROVEDE u <Skracenica kod={zavod} naziv="naziv" />
                </strong>{" "}
                radi izdržavanja mjere ekstradicionog pritvora određenog
                rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {PREKSfaza} od{" "}
                {datumodluke}.
              </p>
              <br />
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici{" "}
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong>SUDSKOJ POLICIJI SUDA BOSNE I HERCEGOVINE</strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod="SUDSKA" aku="aku" /> da {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                <strong>
                  SPROVEDE u <Skracenica kod={zavod} naziv="naziv" />
                </strong>{" "}
                radi izdržavanja mjere ekstradicionog pritvora određenog
                rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {EKSfaza} od{" "}
                {datumodluke}.
              </p>
              <br />
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}

      {vrstaDopisa === "ZIKS" && (
        <>
          {(vrstaOdluke === "PR" || vrstaOdluke === "PREKS") && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pJ}>
                Sud Bosne i Hercegovine, sudija za prethodni postupak
                {SUDIJA.ime || "_________"}, u postupku izručenja {potraživanog}
                {IMELICA.imegen || "_________"} po međunarodnoj potjernici
                <Snipet kod="ncb1ncb2" />, odlučujući na osnovu odredbe člana
                163. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> u vezi sa
                odredbom člana 60. i 121. stav 1.{" "}
                <Skracenica kod="ZIKSBiH" gen="gen" />, dana {datumodluke}, izdaje:
              </p>
              <p style={pC}>
                <strong>N A R E D B U </strong>
                <br />
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod={zavod} aku="aku" />
                </strong>
              </p>
              <p style={pC}>
                <strong>I</strong>
              </p>
              <p style={pL}>
                <strong>Nalaže se </strong>
                <Skracenica kod={zavod} aku="aku" /> da izvrši{" "}
                <strong>prijem {potraživanog}</strong>:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                <strong>
                  radi izvršenja mjere{" "}
                  {vrstaOdluke === "PR" ? "privremenog" : "ekstradicionog"}{" "}
                  pritvora
                </strong>{" "}
                u okviru pritvorskog odjeljenja zavoda i to pritvora određenog
                rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {vrstaOdluke === "PR" ? PRfaza : PREKSfaza} od{" "}
                {datumodluke}.
              </p>
              <br />
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}

      {vrstaDopisa === "TBIH" && (
        <>
          {vrstaOdluke === "ODB" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o{" "}
                {ODBobustavi === "NE" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje{" "}
                  {potraživanog}, dostavlja se;</>}
                {ODBobustavi === "DA" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje i <strong>obustavi postupka izručenja</strong> u odnosu na {potraživanog}, dostavlja se;</>}
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja
                {potraživanog}:
              </p>         
                <LPodaci kod="LPprikazSkraceno" />             
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                <Snipet kod="nalogzahapsenje" />
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za privremeno
                pritvaranje sadržanom u međunarodnoj potjernici donio rješenje,
                broj:{" "}
                {brojPredmeta || "__________"} {ODBfaza} od{" "}
                {datumodluke}, kojim se{" "}
                {ODBobustavi === "NE" && <>odbija zahtjev za
                  privremeno pritvaranje {potraživanog}.</>}
                {ODBobustavi === "DA" && <>odbija zahtjev za
                  privremeno pritvaranje i, istovremeno, obustavlja postupak izručenja u odnosu na {potraživanog}.</>}
              </p>
            </>
          )}
          {vrstaOdluke === "MZ" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o
                lišenju slobode i <strong>izricanju mjera zabrane</strong>{" "}
                {potraživanom}, <strong>molba za izručenje</strong>, traži se;
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />             
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                <Snipet kod="nalogzahapsenje" />
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za privremeno
                pritvaranje sadržanom u međunarodnoj potjernici donio rješenje,
                broj:
                {brojPredmeta || "__________"} {MZfaza} od{" "}
                {datumodluke}, kojim se odbija zahtjev za
                privremeno pritvaranje te {potraživanom}, istovremeno
                {!MZputovanje && !MZboraviste && !MZjavljanje ? (
                  <>
                    izriču mjere zabrane ... <br />
                    <br />{" "}
                    <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />{" "}
                    <br />
                  </>
                ) : (
                  <>
                    , <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />.
                  </>
                )}
              </p>
              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda u smislu odredbe člana 38. stav 4.{" "}
                <Skracenica kod="ZMPP" gen="gen" /> radi traženja molbe za
                izručenje (u skladu sa odredbama
                zakona/bilateralnog/multilateralnog ugovora) od strane države
                koja je raspisala potjernicu kako bi se postupak izručenja mogao
                okončati u razumnom roku.
              </p>
            </>
          )}
          {vrstaOdluke === "PR" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o privremenom pritvaranju
                {potraživanog} <strong>{IMELICA.imegen || "__________"}</strong>,
                upućivanje zahtjeva državi moliteljici za{" "}
                <strong>
                  {" "}
                  hitno dostavljanje kompletirane molbe za izručenje
                </strong>
                , traži se;
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />             
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                <Snipet kod="nalogzahapsenje" />
              </p>
              <p style={pJ}>
                Odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici Sud je donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodluke} kojim se {potraživanom} određuje
                privremeni pritvor koji po tom rješenju može trajati najduže 18
                (osamnaest) dana od dana lišenja slobode {potraživanog}, odnosno
                od <strong>{datumlisenja || "__________"}</strong> do
                <strong>{PRkrajlisenja || "__________"}</strong> ili do nove
                odluke Suda.
              </p>
              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda u smislu odredbe člana 38. stav 4.{" "}
                <Skracenica kod="ZMPP" gen="gen" /> radi{" "}
                <strong>hitnog traženja molbe za izručenje</strong> (u skladu sa
                odredbama zakona/bilateralnog/multilateralnog ugovora) od strane
                države koja je raspisala potjernicu, a vodeći računa o trajanju
                mjere privremenog pritvora te uslovima pod kojim se isti,
                eventualno, može produžiti sa ciljem pribavljanja kompletirane
                molbe za izručenje.
              </p>
            </>
          )}
          {vrstaOdluke === "PREKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o pritvaranju {potraživanog}{" "}
                <strong>{IMELICA.imegen || "__________"}</strong> i davanju saglasnosti
                {potraživanog} za{" "}
                <strong>pojednostavljeno izručenje državi</strong> moliteljici,{" "}
                dostavlja se;
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za
                pritvaranje sadržanom u međunarodnoj potjernici donio rješenje,
                broj:
                {brojPredmeta || "__________"} {PREKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom},{" "}
                <strong>
                  po davanju saglasnosti za pojednostavljeno izručenje
                </strong>
                , određuje ekstradicioni pritvor koji po tom rješenju prema
                {potraživanom} može trajati do izvršenja odluke o izručenju ili do
                nove odluke ovog Suda, ali najduže šest mjeseci računajući od
                dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do{" "}
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                Zapisnik u kojem je sadržana saglasnost za pojednostavljeno
                izručenje Sud će, u smislu odredbe člana 52. stav 5.{" "}
                <Skracenica kod="ZMPP" gen="gen" />, bez odgađanja dostaviti
                Ministarstvu pravde Bosne i Hercegovine radi donošenja odluke
                (rješenja) o izručenju {potraživanog}.
              </p>
            </>
          )}
          {vrstaOdluke === "PRO" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o{" "}
                <strong>produženju privremenog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,
                preduzimanje radnji u cilju{" "}
                <strong>
                  {" "}
                  blagovremenog dostavljanja kompletirane molbe za izručenje
                </strong>
                , traži se;
              </p>
              <p style={pJ}>
                Posdjećamo da je realizacijom međunarodne potjernice{" "}
                <Snipet kod="ncb1ncb2"  veznik="potjernice" />, pred Sudom Bosne i Hercegovine (u
                daljem tekstu "Sud BiH/Sud") iniciran postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodlukePR || "__________"} {potraživanom} je određen
                privremeni pritvor, a isti je rješenjem Suda BiH, broj:{" "}
                {brojPredmeta || "__________"} {PROfaza} od{" "}
                {datumodlukePRO || "__________"}, <strong>produžen</strong> na
                način da može trajati do dostavljanja molbe za izručenje i
                ekstradicione dokumentacije od strane države moliteljice, ili do
                nove odluke Suda, a najduže 40 (četrdeset) dana računajući od
                dana lišenja slobode,{" "}
                <strong>
                  {" "}
                  odnosno od {datumlisenja || "__________"} do{" "}
                  {PROkrajlisenja || "__________"} ili do nove odluke Suda.
                </strong>
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda kojim se {potraživanom} produžava mjera privremenog pritvora
                uz zahtjev da Tužilaštvo BiH, u smislu odredbe člana 38. stav 4.{" "}
                <Skracenica kod="ZMPP" gen="gen" />, preduzme radnje radi{" "}
                <strong>blagovremenog dostavljanja molbe za izručenje</strong>{" "}
                (u skladu sa odredbama zakona/bilateralnog/multilateralnog
                ugovora) od strane države koja je raspisala potjernicu, a vodeći
                računa maksimalnom mogućem trajanju mjere privremenog pritvora.
              </p>
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o ukidanju privremenog i{" "}
                <strong>određivanju ekstradicionog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,{" "}
                dostavlja se;{" "}
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"}, a nakon zaprimanja molbe za izručenje{" "}
                {DRZMOLITELJICA.imegen || "__________"},{" "}
                {EKSbrojmolbe || "__________"}, te izjašnjenja stranaka i
                branioca o molbi i pritvoru, donio rješenje{" "}
                broj: {brojPredmeta || "__________"} {EKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                - ukida ranije određeni privremeni pritvor i, istovremeno,{" "}
                <strong>određuje ekstradicioni pritvor</strong> koji po
                navedenom rješenju prema {potraživanom} može trajati do izvršenja
                odluke o izručenju ili do nove odluke ovog Suda, ali najduže
                šest mjeseci računajući od dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo naznačeno
                rješenje te, istovremeno, obavještavamo da će Sud, ukoliko u
                smislu odredbe člana 36. stav 2.{" "}
                <Skracenica kod="ZMPP" gen="gen" /> ne bude bilo potrebno
                tražiti dopunu ekstradicione dokumentacije, nastaviti sa
                preduzimanjem drugih izviđajnih radnji radi utvrđivanja postoje
                li pretpostavke za izručenje, a zatim spise izviđaja sa
                mišljenjem (sudije za prethodni postupak) dostaviti Vijeću Suda
                Bosne i Hercegovine radi donošenja odluke o pretpostavkama za
                izručenje {potraživanog}.
              </p>
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}

      {vrstaDopisa === "NCB" && (
        <>
          {vrstaOdluke === "ODB" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o{" "}
                {ODBobustavi === "NE" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje {potraživanog}, <strong>stavljanje van snage potjernice</strong>, traži se;</>}
                {ODBobustavi === "DA" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje i <strong>obustavi postupka</strong> u odnosu na {licezensko ? "potraživanu" : "potraživanog"}, <strong>stavljanje van snage potjernice</strong>, traži se;</>}
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja{" "}
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                U vezi sa naprijed navedenim, obavještavamo vas da je Sud,
                odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici, a po saslušanju {potraživanog}, donio
                rješenje, broj:{" "}
                {brojPredmeta || "__________"} {ODBfaza} od{" "}
                {datumodluke}, kojim se{" "}
                {ODBobustavi === "NE" && <><strong>odbija zahtjev za privremeno pritvaranje</strong> {potraživanog} zbog razloga koji
                  su detaljno obrazloženi u rješenju koje vam dostavljamo u prilogu.</>}
                {ODBobustavi === "DA" && <><strong>odbija zahtjev za privremeno pritvaranje i, istovremeno, obustavlja postupak</strong> izručenja
                  u odnosu na {licezensko ? "potraživanu" : "potraživanog"} zbog razloga koji su detaljno obrazloženi u rješenju koje vam dostavljamo u prilogu.</>}
              </p>
                <p style={pJ}>Imaući u vidu navednu odluku Suda neophodno je <strong>staviti van snage međunarodnu potjernicu</strong> raspisanu prema {licezensko ? "potraživanoj" : "potraživanom"} {IMELICA.imedat || "__________"} <strong>na području Bosne i Hercegovine</strong>. Ovo iz razloga kako {potraživani} ne bi {bio} ponovo {lišen} slobode na području Bosne i Hercegovine po istom zahtjevu za privremeno pritvaranje/međunarodnoj potjernici o kojem je odlučeno navedenim rješenjem Suda.</p>
            </>
          )}
          {vrstaOdluke === "MZ" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o
                lišenju slobode i <strong>izricanju mjera zabrane</strong>{" "}
                {potraživanom}, dostavlja se;
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                U vezi sa naprijed navedenim, bavještavamo vas da je Sud,
                odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici donio rješenje, broj:
                {brojPredmeta || "__________"} {MZfaza} od{" "}
                {datumodluke}, kojim se <strong> odbija zahtjev za
                privremeno pritvaranje</strong> te {potraživanom}, istovremeno
                {!MZputovanje && !MZboraviste && !MZjavljanje ? (
                  <>
                   {" "}izriču mjere zabrane ... <br />
                    <br />{" "}
                    <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />{" "}
                  </>
                ) : (
                  <>
                    , <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />.
                  </>
                )}
              </p>
              <p style={pJ}>
                Sud je o izricanju mjera zabrane {potraživanom} dostavio obavijest{" "}
                <Skracenica kod="TBiH" aku="aku" /> i{" "}
                <Skracenica kod="MPBiH" aku="aku" /> radi obavještavanja države
                moliteljice i traženja molbe za izručenje (u skladu sa odredbama
                zakona/bilateralnog/multilateralnog ugovora), a sve kako bi se
                postupak izručenja mogao okončati u razumnom roku.
              </p>
              <p style={pJ}>Imaući u vidu navednu odluku Suda neophodno je <strong>staviti van snage međunarodnu potjernicu</strong> raspisanu prema {licezensko ? "potraživanoj" : "potraživanom"} {IMELICA.imedat || "__________"} <strong>na području Bosne i Hercegovine</strong>. Ovo iz razloga kako {potraživani} ne bi {bio} ponovo {lišen} slobode na području Bosne i Hercegovine po istom zahtjevu za privremeno pritvaranje/međunarodnoj potjernici o kojem je odlučeno navedenim rješenjem Suda.</p>
            </>

          )}
          {vrstaOdluke === "PR" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o{" "}
                <strong>privremenom pritvaranju</strong> {potraživanog}{" "}
                <strong>{IMELICA.imegen || "__________"}</strong>,{" "}
                <strong>dostavljanje obavijesti državi moliteljici</strong>,
                traži se;
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                U vezi sa naprijed navedenim, obavještavamo vas da je Sud
                odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodluke} kojim se {potraživanom} određuje
                privremeni pritvor koji po tom rješenju može trajati najduže 18
                (osamnaest) dana od dana lišenja slobode {potraživanog}, odnosno
                od <strong>{datumlisenja || "__________"}</strong> do
                <strong>{PRkrajlisenja || "__________"}</strong> ili do nove
                odluke Suda.
              </p>
              <p style={pJ}>
                Sud je o privremenom pritvaranju {potraživanog} dostavio obavijest{" "}
                <Skracenica kod="TBiH" aku="aku" /> i{" "}
                <Skracenica kod="MPBiH" aku="aku" /> radi obavještavanja države
                moliteljice i{" "}
                <strong>hitnog traženja molbe za izručenje</strong> (u skladu sa
                odredbama zakona/bilateralnog/multilateralnog ugovora), a sve u
                okviru trajanja mjere privremenog pritvora.
              </p>
              <p style={pJ}>
                Međutim, imajući u vidu razloge hitnosti ovom prilikom vam se
                obraćamo sa zahtjevom da informaciju o privremenom pritvaranju
                {potraživanog} dostavite nadležnom organu države moliteljice i
                putem Interpola {DRZMOLITELJICA.gen || "__________"}{" "}
                <strong>uz napomenu</strong> da je redovnim putem (putem{" "}
                <Skracenica kod="MPBiH" gen="gen" />) potrebno dostviti molbu za
                izručenje kompletiranu u skladu primjenjivim
                bilateralnim/multilateralnim ugovorima i to{" "}
                <strong>u okviru trajanja privremenog pritvora</strong> koji se,
                po zahtjevu države moliteljice, može produžiti do 40 dana od
                dana lišenja slobode {potraživanog}.
                <br />
                <br />
                Državu moliteljicu je neophdno upoznati da će {potraživani} biti
                pušten na slobodu ukoliko molba za izručenje ne bude dostavljena
                u okviru trajanja privremenog pritvora.
              </p>
            </>
          )}
          {vrstaOdluke === "PREKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o{" "}
                <strong>pritvaranju</strong> {potraživanog}{" "}
                <strong>{IMELICA.imegen || "__________"}</strong> i davanju saglasnosti
                {potraživanog} za{" "}
                <strong>pojednostavljeno izručenje državi moliteljici</strong>,
                dostavlja se;
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za
                pritvaranje sadržanom u međunarodnoj potjernici donio rješenje,
                broj:
                {brojPredmeta || "__________"} {PREKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom},{" "}
                <strong>
                  po davanju saglasnosti za pojednostavljeno izručenje
                </strong>
                , određuje ekstradicioni pritvor koji po tom rješenju prema
                {potraživanom} može trajati do izvršenja odluke o izručenju ili do
                nove odluke ovog Suda, ali najduže šest mjeseci računajući od
                dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do{" "}
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                Zapisnik u kojem je sadržana saglasnost za pojednostavljeno
                izručenje Sud će, u smislu odredbe člana 52. stav 5.{" "}
                <Skracenica kod="ZMPP" gen="gen" />, bez odgađanja dostaviti
                Ministarstvu pravde Bosne i Hercegovine radi donošenja odluke
                (rješenja) o izručenju {potraživanog}.
              </p>
            </>
          )}
          {vrstaOdluke === "PRO" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o{" "}
                <strong>produženju privremenog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,{" "}
                <strong>dostavljanje obavijesti državi moliteljici</strong>,
                traži se;
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Posdjećamo da je realizacijom međunarodne potjernice{" "}
                <Snipet kod="ncb1ncb2"  veznik="potjernice" />, pred Sudom Bosne i Hercegovine (u
                daljem tekstu "Sud BiH/Sud") iniciran postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodlukePR || "__________"} {potraživanom} je određen 
                privremeni pritvor, a isti je rješenjem Suda BiH, broj:{" "}
                {brojPredmeta || "__________"} {PROfaza} od{" "}
                {datumodlukePRO || "__________"}, <strong>produžen</strong> na
                način da može trajati do dostavljanja molbe za izručenje i
                ekstradicione dokumentacije od strane države moliteljice, ili do
                nove odluke Suda, a najduže 40 (četrdeset) dana računajući od
                dana lišenja slobode,{" "}
                <strong>
                  {" "}
                  odnosno od {datumlisenja || "__________"} do{" "}
                  {PROkrajlisenja || "__________"} ili do nove odluke Suda.
                </strong>
              </p>

              <p style={pJ}>
                Imajući u vidu razloge hitnosti ovom prilikom vam se obraćamo sa
                zahtjevom da informaciju o produženju privremenog pritvora
                {potraživanom} dostavite nadležnom organu države moliteljice putem
                Interpola {DRZMOLITELJICA.gen || "__________"}{" "}
                <strong>uz napomenu</strong> da je redovnim putem (putem{" "}
                <Skracenica kod="MPBiH" gen="gen" />) potrebno dostviti molbu za
                izručenje kompletiranu u skladu primjenjivim
                bilateralnim/multilateralnim ugovorima i to{" "}
                <strong>
                  u okviru maksimalnog trajanja privremenog pritvora
                </strong>
                .
                <br />
                <br />
                Državu moliteljicu je neophdno upoznati da će {potraživani} biti
                pušten na slobodu ukoliko molba za izručenje ne bude dostavljena
                u okviru trajanja privremenog pritvora.
              </p>
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="NCBSarajevo" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o ukidanju privremenog i{" "}
                <strong>određivanju ekstradicionog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,{" "}
                <strong>dostavljanje obavijesti državi moliteljici</strong>,
                traži se;
              </p>
              <p style={pL}>
                <strong>Veza:</strong> Vaš akt broj:{" "}
                <strong>{ncb1 || "__________"}</strong>
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"}, a nakon zaprimanja molbe za izručenje{" "}
                {DRZMOLITELJICA.imegen || "__________"},{" "}
                {EKSbrojmolbe || "__________"}, te izjašnjenja stranaka i
                branioca o molbi i pritvoru, donio rješenje{" "}
                broj: {brojPredmeta || "__________"} {EKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                - ukida ranije određeni privremeni pritvor i, istovremeno,{" "}
                <strong>određuje ekstradicioni pritvor</strong> koji po
                navedenom rješenju prema {potraživanom} može trajati do izvršenja
                odluke o izručenju ili do nove odluke ovog Suda, ali najduže
                šest mjeseci računajući od dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo naznačeno
                rješenje te, istovremeno, obavještavamo da će Sud, ukoliko ne
                bude bilo potrebno tražiti dopunu ekstradicione dokumentacije,
                nastaviti sa preduzimanjem drugih izviđajnih radnji radi
                utvrđivanja postoje li pretpostavke za izručenje {potraživanog}
                nakon čega će, po okončanju sudske faze, konačnu odluku o
                (ne)dopuštenosti izručenja potrživanog donijeti ministar pravde
                Bosne i Hercegovine o čemu će država moliteljica biti
                blagovremeno obaviještena.
              </p>
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}

      {vrstaDopisa === "MPBIH" && (
        <>
          {vrstaOdluke === "ODB" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="MPBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o{" "}
                {ODBobustavi === "NE" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje {potraživanog}, dostavlja se;</>}
                {ODBobustavi === "DA" && <><strong>odbijanju zahtjeva</strong> za privremeno pritvaranje i <strong>obustavi postupka</strong> u odnosu {potraživanog}, dostavlja se;</>}
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice NCB Interpola
                {DRZMOLITELJICA.nazivinterpola || "__________"},
                {DRZMOLITELJICA.ime || "__________"}, broj:
                {ncb2 || "_________"}, odnosno ekvivalentne potjernice NCB
                Interpola Sarajevo, iniciran je pred Sudom Bosne i Hercegovine
                (u daljem tekstu "Sud BiH/Sud") postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za privremeno
                pritvaranje sadržanom u međunarodnoj potjernici Sud je donio
                rješenje, broj:{" "}
                {brojPredmeta || "__________"} {ODBfaza} od{" "}
                {datumodluke}, kojim se{" "}
                {ODBobustavi === "NE" && <>odbija zahtjev za privremeno pritvaranje {potraživanog}.</>}
                {ODBobustavi === "DA" && <>odbija zahtjev za privremeno pritvaranje i, istovremeno, obustavlja postupak izručenja u odnosu na {potraživanog}.</>}
              </p>
            </>
          )}
          {vrstaOdluke === "MZ" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="MPBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o
                lišenju slobode i <strong>izricanju mjera zabrane</strong>{" "}
                {potraživanom}, <strong>molba za izručenje</strong>, traži se;
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud, odlučujući o zahtjevu za privremeno
                pritvaranje sadržanom u međunarodnoj potjernici donio rješenje,
                broj:
                {brojPredmeta || "__________"} {MZfaza} od{" "}
                {datumodluke}, kojim se odbija zahtjev za
                privremeno pritvaranje te {potraživanom}, istovremeno{" "}
                {!MZputovanje && !MZboraviste && !MZjavljanje ? (
                  <>
                    izriču mjere zabrane ... <br />
                    <br />{" "}
                    <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />{" "}
                    <br />
                  </>
                ) : (
                  <>
                    , <Snipet kod="MZobrazlozenje" tip="opisskracenodopis" />.
                  </>
                )}
              </p>
              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda u smislu odredbe člana 38. stav 4.{" "}
                <Skracenica kod="ZMPP" gen="gen" /> radi traženja molbe za
                izručenje (u skladu sa odredbama
                zakona/bilateralnog/multilateralnog ugovora) od strane države
                koja je raspisala potjernicu kako bi se postupak izručenja mogao
                okončati u razumnom roku.
              </p>
            </>
          )}
          {vrstaOdluke === "PR" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="MPBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o privremenom pritvaranju
                {potraživanog} <strong>{IMELICA.imegen || "__________"}</strong>,
                upućivanje zahtjeva državi moliteljici za{" "}
                <strong>
                  {" "}
                  hitno dostavljanje kompletirane molbe za izručenje
                </strong>
                , traži se;
              </p>
              <p style={pJ}>
                Realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                iniciran je pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud
                BiH/Sud") postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici Sud je donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodluke} kojim se {potraživanom} određuje
                privremeni pritvor koji po tom rješenju može trajati najduže 18
                (osamnaest) dana od dana lišenja slobode {potraživanog}, odnosno
                od <strong>{datumlisenja || "__________"}</strong> do
                <strong>{PRkrajlisenja || "__________"}</strong> ili do nove
                odluke Suda.
              </p>
              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda u smislu odredbe člana 38. stav 4.{" "}
                <Skracenica kod="ZMPP" gen="gen" /> radi{" "}
                <strong>hitnog traženja molbe za izručenje</strong> (u skladu sa
                odredbama zakona/bilateralnog/multilateralnog ugovora) od strane
                države koja je raspisala potjernicu, a vodeći računa o trajanju
                mjere privremenog pritvora te uslovima pod kojim se isti,
                eventualno, može produžiti sa ciljem pribavljanja kompletirane
                molbe za izručenje.
              </p>
            </>
          )}

          {vrstaOdluke === "PREKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="MPBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>{Potraživani}{" "}
                <strong>{IMELICA.ime || "__________"}</strong>, informacija o
                određivanju ekstradicionog pritvora{" "}
                {potraživanom}, zapisnik Suda -
                <strong>
                  {" "}
                  saglasnost {potraživanog} za pojednostavljeno izručenje
                </strong>
                , dostavlja se;
              </p>

              <p style={pJ}>
                Realizacijom međunarodne potjernice NCB Interpola
                {DRZMOLITELJICA.nazivinterpola || "__________"},
                {DRZMOLITELJICA.ime || "__________"}, broj:
                {ncb2 || "_________"}, odnosno ekvivalentne potjernice NCB
                Interpola Sarajevo, iniciran je pred Sudom Bosne i Hercegovine
                (u daljem tekstu "Sud BiH/Sud") postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Odlučujući o zahtjevu za pritvaranje sadržanom u međunarodnoj
                potjernici Sud je donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PREKSfaza} od{" "}
                {datumodluke} kojim se{" "}
                {potraživanom},{" "}
                <strong>
                  po davanju saglasnosti za pojednostavljeno izručenje
                </strong>
                , određuje ekstradicioni pritvor koji po tom rješenju može
                trajati do izvršenja odluke o izručenju ili do nove odluke ovog
                Suda, ali najduže šest mjeseci računajući od dana lišenja
                slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do{" "}
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa Vam dostavljamo zapisnik
                Suda od {datumlisenja || "_________"} u kojem je sadržana
                saglasnost {potraživanog} za pojednostavljeno izručenje kako bi
                ministar pravde Bosne i Hercegovine, u simslu člana 52. stav 5.{" "}
                <Skracenica kod="ZMPP" gen="gen" />, odlučio o izručenju
                {potraživanog}.
              </p>
            </>
          )}
          {vrstaOdluke === "PRO" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="TBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o{" "}
                <strong>produženju privremenog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,
                preduzimanje radnji u cilju{" "}
                <strong>
                  {" "}
                  blagovremenog dostavljanja kompletirane molbe za izručenje
                </strong>
                , traži se;
              </p>
              <p style={pJ}>
                Posdjećamo da je realizacijom međunarodne potjernice{" "}
                <Snipet kod="ncb1ncb2"  veznik="potjernice" />, pred Sudom Bosne i Hercegovine (u
                daljem tekstu "Sud BiH/Sud") iniciran postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodlukePR || "__________"} {potraživanom} je određen
                privremeni pritvor, a isti je rješenjem Suda BiH, broj:{" "}
                {brojPredmeta || "__________"} {PROfaza} od{" "} 
                {datumodlukePRO || "__________"}, <strong>produžen</strong> na
                način da može trajati do dostavljanja molbe za izručenje i
                ekstradicione dokumentacije od strane države moliteljice, ili do
                nove odluke Suda, a najduže 40 (četrdeset) dana računajući od
                dana lišenja slobode,{" "}
                <strong>
                  {" "}
                  odnosno od {datumlisenja || "__________"} do{" "}
                  {PROkrajlisenja || "__________"} ili do nove odluke Suda.
                </strong>
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo rješenje
                Suda kojim se {potraživanom} produžava mjera privremenog pritvora
                uz zahtjev da <Skracenica kod="MPBiH" naziv="naziv" />, u smislu
                odredbe člana 38. stav 4. <Skracenica kod="ZMPP" gen="gen" />
                , preduzme radnje radi{" "}
                <strong>blagovremenog dostavljanja molbe za izručenje</strong>{" "}
                (u skladu sa odredbama zakona/bilateralnog/multilateralnog
                ugovora) od strane države koja je raspisala potjernicu, a vodeći
                računa maksimalnom mogućem trajanju mjere privremenog pritvora.
              </p>
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod="MPBiH" naziv="naziv" />
                </strong>
              </p>
              <br />
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o ukidanju privremenog i{" "}
                <strong>određivanju ekstradicionog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>,{" "}
                dostavlja se;{" "}
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"}, a nakon zaprimanja molbe za izručenje{" "}
                {DRZMOLITELJICA.imegen || "__________"},{" "}
                {EKSbrojmolbe || "__________"}, te izjašnjenja stranaka i
                branioca o molbi i pritvoru, donio rješenje{" "}
                broj: {brojPredmeta || "__________"} {EKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                - ukida ranije određeni privremeni pritvor i, istovremeno,{" "}
                <strong>određuje ekstradicioni pritvor</strong> koji po
                navedenom rješenju prema {potraživanom} može trajati do izvršenja
                odluke o izručenju ili do nove odluke ovog Suda, ali najduže
                šest mjeseci računajući od dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>

              <p style={pJ}>
                S tim u vezi, u prilogu ovog dopisa vam dostavljamo naznačeno
                rješenje radi obavještavanja države moliteljice u smislu
                odredbe člana 39. stav 6. <Skracenica kod="ZMPP" gen="gen" />{" "}
                uz napomenu da će Sud, ukoliko ne bude bilo potrebno tražiti
                dopunu ekstradicione dokumentacije, nastaviti sa preduzimanjem
                drugih izviđajnih radnji radi utvrđivanja postoje li
                pretpostavke za izručenje nakon čega će, po okončanju sudske
                faze, cio spis biti dostavljen{" "}
                <Skracenica kod="MPBiH" aku="aku" /> radi donošenja konačne
                odluke o (ne)dopuštenosti izručenja potrživanog.
              </p>
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}


      {vrstaDopisa === "SAGLASNOST" && (
        <>
          {(vrstaOdluke === "PR" || vrstaOdluke === "PREKS" ) && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <strong style={{ textTransform: "uppercase" }}>
                  <Skracenica kod={zavod} naziv="naziv" />
                </strong>
                <br /> <Skracenica kod={zavod} adresa="adresa" />
                <br /><strong>- Pritvorskom odjeljenju Zavoda -</strong>
              </p>

              <p style={pR}>
                {dvabranioca ?
                  <>
                    <strong style={{ textTransform: "uppercase" }}>
                      ADVOKAT
                    </strong>
                    <br />
                    <strong>{BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}</strong>{" "}
                    (<strong>{BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}</strong>)
                    <br /> (na informaciju)
                    <br />
                    <br />
                    <strong style={{ textTransform: "uppercase" }}>
                      ADVOKAT
                    </strong>
                    <br />
                    <strong>{BRANILAC.length > 0 ? BRANILAC[1].ime || "_________" : "_________"}</strong>{" "}
                    (<strong>{BRANILAC.length > 0 ? BRANILAC[1].grad || "_________" : "_________"}</strong>)
                    <br /> (na informaciju)
                  </>
                  :
                  <>
                    <strong style={{ textTransform: "uppercase" }}>
                      ADVOKAT
                    </strong>
                    <br />
                    <strong>{BRANILAC.length > 0 ? BRANILAC[0].ime || "_________" : "_________"}</strong>{" "}
                    (<strong>{BRANILAC.length > 0 ? BRANILAC[0].grad || "_________" : "_________"}</strong>)
                    <br />(na informaciju)
                  </>}
              </p>

              <p style={pR}>
                <strong style={{ textTransform: "uppercase" }}>
                  {POTRAŽIVANI}
                </strong>
                <br />
                <strong>{IMELICA.ime || "__________"}</strong>
                <br /> (na informaciju)
              </p>
              {dodajtumaca &&
                <>
                  <p style={pR}>
                    <strong style={{ textTransform: "uppercase" }}>
                      SUDSKI TUMAČ
                    </strong>
                    <br />
                    <strong>{TUMAC.ime || "__________"}</strong>
                    <br /> (na informaciju)
                  </p>
                </>}
              <p style={pC}>
                <strong>
                  SAGLASNOST
                </strong>
                <br />
                (za posjetu i telefonsku komunikaciju pritvorenika/{potraživanog})
              </p>

              <p style={pL}>
                Obavještavamo vas da je {potraživani}:
              </p>
              <br />
              <LPodaci kod="LPprikazSkraceno2" />
              <br />
              <p style={pJ}>
              {upućen} u <Skracenica kod={zavod} naziv="naziv" /> <strong>radi izdržavanja mjere{" "}{vrstaOdluke === "PR" ? "privremenog" : "ekstradicionog"}{" "} pritvora</strong>{" "}
              određenog rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {vrstaOdluke === "PR" ? PRfaza : PREKSfaza} od {datumodluke}.
              </p>
              <p style={pJ}>
              Radi ostvarenja prava {potraživanog} na komunikaciju sa {njegovim} braniocem potrebno je {istom} <strong>omogućiti stalnu posjetu bez posebne pojedinačne dozvole kao i telefonsku komunikaciju od strane</strong>:
              </p>
              <p style={pC}> <strong>I</strong> </p>
              {dvabranioca ?
                <p style={pJ}>
                   <strong>- Advokata {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</strong> (izabrani branilac) - <strong>kontakt telefon: _______________</strong>;
                  <br />
                   <strong>- Advokata {BRANILAC.length > 0 ? BRANILAC[1].imegen || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[1].gradgen || "_________" : "_________"}</strong> (izabrani branilac) - <strong>kontakt telefon: _______________</strong>;
                </p> :
                <p style={pJ}>
                  <strong>- Advokata {BRANILAC.length > 0 ? BRANILAC[0].imegen || "_________" : "_________"} iz {BRANILAC.length > 0 ? BRANILAC[0].gradgen || "_________" : "_________"}</strong> {vrstabranioca === "izabrani" ? "(izabrani branilac)" : "(branilac po službenoj dužnosti)"} - <strong>kontakt telefon: _______________</strong>;
                </p>}
              {dodajtumaca && <p style={pJ}>
             <strong>- Sudskog tumača {TUMAC.ime || "__________"}</strong> za {jezikprevoda || "__________"} jezik - <strong>kontakt telefon: _______________</strong>;
              </p>}
         
              <p style={pC}> <strong>II</strong> </p>
              <p style={pJ}>
              Posjetu i kontakt {potraživanog} u smislu tačke I ove saglasnosti neophodno je prilagoditi pravilima kućnog reda <Skracenica kod={zavod} gen="gen" />.
              </p>
              <br />
          
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}

      {vrstaDopisa === "AMBASADA" && (
        <>
          {vrstaOdluke === "PR" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <NazivAmbasade />
              </p>
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o privremenom pritvaranju{" "}
                {potraživanog} <strong>{IMELICA.imegen || "__________"}</strong>, <strong>
                  {državljanin} {licnipodaci.državljanstvo || "_______________"}
                </strong>, dostavlja se;
              </p>
              <p style={pJ}>
                Obavještavamo Vas da je realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") pokrenut postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Odlučujući o zahtjevu za privremeno pritvaranje sadržanom u
                međunarodnoj potjernici Sud je, po saslušanju {potraživanog}, donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodluke} kojim se {potraživanom} određuje
                privremeni pritvor koji po tom rješenju može trajati najduže 18
                (osamnaest) dana od dana lišenja slobode {potraživanog}, odnosno
                od <strong>{datumlisenja || "__________"}</strong> do{" "}
                <strong>{PRkrajlisenja || "__________"}</strong> ili do nove
                odluke Suda.
              </p>
              <p style={pJ}>
                O navedenom Vas obavještavamo obzirom da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "_______________"}.
              </p>
              <p style={pJ}>
                Za sve dalje informacije u vezi postupka koji se vodi protiv {potraživanog}, eventualne posjete pritvorenom licu i slično, možete se obratiti ovom Sudu pozivom na broj sudskog predmeta naznačenom u uvodu ovog dopisa.
              </p>
              <p style={pJ}>
                S poštovanjem.
              </p>
            </>
          )}

          {vrstaOdluke === "PREKS" && (
            <>
               <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <NazivAmbasade />
              </p>
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o određivanju ekstradicionog pritvora {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>, <strong>
                  {državljanin} {licnipodaci.državljanstvo || "_______________"}
                </strong>, dostavlja se;
              </p>
              <p style={pJ}>
                Obavještavamo Vas da je realizacijom međunarodne potjernice <Snipet kod="ncb1ncb2"  veznik="potjernice" />,
                pred Sudom Bosne i Hercegovine (u daljem tekstu "Sud BiH/Sud") pokrenut postupak izručenja {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>
              <p style={pJ}>
                Odlučujući o zahtjevu za pritvaranje sadržanom u međunarodnoj
                potjernici Sud je donio rješenje, broj:{" "}
                {brojPredmeta || "__________"} {PREKSfaza} od{" "}
                {datumodluke} kojim se{" "}
                {potraživanom},{" "}
                <strong>
                  po davanju saglasnosti za pojednostavljeno izručenje
                </strong>
                , određuje ekstradicioni pritvor koji po tom rješenju može
                trajati do izvršenja odluke o izručenju ili do nove odluke ovog
                Suda, ali najduže šest mjeseci računajući od dana lišenja
                slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do{" "}
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>
              <p style={pJ}>
                O navedenom Vas obavještavamo obzirom da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "_______________"}.
              </p>
              <p style={pJ}>
                Za sve dalje informacije u vezi postupka koji se vodi protiv {potraživanog}, eventualne posjete pritvorenom licu i slično, možete se obratiti ovom Sudu pozivom na broj sudskog predmeta naznačenom u uvodu ovog dopisa.
              </p>
              <p style={pJ}>
                S poštovanjem.
              </p>
            </>
          )}
          {vrstaOdluke === "PRO" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <NazivAmbasade />
              </p>
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o <strong>produženju privremenog pritvora</strong> prema
                {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>, <strong>
                  {državljanin} {licnipodaci.državljanstvo || "_______________"}
                </strong>, dostavlja se;
              </p>
              <p style={pJ}>
                Posdjećamo da je realizacijom međunarodne potjernice{" "}
                <Snipet kod="ncb1ncb2"  veznik="potjernice" />, pred Sudom Bosne i Hercegovine (u
                daljem tekstu "Sud BiH/Sud") iniciran postupak izručenja
                {potraživanog}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                Međunarodna potjernica raspisana je u cilju <Snipet kod="razlozi" />.
              </p>

              <p style={pJ}>
                Rješenjem Suda BiH, broj: {brojPredmeta || "__________"} {PRfaza} od{" "}
                {datumodlukePR || "__________"} {potraživanom} je određen
                privremeni pritvor, a isti je rješenjem Suda BiH, broj:{" "}
                {brojPredmeta || "__________"} {PROfaza} od{" "}
                {datumodlukePRO || "__________"}, <strong>produžen</strong> na
                način da može trajati do dostavljanja molbe za izručenje i
                ekstradicione dokumentacije od strane države moliteljice, ili do
                nove odluke Suda, a najduže 40 (četrdeset) dana računajući od
                dana lišenja slobode,{" "}
                <strong>
                  {" "}
                  odnosno od {datumlisenja || "__________"} do{" "}
                  {PROkrajlisenja || "__________"} ili do nove odluke Suda.
                </strong>
              </p>
              <p style={pJ}>
                O navedenom Vas obavještavamo obzirom da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "_______________"}.
              </p>
              <p style={pJ}>
                Za sve dalje informacije u vezi postupka koji se vodi protiv {potraživanog}, eventualne posjete pritvorenom licu i slično, možete se obratiti ovom Sudu pozivom na broj sudskog predmeta naznačenom u uvodu ovog dopisa.
              </p>
              <p style={pJ}>
                S poštovanjem.
              </p>
            </>
          )}
          {vrstaOdluke === "EKS" && (
            <>
              <Snipet kod="BrojDatum"/>
              <p style={pC}>
                <NazivAmbasade />
              </p>
              <p style={pJ}>
                <strong>Predmet: </strong>Informacija o ukidanju privremenog i određivanju ekstradicionog pritvora {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong>, <strong>
                  {državljanin} {licnipodaci.državljanstvo || "_______________"}
                </strong>, dostavlja se;
              </p>
              <p style={pJ}>
                Obavještavamo vas da je Sud u postupku izručenja {potraživanog}{" "}
                {IMELICA.imegen || "_________"}, a nakon zaprimanja molbe za izručenje{" "}
                {DRZMOLITELJICA.imegen || "__________"},{" "}
                {EKSbrojmolbe || "__________"}, te izjašnjenja stranaka i
                branioca o molbi i pritvoru, donio rješenje{" "}
                broj: {brojPredmeta || "__________"} {EKSfaza} od{" "}
                {datumodluke} kojim se {potraživanom}:
              </p>
              <LPodaci kod="LPprikazSkraceno" />
              <p style={pJ}>
                - ukida ranije određeni privremeni pritvor i, istovremeno,{" "}
                <strong>određuje ekstradicioni pritvor</strong> koji po
                navedenom rješenju prema {potraživanom} može trajati do izvršenja
                odluke o izručenju ili do nove odluke ovog Suda, ali najduže
                šest mjeseci računajući od dana lišenja slobode{" "}
                <strong>
                  odnosno od {datumlisenja || "_________"} do
                  {EKSkrajlisenja || "_________"}
                </strong>
                .
              </p>
              <p style={pJ}>
                O navedenom Vas obavještavamo obzirom da je {potraživani} {državljanin} {licnipodaci.državljanstvo || "_______________"}.
              </p>
              <p style={pJ}>
                Za sve dalje informacije u vezi postupka koji se vodi protiv {potraživanog}, eventualne posjete pritvorenom licu i slično, možete se obratiti ovom Sudu pozivom na broj sudskog predmeta naznačenom u uvodu ovog dopisa.
              </p>
              <p style={pJ}>
                S poštovanjem.
              </p>
            </>
          )}
          <Sastavsudaipouka kod="sastav" sastav="spp" />
        </>
      )}


    </>
  );
};

export default Dopis;
