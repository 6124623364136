import React from 'react'
import skracenice  from "../liste/skracenice";

/*
const data = { name: "America", html: "text <strong>strong text</strong>" };

const MyComponent = ({ data }) => {
  return (
    <div>
      <h1>{data.name}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.html }} />
    </div>
  );
};
*/

const Skracenica = (props) => {

  function pretraga(collection, key, value) {
    for (const o of collection) {
      for (const [k, v] of Object.entries(o)) {
        if (k === key && v === value) {
          return o
        }
        if (Array.isArray(v)) {
          const _o = pretraga(v, key, value)
          if (_o) {
            return _o
          }
        }
      }
    }
  }
  
  let skr = pretraga(skracenice, 'kod', props.kod)

  return (
    <>
  {props.naziv && <>{skr.naziv}</>}
      {props.gen && <>{skr.gen}</>}
      {props.aku && <>{skr.aku}</>}
      {props.sl && <>{skr.sl}</>}
      {props.adresa && <>{skr.adresa}</>} 
      {props.skr && <>{skr.skr}</>} 
      {props.skraku && <>{skr.skraku}</>} 
    </>
  );
};

export default Skracenica;
