import React from 'react'

import { useGlobalContext } from '../../context'
import Button from "react-bootstrap/Button";

import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import ToggleButton from 'react-bootstrap/ToggleButton';

import skracenice from '../liste/skracenice';
import Skracenica from './Skracenica';


const Zavodopcije = () => {
    const {  zavod, izaberiZavodCanvas, kontrolisiCanvas, potraživani, inputChange} = useGlobalContext()
    let svizavodi = skracenice.find(e => e.zavodi);

  return (
    <>
    <Button variant="secondary mb-2" style={{ width: "100%" }}
                onClick={(e) => kontrolisiCanvas("CANVAS_ZAVODOPCIJE_OTVORI")}
            >
                Preciziraj zavod ({<Skracenica kod={zavod} skr="skr"/>})
            </Button>

            <Offcanvas style={{ "width": "700px" }} show={izaberiZavodCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_ZAVODOPCIJE_ZATVORI")} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Izaberi zavod u kojem {potraživani} izdržava mjeru pritvora</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container >
                        <Row>
                            <Col>

                          
        {svizavodi.zavodi.map((z, id) => (
          <ToggleButton
            key={id}
            id={id}
            className="mb-2 me-2"
            style={{ "width": "180px" }}
            type="radio"
            variant={zavod === z.kod ? 'secondary' : 'outline-secondary'}
            name="radio"
            value={z.kod}
            checked={zavod === z.kod}
            onChange={(e) => inputChange('izborzavoda', z.kod)}
          >
            {z.skr}
          </ToggleButton>
        ))}

                            </Col>
                            </Row>
</Container>
</Offcanvas.Body>
</Offcanvas>
   
    </>
  )
}

export default Zavodopcije