import { useState, forwardRef } from 'react';
import { useGlobalContext } from '../../context'
import DatePicker from "react-datepicker";

import { Plus, Dash, Calendar2Check,  Pencil, PencilFill } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import Card from 'react-bootstrap/Card';


const PROdodatne = () => {
    const {kontrolisiCanvas, PROopcijeCanvas, datumodlukePR, PRfaza, datumodlukePRTMP, inputChange, PROzahtjev, PROzahtjevNCB, PROzahtjevMPBIH, 
        PROzahtjevdostavljenBR, PROzahtjevNCBbroj, PROzahtjevMPBIHdatumprijema, PROzahtjevNCBdatumprijema, PROzahtjevMPBIHbroj} = useGlobalContext()
    const PROpromijeni = (id, val) => inputChange(id, val);

    const PROprovjeriopcije = () => {
        if (PROzahtjev === 'NE') {
            return '';
        }
        if (PROzahtjev === 'DA' && (PROzahtjevNCB === true || PROzahtjevMPBIH === true) && PROzahtjevdostavljenBR !== "") {
            return '';
        }
        return 'obavezno';
    };

    const [postavkePR, setPostavkePR] = useState(false);
  

    const KalendarBTN = forwardRef(({ value, onClick }, ref) => (
        <Button variant="secondary" onClick={onClick} ref={ref} style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Calendar2Check /> Kalendar
    </Button>
    
      ))

    return (
        <>
       <div className="d-grid gap-2">
                <Button variant="outline-secondary obavezno mb-2"
                    onClick={(e) => kontrolisiCanvas("CANVAS_PROOPCIJE_OTVORI")}
                >
                    Dodatne opcije
                </Button>
            </div>
            <Offcanvas style={{ "width": "650px" }} show={PROopcijeCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_PROOPCIJE_ZATVORI")} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Produženje privremenog - dodatne opcije</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
             
                          <Card
                            bg=""
                            key="1"
                            text="dark"
                            style={{textAlign: "left" }}
                            className={`text-dark mb-2 ${PROprovjeriopcije()}`}
                        >
                            <Card.Header className={PROprovjeriopcije()}>
                                Zahtjev države moliteljice
                            </Card.Header>
                            <Card.Body className='p-2'>

                                <ToggleButtonGroup type="radio" name='PROzahtjev' value={PROzahtjev} className="mb-2" >
                                    <ToggleButton id="PROimazahtjev" variant="outline-secondary" value="DA" onChange={(e) => PROpromijeni("PROzahtjev", e.target.value)} style={{paddingLeft:"0.60rem", paddingRight:"0.60rem", fontSize: "14px" }}>
                                        dostavljen
                                    </ToggleButton>
                                    <ToggleButton id="PROnemazahtjev" variant="outline-secondary" value="NE" onChange={(e) => PROpromijeni("PROzahtjev", e.target.value)} style={{paddingLeft:"0.60rem", paddingRight:"0.60rem", fontSize: "14px" }}>
                                        nije dostavljen
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                {(PROzahtjev === "DA") && <>
                                <br/>
                                    <ToggleButton
                                        id="PROzahtjevNCB"
                                        type="checkbox"
                                        variant="outline-secondary mb-1 me-1"
                                        size="sm"
                                        checked={PROzahtjevNCB}
                                        value="PROzahtjevNCB"
                                        onChange={(e) => inputChange('PROzahtjevNCB', e.target.checked)}
                                    >
                                        dostavljen putem Interpola
                                    </ToggleButton>
                                    <ToggleButton
                                        id="PROzahtjevMPBIH"
                                        type="checkbox"
                                        variant="outline-secondary mb-1"
                                        size="sm"
                                        checked={PROzahtjevMPBIH}
                                        value="PROzahtjevMPBIH"
                                        onChange={(e) => inputChange('PROzahtjevMPBIH', e.target.checked)}
                                    >
                                        dostavljen putem MP BiH
                                    </ToggleButton>
                                    <br />
                                    <br />
                                    Zahtjev dostavljen odbrani:
                                    <ToggleButtonGroup type="radio" name='PROzahtjevdostavljenBR' value={PROzahtjevdostavljenBR} className="mb-2" >
                                        <ToggleButton id="PROzahtjevdostavljenBRda" variant="outline-secondary ms-1" value="DA" onChange={(e) => PROpromijeni("PROzahtjevdostavljenBR", e.target.value)} style={{ fontSize: "14px" }}>
                                            DA
                                        </ToggleButton>
                                        <ToggleButton id="PROzahtjevdostavljenBRne" variant="outline-secondary" value="NE" onChange={(e) => PROpromijeni("PROzahtjevdostavljenBR", e.target.value)} style={{ fontSize: "14px" }}>
                                            NE
                                        </ToggleButton>
                                    </ToggleButtonGroup>


                                </>}

                            </Card.Body>
                        </Card>
                       
        
                        {(PROzahtjevNCB === true && PROzahtjev === "DA") &&
                            <>
                                <FloatingLabel
                                    controlId="PROzahtjevNCBbroj"
                                    label="Broj i datum zahtjeva za produženje NCB Int."
                                    className="mb-1"
                                ><Form.Control type="text" className={(PROzahtjevNCBbroj === "") && 'obavezaninput'} placeholder="Broj i datum zahtjeva za produženje NCB Int." value={PROzahtjevNCBbroj}
                                    onChange={(e) => inputChange('PROzahtjevNCBbroj', e.target.value)} />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="PROzahtjevNCBdatumprijema"
                                    label="Datum prijema zahtjeva za produženje NCB"
                                    className="mb-1"
                                ><Form.Control type="text" className={(PROzahtjevNCBdatumprijema === "") && 'obavezaninput'} placeholder="Datum prijema zahtjeva za produženje NCB" value={PROzahtjevNCBdatumprijema}
                                    onChange={(e) => inputChange('PROzahtjevNCBdatumprijema', e.target.value)} />
                                </FloatingLabel>

                            </>}

                        {(PROzahtjevMPBIH === true  && PROzahtjev === "DA")&&
                            <>
                                <FloatingLabel
                                    controlId="PROzahtjevMPBIHbroj"
                                    label="Broj i datum zahtjeva za produženje MP BiH"
                                    className="mb-1"
                                ><Form.Control type="text" className={(PROzahtjevMPBIHbroj === "") && 'obavezaninput'} placeholder="Broj i datum zahtjeva za produženje MP BiH" value={PROzahtjevMPBIHbroj}
                                    onChange={(e) => inputChange('PROzahtjevMPBIHbroj', e.target.value)} />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="PROzahtjevMPBIHdatumprijema"
                                    label="Datum prijema zahtjeva za produženje MP BiH"
                                    className="mb-1"
                                ><Form.Control type="text" className={(PROzahtjevMPBIHdatumprijema === "") && 'obavezaninput'} placeholder="Datum prijema zahtjeva za produženje MP BiH" value={PROzahtjevMPBIHdatumprijema}
                                    onChange={(e) => inputChange('PROzahtjevMPBIHdatumprijema', e.target.value)} />
                                </FloatingLabel>
                            </>}

                    <Container>
                        <Row>
                            <Col>

                                <Container className='ps-0 pe-0 mt-2'>
                                            <Row>
                                                <Col xs={10}>
                                                <Form.Label htmlFor="PROdodatneDatumodlukePR">Datum rješenja o <b>određivanju</b> privremenog pritvora</Form.Label>
                                                <InputGroup size='lg'>
                                                <InputGroup.Text style={{minWidth:"42%"}}>
                                                    {datumodlukePR}
                                                </InputGroup.Text>

                                                    <InputGroup.Text >
                                                        Eks {PRfaza}
                                                    </InputGroup.Text>
                                                    <InputGroup.Text onClick={() => setPostavkePR(!postavkePR)}>{postavkePR ? <PencilFill/> : <Pencil/>}</InputGroup.Text>
                                                </InputGroup>
                                                </Col>
                                            </Row>
                                            {postavkePR && 
                                            <>
                                                  <Row >
                                              <Col xs={2} style={{borderRight: "3px dashed rgb(209, 206, 206)"}}> <br/> </Col>
                                            </Row>
                                            <Row>
                                                        <Col>
                                                          <Container>
                                                            <Row>
                                                             <Col xs={7}>
                                                                <Container className='ps-3 pt-2 pb-2 text-bg-light border rounded-3'>
                                                                    <Row>
                                                              
                                                                <Col xs={5} className='pe-0'>
                                                                   <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PR", "+", undefined, "neDirajDatumodluke")}>
                                                                        <Plus />  Datum gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenadatuma", "PR", "-", undefined, "neDirajDatumodluke")}>
                                                                        <Dash />  Datum dole
                                                                    </Button>
                                                                    <DatePicker
                                                                    dateFormat="dd.MM.yyyy."
                                                                    selected={datumodlukePRTMP ? new Date(datumodlukePRTMP) : new Date()}  
                                                                    onChange={(date) => inputChange('izmjenadatuma', "PR", "kalendar", date, "neDirajDatumodluke")}
                                                                    locale="bs"
                                                                    customInput={<KalendarBTN />}
                                                                    />
                                                                    </ButtonGroup>

                                                                </Col>
                                                                <Col xs={5} className='ps-0'>
                                                                    <ButtonGroup
                                                                    vertical
                                                                    size="sm"
                                                                    >
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PR", "+")}>
                                                                        <Plus />  Faza gore
                                                                    </Button>
                                                                    <Button variant="secondary" onClick={(e) => inputChange("izmjenafaze", "PR", "-")}>
                                                                        <Dash />  Faza dole
                                                                    </Button>
                                                                  
                                                                    </ButtonGroup>
                                                                </Col>
                                                                <Col xs={1}><CloseButton onClick={() => setPostavkePR(!postavkePR)}/></Col>
                                                                    </Row>
                                                                </Container>
                                                             </Col>

                                                            
                                                            </Row>
                                                        
                                                </Container>
                                                </Col>
                                               
                                            </Row>
                                            </>}
     
                                      </Container>
                    
                            
                            </Col>

                                </Row>
                                <br />
                    </Container>

                </Offcanvas.Body>
            </Offcanvas>
       
        </>
      
    )
}

export default PROdodatne