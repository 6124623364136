import React from "react";
import { useGlobalContext } from '../context'

import Alert from 'react-bootstrap/Alert';
import Snipet from './dodatneopcije/Snipet'
import Skracenica from './dodatneopcije/Skracenica';

const Uvodnidio = () => {
    const { datumodluke, vrstaOdluke, IMELICA, DRZMOLITELJICA, PROzahtjev,
        EKSbrojprijedloga, EKSbrojmolbe, SUDIJA, TUZILAC, ZAPISNICAR, vrstabranioca,
        MZputovanje, MZboraviste, MZjavljanje, pJ, potraživanog, potraživanom, potraživani } = useGlobalContext()

    // ODB definiši osnov za odbijanje prema ugovoru  NIJE ZAVRŠENO
    // var ODBosnovzaodbijanje = "__________"
    //        {vrstaOdluke === "" && <><p style={{ textAlign: "justify", fontFamily: "Arial" }}> <strong>Broj: </strong><strong>{brojPredmeta}</strong><br /><strong>Sarajevo, </strong><strong>{datumodluke}</strong><br /><br /> Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "__________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" />, odlučujući o __________ __________, na osnovu članova _________ <Skracenica kod="ZMPP" gen="gen"/> <Skracenica kod="ZMPP" sl="sl"/>, a nakon održanog ročišta povodom lišenja slobode {potraživanog} koje je održano u prisustvu {potraživanog} <Snipet kod="UVODstranke" />, dana {datumodluke}, donio je:</p><br/></>}

    return (

        <>

            {vrstaOdluke === "ODB" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "__________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o određivanju mjere privremenog pritvora prema {potraživanom}, na osnovu člana 37 i 40. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, a nakon održanog ročišta povodom lišenja slobode {potraživanog} koje je održano u prisustvu {potraživanog} <Snipet kod="UVODstranke" />, dana {datumodluke}, donio je:</p></>}

            {vrstaOdluke === "MZ" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "__________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o određivanju mjere privremenog pritvora prema {potraživanom},
                    {MZputovanje && !MZboraviste && !MZjavljanje
                        ?
                        <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2. i 126. stav 2. <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                        :
                        MZputovanje && MZboraviste && !MZjavljanje
                            ?
                            <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2. i 126. stav 1. i 2. <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                            :
                            MZputovanje && MZboraviste && MZjavljanje
                                ?
                                <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2., 126. i 126a stav 1. tačka d) <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                                :
                                !MZputovanje && MZboraviste && !MZjavljanje
                                    ?
                                    <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2. i 126. stav 1. <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                                    :
                                    !MZputovanje && MZboraviste && MZjavljanje
                                        ?
                                        <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2., 126. stav 1. i 126a stav 1. tačka d) <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                                        :
                                        !MZputovanje && !MZboraviste && MZjavljanje
                                            ?
                                            <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2. i 126a stav 1. tačka d) <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                                            :
                                            MZputovanje && !MZboraviste && MZjavljanje
                                                ?
                                                <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova 123. stav 2., 126. stav 2. i 126a stav 1. tačka d) <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                                                :
                                                <>na osnovu članova 37., 39. stav 5. i 97. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, te članova __________ <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />")</>
                    }, a nakon održanog ročišta povodom lišenja slobode {potraživanog} koje je održano u prisustvu {potraživanog} <Snipet kod="UVODstranke" />, dana {datumodluke}, donio je:</p></>}

            {vrstaOdluke === "PR" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o određivanju mjere privremenog pritvora prema {potraživanom}, na osnovu članova 37. i 38. stav 4. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, a nakon održanog ročišta povodom lišenja slobode {potraživanog} koje je održano u prisustvu {potraživanog} <Snipet kod="UVODstranke" />, dana {datumodluke}, donio je:</p></>}
            {vrstaOdluke === "PREKS" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o zahtjevu za pritvaranje {potraživanog} sadržanom u međunarodnoj potjernici, na osnovu člana 37. u vezi sa članovima 52. i 39.  <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, a nakon održanog ročišta povodom lišenja slobode {potraživanog} koje je održano u prisustvu {potraživanog} <Snipet kod="UVODstranke" />, dana {datumodluke}, donio je:</p></>}
            {vrstaOdluke === "PRO" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />, raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o produženju mjere privremenog pritvora prema {potraživanom}
                    {PROzahtjev === 'DA'
                        ? <>, po prijedlogu države moliteljice {DRZMOLITELJICA.gen || "_________"} ,</>
                        : PROzahtjev === 'NE'
                            ? <>,</>
                            :
                            <>,</>
                    } na osnovu članova 38. stav 5. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" /> u vezi sa članom 16. stav 4. Evropske konvencije o ekstradiciji, van ročišta, dana {datumodluke}, donio je:</p></>}
            {vrstaOdluke === "EKS" && <>
                <Snipet kod="BrojDatum" />
                <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"}, u postupku izručenja {potraživanog} {IMELICA.imegen || "_________"} po molbi za izručenje {DRZMOLITELJICA.imegen || "__________"}, {EKSbrojmolbe || "__________"}, u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o prijedlogu Tužilaštva Bosne i Hercegovine, broj: {EKSbrojprijedloga || "__________"}, za određivanje ekstradicionog pritvora prema {potraživanom}, a nakon održanog ročišta na kojem su prisustvovali tužilac Tužilaštva Bosne i Hercegovine {TUZILAC.ime || "__________"}, {potraživani} <Snipet kod="UVODstranke" />, na osnovu članova 39. stavovi 1. i 3. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" />, dana {datumodluke}, donio je:</p></>}
            {vrstaOdluke === "BR" &&
                <>
                    {vrstabranioca !== "izabrani" ?
                        <>   <Snipet kod="BrojDatum" />
                            <p style={pJ}>Sud Bosne i Hercegovine, sudija za prethodni postupak {SUDIJA.ime || "_________"}, uz sudjelovanje zapisničara {ZAPISNICAR.imegen || "_________"},
                                u postupku izručenja {potraživanog} {IMELICA.imegen || "_________"} po međunarodnoj potjernici <Snipet kod="ncb1ncb2" />,
                                raspisanoj u cilju <Snipet kod="razlozi" tip="uvod" />, odlučujući o postavljenju branioca po službenoj dužnosti {potraživanom} na osnovu
                                odredbe člana 38. stav 2. <Skracenica kod="ZMPP" gen="gen" /> <Skracenica kod="ZMPP" sl="sl" /> u vezi sa članom 45. stav 2. <Skracenica kod="ZKPBiH" gen="gen" /> (u daljem tekstu: "<Skracenica kod="ZKPBiH" skr="Skr" />"),
                                dana {datumodluke}, donio je:</p></>
                        :
                        <>
                            <Alert variant="secondary">Nije potrebno rješenje jer je <strong>izabrani branilac</strong>.</Alert>

                        </>}

                </>}

        </>

    )
}

export default Uvodnidio