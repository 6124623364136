import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context'
import axios from 'axios';

import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Alert from 'react-bootstrap/Alert';
import AsyncSelect from 'react-select/async';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import Upozorenje from './Upozorenje';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

//import { zemlje } from '../liste/zemlje';
//import { mupovi } from '../liste/mupovi';


const MZpasosilista = () => {

    const { izmjenadokumenta, MZpasosi, selektovano } = useGlobalContext()

    const [drzave, setDrzave] = useState([])
   
    useEffect( () => {
        preuzmiDrzave()
    }, [])

    const preuzmiDrzave = async () => {
        const response = await axios.get("https://liste-odluka.onrender.com/drzave");
        if (response.status === 200) {
          setDrzave(response.data)
        }
      }
   



    //Zemlje
    const conditionally = 0; //onemogućava učitavanje dropdown liste
    const filtrirajZemlje = (value) => {
        return drzave.filter((i) =>
            i.ime.toLowerCase().includes(value.toLowerCase())
        );
    };

    const ucitajZemlje = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajZemlje(value));
            } else return null;
        }, 1000);
    };

    return (
        <>
            {MZpasosi.length !== 0 ? <>
                {
                    MZpasosi.map((pasos, index) => (

                        <InputGroup className="mb-2" key={index} >

                            <ToggleButton
                                id={`pasos${index}`}
                                name="pasos"
                                type="checkbox"
                                variant="outline-secondary"
                                checked={pasos.pasos}
                                disabled={true}
                                onChange={e => izmjenadokumenta('MZ', index, e)}
                            >
                                Pasoš
                            </ToggleButton>

                            <Form.Control
                                placeholder="broj dokumenta"
                                aria-label="Default"
                                aria-describedby={`brojdokumenta${index}`}
                                type="text"
                                name="brojdokumenta"
                                value={pasos.brojdokumenta || ""}
                                onChange={e => izmjenadokumenta('MZ', index, e)}
                            />

                            <AsyncSelect
                                className='acinput'
                                classNamePrefix="acinput"
                                placeholder="država izdavanja"
                                cacheOptions
                                defaultOptions={drzave}
                                isClearable
                                openMenuOnClick={false}
                                value={pasos.drzavaizdavanja}
                                getOptionLabel={(e) => e.ime}
                                getOptionValue={(e) => e.ime}
                                loadOptions={ucitajZemlje}
                                onChange={(e) => selektovano("LDdrzavaizdavanja", e, index)}
                                noOptionsMessage={() => 'Nije pronađena država.'}
                            />



                            <ToggleButton
                                id={`uvid${index}`}
                                name="uvid"
                                type="checkbox"
                                variant="outline-secondary"
                                checked={pasos.uvid}
                                onChange={e => izmjenadokumenta('MZ', index, e)}
                            >
                                {pasos.uvid ? <><FontAwesomeIcon icon={faEye} /></> : <><FontAwesomeIcon icon={faEyeSlash} /></>}

                            </ToggleButton>

                        </InputGroup>

                    ))
                }
            </>
                : <><Upozorenje variant="warning">
                    Nema pasoša u ličnim dokumentima!
                </Upozorenje></>}

        </>
    )
}


const MZdodatne = () => {
    const { MZopcijeCanvas, kontrolisiCanvas, inputChange, selektovano, MZputovanje, MZpasosi,
        MZboraviste, MZboravistepodrucje, MZboravistekucni, MZboravisteadresa, MZboravisteadresanacinprovjere,
        MZstanica, MZjavljanje, MZjavljanjenacin, potraživanog } = useGlobalContext()
    const MZpromijeni = (val) => inputChange("MZboravistekucni", val);

    const [mupovi, setMupovi] = useState([])
  
    useEffect( () => {
        preuzmiMupove()
    }, [])

    const preuzmiMupove = async () => {
        const response = await axios.get("https://liste-odluka.onrender.com/mupovi");
        if (response.status === 200) {
          setMupovi(response.data)
        }
      }

    //Stanice
    const conditionally = 0; //onemogućava učitavanje dropdown liste
    const filtrirajStanice = (value) => {
        return mupovi.filter((i) =>
            i.opis === "" && (i.grad.toLowerCase().includes(value.toLowerCase()) ||     //i.opis === "" jer PS nemaju opis
                i.nazivstanice.toLowerCase().includes(value.toLowerCase()))
        );
    };

    const ucitajStanice = (value, callback) => {
        setTimeout(() => {
            if (conditionally === 0) {
                callback(filtrirajStanice(value));
            } else return null;
        }, 1000);
    };

    return (
        <>

            <Button variant="outline-secondary obavezno mb-2  pt-3 pb-3" style={{ width: "100%" }}
                onClick={(e) => kontrolisiCanvas("CANVAS_MZOPCIJE_OTVORI")}
            >
                Preciziraj mjere zabrane
            </Button>

            <Offcanvas style={{ "width": "1000px" }} show={MZopcijeCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_MZOPCIJE_ZATVORI")} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Preciziraj mjere zabrane</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <Container>
                        <Row>
                            <Col xs={3}>
                                <ButtonGroup className="mb-2">
                                    <ToggleButton
                                        id="MZputovanje"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        style={{ "width": "235px" }}
                                        checked={MZputovanje}
                                        onChange={(e) => inputChange('MZputovanje', e.target.checked)} >
                                        Zabrana putovanja
                                    </ToggleButton>
                                </ButtonGroup>
                            </Col>
                            <Col xs={6}>
                                {MZputovanje && <>{MZpasosi.length === 0 ? <> <Alert variant="warning" style={{ padding: "0.5rem 0.5rem" }}>
                                    Unesite putne isprave/pasoše u lične podatke {potraživanog} lica.
                                </Alert></> : <>  <MZpasosilista /></>}</>}





                                <Col md={{ span: 4, offset: 9 }}>
                                    {/*
                                    <ToggleButton
                                        className="mb-2"
                                        id="MZpreuzmiisprave"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        style={{
                                            opacity: MZputovanje ? '' : '40%'
                                        }}
                                        size="sm"
                                        disabled={(!MZputovanje || MZpasosiNisuPreuzetiMax.length === 3) ? true : false}
                                        checked={MZpreuzmiisprave}
                                        onChange={e => MZuzmipasose(e)}
                                    >
                                        Preuzmi isprave
                                    </ToggleButton>

                                <Button variant="outline-secondary"
                                        style={{
                                            opacity: (MZpasosi.length === 3) ? '40%' : ''
                                        }}
                                        disabled={MZputovanje ? false : true}
                                        size="sm" onClick={(e) => dodajdokument("MZ")}>
                                        Dodaj dokument
                                    </Button>*/}

                                </Col>

                            </Col>

                            <Col xs={3}>
                                <Col md={{ span: 7, offset: 4 }}>



                                </Col>
                            </Col>


                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col xs={3}>
                                <ButtonGroup className="mb-2">
                                    <ToggleButton
                                        id="MZboraviste"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        style={{ "width": "235px" }}
                                        checked={MZboraviste}
                                        onChange={(e) => inputChange('MZboraviste', e.target.checked)} >
                                        Zabrana napuštanja boravišta
                                    </ToggleButton>
                                </ButtonGroup>

                            </Col>

                            <Col xs={6}>

                                {(MZboravistekucni === "NE") ?
                                    <>
                                        <FloatingLabel
                                            controlId="MZboravistepodrucje"
                                            label="Preciziraj područje boravka"
                                            className="mb-1"
                                            style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}
                                        ><Form.Control type="text" placeholder="Preciziraj područje boravka" disabled={MZboraviste ? false : true} value={MZboravistepodrucje}
                                            onChange={(e) => inputChange('MZboravistepodrucje', e.target.value)} />
                                        </FloatingLabel>

                                        {!MZjavljanje && <>
                                            {/*
                                    
                                         <FloatingLabel
                                            controlId="MZstanica"
                                            label="Policijskaaa stanica koja vrši kontrolu"
                                            className="mb-1"
                                            style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}
                                        ><Form.Control type="text" placeholder="Policijska stanica koja vrši kontrolu" disabled={MZboraviste ? false : true} value={MZstanica}
                                            onChange={(e) => inputChange('MZstanica', e.target.value)} />
                                        </FloatingLabel>
                                        
                                        */}
                                            <div style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}>
                                                <AsyncSelect
                                                    className="mb-1"
                                                    placeholder="Policijska stanica koja vrši kontrolu"
                                                    classNamePrefix="select_bootstrapstil"
                                                    cacheOptions
                                                    defaultOptions={mupovi}
                                                    isClearable
                                                    isDisabled={MZboraviste ? false : true}

                                                    openMenuOnClick={false}
                                                    value={MZstanica}
                                                    getOptionLabel={(e) => "MUP " + e.tag + " | " + e.nazivuprave + " | PS " + e.nazivstanice}
                                                    getOptionValue={(e) => e.nazivstanice}
                                                    loadOptions={ucitajStanice}
                                                    // onInputChange={(e) => inputChange('BRinput', e)}
                                                    onChange={(e) => selektovano("MZSTANICA", e)}
                                                    noOptionsMessage={() => 'Nije pronađena stanica'}
                                                />


                                            </div>


                                        </>}



                                    </>

                                    : <>
                                        <FloatingLabel
                                            controlId="MZboravisteadresa"
                                            label="Preciziraj adresu boravka"
                                            className="mb-1"
                                            style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}
                                        ><Form.Control type="text" placeholder="Preciziraj adresu boravka" disabled={MZboraviste ? false : true} value={MZboravisteadresa}
                                            onChange={(e) => inputChange('MZboravisteadresa', e.target.value)} />
                                        </FloatingLabel>

                                        <FloatingLabel
                                            controlId="MZboravisteadresanacinprovjere"
                                            label="Preciziraj način provjere"
                                            className="mb-1"
                                            style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}
                                        ><Form.Control type="text" placeholder="Preciziraj način provjere" disabled={MZboraviste ? false : true} value={MZboravisteadresanacinprovjere}
                                            onChange={(e) => inputChange('MZboravisteadresanacinprovjere', e.target.value)} />
                                        </FloatingLabel>

                                        {/*
                                             <br/>
                                        <FloatingLabel
                                            controlId="MZstanica"
                                            label="Policijska stanica koja vrši provjeru"
                                            className="mb-1"
                                            style={{
                                                opacity: MZboraviste ? '' : '40%'
                                            }}
                                        ><Form.Control type="text" placeholder="Policijska stanica koja vrši provjeru" disabled={MZboraviste ? false : true} value={MZstanica}
                                            onChange={(e) => inputChange('MZstanica', e.target.value)} />
                                        </FloatingLabel>
                                        */}

                                        <div style={{
                                            opacity: MZboraviste ? '' : '40%'
                                        }}>
                                            <AsyncSelect
                                                className="mb-1"
                                                placeholder="Policijska stanica koja vrši provjeru"
                                                classNamePrefix="select_bootstrapstil"
                                                cacheOptions
                                                disabled={MZboraviste ? false : true}
                                                defaultOptions={mupovi}
                                                isClearable
                                                isDisabled={MZboraviste ? false : true}

                                                openMenuOnClick={false}
                                                value={MZstanica}
                                                getOptionLabel={(e) => e.nazivuprave + " - Policijska stanica " + e.nazivstanice}
                                                getOptionValue={(e) => e.nazivstanice}
                                                loadOptions={ucitajStanice}
                                                // onInputChange={(e) => inputChange('BRinput', e)}
                                                onChange={(e) => selektovano("MZSTANICA", e)}
                                                noOptionsMessage={() => 'Nije pronađena stanica'}
                                            />
                                        </div>





                                    </>}





                            </Col>

                            <Col xs={3}>

                                <ToggleButtonGroup type="radio" name="MZboravistevrsta" value={MZboravistekucni} onChange={MZpromijeni} >
                                    <ToggleButton id="MZboravistekucniDA" variant="outline-secondary" size="sm" style={{
                                        opacity: MZboraviste ? '' : '40%'
                                    }} value="DA" disabled={MZboraviste ? false : true}>
                                        Kućni pritvor
                                    </ToggleButton>
                                    <ToggleButton id="MZboravistekuceniNE" variant="outline-secondary" size="sm" style={{
                                        opacity: MZboraviste ? '' : '40%'
                                    }} value="NE" disabled={MZboraviste ? false : true}>
                                        Šire područje
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col xs={3}>
                                <ButtonGroup className="mb-2">
                                    <ToggleButton
                                        id="MZjavljanje"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        style={{ "width": "235px" }}
                                        checked={MZjavljanje}
                                        onChange={(e) => inputChange('MZjavljanje', e.target.checked)} >
                                        Javljanje policijskom organu
                                    </ToggleButton>
                                </ButtonGroup>
                            </Col>

                            <Col xs={6}>
                                <FloatingLabel
                                    controlId="MZjavljanjenacin"
                                    label="Preciziraj način javljanja"
                                    className="mb-1"
                                ><Form.Control type="text" placeholder="Preciziraj način javljanja"
                                    disabled={MZjavljanje ? false : true}
                                    value={MZjavljanjenacin}
                                    style={{
                                        opacity: MZjavljanje ? '' : '40%'
                                    }}
                                    onChange={(e) => inputChange('MZjavljanjenacin', e.target.value)} />
                                </FloatingLabel>

                                <div style={{
                                    opacity: MZjavljanje ? '' : '40%'
                                }}>
                                    <AsyncSelect
                                        className="mb-1"
                                        placeholder="Policijska stanica kojoj se javlja"
                                        classNamePrefix="select_bootstrapstil"
                                        cacheOptions
                                        disabled={MZboraviste ? false : true}
                                        defaultOptions={mupovi}
                                        isClearable
                                        isDisabled={MZjavljanje ? false : true}

                                        openMenuOnClick={false}
                                        value={MZstanica}
                                        getOptionLabel={(e) => e.nazivuprave + " - Policijska stanica " + e.nazivstanice}
                                        getOptionValue={(e) => e.nazivstanice}
                                        loadOptions={ucitajStanice}
                                        // onInputChange={(e) => inputChange('BRinput', e)}
                                        onChange={(e) => selektovano("MZSTANICA", e)}
                                        noOptionsMessage={() => 'Nije pronađena stanica'}
                                    />
                                </div>

                                {/*         <FloatingLabel
                                    controlId="MZstanica"
                                    label="Policijska stanica koja vrši kontrolu"
                                    className="mb-1"
                                    style={{
                                        opacity: MZjavljanje ? '' : '40%'
                                    }}
                                ><Form.Control type="text" placeholder="Policijska stanica kojoj se javlja" disabled={MZjavljanje ? false : true} value={MZstanica}
                                    onChange={(e) => inputChange('MZstanica', e.target.value)} />
                                </FloatingLabel>
*/}

                            </Col>
                            <Col xs={3}>

                            </Col>
                        </Row>



                    </Container>


                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default MZdodatne