import React from 'react'
import { useGlobalContext } from '../context'
import LPodaci from './dodatneopcije/LPodaci';
import Alert from 'react-bootstrap/Alert';

const Dispozitiv = () => {

    const { vrstaOdluke, brojPredmeta, IMELICA, BRANILAC,
        DRZMOLITELJICA, DrzavljaninBiH, ncb1, ncb2, MZputovanje, MZboraviste, MZjavljanje,
        ODBobustavi, PRfaza, PROfaza, PROpostoji, datumodlukePR, datumodlukePRO, MZpasosi, MZboravistekucni, MZboravistepodrucje, MZboravisteadresa, MZstanica, MZboravisteadresanacinprovjere,
        MZjavljanjenacin, vrstabranioca, datumlisenja, PRkrajlisenja, PROkrajlisenja, EKSkrajlisenja, pL, pC, pJ,
        potraživani, potraživanog, potraživanom, Potraživani, POTRAŽIVANOM, isti, Potraživanom, mu_se, mogao

    } = useGlobalContext()

    //datumlisenja = format(datumlisenja, 'dd.MM.yyyy') + ". godine"
    //PRkrajlisenja = format(PRkrajlisenja, 'dd.MM.yyyy') + ". godine"
    //  PROkrajlisenja = format(PROkrajlisenja, 'dd.MM.yyyy') + ". godine"
    //EKSkrajlisenja = format(EKSkrajlisenja, 'dd.MM.yyyy') + ". godine"


    //REDNI BROJEVI MZ - TAČKE DISPOZITIVA

    let mzPbroj
    let mzBbroj
    let mzJbroj

    if (MZputovanje && !MZboraviste && !MZjavljanje) {
        mzPbroj = "1."
    } else if (MZputovanje && MZboraviste && !MZjavljanje) {
        mzPbroj = "1.";
        mzBbroj = "2."
    } else if (MZputovanje && MZboraviste && MZjavljanje) {
        mzPbroj = "1.";
        mzBbroj = "2.";
        mzJbroj = "3."
    } else if (!MZputovanje && MZboraviste && !MZjavljanje) {
        mzBbroj = "1.";
    } else if (!MZputovanje && MZboraviste && MZjavljanje) {
        mzBbroj = "1.";
        mzJbroj = "2.";
    } else if (!MZputovanje && !MZboraviste && MZjavljanje) {
        mzJbroj = "1.";
    } else if (MZputovanje && !MZboraviste && MZjavljanje) {
        mzPbroj = "1.";
        mzJbroj = "2.";
    }



    //MZ PUTOVANJE 
    const MZputovanjeSadrzaj = () => {

        //Izdvoji pasoše u koje je Sud IZVRŠIO UVID (nalaze se kod sudske ili u spisu)
        const MZsveoduzeteISPR = MZpasosi.filter(dokument => dokument.uvid === true).map(function (dokument, index) {

            return (dokument.drzavaizdavanja.gen || "__________") + " broj: " + (dokument.brojdokumenta || "__________");

        }).join(', ').replace(/, ([^,]*)$/, ' i $1')
        const MZsveoduzeteISPRbroj = MZpasosi.filter(dokument => dokument.uvid === true).length

        //Izdvoji pasoše u koje je Sud NIJE IZVRŠIO UVID ({potraživani} ih posjeduje prema sadržaju spisa)
        const MZsveNEoduzeteISPR = MZpasosi.filter(dokument => dokument.uvid === false).map(function (dokument, index) {

            return (dokument.drzavaizdavanja.gen || "__________") + " broj: " + (dokument.brojdokumenta || "__________");
        }).join(', ').replace(/, ([^,]*)$/, ' i $1')
        const MZsveNEoduzeteISPRbroj = MZpasosi.filter(dokument => dokument.uvid === false).length

        return (
            <>
                {/*  nema dokumenata       nije drzavljanin */}
                {MZpasosi.length === 0 && DrzavljaninBiH === "NE"
                    ?
                    <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> {potraživanog} ukoliko iste posjuduje, koje će {isti} dostaviti Sudu BiH u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koje mjere će izvršiti Granična policija BiH, na način da će osigurati unošenje ovih zabrana u informacioni sistem Granične policije BiH.</p></>
                    /*  nema dokumenata       drzavljanin */
                    : MZpasosi.length === 0 && DrzavljaninBiH === "DA"
                        ?
                        <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> {potraživanog} ukoliko iste posjuduje, koje će {isti} dostaviti Sudu BiH u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH. Nadležnom organu za izdavanje ličnih karti nalaže se postupanje u skladu s članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i odredbama Pravilnika o upisivanju zabrane za prelazak državne granice u ličnu kartu državljana BiH.</p></>
                        /* ima dokumenata nije drzavljanin, imaoduzetih, nemaNEoduzetih   */
                        : MZpasosi.length > 0 && DrzavljaninBiH === "NE" && MZsveoduzeteISPRbroj > 0 && MZsveNEoduzeteISPRbroj === 0
                            ?
                            <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se od {potraživanog} privremeno {MZsveoduzeteISPRbroj === 1 ? <>oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će biti deponovana</> : <>oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave će biti deponovane</>} u službu za čuvanje privremeno oduzetih predmeta Suda BiH. {Potraživani} se obavezuje da dostavi Sudu i sve druge putne isprave ukoliko iste posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH.</p></>
                            /*   ima dokumenata    nije drzavljanin, imaoduzetih, ima neoduzetih  */
                            : MZpasosi.length > 0 && DrzavljaninBiH === "NE" && MZsveoduzeteISPRbroj > 0 && MZsveNEoduzeteISPRbroj > 0
                                ?
                                <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se od {potraživanog} privremeno {MZsveoduzeteISPRbroj === 1 ? <>oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će biti deponovana</> : <>oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave će biti deponovane</>} u službu za čuvanje privremeno oduzetih predmeta Suda BiH. {Potraživani} se obavezuje da dostavi Sudu i {MZsveNEoduzeteISPRbroj === 1 ? <>putnu ispravu {MZsveNEoduzeteISPR}</> : <>putne isprave {MZsveNEoduzeteISPR}</>} kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH.</p></>
                                /*  ima dokumenata  nije drzavljanin, nemaoduzetih, ima neoduzetih  */
                                : MZpasosi.length > 0 && DrzavljaninBiH === "NE" && MZsveoduzeteISPRbroj === 0 && MZsveNEoduzeteISPRbroj > 0
                                    ?
                                    <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se {potraživani} obavezuje da dostavi Sudu {MZsveNEoduzeteISPRbroj === 1 ? <>putnu ispravu {MZsveNEoduzeteISPR}</> : <>putne isprave {MZsveNEoduzeteISPR}</>}, kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH.</p><br /></>
                                    /*  ima dokumenata  jest drzavljanin, imaoduzetih, nemaNEoduzetih  */
                                    : MZpasosi.length > 0 && DrzavljaninBiH === "DA" && MZsveoduzeteISPRbroj > 0 && MZsveNEoduzeteISPRbroj === 0
                                        ?
                                        <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se od {potraživanog} privremeno {MZsveoduzeteISPRbroj === 1 ? <>oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će biti deponovana</> : <>oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave će biti deponovane</>} u službu za čuvanje privremeno oduzetih predmeta Suda BiH. {Potraživani} se obavezuje da dostavi Sudu i sve druge putne isprave ukoliko iste posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH.<br /> Nadležnom organu za izdavanje ličnih karti nalaže se postupanje u skladu s članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i odredbama Pravilnika o upisivanju zabrane za prelazak državne granice u ličnu kartu državljana BiH.</p></>
                                        /*  ima dokumenata  jest drzavljanin, imaoduzetih, ima neoduzetih  */
                                        : MZpasosi.length > 0 && DrzavljaninBiH === "DA" && MZsveoduzeteISPRbroj > 0 && MZsveNEoduzeteISPRbroj > 0
                                            ?
                                            <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se od {potraživanog} privremeno {MZsveoduzeteISPRbroj === 1 ? <>oduzima putna isprava (pasoš) {MZsveoduzeteISPR}, koja putna isprava će biti deponovana</> : <>oduzimaju putne isprave (pasoši) {MZsveoduzeteISPR}, koje putne isprave će biti deponovane</>} u službu za čuvanje privremeno oduzetih predmeta Suda BiH. {Potraživani} se obavezuje da dostavi Sudu i {MZsveNEoduzeteISPRbroj === 1 ? <>putnu ispravu {MZsveNEoduzeteISPR}</> : <>putne isprave {MZsveNEoduzeteISPR}</>} kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH.<br /> Nadležnom organu za izdavanje ličnih karti nalaže se postupanje u skladu s članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i odredbama Pravilnika o upisivanju zabrane za prelazak državne granice u ličnu kartu državljana BiH.</p></>
                                            /*  ima dokumenata  jest drzavljanin, nemaoduzetih, ima neoduzetih  */
                                            : MZpasosi.length > 0 && DrzavljaninBiH === "DA" && MZsveoduzeteISPRbroj === 0 && MZsveNEoduzeteISPRbroj > 0
                                                ?
                                                <><p style={pL}><strong>{mzPbroj} Zabrana putovanja van Bosne i Hercegovine</strong> u smislu člana 126. stav 2. ZKP BiH, a u cilju izvršenja ove mjere <strong>naređuje se:</strong></p><p style={pJ}><strong>a) Privremeno oduzimanje putnih isprava</strong> na način da se {potraživani} obavezuje da dostavi Sudu {MZsveNEoduzeteISPRbroj === 1 ? <>putnu ispravu {MZsveNEoduzeteISPR}</> : <>putne isprave {MZsveNEoduzeteISPR}</>}, kao i sve druge putne isprave koje posjuduje u roku od 2 (dva) dana od dana prijema ovog rješenja. </p><p style={pJ}></p><p style={pJ}><strong>b) Zabrana izdavanja novih putnih isprava</strong> kao i <strong>zabrana korištenja lične karte za prelazak državne granice</strong>, koju mjeru će izvršiti Granična policija BiH, na način da će osigurati unošenje ove zabrane u informacioni sistem Granične policije BiH. <br /> Nadležnom organu za izdavanje ličnih karti nalaže se postupanje u skladu s članom 18. Zakona o ličnoj karti državljana Bosne i Hercegovine i odredbama Pravilnika o upisivanju zabrane za prelazak državne granice u ličnu kartu državljana BiH.</p></>
                                                : <></>}


            </>


        )
    }


    //MZ BORAVIŠTE
    const MZboravisteSadrzaj = () => {
        return <>
            {MZboravistekucni === "NE" ?
                <>
                    <p style={pL}><strong>{mzBbroj} Zabrana napuštanja boravišta</strong> u smislu odredbe člana 126. stav 1. ZKP BiH, a u cilju izvršenja ove mjere:</p> <p style={pJ}><strong>a) </strong> {Potraživanom} <strong>{IMELICA.imedat || "__________"}</strong> se zabranjuje napuštanje boravišta odnosno <strong >{MZboravistepodrucje || "__________"}</strong> koje {isti} ne može napustiti bez prethodnog odobrenja Suda, izuzev ukoliko ga napušta da bi se pojavio pred Sudom BiH. Ukoliko je hitno potrebno da napusti mjesto boravišta, {potraživani} se može obratiti Sudu kako bi mu to eventualno bilo odobreno. {Potraživani} ne može promijeniti prebivalište, odnosno boravište bez prethodnog odobrenja Suda.</p>
                </>
                :
                <>
                    <p style={pL}><strong>{mzBbroj} Zabrana napuštanja boravišta (adrese stanovanja)</strong> u smislu odredbe člana 126. stav 1. ZKP BiH, a u cilju izvršenja ove mjere:</p> <p style={pJ}><strong>a) </strong> {Potraživanom} <strong>{IMELICA.imedat || "__________"}</strong> se zabranjuje napuštanje mjesta boravišta na adresi <strong >{MZboravisteadresa || "__________"}</strong> koje mjesto boravišta/prebivališta {potraživani} ne može napustiti bez prethodnog odobrenja Suda, izuzev ukoliko ga napušta  da bi se pojavio pred Sudom BiH. Ukoliko je hitno potrebno da napusti mjesto boravišta, {potraživani} se može obratiti Sudu kako bi mu to eventualno bilo odobreno. {Potraživani} ne može promijeniti prebivalište, odnosno boravište bez prethodnog odobrenja Suda.<br /></p><p style={pJ}><strong>b) </strong> Provjeru mjere zabrane napuštanja boravišta u smislu <strong>člana 126.f stav 1. ZKP BiH</strong>, odnosno boravišta <strong>{potraživanog}</strong> <strong>{IMELICA.imegen || "__________"}</strong> na naznačenoj adresi boravišta/prebivališta, provodit će ovlaštena službena lica <strong >policijske stanice {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"})</strong>, tako što će <strong >{MZboravisteadresanacinprovjere || "__________ _________"}</strong> kontrolisati da li {potraživani} boravi na naznačenoj adresi.<br /> <strong>c) </strong> O svakoj izvršenoj provjeri, službenici <strong >policijske stanice {MZstanica.nazivstanice || "__________"} </strong> će pismenim putem, izvještavati ovaj Sud, <strong>a ukoliko se utvrdi eventualno kršenje izrečenih mjera, o istom je hitno potrebno obavijestiti ovaj Sud.</strong></p>
                </>
            }
        </>

    }



    //MZ JAVLJANJE
    const MZjavljanjeSadrzaj = () => {
        return (
            <><p style={pJ}><strong>{mzJbroj} Obavezno javljanje nadležnom državnom organu iz člana 126.a stav 1. tačka d) ZKP BiH</strong>, a u cilju izvršenja ove mjere nalaže se {potraživanom} <strong>{IMELICA.imedat || "__________"}</strong> da se javlja nadležnoj <strong>policijskoj stanici {MZstanica.nazivstanice || "__________"} (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"})</strong> i to:  <strong>{MZjavljanjenacin || "__________ _________ _________"}</strong>, o čemu će PS pismenim putem redovno izvještavati Sud, a u slučaju nepoštivanja izrečene obaveze javljanja, PS će <strong>bez odlaganja</strong> obavijestiti Sud BiH.</p> </>
        )
    }




    return (
        <>

            {vrstaOdluke === "ODB" ? <><p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pC}><strong>I</strong></p><p style={pJ}><strong>Odbija se</strong> zahtjev države moliteljice za privremeno pritvaranje {potraživanog} sadržan u međunarodnoj potjernici NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"}, {DRZMOLITELJICA.ime || "__________"}, broj: {ncb2 || "__________"}, odnosno potjernici Interpola NCB Sarajevo, broj: {ncb1 || "__________"}, <strong>pa se {potraživani}</strong>:<br /></p> <LPodaci kod="LPprikaz" /> <p style={pL}><strong>ima ODMAH pustiti na slobodu.</strong></p> {ODBobustavi === "DA" ? <><p style={pC}><strong>II</strong></p> <p style={pJ}><strong>Obustavlja se postupak izručenja {potraživanog} {IMELICA.imegen || "__________"} </strong> pokrenut pred Sudom Bosne i Hercegovine na osnovu međunarodne potjernice naznačene u tački I dispozitiva ovog rješenja.</p> <p style={pC}><strong>III</strong></p> <p style={pL}>Žalba protiv ovog rješenja  ne odlaže njegovo izvršenje.</p> </> : <><p style={pC}><strong>II</strong></p> <p style={pL}>Žalba protiv ovog rješenja  ne odlaže njegovo izvršenje.</p></>}</> : <></>}
            {vrstaOdluke === "MZ" ? <>
            <p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pC}><strong>I</strong></p> <p style={pJ}><strong>Odbija se</strong> zahtjev države moliteljice za privremeno pritvaranje {potraživanog} sadržan u međunarodnoj potjernici NCB Interpola {DRZMOLITELJICA.nazivinterpola || "__________"}, {DRZMOLITELJICA.ime || "__________"}, broj: {ncb2 || "__________"}, odnosno potjernici Interpola NCB Sarajevo, broj: {ncb1 || "__________"}, <strong>te se istovremeno</strong>:</p> <p style={pL}><strong>PREMA {POTRAŽIVANOM}:</strong></p><LPodaci kod="LPprikaz" /><p style={pC}><strong>ODREĐUJU MJERE ZABRANE</strong></p>

                {MZputovanje && !MZboraviste && !MZjavljanje
                    ?
                    <><MZputovanjeSadrzaj /><p style={pL}><strong>2. </strong> O izvršenju izrečenih mjera starat će se <strong>Granična policija Bosne i Hercegovine</strong>.</p><p style={pJ}><strong>3. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>4. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                    :
                    MZputovanje && MZboraviste && !MZjavljanje
                        ?
                        <><MZputovanjeSadrzaj /><MZboravisteSadrzaj /><p style={pL}><strong>3. </strong> O izvršenju izrečenih mjera starat će se <strong>Granična policija Bosne i Hercegovine</strong> kao i ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>4. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>5. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                        :
                        MZputovanje && MZboraviste && MZjavljanje
                            ?
                            <><MZputovanjeSadrzaj /><MZboravisteSadrzaj /> <MZjavljanjeSadrzaj /><p style={pL}><strong>4.</strong> O izvršenju izrečenih mjera starat će se <strong>Granična policija Bosne i Hercegovine</strong> kao i ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>5. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>6. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                            :
                            !MZputovanje && MZboraviste && !MZjavljanje
                                ?
                                <><MZboravisteSadrzaj /><p style={pL}><strong>2. </strong> O izvršenju izrečenih mjera starat će se ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>3. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>4. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                                :
                                !MZputovanje && MZboraviste && MZjavljanje
                                    ?
                                    <><MZboravisteSadrzaj /> <MZjavljanjeSadrzaj /><p style={pL}><strong>3. </strong> O izvršenju izrečenih mjera starat će se ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>4. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>5. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                                    :
                                    !MZputovanje && !MZboraviste && MZjavljanje
                                        ?
                                        <> <MZjavljanjeSadrzaj /><p style={pL}><strong>2. </strong> O izvršenju izrečenih mjera starat će se ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>3. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>4. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                                        :
                                        MZputovanje && !MZboraviste && MZjavljanje
                                            ?
                                            <><MZputovanjeSadrzaj /> <MZjavljanjeSadrzaj /><p style={pL}><strong>3. </strong> O izvršenju izrečenih mjera starat će se <strong>Granična policija Bosne i Hercegovine</strong> kao i ovlaštena službena lica <strong>policijske stanice {MZstanica.nazivstanice || "__________"}</strong> (MUP {MZstanica.tag || "__________"} - {MZstanica.nazivuprave || "__________"}).</p><p style={pJ}><strong>4. </strong>Izrečene mjere zabrane mogu trajati dok za tim postoji potreba, a najduže do okončanja ovog ekstradiconog postupka ili drugačije odluke Suda, s tim da će se kontrola opravdanosti izrečenih mjera zabrane vršiti svaka 2 (dva) mjeseca od donošenja posljednjeg rješenja o mjerama zabrane.</p><p style={pJ}><strong>5. </strong>U skladu sa članom 126.b stav 3. ZKP-a BiH <strong>{potraživani} se posebno upozorava da {mu_se} može odrediti pritvor</strong> ukoliko ne budu postupao u skladu sa svim izrečenim mjerama ovog rješenja.</p><p style={pC}><strong>II</strong></p><p style={pL}>Žalba na ovo rješenje ne odlaže njegovo izvršenje.</p></>
                                            :
                                            <><Alert variant="warning">Preciziraj <strong>mjere zabrane</strong> koje se izriču {potraživanom}.</Alert></>
                }




            </> : <></>}


            {vrstaOdluke === "PR" ? <><p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pL}><strong>PREMA {POTRAŽIVANOM}:</strong></p> <LPodaci kod="LPprikaz" /><p style={pC}><strong> ODREĐUJE SE PRIVREMENI PRITVOR </strong></p><p style={pJ}>Koji po ovom rješenju može trajati najduže 18 (osamnaest) dana od dana lišenja slobode, <strong>odnosno od {datumlisenja || "__________"} do {PRkrajlisenja || "__________"} ili do nove odluke Suda.</strong></p><p style={pL}>Žalba protiv ovog rješenja ne odlaže njegovo izvršenje.</p></> : <></>}
            {vrstaOdluke === "PREKS" ? <><p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pL}><strong>PREMA {POTRAŽIVANOM}:</strong></p> <LPodaci kod="LPprikaz" /><p style={pC}><strong> ODREĐUJE SE EKSTRADICIONI PRITVOR </strong></p><p style={pJ}>Koji pritvor po ovom rješenju prema {potraživanom} može trajati do izvršenja odluke o izručenju ili do nove odluke ovog Suda, ali najduže šest mjeseci računajući od dana lišenja slobode <strong>odnosno od {datumlisenja || "_________"} do {EKSkrajlisenja || "_________"}.</strong></p><p style={pL}>Žalba protiv ovog rješenja ne odlaže njegovo izvršenje.</p> </> : <></>}
            {vrstaOdluke === "PRO" ? <><p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pL}><strong>PREMA {POTRAŽIVANOM}:</strong></p> <LPodaci kod="LPprikaz" /><p style={pC}><strong> PRODUŽAVA SE PRIVREMENI PRITVOR </strong></p><p style={pJ}>određen rješenjem Suda Bosne i Hercegovine, broj: {brojPredmeta || "__________"} {PRfaza} od {datumodlukePR || "__________"}, tako da privremeni pritvor {potraživanom} po ovom rješenju može trajati do dostavljanja molbe za izručenje i ekstradicione dokumentacije od strane države moliteljice, ili do nove odluke Suda, a najduže 40 (četrdeset) dana računajući od dana lišenja slobode, <strong> odnosno od {datumlisenja || "__________"} do  {PROkrajlisenja || "__________"} ili do nove odluke Suda.</strong></p><p style={pL}>Žalba protiv ovog rješenja ne odlaže njegovo izvršenje.</p></> : <></>}
            {vrstaOdluke === "EKS" ? <><p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pL}><strong>PREMA {POTRAŽIVANOM}:</strong></p> <LPodaci kod="LPprikaz" /><p style={pC}><strong> UKIDA SE PRIVREMENI PRITVOR </strong></p><p style={pJ}>određen rješenjem Suda Bosne i Hercegovine, broj: {brojPredmeta || "__________"} {PRfaza} od {datumodlukePR || "__________"} {PROpostoji && `te produžen rješenjem, broj: ${brojPredmeta || "__________"} ${PROfaza} od ${datumodlukePRO || "__________"}`}  <strong>te {mu_se} istovremeno:</strong></p><p style={pC}><strong> ODREĐUJE  EKSTRADICIONI  PRITVOR </strong></p><p style={pJ}><strong> - zbog opasnosti da bi boravkom na slobodi {mogao} izbjeći postupak izručenja ili provođenja izručenja, u smislu člana 39. stav 1. tačka a) Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima.</strong></p><p style={pJ}>Pritvor po ovom rješenju može trajati do izvršenja odluke o izručenju ili do nove odluke ovog Suda, ali  najduže šest mjeseci računajući od dana pritvaranja, odnosno <strong>od {datumlisenja || "__________"} do {EKSkrajlisenja || "__________"}</strong>.</p><p style={pL}>Žalba protiv ovog rješenja ne odlaže njegovo izvršenje.</p></> : <></>}
            {vrstaOdluke === "BR" &&
                <>
                    {vrstabranioca !== "izabrani" &&
                        <>
                    <p style={pC}><strong> R J E Š E NJ E </strong></p><p style={pJ}>{Potraživanom} <strong>{IMELICA.imedat || "__________"}</strong> za branioca po službenoj dužnosti se postavlja {Array.isArray(BRANILAC) && BRANILAC.length > 0 ? BRANILAC[0].ime : "__________"} advokat iz {Array.isArray(BRANILAC) && BRANILAC.length > 0 ? BRANILAC[0].gradgen : "__________"} u ekstradicionom predmetu ovog Suda, broj: <strong>{brojPredmeta || "__________"}</strong>.</p>
                        </>}

                </>}



        </>
    )
}

export default Dispozitiv