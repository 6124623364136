//SINTAKSA
// <Skracenica kod="ZKPBiH" gen="gen" />    Zakona o krivičnom postupku Bosne i Hercegovine

const skracenice = [
  // ZAKONI
  {zakoni:[
    {
    kod: "ZMPP",
    naziv: "Zakon o međunarodnoj pravnoj pomoći u krivičnim stvarima",
    gen: "Zakona o međunarodnoj pravnoj pomoći u krivičnim stvarima",
    aku: "",
    sl: "(Službeni glasnik Bosne i Hercegovine, broj: 53/09 i 58/13)",
    skr: "ZMPP",
  },
  {
    kod: "ZKPBiH",
    naziv: "Zakon o krivičnom postupku Bosne i Hercegovine",
    gen: "Zakona o krivičnom postupku Bosne i Hercegovine",
    aku: "",
    sl: "(Službeni glasnik Bosne i Hercegovine, broj: 3/03, 32/03, 36/03 ... 72/13, 65/18)",
    skr: "ZKP BiH",
  },
  {
    kod: "ZIKSBiH",
    naziv:
      "Zakon Bosne i Hercegovine o izvršenju krivičnih sankcija, pritvora i drugih mjera",
    gen: "Zakona Bosne i Hercegovine o izvršenju krivičnih sankcija, pritvora i drugih mjera",
    sl: "(Službeni glasnik BiH, broj 22/16)",
    skr: "ZIKS",
  }
  ]},
   //INSTITUCIJE
     //ZAVODI
  {zavodi:[
    {
      kod: "ZAVODZIKS",
      naziv: "Zavod za izvršenje krivičnih sankcija, pritvora i drugih mjera BiH",
      gen: "Zavoda za izvršenje krivičnih sankcija, pritvora i drugih mjera BiH",
      aku: "Zavodu za izvršenje krivičnih sankcija, pritvora i drugih mjera BiH",
      adresa: "Istočna Ilidža, Miloša Obilića br. 44",
      skr: "ZIKS",
      skraku: "ZIKS-u",

    },
    {
      kod: "ZAVODZenica",
      naziv: "Kazneno-popravni zavod Zenica",
      gen: "Kazneno-popravnog zavoda Zenica",
      aku: "Kazneno-popravnom zavodu Zenica",
      adresa: "__________",
      skr: "KPZ Zenica",
      skraku: "KPZ-u Zenica",
    },
    {
      kod: "ZAVODSarajevo",
      naziv: "Kazneno-popravni zavod Sarajevo",
      gen: "Kazneno-popravnog zavoda Sarajevo",
      aku: "Kazneno-popravnom zavodu Sarajevo",
      adresa: "__________",
      skr: "KPZ Sarajevo",
      skraku: "KPZ-u Sarajevo",

    },
    {
      kod: "ZAVODTuzla",
      naziv: "Kazneno-popravni zavod Tuzla",
      gen: "Kazneno-popravnog zavoda Tuzla",
      aku: "Kazneno-popravnom zavodu Tuzla",
      adresa: "__________",
      skr: "KPZ Tuzla",
      skraku: "KPZ-u Tuzla",
    },
    {
      kod: "ZAVODMostar",
      naziv: "Kazneno-popravni zavod Mostar",
      gen: "Kazneno-popravnog zavoda Mostar",
      aku: "Kazneno-popravnom zavodu Mostar",
      adresa: "__________",
      skr: "KPZ Mostar",
      skraku: "KPZ-u Mostar",
    },
    {
      kod: "ZAVODBihać",
      naziv: "Kazneno-popravni zavod Bihać",
      gen: "Kazneno-popravnog zavoda Bihać",
      aku: "Kazneno-popravnom zavodu Bihać",
      adresa: "__________",
      skr: "KPZ Bihać",
      skraku: "KPZ-u Bihać",
    },
    {
      kod: "ZAVODOrašje",
      naziv: "Kazneno-popravni zavod poluotvorenog tipa Orašje",
      gen: "Kazneno-popravnog zavoda poluotvorenog tipa Orašje",
      aku: "Kazneno-popravnom zavodu poluotvorenog tipa Orašje",
      adresa: "__________",
      skr: "KPZ PT Orašje",
      skraku: "KPZ-u PT Orašje",
    },
    {
      kod: "ZAVODBusovača",
      naziv: "Kazneno-popravni zavod poluotvorenog tipa Busovača",
      gen: "Kazneno-popravnog zavoda poluotvorenog tipa Busovača",
      aku: "Kazneno-popravnom zavodu poluotvorenog tipa Busovača",
      adresa: "__________",
      skr: "KPZ Busovača",
      skraku: "KPZ-u Busovača",
    },
    {
      kod: "ZAVODBanjaLuka",
      naziv: "Kazneno-popravni zavod Banja Luka",
      gen: "Kazneno-popravnog zavoda Banja Luka",
      aku: "Kazneno-popravnom zavodu Banja Luka",
      adresa: "Blagoja Parovića 139b, Banja Luka",
      skr: "KPZ Banja Luka",
      skraku: "KPZ-u Banja Luka",
    },
    {
      kod: "ZAVODFoča",
      naziv: "Kazneno-popravni zavod Foča",
      gen: "Kazneno-popravnog zavoda Foča",
      aku: "Kazneno-popravnom zavodu Foča",
      adresa: "Ul. Studentska br.2, Foča",
      skr: "KPZ Foča",
      skraku: "KPZ-u Foča",
    },
    {
      kod: "ZAVODBijeljina",
      naziv: "Kazneno-popravni zavod Bijeljina",
      gen: "Kazneno-popravnog zavoda Bijeljina",
      aku: "Kazneno-popravnom zavodu Bijeljina",
      adresa: "Šabačkih đaka 9, 76 300 Bijeljina",
      skr: "KPZ Bijeljina",
      skraku: "KPZ-u Bijeljina",
    },
    {
      kod: "ZAVODIstočnoSarajevo",
      naziv: "Kazneno-popravni zavod Istočno Sarajevo",
      gen: "Kazneno-popravnog zavoda Istočno Sarajevo",
      aku: "Kazneno-popravnom zavodu Istočno Sarajevo",
      adresa: "Kula, Meše Selimovića 1, 71124 Sarajevo",
      skr: "KPZ Istočno Sarajevo",
      skraku: "KPZ-u Istočno Sarajevo",
    },
    {
      kod: "ZAVODDoboj",
      naziv: "Kazneno-popravni zavod Doboj",
      gen: "Kazneno-popravnog zavoda Doboj",
      aku: "Kazneno-popravnom zavodu Doboj",
      adresa: "Svetog Save 18, 74000 Doboj",
      skr: "KPZ Doboj",
      skraku: "KPZ-u Doboj",
    },
    {
      kod: "ZAVODTrebinje",
      naziv: "Kazneno-popravni zavod Trebinje",
      gen: "Kazneno-popravnog zavoda Trebinje",
      aku: "Kazneno-popravnom zavodu Trebinje",
      adresa: "Republike Srpske 31,	89101 Trebinje", // Provjeriti adresu
      skr: "KPZ Trebinje",
      skraku: "KPZ-u Trebinje",
    },
  ]},
    //OSTALE INSTITUCIJE
  {institucije:[
 {
    kod: "SUDSKA",
    naziv: "Sudska policija Suda Bosne i Hercegovine",
    gen: "Sudske policije Suda Bosne i Hercegovine",
    aku: "Sudskoj policiji Suda Bosne i Hercegovine",
    adresa: "",
    skr: "",
  },
  {
    kod: "TBiH",
    naziv: "Tužilaštvo Bosne i Hercegovine",
    gen: "Tužilaštva Bosne i Hercegovine",
    aku: "Tužilaštvu Bosne i Hercegovine",
    adresa: "",
    skr: "Tužilaštvo BiH",
  },
  {
    kod: "MPBiH",
    naziv: "Ministarstvo pravde Bosne i Hercegovine",
    gen: "Ministarstva pravde Bosne i Hercegovine",
    aku: "Ministarstvu pravde Bosne i Hercegovine",
    adresa: "Trg BiH 1, 71000 Sarajevo",
    skr: "Ministarstvo pravde BiH",
  },
  {
    kod: "MSBiH",
    naziv: "Ministarstvo sigurnosti Bosne i Hercegovine",
    gen: "Ministarstva sigurnosti Bosne i Hercegovine",
    aku: "Ministarstvu sigurnosti Bosne i Hercegovine",
    adresa: "",
    skr: "Ministarstvo sigurnosti BiH",
  },
  {
    kod: "NCBSarajevo",
    naziv: "NCB Interpol Sarajevo",
    gen: "NCB Interpola Sarajevo",
    aku: "NCB Interpolu Sarajevo",
    adresa: "",
    skr: "NCB Interpol Sarajevo",
  },
  ]},
   
  
 
];

export default skracenice;