import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../context'

import Button from "react-bootstrap/Button";
import ToggleButton from "react-bootstrap/ToggleButton";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import Offcanvas from 'react-bootstrap/Offcanvas';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/esm/Container';
import Alert from 'react-bootstrap/Alert';

const Razlogizrucenja = () => {

    const { razlogIzrucenjaCanvas, kontrolisiCanvas, konrolisiRazlogizrucenja, procesuiranje, izvrsenje, izvrsenjeInfo, inputChange,
        procesuiranjeInfo, potraživani, izdržao, Prisutan } = useGlobalContext()

        const {   
            izvrsenjekazna: izvrsenjekazna1,   // {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
          } = izvrsenjeInfo[0];
       
          const {
            izvrsenjekazna: izvrsenjekazna2,   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /       
          } = izvrsenjeInfo[1] || {};
    
        const VrijednostIzrecene = (inputstring) => {
          
            let kazna;
            if (inputstring) {
              if (inputstring.includes("/")) {
                inputstring = inputstring.split("/")[1].trim();
              }
              kazna = inputstring.replace(/\(.*?\)/g, '').trim();
          
              const regex = /(\d+)\s*(godin[ea]?|mjese[ca]?|dana?)/g;
              let match;
              let ukupnogodina = 0;
          
              while ((match = regex.exec(kazna)) !== null) {
                const number = parseInt(match[1], 10);
                const unit = match[2];
          
                if (unit.startsWith('godin')) {
                  ukupnogodina += number;
                } else if (unit.startsWith('mjese')) {
                  ukupnogodina += number / 12;
                } else if (unit.startsWith('dan')) {
                  ukupnogodina += number / 365;
                }
              }
          
              return ukupnogodina;
            } else {
              return null;
            }
          }
          
          const cetirimjseca = 4 / 12;

          const I1tezina = VrijednostIzrecene(izvrsenjekazna1.val) 
          const I2tezina = VrijednostIzrecene(izvrsenjekazna2?.val)
  
          const [inputDebounceTimeout, setInputDebounceTimeout] = useState({});
       
/*
   procesuiranjekd: "",
    procesuiranjekazna: { val: "", paraf: "", parafrazirano: "DA" },    //{val: "", paraf: "", parafrazirano: "NE" }
    procesuiranjevrijemeiizvrsenja: "",
    procesuiranjebrojnaloga: "",
    procesuiranjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}  undefined ako nije upisan genitivn poslije /
    procesuiranjeODB: { vrijemevazenja: false, visinakazne: false }
*/
          const [procesuiranjekdLocal, setProcesuiranjekdLocal] = useState('');
          const [procesuiranjekaznaLocal, setProcesuiranjekaznaLocal ] = useState('');
          const [procesuiranjevrijemeiizvrsenjaLocal, setProcesuiranjevrijemeiizvrsenjaLocal] = useState('');
          const [procesuiranjebrojnalogaLocal, setProcesuiranjebrojnalogaLocal] = useState('');
          const [procesuiranjenazivsudanalogLocal, setProcesuiranjenazivsudanalogLocal] = useState('');

          const [procesuiranjekdLocal1, setProcesuiranjekdLocal1] = useState('');
          const [procesuiranjekaznaLocal1, setProcesuiranjekaznaLocal1 ] = useState('');
          const [procesuiranjevrijemeiizvrsenjaLocal1, setProcesuiranjevrijemeiizvrsenjaLocal1] = useState('');
          const [procesuiranjebrojnalogaLocal1, setProcesuiranjebrojnalogaLocal1] = useState('');
          const [procesuiranjenazivsudanalogLocal1, setProcesuiranjenazivsudanalogLocal1] = useState('');


/*
  izvrsenjepresuda: "",
    izvrsenjenazivsuda: { val: "", nom: "", gen: undefined }, //  {val: action.payload.val, nom: action.payload.val, gen: undefined}
    izvrsenjekd: "",
    izvrsenjekazna: { val: "", izrecenakazna: "", preostalidio: undefined, izrecenakaznanom: "", preostalidionom: "" },   //  {val: action.payload.val, izrecenakazna: modIzrecenakazna, preostalidio: modPreostalidio};  preostali dio undefined ako nije upisan poslije /
    izvrsenjeprisutanizricanju: "DA",
    izvrsenjevrijemeizvrsenja: "",
    izvrsenjebrojnaloga: "",
    izvrsenjenazivsudanalog: { val: "", nom: "", gen: undefined }, //{val: action.payload.val, nom: action.payload.val, gen: undefined}
*/
const [izvrsenjepresudaLocal, setIzvrsenjepresudaLocal ] = useState('');
const [izvrsenjenazivsudaLocal, setIzvrsenjenazivsudaLocal] = useState('');
const [izvrsenjekdLocal, setIzvrsenjekdLocal ] = useState('');
const [izvrsenjekaznaLocal, setIzvrsenjekaznaLocal] = useState('');
const [izvrsenjevrijemeizvrsenjaLocal, setIzvrsenjevrijemeizvrsenjaLocal] = useState('');
const [izvrsenjebrojnalogaLocal, setIzvrsenjebrojnalogaLocal] = useState('');
const [izvrsenjenazivsudanalogLocal, setIzvrsenjenazivsudanalogLocal] = useState('');
       
const [izvrsenjepresudaLocal1, setIzvrsenjepresudaLocal1 ] = useState('');
const [izvrsenjenazivsudaLocal1, setIzvrsenjenazivsudaLocal1] = useState('');
const [izvrsenjekdLocal1, setIzvrsenjekdLocal1 ] = useState('');
const [izvrsenjekaznaLocal1, setIzvrsenjekaznaLocal1] = useState('');
const [izvrsenjevrijemeizvrsenjaLocal1, setIzvrsenjevrijemeizvrsenjaLocal1] = useState('');
const [izvrsenjebrojnalogaLocal1, setIzvrsenjebrojnalogaLocal1] = useState('');
const [izvrsenjenazivsudanalogLocal1, setIzvrsenjenazivsudanalogLocal1] = useState('');
        


          const inputDebounce = (broj, val, dodatniparam, dodatniparam2) => {
          
            if (broj === "procesuiranjekd") {
                if (dodatniparam === "procesuiranjeInfo") {
                    setProcesuiranjekdLocal(val)
                } else {
                    setProcesuiranjekdLocal1(val)
                }     
            }
            if (broj === "procesuiranjekazna") {
                if (dodatniparam === "procesuiranjeInfo") {
                    setProcesuiranjekaznaLocal(val)
                } else {
                    setProcesuiranjekaznaLocal1(val)
                }     
            }
            if (broj === "procesuiranjevrijemeiizvrsenja") {
                if (dodatniparam === "procesuiranjeInfo") {
                    setProcesuiranjevrijemeiizvrsenjaLocal(val)
                } else {
                    setProcesuiranjevrijemeiizvrsenjaLocal1(val)
                }     
            }
            if (broj === "procesuiranjebrojnaloga") {
                if (dodatniparam === "procesuiranjeInfo") {
                    setProcesuiranjebrojnalogaLocal(val)
                } else {
                    setProcesuiranjebrojnalogaLocal1(val)
                }     
            }
            if (broj === "procesuiranjenazivsudanalog") {
                if (dodatniparam === "procesuiranjeInfo") {
                    setProcesuiranjenazivsudanalogLocal(val)
                } else {
                    setProcesuiranjenazivsudanalogLocal1(val)
                }     
            }
           


            if (broj === "izvrsenjepresuda") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjepresudaLocal(val)
                } else {
                    setIzvrsenjepresudaLocal1(val)
                }     
            }
            if (broj === "izvrsenjenazivsuda") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjenazivsudaLocal(val)
                } else {
                    setIzvrsenjenazivsudaLocal1(val)
                }     
            }
            if (broj === "izvrsenjekd") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjekdLocal(val)
                } else {
                    setIzvrsenjekdLocal1(val)
                }
            }   
            if (broj === "izvrsenjekazna") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjekaznaLocal(val)
                } else {
                    setIzvrsenjekaznaLocal1(val)
                }
            }
            if (broj === "izvrsenjevrijemeizvrsenja") {
                if (dodatniparam === "izvrsenjeInfo") {
                   setIzvrsenjevrijemeizvrsenjaLocal(val)
                } else {
                    setIzvrsenjevrijemeizvrsenjaLocal1(val)
                }   
            }
            if (broj === "izvrsenjebrojnaloga") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjebrojnalogaLocal(val)
                } else {
                    setIzvrsenjebrojnalogaLocal1(val)
                }   
            }
            if (broj === "izvrsenjenazivsudanalog") {
                if (dodatniparam === "izvrsenjeInfo") {
                    setIzvrsenjenazivsudanalogLocal(val)
                } else {
                    setIzvrsenjenazivsudanalogLocal1(val)
                }
            } 
            

          
            clearTimeout(inputDebounceTimeout[broj]);
            setInputDebounceTimeout((prevTimeouts) => ({
              ...prevTimeouts,
              [broj]: setTimeout(() => {
                // Call the API after the debounce timeout
                inputChange(broj, val, dodatniparam, dodatniparam2);
              }, 1000)
            }));
          };
          
          useEffect(() => {
            // Cleanup function to clear the timeouts on unmount and re-render
            return () => {
              Object.values(inputDebounceTimeout).forEach((timeout) => {
                clearTimeout(timeout);
              });
            };
          }, [inputDebounceTimeout]);

    const izvrsenjeKaznaPopover = (
        <Popover id="popover-basic" bsPrefix="popover-danger popover">
            <Popover.Body>
                Primjer: <b>5 (pet) godina</b>
                <br /><br />
                Ukoliko je {potraživani} {izdržao} dio kazne <strong>preostali dio</strong> možete upisati poslije kose crte<strong> /</strong> npr: <br /> <br />
                5 (pet) godina <b>/</b> 3 (tri) godine
            </Popover.Body>
        </Popover>
    );

    const nazivSudaOrganaPopover = (
        <Popover id="popover-basic" style={{ minWidth: "400px" }} bsPrefix="popover-danger popover" >
            <Popover.Body >
                Primjer: <b>Općinski sud u Splitu</b>
                <br /><br />
                Poželjno je poslije kose crte <b>/</b> upisati i kako se naziv organa izgovara u genitivu npr: <br /><br /> <strong>Općinski sud u Splitu <b>/</b> Općinskog suda u Splitu</strong>
            </Popover.Body>
        </Popover>
    );

    return (
        <>

            <div className="d-grid gap-2">
                <Button variant={procesuiranje || izvrsenje ? "secondary" : "outline-secondary"}
                    className="mt-1 mb-2"
                    onClick={(e) => kontrolisiCanvas("CANVAS_RAZLOGIZRUCENJA_OTVORI")}
                >
                    Razlog izručenja
                </Button>
            </div>

            <Offcanvas style={{
                width:
                    ((procesuiranjeInfo.length + izvrsenjeInfo.length) === 2) ? "700px"
                        : ((procesuiranjeInfo.length + izvrsenjeInfo.length) === 3) ? "850px"
                            : ((procesuiranjeInfo.length + izvrsenjeInfo.length) === 4) ? "1150px"
                                : "600px"
            }}
                show={razlogIzrucenjaCanvas} onHide={(e) => kontrolisiCanvas("CANVAS_RAZLOGIZRUCENJA_ZATVORI")}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Preciziraj razlog izručenja</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <Container>

                        <Row>

                            <Col lg={(procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1) ? "6"
                                : (procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2) ? "4"
                                    : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2) ? "6"
                                        : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1) ? "8"
                                            : ""
                            }>
                                <div className="d-grid gap-2 mb-2">
                                    <ToggleButton
                                        type="checkbox"
                                        variant="outline-secondary"
                                        checked={procesuiranje}
                                        onClick={(e) => konrolisiRazlogizrucenja('procesuiranje', procesuiranje)}
                                    >
                                        Procesuiranje
                                    </ToggleButton>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-grid gap-2 mb-2">
                                    <ToggleButton
                                        type="checkbox"
                                        variant="outline-secondary"
                                        checked={izvrsenje}
                                        onClick={(e) => konrolisiRazlogizrucenja('izvrsenje', izvrsenje)}
                                    >
                                        Izvršenje
                                    </ToggleButton>
                                </div>
                            </Col>



                        </Row>

                        <Row>

                            <Col style={{ opacity: procesuiranje ? '100%' : '50%' }}>

                                <FloatingLabel
                                    controlId="procesuiranje-kd"
                                    label="Krivično djelo/a"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Krivično djelo/a" value={procesuiranjekdLocal}
                                    onChange={(e) => inputDebounce('procesuiranjekd', e.target.value, "procesuiranjeInfo")} disabled={!procesuiranje && 'disabled'} />
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="procesuiranje-kazna"
                                    label="Zaprijećena kazna"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="zaprijećena kazna" value={procesuiranjekaznaLocal}
                                    onChange={(e) => inputDebounce('procesuiranjekazna', e.target.value, "procesuiranjeInfo")} disabled={!procesuiranje && 'disabled'} isInvalid={procesuiranjeInfo[0].procesuiranjekazna.parafrazirano === "NE"} />
                                </FloatingLabel>

                                <FloatingLabel
                                        controlId="procesuiranje-vrijemeizvrsenja"
                                        label="Vrijeme izvršenja"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="vrijeme izvršenja" value={procesuiranjevrijemeiizvrsenjaLocal}
                                        onChange={(e) => inputDebounce('procesuiranjevrijemeiizvrsenja', e.target.value, "procesuiranjeInfo")} disabled={!procesuiranje && 'disabled'} />
                                    </FloatingLabel>
                                <br/><br/>
                                <FloatingLabel
                                    controlId="procesuiranje-brojnaloga"
                                    label="Broj i datum naloga za hapšenje"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum naloga za hapšenje" value={procesuiranjebrojnalogaLocal}
                                    onChange={(e) => inputDebounce('procesuiranjebrojnaloga', e.target.value, "procesuiranjeInfo")} disabled={!procesuiranje && 'disabled'} />
                                </FloatingLabel>

                                <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                    <FloatingLabel
                                        controlId="procesuiranje-nazivsudanalog"
                                        label="Organ/sud koji je izdao nalog"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Organ/sud koji je izdao nalog" value={procesuiranjenazivsudanalogLocal}
                                        onChange={(e) => inputDebounce('procesuiranjenazivsudanalog', e.target.value, "procesuiranjeInfo")} disabled={!procesuiranje && 'disabled'} />
                                    </FloatingLabel>
                                </OverlayTrigger>

                                <Button variant="outline-secondary float-end"
                                    size="sm" disabled={!procesuiranje && 'disabled'}
                                    onClick={
                                        procesuiranjeInfo.length < 2
                                            ? (e) => inputChange('PROCESUIRANJE_DODAJ', undefined, undefined)
                                            : (e) => inputChange('PROCESUIRANJE_BRISI', undefined, undefined)
                                    }
                                >{procesuiranjeInfo.length < 2 ? "Dodaj" : <>Briši{"  "}   <FontAwesomeIcon icon={faTrashCan} /></>}</Button>

                            </Col>

                            {procesuiranjeInfo.length === 2 &&
                                <Col lg={(procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2) ? "3"
                                    : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1) ? "4"
                                        : ""
                                } style={{ opacity: procesuiranje ? '100%' : '50%' }}>
                                    <FloatingLabel
                                        controlId="procesuiranje-kd"
                                        label="Krivično djelo/a"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Krivično djelo/a" value={procesuiranjekdLocal1}
                                        onChange={(e) => inputDebounce('procesuiranjekd', e.target.value, "procesuiranjeInfo1")} disabled={!procesuiranje && 'disabled'} />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="procesuiranje-kazna"
                                        label="zaprijećena kazna"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="zaprijećena kazna" value={procesuiranjekaznaLocal1}
                                        onChange={(e) => inputDebounce('procesuiranjekazna', e.target.value, "procesuiranjeInfo1")} disabled={!procesuiranje && 'disabled'} isInvalid={procesuiranjeInfo[1].procesuiranjekazna.parafrazirano === "NE"} />
                                    </FloatingLabel>

                                    <FloatingLabel
                                        controlId="procesuiranje-vrijemeizvrsenja"
                                        label="Vrijeme izvršenja"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="vrijeme izvršenja" value={procesuiranjevrijemeiizvrsenjaLocal1}
                                        onChange={(e) => inputDebounce('procesuiranjevrijemeiizvrsenja', e.target.value, "procesuiranjeInfo1")} disabled={!procesuiranje && 'disabled'} />
                                    </FloatingLabel>
                                    <br/><br/>
                                    <FloatingLabel
                                        controlId="procesuiranje-brojnaloga"
                                        label="Broj i datum naloga za hapšenje"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum naloga za hapšenje" value={procesuiranjebrojnalogaLocal1}
                                        onChange={(e) => inputDebounce('procesuiranjebrojnaloga', e.target.value, "procesuiranjeInfo1")} disabled={!procesuiranje && 'disabled'} />
                                    </FloatingLabel>

                                    <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                        <FloatingLabel
                                            controlId="procesuiranje-nazivsudanalog"
                                            label="Organ/sud koji je izdao nalog"
                                            className="mb-2"
                                        ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Organ/sud koji je izdao nalog" value={procesuiranjenazivsudanalogLocal1}
                                            onChange={(e) => inputDebounce('procesuiranjenazivsudanalog', e.target.value, "procesuiranjeInfo1")} disabled={!procesuiranje && 'disabled'} />
                                        </FloatingLabel>
                                    </OverlayTrigger>


                                    <Button variant="outline-secondary float-end"
                                        size="sm" disabled={!procesuiranje && 'disabled'}
                                        onClick={
                                            procesuiranjeInfo.length < 2
                                                ? (e) => inputChange('PROCESUIRANJE_DODAJ', undefined, undefined)
                                                : (e) => inputChange('PROCESUIRANJE_BRISI', undefined, undefined)
                                        }
                                    >{procesuiranjeInfo.length < 2 ? "Dodaj" : <>Briši{"  "}   <FontAwesomeIcon icon={faTrashCan} /></>}</Button>


                                </Col>

                            }

                            <Col lg={(procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1) ? "6"
                                : (procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2) ? "4"
                                    : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2) ? "3"
                                        : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1) ? ""
                                            : ""} style={{ opacity: izvrsenje ? '100%' : '50%' }}>


                                <FloatingLabel
                                    controlId="izvrsenje-presuda"
                                    label="Broj i datum presude"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum presude" value={izvrsenjepresudaLocal}
                                    onChange={(e) => inputDebounce('izvrsenjepresuda', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                </FloatingLabel>

                                <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                    <FloatingLabel
                                        controlId="izvrsenje-nazivsuda"
                                        label="Naziv suda"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Naziv suda" value={izvrsenjenazivsudaLocal}
                                        onChange={(e) => inputDebounce('izvrsenjenazivsuda', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>
                                </OverlayTrigger>


                                <FloatingLabel
                                    controlId="izvrsenje-kd"
                                    label="Krivično djelo/a"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Krivično djelo/a" value={izvrsenjekdLocal}
                                    onChange={(e) => inputDebounce('izvrsenjekd', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                </FloatingLabel>

                                <OverlayTrigger trigger="focus" placement="right" overlay={izvrsenjeKaznaPopover}>
                                    <FloatingLabel
                                        controlId="izvrsenje-kazna"
                                        label="Izrečena kazna"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Izrečena kazna" value={izvrsenjekaznaLocal}
                                        onChange={(e) => inputDebounce('izvrsenjekazna', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>
                                </OverlayTrigger>

                                <FloatingLabel
                                        controlId="izvrsenje-vrijemeizvrsenja"
                                        label="Vrijeme izvršenja"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Vrijeme izvršenja" value={izvrsenjevrijemeizvrsenjaLocal}
                                        onChange={(e) => inputDebounce('izvrsenjevrijemeizvrsenja', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>

                                 
                                    <InputGroup className="mb-3" >
                                        <InputGroup.Text style={{position: "relative", flex: "1"}}>{Prisutan} izricanju</InputGroup.Text>
                                
                                        <ToggleButton
                                        id="izvrsenjeInfo-izvrsenjeprisutanizricanjuDA"
                                        type="checkbox"
                                        variant={izvrsenjeInfo[0].izvrsenjeprisutanizricanju === 'DA' ? 'secondary' : 'outline-secondary'}
                                        value={'DA'}
                                        checked={izvrsenjeInfo[0].izvrsenjeprisutanizricanju === 'DA'}
                                        onChange={(e) => inputChange('izvrsenjeprisutanizricanju', e.target.value, 'izvrsenjeInfo')}
                                        >
                                        Da
                                        </ToggleButton>

                                        <ToggleButton
                                        id="izvrsenjeInfo-izvrsenjeprisutanizricanjuNE"
                                        type="checkbox"
                                        variant={izvrsenjeInfo[0].izvrsenjeprisutanizricanju === 'NE' ? 'secondary' : 'outline-secondary'}
                                        value={'NE'}
                                        checked={izvrsenjeInfo[0].izvrsenjeprisutanizricanju === 'NE'}
                                        onChange={(e) => inputChange('izvrsenjeprisutanizricanju', e.target.value, 'izvrsenjeInfo')}
                                        >
                                        Ne
                                        </ToggleButton>

                                    </InputGroup>

                                  
                                <br/><br/>


                                <FloatingLabel
                                    controlId="izvrsenje-brojnaloga"
                                    label="Broj i datum naloga za hapšenje"
                                    className="mb-2"
                                ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum naloga za hapšenje" value={izvrsenjebrojnalogaLocal}
                                    onChange={(e) => inputDebounce('izvrsenjebrojnaloga', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                </FloatingLabel>

                                <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                    <FloatingLabel
                                        controlId="izvrsenje-nazivsudanalog"
                                        label="Organ/sud koji je izdao nalog"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Organ/sud koji je izdao nalog" value={izvrsenjenazivsudanalogLocal}
                                        onChange={(e) => inputDebounce('izvrsenjenazivsudanalog', e.target.value, "izvrsenjeInfo")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>
                                </OverlayTrigger>


                                <Button variant="outline-secondary float-end"
                                    size="sm" disabled={!izvrsenje && 'disabled'}
                                    onClick={
                                        izvrsenjeInfo.length < 2
                                            ? (e) => inputChange('IZVRSENJE_DODAJ', undefined, undefined)
                                            : (e) => inputChange('IZVRSENJE_BRISI', undefined, undefined)
                                    }
                                >{izvrsenjeInfo.length < 2 ? "Dodaj" : <>Briši{"  "}   <FontAwesomeIcon icon={faTrashCan} /></>}</Button>


                            </Col>

                            {izvrsenjeInfo.length === 2 &&

                                <Col style={{ opacity: izvrsenje ? '100%' : '50%' }}>
                                    <FloatingLabel
                                        controlId="izvrsenje-presuda"
                                        label="Broj i datum presude"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum presude" value={izvrsenjepresudaLocal1}
                                        onChange={(e) => inputDebounce('izvrsenjepresuda', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>

                                    <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                        <FloatingLabel
                                            controlId="izvrsenje-nazivsuda"
                                            label="Naziv suda"
                                            className="mb-2"
                                        ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Naziv suda" value={izvrsenjenazivsudaLocal1}
                                            onChange={(e) => inputDebounce('izvrsenjenazivsuda', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                        </FloatingLabel>
                                    </OverlayTrigger>


                                    <FloatingLabel
                                        controlId="izvrsenje-kd"
                                        label="Krivično djelo/a"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Krivično djelo/a" value={izvrsenjekdLocal1}
                                        onChange={(e) => inputDebounce('izvrsenjekd', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>

                                    <OverlayTrigger trigger="focus" placement="right" overlay={izvrsenjeKaznaPopover}>
                                        <FloatingLabel
                                            controlId="izvrsenje-kazna"
                                            label="Izrečena kazna"
                                            className="mb-2"
                                        ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Izrečena kazna" value={izvrsenjekaznaLocal1}
                                            onChange={(e) => inputDebounce('izvrsenjekazna', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                        </FloatingLabel>
                                    </OverlayTrigger>

                                    <FloatingLabel
                                        controlId="izvrsenje-vrijemeizvrsenja"
                                        label="Vrijeme izvršenja"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Vrijeme izvršenja" value={izvrsenjevrijemeizvrsenjaLocal1}
                                        onChange={(e) => inputDebounce('izvrsenjevrijemeizvrsenja', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>

                                    <InputGroup className="mb-3" >
                                        <InputGroup.Text style={{position: "relative", flex: "1"}}>{Prisutan} izricanju</InputGroup.Text>
                                
                                        <ToggleButton
                                        id="izvrsenjeInfo1-izvrsenjeprisutanizricanjuDA"
                                        type="checkbox"
                                        variant={izvrsenjeInfo[1].izvrsenjeprisutanizricanju === 'DA' ? 'secondary' : 'outline-secondary'}
                                        value={'DA'}
                                        checked={izvrsenjeInfo[1].izvrsenjeprisutanizricanju === 'DA'}
                                        onChange={(e) => inputChange('izvrsenjeprisutanizricanju', e.target.value, 'izvrsenjeInfo1')}
                                        >
                                        Da
                                        </ToggleButton>

                                        <ToggleButton
                                        id="izvrsenjeInfo1-izvrsenjeprisutanizricanjuNE"
                                        type="checkbox"
                                        variant={izvrsenjeInfo[1].izvrsenjeprisutanizricanju === 'NE' ? 'secondary' : 'outline-secondary'}
                                        value={'NE'}
                                        checked={izvrsenjeInfo[1].izvrsenjeprisutanizricanju === 'NE'}
                                        onChange={(e) => inputChange('izvrsenjeprisutanizricanju', e.target.value, 'izvrsenjeInfo1')}
                                        >
                                        Ne
                                        </ToggleButton>

                                    
                                    </InputGroup>

                                <br/><br/>

                                    <FloatingLabel
                                        controlId="izvrsenje-brojnaloga"
                                        label="Broj i datum naloga za hapšenje"
                                        className="mb-2"
                                    ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Broj i datum naloga za hapšenje" value={izvrsenjebrojnalogaLocal1}
                                        onChange={(e) => inputDebounce('izvrsenjebrojnaloga', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                    </FloatingLabel>

                                    <OverlayTrigger trigger="focus" placement="right" overlay={nazivSudaOrganaPopover}>
                                        <FloatingLabel
                                            controlId="izvrsenje-nazivsudanalog"
                                            label="Organ/sud koji je izdao nalog"
                                            className="mb-2"
                                        ><Form.Control type="text" spellCheck={false} autoComplete="off" placeholder="Organ/sud koji je izdao nalog" value={izvrsenjenazivsudanalogLocal1}
                                            onChange={(e) => inputDebounce('izvrsenjenazivsudanalog', e.target.value, "izvrsenjeInfo1")} disabled={!izvrsenje && 'disabled'} />
                                        </FloatingLabel>
                                    </OverlayTrigger>


                                    <Button variant="outline-secondary float-end"
                                        size="sm" disabled={!izvrsenje && 'disabled'}
                                        onClick={
                                            izvrsenjeInfo.length < 2
                                                ? (e) => inputChange('IZVRSENJE_DODAJ', undefined, undefined)
                                                : (e) => inputChange('IZVRSENJE_BRISI', undefined, undefined)
                                        }
                                    >{izvrsenjeInfo.length < 2 ? "Dodaj" : <>Briši{"  "}   <FontAwesomeIcon icon={faTrashCan} /></>}</Button>
                                </Col>
                            }

                        </Row>
                        {/*
                         <b>razlozi</b>
                        <br />
                        <Snipet kod="razlozi" />
                        <br /><br />
                        <b>razlozi uvod</b>
                        <br />
                        <Snipet kod="razlozi" tip="uvod" />
                        <br /><br />
                        <b>nalogzahapsenje</b>
                        <br />
                        <Snipet kod="nalogzahapsenje" />
                        <br /><br />
                        <b>nalogzahapsenje  tip 2</b>
                        <br />
                        <Snipet kod="nalogzahapsenje" tip="2" />
                        <br /><br />
                        <b>izspisa</b>
                        <br />
                        <Snipet kod="izspisa" />
                        <br /><br />
                        <b>izspisa2</b>
                        <br /><br />
                        <Snipet kod="izspisa2" />
                        <br /><br />
                        */}
                       
                        {I1tezina && 
                        <>
                            {I1tezina < cetirimjseca &&
                            <Alert variant="warning alertV2 mt-2">
                            <b>Upozorenje</b>: {izvrsenjekazna1.preostalidio ? "Neizdržani dio kazne zatvora izrečen" : "Kazna zatvora izrečena " } <b>prvom</b> presudom je {izvrsenjekazna1.preostalidio ? "manji" : "manja"} od 4 mjeseca (minimum propisan Konvencijom!). 
                            </Alert>}
                        </>}

                        {I2tezina && 
                        <>
                            {I2tezina < cetirimjseca &&
                            <Alert variant="warning alertV2 mt-2">
                            <b>Upozorenje</b>: {izvrsenjekazna2?.preostalidio ? "Neizdržani dio kazne zatvora izrečen" : "Kazna zatvora izrečena " } <b>drugom</b> presudom je {izvrsenjekazna2?.preostalidio ? "manji" : "manja"} od 4 mjeseca (minimum propisan Konvencijom!). 
                            </Alert>}
                        </>}
                      

                        {(procesuiranjeInfo[0].procesuiranjekazna.parafrazirano === "NE" || procesuiranjeInfo[1]?.procesuiranjekazna.parafrazirano === "NE") &&

                            <Col lg={(procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 1) ? "12"
                                : (procesuiranjeInfo.length === 1 && izvrsenjeInfo.length === 2) ? "12"
                                    : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 2) ? "6"
                                        : (procesuiranjeInfo.length === 2 && izvrsenjeInfo.length === 1) ? "12"
                                            : ""
                            }>
                                <Alert variant="danger alertV2 mt-2">
                                    Zaprijećena kazna bi trebala biti u nekom od <b>sljedećih formata</b>: <br /> <br />
                                    <Container>
                                        <Row>
                                            <Col>10 godina<br />
                                                10 (deset) godina<br />
                                                maksimalno 10 godina<br />
                                                maksimalno 10 (deset) godina<br />
                                                najmanje 10 godina<br />
                                                najmanje 10 (deset) godina<br />
                                            </Col>
                                            <Col>
                                                10 do 20 godina<br />
                                                10 (deset) do 20 (dvadeset) godina<br />
                                                od 10 do 20 godina<br />
                                                od 10 (deset) do 20 (dvadeset) godina<br />
                                            </Col>
                                        </Row>
                                    </Container>


                                </Alert>
                            </Col>
                        }


                    </Container>


                </Offcanvas.Body>
            </Offcanvas>
        </>
    );


}

export default Razlogizrucenja

export function Parafraziraj(tip, input) {

    if (tip === "PROCESKAZNA") {
        const pattern1 = /^\s*((od|maksimalno)\s+)?(\d+)\s*(\((\s*[a-zA-ZšđčćžŠĐČĆŽ]+\s*)+\)\s*)?(godin(a|e)|mjesec(a|i)?|\bmjesec\b)\s*$/;
        // 6 mjeseci
        // 6 (šest) mjeseci
        // 10 godina 
        // 10 (deset) godina
        // od/maksimalno 10 godina
        // od/maksimalno 10 (deset) godina

        //  dobar     const pattern2 = /\s*najmanje\s*(\d+)\s*(?:\(\s*([\w\s]+)\s*\))?\s*((?:godin(?:a|e))|(?:mjesec(?:a|i)?|\bmjesec\b))(?:.*)?/i;
        const pattern2 = /\s*najmanje\s*(\d+)\s*(?:\(\s*([\w\s]+)\s*\))?\s*((?:godin(?:a|e))|(?:mjesec(?:a|i)?|\bmjesec\b))(?=\s*$)/i;

        // najmanje 6 mjeseci, najmanje 6 (šest) mjeseci
        // najmanje 10 godina, najmanje 10 (deset) godina

        const pattern3 = /\s*najmanje\s*(\d+)\s*(?:\(\s*([\w\s]+)\s*\))?\s*((?:godin(?:a|e))|(?:mjesec(?:a|i)?|\bmjesec\b))(?:.*)?\s+ili\s+(?=.*(?:doživotni|doživotnog)\b)(.*)$/i;
        // najmanje 6 mjeseci, najmanje 6 (šest) mjeseci  ili kazna doživotnog zatvora / doživotni zatvor
       // najmanje 10 godina, najmanje 10 (deset) godina  ili kazna doživotnog zatvora / doživotni zatvor

        const pattern4 = /\s*(\bod\b)?\s*(\d+)\s*(?:\(([\w\s]+)\))?\s*(godina|mjesec(?:a|i)?)?\s*(\bdo\b|-)\s*(\d+)\s*(?:\(([\w\s]+)\))?\s*(godina|mjesec(?:a|i)?)\s*/i;

        //10 ?MJESECI/GODINA do 20 MJESECI/GODINA
        //od 10 do 20 godina
        //10 (deset) do 20 (dvadeset) godina
        //od 10 (deset) do 20 (dvadeset) godina

   

        let matchedPattern = null;
        let parafrazirano;
        input = input.toLowerCase();

        if (pattern1.test(input)) {
            matchedPattern = pattern1;
        } else if (pattern2.test(input)) {
            matchedPattern = pattern2;
        } else if (pattern3.test(input)) {
            matchedPattern = pattern3;
        }
        else if (pattern4.test(input)) {
            matchedPattern = pattern4;
        }

        if (matchedPattern) {
            let matches = input.match(matchedPattern);

            if (matchedPattern === pattern1) {
                const [, , , broj, slovima, , godina] = matches;
                parafrazirano = "je, kao maksimalna, zaprijećena kazna zatvora u trajanju od " + broj + " " + (slovima ? slovima : "") + godina;
            } 
            else if (matchedPattern === pattern2) {
                console.log("pattern 2")
                console.log(matches)
                const [, broj, slovima, godina] = matches;
                parafrazirano = "je zaprijećena kazna zatvora u trajanju od najmanje " + broj + " " + (slovima ? "(" + slovima + ") " : "") + godina;
            } else if (matchedPattern === pattern3) {
                console.log("pattern 3")
                console.log(matches)
                const [, broj, slovima, godina, ostatak] = matches;
                parafrazirano = "je zaprijećena kazna zatvora u trajanju od najmanje " + broj + " " + (slovima ? "(" + slovima + ") " : "") + godina + " ili " + ostatak;

            } else if (matchedPattern === pattern4) {
                console.log("pattern 4")
                console.log(matches)
                const [, , broj1, slovima1, godina1, , broj2, slovima2, godina2] = matches;
                parafrazirano = "je zaprijećena kazna zatvora u trajanju od " + broj1 + " " + (slovima1 ? "(" + slovima1 + ")" : "") + (godina1 ? " " + godina1 : "") + " do " + broj2 + " " + (slovima2 ? "(" + slovima2 + ") " : "") + godina2;
              //  let kraj = godina2

/*
                if (/mjesec(?:a|i)?$/.test(kraj)) {
                    parafrazirano = parafrazirano.replace(/\(\s*(.*?)\s*jedan\s*\)(\s*)/g, '($1jednog)$2');
                }

                if (/godin(?:a|e)$/.test(kraj)) {
                    console.log("završava sa godina")
                    parafrazirano = parafrazirano.replace(/\(\s*(.*?)\s*jedan\s*\)(\s*)/g, '($1jedne)$2');
                    parafrazirano = parafrazirano.replace(/\(\s*(.*?)\s*dva\s*\)(\s*)/g, '($1dvije)$2');
                }

 */

            } 

            parafrazirano = parafrazirano.replace(/\((.*?)jedan\s*\)(\s*)mjesec/g, '($1jednog)$2mjeseca');
            parafrazirano = parafrazirano.replace(/\bmjesec(?!\w)/g, "mjeseca");

            parafrazirano = parafrazirano.replace(/(\b(?!11)\d*[1])\sgodina\b/g, '$1 godine');  //11 excluded

            parafrazirano = parafrazirano.replace(/\((.*?)jedne\s*\)(\s*)godina/g, '($1jedne)$2godine');
            parafrazirano = parafrazirano.replace(/\((.*?)jedan\s*\)(\s*)godina/g, '($1jedne)$2godine');
            parafrazirano = parafrazirano.replace(/\((.*?)jedna\s*\)(\s*)godina/g, '($1jedne)$2godine');

            parafrazirano = parafrazirano.replace(/\((.*?)dva\s*\)(\s*)godina/g, '($1dvije)$2godine');
            parafrazirano = parafrazirano.replace(/\((.*?)dva\s*\)(\s*)godine/g, '($1dvije)$2godine');
            parafrazirano = parafrazirano.replace(/\((.*?)dvije\s*\)(\s*)godina/g, '($1dvije)$2godine');
            // kasnije fixati ( jedan ) da returna ( jedne ) ne ( jedne)

            return { parafrazirano, status: "DA" };
        } else {
            parafrazirano = "";
            return { parafrazirano, status: "NE" };
        }

    }  //PROCESKAZNA

    if (tip === "IZVRSENJEKAZNA") {

        if (input.includes("/")) {
            input.toLowerCase();
            const [izrecenakazna, preostalidio] = input.split("/");
            let izrecenakaznanom = izrecenakazna
            let preostalidionom = preostalidio

            let modIzrecenakazna = izrecenakazna.replace(/\((.*?)jedan\s*\)(\s*)dan/g, '($1jednog)$2dana'); // fixati ovo jer strogo prati (jedan) dan pa izbacuje danaa
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedan\)(\s*)mjesec/g, '($1jednog)$2mjeseca');

            modIzrecenakazna = modIzrecenakazna.replace(/(\b(?!11)\d*[1])\sgodina\b/g, '$1 godine');  //11 excluded
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedan\)(\s*)godina/g, '($1jedne)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedna\)(\s*)godina/g, '($1jedne)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedne\)(\s*)godina/g, '($1jedne)$2godine');

            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dva\)(\s*)godina/g, '($1dvije)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dva\)(\s*)godine/g, '($1dvije)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dvije\)(\s*)godina/g, '($1dvije)$2godine');

            modIzrecenakazna = modIzrecenakazna.replace(/\bdan\b/g, "dana");
            modIzrecenakazna = modIzrecenakazna.replace(/\bmjesec\b/g, "mjeseca");

            let modPreostalidio = preostalidio.replace(/(\b(?!11)\d*[1])\sgodina\b/g, '$1 godinu');  //11 excluded
            modPreostalidio = modPreostalidio.replace(/\((.*?)jedan\)(\s*)godina/g, '($1jednu)$2godinu');
            modPreostalidio = modPreostalidio.replace(/\((.*?)jedna\)(\s*)godina/g, '($1jednu)$2godinu');
            modPreostalidio = modPreostalidio.replace(/\((.*?)jedne\)(\s*)godina/g, '($1jednu)$2godinu');

            modPreostalidio = modPreostalidio.replace(/\((.*?)dva\)(\s*)godina/g, '($1dvije)$2godine');
            modPreostalidio = modPreostalidio.replace(/\((.*?)dva\)(\s*)godine/g, '($1dvije)$2godine');
            modPreostalidio = modPreostalidio.replace(/\((.*?)dvije\)(\s*)godina/g, '($1dvije)$2godine');

            return { modIzrecenakazna, modPreostalidio, izrecenakaznanom, preostalidionom };

        } else {

            let modIzrecenakazna = input.replace(/\((.*?)jedan\s*\)(\s*)dan/g, '($1jednog)$2dana'); // fixati ovo jer strogo prati (jedan) dan pa izbacuje danaa
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedan\)(\s*)mjesec/g, '($1jednog)$2mjeseca');

            modIzrecenakazna = modIzrecenakazna.replace(/(\b(?!11)\d*[1])\sgodina\b/g, '$1 godine');  //11 excluded
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedan\)(\s*)godina/g, '($1jedne)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedna\)(\s*)godina/g, '($1jedne)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)jedne\)(\s*)godina/g, '($1jedne)$2godine');

            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dva\)(\s*)godina/g, '($1dvije)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dva\)(\s*)godine/g, '($1dvije)$2godine');
            modIzrecenakazna = modIzrecenakazna.replace(/\((.*?)dvije\)(\s*)godina/g, '($1dvije)$2godine');

            modIzrecenakazna = modIzrecenakazna.replace(/\bdan\b/g, "dana");
            modIzrecenakazna = modIzrecenakazna.replace(/\bmjesec\b/g, "mjeseca");

            return { modIzrecenakazna, modPreostalidio: undefined };

        }

    } //IZVRSENJEKAZNA

    if (tip === "ORGANLIŠENJA") {

            let nom, gen;

            nom = input.replace(/(\s*)\bPU(\s*)\b/gi, "$1Policijska uprava$2");  
            nom = nom.replace(/(\s*)\bMUP(\s*)\b/gi, "$1Ministarstvo unutrašnjih poslova$2");
            nom = nom.replace(/(\s*)\bRS(\s*)\b/gi, "$1Republika Srpska$2");
            nom = nom.replace(/(\s*)\bGP\s*BiH(\s*)\b/gi, "$1Granična policija Bosne i Hercegovine$2");
            nom = nom.replace(/(\s*)\bPU(\s*)\b/gi, "$1Policijska uprava$2");
            nom = nom.replace(/(\s*)\bF\s*BIH(\s*)\b/gi, "$1Federacija Bosne i Hercegovine$2");                     
            nom = nom.replace(/(\s*)\bSIPA(\s*)\b/gi, "$1Državne anomcije za istrage i zaštitu$2");

            gen = input.replace(/(\s*)\bPU(\s*)\b/gi, "$1Policijske uprave$2");  
            gen = gen.replace(/(\s*)\bMUP(\s*)\b/gi, "$1Ministarstva unutrašnjih poslova$2");
            gen = gen.replace(/(\s*)\bRS(\s*)\b/gi, "$1Republike Srpske$2");
            gen = gen.replace(/(\s*)\bMinistarstvo(\s*)\b/gi, "$1Ministarstva$2");
            gen = gen.replace(/(\s*)\bKanton(\s*)\b/gi, "$1Kantona$2");
            gen = gen.replace(/(\s*)\bSektor(\s*)\b/gi, "$1Sektora$2");
            gen = gen.replace(/(\s*)\bGP\s*BiH(\s*)\b/gi, "$1Granične policije Bosne i Hercegovine$2");
            gen = gen.replace(/(\s*)\bDržavna\s*agencija(\s*)\b/gi, "$1Državne agencije$2");
            gen = gen.replace(/(\s*)\bUprava(\s*)\b/gi, "$1Uprave$2");
            gen = gen.replace(/(\s*)\bPU(\s*)\b/gi, "$1Policijske uprave$2");   
            gen = gen.replace(/(\s*)\bF\s*BIH(\s*)\b/gi, "$1Federacije Bosne i Hercegovine$2");                     
            gen = gen.replace(/(\s*)\bSIPA(\s*)\b/gi, "$1Državne agencije za istrage i zaštitu$2");
            return { nom, gen };

 

    } //ORGANLIŠENJA


} //Parafraziraj
